
import { Component } from 'vue-property-decorator';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { Getter, Mutation, State } from 'vuex-class';
import { IProject } from '@/types/project.types';
import {
  EnergyDevicesCircleTypes,
} from '@/types/energyVisualisation/EnergyCircleType';
import BatteryStatusBar
  from '@/ui/components/wizards/installationWizard/steps/inviteUsers/components/BatteryStatusBar.vue';
import ActualView
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/ActualView/index.vue';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import { IDevice } from '@/types/devices.types';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import {
  energyGreen,
  energyRed
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/constants';
import { IIncludedSystemsTypes, WizardPath } from '@/types/wizards/installationWizard.types';
import { defaultTimeoutRepotMessages } from '@/utils/constants';
import { hybridVersionDate, plcVersionDate } from '@/utils/versionManagementUtils';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';
import installationWizardVariables
  from '@/ui/components/wizards/installationWizard/installationWizardVariables';

@Component({
  components: {
    ModalWindow,
    BatteryStatusBar,
    WizardContentView,
    ActualView,
    InfoTooltip,
    PushButtonBase,
  },
})
export default class SystemTestPage extends WizardComponent {
  @State('installationWizard') wizardState!: IInstallationWizardState;
  @Getter('projects/project') project!: IProject;
  @Getter('installationWizard/emsDevice') emsDevice!: IDevice;
  @Getter('installationWizard/includedSystemsTypes') includedSystemsTypes!: IIncludedSystemsTypes;
  @Getter('installationWizard/considerProjectStatus') considerProjectStatus!: boolean;
  @Getter('installationWizard/pilotSystemCount') pilotSystemCount!: number;
  @Getter('mqttClient/isProjectOnline') isProjectOnline !: boolean;
  @Getter('installationWizard/disabledWhileOffline') disabledWhileOffline!: boolean;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Mutation('installationWizard/setRestartSent') setRestartSent!: (payload: boolean) => void;
  @Mutation('installationWizard/setDisabledWhileOffline') setDisabledWhileOffline!: (payload: boolean) => void;

  tests: any = {};
  activateTests = false;
  testsStarted = false;
  modeChanged = false;
  finished = false;
  disable = false;
  hasBeenOpened = false;

  valid = true;
  errorWarning = {
    thrown: false,
    message: {},
  };

  doesHover = false;
  openDialog = false;

  currentState = 0;
  duration = 0;
  testableSystems = ['charge_station', 'electric_heating', 'chp', 'pilot'];
  obligatoryTests = ['battery', 'ioModulesTest'];

  getColorMappings(value: number) {
    if (value === 0) return 'lynusText';
    if (value > 0) return energyRed;
    if (value < 0) return energyGreen;
  }

  gridConverterMappings = [
    'prgEnergy.lrPowerL1Grid',
    'prgEnergy.lrPowerL2Grid',
    'prgEnergy.lrPowerL3Grid',
  ];

  batteryVariables = {
    start: 'prgEnergy.fbI.bStartChrgDischrgTest',
    feedback: 'prgEnergy.fbI.bS_StartChrgDischrgTest',
    state: 'prgEnergy.fbI.byModeChrgDischrgTest', // State of the battery test. 0 = not started, 1 = charging, 2 = discharging
  }

  errorWarnings: any = {
    battery: {
      name: this.$t('installationWizard.systemTestPage.tests.battery'),
      variables: ['prgEnergy.byErrorWarningB1'],
      errorValue: 2,
      thrown: false,
    },
    ioModulesTest: {
      name: this.$t('installationWizard.systemTestPage.tests.ioModulesTest'),
      variables: ['prgIO.bErrorIO'],
      errorValue: 1,
      thrown: false,
    },
    pilot: {
      name: this.$t('installationWizard.systemTestPage.tests.pilot'),
      variables: ['prgPilot.bErrorPilot'],
      errorValue: 1,
      thrown: false,
    },
  }

  stateLimit: { [key: number]: number } = {
    0: 90,
    1: 250,
    2: 250,
  }

  get areAllGirdConverterMappingsAvailable() {
    return this.gridConverterMappings.every((mapping) => this.measurements[mapping] !== undefined);
  }

  get power(): number {
    return (this.measurements[installationWizardVariables('maxPowerInverter', 'componentsPage').variable] as number ?? 0) / this.wizardState.inverterCount;
  }

  get isHybridVersion() {
    return plcVersionDate(this.project).getTime() > hybridVersionDate.getTime();
  }

  get tenantInstallationNoBattery() {
    return this.project.meta.wizardSettings?.wizardPath === WizardPath.TENANT_INSTALLATION_NO_BATTERY;
  }

  async prepareVariablesToSend() {
    // check if EMS is disabled and enable it if necessary
    if (this.measurements['prgEMS.fbEMS.bEnable'] === 0 && !this.tenantInstallationNoBattery) {
      await this.sendVariables([{
        variable: 'prgEMS.fbEMS.bEnable',
        value: 1,
        feedbackVariable: 'prgEMS.fbEMS.bStateEnable',
        isBoolean: true,
      }]);
    }
  }

  resetTests() {
    this.errorWarning = {
      thrown: false,
      message: {},
    };
    this.currentState = 0;
    this.duration = 0;

    this.modeChanged = false;
    this.testsStarted = false;
    this.finished = false;
    this.validate();
  }

  // Get error warnings that need to be observed for selected tests
  selectedErrorWarnings(): [string, string, number][] {
    Object.keys(this.tests).filter((test) => !this.obligatoryTests.includes(test) && this.testableSystems.includes(test)).forEach((test) => {
      if (this.tests[test] && !['pilot', 'string_inverter'].includes(test)) {
        this.errorWarnings[test] = {
          name: this.$t(`installationWizard.systemTestPage.tests.${test}`),
          variables: this.getErrorWarnings(test),
          errorValue: 2,
        };
      }
    });
    // First, filter out the keys of interest from the errorWarnings object
    const filteredKeys = Object.keys(this.errorWarnings)
      .filter(test => !this.obligatoryTests.includes(test) &&
        this.testableSystems.includes(test) &&
        this.tests[test]);

    const obligatoryErrorWarnings: [string, string, number][] = [];
    this.obligatoryTests.forEach((test) => {
      obligatoryErrorWarnings.push(...this.errorWarnings[test].variables.map((variable: string) => [test, variable, this.errorWarnings[test].errorValue]));
    });
    // Now, transform these keys into the desired array structure using reduce
    const optionalErrorWarnings = filteredKeys.reduce((acc, test) => {
      const {variables, errorValue} = this.errorWarnings[test];
      const newEntries = variables.map((variable: string) => [test, variable, errorValue]);
      return acc.concat(newEntries);
    }, []) as [string, string, number][];
    return [...obligatoryErrorWarnings, ...optionalErrorWarnings];
  }

  getErrorWarnings(system: string) {
    return Object.values(this.emsDevice.data?.meta?.controllerMappings[system].components).filter((component: any) => component.error).map((component: any) => component.error);
  }

  get testsComplete() {
    return this.testsStarted && this.finished;
  }

  validate() {
    // If activateTests is false, the tests are not activated and the page is valid
    if (!this.activateTests) {
      this.valid = true;
    } else {
      if (!this.hasBeenOpened) {
        this.openDialog = true;
        this.hasBeenOpened = true;
      }
      this.valid = this.testsComplete;
    }
  }

  observeBatteryState() {
    // Observe battery state
    const stateVariable = this.measurements[this.batteryVariables.state];
    const stateFeedbackVariable = this.measurements[this.batteryVariables.feedback];
    if (this.testsStarted && stateFeedbackVariable === 0 && stateVariable === 0 && this.modeChanged) {
      this.finished = true;
    }
    this.currentState = stateVariable;
  }

  observeErrorWarnings() {
    // Observe error warnings
    this.selectedErrorWarnings().forEach((errorWarning) => {
      if (errorWarning) {
        if (this.measurements[errorWarning[1]] === errorWarning[2]) {
          this.setErrorWarning(errorWarning[0], errorWarning[1], true);
        }
      }
    });
  }

  setTestsStarted() {
    // Set testsStarted to true if the battery test has started
    if (this.measurements[this.batteryVariables.feedback] === 1) {
      this.testsStarted = true;
      this.setErrorWarning('battery', this.batteryVariables.start, false);
    }
  }

  setErrorWarning(errorWarning: any, variable: string, state: boolean) {
    this.errorWarning.thrown = state;
    this.errorWarnings[errorWarning].thrown = state;
    this.errorWarning.message = state ? this.$t('installationWizard.systemTestPage.error.errorWarning', {
      variable,
      errorWarning
    }) : '';
  }

  setTimeoutError() {
    this.errorWarning.thrown = true;
    this.errorWarning.message = this.$t('installationWizard.systemTestPage.error.timeout');
    this.disable = false;
  }

  async setEMSState(state: 0 | 1) {
    try {
      await this.sendVariables([{
        variable: 'prgEMS.fbEMS.bEnable',
        value: state,
        feedbackVariable: 'prgEMS.fbEMS.bStateEnable',
        isBoolean: true,
      }]);
      return true;
    } catch (e) {
      this.setReport({
        type: 'error',
        message: `Could not ${state ? 'enable' : 'disable'} EMS`,
        value: true,
        timeout: defaultTimeoutRepotMessages,
      });
      return false;
    }
  }

  // Start testing process
  async startTests() {
    this.disable = true;
    await this.setEMSState(0);
    this.resetTests();
    this.errorWarnings = {
      battery: {
        name: this.$t('installationWizard.systemTestPage.tests.battery'),
        variables: ['prgEnergy.byErrorWarningB1'],
        errorValue: 2,
        thrown: false,
      },
      ioModulesTest: {
        name: this.$t('installationWizard.systemTestPage.tests.ioModulesTest'),
        variables: ['prgIO.bErrorIO'],
        errorValue: 1,
        thrown: false,
      },
      pilot: {
        name: this.$t('installationWizard.systemTestPage.tests.pilot'),
        variables: ['prgPilot.bErrorPilot'],
        errorValue: 1,
        thrown: false,
      },
    };
    if (this.wizardState.stringInverterCount > 0) {
      this.errorWarnings = {
        ...this.errorWarnings,
        string_inverter: {
          name: this.$t('installationWizard.systemTestPage.tests.string_inverter'),
          variables: ['prgACPV.byErrorWarningInv'],
          errorValue: 1,
          thrown: false,
        },
      };
    }
    // Start battery test
    this.duration = 0;
    let previousState = this.currentState;

    // Check in a 1s interval the state variable of the battery test in measurement and stop if it has reached 0
    const interval = setInterval(async () => {
      if (this.project.id) {
        // Newly fetch measurements
        await this.fetchMeasurements(this.project.id);
      }
      if (!this.testsStarted) this.setTestsStarted();

      // Observe and handle changes in measurements
      this.observeBatteryState();
      this.observeErrorWarnings();

      // Clear interval if tests are completed successfully
      if (this.testsComplete) {
        clearInterval(interval);
        this.validate();
        await this.setEMSState(1);
        this.disable = false;
      }

      // Check duration for current state
      if (this.currentState === previousState) {
        if (this.duration >= this.stateLimit[this.currentState]) {
          this.setTimeoutError();
          this.handleError();
          clearInterval(interval);
          await this.setEMSState(1);
        }
      } else {
        this.modeChanged = true;
        this.duration = 0;
      }
      previousState = this.currentState;
      this.duration++;
    }, 1000);
  }

  handleError() {
    // Display error message
    this.throwError();
    this.resetTests();
    this.disable = false;
  }

  throwError() {
    const error = this.errorWarning.message;
    this.setReport({
      type: 'error',
      message: error,
      value: true,
      timeout: defaultTimeoutRepotMessages,
    });
    this.currentState = 0;
  }

  // Check available systems
  defineTests() {
    if (plcVersionDate(this.project).getTime() > hybridVersionDate.getTime()) {
      this.testableSystems = [...this.testableSystems, 'heating_pump', 'string_inverter', 'chp'];
    }
    // Define obligatory tests
    this.tests = {
      battery: true,
      ioModulesTest: true,
    };

    if (this.wizardState.stringInverterCount > 0) {
      this.tests.string_inverter = true;
      this.errorWarnings = {
        ...this.errorWarnings,
        string_inverter: {
          name: this.$t('installationWizard.systemTestPage.tests.string_inverter'),
          variables: ['prgACPV.byErrorWarningInv'],
          errorValue: 1,
          thrown: false,
        },
      };
    }

    if (this.pilotSystemCount) {
      this.tests.pilot = true;
    }

    // Define optional tests
    Object.values(EnergyDevicesCircleTypes).forEach((energyCircleType: EnergyDevicesCircleTypes) => {
      if (this.isSystemPresent(energyCircleType) && this.testableSystems.includes(energyCircleType)) {
        this.tests[energyCircleType] = true;
      }
    });

  }

  isSystemPresent(systemType: EnergyDevicesCircleTypes) {
    return this.getSystemCount(systemType) > 0;
  }

  getSystemCount(systemType: EnergyDevicesCircleTypes) {
    return Object.entries(this.emsDevice.data?.meta?.controllerMappings[systemType]?.components)
      .filter((component: any) => component[1].error).length ?? 0;
  }

  created() {
    if (!this.wizardState.restartSent && (this.power >= 30000) && this.isHybridVersion && this.isDeye) {
      this.setRestartSent(true);
      this.setDisabledWhileOffline(false);
      setTimeout(() => {
        this.setDisabledWhileOffline(true);
      }, 120000);
    }
    this.defineTests();
  }
}
