
import { Vue, Component, Prop } from 'vue-property-decorator';
import ChartProdCons from '@/ui/components/devices/components/EnergyParts/EMS/components/charts/ChartProdCons.vue';
import Forecast from '@/ui/components/devices/mpc/EMS/components/charts/Forecast/index.vue';
import SelfConsumption
  from '@/ui/components/devices/mpc/components/chartsWindow/SelfConsumption/index.vue';

/**
 * Component that represent wrapper for charts tabs navigation.
 * Used in EMS.
 */
@Component({
  components: {
    ChartProdCons,
    Forecast,
    SelfConsumption,
  },
})
export default class EMSCharts extends Vue {
  @Prop({ default: null }) mpcData!: any;
  @Prop({ default: null }) prediction!: any;
  @Prop({ default: null }) predictedPower!: any;
  @Prop({ default: null }) predictedTargetPower!: any;
  @Prop({ default: null }) predictedEnergy!: any;
  @Prop({ default: null }) predictedSOC!: any;
  @Prop() mpcId!: any;
  @Prop() scaling!: any;

  tab = null
  settingsWrapperHeight = 0;

  mounted() {
    this.$nextTick(() => {
      const settingsWrapperElement = (this.$refs.settingsWrapper as any);
      this.settingsWrapperHeight = settingsWrapperElement.clientHeight * 0.7;
    });
  }

  get items() {
    return [
      {
        title: this.$t('mlModel.EMS.charts.chartDifference.title'),
        view: 'SelfConsumption',
      },
      {
        title: this.$t('mlModel.EMS.charts.chartProdConst.title'),
        view: 'ChartProdCons',
      },
      {
        title: this.$t('mlModel.EMS.charts.forecast.title'),
        view: 'Forecast',
      },
    ];
  }
}
