
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';
import ActualView from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/ActualView/index.vue';
import EnergyFlowView from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyFlowView/index.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import EnergyViewCharts from '@/ui/components/devices/mpc/EnergyView/components/charts/index.vue';

@Component({
  components: {
    MPCLayout,
    ActualView,
    EnergyFlowView,
    CircleSpinner,
    ShowEventDotBase,
    EnergyViewCharts,
  },
})
export default class EnergyView extends Vue {
  @Prop() deviceData!: any;
  @State('mpc') mpcState!: any;
  @Action('mpc/fetchMPCData') fetchMPCData!: any;

  mpc: any = null;
  reRenderKey = 0;

  get showEventGeneralErrorWarning() {
    return {
      'ShowEvent_errorWarningState': this.mpc?.data?.meta?.controllerMappings?.errorWarning,
    };
  }

  get allSystems() {
    if (this.mpc?.data?.meta?.controllerMappings) {
      const {
        pv,
        generator,
        grid,
        battery,
        house,
        charge_station,
        electric_heating,
        heating_pump,
        big_consumer,
      } = this.mpc.data.meta.controllerMappings;
      return {
        pv,
        generator,
        battery,
        grid,
        house,
        charge_station,
        electric_heating,
        heating_pump,
        big_consumer,
      };
    } else {
      return null;
    }
  }

  async rerenderDevice() {
    this.mpc = null;
    this.mpc = await this.fetchMPCData(this.deviceData.id);
    this.reRenderKey += 1;
  }

  /**
   * Convert MPC id from "id-id-id" to "id_id_id"
   */
  get mpcIdMod() {
    if (this.deviceData.id) return this.deviceData.id.replace(/-/g, '_');
    return null;
  }

  async created() {
    this.mpc = await this.fetchMPCData(this.deviceData.id);
  }
}
