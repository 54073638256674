
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent ShowEventDotBase component.
 */
@Component
export default class ShowEventDotBase extends Vue {
  @Prop({ default: false }) state!: boolean;
  @Prop({ default: '#ff0000' }) activeColor!: string;
  @Prop({ default: '#bebebe' }) inactiveColor!: string;
  @Prop({ default: null }) activeMessage!: string;
  @Prop({ default: null }) inactiveMessage!: string;
  @Prop({ default: 20 }) width!: number | string;
  @Prop({ default: 20 }) height!: number | string;

  get warningState() {
    return this.state ? this.activeColor : this.inactiveColor;
  }
}
