
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { ITasksObject } from '@/types/partnerWorkspace.types';
import { formatIsoDate } from '@/utils/utilsFunctions';

/**
 * Displays rows for TasksTable
 */
@Component({
  methods: { formatIsoDate },
})
export default class DataTableRowHandler extends mixins(Validation) {
  @Prop({ default: () => [] }) tasks!: ITasksObject[];
  @Prop({ default: {} }) item!: any;
  @Prop({ default: () => [] }) headers!: any;

  rowsWithExtraFormatting: string[] = ['dependencies', 'start', 'end', 'actions'];

  columnName(header: any) {
    return `item.${header.value}`;
  }

  valueOfColumn(item: any, header: any) {
    return item[header];
  }

  /**
   * Format dependencies to be displayed in the table
   * @param dependencies array of dependencies {value: string; text: string}
   * @returns string of dependencies separated by comma
   */
  getDependenciesFormatted(dependencies: string[]) {
    const strings: string[] = [];
    dependencies.forEach((dependency: string) => {
      const name = this.getNameOfDependenciesByTaskId(dependency) ?? '';
      strings.push(name);
    });
    return strings.join(', ');
  }

  /**
   * Get the name of the task by its id
   * @param id id of the task
   */
  getNameOfDependenciesByTaskId(id: string) {
    const task = this.tasks.find((task) => task.id === id);
    return task?.name;
  }

  /**
   * Get the length of dependencies
   * @param dependencies dependencies of the task
   */
  getLengthOfDependencies(dependencies: string) {
    return dependencies.length;
  }
}
