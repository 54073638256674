
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import ProjectService from '@/services/ProjectService';
import { envAppShowProjectTypeSelection, envProjectOpenSubpage } from '@/utils/env';
import { mixins } from 'vue-class-component';
import devicesTypes from '@/store/modules/devices/settings/devicesTypes';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import ProjectTagsField from '@/ui/components/components/ProjectTagsField.vue';
import { Validation } from '@/ui/mixins/validation';
import { IProject } from '@/types/project.types';
import { IAppState } from '@/store/modules/app/types';
import CollectionService from '@/services/CollectionService';
import DeviceService from '@/services/DeviceService';

/**
 * Component that allows to create a new project
 */
@Component({
  components: {
    ModalWindow,
    ProjectTagsField,
  },
})
export default class CreateProject extends mixins(Validation) {
  @State('app') appState!: IAppState;
  @Getter('projects/projects') projects!: IProject[];
  @Action('projects/createProject') createProject!: (project: Record<string, any>) => Promise<IProject>;
  @Action('projects/loadProjects') loadProjects!: () => Promise<void>;

  valid = false;
  isTooltip = false;
  project: Record<string, string> = {
    name: '',
    description: '',
  };
  projectFilterTags: string[] = [];

  isCustomLabel = envAppShowProjectTypeSelection;
  defaultDevice = ['Robot', 'Survey Client'];
  selectedDevice = '';
  defaultDeviceName = '';

  get isConfirmationDialog() {
    return !!this.projectFilterTags.length || !!this.project.name || !!this.project.description;
  }

  get currentUserMail() {
    return this.appState.user.email;
  }

  get currentLang() {
    return this.$i18n.locale;
  }

  @Watch('currentLang', { deep: true })
  handleLangChange() {
    this.validate();
  }

  async onDialogStatusChange(status: boolean) {
    // clean local project + projectFilterTags when close dialog
    if (!status) {
      Object.keys(this.project).forEach((prop: string) => this.project[prop] = '');
      this.projectFilterTags = [];
    } else {
      await this.$nextTick();
      this.validate();
    }
  }

  handleChipsChange(value: string[]) {
    this.projectFilterTags = value;
  }

  validate() {
    (this.$refs.form as any)?.validate();
  }

  /**
   * Creates new project with certain parameters
   */
  async createNewProject() {
    let resultNewProject: IProject | undefined;
    if (this.project.name.length) {
      const newProject: Record<string, any> = {
        name: this.project.name,
        meta: {
          description: this.project.description,
          isDNDActive: false,
          isAreasMiniView: false,
          energyPrice: {
            buyPrice: 0.4,
            sellPrice: 0.1,
          },
          projectCurrency: '€',
        },
        connectivity: {
          enabled: true,
          actions: [{
            type: 'alert',
            params: {
              type: 2,
              body: 'Connectivity Check. Project is currently not online',
            },
          }, {
            type: 'email',
            params: {
              recipients: [this.currentUserMail],
              subject: `${this.project.name}: Connectivity Check`,
              body: 'Project is currently offline',
            },
          },
          ],
        },
      };

      if (this.projectFilterTags.length !== 0) {
        newProject.meta.tags = this.projectFilterTags;
      }
      if (envAppShowProjectTypeSelection === false) {
        resultNewProject = await this.createProject(newProject);
      } else {
        newProject.meta.defaultDevice = this.selectedDevice;

        resultNewProject = await ProjectService.createProject(newProject as IProject);
        await this.loadProjects();

        if (resultNewProject?.id !== undefined) {
          const defaultRoomObject = {
            cover: '61769',
            covertype: 'icon',
            name: 'Area 1',
            room: {
              meta: {
                cover: '61769',
                devicesPositions: undefined,
              },
            },
          };

          // default room gets created
          const resultRoom = await CollectionService.createCollection(resultNewProject.id, defaultRoomObject);
          // contains the collectionID

          // creates the selected default device based on the Device selected
          if (this.selectedDevice === 'Robot') {
            const defaultRobotObject = {
              collection_id: resultRoom.id,
              data: {
                mappings: Object.fromEntries(Object.keys(devicesTypes.Robot.mappings).map(e => [e, ''])),
                meta: {
                  deviceSchema: devicesTypes.Robot.devicesSchemas,
                },
                type: 'Robot',
              },
              name: this.defaultDeviceName,
            };
            await DeviceService.createDevice(resultNewProject.id, defaultRobotObject);
          } else {
            const defaultSurveyClientObject = {
              collection_id: resultRoom.id,
              data: {
                mappings: Object.fromEntries(Object.keys(devicesTypes.SurveyClient.mappings).map(e => [e, ''])),
                meta: {
                  deviceSchema: devicesTypes.SurveyClient.devicesSchemas,
                },
                type: 'SurveyClient',
              },
              name: this.defaultDeviceName,
            };
            await DeviceService.createDevice(resultNewProject.id, defaultSurveyClientObject);
          }
        }
      }
    }
    (this.$refs.ModalWindowInstance as ModalWindow).onConfirmationDialogClose();
    // enter the create project
    if (resultNewProject !== undefined) {
      await new Promise(resolve => setTimeout(resolve, 250));
      await this.$router.push(`/projects/${resultNewProject.id}${envProjectOpenSubpage}`);
    }
  }
}
