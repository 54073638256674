
import { Component, Prop, Watch } from 'vue-property-decorator';
import { connectWithLineItems } from '@/utils/installationWizardUtilsFunctions';
import { Getter, Mutation } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import EnergyVisualisationPreview
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyVisualisationPreview.vue';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import { itemsContainValue } from '@/utils/utilsFunctions';
import {
  IIncludedSystemsChargeStationDefinition,
  IIncludedSystemsTypes,
} from '@/types/wizards/installationWizard.types';
import {
  chargingStationV2Feature,
  mennekesEnerchargeDate,
  newChargeStationLimitDate,
  plcLevelVersionDate,
  plcVersionDate,
} from '@/utils/versionManagementUtils';
import { ChargeStationType, IProject } from '@/types/project.types';
import {
  chargeStationTypes,
  getChargeStationTypeInformationBySystemType,
} from '@/ui/components/wizards/loggerWizard/wizardSettings/systemTypes';
import {
  webastoChargeStationTypes,
} from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { PlcPerformanceCategorie } from '@/utils/plcPerformanceLimits';
import { debounce } from 'lodash';
import {
  limitWaidmullerChargeStations,
  limitWebastoChargeStations,
} from '../../../wizardSettings/wizardLimits';
import { SYSTEM_IP_RANGE_START } from '../../../wizardSettings/wizardConstants';

@Component({
  computed: {
    PlcPerformanceCategorie() {
      return PlcPerformanceCategorie;
    },
    SYSTEM_IP_RANGE_START() {
      return SYSTEM_IP_RANGE_START;
    },
    webastoChargeStationTypes() {
      return webastoChargeStationTypes;
    },
  },
  methods: {
    mennekesEnerchargeDate() {
      return mennekesEnerchargeDate;
    },
    plcLevelVersionDate() {
      return plcLevelVersionDate;
    },
    itemsContainValue,
    getChargeStationTypeInformationBySystemType,
  },

  components: {
    InfoTooltip,
    EnergyVisualisationPreview,
  },
})
export default class ChargeStationComponentsSetting extends mixins(Validation, BasicControl) {
  @Prop({default: {}}) includedSystemsTypes!: IIncludedSystemsTypes;
  @Getter('projects/project') project!: IProject;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('installationWizard/connectedLines') connectedLines!: number[];
  @Getter('installationWizard/getDisablePilotDevices') getDisablePilotDevices!: boolean;
  @Mutation('installationWizard/resetChargeStationDefinition') resetChargeStationDefinition!: (payload: {
    index: number;
  }) => void;
  @Mutation('installationWizard/handleIncludedSystemsTypesSystemDefinitionProps') handleIncludedSystemsTypesSystemDefinitionProps!: (
    {systemName, systemIndex, prop, value}: any
  ) => void;
  rerenderKey = 0;
  valid = false;
  doesHover = false;
  localConnectWithLineItems = connectWithLineItems();
  applyOnNextValues: { [key: number]: number | null } = {};
  enableApply: { [key: number]: boolean } = {};

  @Watch('includedSystemsTypes.charge_station.definition', {deep: true})
  updateValidation() {
    this.$nextTick(() => {
      this.validate();
      this.initializeApplyProperties();
      this.rerenderKey++;
    });
  }

  filterConnectWithLine(systemType: any[]): any[] {
    return systemType.map((element: any) => {
      return typeof element.connectWithLine !== 'string' ? element.connectWithLine : undefined;
    }).filter(element => element !== undefined);
  }

  getFilteredApplyOnNextItems(index: number) {
    const totalItems = Array.from(
      {length: this.includedSystemsTypes.charge_station.definition.length - 1 - index},
      (_, i) => ({value: i + 1, text: i + 1})
    );

    // Check if the current station is a twinpoint and filter the items to show every second value
    if (this.isDualPointStation(this.includedSystemsTypes.charge_station.definition[index].systemType)) {
      // only display every second number and if the item is the last index also filter it out
      return totalItems.filter(item => (item.value % 2 === 0) && !(index + item.value === this.includedSystemsTypes.charge_station.definition.length - 1));
    }

    // If it's not a twinpoint station, return all items
    return totalItems;
  }

  initializeApplyProperties() {
    this.includedSystemsTypes.charge_station.definition.forEach((_, index) => {
      if (!Object.hasOwnProperty.call(this.enableApply, index)) {
        this.$set(this.enableApply, index, false);
      }
      if (!Object.hasOwnProperty.call(this.applyOnNextValues, index)) {
        this.$set(this.applyOnNextValues, index, null);
      }
    });
  }

  getIpAddress(index: number) {
    return (index < 10) ? SYSTEM_IP_RANGE_START.charge_station + index : SYSTEM_IP_RANGE_START.big_plc_charge_station + (index - 10);
  }

  onIntersect() {
    this.$nextTick(() => {
      this.validate();
    });
  }

  // debounce onIntersect
  debouncedOnIntersect = debounce(this.onIntersect, 100);

  /**
   * Check change in length of charge station definition
   */
  @Watch('includedSystemsTypes.charge_station.definition', {deep: true})
  handleDefinitionChange() {
    this.validateChargeStationObject();
  }

  isObjectValid = false;

  validateChargeStationObject() {
    this.isObjectValid = this.includedSystemsTypes.charge_station.definition.every((chargeStation: IIncludedSystemsChargeStationDefinition) => {
      const chargeStationInformations = getChargeStationTypeInformationBySystemType(chargeStation.systemType);
      if (!chargeStationInformations) {
        // if systemType is not set return false
        return chargeStation.occupiedByTwinPoint;
      }
      if (chargeStationInformations.isExternal) {
        // for external charge stations we need to check if systemType & connectWithLine is set
        return chargeStation.systemType !== '' && chargeStation.connectWithLine !== '';
      } else {
        // for internal charge stations we only need to check if systemType is set
        return chargeStation.systemType !== '';
      }
    });
  }

  get showChargeStationV2Feature() {
    return plcVersionDate(this.project).getTime() > chargingStationV2Feature.getTime();
  }

  get plcLevelVersionDateFeature() {
    return plcVersionDate(this.project).getTime() > plcLevelVersionDate.getTime();
  }

  isDualPointStation(systemType: string): boolean {
    return systemType === 'AmtronTC22' || systemType === 'AmedioPro22';
  }

  get hasDuplicateValue(): boolean | string {
    const heatingPumpArray: any[] = this.filterConnectWithLine(this.includedSystemsTypes.heating_pump.definition);
    const chargeStationArray: any[] = this.filterConnectWithLine(this.includedSystemsTypes.charge_station.definition);
    const bigConsumerArray: any[] = this.filterConnectWithLine(this.includedSystemsTypes.big_consumer.definition);

    const array = [heatingPumpArray, chargeStationArray, bigConsumerArray].flat();
    // Create an object to store the count of each value
    const count: any = {};

    // Iterate over each element in the array
    for (let i = 0; i < array.length; i++) {
      const value = array[i];

      // If the value is already in the count object, it's a duplicate
      if (count[value]) {
        return this.$t('installationWizard.defineComponents.componentsPage.duplicatePilotLineSelection').toString();
      }

      // Otherwise, add the value to the count object
      count[value] = 1;
    }

    // No duplicates found
    return true;
  }

  // checks if limit of Webasto Charge Stations is reached
  get isLimitOfWebastoStationsReached() {
    const arrayWebasto = this.includedSystemsTypes.charge_station.definition.filter((value: IIncludedSystemsChargeStationDefinition) => {
      return ['Webasto11', 'Webasto22', ChargeStationType.WebastoNext11, ChargeStationType.WebastoNext22, ChargeStationType.WebastoUnite11, ChargeStationType.WebastoUnite22].includes(value.systemType);
    });
    return arrayWebasto.length >= limitWebastoChargeStations;
  }

  get isNewChargeStationPlcVersion() {
    return (plcVersionDate(this.project).getTime() > newChargeStationLimitDate.getTime()) && (this.isDeye || this.isSolarmax);
  }

  get isMennekesEnerchargeVersion() {
    return plcVersionDate(this.project).getTime() > mennekesEnerchargeDate.getTime() && (this.isDeye || this.isSolarmax);
  }

  // checks if limit of Weidmüller Charge Stations is reached
  get isLimitOfWeidmuellerStationsReached() {
    if (this.isNewChargeStationPlcVersion) return false;
    const arrayWeidmueller = this.includedSystemsTypes.charge_station.definition.filter((value: IIncludedSystemsChargeStationDefinition) => {
      return value.systemType.includes('Weidmüller') && value.systemType.includes('external');
    });
    return arrayWeidmueller.length >= limitWaidmullerChargeStations;
  }

  // returns all system types that can be selected for the current project and state of allready selected charge stations
  availableChargeStationTypes(index: number) {
    const filteredStations = [...chargeStationTypes].filter(systemTypeElement => systemTypeElement.availableFrom.getTime() < plcVersionDate(this.project).getTime());

    return filteredStations.map(system => {
      const isLastDefinition = this.includedSystemsTypes.charge_station.definition.length === index + 1;
      const isNextStationOccupied = isLastDefinition ||
        (this.includedSystemsTypes.charge_station.definition[index + 1].systemType !== '' && !this.includedSystemsTypes.charge_station.definition[index + 1].occupiedByTwinPoint);
      const isTwinChargeStation = system.name.includes('TwinCharge') || system.name.includes('AMEDIO');

      let isDisabled = false;

      // Disable Weidmüller stations if limit is reached
      if (this.isLimitOfWeidmuellerStationsReached && system.name.includes('Weidmüller') && system.value.includes('external')) {
        isDisabled = true;
      }

      // Disable TwinCharge stations if the next station is occupied
      if (isNextStationOccupied && isTwinChargeStation) {
        isDisabled = true;
      }

      // Disable Webasto stations if limit is reached
      if (system.name.includes('Webasto')) {
        isDisabled = plcVersionDate(this.project).getTime() < newChargeStationLimitDate.getTime() && this.isLimitOfWebastoStationsReached;
      }

      return {...system, disabled: isDisabled};
    });
  }

  // updates IncludedSystemsDefinitions in Store
  handleChange(system_index: number | string, prop: string, value: any) {
    const index = typeof system_index === 'string' ? parseInt(system_index, 10) : system_index;
    if (prop === 'systemType') {
      this.handleClear(index);
    }

    if (prop === 'applyOnNext') {
      if (!this.enableApply[index] || this.applyOnNextValues[index] === null) return;

      const currentStation = {...this.includedSystemsTypes.charge_station.definition[index]};
      const applyValue = this.applyOnNextValues[index];

      if (applyValue === null) return;
      for (let i = 1; i <= applyValue; i++) {
        if (index + i < this.includedSystemsTypes.charge_station.definition.length) {
          if (this.isDualPointStation(currentStation.systemType)) {
            this.$set(this.includedSystemsTypes.charge_station.definition, (index + i) + 1, {
              ...currentStation,
              connectWithLine: ''
            });
            this.handleIncludedSystemsTypesSystemDefinitionProps({
              systemName: 'charge_station',
              systemIndex: (index + i) + 2,
              prop: 'occupiedByTwinPoint',
              value: true,
            });
            i += 1;
          } else {
            const newStation = {...currentStation, connectWithLine: ''};
            this.$set(this.includedSystemsTypes.charge_station.definition, index + i, newStation);
          }
        }
      }
      this.$set(this.applyOnNextValues, index, null);
      this.$set(this.enableApply, index, false);
    } else {
      if (prop === 'systemType') {
        this.handleClear(index);

        this.$set(this.includedSystemsTypes.charge_station.definition[index], 'systemType', value);

        const chargeStationInformations = getChargeStationTypeInformationBySystemType(value);
        if (chargeStationInformations) {
          if (this.getDisablePilotDevices && chargeStationInformations.isExternal) {
            this.$set(this.includedSystemsTypes.charge_station.definition[index], 'connectWithLine', 'noLine');
          }

          if (chargeStationInformations.type === 'Webasto' && this.showChargeStationV2Feature) {
            this.$set(this.includedSystemsTypes.charge_station.definition[index], 'webastoType', 0);
          }
        }
      } else {
        this.$set(this.includedSystemsTypes.charge_station.definition[index], prop, value);
      }

      if (this.isDualPointStation(value)) {
        if (index < this.includedSystemsTypes.charge_station.definition.length - 1) {
          if (prop === 'systemType') {
            this.handleIncludedSystemsTypesSystemDefinitionProps({
              systemName: 'charge_station',
              systemIndex: index + 1,
              prop: 'occupiedByTwinPoint',
              value: true,
            });
          }
          this.handleIncludedSystemsTypesSystemDefinitionProps({
            systemName: 'charge_station',
            systemIndex: index + 1,
            prop,
            value,
          });
        }
      }
    }

    // if prop is something other than systemtype and the charge station on the system is a dual point station, update the next charge station as well
    const chargeStationDefinition = this.includedSystemsTypes.charge_station.definition[index];
    const isDualPointStation = ['AmtronTC22', 'AmedioPro22'].includes(chargeStationDefinition.systemType);
    if (isDualPointStation) {
      this.handleIncludedSystemsTypesSystemDefinitionProps({
        systemName: 'charge_station',
        systemIndex: index + 1,
        prop,
        value,
      });
    }

    this.validate();
    this.rerenderKey++;
  }

  handleClear(index: number) {
    // if clearing a occupied twin point, also clear the previous charge point
    if (this.includedSystemsTypes.charge_station.definition[index].occupiedByTwinPoint) {
      this.includedSystemsTypes.charge_station.definition[index - 1].systemType = '';
      this.includedSystemsTypes.charge_station.definition[index - 1].connectWithLine = '';
      this.includedSystemsTypes.charge_station.definition[index - 1].occupiedByTwinPoint = false;
    }

    // If clearing a dual-point station, also clear the next charge point
    if (this.isDualPointStation(this.includedSystemsTypes.charge_station.definition[index].systemType)) {
      this.includedSystemsTypes.charge_station.definition[index + 1].systemType = '';
      this.includedSystemsTypes.charge_station.definition[index + 1].connectWithLine = '';
      this.includedSystemsTypes.charge_station.definition[index + 1].occupiedByTwinPoint = false;
    }

    this.includedSystemsTypes.charge_station.definition[index].systemType = '';
    this.includedSystemsTypes.charge_station.definition[index].connectWithLine = '';
    this.includedSystemsTypes.charge_station.definition[index].occupiedByTwinPoint = false;

    // Emit an event to notify parent component
    this.$emit('chargeStationCleared', index);

    this.$nextTick(() => {
      this.validate();
      this.rerenderKey++;
    });
  }

  @Watch('connectedLines', {deep: true})
  updateLineItems() {
    this.localConnectWithLineItems = this.localConnectWithLineItems.map((item: any) => {
      item.disabled = this.connectedLines.includes(item.value);
      return item;
    });
  }

  validate() {
    (this.$refs.form as any).validate();
    // TODO: remove this when PLC performance level is implemented on SPS side
    // if (this.plcLevelVersionDateFeature && this.project.meta.controller.plcPerformanceLevel === PlcPerformanceCategorie.high) {
    //   this.$emit('validationStatus', {
    //     system: 'charge_station',
    //     status: this.valid && this.isObjectValid,
    //   });
    // } else {
    //   this.$emit('validationStatus', {system: 'charge_station', status: this.valid});
    // }
    this.$emit('validationStatus', {system: 'charge_station', status: this.valid});
  }

  mounted() {
    this.$nextTick(() => {
      this.validate();
      this.initializeApplyProperties();
    });
    this.updateLineItems();
    this.validateChargeStationObject();
  }
}
