
import { Vue, Component, Prop } from 'vue-property-decorator';
import Preview from '@/ui/components/devices/components/EnergyParts/Preview.vue';
import SystemModalWindowView from '@/ui/components/devices/components/EnergyParts/SystemModalWindowView.vue';
import TimeSlider from '@/ui/components/devices/components/EnergyParts/TimeSlider.vue';
import AnimatedLine from '@/ui/components/devices/components/EnergyParts/AnimatedLine/index.vue';
import CentralEndpoint from '@/ui/components/devices/components/EnergyParts/CentralEndpoint.vue';
import { throttle } from 'lodash';

@Component({
  components: {
    Preview,
    SystemModalWindowView,
    TimeSlider,
    AnimatedLine,
    CentralEndpoint,
  },
})
export default class ForecastView extends Vue {
  @Prop({ default: null }) groupSliderState!: any;
  @Prop({ default: null }) forecastData!: any;
  @Prop({ default: true }) showTimeSlider!: any;
  @Prop({ default: false }) isPreviewManageModalOpen!: boolean;

  canvasSize: any = null
  canvasCenter: any = null
  canvasLines: any = {}
  systemsList = ['pv', 'generator', 'battery', 'grid', 'house',
    'charge_station', 'electric_heating', 'heating_pump', 'big_consumer']

  sliders: any = {
    pv: { slider: 0 },
    generator: { slider: 0 },
    grid: { slider: 0 },
    battery: { slider: 0 },
    house: { slider: 0 },
    charge_station: { slider: 0 },
    electric_heating: { slider: 0 },
    heating_pump: { slider: 0 },
    big_consumer: { slider: 0 },
  }

  instancesCanvasSize: any = null
  instancesCanvasCenter: any = null
  instancesCanvasLines: any = {}

  // systems
  producers = [
    {
      system: 'generator',
      previewIcon: 'generator',
    },
    {
      system: 'pv',
      previewIcon: 'photovoltaic',
    },
  ]
  producerConsumers = {
    battery: {
      system: 'battery',
    },
    grid: {
      system: 'grid',
      previewIcon: 'main-connection',
    },
  }
  consumers = [
    {
      system: 'house',
      previewIcon: 'house-consumption',
    },
    {
      system: 'charge_station',
      previewIcon: 'electric-charging-station',
    },
    {
      system: 'electric_heating',
      previewIcon: 'electric-charging-boiler',
    },
    {
      system: 'heating_pump',
      previewIcon: 'heating-capacity',
    },
    {
      system: 'big_consumer',
      previewIcon: 'socket-switch',
    },
  ]

  /**
   * Load line if all coords and sizes ready
   * @return {boolean} load line status
   */
  get loadLine() {
    return !!this.canvasSize && !!this.canvasCenter && !!Object.values(this.canvasLines);
  }

  /**
   * Load line if all coords and sizes ready
   * @return {boolean} load line status
   */
  get isDataForInstancesAnimatedLineReady() {
    return !!this.instancesCanvasSize && !!this.instancesCanvasCenter && !!Object.values(this.instancesCanvasLines);
  }

  get sumPV() {
    const { pv1, pv2 } = this.forecastData;
    const arr: any = Object.values({ pv1, pv2 }).map((system: any) => system[this.groupSliderState] || [null, null]);
    const res: any = arr.map((el: any) => el[1]);
    const num: any = res.reduce((a: number, b: number) => a + b);
    return +num.toFixed(2);
  }
  get sumGenerator() {
    const { generator1, generator2 } = this.forecastData;
    const arr: any = Object.values({ generator1, generator2 }).map((system: any) => system[this.groupSliderState] || [null, null]);
    const res: any = arr.map((el: any) => el[1]);
    const num: any = res.reduce((a: number, b: number) => a + b);
    return +num.toFixed(2);
  }
  get sumBattery() {
    const { battery1, battery2 } = this.forecastData;
    const arr: any = Object.values({ battery1, battery2 }).map((system: any) => system[this.groupSliderState] || [null, null]);
    const res: any = arr.map((el: any) => el[1]);
    const num: any = res.reduce((a: number, b: number) => a + b);
    return +num.toFixed(2);
  }
  get sumHouse() {
    const { house1, house2 } = this.forecastData;
    const arr: any = Object.values({ house1, house2 }).map((system: any) => system[this.groupSliderState] || [null, null]);
    const res: any = arr.map((el: any) => el[1]);
    const num: any = res.reduce((a: number, b: number) => a + b);
    return +num.toFixed(2);
  }
  get sumChargeStation() {
    const { charge_station1, charge_station2 } = this.forecastData;
    const arr: any = Object.values({ charge_station1, charge_station2 }).map((system: any) => system[this.groupSliderState] || [null, null]);
    const res: any = arr.map((el: any) => el[1]);
    const num: any = res.reduce((a: number, b: number) => a + b);
    return +num.toFixed(2);
  }
  get sumElectricHeating() {
    const { electric_heating1, electric_heating2 } = this.forecastData;
    const arr: any = Object.values({ electric_heating1, electric_heating2 }).map((system: any) => system[this.groupSliderState] || [null, null]);
    const res: any = arr.map((el: any) => el[1]);
    const num: any = res.reduce((a: number, b: number) => a + b);
    return +num.toFixed(2);
  }
  get sumHeatingPump() {
    const { heating_pump1, heating_pump2 } = this.forecastData;
    const arr: any = Object.values({ heating_pump1, heating_pump2 }).map((system: any) => system[this.groupSliderState] || [null, null]);
    const res: any = arr.map((el: any) => el[1]);
    const num: any = res.reduce((a: number, b: number) => a + b);
    return +num.toFixed(2);
  }
  get sumBigConsumer() {
    const { big_consumer1, big_consumer2 } = this.forecastData;
    const arr: any = Object.values({ big_consumer1, big_consumer2 }).map((system: any) => system[this.groupSliderState] || [null, null]);
    const res: any = arr.map((el: any) => el[1]);
    const num: any = res.reduce((a: number, b: number) => a + b);
    return +num.toFixed(2);
  }

  get allGroupSliderStates() {
    return {
      pv: this.sumPV,
      generator: this.sumGenerator,
      battery: this.sumBattery,
      house: this.sumHouse,
      charge_station: this.sumChargeStation,
      electric_heating: this.sumElectricHeating,
      heating_pump: this.sumHeatingPump,
      big_consumer: this.sumBigConsumer,
    };
  }

  handleGetCoords() {
    this.getCoords();
  }

  /**
   * Defines coordinates of systems, Central Energy Point.
   * Use vm.$refs to retrieve references to elements.
   * Then it goes through the list of references and creates an options object for each one.
   */
  getCoords() {
    const central: any = this.$refs.centralEndpoint;
    const actualViewRef: any = this.$refs.canvas;
    this.canvasSize = { width: actualViewRef?.clientWidth, height: actualViewRef?.clientHeight };
    this.canvasCenter = {
      x: central.offsetLeft + central.offsetWidth / 2,
      y: central.offsetTop + central.offsetHeight / 2,
      w: central.offsetWidth,
      h: central.offsetHeight,
    };
    this.systemsList.forEach((system: string) => {
      const target: any = this.$refs[system];
      if (Array.isArray(target)) {
        this.canvasLines[system] = {
          key: system,
          x: target[0].offsetLeft + target[0].offsetWidth / 2,
          y: target[0].offsetTop + target[0].offsetHeight / 2,
          w: target[0].offsetWidth,
          h: target[0].offsetHeight,
        };
      } else {
        this.canvasLines[system] = {
          key: system,
          x: target.offsetLeft + target.offsetWidth / 2,
          y: target.offsetTop + target.offsetHeight / 2,
          w: target.offsetWidth,
          h: target.offsetHeight,
        };
      }
    });
  }
  getCoordsThrottle = throttle(this.getCoords, 500)

  /**
   * Defines coordinates of systems, Central Energy Point.
   * Use vm.$refs to retrieve references to elements.
   * Then it goes through the list of references and creates an options object for each one.
   */
  getInstancesCoords() {
    const central: any = this.$refs.instancesCentralEndpoint;
    const actualViewRef: any = this.$refs.instancesCanvas;
    if (central) {
      const arrItem: any = Array.isArray(central) ? central[0] : central;
      this.instancesCanvasCenter = {
        x: arrItem.offsetLeft + arrItem.offsetWidth / 2,
        y: arrItem.offsetTop + arrItem.offsetHeight / 2,
        w: arrItem.offsetWidth,
        h: arrItem.offsetHeight,
      };
    }
    if (actualViewRef) {
      const arrItem: any = Array.isArray(actualViewRef) ? actualViewRef[0] : actualViewRef;
      this.instancesCanvasSize = { width: arrItem?.clientWidth, height: arrItem?.clientHeight };
    }

    ['instance-0', 'instance-1'].forEach((system: any) => {
      const target: any = this.$refs[system];
      if (!target?.length) return;

      this.instancesCanvasLines[system] = {
        key: system,
        x: target[0].offsetLeft + target[0].offsetWidth / 2,
        y: target[0].offsetTop + target[0].offsetHeight / 2,
        w: target[0].offsetWidth,
        h: target[0].offsetHeight,
      };
    });
  }
  getInstancesCoordsThrottle = throttle(this.getInstancesCoords, 500)

  /**
   * When you open the system grid window,
   * get the current coordinates of the systems, Central Energy Point.
   * Define sizes.
   * @param {boolean} isContentVisible system grid visible status
   */
  async handleSystemModalWindowContent(isContentVisible: boolean) {
    if (isContentVisible) {
      await this.$nextTick();
      window.addEventListener('resize', this.getInstancesCoordsThrottle);
      this.getInstancesCoords();
    } else {
      window.removeEventListener('resize', this.getInstancesCoordsThrottle);
      this.instancesCanvasSize = null;
      this.instancesCanvasCenter = null;
      this.instancesCanvasLines = {};
    }
  }

  getCurrentValue(systemInstance: string, sliderState: number) {
    return this.forecastData[systemInstance][sliderState][1];
  }

  async mounted() {
    window.addEventListener('resize', this.getCoordsThrottle);
    this.getCoords();
    if (this.isPreviewManageModalOpen) {
      await this.$nextTick();
      this.getCoords();
    }
  }
  destroyed() {
    window.removeEventListener('resize', this.getCoordsThrottle);
  }
}
