
import { Vue, Component, Inject } from 'vue-property-decorator';
import OfflineChart from '@/ui/components/devices/previews/charts/components/OfflineChart.vue';

@Component({
  components: {
    OfflineChart,
  },
})
export default class ChartProdCons extends Vue {
  @Inject({ from: 'chartColors' }) injectedChartColors!: string[];

  tab = null

  // contains Chart Object
  get mockChartData() {
    let currentChartOptions: any = [];
    if (this.tab === 0) {
      currentChartOptions = [
        {
          agg: 'first',
          name: this.$t('mlModel.EMS.charts.chartProdConst.powerPV.actualPVPower'),
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kWh',
          var: '',
        }, {
          agg: 'first',
          name: this.$t('mlModel.EMS.charts.chartProdConst.powerPV.calculatedPVPower'),
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kW',
          var: '',
        },
      ];
    } else {
      currentChartOptions = [
        {
          agg: 'first',
          name: this.$t('mlModel.EMS.charts.chartProdConst.powerConsumption.actualPower'),
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kWh',
          var: '',
        }, {
          agg: 'first',
          name: this.$t('mlModel.EMS.charts.chartProdConst.powerConsumption.calculatedPower'),
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kW',
          var: '',
        },
      ];
    }

    const currentData = {
      data: {
        chartOptions: currentChartOptions,
        selectedStackingOptions: 'normal',
        selectedWidth: 'full',
        type: 'chart',
      },
      created_at: '2021-10-07T07:43:33.554983Z',
    };
    return currentData;
  }

  get items() {
    return [
      {
        id: 'pvPower',
        title: this.$t('mlModel.EMS.charts.chartProdConst.powerPV.title'),
      },
      {
        id: 'consumptionPower',
        title: this.$t('mlModel.EMS.charts.chartProdConst.powerConsumption.title'),
      },
    ];
  }
}
