
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import MapBanner from '@/ui/components/components/MapBanner.vue';
import CreateProject from '@/ui/components/modals/CreateProject.vue';
import ProjectsListFlexGrid from '@/ui/components/lists/ProjectsListFlexGrid/index.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import ProjectItemPlaceholder
  from '@/ui/components/lists/ProjectsListFlexGrid/ProjectItemPlaceholder.vue';
import { IProjectsState } from '@/store/modules/projects/types';
import { IProject } from '@/types/project.types';

Component.registerHooks(['beforeRouteEnter']);

/**
 * Home page that shows projects
 */
@Component({
  components: {
    MapBanner,
    CreateProject,
    ProjectsListFlexGrid,
    CircleSpinner,
    ProjectItemPlaceholder,
  },
})
export default class Home extends Vue {
  @State('projects') projectsState!: IProjectsState;
  @Action('projects/loadProjects') loadProjects!: () => Promise<void>;
  @Mutation('router/setHomeDataLoaded') setHomeDataLoaded!: (data: boolean) => void;
  @Getter('router/scrollPosition') scrollPosition!: { x: number; y: number };
  @Getter('router/homeDataLoaded') homeDataLoaded!: boolean;
  @Getter('projects/projects') projects!: IProject[];

  @Watch('homeDataLoaded')
  onHomeDataLoaded() {
    if (this.homeDataLoaded && !this.projectsState.projectsLoader) {
      setTimeout(() => {
        this.scrollToSavedPosition();
      }, 100);
    }
  }

  get projectsLoader() {
    return this.projectsState.projectsLoader;
  }

  async created() {
    await this.loadProjects();
  }

  scrollToSavedPosition() {
    const savedPosition = this.scrollPosition;
    window.scrollTo({
      top: savedPosition.y,
      left: savedPosition.x,
      behavior: 'smooth',
    });
  }

  beforeDestroy() {
    this.setHomeDataLoaded(false);
  }
}
