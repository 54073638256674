import { BatteryType } from './systemTypes';

// Contains all the variable names that should be send based on what system type is selected inside the project settings
export const variablesForBatteryTypes: Record<BatteryType, string[]> = {
  [BatteryType.AZZURRO]: [
    'prgEnergy.fbI.rMaxPowerInverter',
    'prgEnergy.fbI.bPVPowerExt',
  ],
  [BatteryType.DEYE]: [
  ],
  [BatteryType.STANDALONE]: [
  ],
  [BatteryType.SOLARMAX]: [
  ],
  [BatteryType.MobileBatteryDeye]: [
  ],
};

export function shouldSendVariableForType(type: BatteryType | undefined | string, variableName: string) {
  return type !== undefined && variablesForBatteryTypes[type as BatteryType].includes(variableName);
}
