
import { Component, Prop, Watch } from 'vue-property-decorator';
import BasicControl, { SendingState } from '@/ui/components/devices/devices/BasicControl';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';
import { mixins } from 'vue-class-component';
import { defaultFeedbackTimeoutMilliSeconds } from '@/utils/constants';

/**
 * Component that represent Switch Basic Control
 */
@Component({
  components: {
    FeedbackProvider,
  },
})
export default class SwitchBase extends mixins(BasicControl) {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop({ default: defaultFeedbackTimeoutMilliSeconds }) feedBackTimeout!: number;

  timer?: NodeJS.Timeout;
  localState?: boolean;

  @Watch('state')
  onChange(val: boolean) {
    clearTimeout(this.timer);
  }

  /**
   * Used to disable the Base component if there is no variable set in the mappings of the device.
   */
  get isNotMapped() {
    return this.mappingsClean.off === ''
      || this.mappingsClean.on === ''
      || this.mappingsClean.state === '';
  }

  /**
   * Returns state status according to variables values
   * @return {boolean} state status
   */
  get state() {
    if (this.mappingsClean.off === '' && this.mappingsClean.on === '' && this.mappingsClean.state === '') {
      return false;
    }
    if (this.mappingsClean) {
      return this.measurements.get(this.mappingsClean.state) === 1;
    }

    return false;
  }

  @Watch('state')
  onStateChange(val: boolean) {
    if (this.sendingState === SendingState.Sending && this.localState === val) {
      this.onSendSuccess();
      clearTimeout(this.timer);
    }
  }

  /**
   * If the current status is the same as the previous status launches fn1 then fn2
   * @param {boolean} snapshot state status snapshot
   * @param {function} fn1 switchOn or switchOff functions
   * @param {function} fn2 switchOn or switchOff functions
   */
  timeoutFn(fn1: any, fn2: any) {
    return setTimeout(() => {
      fn1();
      fn2();
      this.onSendError();
    }, this.feedBackTimeout);
  }

  switchOn() {
    this.send([{ v: 1, n: this.mappingsClean.on, u: '' }]);
    this.send([{ v: 0, n: this.mappingsClean.on, u: '' }]);
  }

  switchOff() {
    this.send([{ v: 1, n: this.mappingsClean.off, u: '' }]);
    this.send([{ v: 0, n: this.mappingsClean.off, u: '' }]);
  }

  handleState(event: MouseEvent) {
    // https://stackoverflow.com/q/51000149
    event.stopPropagation();
    event.preventDefault();

    // new desired local state is the opposite of the current state
    this.localState = !this.state;
    this.onSendStart();
    if (this.state) {
      this.switchOff();
      this.timer = this.timeoutFn(this.switchOn, this.switchOff);
    } else {
      this.switchOn();
      this.timer = this.timeoutFn(this.switchOn, this.switchOff);
    }
  }

  beforeDestroy() {
    clearTimeout(this.timer);
  }
}
