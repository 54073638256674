import { envDevices } from '@/utils/env';
import devicesTypes from './devicesTypes';

// device types filtered by VUE_APP_DEVICES of config.js
const devicesTypesFiltered: any = {};
const filterList: string[] = envDevices;

// eslint-disable-next-line no-restricted-syntax
for (const [key, value] of Object.entries(devicesTypes)) {
  if (filterList.indexOf(key) !== -1) {
    devicesTypesFiltered[key] = value;
  }
}

export default devicesTypesFiltered;
