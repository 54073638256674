
import { Component, Prop } from 'vue-property-decorator';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import DropDownBase from '@/ui/components/devices/devices/base/DropDown.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent TSGFrischwasser device
 */
@Component({
  components: {
    PushButtonBase,
    OutputFieldBase,
    DropDownBase,
    InputFieldBase,
    ShowEventDotBase,
    DeviceLayout,
    LabelUnitWrapper,
  },
})
export default class TSGFrischwasser extends mixins(Validation) {
  @Prop() deviceData!: IDevice;

  textMappingError = {
    0: 'Keine Fehler',
    1: 'KL1512 Konfiguration Fehler',
    2: 'Redox Sensor AI Fehler',
    3: 'Systemzeit Fehler',
    4: 'Fehler Ein-Aus Schaltzeit',
  };
  textMappingAlarm = {
    0: 'Kein Alarm',
    1: 'Zu hoher Frischwasserverbrauch',
    2: 'Zu hoher Chemieverbrauch',
    3: 'Zu hoher Chemie und Frischwasserverbrauch',
    4: 'Chemieverbrauch zu tief',
    5: 'Min Wert der Redox Sonde erreicht',
    6: 'Max Wert der Redox Sonde erreicht',
    7: 'Schwimmerschalter hat ausgelöst',
    8: 'Reed Contact Chemiebehälter hat ausgelöst',
  }

  rulesForInputFields = {
    field999Rule: (value: number) => value >= -999 && value <= 999 || 'Value should be between -999 and 999',
    hourRule: (value: number) => value >= 0 && value <= 23 || 'Value should be between 0 and 23',
    minuteRule: (value: number) => value >= 0 && value <= 59 || 'Value should be between 0 and 59',
    tenThousandRule: (value: number) => value >= 0 && value <= 10000 || 'Value should be between 0 and 10000',
    onTimeRule: (value: number) => value >= 0 && value <= 25000 || 'Value should be between 0 and 25000',
    timeToMaxRule: (value: number) => value >= 0 && value <= 60 || 'Value should be between 0 and 60',
  }
}
