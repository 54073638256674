
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import ManageCollectionsModal from '@/ui/components/components/ManageCollectionsAccessModal.vue';
import _ from 'lodash';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { duplicateSingleRoom } from '@/utils/duplicationUtils';
import { IRoom } from '@/types/rooms.types';
import { IProject } from '@/types/project.types';
import { IMember } from '@/types/members.types';

/**
 * Component that allows to create a new project
 */
@Component({
  components: {
    ModalWindow,
    ManageCollectionsModal,
    CircleSpinner,
  },
})
export default class DuplicateRoomModal extends Vue {
  @Prop() roomData!: IRoom;
  @Prop() currentMember!: IMember;
  @Prop() project!: IProject;

  isLoading = false;

  async handleChange(value: boolean) {
    if (value) {
      this.isLoading = true;
      await duplicateSingleRoom(this.roomData, this.project, this.currentMember);
      this.isLoading = false;
      (this.$refs.ModalWindowInstance as any).closeDialog();
    }
  }

  mounted() {
    this.isLoading = false;
  }

  async handleCancel() {
    window.stop();
    this.isLoading = false;
    (this.$refs.ModalWindowInstance as any).closeDialog();
  }
}
