
import { Component, Prop } from 'vue-property-decorator';
import ComboboxField from '@/ui/components/modals/components/form/ComboboxField.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import VueI18n from 'vue-i18n';
import { IVariable } from '@/types/variables.types';
import { TargetObject } from '@/types/common.types';

/**
 * Dynamic field component which used in manage modal forms.
 * Creates new fields for targetObject prop
 */
@Component({
  components: {
    ComboboxField,
  },
})
export default class DynamicFieldWithTitle extends mixins(Validation) {
  @Prop({ default: false }) isOptional!: boolean;
  @Prop({ default: null }) targetObject!: TargetObject;
  @Prop({ default: null }) label!: string | VueI18n.TranslateResult;
  @Prop({ default: null }) itemsList!: IVariable[];
  @Prop({ default: null }) maxNumberOfFields!: number;
  @Prop({ default: null }) instanceLabelPath!: string; // used vue-i18n "$t" function, example: $t(path_to_lang_file)

  addMaxPowerItem() {
    this.$set(this.targetObject, `mp-${Date.now()}`, { variable: '', title: '' });
  }
  deleteMaxPowerItem(key: string) {
    this.$delete(this.targetObject, key);
  }
}
