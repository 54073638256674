
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { NavigationGuardNext, Route } from 'vue-router';
import { mixins } from 'vue-class-component';
import { isEqual } from 'lodash';
import { Validation } from '@/ui/mixins/validation';
import LeaveConfirmationModal from '@/ui/components/modals/LeaveConfirmationModal.vue';
import { IProject, IEnergyPrice } from '@/types/project.types';

Component.registerHooks(['beforeRouteLeave']);

/**
 * Component that shows Notification settings tab
 */
@Component({
  components: {
    LeaveConfirmationModal,
  },
})
export default class EnergyPrice extends mixins(Validation) {
  @Prop({ default: false }) isWizard!: boolean;
  @Getter('projects/project') project!: IProject;
  @Getter('projects/projectCurrency') projectCurrency!: '€' | 'CHF' | undefined;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;

  energyPrice: IEnergyPrice = {
    buyPrice: 0.4,
    sellPrice: 0.1,
  };
  currencySelectionItems = ['€', 'CHF'];
  selectedCurrency: '€' | 'CHF' | '' = '';
  valid = false;

  /**
   * If the energy price was not saved previously,
   * a confirmation dialog will be displayed,
   * because local energy price has default values.
   */
  get isLocalOriginEnergyPriceEqual() {
    return isEqual(this.project.meta?.energyPrice, this.energyPrice);
  }

  @Watch('energyPrice', { deep: true })
  handleEnergyPriceChange() {
    this.$emit('validationChange', { isValid: this.valid, energyPrice: this.energyPrice });
  }

  @Watch('valid')
  handleValidationChange() {
    this.$emit('validationChange', { isValid: this.valid, energyPrice: this.energyPrice });
  }

  /**
   * Updates current project
   */
  async handleGeneralSettings() {
    await this.updateProject({
      ...this.project,
      meta: {
        ...this.project.meta,
        energyPrice: {
          buyPrice: this.energyPrice.buyPrice,
          sellPrice: this.energyPrice.sellPrice,
        },
        projectCurrency: this.selectedCurrency,
      },
    });
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (from.name === 'EnergyPrice' && !this.isLocalOriginEnergyPriceEqual) {
      await (this.$refs.confirmationModal as LeaveConfirmationModal).confirmAction(to, next);
    } else next();
  }

  created() {
    if (this.project.meta?.energyPrice) {
      this.energyPrice = { ...this.project.meta?.energyPrice };
    }
    if (this.projectCurrency !== undefined) {
      this.selectedCurrency = this.projectCurrency;
    }
  }

  mounted() {
    (this.$refs.form as any).validate();
  }
}
