
import { Vue, Component, Prop } from 'vue-property-decorator';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';

/**
 * Component that represents the force charge indicator for battery systems
 */
@Component({
  components: {
    InfoTooltip,
  },
})
export default class HouseCalculationInfo extends Vue {
}
