
import { Component } from 'vue-property-decorator';
import LightPushButton from '@/ui/components/devices/devices/LightPushButton.vue';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { SendingState } from '@/ui/components/devices/devices/BasicControl';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { IDevice } from '@/types/devices.types';
import { Getter, State } from 'vuex-class';
import { hybridVersionDate, plcVersionDate } from '@/utils/versionManagementUtils';
import { IProject } from '@/types/project.types';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';
import installationWizardVariables
  from '@/ui/components/wizards/installationWizard/installationWizardVariables';

@Component({
  components: {
    LightPushButton,
    WizardContentView,
  },
})
export default class InstallationPage extends WizardComponent {
  @State('installationWizard') installationWizardState!: IInstallationWizardState;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/project') project!: IProject;
  @Getter('mqttClient/isProjectOnline') isProjectOnline !: boolean;
  @Getter('installationWizard/disabledWhileOffline') disabledWhileOffline!: boolean;

  sent = false;

  get isHybridVersion() {
    return plcVersionDate(this.project).getTime() > hybridVersionDate.getTime();
  }

  deviceData: IDevice = {
    id: '',
    name: this.$t('installationWizard.inviteUsers.installationPage.initialLoadDevice.name').toString(),
    data: {
      type: 'LightPushButton',
      meta: {
        deviceSchema: {
          additionalBasicDevices: [],
          basicDevices: ['PushButton'],
        },
        minMaxBorders: {},
      },
      mappings: {
        PushButton_onOff: 'prgEnergy.fbI.bStartInitCharge',
        PushButton_state: 'prgEnergy.fbI.bStartInitCharge',
        ShowEvent_errorWarningState: '',
      },
    },
    favorite: false,
    collection_id: '',
    created_at: new Date(Date.now()),
  }

  handleSent(event: any) {
    this.sent = event === SendingState.Success;
  }

  get disableButton() {
    if (this.isDeye && this.isHybridVersion) {
      return (!this.isProjectOnline || this.disabledWhileOffline) && this.power >= 30000;
    }
    return false;
  }

  get power(): number {
    return (this.measurements[installationWizardVariables('maxPowerInverter', 'componentsPage').variable] as number ?? 0) / this.installationWizardState.inverterCount;
  }
}
