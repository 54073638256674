
import { Vue, Component, Prop } from 'vue-property-decorator';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { EnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import LynusIcon from '@/ui/components/components/LynusIcon.vue';
import StatusIndicator from '@/ui/components/components/StatusIndicator.vue';

export interface IndicatorInfo {
  icon: string;
  documentation: {
    title: string;
    info: string;
    link?: string;
  };
}

/**
 * Component that represents the force charge indicator for battery systems
 */
@Component({
  components: {
    StatusIndicator,
    LynusIcon,
    ModalWindow,
    InfoTooltip,
  },
})
export default class EmdModeIndicator extends Vue {
  @Prop({}) info!: IndicatorInfo;
}
