
import { Vue, Component, Prop } from 'vue-property-decorator';
import SystemInstance
  from '@/ui/components/devices/previews/mpc/SetpointOptimizer/system/SystemInstance.vue';
import CentralEndpoint from '@/ui/components/devices/components/EnergyParts/CentralEndpoint.vue';
import TimeSlider from '@/ui/components/devices/components/EnergyParts/TimeSlider.vue';
import { throttle } from 'lodash';
import forecastMockData
  from '@/ui/components/devices/previews/mpc/SetpointOptimizer/forecastMockData';

@Component({
  components: {
    SystemInstance,
    CentralEndpoint,
    TimeSlider,
  },
})
export default class InstancesGridView extends Vue {
  @Prop({ default: null }) systemInstanceType!: string;
  @Prop({ default: null }) systemData!: any;
  @Prop({ default: null }) systemColors!: any;
  @Prop({ default: null }) canvasSize!: any;
  @Prop({ default: null }) canvasCenter!: any;
  @Prop({ default: false }) isForecastView!: boolean;

  systemSlider = 0

  instancesCanvasSize: any = null
  instancesCanvasCenter: any = null
  instancesCanvasLines: any = {}
  forecastData: any = {}

  get timeSliderInitArr() {
    return Object.values(forecastMockData)[0];
  }
  get systemsForecastDataFiltered() {
    let obj = {};
    Object.entries(forecastMockData).forEach((system: any) => {
      const res = system[1][this.systemSlider] || [null, null];
      obj = { ...obj, ...{ [system[0]]: res } };
    });
    return obj;
  }

  get instancesTopLine() {
    return Object.keys(this.systemData);
  }
  get instancesBottomLine() {
    return [];
  }

  /**
   * Load line if all coords and sizes ready
   * @return {boolean} load line status
   */
  get isDataForInstancesAnimatedLineReady() {
    return !!this.instancesCanvasSize && !!this.instancesCanvasCenter && !!Object.values(this.instancesCanvasLines);
  }

  /**
   * Defines coordinates of systems, Central Energy Point.
   * Use vm.$refs to retrieve references to elements.
   * Then it goes through the list of references and creates an options object for each one.
   */
  getInstancesCoords() {
    const central: any = this.$refs.instancesCentralEndpoint;
    const actualViewRef: any = this.$refs.instancesCanvas;
    this.instancesCanvasSize = { width: actualViewRef?.clientWidth, height: actualViewRef?.clientHeight };
    this.instancesCanvasCenter = {
      x: central.offsetLeft + central.offsetWidth / 2,
      y: central.offsetTop + central.offsetHeight / 2,
      w: central.offsetWidth,
      h: central.offsetHeight,
    };

    [...this.instancesTopLine, ...this.instancesBottomLine].forEach((system: any) => {
      const target: any = this.$refs[system];
      if (!target.length) return;

      this.instancesCanvasLines[system] = {
        key: system,
        x: target[0].offsetLeft + target[0].offsetWidth / 2,
        y: target[0].offsetTop + target[0].offsetHeight / 2,
        w: target[0].offsetWidth,
        h: target[0].offsetHeight,
      };
    });
  }
  getInstancesCoordsThrottle = throttle(this.getInstancesCoords, 500)

  async created() {
    await this.$nextTick();
    window.addEventListener('resize', this.getInstancesCoordsThrottle);
    this.getInstancesCoords();
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.getInstancesCoordsThrottle);
  }
}
