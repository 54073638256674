import BasicBillingInformation
  from '@/ui/components/wizards/tenantWizard/steps/GeneralInformation/BasicBillingInformation.vue';
import PaymentInformation
  from '@/ui/components/wizards/tenantWizard/steps/GeneralInformation/PaymentInformation.vue';
import ExtraCosts
  from '@/ui/components/wizards/tenantWizard/steps/GeneralInformation/ExtraCosts.vue';
import FlatsInformation
  from '@/ui/components/wizards/tenantWizard/steps/GeneralInformation/FlatsInformation.vue';
import TariffSettings
  from '@/ui/components/wizards/tenantWizard/steps/GeneralInformation/TariffSettings.vue';
import WelcomePage
  from '@/ui/components/wizards/tenantWizard/steps/GeneralInformation/WelcomePage.vue';
import ColdWater from '@/ui/components/wizards/tenantWizard/steps/CounterInformation/ColdWater.vue';
import Heating from '@/ui/components/wizards/tenantWizard/steps/CounterInformation/Heating.vue';
import WarmWater
  from '@/ui/components/wizards/tenantWizard/steps/CounterInformation/WarmWater.vue';
import Electricity from '@/ui/components/wizards/tenantWizard/steps/CounterInformation/Electricity.vue';
import ConsumptionComparison
  from '@/ui/components/wizards/tenantWizard/steps/ConsumptionComparison/ConsumptionComparison.vue';
import BillingAndDocumentOutput
  from '@/ui/components/wizards/tenantWizard/steps/BillingAndDocumentOutput/BillingAndDocumentOutput.vue';
import FinalPage from '@/ui/components/wizards/tenantWizard/steps/Final/FinalPage.vue';
import AutoMapping from '@/ui/components/wizards/tenantWizard/steps/GeneralInformation/AutoMapping.vue';
import Cooling from '@/ui/components/wizards/tenantWizard/steps/CounterInformation/Cooling.vue';
import SystemInformation from '@/ui/components/wizards/tenantWizard/steps/GeneralInformation/SystemInformation.vue';

const tenantWizardImports = {
  BasicBillingInformation,
  PaymentInformation,
  ExtraCosts,
  FlatsInformation,
  TariffSettings,
  WelcomePage,
  ColdWater,
  Heating,
  WarmWater,
  Electricity,
  ConsumptionComparison,
  BillingAndDocumentOutput,
  FinalPage,
  AutoMapping,
  Cooling,
  SystemInformation,
};

export default tenantWizardImports;
