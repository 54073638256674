
import { Vue, Component } from 'vue-property-decorator';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    DeviceLayout,
  },
})
export default class TemperaturePreview extends Vue {
  state = 0
  timer: any = null

  get previewNameLang() {
    return this.$t('devices.Temperature.previewName');
  }

  upTemperature() {
    return setInterval(() => {
      this.state += 1;
      if (this.state > 35) {
        this.state = 0;
        clearInterval(this.timer);
        this.timer = this.downTemperature();
      }
    }, 1000);
  }
  downTemperature() {
    return setInterval(() => {
      this.state -= 1;
      if (this.state < -20) {
        this.state = 0;
        clearInterval(this.timer);
        this.timer = this.upTemperature();
      }
    }, 1000);
  }

  mounted() {
    this.timer = this.upTemperature();
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }
}
