
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { IEMSTariffSettings } from '@/types/mpc/devices/ems.types';
import { IDevice } from '@/types/devices.types';

const DEFAULT_HEATING_PUMP_BUFFER = 50;

@Component
export default class HeatingBufferInput extends Vue {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop() systemKey!: string;
  @Prop() systemInstanceKey!: string;

  @Action('mpc/updateTariffSettings') updateHeatingBufferSettings!: (
    { mpc_id, settings }: { mpc_id: string; settings: IEMSTariffSettings },
  ) => void;

  heatingPumpBufferInstance = null;

  get isHeatingBufferSendButtonValid() {
    return typeof this.heatingPumpBufferInstance === 'number';
  }

  async sendValue() {
    const settings = this.deviceData.data?.meta?.settings ? {
      mode: this.deviceData.data?.meta?.settings?.mode,
      energyPrice: {
        ...this.deviceData.data?.meta?.settings?.energyPrice,
        heating_pump_buffer: {
          ...this.deviceData.data?.meta?.settings?.energyPrice?.heating_pump_buffer,
          [this.systemInstanceKey]: this.heatingPumpBufferInstance,
        },
      },
    } : {
      mode: null,
      energyPrice: {
        start_low: null,
        end_low: null,
        start_high: null,
        end_high: null,
        price_low: null,
        price_high: null,
        timezone: null,
        heating_pump_buffer: { [this.systemInstanceKey]: this.heatingPumpBufferInstance },
      },
    };

    await this.updateHeatingBufferSettings({ mpc_id: this.deviceData.id, settings });
  }

  created() {
    this.heatingPumpBufferInstance =
      this.deviceData.data?.meta?.settings?.energyPrice?.heating_pump_buffer?.[this.systemInstanceKey]
      || DEFAULT_HEATING_PUMP_BUFFER;
  }
}
