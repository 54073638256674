import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/store/types';
import { IDevicesState } from '@/store/modules/devices/types';
import { IDevice, IDeviceType } from '@/types/devices.types';
import DeviceService from '@/services/DeviceService';
import { Map } from 'immutable';
import i18n from '@/ui/plugins/i18n';
import { sortElementsByPosition, convertElementsPositionsToObject } from '@/utils/utilsFunctions';
import devicesSettings from './settings';

const state: IDevicesState = {
  devices: Map(),
  devicesFilter: '',
  lastCreatedDevice: '',
  ...devicesSettings,
};

const getters: GetterTree<IDevicesState, RootState> = {
  /**
   * all devices: devices and charts
   * when we fetch use "/projects/project_id/devices" in request url in response will be devices and charts
   * @param state list of devices, list of devices types, deviceFilter
   */
  allDevices(state: IDevicesState) {
    return state.devices.valueSeq().toJS();
  },
  /**
   * Only devices, filtered from charts
   * @param state
   * @param getters
   */
  devices(state: IDevicesState, getters: Record<string, any>) {
    return getters.allDevices
      .filter((device: IDevice) => Object.keys(state.devicesTypesFiltered).includes(device.data.type));
  },
  devicesTypes(state: IDevicesState) {
    return state.devicesTypesFiltered;
  },
  lastCreatedDevice(state: IDevicesState) {
    return state.lastCreatedDevice;
  },
  deviceTypeSchemaByKey: (state: IDevicesState) => (type: string) => state.devicesTypesFiltered[type],

  /**
   * Goes through devicesTypesFiltered and create options
   * (Example: { value: key, title: i18n translation }) for every item
   * @param state
   */
  devicesTypesWithLocaleNamesList(state: IDevicesState) {
    return Object.keys(state.devicesTypesFiltered)
      .map((itemType: string) => ({ value: itemType, title: i18n.t(`devices.${itemType}.previewName`) }));
  },

  /**
   * Return devices, MPC, charts attached to selected room
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   */
  devicesByRoom: (state, getters, rootState, rootGetters) => (roomId: string) => {
    const roomDevicesPositions = rootGetters['members/currentMember']?.meta?.device_positions?.[roomId];
    const roomDevicesPositionsMap = roomDevicesPositions
      ? convertElementsPositionsToObject(roomDevicesPositions)
      : {};

    const mpc = rootGetters['mpc/mpcControllers'];
    const charts = rootGetters['charts/charts'];
    const { devices } = getters;
    const roomDevices = [...devices, ...charts, ...mpc].filter((device: IDevice) => device.collection_id === roomId);

    return sortElementsByPosition({
      elementsList: JSON.parse(JSON.stringify(roomDevices)),
      positions: roomDevicesPositionsMap,
    });
  },

  /**
   * Returns devices, MPC, charts which are in current user favorites list
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   */
  favoritesDevices(state, getters, rootState, rootGetters) {
    const favoritePositions = rootGetters['members/currentMember']?.meta?.favorite_positions;
    const favoritePositionsMap = favoritePositions
      ? convertElementsPositionsToObject(favoritePositions)
      : {};

    const mpc = rootGetters['mpc/mpcControllers'];
    const charts = rootGetters['charts/charts'];
    const { devices } = getters;
    const favoriteDevices = [...devices, ...charts, ...mpc].filter(device => {
      return rootGetters['members/currentMemberFavoriteDevices'].includes(device.id);
    });

    return sortElementsByPosition({
      elementsList: JSON.parse(JSON.stringify(favoriteDevices)),
      positions: favoritePositionsMap,
    });
  },

  /**
   * Returns devices list with the visibility status of the settings window
   * @param state
   */
  devicesIsSettings(state: IDevicesState) {
    return Object.entries(state.devicesTypes).map((device: [string, IDeviceType]) => [device[0], device[1].isSettingsView]);
  },
};

const mutations: MutationTree<IDevicesState> = {
  setDevices(state: IDevicesState, devices: Map<string, IDevice>) {
    state.devices = devices;
  },
  setDevicesFilter(state: IDevicesState, name: string) {
    state.devicesFilter = name;
  },
  setLastCreatedDevice(state: IDevicesState, device: string) {
    state.lastCreatedDevice = device;
  },
};

const actions: ActionTree<IDevicesState, RootState> = {
  /**
   * Load devices list, creates Map collection from them and set to devices state
   * @param commit
   * @param state
   * @param rootState
   */
  async fetchDevices({ commit, state, rootState }) {
    const res = await DeviceService.fetchDevices(rootState.projects.projectId as string);
    const devices = (res as IDevice[]).reduce((acc, cur) => acc.set(cur.id, cur), Map<string, IDevice>());
    commit('setDevices', devices);
  },

  /**
   * Load energy data for selected device (EnergyView, EMS).
   * @param commit
   * @param state
   * @param rootState
   * @param rootGetters
   * @param id
   */
  async fetchEnergyData({ commit, state, rootState, rootGetters }, params) {
    const project_id = rootGetters['projects/project'].id;
    try {
      return await DeviceService.fetchEnergyData(project_id, params.device_id, params.start, params.end);
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: i18n.t('uiComponents.reportMessages.errorLoadingEnergyData'),
        value: true,
      }, { root: true });
    }
  },

  /**
   * Creates device
   * @param commit
   * @param state
   * @param rootState
   * @param device
   */
  async createDevice({ commit, state, rootState }, device: IDevice) {
    try {
      const res = await DeviceService.createDevice(rootState.projects.projectId as string, device);
      commit('setDevices', state.devices.set(res.id, res));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.createDevice', { name: res.name }),
        value: true,
      }, { root: true });
      return res;
    } catch (e) {
      console.log(e);
      return null;
    }
  },

  /**
   * Removes selected device, rules attached to device
   * @param commit
   * @param dispatch
   * @param state
   * @param rootState
   * @param device device data
   */
  async deleteDevice({ commit, dispatch, state, rootState }, device: IDevice) {
    const project_id = rootState.projects.projectId;
    const warningRuleId = device.data?.meta?.warningRule;
    const errorRuleId = device.data?.meta?.errorRule;
    // remove rules attached to device
    await Promise.all([warningRuleId, errorRuleId].filter((rule_id: string) => rule_id).map((rule_id: string) => {
      return dispatch('rules/deleteRule', { project_id, rule_id }, { root: true });
    }));

    // will remove all of the single system Error and Warning Rules only for EMSV2
    if (device.data.type === 'EMSV2') {
      const systemsRules = device.data.meta.rules ? Object.values(device.data.meta.rules) : [];
      const rulesVariablesList: any = systemsRules.length
        ? systemsRules
          .map((system: any) => [system.errorRule, system.warningRule])
          .reduce((acc: any, item: any) => acc.concat(item), [])
        : [];
      const rulesList: any = [...rulesVariablesList]
        .filter((el: any) => !!el)
        .map((variable: string) => dispatch('rules/deleteRule', { project_id, rule_id: variable }, { root: true }));
      await Promise.all(rulesList);
    }

    await DeviceService.deleteDevice(rootState.projects.projectId as string, device.id);
    commit('setDevices', state.devices.remove(device.id));
    commit('app/setReport', {
      type: 'success',
      message: i18n.t('uiComponents.reportMessages.deleteDevice'),
      value: true,
    }, { root: true });
  },

  /**
   * Updates selected device
   * @param commit
   * @param state
   * @param rootState
   * @param device device data
   */
  async updateDevice({ commit, state, rootState }, { device, skipReport }) {
    if (!rootState.projects.projectId) return;

    try {
      const res = await DeviceService.updateDevice(rootState.projects.projectId, device.id, device);
      commit('setDevices', state.devices.set(res.id, res));
      if (!skipReport) {
        commit('app/setReport', {
          type: 'success',
          message: i18n.t('uiComponents.reportMessages.editDevice', { name: res.name }),
          value: true,
        }, { root: true });
      }
    } catch (e) {
      if (!skipReport) {
        commit('app/setReport', {
          type: 'error',
          message: e.message,
          value: true,
        }, { root: true });
      }
    }
  },

  /**
   * Add device to Favorites
   * @param commit
   * @param state
   * @param rootState
   * @param deviceId device id
   */
  async addDeviceToFavorites({ commit, state, rootState }, deviceId) {
    await DeviceService.addToFavorites(rootState.projects.projectId as string, deviceId);

    const currentDevice = state.devices.get(deviceId);
    if (currentDevice) {
      currentDevice.favorite = true;
      commit('setDevices', state.devices.set(currentDevice.id, currentDevice));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.addToFavorites', { name: currentDevice.name }),
        value: true,
      }, { root: true });
    }
  },

  /**
   * Removes device from Favorites
   * @param commit
   * @param state
   * @param rootState
   * @param deviceId device id
   */
  async removeDeviceFromFavorites({ commit, state, rootState }, deviceId) {
    await DeviceService.deleteFromFavorites(rootState.projects.projectId as string, deviceId);

    const currentDevice = state.devices.get(deviceId);
    if (currentDevice) {
      currentDevice.favorite = false;
      commit('setDevices', state.devices.set(currentDevice.id, currentDevice));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.removeFromFavorites', { name: currentDevice.name }),
        value: true,
      }, { root: true });
    }
  },

  /**
   * Change date by direction and period
   * @param dispatch
   * @param id Device id
   * @param start Start date for mpc data
   * @param end End date for mpc data
   * @param period Current period
   */
  async fetchDataByPeriod(
    { dispatch },
    { id, start, end, period }: { id: string; start: number; end: number; period: string },
  ) {
    const energyResponse = await dispatch('devices/fetchEnergyData', { device_id: id, start, end }, { root: true });
    return energyResponse;
  },

  /**
   * Returns list of all basic devices by collection ID
   * @param collection_id
   */
  getDevicesByCollectionID({ getters }, collection_id: string) {
    return getters.devices.filter((device: any) => device.collection_id === collection_id);
  },

  /**
   * Returns list of all basic devices by collection ID
   * @param collection_id
   */
  getAllDevicesByCollectionID({ getters }, collection_id: string) {
    return getters.allDevices.filter((device: any) => device.collection_id === collection_id);
  },

  async transferToRegelenergie({ commit, rootState, dispatch }, device: IDevice) {
    try {
      // transfer device to regelenergie
      await DeviceService.transferToRegelenergie(rootState.projects.projectId as string, device);
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('mlModel.EMS.settingsView.regelenergie.transferSuccess') as string,
        value: true,
      }, { root: true });

      // update device meta to signalize that is has been transferred
      device.data.meta.regie = true;
      dispatch('updateDevice', device);
    } catch (e) {
      console.log(e);
      commit('app/setReport', {
        type: 'error',
        message: i18n.t('mlModel.EMS.settingsView.regelenergie.transferError') as string,
        value: true,
      }, { root: true });
    }
  },
};

export const devices: Module<IDevicesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
