
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: {
    CircleSpinner,
  },
})
export default class ChartLoader extends Vue {

  timer: NodeJS.Timeout|undefined;
  loadingText: TranslateResult = '';

  created() {
    this.timer = setTimeout(() => {
      this.loadingText = this.$t('uiComponents.chartLoadingText.longLoading');
    }, 15000);
  }

  beforeDestroy() {
    clearTimeout(this.timer);
  }
}
