
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

/**
 * Component that represent ShowEvent Basic Control
 */
@Component
export default class ShowEventBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;

  get errorStatus() {
    return this.measurements.get(this.mappingsClean.errorWarningState);
  }
  get warningClass() {
    return {
      'device-warning-state': this.errorStatus === 1,
      'device-error-state': this.errorStatus === 2,
    };
  }
  get isNotMapped(): boolean {
    return this.mappingsClean.errorWarningState === '';
  }
}
