
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getVariableNames } from '@/ui/components/devices/charts/charts/ChartMath';

/**
 * Component that manage calculation, aggregation
 */
@Component
export default class CalculationAggregationWindow extends Vue {
  @Prop() aggregations!: string[];
  @Prop() expression!: string;
  @Prop() aggregationMethods!: any[];

  dialog = false;

  get variablesList() {
    return getVariableNames(this.expression);
  }
}
