
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import Wizard from '@/ui/components/wizards/baseComponents/Wizard.vue';
import {
  IInstallationWizardState,
} from '@/store/modules/installationWizard/types';
import { BatteryType } from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { IDevice } from '@/types/devices.types';
import { IWizardLoadingState, IWizardPage } from '@/types/wizards/wizard.general.types';
import { hybridVersionDate, plcVersionDate } from '@/utils/versionManagementUtils';
import { IProject } from '@/types/project.types';
import installationWizardVariables
  from '@/ui/components/wizards/installationWizard/installationWizardVariables';
import { IIncludedSystemsTypes } from '@/types/wizards/installationWizard.types';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    Wizard,
  },
})
export default class InstallationWizard extends Vue {
  @State('installationWizard') wizardState!: IInstallationWizardState;
  @Getter('devices/allDevices') allDevices!: IDevice[];
  @Getter('installationWizard/currentStep') activeStep!: number;
  @Getter('installationWizard/wizardBlocked') wizardBlocked!: boolean;
  @Getter('installationWizard/wizardPages') wizardPages!: IWizardPage[];
  @Getter('serialNumber/serialNumbersFromMqtt') serialNumbersFromMqtt!: { [key: string]: string };
  @Getter('mqttClient/isProjectOnline') isProjectOnline !: boolean;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('projects/project') project!: IProject;
  @Getter('projects/batterySystemType') batterySystemType!: BatteryType | undefined;
  @Getter('installationWizard/considerProjectStatus') considerProjectStatus !: boolean;
  @Getter('installationWizard/loadingState') loadingState!: IWizardLoadingState;
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;
  @Action('devices/fetchDevices') fetchDevices!: (projectId: string) => Promise<void>;
  @Action('mqttClient/createConnection') createConnection!: () => Promise<void>;
  @Action('mqttClient/removeConnection') removeConnection!: () => Promise<void>;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;

  loading = true;

  get failedHybridInverters() {
    const hybridInverterIndices: number[] = [];
    for (let i = 0; i < this.wizardState.inverterCount; i++) {
      const serialNumberVariable = installationWizardVariables('deyeInverterSerialNumber', 'pilotPage', -1, i);
      const serialNumber = this.serialNumbersFromMqtt[serialNumberVariable.variable];
      if (!serialNumber || [-1, '-1', '#EMPTY#'].includes(serialNumber)) {
        hybridInverterIndices.push(i);
      }
    }
    return hybridInverterIndices;
  }

  get failedStringInverters() {
    const stringInverterIndices: number[] = [];
    for (let i = 0; i < this.wizardState.stringInverterCount; i++) {
      const serialNumberVariable = installationWizardVariables('stringInverterSerialNumber', 'pilotPage', -1, i);
      const serialNumber = this.serialNumbersFromMqtt[serialNumberVariable.variable];
      if (!serialNumber || [-1, '-1', '#EMPTY#'].includes(serialNumber)) {
        stringInverterIndices.push(i);
      }
    }
    return stringInverterIndices;
  }

  get currentPage() {
    return this.wizardState.currentPageIndex;
  }

  get hasBatteryDefined() {
    return !!this.batterySystemType;
  }

  get doesEnergyDevicesHaveBattery() {
    const hasEmsBattery = this.allDevices.find((device: IDevice) => device.data.type === 'EMSV2')?.data.meta.controllerMappings.battery.count > 0;
    const hasEnergyViewBattery = this.allDevices.find((device: IDevice) => device.data.type === 'EnergyViewV2')?.data.meta.controllerMappings.battery.count > 0;
    return hasEmsBattery && hasEnergyViewBattery;
  }

  get pages() {
    return this.wizardPages;
  }

  get isHybridVersion() {
    return plcVersionDate(this.project).getTime() > hybridVersionDate.getTime();
  }

  get hasToBeOnline() {
    const systemTestPageIndex = this.wizardPages.findIndex((page: IWizardPage) => page.title === 'SystemTestPage');
    if (this.isDeye && this.isHybridVersion && this.wizardState.currentPageIndex >= systemTestPageIndex) {
      return !this.wizardState.restartSent;
    }
    return true;
  }

  reloadPage() {
    window.location.reload();
  }

  async mounted() {
    await new Promise((resolve, reject) => setTimeout(() => {
      this.loading = false;
      resolve();
    }, 1000));

    if ((plcVersionDate(this.project).getTime() > hybridVersionDate.getTime())) {
      await this.createConnection();
    }
  }

  async created() {
    this.setProjectLoader(true);
    await Promise.all([
      this.fetchDevices(this.$route.params.id),
      this.fetchMembers(this.$route.params.id),
    ]);
    this.setProjectLoader(false);
  }
}
