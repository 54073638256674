
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import RoomsList from '@/ui/components/lists/RoomsList/index.vue';
import ManageRoom from '@/ui/components/modals/ManageRoom.vue';
import DevicesListFlexGrid from '@/ui/components/lists/DevicesListFlexGrid/index.vue';
import DevicesListDnD from '@/ui/components/lists/DevicesListDnD/index.vue';
import DevicesList from '@/ui/components/lists/DevicesList/index.vue';
import RoomsListFlexGrid from '@/ui/components/lists/RoomsListFlexGrid/index.vue';
import { IAppState } from '@/store/modules/app/types';
import { IAnomalyDetectionState } from '@/store/modules/anomalyDetection/types';
import { envWorkbenchButtons } from '@/utils/env';
import { requestWithTimer } from '@/utils/utilsFunctions';
import ManageDevice from '@/ui/components/modals/ManageDevice/index.vue';
import ManageMLModel from '@/ui/components/modals/ManageMLModel/index.vue';
import ManageCharts from '@/ui/components/modals/ManageChart/index.vue';
import ManageAnomalyDetection from '@/ui/components/modals/ManageAnomalyDetection/index.vue';
import { IMPCState } from '@/store/modules/mpc/types';
import { IRoom } from '@/types/rooms.types';
import { IProject } from '@/types/project.types';
import { IDevice } from '@/types/devices.types';
import { IReportBox } from '@/types/app.types';
import { IMember } from '@/types/members.types';

/**
 * Rooms page that shows rooms list
 */
@Component({
  components: {
    RoomsList,
    ManageRoom,
    DevicesListFlexGrid,
    DevicesListDnD,
    DevicesList,
    RoomsListFlexGrid,
    ManageMLModel,
    ManageDevice,
    ManageCharts,
    ManageAnomalyDetection,
  },
})
export default class Rooms extends Vue {
  @State('anomalyDetection') anomalyDetectionState!: IAnomalyDetectionState;
  @State('app') appState!: IAppState;
  @State('devices') devicesState!: IDevice[];
  @State('mpc') mpcState!: IMPCState;
  @Getter('rooms/rooms') rooms!: IRoom[];
  @Getter('devices/devicesByRoom') devicesByRoom!: (roomId: string) => IDevice[];
  @Getter('projects/project') project!: IProject;
  @Getter('members/currentMember') currentMember!: IMember;
  @Mutation('app/setReport') setReport!: (payload: IReportBox) => void;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;
  @Action('rooms/fetchRooms') fetchRooms!: (projectId: string) => Promise<void>;
  @Action('mpc/fetchMPCListByProject') fetchMPCListByProject!: () => Promise<void>;
  @Action('devices/fetchDevices') fetchDevices!: (projectId: string) => Promise<void>;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Action('mpc/createMCCInstance') createMCCInstance!: (control: any) => void;
  @Action('devices/createDevice') createDevice!: (control: any) => void;
  @Action('mpc/fetchMPCWeatherStatus') fetchMPCWeatherStatus!: any;
  @Action('members/updateMemberMeta') updateMemberMeta!: (meta: any) => Promise<void>;

  isMenuOpen = false;
  isRoomsDataLoaded = false;
  activeRoomId = '';

  get isRoomCreated() {
    return this.rooms.length !== 0;
  }

  get devicesByRoomLocal() {
    return this.devicesByRoom(this.activeRoomId);
  }
  get currentRoomData() {
    return this.rooms.find((room: any) => room.id === this.activeRoomId);
  }
  get devicesFilter() {
    return (this.devicesState as any).devicesFilter || '';
  }

  get isWeatherServiceActive() {
    return this.mpcState.isWeatherServiceActive;
  }

  get mlModelTypes() {
    return Object.keys(this.mpcState.mlModelTypes);
  }

  get anomalyDetectionTypes() {
    return Object.keys(this.anomalyDetectionState.anomalyDetectionTypes);
  }

  /**
   * List of create forms, contains options for ManageDevice, ManageAnomalyDetection,
   * ManageCharts, ManageMLModel
   */
  get createDeviceOptions() {
    return [
      {
        id: 'device',
        form: 'ManageDevice',
        formTitle: this.$t('modals.manageDevice.createDeviceTitle'),
        btnTitle: this.$t('uiComponents.buttons.addDevice'),
        visible: true,
      },
      {
        id: 'aiml',
        form: 'ManageAnomalyDetection',
        formTitle: this.$t('modals.manageAnomalyDetection.createAnomalyDetectionTitle'),
        btnTitle: this.$t('uiComponents.buttons.addAnomalyDetection'),
        visible: true,
      },
      {
        id: 'charts',
        form: 'ManageCharts',
        formTitle: this.$t('modals.manageCharts.createChartTitle'),
        btnTitle: this.$t('uiComponents.buttons.addChart'),
        visible: true,
      },
      {
        id: 'mpc',
        form: 'ManageMLModel',
        formTitle: this.$t('modals.manageMLModel.createDeviceTitle'),
        btnTitle: this.$t('uiComponents.buttons.addMlModel'),
        visible: this.isWeatherServiceActive,
      },
    ].filter((e) => e.visible && envWorkbenchButtons.includes(e.form));
  }

  get dndStatus() {
    if (this.project) {
      return this.project.meta?.isDNDActive;
    }
    return false;
  }

  @Watch('dndStatus')
  async onDNDStatusChange(val: boolean) {
    if (!val) {
      await this.saveAreasDevicesPositions();
    }
  }

  async saveAreasDevicesPositions() {
    const member = JSON.parse(JSON.stringify(this.currentMember));
    this.createMemberMeta(member);
    this.addAreasPositions(member);
    this.addAreaDevicesPositions(member);

    await this.updateMemberMeta({ project_id: this.project.id, member });
  }

  createMemberMeta(member: any) {
    if (!member?.meta) member.meta = { area_positions: [], device_positions: {} };
    if (!member?.meta?.area_positions) member.meta.area_positions = [];
    if (!member?.meta?.device_positions) member.meta.device_positions = {};
  }
  addAreasPositions(member: any) {
    member.meta.area_positions = (this.$refs.RoomsList as any)?.areasPositions;
  }
  addAreaDevicesPositions(member: any) {
    if (!this.$refs?.DevicesList) return;

    const [area_id, devices] = (this.$refs.DevicesList as any)?.areaDevicesPositionsMap;

    const actualAreasIds = this.rooms.map((room: IRoom) => room.id);
    // filter removed areas which currently can exist in member meta
    const filtered = Object.keys(member.meta.device_positions).filter((id: string) => actualAreasIds.includes(id));
    member.meta.device_positions = filtered.length
      ? filtered.reduce((acc, el) => ({ ...acc, [el]: member.meta.device_positions[el] }), {})
      : {};
    member.meta.device_positions[area_id] = devices;
  }

  /**
   * Create device, MPC device
   * @param payload device or MPC data
   */
  handleWorkbenchButton(payload: any) {
    this.isMenuOpen = false;
    const mpcApiTypes: string[] = [...this.mlModelTypes, ...this.anomalyDetectionTypes];
    // variable that check if current function parameter (payload) device or MPC device
    const isMpc: boolean = mpcApiTypes.some((type: string) => type === payload.data.type);
    if (isMpc) {
      this.createMCCInstance(payload);
    } else {
      this.createDevice(payload);
    }
  }

  handleOpen() {
    this.isMenuOpen = false;
  }

  handleRoomActiveStatus(roomId: string) {
    this.activeRoomId = roomId;
  }

  async created() {
    this.setProjectLoader(true);
    // Load measurements, rooms, devices, MPC
    await Promise.allSettled([
      this.fetchMeasurements(this.$route.params.id),
      this.fetchRooms(this.$route.params.id),
      this.fetchDevices(this.$route.params.id),
      requestWithTimer([
        this.fetchMPCListByProject(),
        this.fetchMPCWeatherStatus(),
      ], this.setReport, {
        type: 'error',
        message: this.$t('uiComponents.reportMessages.loadMPCTimeoutError'),
        value: true,
      }),
    ]).finally(() => {
      this.setProjectLoader(false);
      this.isRoomsDataLoaded = true;
    });
    await this.fetchMPCListByProject();
  }
}
