
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { EnergyLineDisplayData } from '@/types/energyVisualisation/EnergyLineDisplayData';

@Component({
})
export default class LineCross extends Vue {
  @Prop({}) displayDataItem!: EnergyLineDisplayData;
  @Prop({ default: null }) width!: number;
  @Prop({ default: null }) height!: number;
  @Prop({ default: true }) isLineActive!: boolean;

  ctx: CanvasRenderingContext2D | null = null
  widthOffGridText = 0;

  // xPosition - 50, yPosition + 30
  get yPositionCrossText() {
    const yPosition = this.displayDataItem.startPoint.y > this.displayDataItem.endPoint.y ?
      (this.displayDataItem.startPoint.y + this.displayDataItem.endPoint.y) / 2 :
      (this.displayDataItem.endPoint.y + this.displayDataItem.startPoint.y) / 2;
    return yPosition + 25;
  }
  get xPositionCrossText() {
    const xPosition = this.displayDataItem.startPoint.x > this.displayDataItem.endPoint.x ?
      (this.displayDataItem.startPoint.x + this.displayDataItem.endPoint.x) / 2 :
      (this.displayDataItem.endPoint.x + this.displayDataItem.startPoint.x) / 2;
    return xPosition - this.widthOffGridText / 2;
  }

  @Watch('$i18n.locale')
  handleChange() {
    this.$nextTick(() => {
      this.widthOffGridText = (this.$refs.offGridText as HTMLDivElement).clientWidth;
    });
  }

  /**
   * Draw red cross in middle of line
   * @param {object} context canvas context object
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} x2 end x position
   * @param {number} y2 end y position
   */
  drawCross(context: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number) {
    const yPosition = y1 > y2 ? (y1 + y2) / 2 : (y2 + y1) / 2;
    const xPosition = x1 > x2 ? (x1 + x2) / 2 : (x2 + x1) / 2;
    context.beginPath();
    context.moveTo(xPosition - 17, yPosition - 17);
    context.lineTo(xPosition + 17, yPosition + 17);

    context.moveTo(xPosition + 17, yPosition - 17);
    context.lineTo(xPosition - 17, yPosition + 17);
    context.lineWidth = 4;
    context.strokeStyle = '#ff0000';
    context.lineCap = 'round';

    context.stroke();
    context.closePath();
  }

  changeDirection() {
    this.$emit('changeDirection');
  }

  mounted() {
    const canvas = this.$refs.cross as HTMLCanvasElement;
    this.ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    this.drawCross(
      this.ctx,
      this.displayDataItem.endPoint.x,
      this.displayDataItem.endPoint.y,
      this.displayDataItem.startPoint.x,
      this.displayDataItem.startPoint.y,
    );

    this.$nextTick(() => {
      this.widthOffGridText = (this.$refs.offGridText as HTMLDivElement).clientWidth;
    });
  }
}
