
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Component } from 'vue-property-decorator';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { Getter, Mutation } from 'vuex-class';
import { minMaxValidation } from '@/utils/utilsFunctions';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { overWriteVariableValuesWithMeasurements } from '@/utils/installationWizardUtilsFunctions';

@Component({
  methods: { minMaxValidation },
  components: {
    InfoTooltip,
    WizardContentView,
  },
})
export default class ChpSettings extends WizardComponent {
  @Getter('projects/isDeye') isDeye!: boolean;
  @Mutation('installationWizard/addSystemPropertyValues') addSystemPropertyValues!: (data: { systemType: string; data: any }) => void;
  @Mutation('installationWizard/updateSystemPropertyValues') updateSystemPropertyValues!: (data: { systemType: string; data: any; systemIndex: number }) => void;
  @Mutation('installationWizard/handleIncludedSystemsTypesSystemSystemsProps') handleIncludedSystemsTypesSystemSystemsProps!: (
    { systemName, systemIndex, prop, value }: { systemName: string; systemIndex: number; prop: string; value: any }
  ) => void;
  doesHover = false;
  valid = false;

  visibleVariables: any = [];
  backgroundVariable: any = {}

  updateVariables(event: any, chpIndex: number, variableName: string) {
    this.visibleVariables[chpIndex][variableName].mqttVariableInfo.value = event;
  }

  prepareVariablesToSend() {
    this.visibleVariables.forEach((element: any) => {
      return Object.values(element).forEach((variable: any) => {
        this.variablesToSend.set(variable.mqttVariableInfo.variable, variable.mqttVariableInfo);
      });
    });
    Object.entries(this.backgroundVariable).forEach((element: any, index: number) => {
      Object.entries(element[1]).forEach((variable: any) => {
        this.variablesToSend.set(`chp${index + 1}${variable[0]}`, variable[1]);
      });
    });
  }

  initMappings() {
    for (let ind = 1; ind <= this.includedSystems.chp.count; ind++) {
      this.visibleVariables.push({
        'socForEnable': {
          mqttVariableInfo: { value: 3, variable: `prgBHKW.fbBHKW.stSetupBHKW_${ind}.byEnableSOC` },
          fieldInfo: { min: 0, max: 89, type: 'textField', unit: '%', rules: [this.rules.noCommas] },
        },
        'socForDisable': {
          mqttVariableInfo: { value: 35, variable: `prgBHKW.fbBHKW.stSetupBHKW_${ind}.byDisableSOC` },
          fieldInfo: { min: 0, max: 90, type: 'textField', unit: '%', rules: [this.rules.noCommas] },
        },
        'storageCap': {
          mqttVariableInfo: { value: 50, variable: `prgBHKW.fbBHKW.stSetupBHKW_${ind}.rMaxCapHeatStorage` },
          fieldInfo: { min: 1, max: 100000, type: 'textField', unit: 'l', rules: [this.rules.oneComma] },
        },
        'thermalPower': {
          mqttVariableInfo: { value: 62, variable: `prgBHKW.fbBHKW.stSetupBHKW_${ind}.rThermalPowerBHKW` },
          fieldInfo: { min: 1, max: 10000, type: 'textField', unit: 'kWth', rules: [this.rules.oneComma] },
        },
        'emergencyCooler': {
          mqttVariableInfo: { value: 0, variable: `prgBHKW.fbBHKW.stSetupBHKW_${ind}.bEmergencyCooler`, feedbackVariable: `prgBHKW.fbBHKW.stDataBHKWOut${ind}.bSActEmergencyC`, isBoolean: true },
          fieldInfo: { type: 'checkbox' },
        },
        'heatingSystem': {
          mqttVariableInfo: { value: 0, variable: `prgBHKW.fbBHKW.stSetupBHKW_${ind}.bEnHeatingSystem`, feedbackVariable: `prgBHKW.fbBHKW.stDataBHKWOut${ind}.bSActHeatingSys`, isBoolean: true },
          fieldInfo: { type: 'checkbox' },
        },
      });
      this.backgroundVariable[`chp${ind}`] = {
        'byWorkModeOn': { variable: `rgBHKW.fbBHKW.stSetupBHKW_${ind}.byWorkModeOn`, value: 6 },
        'byTargetPowerOn': { variable: `prgBHKW.fbBHKW.stSetupBHKW_${ind}.byTargetPowerOn`, value: 100, feedbackVariable: `prgBHKW.fbBHKW.stDataBHKWOut${ind}.rTargetPower` },
      };
    }

    // if wizard was done once. overwrite the values with the current measurements
    if (this.wasInstallationWizardCompleted) {
      this.visibleVariables.forEach((element: any) => {
        return Object.values(element).forEach((variable: any) => {
          const varibaleName = variable.mqttVariableInfo.variable;
          const currentMeasurentValue = this.measurements[varibaleName];

          if (currentMeasurentValue !== undefined) {
            variable.mqttVariableInfo.value = currentMeasurentValue;
          }
        });
      });
    }
  }

  async created() {
    await this.fetchMeasurements(this.$route.params.id);
    this.initMappings();
  }

  async mounted() {
    await this.$nextTick();
    (this.$refs.form as any).validate();
  }
}
