
import { Vue, Component } from 'vue-property-decorator';
import PushButtonMock from '@/ui/components/devices/previews/components/PushButtonMock.vue';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    PushButtonMock,
    SwitchMock,
    DeviceLayout,
  },
})
export default class GeneratorPreview extends Vue {
  switchState = false

  get settingsViewLang() {
    return 'devices.Generator.settingsView';
  }
  get previewNameLang() {
    return this.$t('devices.Generator.previewName');
  }
}
