import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/store/types';
import PartnerWorkspaceService from '@/services/PartnerWorkspaceService';
import i18n from '@/ui/plugins/i18n';
import { IPartnerWorkspacesState } from './types';

const state: IPartnerWorkspacesState = {
  workspaces: [],
  workspace: null,
  workspaceId: '',
  workspaceFilter: '',
  filterByPartner: '',
};

const getters: GetterTree<IPartnerWorkspacesState, RootState> = {
  workspaces(state) {
    return state.workspaces;
  },
  workspace(state) {
    return state.workspace;
  },
  workspaceId(state) {
    return state.workspaceId;
  },
  workspaceById: (state) => (id: string) => {
    return state.workspaces.find((workspace) => workspace.id === id);
  },
  workspaceFilter(state) {
    return state.workspaceFilter;
  },
  getFilterByPartner(state) {
    return state.filterByPartner;
  },
};

const mutations: MutationTree<IPartnerWorkspacesState> = {
  setWorkspaces(state, payload) {
    state.workspaces = payload;
  },
  setWorkspace(state, payload) {
    state.workspace = payload;
  },
  setWorkspaceId(state, payload) {
    state.workspaceId = payload;
  },
  setWorkspaceFilter(state, payload) {
    state.workspaceFilter = payload;
  },
  setFilterByPartner(state, payload) {
    state.filterByPartner = payload;
  },
};

const actions: ActionTree<IPartnerWorkspacesState, RootState> = {
  async fetchWorkspaces({ commit, rootGetters }) {
    try {
      const user = rootGetters['app/getUser'];
      let data;
      if (user.super_admin) {
        data = await PartnerWorkspaceService.fetchWorkspaces();
      } else {
        data = await PartnerWorkspaceService.fetchWorkspaceNonAdmin();
      }
      commit('setWorkspaces', data);
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  async createWorkspace({ commit, dispatch, rootGetters }, data) {
    try {
      const user = rootGetters['app/getUser'];
      if (user.super_admin) {
        await PartnerWorkspaceService.createdWorkspace(data.id, data.workspace);
      } else {
        await PartnerWorkspaceService.createWorkspaceNonAdmin(data.workspace);
      }
      dispatch('fetchWorkspaces');
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.createPartnerWorkspace'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
  async updateWorkspace({ commit, dispatch, rootGetters }, data) {
    try {
      const user = rootGetters['app/getUser'];
      if (user.super_admin) {
        await PartnerWorkspaceService.updateWorkspace(data.project_id, data.workspace);
      } else {
        await PartnerWorkspaceService.updateWorkspaceNonAdmin(data.project_id, data.workspace);
      }
      dispatch('fetchWorkspaces');
      if (data.showMessage) {
        commit('app/setReport', {
          type: 'success',
          message: i18n.t('uiComponents.reportMessages.updatePartnerWorkspace'),
          value: true,
        }, { root: true });
      }
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  async deleteWorkspace({ commit, dispatch, rootGetters }, id) {
    try {
      const user = rootGetters['app/getUser'];
      if (user.super_admin) {
        await PartnerWorkspaceService.deleteWorkspace(id);
      } else {
        await PartnerWorkspaceService.deleteWorkspaceNonAdmin(id);
      }
      dispatch('fetchWorkspaces');
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.deletePartnerWorkspace'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
};

export const partnerWorkspaces: Module<IPartnerWorkspacesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
