
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import IconList from '@/ui/components/components/IconList.vue';
import ComboboxField from '@/ui/components/modals/components/form/ComboboxField.vue';
import { getDefaultRulesObject } from '@/utils/utilsFunctions';
import { FormUpdateTracker } from '@/ui/mixins/formUpdateTracker';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IVariable } from '@/types/variables.types';
import { cloneDeep, isEqual } from 'lodash';
import { IRoom } from '@/types/rooms.types';
import { IProject } from '@/types/project.types';
import { IRule } from '@/types/rules.types';
import { IDevice, IDevicesTypes, IDeviceMapping } from '@/types/devices.types';
import IconService from '@/services/IconService';
import { Icon, TextMapping } from '@/types/common.types';

/**
 * Scheme to create, modify a specific device.
 * Specified in the device type definition in store/devices/devicesTypes.ts
 */
@Component({
  components: {
    IconList,
    ComboboxField,
  },
})
export default class DropDownSchema extends mixins(FormUpdateTracker) {
  @Prop() deviceData!: IDevice;
  @Prop() isEditModal!: boolean
  @Prop({ default: '' }) activeRoomId!: string;

  @State('measurements') measurementsState!: IVariablesState;
  @Getter('rooms/sortedRoomsByName') sortedRoomsByName!: IRoom[];
  @Getter('devices/devicesTypes') devicesTypes!: IDevicesTypes;
  @Getter('projects/project') project!: IProject;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;
  @Action('rules/addRules') addRules!: (payload: {
    project_id: string;
    rulesList: IRule[];
  }) => Promise<IRule[]>;
  @Action('rules/deleteRule') deleteRule!: (payload: { project_id: string; rule_id: string }) => Promise<void>;
  @Getter('variables/variablesForComboBox') variablesForComboBox!: IVariable[];

  stage = 1
  localDeviceData: Partial<IDevice> = {};
  showOptionalFields = false;

  iconObject?: Icon
  icons: Icon[] = []

  textMapping: TextMapping[] = []
  textMappingSnapshot: TextMapping[] = []

  @Watch('stage')
  onStageChange(val: number) {
    if (val === 2) {
      this.fetchVariables(this.$route.params.id);
    }
  }

  // Overwrite FormUpdateTracker default isFormUnchanged
  get isFormUnchanged() {
    const isTextMappingEqual = isEqual(this.textMapping, this.textMappingSnapshot);
    if (this.isEditModal) {
      const isDeviceDataEqual = isEqual(this.deviceData, this.localDeviceData);
      return isDeviceDataEqual && isTextMappingEqual;
    } else {
      return isEqual(this.dataSnapshot, this.localDeviceData);
    }
  }

  removeIcon() {
    this.localDeviceData.data.meta.cover = '';
  }

  iconTheme() {
    return { filter: this.$vuetify.theme.dark ? 'brightness(0) invert(1)' : null };
  }

  // steps validation
  get nameTypeValidation() {
    return !!this.localDeviceData?.name?.length;
  }

  /**
   * Checks mappings if they not valid
   * @return true if mapping filled, else false
   */
  mappingValidation() {
    if (this.stage === 2) {
      const optionalFields = Object.entries(this.deviceMappings)
        .filter((field: [string, IDeviceMapping]) => !field[1].optional)
        .map((field: [string, IDeviceMapping]) => field[0]);
      return optionalFields.map((el: string) => this.localDeviceData.data.mappings[el]).every((el: string) => el);
    } else {
      return false;
    }
  }
  get settingsValidation() {
    return !!this.localDeviceData.collection_id?.length;
  }

  get deviceSchema() {
    return this.devicesTypes[this.deviceData.data.type];
  }
  get deviceMappings() {
    return this.deviceSchema.mappings;
  }
  get deviceMappingsList() {
    if (this.localDeviceData?.data?.type) {
      return Object.keys(this.deviceSchema.mappings);
    }
    return [];
  }

  mapToObj(map: Map<string, string>) {
    const obj: Record<string, string> = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const [k, v] of map) obj[k] = v;
    return obj;
  }
  makeArrayOfJsonObj() {
    const convertedArr: TextMapping[] = [];
    Object.keys(this.deviceData.data.meta.dropDownTexts.DropDown_textmapping).forEach((element: string) => {
      convertedArr.push({ key: element, value: this.deviceData.data.meta.dropDownTexts.DropDown_textmapping[element] });
    });
    this.textMapping = convertedArr;
  }

  addMapTest() {
    this.textMapping.push({ key: '', value: '' });
  }
  deleteVariable(index: number) {
    this.textMapping.splice(index, 1);
  }

  /**
   * Search icons by inserted name
   * @param q name of the icon
   * @return list of icons
   */
  async search(q: string) {
    // clear icons if the query is smaller than 3 chars
    if (q.length < 3) {
      this.icons = [];
      return Promise.resolve();
    }

    return IconService.fetchIcons(q).then((res: Icon[]) => {
      this.icons = res.map((icon: Icon) => ({ term: icon.term, id: icon.id }));
    });
  }
  onSelected(icon: Icon) {
    this.iconObject = icon;
  }

  /**
   * Goes through device mappings list and create options for every item
   * @return form of mappings options
   */
  initMappingsForDevice() {
    let mappingsSchema: Record<string, string> = {};
    Object.keys(this.devicesTypes[this.deviceData.data.type].mappings).forEach((item: string) => {
      mappingsSchema = { ...mappingsSchema, [item]: '' };
    });
    return mappingsSchema;
  }

  /**
   * Creates rules for device according to ShowEvent_errorWarningState field,
   * save ids of created rules in current device object
   * @param deviceData current device data object
   */
  async addRulesWhenCreateDevice(deviceData: IDevice) {
    if (!deviceData.data.mappings.ShowEvent_errorWarningState.length) return;
    const rulesList: IRule[] = getDefaultRulesObject(deviceData.name, deviceData.data.mappings.ShowEvent_errorWarningState, 'Device');
    if (this.project.id) {
      const res = await this.addRules({
        project_id: this.project.id,
        rulesList,
      });
      deviceData.data.meta.warningRule = res[0].id;
      deviceData.data.meta.errorRule = res[1].id;
    }
  }

  /**
   * Replacing existing rules with new ones when ShowEvent_errorWarningState field is changed
   * @param deviceData current device data object
   */
  async addRulesWhenEditDevice(deviceData: IDevice) {
    if (!this.project.id) return;
    const oldErrorWarningVar = this.deviceData.data.mappings.ShowEvent_errorWarningState;
    const newErrorWarningVar = deviceData.data.mappings.ShowEvent_errorWarningState;
    // if errorWarning was changed
    if (oldErrorWarningVar && !newErrorWarningVar) {
      await Promise.all([
        this.deleteRule({
          project_id: this.project.id,
          rule_id: deviceData.data.meta.warningRule,
        }),
        this.deleteRule({
          project_id: this.project.id,
          rule_id: deviceData.data.meta.errorRule,
        }),
      ]);
    }
    if (oldErrorWarningVar !== newErrorWarningVar && newErrorWarningVar) {
      // delete old rules
      if (oldErrorWarningVar.length) {
        await Promise.all([
          this.deleteRule({
            project_id: this.project.id,
            rule_id: deviceData.data.meta.warningRule,
          }),
          this.deleteRule({
            project_id: this.project.id,
            rule_id: deviceData.data.meta.errorRule,
          }),
        ]);
      }
      const rulesList: IRule[] = getDefaultRulesObject(deviceData.name, deviceData.data.mappings.ShowEvent_errorWarningState, 'Device');
      // create new rules
      const res: IRule[] = await this.addRules({
        project_id: this.project.id,
        rulesList,
      });
      const getWarningRule = () => {
        const warningRuleObj: IRule | undefined = res.find((rule: IRule) => rule.name === `${deviceData.name} Warning Rule`);
        return warningRuleObj?.id;
      };
      const getErrorRule = () => {
        const errorRuleObj: IRule | undefined = res.find((rule: IRule) => rule.name === `${deviceData.name} Error Rule`);
        return errorRuleObj?.id;
      };
      deviceData.data.meta.warningRule = getWarningRule();
      deviceData.data.meta.errorRule = getErrorRule();
    }
  }

  /**
   * Save device data in data base
   */
  async sendForm() {
    this.$emit('onFormUnchanged', true);
    const copy = JSON.parse(JSON.stringify(this.localDeviceData));

    copy.data.meta.deviceSchema = { ...this.deviceSchema.devicesSchemas };
    copy.data.meta.minMaxBorders = {};

    // icon
    const checkIsCoverExist = () => (this.localDeviceData.data?.meta?.cover ? this.localDeviceData.data?.meta?.cover : null);
    copy.data.meta.cover = this.iconObject ? this.iconObject.id : checkIsCoverExist();
    copy.data.meta.coverType = 'icon';

    // text mappings
    const DropDown_textmapping = new Map();
    Object.values(this.textMapping).forEach((element: TextMapping) => {
      DropDown_textmapping.set(element.key, element.value);
    });
    const myJson: Record<string, Record<string, string>> = {};
    myJson.DropDown_textmapping = this.mapToObj(DropDown_textmapping);
    copy.data.meta.dropDownTexts.DropDown_textmapping = this.mapToObj(DropDown_textmapping);

    // add rules
    if (!this.isEditModal) {
      await this.addRulesWhenCreateDevice(copy);
    } else {
      await this.addRulesWhenEditDevice(copy);
    }

    this.$emit('handleControl', copy);
    this.$emit('closeDialog');
  }

  async created() {
    const copy = cloneDeep(this.deviceData);
    if (this.activeRoomId.length && !this.isEditModal) copy.collection_id = this.activeRoomId;

    if (!this.deviceData.data.meta?.hasOwnProperty('dropDownTexts')) {
      copy.data.meta = {
        dropDownTexts: {
          DropDown_title: '',
        },
      };
    } else {
      this.textMapping = copy.data.meta.dropDownTexts.DropDown_textmapping;
      this.makeArrayOfJsonObj();
    }
    this.localDeviceData = copy;

    // init schema if device create
    this.localDeviceData.data.mappings = this.initMappingsForDevice();
    this.localDeviceData.data.mappings = { ...this.localDeviceData.data.mappings, ...this.deviceData.data.mappings };

    if (!this.isEditModal) this.dataSnapshot = cloneDeep(this.localDeviceData);

    this.textMappingSnapshot = cloneDeep(this.textMapping);
  }
}
