import api from '@/services/api';
import { IPublishRecord } from '@/types/mqtt.types';

export default {
  async fetchMeasurements(projectId: string) {
    return api.fetch(`/projects/${projectId}/measurements`, 'GET');
  },

  async sendMeasurements(projectId: string, measurements: IPublishRecord[]) {
    return api.fetch(`/projects/${projectId}/measurements`, 'POST', JSON.stringify(measurements));
  },
};
