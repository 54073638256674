
import { Vue, Component, Prop } from 'vue-property-decorator';
import SliderBase from '@/ui/components/devices/devices/base/SliderBase.vue';
import SwitchBase from '@/ui/components/devices/devices/base/SwitchBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';

/**
 * Component that represent LightDimmer device
 */
@Component({
  components: {
    SliderBase,
    SwitchBase,
    OutputFieldBase,
    DeviceLayout,
  },
})
export default class LightDimmer extends Vue {
  @Prop() deviceData!: any;
  @State('measurements') measurementsState!: IVariablesState;

  get measurements() {
    return this.measurementsState.measurements;
  }
  get isActive() {
    return !!this.measurements.get(this.deviceData.data.mappings.Switch_state);
  }
  get sliderActualValue() {
    return this.measurements.get(this.deviceData.data.mappings.OutputField_actualValue);
  }
}
