
import { Vue, Component, Prop } from 'vue-property-decorator';
import SystemModalWindowView from '@/ui/components/devices/components/EnergyParts/SystemModalWindowView.vue';
import InstancesGridView
  from '@/ui/components/devices/previews/mpc/SetpointOptimizer/system/InstancesGridView.vue';
import Preview from '@/ui/components/devices/components/EnergyParts/Preview.vue';
import AnimatedLine from '@/ui/components/devices/components/EnergyParts/AnimatedLine/index.vue';

@Component({
  components: {
    InstancesGridView,
    SystemModalWindowView,
    Preview,
    AnimatedLine,
  },
})
export default class System extends Vue {
  @Prop({ default: null }) systemInstanceType!: string;
  @Prop({ default: null }) systemData!: any;
  @Prop({ default: null }) systemCount!: number;
  @Prop({ default: null }) systemColors!: any;
  @Prop({ default: null }) canvasSize!: any;
  @Prop({ default: null }) canvasCenter!: any;
  @Prop({ default: null }) lineData!: any;
  @Prop({ default: false }) lineReady!: boolean;

  /**
   * Defines current system icon
   * @return {string} current icon
   */
  get currentPreviewIcon() {
    return ({
      heating_air_systems: 'heating_air',
      heating_water_systems: 'hot-water',
      cooling_air_systems: 'cooling_air',
      cooling_water_systems: 'cold-water',
      hybrid_water_systems: 'hybrid_water',
      hybrid_air_systems: 'hybrid_air',
    } as any)[this.systemInstanceType];
  }

  /**
   * Collection of systems line movement directions
   */
  get lineMovementSystems(): any {
    return {
      heating_air_systems: false,
      heating_water_systems: false,
      cooling_air_systems: false,
      cooling_water_systems: false,
      hybrid_water_systems: false, // value will be getter
      hybrid_air_systems: false, // value will be getter
    };
  }

  /**
   * Defines line movement direction for current system
   * @return {boolean} if true arrows moves in default direction else in opposite direction
   */
  get lineMovement() {
    return this.lineMovementSystems[this.systemInstanceType];
  }
}
