
import { Vue, Component, Watch } from 'vue-property-decorator';
import LynusPopup from '@/ui/components/components/LynusPopup.vue';
import UserService from '@/services/UserService';
import { IFetchPermissionUserResponse } from '@/types/common.types';
import { IMember } from '@/types/members.types';
import { UserRoleCode, checkProtocolAccessList } from '@/utils/userRoles';
import { defaultRoomIdObject, addDefaultRoomToUser, getIsMemberInRulesAsRecipient, addMemberToRuleActions, deleteRecipientFromRules } from '@/utils/membersUtils';
import { IPartnerWorkspace } from '@/types/partnerWorkspace.types';
import { Action, Getter, State } from 'vuex-class';
import { IRoom } from '@/types/rooms.types';
import { IRulesState } from '@/store/modules/rules/types';
import { IRule } from '@/types/rules.types';
import { IProjectsState } from '@/store/modules/projects/types';
import { cloneDeep, has } from 'lodash';
import ManageCollectionsModal from '@/ui/components/components/ManageCollectionsAccessModal.vue';
import { IAppState } from '@/store/modules/app/types';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { IProject, IProjectConnectivity } from '@/types/project.types';

@Component({
  components: {
    LynusPopup,
    ManageCollectionsModal,
    CircleSpinner,
  },
  computed: {
    checkProtocolAccessList: () => checkProtocolAccessList,
    UserRoleCode: () => UserRoleCode,
  },
})
export default class Permissions extends Vue {
  @State('app') appState!: IAppState;
  @State('rules') rulesState!: IRulesState;
  @State('projects') projectsState!: IProjectsState;
  @Getter('members/members') members!: IMember[];
  @Getter('rooms/rooms') rooms!: IRoom[];
  @Getter('partnerWorkspaces/workspace') workspace!: IPartnerWorkspace;
  @Getter('partnerWorkspaces/workspaceById') workspaceById!: (id: string) => any;
  @Getter('projects/projects') projects!: IProject[];
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;
  @Action('members/createMember') createMember!: (data: {member: IMember; showMessage: boolean}) => Promise<IMember>;
  @Action('members/updateMember') updateMember!: (m: IMember) => Promise<IMember>;
  @Action('members/deleteMember') deleteMember!: (m: IMember) => Promise<IMember>;
  @Action('projects/loadProject') loadProject!: (projectId: string) => Promise<void>;
  @Action('devices/fetchDevices') fetchDevices!: () => Promise<void>;
  @Action('mpc/fetchMPCListByProject') fetchMPCListByProject!: () => Promise<void>;
  @Action('rooms/fetchRooms') fetchRooms!: (projectId: string) => any;
  @Action('rules/loadRules') loadRules!: (project_id: string) => Promise<IRule[]>;

  // User Search
  model = null;
  search = '';
  items: IFetchPermissionUserResponse[] = [];
  loading = false;
  selectedUserObject: IMember | null = null;
  conModel: IProjectConnectivity = {
    actions: [],
    enabled: false,
  };
  showLoading = false;
  loadingText = '';
  showNoProjectConnected = false;
  showUserNotInProject = false;
  userNotAdmin = false;

  @Watch('model')
  async onSelected(val: IMember | null) {
    if (val === null) return;

    // use the first items because there will always
    // only be one result
    const newMember = await this.createMember({ member: this.items[0] as any, showMessage: true });
    if (newMember !== null) {
      this.handleAddDefaultRooms(UserRoleCode.readOnly, newMember);
    }

    // clear the autocomplete
    this.model = null;
    this.items = [];
    this.search = '';
  }

  @Watch('search')
  async fetchUsers(val?: string) {
    if (this.loading || val == null) return;
    if (!this.isEmailValid(val)) {
      this.items = [];
      return;
    }
    this.loading = true;
    try {
      this.items = await UserService.fetchUserByEmail(val);
    } catch (e) {
      // this.setReport({ type: 'error', message: e.message, value: true });
    } finally {
      this.loading = false;
    }
  }

  // clear items @blur, otherwise it will still show
  // the old result when focusing the autocomplete
  clearItems() {
    this.items = [];
    this.search = '';
  }

  /**
   * Validates email
   * @param {string} s email
   */
  isEmailValid(s: string): boolean {
    // https://stackoverflow.com/a/574698
    if (s.length < 3 || s.length > 254) return false;

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(s.toLowerCase());
  }
  // end user search

  // Table
  get connectedProjectId() {
    return this.workspace.meta?.project_id ?? '';
  }

  get availableMemberRoles() {
    return [
      { text: 'Admin', value: UserRoleCode.admin },
      { text: 'User', value: UserRoleCode.user },
      { text: 'Readonly', value: UserRoleCode.readOnly },
    ];
  }

  get tableHeaders() {
    return [
      {
        text: this.$t('uiComponents.settings.permissions.table.name'),
        value: 'name',
        width: '25%',
        sortable: false,
      },
      {
        text: this.$t('uiComponents.settings.permissions.table.email'),
        value: 'email',
        width: '25%',
        sortable: false,
      },
      {
        text: this.$t('uiComponents.settings.permissions.table.userRole'),
        value: 'role',
        width: '30%',
        sortable: false,
      },
      {
        text: this.$t('uiComponents.settings.permissions.table.notification'),
        value: 'notification',
        width: '10%',
        sortable: false,
      },
      {
        text: this.$t('uiComponents.settings.permissions.table.actions'),
        value: 'actions',
        width: '10%',
        sortable: false,
      },
    ];
  }

  get tableItems() {
    if (this.connectedProjectId === '') {
      return [];
    }
    return this.members.map((member: IMember) => {
      return {
        ...member,
        notification: false,
        origin: member,
      };
    }).filter((member: IMember) => !checkProtocolAccessList.includes(member.email));
  }

  get project() {
    return this.projectsState.project;
  }

  get rulesList() {
    return this.rulesState.rulesList;
  }

  get membersNotificationMap() {
    return this.members.reduce((acc: Record<string, boolean>, m: IMember) => {
      return { ...acc, [m.email]: getIsMemberInRulesAsRecipient(m, this.rulesList, this.conModel) };
    }, {});
  }
  get rulesWithEmailAction(): any {
    return this.rulesState.rulesList.map((rule: IRule) => {
      if (rule.actions.some((action) => action.type === 'email')) {
        return rule;
      } else {
        return undefined;
      }
    }).filter((rule) => rule !== undefined);
  }
  get rulesWithNoMailAction() {
    return this.rulesState.rulesList.map((rule: IRule) => {
      if (!rule.actions.some((action) => action.type === 'email')) {
        return rule;
      } else {
        return undefined;
      }
    }).filter((rule) => rule !== undefined);
  }
  get notActiveRules() {
    return this.rulesState.rulesList.filter((rule: IRule) => !rule.active);
  }

  get user() {
    return this.appState.user;
  }

  get defaultRoomIdObject() {
    return defaultRoomIdObject(this.rooms);
  }

  showMembersNotificationCheckbox(key: string) {
    return has(this.membersNotificationMap, key);
  }

  /**
   * Adds or removes member from all rules
   */
  async handleUpdateRules(event: any, email: string) {
    event.stopPropagation();
    event.preventDefault();
    this.showLoading = true;
    this.loadingText = this.$t('uiComponents.settings.permissions.notificationsGetUpdated') as string;
    const hasEmail = has(this.membersNotificationMap, email);
    if (hasEmail && this.membersNotificationMap[email]) {
      await deleteRecipientFromRules(email, this.connectedProjectId, this.rulesWithEmailAction, this.conModel);
    } else if (hasEmail && !this.membersNotificationMap[email]) {
      await addMemberToRuleActions(email, this.connectedProjectId, this.rulesWithEmailAction, this.rulesWithNoMailAction, this.conModel);
    }
    this.showLoading = false;
  }

  async handleAddDefaultRooms(role: UserRoleCode, newMember: IMember) {
    await addDefaultRoomToUser(role, newMember, this.defaultRoomIdObject, this.connectedProjectId);
  }

  /**
   * Updates role
   * @param {number} value v-select (code of user role)
   * @param {object} member current member
   */
  async onRoleChange(value: UserRoleCode, member: IMember) {
    const updatedUser = await this.updateMember({ ...member, ...{ role: value } });
    if (value === UserRoleCode.admin) return;
    this.handleAddDefaultRooms(value, updatedUser);
  }

  async created() {
    this.showLoading = true;
    this.loadingText = this.$t('uiComponents.settings.permissions.loadingProjectData') as string;
    // set workspace in store
    const { id } = this.$route.params;
    const workspace = this.workspaceById(id);
    const canUserAccessProject = this.projects.some((project) => project.id === workspace.meta.project_id);
    // display messages if user is not in project or no project is connected
    if (!canUserAccessProject) {
      this.showUserNotInProject = true;
      this.showLoading = false;
      return;
    } else if (this.connectedProjectId === '') {
      this.showNoProjectConnected = true;
      this.showLoading = false;
      return;
    }
    // needs to be loaded first to get the project_id for fetch devices
    await this.loadProject(workspace.meta.project_id);
    // load all the data needed from the project
    await Promise.allSettled([
      this.fetchMembers(workspace.meta.project_id),
      this.fetchDevices(),
      this.fetchMPCListByProject(),
      this.fetchRooms(workspace.meta.project_id),
      this.loadRules(workspace.meta.project_id),
    ]);

    // check if current user is admin
    const currentUser = this.members.find((m) => m.id === this.user.id);
    if (currentUser === undefined || currentUser.role !== UserRoleCode.admin) {
      this.userNotAdmin = true;
      return;
    }

    this.conModel = cloneDeep(this.project.connectivity);
    this.showLoading = false;
  }
}
