
import { Vue, Component, Prop } from 'vue-property-decorator';
import EnergyFlowProductionChart
  from '@/ui/components/devices/mpc/components/chartsWindow/SelfConsumption/EnergyFlowProductionChart.vue';
import EnergyFlowConsumptionChart
  from '@/ui/components/devices/mpc/components/chartsWindow/SelfConsumption/EnergyFlowConsumptionChart.vue';
import EnergyFlowConsumerChart from '@/ui/components/devices/components/EnergyParts/EnergyView/components/charts/SingleSystemsView.vue';
import { IDevice } from '@/types/devices.types';

@Component({
  components: {
    EnergyFlowProductionChart,
    EnergyFlowConsumptionChart,
    EnergyFlowConsumerChart,
  },
})
export default class SelfConsumption extends Vue {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: '' }) mpcId!: string;
  @Prop() settingsWrapperHeight!: number;
  @Prop({ default: false }) isMpc!: boolean;
  @Prop({ default: false }) isHybridSystem!: boolean;

  tab = null
  shownSystemsEnergyFlowConsumer: string[] = ['charge_station', 'electric_heating', 'heating_pump', 'big_consumer', 'house'];

  consumerSystemsVars = {
    charge_station: ['power'],
    electric_heating: ['power'],
    heating_pump: ['power'],
    big_consumer: ['power'],
    pv: ['power'],
    chp: [
      'power', 'thermal_power_output', 'flow_temperature', 'return_temperature',
      'buffer_temperature_top', 'buffer_temperature_bottom',
    ],
    generator: ['power'],
    grid: ['power'],
    battery: ['power', 'soc'],
    house: ['power'],
  };

  get items() {
    return [
      {
        title: this.$t('mlModel.EMS.charts.chartDifference.energyFlow'),
        view: 'EnergyFlowProductionChart',
      },
      {
        title: this.$t('mlModel.EMS.charts.chartDifference.energyFlowConsumer'),
        view: 'EnergyFlowConsumerChart',
        numberOfSeriesPerChart: 8,
        excludeHouse: true,
      },
    ];
  }

  getNumberOfSeriesPerChart(item: Record<string, any>) {
    return item?.numberOfSeriesPerChart;
  }
}
