
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent data export button
 */
@Component
export default class DataExport extends Vue {
  @Prop() dataToExport!: any;
  @Prop({
    default: () => ({ picker: '', currentPeriod: false }),
  }) navigator?: any;
  @Prop({
    default: () => ({ data: { type: '' } }),
  }) chartData?: any;

  /**
   * Preparation of data for export into csv
   */
  exportData() {
    let fileName = '';
    const doc = 'data:text/csv;charset=utf-8,';
    let tableHeader = 'time';
    this.dataToExport.forEach((item: any) => {
      fileName += item.name;
      tableHeader += `;${item.name}`;
    });
    let table = doc + tableHeader;

    const isDay = this.navigator.picker === 'day';
    const isColumn = this.chartData.data.type === 'ColumnChart';

    let datePeriod = this.dataToExport[0].data.map((item: any) => [item[0]]);

    // add current time into the end of period if column chart and current day
    if (isColumn && this.navigator.currentPeriod) {
      const d = new Date();
      d.setSeconds(0, 0);
      let copy = [...this.dataToExport[0].data];
      copy.reverse();
      const idx = copy.findIndex((val: any) => typeof val[1] === 'number');
      copy[idx][0] = d.getTime();
      copy = copy.map(item => [item[0]]).reverse();
      datePeriod = copy;
    }

    const handleDateFormat = (date: any) => {
      if (isDay) {
        return new Date(date).toLocaleString('de-GE');
      }
      return new Date(date).toLocaleDateString('de-GE');
    };
    // hide time for week, month, year
    datePeriod = isColumn
      ? this.dataToExport[0].data.map((item: any) => [handleDateFormat(item[0])])
      : this.dataToExport[0].data.map((item: any) => [new Date(item[0]).toLocaleString('de-GE')]);

    this.dataToExport.forEach((serie: any) => {
      serie.data.forEach((item: any, index: any) => {
        datePeriod[index] = [...datePeriod[index], Intl.NumberFormat('de-GE').format(item[1])];
      });
    });

    // convert date periods for table
    datePeriod.forEach((item: any) => {
      table += `\n${item.join(';')}`;
    });

    const data = encodeURI(table);
    // create link to download data
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', `${fileName}.csv`);
    link.click();
  }
}
