
import { Component, Prop, Watch } from 'vue-property-decorator';
import BasicControl, { SendingState } from '@/ui/components/devices/devices/BasicControl';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';
import { defaultFeedbackTimeoutMilliSeconds } from '@/utils/constants';

/**
 * Component that represent Switch2V Basic Control
 */
@Component({
  components: {
    FeedbackProvider,
  },
})
export default class Switch2VBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop({ default: defaultFeedbackTimeoutMilliSeconds }) feedBackTimeout!: number;

  timer?: NodeJS.Timeout;
  localState?: boolean;

  /**
   * Used to disable the Base component if there is no variable set in the mappings of the device.
   */
  get isNotMapped() {
    return this.mappingsClean.onOff === ''
      || this.mappingsClean.state === '';
  }

  get state() {
    if (this.mappingsClean.onOff === '' && this.mappingsClean.state === '') {
      return false;
    }
    if (this.mappingsClean) return this.measurements.get(this.mappingsClean.state) === 1;
    return false;
  }

  @Watch('state')
  onStateChange(val: boolean) {
    if (this.sendingState === SendingState.Sending && this.localState === val) {
      this.onSendSuccess();
      clearTimeout(this.timer);
    }
  }

  async handleState(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    // new desired local state is opposite of current state
    this.localState = !this.state;
    this.onSendStart();
    this.send([{ v: +(!(this.state)), n: this.mappingsClean.onOff, u: '' }]);
    this.timer = setTimeout(async () => {
      // state was not updated -> reset
      // send opposite of original state
      this.onSendError();
      this.send([{ v: +(!!(this.localState)), n: this.mappingsClean.onOff, u: '' }]);

      // 500ms delay until we send second reset message
      await new Promise<void>((resolve) => setTimeout(() => {
        this.send([{ v: +(!this.localState), n: this.mappingsClean.onOff, u: '' }]);
        resolve();
      }, 500));
    }, this.feedBackTimeout);
  }

  destroyed() {
    clearTimeout(this.timer);
  }
}
