
import { Vue, Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { IMQTTState } from '@/store/modules/mqtt/types';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';

/**
 * Component that represent InternetStatusIndicator of the project
 * Used in AppBar.vue
 * Shows current internet connection by watching hb2 mqtt variable
 */
@Component({
  components: { InfoTooltip },
})
export default class InternetStatusIndicator extends Vue {
  @State('mqttClient') mqttState!: IMQTTState;

  get internetStatus() {
    return this.mqttState.internet;
  }

  get icon() {
    return this.internetStatus ? 'mdi-access-point-network' : 'mdi-access-point-network-off';
  }
}
