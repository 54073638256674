
import { IMember } from '@/types/members.types';
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

/**
 * Component that represent project members list on Favorites page
 */
@Component
export default class MembersWidget extends Vue {
  @Getter('members/members') members!: IMember[];

  model = null

  get iconSizes() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 36;
      case 'sm': return 36;
      case 'md': return 40;
      case 'lg': return 57;
      case 'xl': return 57;
    }
  }

  initials(m: IMember): string {
    return m.first_name.charAt(0).toUpperCase()
      + m.last_name.charAt(0).toUpperCase();
  }
}
