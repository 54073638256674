
import { Component, Prop } from 'vue-property-decorator';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import DropDownBase from '@/ui/components/devices/devices/base/DropDown.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent DropDownOutputFieldWithVariable device
 */
@Component({
  components: {
    OutputFieldBase,
    DropDownBase,
    DeviceLayout,
  },
})
export default class DropDownOutputFieldWithVariable extends mixins(Validation) {
  @Prop() deviceData!: IDevice;

  iconTheme() {
    return { filter: this.$vuetify.theme.dark ? 'brightness(0) invert(1)' : null };
  }
}
