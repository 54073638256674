
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import TimezoneChooser from '@/ui/components/components/TimezoneChooser.vue';
import { Getter, Action } from 'vuex-class';
import { minutesToTime, timeToMinutes } from '@/utils/utilsFunctions';
import moment from 'moment';
import { CanAccessCheck } from '@/types/members.types';

/**
 * Component that represent EMS settings view.
 * Contains navigation tabs. EMS settings and Mode settings.
 */
@Component({
  components: {
    TimezoneChooser,
  },
})
export default class SettingsEMS extends mixins(Validation) {
  @Prop({ default: null }) mpcData!: any; // data was loaded from mpc/fetchMPCData (loads full mpc instance data by id)
  @Prop({ default: null }) deviceData!: any; // data was loaded from mpc/fetchMPCListByProject (loads a list of mps but with incomplete data)
  @Getter('members/canMemberAccess') canMemberAccess!: CanAccessCheck;
  @Action('mpc/updateTariffSettings') updateTariffSettings!: any;

  tariffMode: null | number = null;

  start_low: any = null;
  end_low: any = null;
  price_low: any = null;
  price_high: any = null;
  timezone!: string;
  heatingPumpBuffer!: string;
  get modeSettingsDisabled() {
    return !this.canMemberAccess('Devices', 'BasicControls');
  }

  /**
   * Mode settings
   */
  get emsSettingsModesItems() {
    const lang: any = this.$t('mlModel.EMS.settingsView.tariffModes');
    return [
      { value: 0, name: lang.energyOptimized },
      { value: 1, name: lang.balancedOptimization },
      { value: 2, name: lang.tariffOptimized },
      { value: 3, name: lang.disabledMode },
    ];
  }

  get startLowMinutes() {
    const reg = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(this.start_low);
    return reg ? timeToMinutes(this.start_low) : null;
  }
  get endLowMinutes() {
    const reg = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(this.end_low);
    return reg ? timeToMinutes(this.end_low) : null;
  }
  get startHighMinutes() {
    if (typeof this.endLowMinutes !== 'number') return null;
    return this.endLowMinutes === 1439 ? 0 : this.endLowMinutes + 1;
  }
  get endHighMinutes() {
    if (typeof this.startLowMinutes !== 'number') return null;
    return this.startLowMinutes === 0 ? 1439 : this.startLowMinutes - 1;
  }

  get startHighTime() {
    if (typeof this.startHighMinutes !== 'number') return null;
    return minutesToTime(this.startHighMinutes);
  }
  get endHighTime() {
    if (typeof this.endHighMinutes !== 'number') return null;
    return minutesToTime(this.endHighMinutes);
  }

  /**
   * Tariff validation
   * @return {boolean} validation status
   */
  get tariffValidation() {
    const priceLow = typeof this.price_low === 'number';
    const priceHigh = typeof this.price_high === 'number';
    return (
      typeof this.startLowMinutes === 'number' &&
      typeof this.endLowMinutes === 'number' &&
      priceLow &&
      priceHigh
    );
  }

  onTimezoneChange(timezone: string) {
    this.timezone = timezone;
  }

  /**
   * Init tariff form fields.
   */
  initTariffForm() {
    this.tariffMode = this.deviceData.data.meta?.settings?.mode;
    this.start_low =
      typeof this.deviceData.data.meta?.settings?.energyPrice?.start_low === 'number'
        ? minutesToTime(this.deviceData.data.meta?.settings?.energyPrice?.start_low)
        : null;
    this.end_low =
      typeof this.deviceData.data.meta?.settings?.energyPrice?.end_low === 'number'
        ? minutesToTime(this.deviceData.data.meta?.settings?.energyPrice?.end_low)
        : null;
    this.price_low = this.deviceData.data.meta?.settings?.energyPrice?.price_low;
    this.price_high = this.deviceData.data.meta?.settings?.energyPrice?.price_high;

    this.timezone = this.deviceData.data.meta?.settings?.energyPrice?.timezone ?? moment.tz.guess();
    this.heatingPumpBuffer =
      this.deviceData.data.meta?.settings?.energyPrice?.heating_pump_buffer ?? {};
  }

  /**
   * Update tariff.
   */
  async handleTariffForm() {
    const converted = {
      start_low: this.startLowMinutes,
      end_low: this.endLowMinutes,
      start_high: this.startHighMinutes,
      end_high: this.endHighMinutes,
      price_low: this.price_low,
      price_high: this.price_high,
      timezone: this.timezone,
      heating_pump_buffer: this.heatingPumpBuffer,
    };

    const newTariff = {
      mode: this.tariffMode,
      energyPrice: { ...converted },
    };
    await this.updateTariffSettings({ mpc_id: this.mpcData.id, settings: newTariff });
  }

  created() {
    this.initTariffForm();
  }
}
