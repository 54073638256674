
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { throttle } from 'lodash';
import { IVariablesState } from '@/store/modules/measurements/types';
import { State } from 'vuex-class';

/**
 * Component that represent gauge chart for PVMonitoringService.
 */
@Component
export default class MonitoringGauge extends Vue {
  @Prop() deviceData!: any;
  @Prop() inputVariable!: any;
  @Prop({ default: null }) dndGridContainerWidth!: any;
  @Prop({ default: null }) reRenderKey!: any;
  @State('measurements') measurementsState!: IVariablesState;

  get measurements() {
    return this.measurementsState.measurements;
  }

  chartKey = 1;

  options: any = {
    arcLabels: ['25%', '50%', '75%'],
    needleValue: null,
    needleColor: 'black',
    needleStartValue: 0,
    hasNeedle: true,
    rangeLabel: ['0', '100'],
    arcDelimiters: [25, 50, 75],
    chartWidth: null,
    arcColors: [],
    needleUpdateSpeed: 0,
  }

  @Watch('actualValue')
  onActualValueChange(val: any) {
    this.setNeedleValue(val);
    this.chartKey += 1;
  }

  @Watch('reRenderKey')
  async onReRenderKeyChange(val: any) {
    await this.$nextTick();
    this.resizeChart();
  }

  get actualValue() {
    return this.measurements.get(this.inputVariable);
  }

  setOptions(optionsConfig: any) {
    const copy: any = JSON.parse(JSON.stringify(optionsConfig));
    this.options = { ...this.options, ...copy };
  }
  setNeedleValue(value: any) {
    this.options.needleValue = typeof value !== 'number' ? null : value;
  }

  async mounted() {
    await this.$nextTick();

    this.resizeChart();
    this.setOptions({
      needleValue: null,
      arcColors: [`${this.$vuetify.theme.currentTheme.error}`, `${this.$vuetify.theme.currentTheme.error}`, '#FF9800', '#30BF54'],
    });
    this.setNeedleValue(this.actualValue);
    window.addEventListener('resize', this.resizeChartThrottle);
    this.chartKey += 1;

    // load gauge width according to screen size
    if (!this.$vuetify.breakpoint.mobile) {
      const initWidth: number = +(this.dndGridContainerWidth / 4 - 60);
      const width: number = initWidth > 320 ? 320 : initWidth;
      this.setOptions({ chartWidth: width });
    }
  }

  /**
   * Updates gauge width according to screen size
   */
  resizeChart() {
    const element: any = this.$refs?.chartDiv;
    const isElementWidth: boolean = typeof element?.clientWidth === 'number' && element?.clientWidth < 320;
    const width = isElementWidth ? element.clientWidth : 320;
    this.setOptions({ chartWidth: width });

    this.chartKey += 1;
  }

  /**
   * Throttle wrapper for better performance
   */
  resizeChartThrottle = throttle(this.resizeChart, 200)

  destroyed() {
    window.removeEventListener('resize', this.resizeChartThrottle);
  }
}
