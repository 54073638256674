
import { Vue, Component } from 'vue-property-decorator';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';
import SettingsCharts from '@/ui/components/devices/previews/mpc/PVProductionService/SettingsCharts.vue';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';

@Component({
  components: {
    ShowEventDotMock,
    SettingsCharts,
    MPCLayout,
  },
})
export default class PVProductionServicePreview extends Vue {
  series = [
    {
      name: 'Valve Position',
      type: 'line',
      yAxis: 0,
      color: '#3450ae',
      data: [
        [1583100000000, 23],
        [1583103600000, 26],
        [1583107200000, 22],
        [1583110800000, 28],
        [1583114400000, 28],
        [1583118000000, 29],
        [1583121600000, 29],
      ],
    },
    {
      name: 'Temperature',
      type: 'line',
      yAxis: 1,
      color: '#50ab7e',
      data: [
        [1583100000000, 22],
        [1583103600000, 25],
        [1583107200000, 22],
        [1583110800000, 23],
        [1583114400000, 24],
        [1583118000000, 28],
        [1583121600000, 29],
      ],
    },
  ]
  yAxis = [
    {
      title: null,
      opposite: false,
      showFirstLabel: true,
      showLastLabel: true,
      endOnTick: false,
      gridLineWidth: 0,
      min: 0,
      max: 100,
      lineColor: '#3450ae',
      labels: {
        format: '{value} °C',
        style: {
          color: '#3450ae',
        },
      },
    },
    {
      title: null,
      opposite: false,
      showFirstLabel: true,
      showLastLabel: true,
      endOnTick: false,
      gridLineWidth: 0,
      min: 0,
      max: 100,
      lineColor: '#50ab7e',
      labels: {
        format: '{value}',
        style: {
          color: '#50ab7e',
        },
      },
    },
  ]

  get isITLocale() {
    return this.$i18n.locale === 'it';
  }

  get tabs() {
    return [
      {
        title: 'Forecast',
        chartType: 'line',
        chartWidth: null,
        series: this.series,
        yAxis: this.yAxis,
      },
      {
        title: 'Chart',
        chartType: 'line',
        chartWidth: null,
        series: this.series,
        yAxis: this.yAxis,
      },
    ];
  }
  get mainViewLang() {
    return 'mlModel.PVProductionService.mainView';
  }
  get previewNameLang() {
    return this.$t('mlModel.PVProductionService.previewName');
  }
}
