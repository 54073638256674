
import { Vue, Component, Prop } from 'vue-property-decorator';
import SingleSystemsView
  from '@/ui/components/devices/components/EnergyParts/EnergyView/components/charts/SingleSystemsView.vue';
import SelfConsumption
  from '@/ui/components/devices/mpc/components/chartsWindow/SelfConsumption/index.vue';

@Component({
  components: {
    SingleSystemsView,
    SelfConsumption,
  },
})
export default class EnergyViewCharts extends Vue {
  @Prop({ default: null }) allSystemsData!: any;
  @Prop({ default: null }) mpcData!: any;
  @Prop({ default: '' }) mpcId!: string;

  tab = null;
  shownSystemsSingleSystemsChart: string[] = ['charge_station', 'electric_heating', 'heating_pump', 'big_consumer', 'pv', 'generator', 'grid', 'battery', 'house'];

  get items() {
    return [
      {
        title: this.$t('devices.EnergyView.ChartsWindow.selfConsumption'),
        view: 'SelfConsumption',
      },
      {
        title: this.$t('devices.EnergyView.ChartsWindow.singleSystems'),
        view: 'SingleSystemsView',
      },
    ];
  }
}
