
import { Vue, Component, Prop } from 'vue-property-decorator';
import ChartControlVariables
  from '@/ui/components/devices/mpc/SetpointOptimizer/charts/ChartControlVariables.vue';
import ChartsRoomTemperatures
  from '@/ui/components/devices/mpc/SetpointOptimizer/charts/ChartsRoomTemperatures.vue';
import Forecast from '@/ui/components/devices/mpc/SetpointOptimizer/charts/Forecast.vue';

/**
 * Component that represent wrapper for charts tabs navigation.
 * Used in Setpoint Optimizer.
 */
@Component({
  components: {
    ChartControlVariables,
    ChartsRoomTemperatures,
    Forecast,
  },
})
export default class SetpointOptimizerCharts extends Vue {
  @Prop({ default: null }) allDeviceData!: any;

  tab: any = null

  get predictedSetpoint() {
    return this.allDeviceData.data.meta?.charts?.predictedSetpoint;
  }

  /**
   * Gets all systems from controllerMappings
   * @return {object} collection of systems
   */
  get systemsMappings() {
    const {
      heating_air_systems, heating_water_systems, cooling_air_systems,
      cooling_water_systems, hybrid_water_systems, hybrid_air_systems,
    } = this.allDeviceData.data.meta.controllerMappings;
    return {
      heating_air_systems, heating_water_systems, cooling_air_systems, cooling_water_systems, hybrid_water_systems, hybrid_air_systems,
    };
  }

  /**
   * Tabs for navigation.
   */
  get items() {
    return [
      {
        id: 'chartsRoomTemperatures',
        title: this.$t('mlModel.SetpointOptimizer.charts.navigation.chartsRoomTemperatures'),
        view: 'ChartsRoomTemperatures',
        props: {
          systemsMappings: this.systemsMappings,
        },
      },
      {
        id: 'chartControlVariables',
        title: this.$t('mlModel.SetpointOptimizer.charts.navigation.chartControlVariables'),
        view: 'ChartControlVariables',
        props: {
          systemsMappings: this.systemsMappings,
        },
      },
      {
        id: 'forecast',
        title: this.$t('mlModel.SetpointOptimizer.charts.navigation.forecast'),
        view: 'Forecast',
        props: {
          predictedSetpoint: this.predictedSetpoint,
          systemsMappings: this.systemsMappings,
        },
      },
    ];
  }
}
