
import { Component, Prop, Vue } from 'vue-property-decorator';
import EditReport from '@/ui/components/modals/ManageReport/EditReport.vue';
import { TranslateResult } from 'vue-i18n';

/**
 * Report page that shows reports list
 */
@Component({
  components: {
    EditReport,
  },
})
export default class Report extends Vue {
  @Prop({ default: '' }) reportListTitle!: string | TranslateResult;
  @Prop({ default: [] }) reportList!: any[];

  currentReport = null;
}
