import api from '@/services/api';
import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/store/types';
import { IChartsState } from '@/store/modules/charts/types';
import { measurements } from '../measurements';
import i18n from '@/ui/plugins/i18n';

const state: IChartsState = {
  chartsTypes: {
    'chart': {
      mappings: ['value'],
    },
    'LineChart': {
      mappings: ['value'],
    },
    'ColumnChart': {
      mappings: ['value'],
    },
    'AreaChart': {
      mappings: ['value'],
    },
    'GaugeChart': {
      mappings: ['value'],
    },
  },
};

const getters: GetterTree<IChartsState, RootState> = {
  charts(state, getters, rootState, rootGetters) {
    return rootGetters['devices/allDevices']
      .filter((device: any) => Object.keys(state.chartsTypes).some(item => item === device.data.type));
  },
};

const mutations: MutationTree<IChartsState> = {};

const actions: ActionTree<IChartsState, RootState> = {
  async fetchChart({ commit, rootState }, {
    projectId, variableName, from, to, agg, interval,
  }) {
    try {
      return api.fetch(
        `/projects/${projectId}/measurements/${variableName}/chart?start=${from}&end=${to}&agg=${agg}&int=${interval}`,
        'GET',
      );
    } catch (e) {
      commit('app/setReport', {
        type: 'info',
        message: i18n.t('errorMessages.infoTexts.variableNotDefined'),
        value: true,
      }, { root: true });
    }
  },
  async fetchChartByExactTime({ commit, rootState }, { projectId, chartName, time }) {
    return api.fetch(`/projects/${projectId}/measurements/${chartName}?time=${time}`, 'GET');
  },
};

export const charts: Module<IChartsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
