import api from '@/services/api';

// TODO: later need to move this interface to types folder
interface ActivateDataParam {
  latitude: number;
  longitude: number;
  username: string;
  password: string;
}

export default {
  async fetchStatus(projectId: string) {
    return api.fetchMPC(`/weather/${projectId}`, 'GET');
  },

  async activate(projectId: string, data: ActivateDataParam) {
    return api.fetchMPC(`/weather/${projectId}`, 'POST', data);
  },

  async deactivate(projectId: string) {
    return api.fetchMPC(`/weather/${projectId}`, 'DELETE');
  },
};
