import axios, { ResponseType } from 'axios';
import Vue from 'vue';
import { envApi, envMpcApi } from '@/utils/env';
import i18n from '@/ui/plugins/i18n';
import { app } from '@/store/modules/app';

export default class API {
  /**
   * Fetch data from lynus API
   * @param {string} url request URL path
   * @param {'GET'|'POST'|'PUT'|'DELETE'} method request method
   * @param body
   * @param {object} headers request headers
   * @param responseType
   */
  static fetch(url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', body?: unknown, headers?: any, responseType?: ResponseType): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({
        baseURL: envApi,
        url,
        responseType,
        headers: {
          authorization: `Bearer ${Vue.prototype.$keycloak.token}`,
          ...headers,
        },
        method,
        data: body,
      })
        .then(res => resolve(res.data))
        .catch(err => {
          const handledErrors = [400, 401, 500];
          if (handledErrors.includes(err.response.status)) {
            (app.mutations as any)?.setReport({ type: 'error', message: i18n.t(`errorMessages.apiErrorTexts.${err.response.status}`), value: true });
          } else {
            (app.mutations as any)?.setReport({ type: 'error', message: err.message, value: true });
          }
          reject(err);
        });
    });
  }

  /**
   * Fetch data from staging API
   * @param {string} url request URL path
   * @param {'GET'|'POST'|'PUT'|'DELETE'} method request method
   * @param body
   * @param {object} headers request headers
   */
  static fetchMPC(url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', body?: unknown, headers?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios({
        baseURL: envMpcApi,
        url,
        headers: {
          authorization: Vue.prototype.$keycloak.token,
          ...headers,
        },
        method,
        data: body,
      }).then(res => resolve(res.data)).catch(err => reject(err));
    });
  }
}
