
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent a basic popup
 */
@Component
export default class LynusPopup extends Vue {
  @Prop({ default: false }) showPopup!: boolean; // controls the visibility of the popup
  @Prop({ default: 700 }) width!: number; // width of the popup
  @Prop({ default: true }) showCloseButton!: boolean; // controls the visibility of the close button

  closeDialog() {
    this.$emit('closeLynusPopup');
  }
}
