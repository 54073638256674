
import { Vue, Component, Prop } from 'vue-property-decorator';
import AnomalyDetection from '@/ui/components/devices/anomalyDetection/AnomalyDetection.vue';
import { ChartData } from '@/types/chart.types';

/**
 * Stream anomaly detection device
 */
@Component({
  components: {
    AnomalyDetection,
  },
})
export default class StreamAnomalyDetection extends Vue {
  @Prop() deviceData!: ChartData;
  @Prop() chartWidth!: number;
}
