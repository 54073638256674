
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { IProject } from '@/types/project.types';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import {
  hybridVersionDate,
  newChargeStationLimitDate,
  plcVersionDate,
} from '@/utils/versionManagementUtils';
import installationWizardVariables
  from '@/ui/components/wizards/installationWizard/installationWizardVariables';
import { NavigationDirection } from '@/types/wizards/installationWizard.types';
import { IInverter } from '@/types/serialNumber.types';

@Component({
  components: {
    WizardContentView,
  },
})
export default class Information extends WizardComponent {
  @Getter('mqttClient/isProjectOnline') isProjectOnline!: boolean;
  @Getter('installationWizard/considerProjectStatus') considerProjectStatus !: boolean;
  @Getter('projects/project') project!: IProject;
  @Getter('serialNumber/inverters') inverters!: IInverter[];
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('projects/isMobileDeye') isMobileDeye!: boolean;
  @Getter('installationWizard/emsLimits') emsLimits!: Record<string, number>;
  @Mutation('installationWizard/setDisablePilotDevices') setDisablePilotDevices!: (value: boolean) => void;
  @Mutation('installationWizard/setSerialNumberCheckDone') setSerialNumberCheckDone!: () => void;
  @Action('installationWizard/initDevices') initDevices!: () => void;

  showAdditionalMessage = false;

  async created() {
    await this.fetchMeasurements(this.$route.params.id);
    if (this.measurements['prgEM.bEnable'] && this.measurements['prgEM.bEnable'] === 1) {
      this.showAdditionalMessage = true;
      this.setDisablePilotDevices(true);
    }
  }

  get isNewChargeStationPlcVersion() {
    return (plcVersionDate(this.project).getTime() > newChargeStationLimitDate.getTime()) && (this.isDeye || this.isSolarmax);
  }

  handleStart() {
    // initialise EnergyView and EMS device object in store
    this.initDevices();
    this.handleIncrement();
  }
}
