export interface IInverter {
  id: string;
  description: string;
  type: SerialNumberType;
  project_id: string | null;
  assigned_at: number | null;
  created_at: number;
}

export enum SerialNumberType {
  HybridInverter,
  StringInverter,
  ChargeStation,
}
