
import { Vue, Component } from 'vue-property-decorator';
import DeviceDescription from '@/ui/components/devices/actions/DeviceDescription.vue';
import PreviewActions from '@/ui/components/devices/actions/PreviewActions.vue';
import PushButtonMock from '@/ui/components/devices/previews/components/PushButtonMock.vue';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    DeviceDescription,
    PreviewActions,
    PushButtonMock,
    ShowEventDotMock,
    DeviceLayout,
  },
})
export default class TSGLadestationNotAusPreview extends Vue {
  get textMappingStatus() {
    return [
      { name: this.$t(`${this.mainViewLang}.dropDown1.value0`), value: 0 },
      { name: this.$t(`${this.mainViewLang}.dropDown1.value1`), value: 1 },
    ];
  }

  get textMappingEmergency() {
    return [
      { name: this.$t(`${this.mainViewLang}.dropDown2.value0`), value: 0 },
      { name: this.$t(`${this.mainViewLang}.dropDown2.value1`), value: 1 },
    ];
  }

  selected = 0;

  get mainViewLang() {
    return 'devices.TSGLadestationNotAus.mainView';
  }
  get description() {
    return this.$t('devicesDescriptions.TSGLadestationNotAus');
  }
  get previewNameLang() {
    return this.$t('devices.TSGLadestationNotAus.previewName');
  }
}
