
import EnergyPrice from '@/ui/views/Project/Settings/EnergyPrice.vue';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { IProject } from '@/types/project.types';

@Component({
  components: {
    EnergyPrice,
    WizardContentView,
  },
})
export default class CommonEnergyPriceSettings extends Vue {
  @Prop({ default: 'installationWizard' }) currentStoreName!: string;
  @Getter('projects/project') project!: IProject;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;

  valid = false;

  energyPrice: any = {
    buyPrice: 0,
    sellPrice: 0,
  }

  handleChange(value: {isValid: boolean; energyPrice: { buyPrice: number; sellPrice: number }}) {
    this.valid = value.isValid;
    this.energyPrice = value.energyPrice;
  }

  created() {
    if (this.project.meta?.energyPrice !== undefined) {
      this.energyPrice = this.project.meta?.energyPrice;
    }
  }

  handleBack() {
    this.$store.dispatch(`${this.currentStoreName}/handleDecrement`);
  }

  async handleNext() {
    await this.updateProject({
      ...this.project,
      meta: {
        ...this.project.meta,
        energyPrice: {
          buyPrice: parseFloat(this.energyPrice.buyPrice),
          sellPrice: parseFloat(this.energyPrice.sellPrice),
        },
      },
    });
    this.$store.dispatch(`${this.currentStoreName}/handleIncrement`);
  }
}
