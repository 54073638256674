
import { Vue, Component, Prop } from 'vue-property-decorator';

import { IAppState } from '@/store/modules/app/types';
import { State } from 'vuex-class';
import SingleIOSystemsView
  from '@/ui/components/devices/devices/EnergyIO/components/charts/SingleIOSystemView.vue';
import { IOEnergyCircleType } from '@/types/energyVisualisation/EnergyCircleType';

/**
 * Component that represent wrapper for charts tabs navigation.
 * Used in EMS.
 */
@Component({
  components: {
    SingleIOSystemsView,
  },
})
export default class EMSCharts extends Vue {
  @Prop({ default: null }) mpcData!: any;
  @Prop({ default: null }) prediction!: any;
  @Prop({ default: null }) predictedPower!: any;
  @Prop({ default: null }) predictedTargetPower!: any;
  @Prop({ default: null }) predictedEnergy!: any;
  @Prop({ default: null }) predictedSOC!: any;
  @Prop() mpcId!: any;
  @Prop() scaling!: any;
  @Prop({ default: false }) isHybridSystem!: boolean;

  @State('app') appState!: IAppState;

  tab = null
  settingsWrapperHeight = 0;
  shownSystemsSingleSystemsChart: string[] = [];

  created() {
    this.shownSystemsSingleSystemsChart = this.getAllSystems();
  }

  getAllSystems() {
    let systems: any[] = [];
    Object.values(IOEnergyCircleType).forEach((value) => {
      if (this.mpcData.data.meta.controllerMappings[value]) {
        systems = [...systems, ...Object.keys(this.mpcData.data.meta.controllerMappings[value].components)];
      }
    });
    return systems;
  }
  mounted() {
    this.$nextTick(() => {
      const settingsWrapperElement = (this.$refs.settingsWrapper as any);
      // change height of settingsWrapper for hybrid systems because we need to calculate in space for a info tooltip
      this.settingsWrapperHeight = !this.isHybridSystem ? settingsWrapperElement.clientHeight * 0.7 : (settingsWrapperElement.clientHeight * 0.7) - 40;
    });
  }

  get items() {
    return [
      {
        title: this.$t('devices.EnergyView.ChartsWindow.singleSystems'),
        view: 'SingleIOSystemsView',
        showHouseConsumption: false,
        showScores: false,
        showColumnChart: true,
        systemsVars: {
          inputs: ['energy_counter'],
          outputs: ['energy_counter'],
          hybrids: ['energy_counter', 'reverse_energy_counter'],
        },
        defaultAggregation: 'diff',
        navItemsToExclude: ['live', 'hour', 'day'],
      },
    ];
  }

  getNumberOfSeriesPerChart(item: Record<string, any>) {
    return item?.numberOfSeriesPerChart;
  }
}
