
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import RotatingBacklight from '@/ui/components/devices/components/EnergyParts/RotatingBacklight.vue';
import BatterySystem from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/BatterySystem/Forecast/index.vue';
import BigConsumerSystem from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/BigConsumerSystem/Forecast/index.vue';
import ChargeStationSystem from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/ChargeStationSystem/Forecast/index.vue';
import ElectricHeatingSystem from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/ElectricHeatingSystem/Forecast/index.vue';
import GeneratorSystem from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/GeneratorSystem/Forecast/index.vue';
import GridSystem from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/GridSystem/Forecast/index.vue';
import HeatingPumpSystem from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/HeatingPumpSystem/Forecast/index.vue';
import HouseSystem from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/HouseSystem/Forecast/index.vue';
import PVSystem from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/PVSystem/Forecast/index.vue';
import CentralEndpoint from '@/ui/components/devices/components/EnergyParts/CentralEndpoint.vue';
import { throttle } from 'lodash';
import { INavigationState } from '@/store/modules/navigation/types';

/**
 * Component that represent EMS Forecast view
 */
@Component({
  components: {
    RotatingBacklight,
    BatterySystem,
    BigConsumerSystem,
    ChargeStationSystem,
    ElectricHeatingSystem,
    GeneratorSystem,
    GridSystem,
    HeatingPumpSystem,
    HouseSystem,
    PVSystem,
    CentralEndpoint,
  },
})
export default class ForecastView extends Vue {
  @State('navigation') navigationState!: INavigationState;
  @Prop({ default: null }) allSystemsData!: any;
  @Prop({ default: null }) allSystemsForecastData!: any;
  @Prop({ default: 0 }) groupSliderState!: number;
  @Prop({ default: null }) batteryPredictedSOC!: any;
  @Prop({ default: null }) allSystemsForecastTargetPower!: any;
  @State('mpc') mpcState!: any;

  canvasSize: any = null;
  canvasCenter: any = null;
  canvasLines: any = {};
  systemsList = [
    'pv',
    'generator',
    'battery',
    'grid',
    'house',
    'charge_station',
    'electric_heating',
    'heating_pump',
    'big_consumer',
  ];

  systems = {
    pv: 'PVSystem',
    generator: 'GeneratorSystem',
    grid: 'GridSystem',
    battery: 'BatterySystem',
    house: 'HouseSystem',
    charge_station: 'ChargeStationSystem',
    electric_heating: 'ElectricHeatingSystem',
    heating_pump: 'HeatingPumpSystem',
    big_consumer: 'BigConsumerSystem',
  };

  get groups() {
    return this.mpcState.mlModelTypes.EMS.groups;
  }
  get currentProducerSystems() {
    const { systems } = this.groups.producer;
    return systems.filter((system: any) => this.allSystemsData[system].count);
  }
  get currentConsumerSystems() {
    const { systems } = this.groups.consumer;
    return systems.filter((system: any) => this.allSystemsData[system].count);
  }
  get batterySystem() {
    return this.allSystemsData.battery;
  }
  get gridSystem() {
    return this.allSystemsData.grid;
  }

  /**
   * Load line if all coords and sizes ready
   * @return {boolean} load line status
   */
  get loadLine() {
    return !!this.canvasSize && !!this.canvasCenter && !!Object.values(this.canvasLines);
  }

  /**
   * Defines coordinates of systems, Central Energy Point.
   * Use vm.$refs to retrieve references to elements.
   * Then it goes through the list of references and creates an options object for each one.
   */
  getCoords() {
    const central: any = this.$refs.centralEndpoint;
    const actualViewRef: any = this.$refs.canvas;
    this.canvasSize = { width: actualViewRef?.clientWidth, height: actualViewRef?.clientHeight };
    this.canvasCenter = {
      x: central.offsetLeft + central.offsetWidth / 2,
      y: central.offsetTop + central.offsetHeight / 2,
      w: central.offsetWidth,
      h: central.offsetHeight,
    };
    this.systemsList.forEach((system: string) => {
      const target: any = this.$refs[system];
      if (!target) return;

      if (Array.isArray(target)) {
        this.canvasLines[system] = {
          key: system,
          x: target[0].offsetLeft + target[0].offsetWidth / 2,
          y: target[0].offsetTop + target[0].offsetHeight / 2,
          w: target[0].offsetWidth,
          h: target[0].offsetHeight,
        };
      } else {
        this.canvasLines[system] = {
          key: system,
          x: target.offsetLeft + target.offsetWidth / 2,
          y: target.offsetTop + target.offsetHeight / 2,
          w: target.offsetWidth,
          h: target.offsetHeight,
        };
      }
    });
  }
  getCoordsThrottle = throttle(this.getCoords, 500);

  handleGetCoords() {
    this.getCoords();
  }

  get isMini() {
    return this.navigationState.isMini;
  }

  @Watch('isMini')
  handleIsMiniChange(val: any) {
    this.getCoordsThrottle();
  }

  mounted() {
    window.addEventListener('resize', this.getCoordsThrottle);
    this.$nextTick(() => {
      this.getCoords();
    });
  }

  destroyed() {
    window.removeEventListener('resize', this.getCoordsThrottle);
  }
}
