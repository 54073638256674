
import { Component, Prop } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { DevicesSizesFlex } from '@/ui/mixins/devicesSizesFlex';
import InputFieldExternal from '@/ui/components/devices/components/InputFields/InputFieldExternal.vue';
import Chart from '@/ui/components/devices/charts/charts/ChartDevice.vue';
import DropDownOutputFieldWithVariable from '@/ui/components/devices/devices/DropDownOutputFieldWithVariable.vue';
import DropDownInputFieldWithVariable from '@/ui/components/devices/devices/DropDownInputFieldWithVariable.vue';
import TSGFrischwasser from '@/ui/components/devices/devices/TSGFrischwasser.vue';
import TSGBrauchwasser from '@/ui/components/devices/devices/TSGBrauchwasser.vue';
import Robot from '@/ui/components/devices/devices/Robot.vue';
import SurveyClient from '@/ui/components/devices/devices/SurveyClient.vue';
import PVProductionService from '@/ui/components/devices/mpc/PVProductionService/index.vue';
import EnergyViewV2 from '@/ui/components/devices/devices/EnergyViewV2/index.vue';
import MpcEnergyView from '@/ui/components/devices/mpc/EnergyView/index.vue';
import ConsumptionService from '@/ui/components/devices/mpc/ConsumptionService/index.vue';
import TSGLadestationNotAus from '@/ui/components/devices/devices/TSGLadestationNotAus.vue';
import EMS from '@/ui/components/devices/mpc/EMS/index.vue';
import EMSV2 from '@/ui/components/devices/devices/EMS/index.vue';
import GeneralSwitchV2 from '@/ui/components/devices/devices/GeneralSwitchV2.vue';
import PVMonitoringService from '@/ui/components/devices/mpc/PVMonitoringService/index.vue';
import LightDimmer from '@/ui/components/devices/devices/LightDimmer.vue';
import LightSwitch from '@/ui/components/devices/devices/LightSwitch.vue';
import Temperature from '@/ui/components/devices/devices/Temperature.vue';
import VariableInputField from '@/ui/components/devices/devices/VariableInputField.vue';
import VariableOutputField from '@/ui/components/devices/devices/VariableOutputField.vue';
import VariableTextInputField from '@/ui/components/devices/devices/VariableTextInputField.vue';
import VariableTextOutputField from '@/ui/components/devices/devices/VariableTextOutputField.vue';
import LightRGB from '@/ui/components/devices/devices/LightRGB.vue';
import SocketSwitch from '@/ui/components/devices/devices/SocketSwitch.vue';
import LightPushButton from '@/ui/components/devices/devices/LightPushButton.vue';
import ControlBlinds from '@/ui/components/devices/devices/ControlBlinds.vue';
import ControlShutter from '@/ui/components/devices/devices/ControlShutter.vue';
import ControlAwning from '@/ui/components/devices/devices/ControlAwning.vue';
import ThermostatDigital from '@/ui/components/devices/devices/ThermostatDigital.vue';
import ThermostatAnalog from '@/ui/components/devices/devices/ThermostatAnalog.vue';
import BrightnessSensor from '@/ui/components/devices/devices/BrightnessSensor.vue';
import AirHumiditySensor from '@/ui/components/devices/devices/AirHumiditySensor.vue';
import SensorCO2 from '@/ui/components/devices/devices/SensorCO2.vue';
import SensorLevel from '@/ui/components/devices/devices/SensorLevel.vue';
import WeatherStation from '@/ui/components/devices/devices/WeatherStation.vue';
import HotWaterMeter from '@/ui/components/devices/devices/HotWaterMeter.vue';
import ColdWaterMeter from '@/ui/components/devices/devices/ColdWaterMeter.vue';
import ElectricityMeter from '@/ui/components/devices/devices/ElectricityMeter.vue';
import HeatingMeter from '@/ui/components/devices/devices/HeatingMeter.vue';
import MotionSensor from '@/ui/components/devices/devices/MotionSensor.vue';
import Battery from '@/ui/components/devices/devices/Battery.vue';
import PVSystem from '@/ui/components/devices/devices/PVSystem.vue';
import HouseConsumption from '@/ui/components/devices/devices/HouseConsumption.vue';
import Generator from '@/ui/components/devices/devices/Generator.vue';
import MainsConnection from '@/ui/components/devices/devices/MainsConnection.vue';
import ElectricChargingStation from '@/ui/components/devices/devices/ElectricChargingStation.vue';
import ElectricChargingStationV2 from '@/ui/components/devices/devices/ElectricChargingStationV2.vue';
import ElectronicBoiler from '@/ui/components/devices/devices/ElectronicBoiler.vue';
import VentilatorSwitch from '@/ui/components/devices/devices/VentilatorSwitch.vue';
import MusicSystem from '@/ui/components/devices/devices/MusicSystem.vue';
import TV from '@/ui/components/devices/devices/TV.vue';
import MotorWithoutVFD from '@/ui/components/devices/devices/MotorWithoutVFD.vue';
import PumpWithoutVFD from '@/ui/components/devices/devices/PumpWithoutVFD.vue';
import PumpWithVFD from '@/ui/components/devices/devices/PumpWithVFD.vue';
import MotorWithVFD from '@/ui/components/devices/devices/MotorWithVFD.vue';
import MixingValve from '@/ui/components/devices/devices/MixingValve.vue';
import Ventilation from '@/ui/components/devices/devices/Ventilation.vue';
import Gauge from '@/ui/components/devices/devices/Gauge.vue';
import RegentLighting from '@/ui/components/devices/devices/RegentLighting.vue';
import HeatingCircuitOptimization from '@/ui/components/devices/mpc/HeatingCircuitOptimization/index.vue';
import HistoryAnomalyDetection from '@/ui/components/devices/anomalyDetection/HistoryAnomalyDetection.vue';
import StreamAnomalyDetection from '@/ui/components/devices/anomalyDetection/StreamAnomalyDetection.vue';
import SetpointOptimizer from '@/ui/components/devices/mpc/SetpointOptimizer/index.vue';
import TSGModulLadestation from '@/ui/components/devices/devices/TSGModulLadestation.vue';
import HppProductionService from '@/ui/components/devices/mpc/HeatingProduction/index.vue';
import { IDevicesState } from '@/store/modules/devices/types';
import { IMPCState } from '@/store/modules/mpc/types';
import { IMQTTState } from '@/store/modules/mqtt/types';
import { IProjectsState } from '@/store/modules/projects/types';
import { IProject } from '@/types/project.types';
import EnergyIO from '@/ui/components/devices/devices/EnergyIO/index.vue';

/**
 * Component that represent all devices list in Flex grid.
 */
@Component({
  components: {
    TSGModulLadestation,
    HppProductionService,
    InputFieldExternal,
    SetpointOptimizer,
    StreamAnomalyDetection,
    HistoryAnomalyDetection,
    PVMonitoringService,
    GeneralSwitchV2,
    EMSV2,
    EMS,
    EnergyIO,
    TSGLadestationNotAus,
    ConsumptionService,
    PVProductionService,
    EnergyViewV2,
    MpcEnergyView,
    SurveyClient,
    Robot,
    TSGBrauchwasser,
    TSGFrischwasser,
    DropDownInputFieldWithVariable,
    DropDownOutputFieldWithVariable,
    HeatingCircuitOptimization,
    Gauge,
    Ventilation,
    MixingValve,
    MotorWithVFD,
    PumpWithVFD,
    PumpWithoutVFD,
    MotorWithoutVFD,
    TV,
    MusicSystem,
    VentilatorSwitch,
    ElectronicBoiler,
    ElectricChargingStation,
    ElectricChargingStationV2,
    MainsConnection,
    Generator,
    HouseConsumption,
    PVSystem,
    Battery,
    MotionSensor,
    ElectricityMeter,
    HeatingMeter,
    ColdWaterMeter,
    HotWaterMeter,
    WeatherStation,
    SensorLevel,
    SensorCO2,
    BrightnessSensor,
    AirHumiditySensor,
    ThermostatAnalog,
    ThermostatDigital,
    ControlShutter,
    ControlAwning,
    ControlBlinds,
    LightRGB,
    SocketSwitch,
    LightPushButton,
    LightSwitch,
    LightDimmer,
    Temperature,
    VariableOutputField,
    VariableInputField,
    VariableTextInputField,
    VariableTextOutputField,
    RegentLighting,
    chart: Chart,
  },
})
export default class DevicesListFlexGrid extends mixins(DevicesSizesFlex) {
  @Prop() devices!: any;
  @State('projects') projectsState!: IProjectsState;
  @State('mqttClient') mqttState!: IMQTTState;
  @State('mpc') mpcState!: IMPCState;
  @State('devices') devicesState!: IDevicesState;
  @Getter('projects/project') project!: IProject;

  get devicesFilter() {
    return this.devicesState.devicesFilter;
  }

  /**
   * Filter all devices by search
   * @return {array} list of devices
   */
  get filteredDevices() {
    return this.devices.filter((device: any) => {
      if (this.devicesFilter && this.devicesFilter.length) {
        return device.name.toLowerCase().includes(this.devicesFilter.toLowerCase());
      }
      return device;
    });
  }

  get isProjectOnline() {
    if (this.projectsState.project?.meta?.disableDevicesWhenOffline) {
      return this.mqttState.online;
    } else {
      return true;
    }
  }
}
