
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PaginationTabs extends Vue {
  @Prop({ default: 10 }) elementsPerPage!: number;
  @Prop({ default: 0 }) totalElements!: number;

  // splits pages into groups of [[start, end], [start, end], ...
  get pages() {
    const pagesCount = Math.ceil(this.totalElements / this.elementsPerPage);
    const pages = [];

    for (let i = 0; i < pagesCount; i++) {
      const first = i * this.elementsPerPage + 1;
      const last = Math.min((i + 1) * this.elementsPerPage, this.totalElements);
      pages.push([first, last]);
    }

    return pages;
  }

  updateSelectedPage(index: number) {
    this.$emit('updatePage', index);
  }
  mounted() {
    this.updateSelectedPage(0);
  }
}
