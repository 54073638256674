
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import Chart from '@/ui/components/devices/charts/charts/ChartDevice.vue';
import LightSwitch from '@/ui/components/devices/devices/LightSwitch.vue';
import LightDimmer from '@/ui/components/devices/devices/LightDimmer.vue';
import Temperature from '@/ui/components/devices/devices/Temperature.vue';
import VariableOutputField from '@/ui/components/devices/devices/VariableOutputField.vue';
import RoomsList from '@/ui/components/lists/RoomsList/index.vue';
import VariableInputField from '@/ui/components/devices/devices/VariableInputField.vue';
import ManageRoom from '@/ui/components/modals/ManageRoom.vue';
import VariableTextOutputField from '@/ui/components/devices/devices/VariableTextOutputField.vue';
import VariableTextInputField from '@/ui/components/devices/devices/VariableTextInputField.vue';
import LightRGB from '@/ui/components/devices/devices/LightRGB.vue';
import SocketSwitch from '@/ui/components/devices/devices/SocketSwitch.vue';
import LightPushButton from '@/ui/components/devices/devices/LightPushButton.vue';
import ControlBlinds from '@/ui/components/devices/devices/ControlBlinds.vue';
import ControlShutter from '@/ui/components/devices/devices/ControlShutter.vue';
import ControlAwning from '@/ui/components/devices/devices/ControlAwning.vue';
import ThermostatDigital from '@/ui/components/devices/devices/ThermostatDigital.vue';
import ThermostatAnalog from '@/ui/components/devices/devices/ThermostatAnalog.vue';
import BrightnessSensor from '@/ui/components/devices/devices/BrightnessSensor.vue';
import AirHumiditySensor from '@/ui/components/devices/devices/AirHumiditySensor.vue';
import SensorCO2 from '@/ui/components/devices/devices/SensorCO2.vue';
import SensorLevel from '@/ui/components/devices/devices/SensorLevel.vue';
import WeatherStation from '@/ui/components/devices/devices/WeatherStation.vue';
import HotWaterMeter from '@/ui/components/devices/devices/HotWaterMeter.vue';
import ColdWaterMeter from '@/ui/components/devices/devices/ColdWaterMeter.vue';
import ElectricityMeter from '@/ui/components/devices/devices/ElectricityMeter.vue';
import HeatingMeter from '@/ui/components/devices/devices/HeatingMeter.vue';
import MotionSensor from '@/ui/components/devices/devices/MotionSensor.vue';
import Battery from '@/ui/components/devices/devices/Battery.vue';
import PVSystem from '@/ui/components/devices/devices/PVSystem.vue';
import HouseConsumption from '@/ui/components/devices/devices/HouseConsumption.vue';
import Generator from '@/ui/components/devices/devices/Generator.vue';
import MainsConnection from '@/ui/components/devices/devices/MainsConnection.vue';
import ElectricChargingStation from '@/ui/components/devices/devices/ElectricChargingStation.vue';
import ElectricChargingStationV2 from '@/ui/components/devices/devices/ElectricChargingStationV2.vue';
import ElectronicBoiler from '@/ui/components/devices/devices/ElectronicBoiler.vue';
import VentilatorSwitch from '@/ui/components/devices/devices/VentilatorSwitch.vue';
import MusicSystem from '@/ui/components/devices/devices/MusicSystem.vue';
import TV from '@/ui/components/devices/devices/TV.vue';
import MotorWithoutVFD from '@/ui/components/devices/devices/MotorWithoutVFD.vue';
import PumpWithoutVFD from '@/ui/components/devices/devices/PumpWithoutVFD.vue';
import PumpWithVFD from '@/ui/components/devices/devices/PumpWithVFD.vue';
import MotorWithVFD from '@/ui/components/devices/devices/MotorWithVFD.vue';
import MixingValve from '@/ui/components/devices/devices/MixingValve.vue';
import Ventilation from '@/ui/components/devices/devices/Ventilation.vue';
import Gauge from '@/ui/components/devices/devices/Gauge.vue';
import RegentLighting from '@/ui/components/devices/devices/RegentLighting.vue';
import HeatingCircuitOptimization from '@/ui/components/devices/mpc/HeatingCircuitOptimization/index.vue';
import VueGridLayout from 'vue-grid-layout';
import { mixins } from 'vue-class-component';
import { DND } from '@/ui/mixins/dnd';
import DropDownOutputFieldWithVariable from '@/ui/components/devices/devices/DropDownOutputFieldWithVariable.vue';
import DropDownInputFieldWithVariable from '@/ui/components/devices/devices/DropDownInputFieldWithVariable.vue';
import TSGFrischwasser from '@/ui/components/devices/devices/TSGFrischwasser.vue';
import TSGBrauchwasser from '@/ui/components/devices/devices/TSGBrauchwasser.vue';
import { IProjectsState } from '@/store/modules/projects/types';
import Robot from '@/ui/components/devices/devices/Robot.vue';
import MpcEnergyView from '@/ui/components/devices/mpc/EnergyView/index.vue';
import EnergyViewV2 from '@/ui/components/devices/devices/EnergyViewV2/index.vue';
import SurveyClient from '@/ui/components/devices/devices/SurveyClient.vue';
import PVProductionService from '@/ui/components/devices/mpc/PVProductionService/index.vue';
import ConsumptionService from '@/ui/components/devices/mpc/ConsumptionService/index.vue';
import HppProductionService from '@/ui/components/devices/mpc/HeatingProduction/index.vue';
import TSGLadestationNotAus from '@/ui/components/devices/devices/TSGLadestationNotAus.vue';
import EMS from '@/ui/components/devices/mpc/EMS/index.vue';
import EMSV2 from '@/ui/components/devices/devices/EMS/index.vue';
import GeneralSwitchV2 from '@/ui/components/devices/devices/GeneralSwitchV2.vue';
import PVMonitoringService from '@/ui/components/devices/mpc/PVMonitoringService/index.vue';
import HistoryAnomalyDetection from '@/ui/components/devices/anomalyDetection/HistoryAnomalyDetection.vue';
import StreamAnomalyDetection from '@/ui/components/devices/anomalyDetection/StreamAnomalyDetection.vue';
import SetpointOptimizer from '@/ui/components/devices/mpc/SetpointOptimizer/index.vue';
import TSGModulLadestation from '@/ui/components/devices/devices/TSGModulLadestation.vue';
import { IProject } from '@/types/project.types';
import { IMemberMetaMap } from '@/types/members.types';
import EnergyIO from '@/ui/components/devices/devices/EnergyIO/index.vue';

/**
 * Component that represent all devices list in Drag and Drop grid.
 * Used in Rooms page.
 */
@Component({
  components: {
    TSGModulLadestation,
    HppProductionService,
    SetpointOptimizer,
    StreamAnomalyDetection,
    HistoryAnomalyDetection,
    PVMonitoringService,
    GeneralSwitchV2,
    EMSV2,
    EMS,
    EnergyIO,
    TSGLadestationNotAus,
    ConsumptionService,
    PVProductionService,
    EnergyViewV2,
    MpcEnergyView,
    SurveyClient,
    Robot,
    TSGBrauchwasser,
    TSGFrischwasser,
    DropDownInputFieldWithVariable,
    DropDownOutputFieldWithVariable,
    HeatingCircuitOptimization,
    Gauge,
    Ventilation,
    MixingValve,
    MotorWithVFD,
    PumpWithVFD,
    PumpWithoutVFD,
    MotorWithoutVFD,
    TV,
    MusicSystem,
    VentilatorSwitch,
    ElectronicBoiler,
    ElectricChargingStation,
    ElectricChargingStationV2,
    MainsConnection,
    Generator,
    HouseConsumption,
    PVSystem,
    Battery,
    MotionSensor,
    ElectricityMeter,
    HeatingMeter,
    ColdWaterMeter,
    HotWaterMeter,
    WeatherStation,
    SensorLevel,
    SensorCO2,
    AirHumiditySensor,
    BrightnessSensor,
    ThermostatAnalog,
    ThermostatDigital,
    ControlShutter,
    ControlAwning,
    ControlBlinds,
    LightRGB,
    SocketSwitch,
    LightPushButton,
    LightSwitch,
    LightDimmer,
    Temperature,
    RoomsList,
    ManageRoom,
    VariableOutputField,
    VariableInputField,
    VariableTextOutputField,
    VariableTextInputField,
    RegentLighting,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    chart: Chart,
  },
})
export default class DevicesListDnD extends mixins(DND) {
  @Prop() devicesByRoomLocal!: any;
  @Prop() currentRoomData!: any;
  @Prop() activeRoomId!: any;

  @State('mqttClient') mqttState!: any;
  @State('devices') devicesState!: any;
  @State('projects') projectsState!: IProjectsState;
  @Getter('rooms/rooms') rooms!: any;
  @Getter('projects/project') project!: IProject;
  @Getter('devices/devicesByRoom') devicesByRoom!: (roomId: string) => any;
  @Getter('members/currentMember') currentMember!: any;
  @Getter('members/currentMemberDevicesPositions') currentMemberDevicesPositions!: IMemberMetaMap;

  dndLayout = [];
  chartInstanceWidth: any = null;
  gridLayoutContainerWidth: any = null;
  areaDevicesPositions: any = [];
  sortedDevicesLength: any = 0;

  @Watch('devicesByRoomLocal')
  onRoomChange(val: any) {
    if (this.devicesByRoomLocal.length) {
      const sortedList = this.sortDevicesForRoom();
      this.initDndDevices(sortedList);
    } else {
      this.initDndDevices(this.devicesByRoomLocal);
    }
  }

  get dndStatus() {
    if (this.project) {
      return (this.project.meta as any).isDNDActive;
    }
    return false;
  }
  get devicesTypes() {
    return Object.keys(this.devicesState.devicesTypes);
  }
  get devicesPositionsByCurrentRoom() {
    return this.currentMemberDevicesPositions?.[this.activeRoomId] || [];
  }

  get isProjectOnline() {
    if (this.projectsState.project.meta.disableDevicesWhenOffline === true) {
      return this.mqttState.online;
    } else {
      return true;
    }
  }

  get areaDevicesPositionsMap() {
    return [this.activeRoomId, this.areaDevicesPositions];
  }

  /**
   * Prepares list of devices for Drag and Drop grid
   * @param {array} devices list of devices
   */
  initDndDevices(devices: any) {
    const lastDeviceOnFirstCol = () => {
      if (!this.devicesPositionsByCurrentRoom.length) return;
      // filter all devices which on position x = 0, return their y-positions
      const devicesAtPositionZero = this.devicesPositionsByCurrentRoom
        .filter((device: any) => device.x === 0);
      const arr = devicesAtPositionZero.map((d: any) => d.y);
      // define last y-position
      const lastPos = Math.max.apply(null, arr);
      return devicesAtPositionZero.find((el: any) => el.y === lastPos);
    };
    const lastDeviceOnFirstColSize = lastDeviceOnFirstCol()
      ? this.sizesForDnD(
          devices.find((dev: any) => dev.id === lastDeviceOnFirstCol()?.i)?.data?.type,
        )
      : null;
    // define start position for new device what was added
    const startPositionForNewDevice =
      lastDeviceOnFirstColSize && lastDeviceOnFirstCol()
        ? lastDeviceOnFirstColSize.h + lastDeviceOnFirstCol()?.y
        : 0;

    this.dndLayout = devices.map((device: any) => {
      const isFullWidthChart = device.data.selectedWidth === 'full';

      // define devices positions
      const positions = () => {
        if (this.currentMemberDevicesPositions?.[this.activeRoomId]) {
          const currentDevicePositions = this.currentMemberDevicesPositions[this.activeRoomId].find(
            (item: any) => item.i === device.id,
          );

          // need when chart instance switch between full width and half width
          let snapshot = null;
          if (currentDevicePositions) {
            snapshot = { ...currentDevicePositions };
            if (isFullWidthChart) snapshot.x = 0;
            else snapshot.x = currentDevicePositions.x;
          }
          return (
            snapshot || {
              x: 0,
              y: startPositionForNewDevice,
              i: device.id,
            }
          );
        } else {
          return {
            x: 0,
            y: 0,
            i: device.id,
          };
        }
      };

      return {
        device,
        ...positions(),
        ...this.sizesForDnD(device.data.type),
      };
    });
    // TODO: change prop names, remove chartWidth, count device init width from container width
    this.$nextTick(() => {
      const gridLayoutContainerPadding = 40;
      this.chartInstanceWidth = Math.round((this.$refs.layout as any).$el.clientWidth / 2) - 40;
      this.gridLayoutContainerWidth =
        (this.$refs.layout as any).$el.clientWidth - gridLayoutContainerPadding;
    });

    // define sizes for EnergyView, charts
    this.dndLayout.forEach((item: any, index: number) => {
      const isChartOrAnomalyDevices: boolean = [
        'chart',
        'HistoryAnomalyDetection',
        'StreamAnomalyDetection',
      ].some((type: string) => type === item.device.data.type);
      if (isChartOrAnomalyDevices) {
        if (item.device.data.selectedWidth === 'half') {
          item.w = 2;
        } else {
          item.w = 4;
        }
      }
    });

    // Fit unsorted devices on grid
    this.fitDevices();
  }

  fitDevices() {
    // Define columns with occupied spaces
    let occupiedColumns = [0, 0, 0, 0];
    let columnIndexCount = 0;
    let currentRowCount = Math.max(...this.dndLayout.map((device: any) => device.y));

    // Go through all devices after the sorted ones and place them in the grid
    this.dndLayout.slice(this.sortedDevicesLength).forEach((currentDevice: any) => {
      const newFittedDevice = currentDevice;

      // Check if end of row is reached, else go to next row
      if (currentDevice.w + columnIndexCount > 4) {
        currentRowCount++;
        occupiedColumns = this.nextRow(occupiedColumns);
        columnIndexCount = 0;
      }

      // Find the first available position for the device
      while (!this.doesFitSpace(occupiedColumns, columnIndexCount, currentDevice.w)) {
        columnIndexCount++;
        if (currentDevice.w + columnIndexCount > 4) {
          currentRowCount++;
          occupiedColumns = this.nextRow(occupiedColumns);
          columnIndexCount = 0;
        }
      }

      // Place the device in the available position
      newFittedDevice.x = columnIndexCount;
      newFittedDevice.y = currentRowCount;
      columnIndexCount += currentDevice.w;

      // If device height is more than 1, add space limitation for next rows
      if (newFittedDevice.h > 1) {
        for (let index = newFittedDevice.x; index <= Math.min(newFittedDevice.x + newFittedDevice.w - 1, 3); index++) {
          occupiedColumns[index] = currentDevice.h;
        }
      }
    });
  }

  doesFitSpace(occupiedColumns: any, columnIndex: number, deviceWidth: number) {
    return occupiedColumns.slice(columnIndex, columnIndex + deviceWidth).every((limit: number) => limit === 0);
  }

  nextRow(occupiedColumns: any) {
    return occupiedColumns.map((limit: number) => Math.max(0, limit - 1));
  }

  /**
   * Saves actual devices positions when Drag and Drop layout was updated
   * @param {array} layout Drag and Drop grid items list
   */
  layoutUpdatedEvent(layout: any) {
    this.areaDevicesPositions = layout.map((item: any) => {
      return {
        x: item.x,
        y: item.y,
        i: item.i,
      };
    });
  }

  sortDevicesForRoom() {
    // will be final list with all devices
    let sortedDevices: any = [];
    // contains a list with unsorted devices
    const unSortedDevices: any = [];
    // saves list of devices that are already set by User

    // adds every device that`s not sorted by the User to a separate list to make sorting by date more simple
    this.devicesByRoomLocal.forEach((device: any) => {
      const hasDeviceSavedPosition = this.devicesPositionsByCurrentRoom
        ? this.devicesPositionsByCurrentRoom.map((d: any) => d.i).includes(device.id)
        : false;
      if (hasDeviceSavedPosition) sortedDevices.push(device);
      else unSortedDevices.push(device);
    });
    this.sortedDevicesLength = sortedDevices.length;
    unSortedDevices.sort(this.compareFunction);

    // sorted and not sorted by User list get combined and create one final list
    sortedDevices = sortedDevices.concat(unSortedDevices);
    return sortedDevices;
  }

  /**
   * Sorts given array by created at. From oldest do newest device
   */
  compareFunction(a: any, b: any) {
    const dateA = new Date(a.created_at).getTime();
    const dateB = new Date(b.created_at).getTime();
    if (dateA > dateB) {
      return 1;
    } else {
      return -1;
    }
  }

  mounted() {
    if (this.devicesByRoomLocal.length) {
      const sortedList = this.sortDevicesForRoom();
      this.initDndDevices(sortedList);
    }
  }
}
