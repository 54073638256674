
import { Vue, Component } from 'vue-property-decorator';
import SliderMock from '@/ui/components/devices/previews/components/SliderMock.vue';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    SliderMock,
    SwitchMock,
    DeviceLayout,
  },
})
export default class LightDimmerPreview extends Vue {
  state = false
  slider = 0

  get previewNameLang() {
    return this.$t('devices.LightDimmer.previewName');
  }
}
