
import { Component, Inject, Prop } from 'vue-property-decorator';
import AnimatedLine from '@/ui/components/devices/components/EnergyParts/AnimatedLine/index.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import PreviewMQTTControls
  from '@/ui/components/devices/components/EnergyParts/PreviewMQTTControls.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';

/**
 * Grid single system settings.
 */
@Component({
  components: {
    AnimatedLine,
    InputFieldBase,
    OutputFieldBase,
    PreviewMQTTControls,
    LabelUnitWrapper,
  },
})
export default class GridSystemSettings extends mixins(Validation) {
  @Prop({ default: null }) instanceData!: any;

  get outputFieldActualPower() {
    return {
      'OutputField_actualValue': this.instanceData.power,
    };
  }
  get inputFieldSize() {
    return {
      'InputField_targetValue': this.instanceData.size,
    };
  }
}
