
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { IProject } from '@/types/project.types';

@Component({
  components: {
    WizardContentView,
    InfoTooltip,
  },
})
export default class SystemInformation extends mixins(Validation) {
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Getter('projects/project') project!: IProject;
  @Mutation('tenantWizard/setHasLynusInverter') setHasLynusInverter!: (data: boolean) => void;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;

  hasLynusInverter = false;

  get hasLynusInverterFromProject() {
    return this.project?.meta?.hasLynusInverter ?? false;
  }

  handleNext() {
    this.updateTenant();
    this.handleIncrement();
  }

  updateTenant() {
    this.setHasLynusInverter(this.hasLynusInverter);
  }

  created() {
    if (this.hasLynusInverterFromProject === true) {
      this.hasLynusInverter = true;
    }
  }
}
