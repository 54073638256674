
import { Vue, Component } from 'vue-property-decorator';
import SystemsNavigation
  from '@/ui/components/devices/mpc/SetpointOptimizer/components/SystemsNavigation.vue';
import OfflineChart from '@/ui/components/devices/previews/charts/components/OfflineChart.vue';

@Component({
  components: {
    SystemsNavigation,
    OfflineChart,
  },
})
export default class Charts extends Vue {
  chartColors = ['#6ca2d8', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'];
  tab: any = null

  schema = [
    [
      'heating_air_systems',
      [
        [
          'heating_air_systems1',
        ],
        [
          'heating_air_systems2',
        ],
      ],
    ],
    [
      'heating_water_systems',
      [
        [
          'heating_water_systems1',
        ],
      ],
    ],
    [
      'cooling_air_systems',
      [
        [
          'cooling_air_systems1',
        ],
      ],
    ],
    [
      'cooling_water_systems',
      [
        [
          'cooling_water_systems1',
        ],
      ],
    ],
    [
      'hybrid_water_systems',
      [
        [
          'hybrid_water_systems1',
        ],
      ],
    ],
    [
      'hybrid_air_systems',
      [
        [
          'hybrid_air_systems1',
        ],
      ],
    ],
  ]

  // contains Chart Object
  get mockChartData() {
    let currentChartOptions: any = [];
    currentChartOptions = [
      {
        agg: 'first',
        name: 'EnergyCircle Name: var',
        scaling: {
          max: '100',
          min: '0',
        },
        seriesType: 'View',
        type: 'line',
        unit: '°C',
        var: '',
      }, {
        agg: 'first',
        name: 'EnergyCircle Name: var',
        scaling: {
          max: '100',
          min: '0',
        },
        seriesType: 'View',
        type: 'line',
        unit: '°C',
        var: '',
      },
    ];

    const currentData = {
      data: {
        chartOptions: currentChartOptions,
        selectedStackingOptions: 'normal',
        selectedWidth: 'full',
        type: 'chart',
      },
      created_at: '2021-10-07T07:43:33.554983Z',
    };
    return currentData;
  }

  get items() {
    return [
      {
        id: 'chartsRoomTemperatures',
        title: this.$t('mlModel.SetpointOptimizer.charts.navigation.chartsRoomTemperatures'),
        view: 'ChartsRoomTemperatures',
      },
      {
        id: 'chartControlVariables',
        title: this.$t('mlModel.SetpointOptimizer.charts.navigation.chartControlVariables'),
        view: 'ChartControlVariables',
      },
      {
        id: 'forecast',
        title: this.$t('mlModel.SetpointOptimizer.charts.navigation.forecast'),
        view: 'Forecast',
      },
    ];
  }
}
