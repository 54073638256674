
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import DeviceActions from '@/ui/components/devices/actions/DeviceActions.vue';
import SettingsView from '@/ui/components/devices/components/SettingsView.vue';
import ActualView from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/ActualView/index.vue';
import ForecastView from '@/ui/components/devices/components/EnergyParts/EMS/components/ForecastView.vue';
import EnergyFlowView from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyFlowView/index.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import ShowEventBase from '@/ui/components/devices/devices/base/ShowEventBase.vue';
import TimeSlider from '@/ui/components/devices/components/EnergyParts/TimeSlider.vue';
import EMSCharts from '@/ui/components/devices/mpc/EMS/components/charts/index.vue';
import SettingsEMS from '@/ui/components/devices/mpc/EMS/components/SettingsEMS.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';
import { getSystems } from '@/utils/mpcUtils';

/**
 * Component that represent EMS
 */
@Component({
  components: {
    EnergyFlowView,
    DeviceActions,
    SettingsView,
    ActualView,
    ForecastView,
    ShowEventDotBase,
    TimeSlider,
    ShowEventBase,
    EMSCharts,
    SettingsEMS,
    CircleSpinner,
    MPCLayout,
  },
})
export default class EMS extends Vue {
  @Prop() deviceData!: any;
  @State('mpc') mpcState!: any;
  @Action('mpc/fetchMPCData') fetchMPCData!: any;

  tab = null
  tabsTimer: any = null

  mpc: any = null
  groupSlider = 0

  isSettingsView = false
  energyFlowViewLoading = true;

  /**
   * Gets all systems from EMS controllerMappings
   * @return {object|null} collection of systems
   */
  get allSystems() {
    if (this.mpc?.data?.meta?.controllerMappings) {
      const {
        pv,
        generator,
        grid,
        battery,
        house,
        charge_station,
        electric_heating,
        heating_pump,
        big_consumer,
      } = this.mpc.data.meta.controllerMappings;
      return {
        pv,
        generator,
        battery,
        grid,
        house,
        charge_station,
        electric_heating,
        heating_pump,
        big_consumer,
      };
    } else {
      return null;
    }
  }

  get prediction() {
    if (this.mpc?.data?.meta?.charts?.prediction) return this.mpc.data.meta.charts.prediction;
    else return null;
  }

  get predictedPower() {
    if (this.prediction) {
      return getSystems(this.prediction, '_power');
    } else return null;
  }

  get predictedTargetPower() {
    if (this.prediction) {
      return getSystems(this.prediction, '_target_power');
    } else return null;
  }

  get predictedEnergy() {
    if (this.prediction) {
      return getSystems(this.prediction, '_energy');
    } else return null;
  }

  get predictedSOC() {
    if (this.prediction) {
      return getSystems(this.prediction, '_soc');
    } else return null;
  }

  get emsScaling() {
    return this.mpc?.data?.meta?.scaling;
  }

  /**
   * Gets timestamps from EMS predictedPower
   * @return {array} list of timestamps
   */
  get timeSliderInitArray() {
    if (this.prediction) {
      return Object.values(Object.values(this.prediction)[0] as any)[0];
    }
    return [];
  }

  /**
   * Convert MPC id from "id-id-id" to "id_id_id"
   */
  get mpcIdMod() {
    if (this.deviceData.id) return this.deviceData.id.replace(/-/g, '_');
    return null;
  }

  get showEventDotServiceReady() {
    return {
      'ShowEventDot_errorWarningState': this.mpc.data.meta.controllerMappings.mpcReady,
    };
  }

  get showEventGeneralErrorWarning() {
    return {
      'ShowEvent_errorWarningState': this.mpc?.data?.meta?.controllerMappings?.errorWarning,
    };
  }

  /**
   * Calculate coords for forecast, energy systems after 500ms of switched tab
   * @param {string} el element reference name
   */
  async handleTab(el: string) {
    this.energyFlowViewLoading = true;
    clearTimeout(this.tabsTimer);
    this.tabsTimer = setTimeout(() => {
      (this.$refs[el] as any)?.handleGetCoords();
      (this.$refs.actual_view as any)?.handleGetCoords();
    }, 500);
  }

  switchSettingsView(setting: boolean) {
    this.isSettingsView = setting;
  }

  handleLoading(payload: any) {
    this.energyFlowViewLoading = payload;
  }

  async rerenderDevice() {
    this.mpc = null;
    this.mpc = await this.fetchMPCData(this.deviceData.id);
  }

  async created() {
    this.mpc = await this.fetchMPCData(this.deviceData.id);
  }
}
