
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import ComboboxField from '@/ui/components/modals/components/form/ComboboxField.vue';
import VueI18n from 'vue-i18n';
import { IVariable } from '@/types/variables.types';

/**
 * Dynamic field component which used in manage modal forms.
 * Creates new fields for targetObject prop
 */
@Component({
  components: {
    ComboboxField,
  },
})
export default class DynamicField extends mixins(Validation) {
  @Prop({ default: false }) isOptional!: boolean;
  @Prop({ default: null }) targetObject!: { [key: string]: string };
  @Prop({ default: null }) label!: string | VueI18n.TranslateResult;
  @Prop({ default: null }) itemsList!: IVariable[];
  @Prop({ default: null }) maxNumberOfFields!: number;
  @Prop({ default: null }) instanceLabelPath!: string; // used vue-i18n "$t" function, example: $t(path_to_lang_file)

  addMaxPowerItem(event: Event | undefined, externalCall = false, externalKey: string | null = null) {
    const key = externalKey !== null ? externalKey : `mp-${Date.now()}`;
    this.$set(this.targetObject, key, '');
    if (!externalCall) this.$emit('added', key);
  }
  deleteMaxPowerItem(key: string, externalCall = false) {
    this.$delete(this.targetObject, key);
    if (!externalCall) this.$emit('deleted', key);
  }
}
