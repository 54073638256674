
import { Component, Prop, Watch } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';

/**
 * Component that represent InputFieldText Basic Control
 */
@Component({
  components: { FeedbackProvider },
})
export default class InputFieldTextBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop({ default: 0 }) min?: number;
  @Prop({ default: 100 }) max?: number;
  @Prop({ default: () => [] }) fieldRules?: any;
  @Prop({ default: '#fff' }) background!: string;
  @Prop({ default: false }) small!: boolean;
  @Prop({ default: false }) enableVariabelButtonColor !: boolean;

  state: any = ''
  timer: any
  valid = true

  @Watch('actualValue')
  onActualValueChange(val: any) {
    if (typeof val === 'string') this.state = val;
  }

  get actualValue(): any {
    return this.measurements.get(this.mappingsClean.targetValue) || 'No Text';
  }

  /**
   * Define button color
   */
  get sendButtonColor() {
    // default way Button only has one color
    if (!this.enableVariabelButtonColor) return 'accent';
    if (this.measurements.get(this.mappingsClean.state) === 1) return 'accent';
    else return '#4b6978';
  }

  @Watch('currentLang', { deep: true })
  handleLangChange() {
    this.validate();
  }

  get currentLang() {
    return this.$i18n.locale;
  }

  /**
   * Used to disable the Base component if there is no variable set in the mappings of the device.
   */
  get isNotMapped() {
    return this.mappingsClean.targetValue === '';
  }

  validate() {
    (this.$refs.form as any).validate();
    this.handleState();
  }

  handleState() {
    if (!this.valid) return;
    this.onSendStart();
    this.timer = setTimeout(() => {
      this.send([{ vs: this.state, n: this.mappingsClean.targetValue, u: '' }]);
      this.onSendSuccess();
    }, 200);
  }

  mounted() {
    (this.$refs.form as any).validate();
    this.state = this.actualValue;
  }

  beforeDestroy() {
    clearTimeout(this.timer);
  }
}
