
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Getter, Mutation, State } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { minMaxValidation, valuesAddTo100 } from '@/utils/utilsFunctions';
import { cloneDeep } from 'lodash';
import { initFlatsAddresses } from '@/utils/tenantWizardUtilsFunctions';
import { IProject } from '@/types/project.types';
import { IFlatsConfig, ITenantModel } from '@/types/wizards/tenant.types';

@Component({
  methods: { minMaxValidation },
  components: {
    WizardContentView,
    InfoTooltip,
  },
})
export default class ExtraCosts extends mixins(Validation) {
  @Getter('projects/project') project!: IProject;
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Mutation('tenantWizard/updateTenantObject') updateTenantObject!: (data: ITenantModel) => void;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;

  valid = false;
  sideCosts = [{ name: '', amount: 0 }];
  selectedCurrency = '';
  flatsConfigList: IFlatsConfig[] = [];
  flatsFullAddress: Record<string, string> = {};
  percentageValues: number[] = [];
  splitEqual = true;

  // check if all percentage values add up to 100%
  get isOneHundredPercent() {
    const percentagesList = this.flatsConfigList.map((element: IFlatsConfig) => {
      return { value: element.sideCostPercentage };
    });
    return valuesAddTo100(percentagesList, 'value') || this.$t('tenantWizard.notHundredPercentError');
  }

  handleClick() {
    if (!this.splitEqual) {
      this.flatsConfigList.forEach((element: IFlatsConfig) => {
        element.sideCostPercentage = 0;
      });
      this.$nextTick(() => {
        (this.$refs.form as any).validate();
      });
      return;
    }
    const equalValue = Math.round((100 / this.flatsConfigList.length) * 100) / 100;
    if (equalValue * this.flatsConfigList.length === 100) {
      // if equal parts add up to 100% then set all values to equalValue
      this.flatsConfigList.forEach((element: IFlatsConfig) => (element.sideCostPercentage = equalValue));
    } else {
      // if equal parts
      const val = Math.round((equalValue * (this.flatsConfigList.length - 1)) * 100) / 100;
      this.flatsConfigList.forEach((element: IFlatsConfig, index: number) => {
        if (index === this.flatsConfigList.length - 1) {
          // set last value to 100 - sum of all other values
          const lastValue = parseFloat((100 - val).toFixed(2));
          element.sideCostPercentage = lastValue;
        } else {
          // set equal part
          element.sideCostPercentage = equalValue;
        }
      });
    }
  }

  created() {
    this.selectedCurrency = this.tenantState.tenant.general.paymentDetails.currency;
    this.flatsConfigList = this.tenantState.tenant.general.flats.flatConfigurations;
    if (this.flatsConfigList.every((value: IFlatsConfig) => value.sideCostPercentage === 0)) {
      this.flatsConfigList.forEach((value: IFlatsConfig) => (value.sideCostPercentage = parseInt((100 / this.flatsConfigList.length).toFixed(0), 10)));
    }
    this.sideCosts = this.tenantState.tenant.general.sideCosts;
    if (this.project.meta.wasTenantWizardCompleted) {
      this.percentageValues = this.flatsConfigList.map((element: IFlatsConfig) => element.sideCostPercentage);
      const difference = Math.max(...this.percentageValues) - Math.min(...this.percentageValues); // Calculates the difference between the highest and lowest value
      if (difference > 1) {
        this.splitEqual = false;
      }
    }
  }

  mounted() {
    this.flatsFullAddress = initFlatsAddresses(this.flatsConfigList);
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  handleNext() {
    this.handleIncrement();
    this.updateTenant();
  }

  /**
   * updates the tenant object in the store
   */
  updateTenant() {
    const tenantCopy = cloneDeep(this.tenantState.tenant);
    tenantCopy.general.flats.flatConfigurations = this.flatsConfigList;
    tenantCopy.general.sideCosts = this.sideCosts;
    this.updateTenantObject(tenantCopy);
  }

  addSideCost() {
    this.sideCosts.push({ name: '', amount: 0 });
  }

  removeSideCost(index: number) {
    this.sideCosts.splice(index, 1);
  }
}
