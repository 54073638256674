import { Vue, Component } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
import { isValidIBAN } from 'ibantools';

export type ValidationRule = (value: number | string) => boolean | VueI18n.TranslateResult;

@Component
export class Validation extends Vue {
  fieldMoreThan(num: number) {
    return (v: number) => v >= num || this.$t('validationRules.fieldMoreThan', { n: num });
  }
  fieldLessThan(num: number) {
    return (v: number) => v <= num || this.$t('validationRules.fieldLessThan', { n: num });
  }
  /**
   * Rules which used in different form fields of project
   */
  get rules() {
    return {
      required: (value: number) => (value === 0 || !!value) || this.$t('validationRules.required'),
      notEmptyString: (value: string) => (value !== '') || this.$t('validationRules.required'),

      fieldNotValidIban: (value: string, codeLength: number) => isValidIBAN(value) || this.$t('validationRules.fieldNotValidIban'),

      // time
      fieldTimeInHHMM: (value: string) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value) || this.$t('validationRules.fieldTimeInHHMM'),

      // email
      email: (value: string) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/.test(value) || this.$t('validationRules.email'),

      // specific
      threeCommas: (value: any) => {
        const pattern = /^\-?\d+(?:\.\d{1,3})?$/;
        return pattern.test(value) || this.$t('validationRules.threeCommas');
      },
      twoCommas: (value: any) => {
        const pattern = /^\-?\d+(?:\.\d{1,2})?$/;
        return pattern.test(value) || this.$t('validationRules.twoCommas');
      },
      oneComma: (value: any) => {
        const pattern = /^\-?\d+(?:\.\d{1})?$/;
        return pattern.test(value) || this.$t('validationRules.oneComma');
      },
      onlyNumbersRule: (value: any) => /^[+-]?\d+(\.\d+)?$/.test(value) || this.$t('modals.manageReport.errorMessages.onlyNumbersRule'),
      noCommas: (value: any) => /^[-+]?\d+$/.test(value) || this.$t('validationRules.noCommas'),

      noSpace: (value: any) => /^[a-zA-Z0-9._%+-]+$/.test(value) || this.$t('validationRules.noSpace'),

      // text
      counter: (value: { length: number }) => value?.length <= 30 || this.$t('validationRules.counter'),
      noEmptyString: (value: string) => value !== '' || this.$t('validationRules.required'),
      timeFormat: (v: any) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || this.$t('validationRules.timeFormat'),

      // special rule for fields that are required and should be numbers only to show the correct error message
      requiredNumber: (value: number | undefined | string) => {
        // if field is empty value is undefined/empty string
        if (value === undefined) {
          return this.$t('validationRules.required') as string;
        }
        // check if value is allowed number input
        if (/^[+-]?\d+(\.\d+)?$/.test(value.toString())) {
          return true;
        }
        // check if value is string
        if (typeof value === 'string') {
          return this.$t('modals.manageReport.errorMessages.onlyNumbersRule') as string;
        }
      },

      // Rule that checks string that can have a varying amount of serial numbers separated by space and each serial number can have only numbers and have a length of 10 characters
      serialNumbers: (value: string) => {
        const replacedString = value.split('\n');
        let valid = true;
        replacedString.forEach((serialNumber) => {
          if (!/^[0-9]{10}$/.test(serialNumber)) {
            valid = false;
          }
        });
        return valid || this.$t('validationRules.serialNumbers');
      },
    };
  }
}
