
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { Validation } from '@/ui/mixins/validation';
import { IGetLocationWithDelay, IWeatherLocation } from '@/types/app.types';
import { debounce } from 'lodash';
import LocationService from '@/services/LocationService';
import { IPartner } from '@/types/partner.types';
import { IPartnerWorkspace, IWorker } from '@/types/partnerWorkspace.types';
import ManageWorker from '@/ui/components/modals/Partner/ManageWorker.vue';
import { Action, Getter, State } from 'vuex-class';
import { IAppState } from '@/store/modules/app/types';

/**
 * Component that allows to create a new partner project
 */
@Component({
  components: {
    ModalWindow,
    ManageWorker,
  },
})
export default class CreatePartnerProject extends mixins(Validation) {
  @State('app') appState!: IAppState;
  @Getter('partners/partners') partners!: IPartner[];
  @Action('partnerWorkspaces/createWorkspace') createWorkspace!: (data: {workspace: IPartnerWorkspace; id: string}) => Promise<void>;

  project: IPartnerWorkspace = {
    name: '',
    meta: {
      phone_number: '',
      description: '',
      logo: '',
      location: {
        display_name: '',
        lat: '0',
        lon: '0',
      },
      type: '',
      workers: [],
      tasks: [],
      offers: [],
      documentation: [],
      solar_calculation_results: [],
    },
    id: '',
    created_at: '',
  };
  selectedPartner = '';
  valid = false;

  // search location start
  location: IWeatherLocation | null = null;
  searchPlaces: string | null = null;
  placesArr: IWeatherLocation[] = [];
  getLocationWithDelay: IGetLocationWithDelay | null = null;

  headers = [
    {
      text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.headers.name'),
      value: 'name',
      sortable: false,
    },
    {
      text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.headers.email'),
      value: 'email',
      sortable: false,
    },
    {
      text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.headers.actions'),
      value: 'actions',
      sortable: false,
    },
  ];

  /*
    * Returns the partner items for the partner select
    */
  get partnerItems() {
    const mappings = this.partners.map((partner) => {
      return { value: partner.id, text: partner.data.name };
    });
    return mappings;
  }

  get projectTypes() {
    return [
      { text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.projectTypes.efh'), value: 'efh' },
      { text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.projectTypes.mfh'), value: 'mfh' },
      { text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.projectTypes.ac'), value: 'ac' },
      { text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.projectTypes.dc'), value: 'dc' },
      { text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.projectTypes.business'), value: 'business' },
      { text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.projectTypes.industry'), value: 'industry' },
    ];
  }

  handleDeleteWorker(worker: IWorker) {
    const index = this.project.meta.workers?.findIndex((w: IWorker) => w.id === worker.id);
    if (index !== undefined && index !== -1) {
      this.project.meta.workers?.splice(index, 1);
    }
  }

  handleAddWorker(worker: IWorker) {
    this.project.meta.workers?.push(worker);
  }

  handleEditWorker(worker: IWorker) {
    const index = this.project.meta.workers?.findIndex((w: IWorker) => w.id === worker.id);
    if (index !== undefined && index !== -1) {
      this.project.meta.workers?.splice(index, 1, worker);
    }
  }

  @Watch('searchPlaces')
  async onSearchPlaces(val: string | null) {
    const isEqualToSaved = this.project?.meta?.location?.display_name === val;
    if (val && val.length > 2 && !isEqualToSaved) {
      this.getLocationWithDelay?.(val);
    }
  }

  setLocation(location: IWeatherLocation) {
    this.location = { ...location };
    this.placesArr.push({ ...location });
  }
  // search location end

  get currentLang() {
    return this.$i18n.locale;
  }

  async onDialogStatusChange(status: boolean) {
    if (status) {
      await this.$nextTick();
      this.validate();
    } else {
      this.resetToDefault();
    }
  }

  resetToDefault() {
    this.project = {
      name: '',
      meta: {
        phone_number: '',
        description: '',
        logo: '',
        location: {
          display_name: '',
          lat: '0',
          lon: '0',
        },
        type: '',
        workers: [],
        tasks: [],
        offers: [],
        documentation: [],
        solar_calculation_results: [],
      },
      id: '',
      created_at: '',
    };
    this.selectedPartner = '';
    this.valid = false;
    this.location = null;
    this.searchPlaces = null;
    this.placesArr = [];
  }

  @Watch('currentLang', { deep: true })
  handleLangChange() {
    this.validate();
  }

  validate() {
    (this.$refs.form as any)?.validate();
  }

  createProject() {
    let partnerId = '';
    if (this.appState.user.super_admin) {
      // select field
      partnerId = this.selectedPartner;
    } else {
      partnerId = this.appState.user.partner ?? '';
    }
    this.project.meta.location = this.location as IWeatherLocation;
    this.createWorkspace({ workspace: this.project, id: partnerId });
    (this.$refs.ModalWindowInstance as any).closeDialog();
  }

  created() {
    // fetch location with 1sec delay
    this.getLocationWithDelay = debounce(async (place) => {
      this.placesArr = await LocationService.fetchLocations(place);
    }, 1000);
  }

  mounted() {
    this.$nextTick(() => {
      this.validate();
    });
  }
}
