
import { Vue, Component } from 'vue-property-decorator';
import SliderMock from '@/ui/components/devices/previews/components/SliderMock.vue';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    SliderMock,
    SwitchMock,
    DeviceLayout,
  },
})
export default class LightRGBPreview extends Vue {
  switchState = false
  slider = 0
  rgbState: any = {
    r: 255,
    g: 0,
    b: 0,
    a: 1,
  }

  get previewNameLang() {
    return this.$t('devices.LightRGB.previewName');
  }
}
