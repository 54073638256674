
import { Vue, Component, Prop } from 'vue-property-decorator';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { SendingState } from '../devices/BasicControl';
import { FeedbackAnimationClass } from '@/types/common.types'

@Component({
  components: {
    CircleSpinner,
  },
})
export default class FeedbackProvider extends Vue {
  @Prop({ default: false }) sendingState!: SendingState;

  feedbackAnimationClass: FeedbackAnimationClass = {
    success: false,
    error: false,
  }
  animationTimer: any = null;

  get isOverlayActive() {
    return this.sendingState !== SendingState.None;
  }

  handleAnimation(animationType: string) {
    clearTimeout(this.animationTimer);
    this.feedbackAnimationClass[animationType as keyof FeedbackAnimationClass] = true;
    this.animationTimer = setTimeout(() => {
      this.feedbackAnimationClass[animationType as keyof FeedbackAnimationClass] = false;
    }, 2000);
    this.$emit('onLastFeedbackDone');
  }

  onFeedbackStart() {
    console.log('start');
  }

  beforeDestroy() {
    clearTimeout(this.animationTimer);
  }
}
