
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { IAppState } from '@/store/modules/app/types';

/**
 * Component that represent Localisation
 * Navigation where possible to switch languages
 */
@Component
export default class Localisation extends Vue {
  @State('app') appState!: IAppState

  selectedLanguage = 0
  get languages() {
    return [
      { title: 'uiComponents.localisationLanguagesList.en', locale: 'en' },
      { title: 'uiComponents.localisationLanguagesList.de', locale: 'de' },
      { title: 'uiComponents.localisationLanguagesList.it', locale: 'it' },
    ];
  }

  get user() {
    return this.appState.user;
  }

  /**
   * Save current locale in browser local storage
   */
  saveLocale() {
    const currentLocales: any = localStorage.getItem('lynusLocales');
    const obj = { [this.user.id]: this.$i18n.locale };
    const res = currentLocales ? { ...JSON.parse(currentLocales), ...obj } : { ...obj };

    localStorage.setItem('lynusLocales', JSON.stringify(res));
  }

  /**
   * Load current locale from browser local storage
   */
  loadLocale() {
    const lynusLocales = localStorage.getItem('lynusLocales');

    if (lynusLocales) {
      const locale = JSON.parse(lynusLocales)[this.user.id];
      if (locale) {
        this.$i18n.locale = locale;
      }
    }
  }

  handleLocale(index: number) {
    this.$i18n.locale = this.languages[index].locale;
    this.selectedLanguage = index;
    this.saveLocale();
  }

  mounted() {
    this.loadLocale();
    this.selectedLanguage = this.languages.findIndex((el: any) => el.locale === this.$i18n.locale);
  }
}
