
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { isEqual, cloneDeep } from 'lodash';
import { VCombobox, VSelect, VCheckbox } from 'vuetify/lib';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import {
  FormUpdateTracker,
} from '@/ui/mixins/formUpdateTracker';
import InputFieldNumber from '@/ui/components/components/InputFieldNumber.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import ComboboxField from '@/ui/components/modals/components/form/ComboboxField.vue';
import TextField from '@/ui/components/modals/components/form/TextField.vue';
import DatePickerCustom from '@/ui/components/modals/components/DatePickerCustom.vue';
import NavigationTab from '@/ui/components/modals/components/NavigationTab.vue';
import DynamicField from '@/ui/components/modals/components/DynamicField.vue';
import EMSAdditionalFields
  from '@/ui/components/modals/ManageMLModel/components/EMSAdditionalFields.vue';
import EnergyViewAdditionalFields
  from '@/ui/components/modals/ManageMLModel/components/EnergyViewAdditionalFields.vue';
import DynamicFieldWithTitle from '@/ui/components/modals/components/DynamicFieldWithTitle.vue';
import ManageSystemsDecreaseModal
  from '@/ui/components/modals/ManageMLModel/components/ManageSystemsDecreaseModal.vue';
import { cutNumberFromString } from '@/ui/components/modals/ManageMLModel/utils';
import { isObject } from '@/utils/typesGuards';
import { getDefaultRulesObject, getSingleRuleObject } from '@/utils/utilsFunctions';
import { IVariable } from '@/types/variables.types';
import {
  EXTERNAL_ENERGY_MEASUREMENTS,
  EXTERNAL_ENERGY_MEASUREMENTS_FOR_HYBRID_SYSTEMS,
  SYSTEMS_WHERE_CALCULATE_AS_EXTERNAL_DEVICE_WORKS,
  SYSTEMS_WHERE_EXTERNAL_ENERGY_MEASUREMENTS_WORKS,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/systemDialog';
import { IRoom } from '@/types/rooms.types';
import {
  ISetpointOptimizerRoomTemperatures,
  ISetpointOptimizerRoomTemperatureItem,
} from '@/types/mpc/devices/setpointOptimizer.types';

const DEVICES_WITH_EXTERNAL_MEASUREMENTS = ['EMSV2', 'EnergyViewV2'];

/**
 * Scheme to create, modify a specific ML Model device.
 * Specified in the ML Model device type definition in store/mpc/mlModelTypes.ts
 */
@Component({
  methods: { cutNumberFromString },
  components: {
    ComboboxField,
    TextField,
    'v-combobox': VCombobox,
    'v-select': VSelect,
    'v-checkbox': VCheckbox,
    InputFieldNumber,
    CircleSpinner,
    DatePickerCustom,
    NavigationTab,
    DynamicField,
    EMSAdditionalFields,
    DynamicFieldWithTitle,
    EnergyViewAdditionalFields,
    ManageSystemsDecreaseModal,
  },
})
export default class EMSSchema extends mixins(Validation, FormUpdateTracker) {
  @Prop({
    default: () => ({
      name: '',
      data: {
        type: '',
        meta: {},
      },
      collection_id: '',
    }),
  }) deviceData!: any;
  @Prop({}) isEditModal!: any;
  @Prop({ default: '' }) activeRoomId!: string;

  @State('measurements') measurementsState: any;
  @State('rules') rulesState!: any;
  @Getter('projects/project') project!: any;
  @Getter('rooms/sortedRoomsByName') sortedRoomsByName!: IRoom[];
  @Getter('mpc/mlModelTypes') mlModelTypes!: any;
  @Getter('devices/devicesTypes') devicesTypes!: any;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;
  @Action('rules/loadRules') loadRules!: (project_id: any) => any;
  @Action('rules/addRule') addRule!: (payload: any) => any;
  @Action('rules/addRules') addRules!: (payload: any) => any;
  @Action('rules/deleteRule') deleteRule!: (payload: any) => any;
  @Getter('variables/variablesForComboBox') variablesForComboBox!: IVariable[];

  vSelectRerenderKey = 0;
  stage = 1
  mlModel: any = null
  updatingDeviceProcess = false // prop responds for block modal when updating ems (rules, fields ...)
  currentSystem: any = {
    group: 'ems',
    system: null,
  }

  copyOfSystemsForValidationError: any = null;

  // local dynamic forms
  systemsForm: any = {}
  controllerMainMappingsLocal: any = {}
  additionalFields: any = {}

  // ====================================== optional mappings, external energy mappings
  // optional mappings
  isOptionalFieldsVisible = false;

  // external energy mappings
  get showExternalEnergyMappingsCheckbox() {
    const isSystemPermitted = SYSTEMS_WHERE_EXTERNAL_ENERGY_MEASUREMENTS_WORKS.includes(this.currentSystem.group);
    return this.hasExternalMeasurements && isSystemPermitted;
  }

  get showCalculateAsExternalDevice() {
    return SYSTEMS_WHERE_CALCULATE_AS_EXTERNAL_DEVICE_WORKS.includes(this.currentSystem.group);
  }

  get systemsExternalEnergyMappingsCheckboxValue() {
    const instance = this.systemsForm?.[this.currentSystem.group]?.components?.[this.currentSystem.system]?.external_energy_measurement;
    return typeof instance === 'boolean' ? instance : false;
  }

  get emsSelectWidth() {
    return this.deviceData.data.type === 'EnergyIO' ? '' : 'width: 60%';
  }

  isExternalEnergyMeasurement({ prop, system }: { prop: string; system: string}) {
    if (system === 'battery') {
      // battery system has different external energy measurements
      return EXTERNAL_ENERGY_MEASUREMENTS_FOR_HYBRID_SYSTEMS.includes(prop);
    }
    return EXTERNAL_ENERGY_MEASUREMENTS.includes(prop);
  }

  /**
   * Shows/hide optional, external energy, required mappings according to condition
   * @param system
   * @param systemInstance
   * @param prop systemInstance mapping key
   */
  isSystemMappingVisible({ system, systemInstance, prop }: {
    system: string;
    systemInstance: string;
    prop: string;
  }) {
    const isOptional = this.isOptional(this.systems[system].mappings[prop].optional, prop, system, systemInstance);

    if (!this.hasExternalMeasurements) {
      return !this.isOptionalFieldsVisible && isOptional;
    } else {
      if (!this.systemsExternalEnergyMappingsCheckboxValue) return !this.isOptionalFieldsVisible && isOptional;
      else return !this.isExternalEnergyMeasurement({ prop, system });
    }
  }

  handleAdded(fieldType: string, input: string, key: string) {
    const { dependency } = this.systems[this.currentSystem.group].mappings[input];
    (this.$refs[`dynamic${fieldType}Field-${dependency}`] as any)[0].addMaxPowerItem(null, true, key);
  }

  handleDeleted(fieldType: string, input: string, key: string) {
    const { dependency } = this.systems[this.currentSystem.group].mappings[input];
    (this.$refs[`dynamic${fieldType}Field-${dependency}`] as any)[0].deleteMaxPowerItem(key, true);
  }

  // ======================================

  // ====================================== Check if form was updated (main logic in FormUpdateTracker mixin)
  get isFormUnchanged() {
    if (this.isEditModal) {
      const local = { ...this.controllerMainMappingsLocal, ...this.systemsForm };
      return isEqual(this.deviceData, this.mlModel) && isEqual(this.dataSnapshot, local);
    } else {
      const local = { ...this.controllerMainMappingsLocal, ...this.systemsForm };
      return !this.mlModel?.name && isEqual(this.dataSnapshot, local);
    }
  }

  // ======================================

  @Watch('stage')
  onStageChange(val: number, oldVal: number) {
    if (oldVal === 1 && val === 2) {
      this.copyOfSystemsForValidationError = null;
      this.copyOfSystemsForValidationError = JSON.parse(JSON.stringify(this.systemsForm));
    }
    if (val === 2) {
      // init currentSystem when go on stage 2. Needs to avoid error when we feel some mappings on system what we later delete
      this.currentSystem = { group: 'ems', system: null };

      // if mpc not have any general controller mappings we set active first existed system
      if (!this.isGeneralMappings) {
        const [group, system] = this.currentGroupedSystemsListWithGeneralMappings[0];
        this.currentSystem = { group, system: system[0] };
      }

      // load variables list for mappings
      this.fetchVariables(this.$route.params.id);
    }
  }

  get rulesList() {
    return this.rulesState.rulesList;
  }

  get rulesListIds() {
    return this.rulesList.map((rule: any) => rule.id);
  }

  // steps validation
  // step 1
  get nameValidation() {
    return !!this.mlModel?.name?.length && !!this.currentSystemsListFiltered?.length;
  }

  // step 2
  /**
   * Checks general EMS mappings
   * @return true if general mappings valid, else false
   */
  get emsGeneralMappingsValidation() {
    if (this.stage === 2) {
      // get all required mappings keys
      const requiredFieldsMainMappings = Object.entries(this.mlModelMappings)
        .filter((field: any) => !field[1].optional)
        .map((field: any) => field[0]);
      return requiredFieldsMainMappings.map((el: any) => {
        const mapping = this.controllerMainMappingsLocal[el];
        if (isObject(mapping)) {
          // checks all values if mapping is object
          return Object.values(mapping).every((el: any) => el.length);
        } else {
          return mapping;
        }
      }).every((el: any) => el);
    } else {
      return null;
    }
  }

  /**
   * Combines systems by group. Creates Map collection width all group validation status
   * and group instances statuses.
   * @return Map collection with status of validation for every system of EMS
   */
  get systemsByGroupsValidation() {
    const res = this.currentGroupedSystemsList.map((system: [string, string[]]) => {
      const systemName: string = system[0];
      const systemInstances: string[] = system[1];

      const systemInstancesWithMappings: [string, boolean][] = systemInstances.map((instance: string) => {
        const requiredMappingsList: string[] = Object.keys(this.systems[systemName].mappings)
          .filter((field: string) => {
            const fieldOptionsOptional = this.systems[systemName]?.mappings[field]?.optional;
            const isFieldOptional = this.isOptional(fieldOptionsOptional, field, systemName, instance);
            return !isFieldOptional;
          });

        const isExternalMeasurement: boolean = this.systemsForm?.[systemName]?.components?.[instance]?.external_energy_measurement;
        // if system title field is required, check it together with external measurements in validation
        const requiredTitleForExternalMeasurements = requiredMappingsList.filter((prop: string) => prop === 'title');
        // if showExternalEnergyMeasurements selected validate only external energy measurements
        const requiredFieldsKeys = !isExternalMeasurement ? requiredMappingsList : [...EXTERNAL_ENERGY_MEASUREMENTS, ...requiredTitleForExternalMeasurements];

        const requiredFieldsValues = requiredFieldsKeys.map((field: string) => {
          return this.systemsForm[systemName].components[instance][field];
        });
        const listOfRequiredFieldsValuesAsBoolean = requiredFieldsValues.map(
          (value: string | ISetpointOptimizerRoomTemperatures | { [key: string]: string }) => {
            if (isObject(value)) {
              const arr = Object.values(value).map((el: ISetpointOptimizerRoomTemperatureItem | string) => {
                return isObject(el)
                  ? !!(el as ISetpointOptimizerRoomTemperatureItem).variable.length
                  : !!(el as string).length;
              });
              return arr.every((el: boolean) => el);
            } else {
              const validateString = (str: string) => str.replace(/\s/g, '');
              return typeof value === 'string' ? !!validateString(value).length : !!value;
            }
          },
        );

        return [instance, listOfRequiredFieldsValuesAsBoolean.some((value: boolean) => !value)];
      });

      const allInstances: boolean = systemInstancesWithMappings.map((el: [string, boolean]) => el[1]).some((el: boolean) => el);
      return [system[0], {
        instances: new Map(systemInstancesWithMappings),
        unFilled: allInstances,
      }];
    });
    return new Map(res as [string, { instances: Map<string, boolean>; unFilled: boolean }][]);
  }

  /**
   * Checks systems groups validation statuses
   * @return boolean, system group validation status
   */
  get systemsValidation() {
    const arr: any = Array.from(this.systemsByGroupsValidation.values()).map((el: any) => el.unFilled);
    return !arr.some((el: any) => el);
  }

  /**
   * Combined validation of all mappings in stage 2
   * @return boolean, status of stage 2 validation
   */
  get mappingValidation() {
    if (this.stage === 2) {
      return this.emsGeneralMappingsValidation && this.systemsValidation;
    }
    return true;
  }

  // step 3
  /**
   * Combined validation of ML Model collection_id field and additional fields
   * @return boolean, status of stage 3 validation
   */
  get settingsValidation() {
    if (this.mlModelAdditionalFields?.objects) {
      const arr2dValues = Object.values(this.additionalFields).map((el: any) => Object.values(el));
      const flat = arr2dValues.flat();
      const isFilled = !flat.some((el: any) => typeof el !== 'number');
      return !!this.mlModel.collection_id.length && isFilled;
    }
    return !!this.mlModel.collection_id.length;
  }

  /**
   * Validation for every error field in systems.
   * Need to avoid create/delete rules when edit EMS
   * @param system system key
   * @param systemInstance system instance key
   * @param mappingKey system instance mapping key
   */
  errorVariableValidation(system: string, systemInstance: string, mappingKey: string) {
    if (this.isEditModal && mappingKey === 'error') {
      const copyOfCurrentWhenGoFromStageOne = this.copyOfSystemsForValidationError[system].components?.[systemInstance]?.error;
      const current = this.systemsForm[system].components?.[systemInstance]?.error;
      const old = this.deviceData.data.meta.controllerMappings[system].components?.[systemInstance]?.error;

      const { rules } = this.mlModel.data.meta;
      const rulesBySystem: undefined | {
        errorRule: string;
        warningRule: string;
      } = rules[systemInstance];
      const checkIfRulesForSystemWasDeleted = () => {
        if (rulesBySystem) {
          const warning: undefined | string = rulesBySystem.warningRule;
          const error: undefined | string = rulesBySystem.errorRule;
          const isWarningRuleExist = warning ? this.rulesListIds.some((ruleId: string) => ruleId === warning) : null;
          const isErrorRuleExist = error ? this.rulesListIds.some((ruleId: string) => ruleId === error) : null;
          return isErrorRuleExist || isWarningRuleExist;
        }
        return false;
      };
      // get unblock when some of props = false
      return copyOfCurrentWhenGoFromStageOne === old
        && copyOfCurrentWhenGoFromStageOne === current
        && !!old.length
        && checkIfRulesForSystemWasDeleted();
    }
    return false;
  }

  // define current EMS instance mappings, other settings
  get mlModelSchema() {
    // TODO: condition used because EMS, EnergyViewV2 placed in devicesTypes
    return this.mlModelTypes[this.deviceData.data.type] ?? this.devicesTypes[this.deviceData.data.type];
  }

  get mlModelMappings() {
    return this.mlModelSchema.controllerMappings;
  }

  get mlModelAdditionalFields() {
    return this.mlModelSchema.additionalFields;
  }

  get mappingsByColumns() {
    return this.mlModelSchema.mappingsByColumns;
  }

  get groups() {
    return this.mlModelSchema.groups;
  }

  get systems() {
    return this.mlModelSchema.systems;
  }

  /**
   * Checks if device has external energy measurements
   */
  get hasExternalMeasurements() {
    return DEVICES_WITH_EXTERNAL_MEASUREMENTS.includes(this.deviceData.data.type);
  }

  get isGeneralMappings() {
    return Object.keys(this.mlModelMappings).length;
  }

  /**
   * Checks if EMS has optional general mappings
   * @return boolean, status of all general optional mappings
   */
  get isGeneralOptionalMappings() {
    return Object.values(this.mlModelMappings).map((el: any) => el.optional).some((el: boolean) => el);
  }

  /**
   * Checks if some system has optional mappings fields
   * @return boolean, true if system has optional mapping
   */
  get isSystemsOptionalMappings() {
    const systems = this.currentGroupedSystemsList.length ? this.currentGroupedSystemsList.map((el: any) => el[0]) : [];
    return systems.map((el: string) => {
      const systemOptions = this.systems[el];
      return Object.values(systemOptions.mappings).map((map: any) => map.optional).some((el: boolean) => el);
    }).some((el: boolean) => el);
  }

  /**
   * Getter filter current systems by error fields. Needs for creating rules
   */
  get filteredSystemsByErrorWarning() {
    const systems: any = Object.entries(this.systemsForm).filter((el: any) => el[1].count);
    const systemsInstances: any = systems.map((system: any) => Object.entries(system[1].components)
      .map((el: any) => [el[0], el[1].error])
      .filter((inst: any) => inst[1]))
      .reduce((acc: any, item: any) => acc.concat(item), []);
    return systemsInstances;
  }

  get groupsList() {
    return Object.entries(this.groups);
  }

  // logic responsible for groups, systems lists
  get groupOfSystemsList() {
    return Object.keys(this.systemsForm);
  }

  get currentSystemsListFiltered() {
    return Object.entries(this.systemsForm).filter((system: any) => Object.keys(system[1].components).length);
  }

  get currentGroupedSystemsList(): [string, string[]][] {
    return this.currentSystemsListFiltered.map((group: [string, any]) => {
      return [
        group[0],
        Object.keys(group[1].components).sort((a: string, b: string) => {
          const numA = parseInt(a.replace(/\D/g, ''), 10);
          const numB = parseInt(b.replace(/\D/g, ''), 10);
          return numA - numB;
        }),
      ];
    });
  }

  get currentGroupedSystemsListWithGeneralMappings() {
    if (!this.isGeneralMappings) {
      return [...this.currentGroupedSystemsList];
    } else {
      return [...[['ems', []]], ...this.currentGroupedSystemsList] as [string, string[]][];
    }
  }

  /**
   * Converts systems instances quantity to array
   * @return Map collection of systems and array(number of system instances)
   */
  get getSystemQuantity() {
    const systemsArr: any = Object.entries(this.systems).map((system: any) => {
      const systemName: string = system[0];
      const systemQuantity: number[] = Array.from({ length: system[1].quantity }, (_, i) => i + 1);
      return [systemName, systemQuantity];
    });
    return new Map(systemsArr);
  }

  /**
   * Init local EMS systems form
   */
  initSystemsFormStructure() {
    const list = Object.keys(this.systems);
    list.forEach((system: string) => {
      const count = +this.systems[system].required;
      this.systemsForm = { ...this.systemsForm, ...{ [system]: { components: {}, count } } };
    });
  }

  // Shows field only if dependant field (condition) is required or filled
  isOptionalAndNotFilled(input: string, requiredField: string, group: string, system: string) {
    return this.systems?.[group]?.mappings?.[requiredField]?.optional &&
      !this.systemsForm?.[group]?.components?.[system]?.[requiredField];
  }

  isOptional(optional: boolean | string, input: string, group: string, system: string) {
    const isExternalMeasurementsEnabled = this.systemsForm[group].components?.[system]?.external_energy_measurement;
    // if External measurements enabled, make all visible fields required
    if (isExternalMeasurementsEnabled) {
      return false;
    } else {
      if (typeof optional === 'boolean') return optional; // look if in schema field required
      return this.isOptionalAndNotFilled(input, optional, group, system); // if in schema some field looks on other field state
    }
  }

  /**
   * Going through device additional fields and create options for it.
   * Additional fields placed in ML Model types definition store/mpc/mlModelTypes.ts
   */
  initAdditionalFields() {
    if (this.mlModelAdditionalFields) {
      const objectsKeys = Object.keys(this.mlModelAdditionalFields.objects);
      objectsKeys.forEach((obj: string) => {
        if (this.isEditModal) {
          this.additionalFields = {
            ...this.additionalFields,
            ...{ [obj]: { ...this.mlModel.data.meta[obj] } },
          };
        } else {
          const props = this.mlModelAdditionalFields.objects[obj];
          let propsObj = {};
          props.forEach((prop: string) => {
            propsObj = { ...propsObj, ...{ [prop]: '' } };
          });
          this.additionalFields = {
            ...this.additionalFields,
            ...{ [obj]: { ...propsObj } },
          };
        }
      });
    }
  }

  /**
   * Goes through the system mappings and creates an option object for each
   * @param groupName system groups name
   * @return system mappings form object
   */
  defineMappingsForSystem(groupName: any) {
    if (this.systems[groupName].isDynamicFields) {
      const form = { ...this.systems[groupName].initMappings };
      const mappingsList = Object.entries(this.systems[groupName].mappings);
      const dynamicMappings = mappingsList.filter((mapping: any) => mapping[1].isDynamic);
      dynamicMappings.forEach((mapping: any) => {
        if (mapping[1].vuetifyComponent === 'DynamicFieldWithTitle') {
          form[mapping[0]] = {
            [`mp-${Date.now()}`]: {
              variable: '',
              title: '',
            },
          };
        } else {
          form[mapping[0]] = { [`mp-${Date.now()}`]: '' };
        }
      });
      return form;
    } else {
      return { ...this.systems[groupName].initMappings };
    }
  }

  /**
   * Creates instances for system according to selected system quantity
   */
  initSystemsFormWhenNewDevice() {
    this.groupOfSystemsList.forEach((system: any) => {
      const arrOfSystemsInstances = Array
        .from({ length: this.systemsForm[system].count }, (_, i) => i + 1)
        .map(index => `${system}${index}`);

      let systemsFormLocal = {};
      arrOfSystemsInstances.forEach((systemInstance: any) => {
        systemsFormLocal = {
          ...systemsFormLocal,
          ...{ [systemInstance]: this.defineMappingsForSystem(system) },
        };
      });
      this.systemsForm[system].components = systemsFormLocal;
    });
  }

  /**
   * Init EMS general mappings form
   * @return general mappings form of EMS
   */
  initMainMappings() {
    let mappingsSchema: any = {};
    Object.keys(this.mlModelMappings).forEach((item: string) => {
      const { isDynamic } = this.mlModelMappings[item];
      const form: any = { [item]: { [`mp-${Date.now()}`]: '' } };
      const mapping: any = isDynamic ? form : { [item]: '' };
      mappingsSchema = { ...mappingsSchema, ...mapping };
    });
    return mappingsSchema;
  }

  /**
   * Init local systemsForm object when edit EMS device
   */
  initSystemsFormWhenEditDevice() {
    const { controllerMappings } = JSON.parse(JSON.stringify(this.deviceData.data.meta));
    const systemsList = Object.keys(this.systems);
    systemsList.forEach((system: any) => {
      const systemData = controllerMappings[system];
      this.systemsForm[system] = systemData ? { ...systemData } : { count: 0, components: {} };
    });
  }

  /**
   * Init local controllerMainMappingsLocal object
   */
  loadMainMappings() {
    const { controllerMappings } = this.deviceData.data.meta;
    const emsMappingsList = Object.keys(this.mlModelMappings);
    emsMappingsList.forEach((mapping: any) => {
      this.controllerMainMappingsLocal[mapping] = controllerMappings[mapping];
    });
  }

  // stage 1 function responsible for add, remove systems in systems groups
  cancelBaseAction(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  /**
   * Managing the number of systems by checkbox.
   * If the checkbox is disabled the number of systems is 0.
   * @param ev event
   * @param system current system key
   */
  handleSystemCountCheckbox({ ev, system }: any) {
    ev.preventDefault();
    ev.stopPropagation();
    this.systemsForm[system].count = this.systemsForm[system].count ? 0 : 1;

    const arrOfSystemsInstances = Array
      .from({ length: this.systemsForm[system].count }, (_, i) => i + 1)
      .map(index => `${system}${index}`);

    let systemsFormLocal = {};
    arrOfSystemsInstances.forEach((systemInstance: any) => {
      systemsFormLocal = {
        ...systemsFormLocal,
        ...{ [systemInstance]: this.defineMappingsForSystem(system) },
      };
    });
    this.systemsForm[system].components = systemsFormLocal;
  }

  handleManageSystemsSave({ instances, system }: { instances: string[]; system: string }) {
    this.systemsForm[system].components = instances.reduce((acc: any, key: string) => {
      return { ...acc, ...{ [key]: this.systemsForm[system].components[key] } };
    }, {});
    this.systemsForm[system].count = instances.length;
  }

  handleManageSystemsCancel() {
    this.vSelectRerenderKey = Date.now();
  }

  /**
   * Managing the number of systems by select.
   * @param system current system key
   * @param event event
   */
  handleSystemCountSelect({ event, system }: { event: number; system: string }) {
    const currentLength = event;
    const prevLength = Object.keys(this.systemsForm[system].components).length;
    const isIncrease = currentLength > prevLength;

    const copy = [...Object.keys(this.systemsForm[system].components)];
    // define and add names for systems instances
    let arrOfSystems = Array
      .from({ length: currentLength }, (_, i) => i + 1)
      .map(index => `${system}${index}`);

    if (copy.length && isIncrease) {
      let result = Array.from(new Set([...arrOfSystems, ...copy]));
      const numberOfItemsToRemove = result.length - arrOfSystems.length;
      const range = Array.from({ length: numberOfItemsToRemove }, (_, i) => i + 1);
      range.forEach((item) => {
        const arr = result.filter((i: any) => !copy.includes(i));
        const lastEl = arr[arr.length - 1];
        result = result.filter((i: any) => i !== lastEl);
      });

      arrOfSystems = [...result];
    }

    // create form by array of systems names
    let systemsFormLocal: any = {};
    arrOfSystems.forEach((systemName: any) => {
      systemsFormLocal = {
        ...systemsFormLocal,
        ...{ [systemName]: this.defineMappingsForSystem(system) },
      };
    });

    if (!isIncrease) {
      (this.$refs[`system-${system}`] as any)[0].handleDialogOnDecrease(prevLength - currentLength);
    } else {
      this.systemsForm[system].count = event;

      this.systemsForm[system].components = Object.keys(systemsFormLocal).reduce((acc: any, key: string) => {
        const instance = this.systemsForm[system].components[key] || systemsFormLocal[key];
        return { ...acc, ...{ [key]: instance } };
      }, {});
    }
  }

  // set current system
  handleCurrentSystem(obj: any) {
    this.currentSystem = obj;
  }

  defineSystemNumber(system: any) {
    return system.replace(/\D/g, '');
  }

  /**
   * Clear date picker value on press button cancel on calendar
   * @param formField form field key
   */
  datePickerCustomClearDate(formField: any) {
    this.controllerMainMappingsLocal[formField] = this.deviceData.data.meta?.controllerMappings?.startDate || '';
  }

  /**
   * Creates general rules for Ml Model device according to errorWarning field.
   * Creates systems rules for Ml Model device according to error field.
   * @param emsData current Ml Model data object
   */
  async addRulesWhenCreateEMS(emsData: any) {
    emsData.data.meta.rules = {};
    const warningRulesSystemList: any = this.filteredSystemsByErrorWarning.map((el: any) => {
      return getSingleRuleObject(`${emsData.name}_${el[0]} Warning Rule`, el[1], 'Device has a Warning', 1);
    });
    const errorRulesSystemList: any = this.filteredSystemsByErrorWarning.map((el: any) => {
      return getSingleRuleObject(`${emsData.name}_${el[0]} Error Rule`, el[1], 'Device has an Error', 2);
    });
    // ems general rules
    let generalRules: any = [];
    if (emsData.data.meta.controllerMappings?.errorWarning !== undefined) {
      generalRules = getDefaultRulesObject(emsData.name, emsData.data.meta.controllerMappings.errorWarning, 'Device');
    }
    // merging all rules obj in one array
    const allRulesList: any = emsData.data.type === 'EMS' || emsData.data.type === 'EMSV2'
      ? [...generalRules, ...warningRulesSystemList, ...errorRulesSystemList]
      : [...generalRules];

    // if rules list empty request not send
    if (!allRulesList.length) {
      emsData.data.meta.warningRule = '';
      emsData.data.meta.errorRule = '';
      return;
    }

    // request
    const responseRulesList = await this.addRules({
      project_id: this.project.id,
      rulesList: allRulesList,
    });
    const resRules: any = responseRulesList.map((rule: any) => ({ [rule.name]: rule.id }));
    // set systems rules in copy.data.meta.rules
    this.filteredSystemsByErrorWarning.forEach((el: any) => {
      const warningRuleObj: any = resRules.find((rule: any) => rule[`${emsData.name}_${el[0]} Warning Rule`]);
      const errorRuleObj: any = resRules.find((rule: any) => rule[`${emsData.name}_${el[0]} Error Rule`]);
      emsData.data.meta.rules = {
        ...emsData.data.meta.rules,
        [el[0]]: {
          warningRule: warningRuleObj[`${emsData.name}_${el[0]} Warning Rule`],
          errorRule: errorRuleObj[`${emsData.name}_${el[0]} Error Rule`],
        },
      };
    });
    // set general ems rules in copy.meta
    const getWarningRule = () => {
      if (!this.isGeneralMappings) return '';
      const warningRuleObj: any = resRules.find((rule: any) => rule[`${emsData.name} Warning Rule`]);
      return warningRuleObj[`${emsData.name} Warning Rule`];
    };
    const getErrorRule = () => {
      if (!this.isGeneralMappings) return '';
      const errorRuleObj: any = resRules.find((rule: any) => rule[`${emsData.name} Error Rule`]);
      return errorRuleObj[`${emsData.name} Error Rule`];
    };
    emsData.data.meta.warningRule = getWarningRule();
    emsData.data.meta.errorRule = getErrorRule();
  }

  /**
   * Replacing existing general rules with new ones when errorWarning field is changed
   * @param emsData current Ml Model data object
   */
  async generalRulesWhenUpdateEMS(emsData: any) {
    const oldErrorWarningVar = this.deviceData.data.meta.controllerMappings.errorWarning;
    const newErrorWarningVar = emsData.data.meta.controllerMappings.errorWarning;
    if (!this.isGeneralMappings) return;
    // add check if warning Rule or error Rule ID's are noit mapped or empty
    if (!emsData.data.meta.warningRule || !emsData.data.meta.errorRule) return;

    // if general error value was deleted
    if (oldErrorWarningVar && !newErrorWarningVar) {
      await Promise.all([
        this.deleteRule({
          project_id: this.project.id,
          rule_id: emsData.data.meta.warningRule,
        }),
        this.deleteRule({
          project_id: this.project.id,
          rule_id: emsData.data.meta.errorRule,
        }),
      ]);
    }
    // if errorWarning was changed
    if (oldErrorWarningVar !== newErrorWarningVar) {
      // delete old rules
      await Promise.all([
        this.deleteRule({
          project_id: this.project.id,
          rule_id: emsData.data.meta.warningRule,
        }),
        this.deleteRule({
          project_id: this.project.id,
          rule_id: emsData.data.meta.errorRule,
        }),
      ]);

      const { errorWarning } = emsData.data.meta.controllerMappings;
      if (errorWarning) {
        const rulesList: any = getDefaultRulesObject(emsData.name, emsData.data.meta.controllerMappings.errorWarning, 'Device');
        // create new rules
        const res: any = await this.addRules({
          project_id: this.project.id,
          rulesList,
        });
        const getWarningRule = () => {
          const warningRuleObj: any = res.find((rule: any) => rule.name === `${emsData.name} Warning Rule`);
          return warningRuleObj.id;
        };
        const getErrorRule = () => {
          const errorRuleObj: any = res.find((rule: any) => rule.name === `${emsData.name} Error Rule`);
          return errorRuleObj.id;
        };
        emsData.data.meta.warningRule = getWarningRule();
        emsData.data.meta.errorRule = getErrorRule();
      }
    }
  }

  /**
   * Replacing existing systems rules with new ones when error field is changed
   * @param emsData current Ml Model data object
   */
  async addSystemRulesWhenUpdateEMS(emsData: any) {
    // define systems
    const {
      pv,
      generator,
      grid,
      battery,
      house,
      charge_station,
      electric_heating,
      heating_pump,
      big_consumer,
    } = this.deviceData.data.meta.controllerMappings;
    const old: any = {
      pv,
      generator,
      grid,
      battery,
      house,
      charge_station,
      electric_heating,
      heating_pump,
      big_consumer,
    };
    const oldArr: any = Object.values(old)
      .filter((system: any) => system.count)
      .map((system: any) => Object.entries(system.components).map((systemInstance: any) => [systemInstance[0], systemInstance[1].error]))
      .reduce((acc: any, el: any) => acc.concat(el), []);
    const currentArr = Object.values(this.systemsForm)
      .filter((system: any) => system.count)
      .map((system: any) => Object.entries(system.components).map((systemInstance: any) => [systemInstance[0], systemInstance[1].error]))
      .reduce((acc: any, el: any) => acc.concat(el), []);

    let copyOfEMSRules = JSON.parse(JSON.stringify(emsData.data.meta.rules));
    // ==== delete old rules
    let oldItemsToDelete: any = oldArr.filter((oldItem: any) => {
      const targ: any = currentArr.find((currentItem: any) => currentItem[0] === oldItem[0]) || false;
      const isValueEqual: any = targ ? targ[1] === oldItem[1] : false;
      return !(targ && isValueEqual);
    });
    // filter if old items was empty string
    if (oldItemsToDelete.length) {
      oldItemsToDelete = oldItemsToDelete.filter((el: any) => el[1].length);
    }

    // request to delete old rules
    oldItemsToDelete.forEach((el: any) => {
      this.deleteRule({ project_id: this.project.id, rule_id: copyOfEMSRules[el[0]].warningRule });
      this.deleteRule({ project_id: this.project.id, rule_id: copyOfEMSRules[el[0]].errorRule });
      delete copyOfEMSRules[el[0]];
    });

    // ==== create new rules
    let newItemsToCreate: any = currentArr.filter((currentItem: any) => {
      const targ: any = oldArr.find((oldItem: any) => oldItem[0] === currentItem[0]) || false;
      const isValueEqual: any = targ ? targ[1] === currentItem[1] : false;
      return !(targ && isValueEqual);
    });
    if (newItemsToCreate.length) {
      newItemsToCreate = newItemsToCreate.filter((el: any) => el[1].length);
    }
    const warningRules = newItemsToCreate.map((el: any) => {
      return getSingleRuleObject(`${emsData.name}_${el[0]} Warning Rule`, el[1], 'Device has a Warning', 1);
    });
    const errorRules = newItemsToCreate.map((el: any) => {
      return getSingleRuleObject(`${emsData.name}_${el[0]} Error Rule`, el[1], 'Device has an Error', 2);
    });
    const allRules = [...warningRules, ...errorRules];
    const responseRulesList = await this.addRules({
      project_id: this.project.id,
      rulesList: allRules,
    });
    newItemsToCreate.forEach((el: any) => {
      const warningRule = responseRulesList.find((rule: any) => rule.name === `${emsData.name}_${el[0]} Warning Rule`);
      const errorRule = responseRulesList.find((rule: any) => rule.name === `${emsData.name}_${el[0]} Error Rule`);
      const field = {
        [el[0]]: {
          warningRule: warningRule.id,
          errorRule: errorRule.id,
        },
      };
      copyOfEMSRules = { ...copyOfEMSRules, ...field };
    });
    emsData.data.meta.rules = copyOfEMSRules;
  }

  /**
   * Save Ml Model data in data base
   */
  async sendForm() {
    this.$emit('onFormUnchanged', true);
    // blocked send button to avoid another clicks
    this.updatingDeviceProcess = true;

    const copy = JSON.parse(JSON.stringify(this.mlModel));

    const controllerMappings = { ...this.systemsForm, ...this.controllerMainMappingsLocal };

    copy.data.meta = { ...copy.data.meta, ...this.additionalFields };

    copy.project_id = this.project.id;
    copy.data.meta.controllerMappings = controllerMappings;

    // ======== rules
    // ==== when create ems
    if (!this.isEditModal) {
      await this.addRulesWhenCreateEMS(copy);
      // ==== when edit ems
    } else {
      await this.generalRulesWhenUpdateEMS(copy);
      if (['EMS', 'EnergyView'].includes(this.deviceData.data.type)) {
        await this.addSystemRulesWhenUpdateEMS(copy);
      }
    }

    this.$emit('handleControl', copy);

    this.$emit('closeDialog');

    // unblock send button
    this.updatingDeviceProcess = false;
  }

  async created() {
    this.initSystemsFormStructure();
    this.mlModel = cloneDeep(this.deviceData);

    if (this.activeRoomId.length && !this.isEditModal) this.mlModel.collection_id = this.activeRoomId;

    this.controllerMainMappingsLocal = this.initMainMappings();

    // init fields in stage 3
    this.initAdditionalFields();

    if (this.isEditModal) {
      this.initSystemsFormWhenEditDevice();
      this.loadMainMappings();
    } else {
      this.initSystemsFormWhenNewDevice();
    }

    this.dataSnapshot = cloneDeep({ ...this.controllerMainMappingsLocal, ...this.systemsForm });
    await this.loadRules(this.$route.params.id);
  }
}
