
import { Vue, Component } from 'vue-property-decorator';
import RegentLighting from '@/ui/components/devices/previews/devices/RegentLighting.vue';

@Component({
  components: {
    RegentLighting,
  },
})
export default class RegentsView extends Vue {
  devices = [
    'RegentLighting',
  ]
}
