
import { Vue, Component, Prop } from 'vue-property-decorator';
import RoomItem from '@/ui/components/lists/RoomsListFlexGrid/RoomItem.vue';
import { Getter } from 'vuex-class';
import { IRoom } from '@/types/rooms.types';
import { IProject } from '@/types/project.types';

/**
 * Component that represent all rooms in Flex grid.
 * Used in Areas page.
 */
@Component({
  components: {
    RoomItem,
  },
})
export default class RoomsListFlexGrid extends Vue {
  @Prop() listOfRooms!: IRoom[];
  @Prop() activeRoomId!: string;
  @Prop() currentRoomData!: IRoom;
  @Getter('projects/project') project!: IProject;

  get isAreasMiniView() {
    if (this.project) return this.project.meta?.isAreasMiniView;
    return false;
  }

  /**
   * Checks is current room active
   * @param {string} roomId room id
   */
  activeRoom(roomId: string) {
    if (this.activeRoomId) {
      return this.activeRoomId === roomId;
    }
    this.$emit('handleRoomActiveStatus', this.listOfRooms?.[0]?.id);
    return this.listOfRooms?.[0]?.id === roomId;
  }
}
