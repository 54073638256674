// this import is needed in case we want to use a config from config.json for testing.
import * as whitelabelConfig from './config.json';

const lynusDefaultConfig = {
  TITLE: 'Lynus',
  LOGO_URL_LIGHT: '/images/lynus.svg',
  LOGO_URL_DARK: '/images/lynus_dark.svg',
  FAVICON_URL: '/favicon.ico',
  PROJECT_OPEN_SUBPAGE: '/favorites',
  REALM: 'lynus',
  KEYCLOAK_URL: 'https://accounts.lynus.io/auth',
  CLIENT_ID: 'console',
  API: 'https://api.lynus.io/v1',
  MPC_API: 'https://mpc.lynus.dev',
  LOAD_MPC: true,
  MQTT: 'wss://mqtt.lynus.io',
  DEFAULTS_TO_DARKMODE: false,
  THEMES: {
    light: {
      primary: '#ffffff',
      sideBarHighlight: '#ffffff',
      sideBarColor: '#f2f2f2',
      surface: '#454545',
      secondary: '#e4f4ff',
      accent: '#6CC1FE',
      accentLight: '#E4F4FF',
      lynusText: '#525252',
      lynusTextInvert: '#FFFFFF',
      lynusTextSidebar: '#171717',
      projectBackground: '#f2f2f2',
      deviceBackground: '#f2f2f2',
      secondaryDeviceBackground: '#D6D6D6',
      dndItemBackground: '#E4F4FF',
      inactiveItem: '#eaeaea',
      activeItem: '#E4F4FF',
      primaryBorder: '#c6c6c6',
      error: '#E83B3A',
      overlay: '#1e4a5f',
      lynusIcon: '#707070',
      green: '#30BF54',
    },
    dark: {
      primary: '#171717',
      sideBarHighlight: '#171717',
      sideBarColor: '#1a1a1a',
      surface: '#272727',
      secondary: '#313131',
      accent: '#0d6efd',
      accentLight: '#E4F4FF',
      lynusText: '#FFFFFF',
      lynusTextInvert: '#525252',
      lynusTextSidebar: '#FFFFFF',
      projectBackground: '#313131',
      deviceBackground: '#313131',
      secondaryDeviceBackground: '#414141',
      dndItemBackground: '#313131',
      inactiveItem: '#313131',
      activeItem: '#0d6efd',
      primaryBorder: '#5f5f5f',
      error: '#E83B3A',
      overlay: '#1e4a5f',
      lynusIcon: '#dbdbdb',
      green: '#30BF54',
    },
  },
  DEVICES: [
    'LightSwitch',
    'GeneralSwitchV2',
    'LightDimmer',
    'Temperature',
    'VariableOutputField',
    'VariableInputField',
    'VariableTextOutputField',
    'VariableTextInputField',
    'LightRGB',
    'LightPushButton',
    'SocketSwitch',
    'ControlBlinds',
    'ControlAwning',
    'ControlShutter',
    'ThermostatDigital',
    'ThermostatAnalog',
    'AirHumiditySensor',
    'BrightnessSensor',
    'SensorCO2',
    'SensorLevel',
    'WeatherStation',
    'HotWaterMeter',
    'ColdWaterMeter',
    'HeatingMeter',
    'ElectricityMeter',
    'MotionSensor',
    'Battery',
    'PVSystem',
    'HouseConsumption',
    'Generator',
    'MainsConnection',
    'ElectricChargingStation',
    'ElectronicBoiler',
    'VentilatorSwitch',
    'MusicSystem',
    'TV',
    'MotorWithoutVFD',
    'PumpWithoutVFD',
    'MotorWithVFD',
    'PumpWithVFD',
    'MixingValve',
    'Ventilation',
    'Gauge',
    'DropDownOutputFieldWithVariable',
    'DropDownInputFieldWithVariable',
    'EMSV2',
    'EnergyViewV2',
    'ElectricChargingStationV2',
    'EnergyIO',
    // 'RegentLighting',
  ],
  MPC_Devices_List: [
    // 'HeatingCircuitOptimization',
    'PVProductionService',
    'ConsumptionService',
    'HppProductionService',
    'EMS',
    'PVMonitoringService',
    'SetpointOptimizer',
    'MpcEnergyView',
  ],
  SHOW_PROJECT_TYPE_SELECTION: false,
  PROJECT_MENU_ENTRIES: [
    'Favorites',
    'Areas',
    'Devices Library',
    'Variables',
    'AI/ML Library',
    'Workbench',
    'Settings',
    'Rules',
    'Event List',
    'Report',
    'InstallationWizard',
    'TenantWizard',
  ],
  PROJECT_SETTINGS_ENTRIES: [
    'General',
    'EnergyPrice',
    'Notification',
    'MQTT',
    'Permissions',
    'CheckProtocol',
  ],
  WORKBENCH_CATEGORIES: [
    'Devices',
    'Charts',
    'Anomaly Detection',
    'MPC',
  ],
  WORKBENCH_BUTTONS: [
    'ManageCharts',
    'ManageDevice',
    'ManageAnomalyDetection',
    'ManageMLModel',
  ],
  DEVICE_LIBRARY_CATEGORIES: {
    'Building Automation': [
      'Energy',
      'Gauges',
      'Multimedia',
      'Light',
      'Sensors',
      'Meters',
      'Motion',
      'Shading',
      'Variable I/O Fields',
      'Sockets',
      'Heating/Cooling',
      'Ventilation',
      'Switch',
    ],
    'Charts': [
      'Line',
      'Column',
    ],
  },
  AIML_LIBRARY_CATEGORIES: [
    'Anomaly Detection',
    'MPC',
  ],
  MPC_Library_Tabs: [
    'Energy',
    'Heating',
  ],
  MAIN_MENU_ENTRIES: [
    'Home',
    'Billing',
    'Documentation',
    'Changelog',
    'PartnerManagement',
    'PartnerWorkspace',
    'SerialNumberManagement',
    // 'Ticket',
  ],
  PARTNER_PROJECT_MENU_ENTRIES: [
    'PartnerProjectView',
  ],
  SETTINGS_MAIL_SUPPORT: 'support@lynus.io',
  SETTINGS_DOWNLOAD_LIST: [

  ],
  SETTINGS_SHOW_WEATHER_SERVICE: true,
  ENABLE_CONTROLLER_SETTINGS: true,
  DOCUMENTATION_TABS: [
    'UserManual',
    'APIDocs',
    'DeviceConnectivity',
  ],
  SHOW_PROJECT_INFO: true,
  SHOW_CREATE_DEVICE_BUTTON_IN_AREA: false,
  USER_ROLES_ACCESS_BLOCK_LIST: {
    'ReadOnly': {
      'CreateModals': [
        'CreateProject',
        'CreateArea',
        'CreateDevice',
        'CreateAiml',
        'CreateCharts',
        'CreateMpc',
        'CreateRule',
        'CreateReport',
      ],
      // Create is not needed because it is allready given up inside the CreateModals array
      'DeviceActions': [
        'edit',
        'delete',
        'duplicate',
      ],
      'AreaActions': [
        'edit',
        'delete',
      ],
      'ProjectMenuEntries': [
        // 'Favorites',
        'Devices Library',
        'Variables',
        'AI/ML Library',
        'Workbench',
        'Rules',
        'Event List',
        'Report',
        'Settings',
        'InstallationWizard',
      ],
      'ProjectSettingsEntries': [
        'General',
        'Downloads',
        'Notification',
        'MQTT',
        'Permissions',
        'CheckProtocol',
      ],
      'ProjectActions': [
        'DragAndDrop',
      ],
      'Devices': [
        'BasicControls',
      ],
    },
    'User': {
      'CreateModals': [],
      'DeviceActions': [
        'edit',
        'delete',
        'duplicate',
      ],
      'AreaActions': [
        'edit',
        'delete',
        'duplicate',
      ],
      'ProjectMenuEntries': [
        'Devices Library',
        'AI/ML Library',
        'InstallationWizard',
        'Rules',
        'Workbench',
      ],
      'ProjectSettingsEntries': [
        'General',
        'Downloads',
        'Notification',
        'MQTT',
      ],
      'ProjectActions': [],
      'Devices': [],
    },
    'Admin': {
      'CreateModals': [],
      'DeviceActions': [],
      'AreaActions': [],
      'ProjectMenuEntries': [],
      'ProjectSettingsEntries': [],
      'ProjectActions': [],
      'Devices': [],
    },
  },
};

/**
 * The alternative whitelabel config that should be merged with the default
 * lynus config (this is usually only done for testing). It defaults to {},
 * and can be set e.g. to
 * `const alternativeConfig = whitelabelConfig['ronovatec.console.lynus.io'];`
 */
const alternativeConfig = {};

/**
 * This is the exported config that is used in development mode and as a fallback
 * for the case that there is no whitelabel config defined in production.
 * The lynus default config is merged with the alternative one.
 */
export const config: any = { ...lynusDefaultConfig, ...alternativeConfig };
