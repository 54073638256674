
import {Component, Prop, Vue} from 'vue-property-decorator';
import DeviceActions from '@/ui/components/devices/actions/DeviceActions.vue';
import ShowEventBase from '@/ui/components/devices/devices/base/ShowEventBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import {State} from 'vuex-class';
import {IVariablesState} from '@/store/modules/measurements/types';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent RegentLighting device
 */
@Component({
  components: {
    DeviceActions,
    ShowEventBase,
    OutputFieldBase,
  },
})
export default class RegentLighting extends Vue {
  @Prop() deviceData!: IDevice;

  @State('measurements') measurementsState!: IVariablesState;

  get measurements() {
    return this.measurementsState.measurements;
  }

  open() {
    window.open("https://regent-poc.lynus.dev", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes");
  }

  get stockwerk() {
    return [
      {name: 'Temperatur', value: '25.5 °C'},
      {name: 'Luftfeuchtigkeit', value: '52 %rH'},
      {name: 'CO2-eq', value: '1431 ppm'},
      {name: 'TVOC', value: '998 ppb'},
      {name: 'Auslastung', value: '77 %'},
      {name: 'Warm Desks', value: '23 %'},
      {name: 'Lighting', value: '250 Lux'},
    ];
  }

  get beacons() {
    return [
      {name: 'Temperatur', value: `${this.temperature} °C`},
      {name: 'Luftfeuchtigkeit', value: `${this.humidity} % rH`},
      {name: 'Helligkeit', value: `${this.brightness} Lux`},
      {name: 'CO2-eq', value: `${this.co2eq} ppm`},
      {name: 'TVOC', value: `${this.tvoc} ppb`},
    ];
  }

  get platz() {
    return [
      {name: 'Anwesenheit', value: `${this.deskinuse ? 'Ja' : 'Nein'}`},
      {name: 'Bewegung', value: `${this.presence ? 'Ja' : 'Nein'}`},
      {name: 'Warm Desk', value: 'Nein'},
    ];
  }

  get brightness() {
    if (this.deviceData.data.mappings.clc_brightness) {
      return this.measurements.get(this.deviceData.data.mappings.clc_brightness);
    }
  }

  get co2eq() {
    if (this.deviceData.data.mappings.clc_co2eq) {
      return this.measurements.get(this.deviceData.data.mappings.clc_co2eq);
    }
  }

  get tvoc() {
    if (this.deviceData.data.mappings.clc_tvoc) {
      return this.measurements.get(this.deviceData.data.mappings.clc_tvoc);
    }
  }

  get humidity() {
    if (this.deviceData.data.mappings.clc_humidity) {
      return this.measurements.get(this.deviceData.data.mappings.clc_humidity);
    }
  }

  get temperature() {
    if (this.deviceData.data.mappings.clc_temperature) {
      return this.measurements.get(this.deviceData.data.mappings.clc_temperature);
    }
  }

  get deskinuse() {
    if (this.deviceData.data.mappings.clc_deskinuse) {
      return this.measurements.get(this.deviceData.data.mappings.clc_deskinuse);
    }
  }

  get presence() {
    if (this.deviceData.data.mappings.clc_presence) {
      return this.measurements.get(this.deviceData.data.mappings.clc_presence);
    }
  }

  // mounted() {
  //   console.log(this.deviceData);
  // }
}
