import { IDevice } from '@/types/devices.types';
import { IProject } from '@/types/project.types';

/**
 * Filter instances from data object
 * @param object Object to filter by keys
 * @param instanceList instance list to search in Object for
 * @param stringExtension extension for instance in object keys
 */
export function filterInstances(object: any, system_type: string, instancesList: any, stringExtension: string) {
  const result: any = [];
  instancesList.forEach((system: any) => {
    const obj: any = {};
    obj[system + stringExtension] = object[system_type][system + stringExtension];
    result.push([system_type, obj]);
  });
  return result;
}

/**
 * get Predictions for instances
 * @param data object to filter data from
 * @param suffix filter by suffix
 */
export function getPredictions(data: any, suffix: string) {
  const result: any = [];
  if (!data.length) return result;
  Object.entries(data[0][1]).forEach(([key, list]: any) => {
    if (key.includes(suffix)) {
      const sum = list
        .map((el: any) => [el[0] * 1000, el[1]]);
      result.push(sum);
    }
  });
  return result[0];
}
/**
 * Get system instances from data object
 * @param data object to filter data from
 * @param suffix filter by suffix
 */
export function getSystems(data: any, suffix: string) {
  const systems: any = {};
  Object.entries(data).forEach(([key, value]: any) => {
    Object.entries(value).forEach((entry: any) => {
      if (entry[0].includes(suffix)) systems[entry[0]] = entry[1];
    });
  });
  return systems;
}

/**
 * Usable for EMS and EnergyView
 * Checks if a system is a combination of the Lynus and Solarman Logger System
 */
export function isHybridEnergyDeviceSystem(deviceData: IDevice, project: IProject) {
  let isHybrid = false;

  // if loggerInformation is present and has at least one element, then can be a hybrid system based on the information of the project
  if (project?.meta?.loggerInformation) {
    isHybrid = project.meta.loggerInformation.length > 0;
  } else {
    // if loggerInformation is not present, then can not be a hybrid system
    return false;
  }

  const pvMappings = deviceData.data.meta.controllerMappings.pv.components;
  // check if there is a pv mapping present
  if (Object.values(pvMappings).length > 0) {
    // check if there is a mapping for solarman and a mapping of the lynus system present
    const hasLoggerMapping = Object.values(pvMappings).some((mapping: any) => mapping.power.includes('solarman'));
    const hasLynusMapping = Object.values(pvMappings).some((mapping: any) => !mapping.power.includes('solarman'));
    // if both mappings are present, then it is a hybrid system
    isHybrid = hasLoggerMapping && hasLynusMapping;
  }
  return isHybrid;
}
