
import { Component, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import MonitoringGauge from '@/ui/components/devices/mpc/PVMonitoringService/MonitoringGauge.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { IVariablesState } from '@/store/modules/measurements/types';

/**
 * Component that represent PVMonitoringService MPC device
 */
@Component({
  components: {
    MonitoringGauge,
    ShowEventDotBase,
    InputFieldBase,
    BaseChartWrapper,
    MPCLayout,
  },
})
export default class PVMonitoringService extends mixins(Validation) {
  @Prop() deviceData!: any;
  @Prop() dndGridLayoutContainerWidth!: number;
  @State('measurements') measurementsState!: IVariablesState;
  @Action('mpc/fetchMPCData') fetchMPCData!: (mpcId: string) => any;

  mpc: any = null;
  reRenderKey = 0;

  get measurements() {
    return this.measurementsState.measurements;
  }

  get ready() {
    return this.deviceData?.data?.meta?.controllerMappings?.mpcReady;
  }
  get power() {
    return this.deviceData?.data?.meta?.controllerMappings?.power;
  }
  get deviceIdUnderline() {
    const deviceId = this.deviceData.id;
    return deviceId.replace(/-/g, '_');
  }
  get gaugeVariable() {
    return `PV.${this.deviceIdUnderline}.efficiency`;
  }
  get settingsVariable() {
    return `PV.${this.deviceIdUnderline}.decay`;
  }
  get inputFieldEfficiency() {
    return {
      'InputField_targetValue': this.settingsVariable,
    };
  }

  get efficiencyActualValue() {
    return this.measurements.get(this.gaugeVariable);
  }
  get powerActualValue() {
    const val: any = this.measurements.get(this.power);
    return typeof val === 'number' ? val.toFixed(2) : null;
  }

  /**
   * Prepares chart options object suitable for the chart component
   */
  get chartData() {
    return {
      chartTitle: '',
      chartWidth: null,
      chartHeight: 550,
      chartWrapperHeight: 700,
      chartData: {
        name: '',
        data: {
          chartOptions: [
            {
              agg: 'avg',
              name: 'Efficiency',
              scaling: {
                min: 0,
                max: 100,
              },
              seriesType: 'View',
              type: 'line',
              unit: '%',
              var: this.gaugeVariable,
            },
          ],
          selectedStackingOptions: 'normal',
          selectedWidth: 'full',
          type: 'chart',
        },
      },
      navigationItemsToExclude: ['live', 'hour', 'day'],
    };
  }

  get readyMqtt() {
    return {
      ShowEventDot1_errorWarningState: this.ready,
    };
  }

  updated() {
    this.$nextTick(() => {
      this.reRenderKey++;
    });
  }

  async mounted() {
    this.mpc = await this.fetchMPCData(this.deviceData.id);
  }
}
