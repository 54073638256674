
import { Vue, Component, Prop } from 'vue-property-decorator';
import DeviceDescription from '@/ui/components/devices/actions/DeviceDescription.vue';
import OfflineChart from '@/ui/components/devices/previews/charts/components/OfflineChart.vue';
import { ChartData } from '@/types/chart.types';

@Component({
  components: {
    DeviceDescription,
    OfflineChart,
  },
})
export default class ColumnChartPreview extends Vue {
  @Prop() chartWidth!: any;
  @Prop() multipleLines!: boolean;
  @Prop() columnViewType!: string | undefined;
  @Prop({ default: 'type1' }) chartDescriptionType!: string;
  @Prop({
    default: 'Column chart',
  }) chartTitle!: string;
  @Prop() chartData!: ChartData;

  descriptionTypes: any = {
    type1: 'chartsDescription.ColumnChart',
    type2: 'chartsDescription.ColumnChart2',
  }

  get description() {
    return this.$t(this.descriptionTypes[this.chartDescriptionType]);
  }
}
