import { IOEnergyCircleType } from '@/types/energyVisualisation/EnergyCircleType';

/**
 * Defines system variable value
 * @param target {object}
 * @param system {string} system name. Example: battery
 * @param instance {string} system instance name. Example: battery_1
 * @param variable {string} one of the variables of system
 */
export const getVariableValue = (target: any, system: string, instance: string, variable: string) => {
  return target[system].components[instance][variable];
};

/**
 * Division of group systems into subgroups depending on the value of the separator.
 * @param {array} system list of systems example: ['pv', { components: { pv1: {}, pv2: {} }, count: 2 }]
 * @param {number} splitter Separator determining the number of elements in a subgroup
 * @param {null|string} filterBy
 * @param {null|object} target
 */
export const defineInstancesGroups = (
  system: [string, { components: any; count: number }],
  splitter: number,
  filterBy: null | string = null,
  target: any | null = null,
) => {
  // sorts map of components by the numeric part of the key. example for component key: 'battery1', 'battery2'
  const sortedSystemsArr = Object.entries(system[1].components)
    .sort(([a], [b]) => {
      const aNum = Number(a.slice(system[0].length));
      const bNum = Number(b.slice(system[0].length));
      return aNum - bNum;
    });

  // creates new object with right order for systems
  system[1].components = Object.fromEntries(sortedSystemsArr);

  const instancesArr: any = Object.keys(system[1].components).filter((instance: string) => {
    if (filterBy && target) return getVariableValue(target, system[0], instance, filterBy as string);
    else return true;
  });

  const count: any = Math.ceil(instancesArr.length / splitter);
  let instancesArrGrouped: any = [];
  const initChartList = (n: number, arr: any) => {
    if (n > 0 && arr.length) {
      initChartList(n - 1, arr);
      instancesArrGrouped = [...instancesArrGrouped, arr.slice(n * splitter - splitter, n * splitter)];
    } else {
      return null;
    }
  };
  initChartList(count, instancesArr);

  return [system[0], instancesArrGrouped];
};

export function defineIoSystemType(type: string) {
  if (type.includes('hybrid')) return IOEnergyCircleType.hybrids;
  if (type.includes('output')) return IOEnergyCircleType.outputs;
  else return IOEnergyCircleType.inputs;
}

/**
 * Defines system name
 * @param target {object}
 * @param system {string} system name. Example: battery
 * @param instance {string} system instance name. Example: battery_1
 * @param variable {string} one of the variables of system
 */
export const getChartSystemInstanceName = (target: any, system: string, instance: string, variable: string) => {
  const { title } = target[system].components[instance];
  const variableName = target[system].components[instance][variable];
  return title || variableName;
};

/**
 * Defines system name
 * @param target {object}
 * @param system {string} system name. Example: battery
 * @param instance {string} system instance name. Example: battery_1
 * @param variable {string} one of the variables of system
 * @param type
 */
export const getChartSystemIOInstanceName = (target: any, system: string, instance: string, variable: string, type: IOEnergyCircleType) => {
  const { title } = target[type].components[system];
  const variableName = target[type].components[system].energy_counter[instance];
  return title || variableName;
};

/**
 * Generates a list of systems with forecast data
 * @param keys list of created systems instances names
 * @param data forecast data for systems
 * @param variable with which the access key to the forecast data is created
 */
export const generateForecastSystems = (keys: string[], data: { [key: string]: [number, number][] }, variable = 'power') => {
  return keys.reduce((acc: { [key: string]: [number, number][] }, el: string) => {
    const system = `${el}_${variable}`;
    return { ...acc, ...{ [system]: data[system] || [] } };
  }, {});
};
