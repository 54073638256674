// Contains System Types List for Selectfields
// availableFrom contains UNIX Timestamp in milliseconds. If Project was created after this timestamp the user can select this product

import {
  mennekesEnerchargeDate,
  newChargeStationLimitDate,
  versionLimitedChargeStations
} from '@/utils/versionManagementUtils';
import { ChargeStationType } from '@/types/project.types';

export enum deyeTypes {
  BatteryTower = 'Battery Tower',
  BatteryRackSystem = 'Battery Rack System',
  WecoBatteryStack = 'Weco Battery Stack',
  WecoSlimStack = 'Weco Slim Stack',
}
export enum BatteryType {
  AZZURRO = 'AIOS (Azzurro)',
  DEYE = 'AIOS (Deye)',
  STANDALONE = 'Standalone (Deye)',
  SOLARMAX = 'Solarmax',
  MobileBatteryDeye = 'Mobiler Speicher Deye'
}
export const deyeBatteryTypes = [BatteryType.DEYE, BatteryType.STANDALONE];
export const inverterPowers = {
  [BatteryType.AZZURRO]: [
    { name: '10 kW', value: '10 kW', availableFrom: new Date('1970-01-01') },
    { name: '20 kW', value: '20 kW', availableFrom: new Date('1970-01-01') },
  ],
  [BatteryType.DEYE]: [
    { name: '6 kW', value: '6 kW', availableFrom: new Date('1970-01-01') },
    { name: '8 kW', value: '8 kW', availableFrom: new Date('1970-01-01') },
    { name: '10 kW', value: '10 kW', availableFrom: new Date('1970-01-01') },
    { name: '12 kW', value: '12 kW', availableFrom: new Date('1970-01-01') },
    { name: '15 kW', value: '15 kW', availableFrom: new Date('1970-01-01') },
    { name: '20 kW', value: '20 kW', availableFrom: new Date('1970-01-01') },
    { name: '25 kW', value: '25 kW', availableFrom: new Date('1970-01-01') },
    { name: '30 kW', value: '30 kW', availableFrom: new Date('1970-01-01') },
    { name: '40 kw', value: '40 kw', availableFrom: new Date('1970-01-01') },
    { name: '50 kW', value: '50 kW', availableFrom: new Date('1970-01-01') },
  ],
  [BatteryType.STANDALONE]: [
    { name: '8 kW', value: '8 kW', availableFrom: new Date('1970-01-01') },
    { name: '12 kW', value: '12 kW', availableFrom: new Date('1970-01-01') },
    { name: '20 kW', value: '20 kW', availableFrom: new Date('1970-01-01') },
  ],
  [BatteryType.SOLARMAX]: [],
  [BatteryType.MobileBatteryDeye]: [],
};

export const batteryCapacity = {
  [BatteryType.AZZURRO]: [
    { name: '20.4 kWh', value: '20.4 kWh', availableFrom: new Date('1970-01-01') },
    { name: '25.5 kWh', value: '25.5 kWh', availableFrom: new Date('1970-01-01') },
    { name: '30.6 kWh', value: '30.6 kWh', availableFrom: new Date('1970-01-01') },
    { name: '35.7 kWh', value: '35.7 kWh', availableFrom: new Date('1970-01-01') },
    { name: '40.8 kWh', value: '40.8 kWh', availableFrom: new Date('1970-01-01') },
  ],
  [BatteryType.DEYE]: {
    [deyeTypes.BatteryTower]: [
      { name: '8.2 kWh', value: '8.2 kWh', availableFrom: new Date('1970-01-01') },
      { name: '12.3 kWh', value: '12.3Wh', availableFrom: new Date('1970-01-01') },
      { name: '16.4 kWh', value: '16.4 kWh', availableFrom: new Date('1970-01-01') },
      { name: '20.5 kWh', value: '20.5 kWh', availableFrom: new Date('1970-01-01') },
      { name: '24.6 kWh', value: '24.6 kWh', availableFrom: new Date('1970-01-01') },
    ],
    [deyeTypes.BatteryRackSystem]: [
      { name: '20,5 kWh', value: '20,5 kWh', availableFrom: new Date('1970-01-01') },
      { name: '25.6 kWh', value: '25.6 kWh', availableFrom: new Date('1970-01-01') },
      { name: '30.7 kWh', value: '30.7 kWh', availableFrom: new Date('1970-01-01') },
      { name: '35.8 kWh', value: '35.8 kWh', availableFrom: new Date('1970-01-01') },
      { name: '41 kWh', value: '41 kWh', availableFrom: new Date('1970-01-01') },
      { name: '46.1 kWh', value: '46.1 kWh', availableFrom: new Date('1970-01-01') },
      { name: '51.2 kWh', value: '51.2 kWh', availableFrom: new Date('1970-01-01') },
      { name: '56.2 kWh', value: '56.2 kWh', availableFrom: new Date('1970-01-01') },
    ],
    [deyeTypes.WecoBatteryStack]: [
      { name: '21.2 kWh', value: '21.2 kWh', availableFrom: new Date('1970-01-01') },
      { name: '26.5 kWh', value: '26.5 kWh', availableFrom: new Date('1970-01-01') },
      { name: '31.8 kWh', value: '31.8 kWh', availableFrom: new Date('1970-01-01') },
      { name: '42.4 kWh', value: '42.4 kWh', availableFrom: new Date('1970-01-01') },
      { name: '47.7 kWh', value: '47.7 kWh', availableFrom: new Date('1970-01-01') },
      { name: '53 kWh', value: '53 kWh', availableFrom: new Date('1970-01-01') },
      { name: '58.3 kWh', value: '58.3 kWh', availableFrom: new Date('1970-01-01') },
      { name: '68.9 kWh', value: '68.9 kWh', availableFrom: new Date('1970-01-01') },
      { name: '74.2 kWh', value: '74.2 kWh', availableFrom: new Date('1970-01-01') },
    ],
    [deyeTypes.WecoSlimStack]: [
      { name: '5.1 kWh', value: '5.1 kWh', availableFrom: new Date('1970-01-01') }, // 1701817200
      { name: '10.2 kWh', value: '10.2 kWh', availableFrom: new Date('1970-01-01') },
      { name: '15.3 kWh', value: '15.3 kWh', availableFrom: new Date('1970-01-01') },
      { name: '20.4 kWh', value: '20.4 kWh', availableFrom: new Date('1970-01-01') },
      { name: '25.5 kWh', value: '25.5 kWh', availableFrom: new Date('1970-01-01') },
      { name: '30.6 kWh', value: '30.6 kWh', availableFrom: new Date('1970-01-01') },
    ],
  },
  [BatteryType.STANDALONE]: [
    { name: '8.2 kWh', value: '8.2 kWh', availableFrom: new Date('1970-01-01') },
    { name: '12.3 kWh', value: '12.3 kWh', availableFrom: new Date('1970-01-01') },
    { name: '16.4 kWh', value: '16.4 kWh', availableFrom: new Date('1970-01-01') },
    { name: '20.5 kWh', value: '20.5 kWh', availableFrom: new Date('1970-01-01') },
    { name: '24.6 kWh', value: '24.6 kWh', availableFrom: new Date('1970-01-01') },
  ],
  [BatteryType.SOLARMAX]: [],
  [BatteryType.MobileBatteryDeye]: [],
};

export const chargeStationTypes = [
  { name: 'Webasto 11 kW', value: 'Webasto11', availableFrom: new Date('1970-01-01'), disabled: false },
  { name: 'Webasto 22 kW', value: 'Webasto22', availableFrom: new Date('1970-01-01'), disabled: false },
  { name: 'Weidmüller AC Smart Value 11 kW', value: ChargeStationType.internalWeidmüller11, availableFrom: versionLimitedChargeStations.weidmueller11kWInternal, disabled: false },
  { name: 'Weidmüller AC Smart Value 22 kW', value: ChargeStationType.internalWeidmüller22, availableFrom: versionLimitedChargeStations.weidmueller22kWInternal, disabled: false },
  { name: 'Weidmüller AC Smart Eco 11 kW (externe Messung)', value: ChargeStationType.externalWeidmüller11, availableFrom: new Date('1970-01-01'), disabled: false },
  { name: 'Weidmüller AC Smart Eco 22 kW (externe Messung)', value: ChargeStationType.externalWeidmüller22, availableFrom: new Date('1970-01-01'), disabled: false },
  { name: 'Weidmüller AC Smart Advanced 11 kW (für Mieterstrom)', value: ChargeStationType.internalWeidmüllerMS11, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'Weidmüller AC Smart Advanced 22 kW (für Mieterstrom)', value: ChargeStationType.internalWeidmüllerMS22, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'Schneider EVlink Pro AC 11 kW', value: ChargeStationType.Schneider11, availableFrom: new Date('1970-01-01'), disabled: false },
  { name: 'Schneider EVlink Pro AC 22 kW', value: ChargeStationType.Schneider22, availableFrom: new Date('1970-01-01'), disabled: false },
  { name: 'AMTRON Charge Control 11 kW', value: ChargeStationType.AmtronCC11, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'AMTRON Professional 11 kW', value: ChargeStationType.AmtronPro11, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'AMTRON Professional 22 kW', value: ChargeStationType.AmtronPro22, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'AMTRON TwinCharge 22 kW', value: ChargeStationType.AmtronTC22, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'AMEDIO Professional 22 kW', value: ChargeStationType.AmedioPro22, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'Enercharge 50 kW', value: ChargeStationType.Enercharge50, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'Enercharge 100 kW', value: ChargeStationType.Enercharge100, availableFrom: mennekesEnerchargeDate, disabled: false },
];

export const electricHeatingTypes = [
  { name: 'Askoma 3.5 kW', value: 'Askoma 3.5 kW', availableFrom: new Date('1970-01-01'), rawValue: 3.5 },
  { name: 'Askoma 5.8 kW', value: 'Askoma 5.8 kW', availableFrom: new Date('1970-01-01'), rawValue: 5.8 },
];

export const heatingPumpTypes = [
  { name: 'Lambda Eureka', value: 'Lambda Eureka', availableFrom: newChargeStationLimitDate, rawValue: 0 },
  { name: 'Soltop M-Tech', value: 'Soltop M-Tech', availableFrom: newChargeStationLimitDate, rawValue: 0 },
];

export const webastoChargeStationTypes = [
  { name: 'Next', value: 0 },
  { name: 'Unite', value: 1 },
];

export const heatingElementModelTypes = [
  { name: 'Askoma Askoheat', value: 0 },
];

export function getRawValueBySystemTypeElectricHeating(system: string) {
  return electricHeatingTypes.find((item: any) => item.name === system)?.rawValue ?? system;
}

export const availableExternalMeasurementTypes = ['big_consumer', 'electric_heating', 'charge_station', 'heating_pump'];
export const availableExternalMeasurementDeyeTypes = ['pv', 'generator', 'chp'];

export function getChargeStationNameByValue(value: string) {
  const foundItem = chargeStationTypes.find(item => item.value === value);
  return foundItem ? foundItem.name : null;
}

export function getChargeStationPower(key: string) {
  const chargeStationPower: Record<string, number> = {
    'Webasto11': 11,
    'Webasto22': 22,
    [ChargeStationType.WebastoNext11]: 11,
    [ChargeStationType.WebastoNext22]: 22,
    [ChargeStationType.WebastoUnite11]: 11,
    [ChargeStationType.WebastoUnite22]: 22,
    [ChargeStationType.internalWeidmüller11]: 11,
    [ChargeStationType.internalWeidmüller22]: 22,
    [ChargeStationType.externalWeidmüller11]: 11,
    [ChargeStationType.externalWeidmüller22]: 22,
    [ChargeStationType.internalWeidmüllerMS11]: 11,
    [ChargeStationType.internalWeidmüllerMS22]: 22,
    [ChargeStationType.Schneider11]: 11,
    [ChargeStationType.Schneider22]: 22,
    [ChargeStationType.AmtronCC11]: 11,
    [ChargeStationType.AmtronPro11]: 11,
    [ChargeStationType.AmtronPro22]: 22,
    [ChargeStationType.AmtronTC22]: 22,
    [ChargeStationType.AmedioPro22]: 22,
    [ChargeStationType.Enercharge50]: 50,
    [ChargeStationType.Enercharge100]: 100,
  };

  return chargeStationPower[key];
}

export function getChargeStationTypeInformationBySystemType(name: string) {
  const chargeStationPower: Record<string, { type: string; isExternal: boolean}> = {
    'Webasto11': { type: 'Webasto', isExternal: false },
    'Webasto22': { type: 'Webasto', isExternal: false },
    [ChargeStationType.WebastoNext11]: { type: 'Webasto', isExternal: false },
    [ChargeStationType.WebastoNext22]: { type: 'Webasto', isExternal: false },
    [ChargeStationType.WebastoUnite11]: { type: 'Webasto', isExternal: false },
    [ChargeStationType.WebastoUnite22]: { type: 'Webasto', isExternal: false },
    [ChargeStationType.internalWeidmüller11]: { type: 'Weidmüller', isExternal: false },
    [ChargeStationType.internalWeidmüller22]: { type: 'Weidmüller', isExternal: false },
    [ChargeStationType.externalWeidmüller11]: { type: 'Weidmüller', isExternal: true },
    [ChargeStationType.externalWeidmüller22]: { type: 'Weidmüller', isExternal: true },
    [ChargeStationType.internalWeidmüllerMS11]: { type: 'Weidmüller', isExternal: false },
    [ChargeStationType.internalWeidmüllerMS22]: { type: 'Weidmüller', isExternal: false },
    [ChargeStationType.Schneider11]: { type: 'Schneider', isExternal: false },
    [ChargeStationType.Schneider22]: { type: 'Schneider', isExternal: false },
    [ChargeStationType.AmtronCC11]: { type: 'Amtron', isExternal: false },
    [ChargeStationType.AmtronPro11]: { type: 'Amtron', isExternal: false },
    [ChargeStationType.AmtronPro22]: { type: 'Amtron', isExternal: false },
    [ChargeStationType.AmtronTC22]: { type: 'AmtronTC', isExternal: false },
    [ChargeStationType.AmedioPro22]: { type: 'Amedio', isExternal: false },
    [ChargeStationType.Enercharge50]: { type: 'Enercharge', isExternal: false },
    [ChargeStationType.Enercharge100]: { type: 'Enercharge', isExternal: false },
  };

  return chargeStationPower[name.split('_')[0]];
}
