import { cloneDeep, merge } from 'lodash';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import {
  emsLimitsByType,
} from '@/ui/components/wizards/loggerWizard/wizardSettings/wizardLimits';
import { IProject } from '@/types/project.types';
import { IDevice } from '@/types/devices.types';
import { allEnergyCircleTypes } from '@/types/energyVisualisation/EnergyCircleType';
import {
  NavigationDirection,
} from '@/types/wizards/installationWizard.types';
import { IIncludedSystemsTypesLogger } from '@/types/wizards/loggerWizard.types';
import { IWizardPage } from '@/types/wizards/wizard.general.types';
import {
  IloggerWizardState,
} from './types';

const defaultWizardState = () => {
  return {
    energyViewDevice: {},
    includedSystemsTypes: {
      battery: {
        isSelected: false,
        count: 0,
        definition: [],
        systems: [],
      },
      pv: {
        isSelected: false,
        count: 0,
        definition: [],
      },
      generator: {
        isSelected: false,
        count: 0,
        definition: [],
      },
      acPV: {
        isSelected: false,
        count: 0,
        definition: [],
      },
    },
    currentStep: 1,
    currentPage: 0,
    pages: [
      {
        title: 'LoggerInformation',
        view: 'LoggerInformation',
        step: 1,
        page: 0,
        dependencies: [],
      },
      {
        title: 'LoggerManualsPage',
        view: 'LoggerManualsPage',
        step: 1,
        page: 1,
        dependencies: [],
      },
      {
        title: 'DefineLoggerIds',
        view: 'DefineLoggerIds',
        step: 1,
        page: 2,
        dependencies: [],
      },
      {
        title: 'LoggerComponentsPage',
        view: 'LoggerComponentsPage',
        step: 1,
        page: 3,
        dependencies: [],
      },
      {
        title: 'GeneralInformationsLogger',
        view: 'GeneralInformationsLogger',
        step: 2,
        page: 0,
        dependencies: [],
      },
      {
        title: 'CommonEnergyPriceSettings',
        view: 'CommonEnergyPriceSettings',
        step: 2,
        page: 1,
        dependencies: [],
      },
      {
        title: 'InviteUsersToProjectLogger',
        view: 'InviteUsersToProjectLogger',
        step: 3,
        page: 0,
        dependencies: [],
      },
      {
        title: 'FinalLoggerPage',
        view: 'FinalLoggerPage',
        step: 4,
        page: 0,
        dependencies: [],
      },
    ],
    ACPV: {
      isSelected: false,
      count: 0,
    },
    currentPageIndex: 0,
    navigationDirection: NavigationDirection.forward,
    wasloggerWizardCompleted: false,
    wasComponentsPageDone: false,
    loggerInformation: [],
    hasGridMeasurement: false,
  } as IloggerWizardState;
};

const state: IloggerWizardState = defaultWizardState();

const setCounts = (mappings: Partial<IDevice>) => {
  const controllerMappings = cloneDeep(mappings.data.meta.controllerMappings);
  Object.keys(state.includedSystemsTypes).forEach((mapping: string) => {
    if (controllerMappings[mapping]) {
      controllerMappings[mapping].count = Object.keys(controllerMappings[mapping].components).length;
    }
  });
  mappings.data.meta.controllerMappings = controllerMappings;
  return mappings;
};

const getters: GetterTree<IloggerWizardState, RootState> = {
  energyViewDevice(state: IloggerWizardState): IDevice {
    return state.energyViewDevice as IDevice;
  },
  includedSystemsTypes(state: IloggerWizardState): IIncludedSystemsTypesLogger {
    return state.includedSystemsTypes;
  },
  currentPage(state: IloggerWizardState): number {
    return state.currentPage;
  },
  currentStep(state: IloggerWizardState): number {
    return state.currentStep;
  },
  wizardPages(state: IloggerWizardState, _, __, rootGetters): IWizardPage[] {
    return state.pages;
  },
  considerProjectStatus(): boolean {
    // this can be used to control whether we show the "project offline" alerts etc.
    return false;
  },
  navigationDirection(state: IloggerWizardState) {
    return state.navigationDirection;
  },
  emsLimits(_, __, ___, rootGetters) {
    return emsLimitsByType(rootGetters['projects/batterySystemType']);
  },
  ACPV(state: IloggerWizardState) {
    return state.ACPV;
  },
  wasLoggerWizardCompleted(state: IloggerWizardState) {
    return state.wasloggerWizardCompleted;
  },
  wasComponentsPageDone(state: IloggerWizardState) {
    return state.wasComponentsPageDone;
  },
  loggerInformation(state: IloggerWizardState) {
    return state.loggerInformation;
  },
  hasGridMeasurement(state: IloggerWizardState) {
    return state.hasGridMeasurement;
  },
};

const mutations: MutationTree<IloggerWizardState> = {
  updateEnergyViewDeviceData(state, device: Partial<IDevice>) {
    state.energyViewDevice.data.meta.controllerMappings = device.data.meta.controllerMappings;
    state.energyViewDevice = setCounts(state.energyViewDevice);
  },
  updateEnergyViewDevice(state, device: IDevice) {
    state.energyViewDevice = device;
  },
  setIncludedSystemsTypes(state: IloggerWizardState, includedSystemsTypes) {
    state.includedSystemsTypes = includedSystemsTypes;
  },
  setCurrentPage(state: IloggerWizardState, data: number) {
    state.currentPage = data;
  },
  setCurrentStep(state: IloggerWizardState, data: number) {
    state.currentStep = data;
  },
  setWasComponentsPageDone(state: IloggerWizardState) {
    state.wasComponentsPageDone = true;
  },
  setNavigationDirection(state: IloggerWizardState, data: NavigationDirection) {
    state.navigationDirection = data;
  },
  setLoggerInformation(state: IloggerWizardState, data: {id: string; hasPv: boolean; hasBattery: boolean; selectedProducer: ''}[]) {
    state.loggerInformation = data;
  },
  setHasGridMeasurement(state: IloggerWizardState, data: boolean) {
    state.hasGridMeasurement = data;
  },
  reset(state: IloggerWizardState) {
    state = merge(state, defaultWizardState());
  },
};

const actions: ActionTree<IloggerWizardState, RootState> = {
  handleIncrement({ state, getters }) {
    if (state.currentPageIndex + 1 < getters.wizardPages.length) {
      state.currentPageIndex++;
      state.currentPage = getters.wizardPages[state.currentPageIndex].page;
      state.currentStep = getters.wizardPages[state.currentPageIndex].step;
    }
    this.commit('loggerWizard/setNavigationDirection', NavigationDirection.forward);
  },
  handleDecrement({ state, getters }) {
    if (state.currentPageIndex !== 0) {
      state.currentPageIndex--;
      state.currentPage = getters.wizardPages[state.currentPageIndex].page;
      state.currentStep = getters.wizardPages[state.currentPageIndex].step;
    }
    this.commit('loggerWizard/setNavigationDirection', NavigationDirection.backward);
  },
  /**
   * This function will remove all the components from the energy view device that cannot be used inside the Logger wizard.
   * Currently only (PV, Battery, Generator, AC-PV, House) are allowed.
   */
  initDevices({ commit, rootGetters }) {
    // Define Wizard Settings
    state.wasloggerWizardCompleted = rootGetters['projects/project'].meta?.wasloggerWizardCompleted ?? false;
    const findByType = (type: string) => rootGetters['devices/allDevices'].find((element: IDevice) => {
      return element.data.type === type;
    });
    const project: IProject = rootGetters['projects/project'];

    const resetToZero = ['heating_pump', 'big_consumer', 'chp', 'electric_heating', 'charge_station'];
    const energyView = cloneDeep(findByType('EnergyViewV2'));
    allEnergyCircleTypes.forEach((systemType: string) => {
      if (resetToZero.includes(systemType)) {
        energyView.data.meta.controllerMappings[systemType].count = 0;
        energyView.data.meta.controllerMappings[systemType].components = {};
      }
    });

    commit('updateEnergyViewDevice', energyView);
  },
};

export const loggerWizard: Module<IloggerWizardState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
