import VueI18n from 'vue-i18n';
import Vue from 'vue';
import lang from '../../lang';

Vue.use(VueI18n);

const dateTimeFormats: VueI18n.DateTimeFormats = {
  'en': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  'de': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
  'it': {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    },
  },
};

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: lang,
  dateTimeFormats,
});
