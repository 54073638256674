
import { Vue, Component } from 'vue-property-decorator';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';
import MonitorGaugeMock
  from '@/ui/components/devices/previews/mpc/PVMonitoringService/MonitorGaugeMock.vue';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import OfflineChart from '@/ui/components/devices/previews/charts/components/OfflineChart.vue';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';

@Component({
  components: {
    ShowEventDotMock,
    MonitorGaugeMock,
    InputFieldMock,
    OfflineChart,
    MPCLayout,
  },
})
export default class PVMonitoringServicePreview extends Vue {
  get description() {
    return this.$t('mlModelsDescriptions.PVMonitoringService');
  }

  get previewNameLang() {
    return this.$t('mlModel.PVMonitoringService.previewName');
  }

  // contains Chart Object
  get mockChartData() {
    let currentChartOptions: any = [];
    currentChartOptions = [
      {
        agg: 'first',
        name: 'Efficiency',
        scaling: {
          max: '100',
          min: '0',
        },
        seriesType: 'View',
        type: 'line',
        unit: '%',
        var: '',
      },
    ];

    const currentData = {
      data: {
        chartOptions: currentChartOptions,
        selectedStackingOptions: 'normal',
        selectedWidth: 'full',
        type: 'chart',
      },
      created_at: '2021-10-07T07:43:33.554983Z',
    };
    return currentData;
  }
}
