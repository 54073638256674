
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';
import { roundNumber } from '@/utils/utilsFunctions';
import { Validation } from '@/ui/mixins/validation';

/**
 * Component that represent InputField Basic Control
 */
@Component({
  components: {
    FeedbackProvider,
  },
})
export default class InputFieldBase extends mixins(Validation, BasicControl) {
  @Prop() variableData!: Record<string, string>;
  @Prop() instance!: string;
  @Prop() isDecimal?: boolean;
  @Prop() disabled?: boolean;
  @Prop({ default: 0.01 }) step?: number;
  @Prop() min?: number;
  @Prop() max?: number;
  @Prop({ default: () => [] }) fieldRules?: any;
  @Prop({ default: '#fff' }) background!: string;
  @Prop({ default: false }) small!: boolean;
  @Prop({ default: () => {} }) descriptionStyles!: any;
  @Prop({ default: () => {} }) inputFieldStyles!: any;
  @Prop({ default: () => {} }) unitStyles!: any;
  @Prop({ default: () => {} }) iconStyles!: any;

  state: any = 24
  timer?: NodeJS.Timeout;
  valid = true

  @Watch('actualValue')
  onActualValueChange(val: any) {
    if (typeof val === 'number' && !Number.isNaN(val)) {
      this.state = Number.isInteger(val) && !this.isDecimal ? val : val.toFixed(2);
    }
  }

  get rulesList() {
    const min = typeof this.min === 'number' ? this.fieldMoreThan(this.min) : true;
    const max = typeof this.max === 'number' ? this.fieldLessThan(this.max) : true;
    return [...this.fieldRules, min, max];
  }
  get allRules() {
    return this.isDisabled ? [] : this.rulesList;
  }

  get isNotMapped(): boolean {
    if (this.mappingsClean) return Object.values(this.mappingsClean).some((el: any) => !el?.length);
    else return false;
  }

  get actualValue(): any {
    const value = this.measurements.get(this.mappingsClean.targetValue) as number;
    return roundNumber(value);
  }

  @Watch('currentLang', { deep: true })
  handleLangChange() {
    this.validate();
  }

  get currentLang() {
    return this.$i18n.locale;
  }

  validate() {
    (this.$refs.form as any).validate();
    this.handleState();
  }

  /**
   * Blocks key which not valid
   * @param e event object
   */
  handleKeypress(e: any) {
    if (this.isDecimal) {
      const isDecimalSymbol = /-|[0-9]|,|./.test(e.key);
      if (!isDecimalSymbol) e.preventDefault();
    } else {
      const isInteger = /-|[0-9]/.test(e.key);
      if (!isInteger) e.preventDefault();
    }
  }

  handleState() {
    if (!this.valid) return;
    this.timer = setTimeout(() => {
      this.onSendStart();
      this.send([{ v: +this.state, n: this.mappingsClean.targetValue, u: '' }]);
      this.onSendSuccess();
    }, 200);
  }

  mounted() {
    (this.$refs.form as any).validate();
    this.state = this.actualValue;
  }

  beforeDestroy() {
    clearTimeout(this.timer);
  }
}
