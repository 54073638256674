
import { Vue, Component } from 'vue-property-decorator';
import { State, Mutation, Getter } from 'vuex-class';
import { IAppState } from '@/store/modules/app/types';
import { IReportBox } from '@/types/app.types';

/**
 * Component that represent notification message
 * Usually see when do some request to API
 */
@Component
export default class ReportMessage extends Vue {
  @State('app') app!: IAppState;
  @Getter('app/getReportMessageAndType') getReportMessageAndType!: IReportBox;
  @Mutation('app/setReport') setReport!: (payload: IReportBox) => void;

  get value() {
    return this.app.reportBox.shouldShow && this.app.reportBox.value;
  }
  set value(val) {
    this.setReport({ message: '', value: val });
  }

  closeReport() {
    this.setReport({ message: '', value: false });
  }
}
