
import { Vue, Component } from 'vue-property-decorator';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import ArrowButtonMock from '@/ui/components/devices/previews/components/ArrowButtonMock.vue';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    SwitchMock,
    ShowEventDotMock,
    InputFieldMock,
    ArrowButtonMock,
    DeviceLayout,
  },
})
export default class ThermostatDigitalPreview extends Vue {
  get mainViewLang() {
    return 'devices.ThermostatDigital.mainView';
  }
  get settingsViewLang() {
    return 'devices.ThermostatDigital.settingsView';
  }
  get previewNameLang() {
    return this.$t('devices.ThermostatDigital.previewName');
  }
}
