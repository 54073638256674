
import { Vue, Component } from 'vue-property-decorator';
import PushButtonMock from '@/ui/components/devices/previews/components/PushButtonMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    PushButtonMock,
    DeviceLayout,
  },
})
export default class LightPushButtonPreview extends Vue {
  get previewNameLang() {
    return this.$t('devices.LightPushButton.previewName');
  }
}
