
import { Vue, Component } from 'vue-property-decorator';
import Forecast from '@/ui/components/devices/previews/mpc/HeatingProduction/Forecast.vue';
import Chart from '@/ui/components/devices/previews/mpc/HeatingProduction/Chart.vue';
import EnergyChart from '@/ui/components/devices/previews/mpc/HeatingProduction/EnergyChart.vue';

@Component({
  components: {
    Forecast,
    Chart,
    EnergyChart,
  },
})
export default class SettingsCharts extends Vue {
  tab: any = null

  get settingsViewLang() {
    return 'mlModel.HppProductionService.settingsView.chartTabs';
  }

  get tabs() {
    return [
      {
        title: 'Chart',
        locale: this.settingsViewLang,
        component: 'Chart',
      },
      {
        title: 'energyChart',
        locale: this.settingsViewLang,
        component: 'EnergyChart',
      },
      {
        title: 'Forecast',
        locale: this.settingsViewLang,
        component: 'Forecast',
      },
    ];
  }
}
