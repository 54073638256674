
import { Vue, Component, Prop } from 'vue-property-decorator';
import SettingsView from '@/ui/components/devices/components/SettingsView.vue';
import ShowEventBase from '@/ui/components/devices/devices/base/ShowEventBase.vue';
import PreviewActions from '@/ui/components/devices/actions/PreviewActions.vue';
import DeviceDescription from '@/ui/components/devices/actions/DeviceDescription.vue';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import VueI18n from 'vue-i18n';
import { IDevice } from '@/types/devices.types';
import { ILocaleEnum } from '@/types/common.types';

/**
 * Component that defines the structure of the MPC device
 */
@Component({
  components: {
    SettingsView,
    DeviceActions: () => import('@/ui/components/devices/actions/DeviceActions.vue'),
    ShowEventBase,
    PreviewActions,
    DeviceDescription,
    InfoTooltip,
    ModalWindow,
  },
})
export default class MPCLayout extends Vue {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: null }) fullDeviceData!: any;
  @Prop({ default: null }) previewData!: { name: string | VueI18n.TranslateResult; type: string };
  @Prop({ default: false }) isPreview!: boolean;
  @Prop({ default: true }) showEvent!: boolean;
  @Prop({ default: 'default' }) deviceSize!: 'default' | 'x2h' | 'x4h' | 'x5h';
  @Prop({ default: null }) deviceIcon!: string; // class name from icons lib
  @Prop({ default: '30' }) deviceIconSize!: string | number;
  @Prop({ default: 'theme' }) deviceIconTheme!: string;
  @Prop({ default: null }) showEventVariable!: { ShowEvent_errorWarningState: string };
  @Prop({ default: false }) isCharts!: boolean; // is mpc charts visible
  @Prop({ default: '1750' }) chartsModalWidth!: string | number;
  @Prop({ default: 'x-small' }) chartsButtonSize!: 'x-small' | 'small' | 'large' | 'x-large';
  @Prop({ default: null }) settingsModalWidth!: string | number;
  @State('measurements') measurementsState!: IVariablesState;

  isSettingsView = false;
  dialogOpen = false;
  isTooltip = false;
  localeEnum: ILocaleEnum = {
    en: 'english',
    de: 'german',
    it: 'italian',
  };

  switchSettingsView(setting: boolean) {
    this.isSettingsView = setting;
  }

  get showErrorTooltip() {
    return this.fullDeviceData?.data?.meta?.error_messages?.english?.length;
  }

  get errorMessage() {
    const currentSelectedLang = this.localeEnum[this.$i18n.locale as keyof ILocaleEnum];
    return this.showErrorTooltip ? this.fullDeviceData.data.meta.error_messages[currentSelectedLang][0] : '';
  }

  get errorStatus() {
    if (this.fullDeviceData?.data?.meta?.controllerMappings?.errorWarning === undefined) return 0;
    return this.measurementsState.measurements.get(this.fullDeviceData?.data?.meta?.controllerMappings?.errorWarning);
  }

  get errorIcon() {
    return [
      {},
      { name: 'warning', size: '24', color: 'orange' },
      { name: 'fault', size: '24', color: 'red' },
    ][this.errorStatus as number];
  }
}
