
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseChart from '@/ui/components/devices/mpc/components/BaseChart.vue';
import { Action } from 'vuex-class';

/**
 * Component that represent forecast chart.
 */
@Component({
  components: {
    BaseChart,
  },
})
export default class Forecast extends Vue {
  @Prop() deviceId: any;
  @Action('mpc/fetchMPCData') fetchMPCData!: any;

  timer: any = null
  rerenderKey = 0

  mpc: any = null

  tab: any = null
  fullscreen = false

  yAxisTempValve = [
    {
      title: null,
      opposite: false,
      showFirstLabel: true,
      showLastLabel: true,
      endOnTick: false,
      gridLineWidth: 1,
      min: 0,
      max: 40,
      lineColor: '#6ca2d8',
      labels: {
        format: '{value} °C',
        style: {
          color: '#6ca2d8',
        },
      },
    },
    {
      title: null,
      opposite: false,
      showFirstLabel: true,
      showLastLabel: true,
      endOnTick: false,
      gridLineWidth: 0,
      min: 0,
      max: 100,
      lineColor: '#90ed7d',
      labels: {
        format: '{value} %',
        style: {
          color: '#90ed7d',
        },
      },
    },
  ]
  yAxisWeather = [
    {
      title: null,
      opposite: false,
      showFirstLabel: true,
      showLastLabel: true,
      endOnTick: false,
      gridLineWidth: 1,
      min: -20,
      max: 50,
      lineColor: '#ab5050',
      labels: {
        format: '{value} °C',
        style: {
          color: '#ab5050',
        },
      },
    },
    {
      title: null,
      opposite: false,
      showFirstLabel: true,
      showLastLabel: true,
      endOnTick: false,
      gridLineWidth: 0,
      min: 0,
      max: 1500,
      lineColor: '#ab8a50',
      labels: {
        format: '{value} W/m2',
        style: {
          color: '#ab8a50',
        },
      },
    },
  ]

  /**
   * Prepares series for chart
   */
  get seriesTempValve() {
    return [
      {
        name: this.$t('mlModel.HeatingCircuitOptimization.settingsView.chartLabels.tempValve.roomTemp'),
        type: 'line',
        yAxis: 0,
        color: '#6ca2d8',
        data: this.roomTemperatureForecast,
      },
      {
        name: this.$t('mlModel.HeatingCircuitOptimization.settingsView.chartLabels.tempValve.valvePosition'),
        type: 'line',
        yAxis: 1,
        color: '#90ed7d',
        data: this.optimizedControl,
      },
    ];
  }

  /**
   * Prepares series for chart
   */
  get seriesWeather() {
    return [
      {
        name: this.$t('mlModel.HeatingCircuitOptimization.settingsView.chartLabels.weather.air'),
        type: 'line',
        yAxis: 0,
        color: '#ab5050',
        data: this.modifyWeatherForecastTime.air_temperature_forecast,
      },
      {
        name: this.$t('mlModel.HeatingCircuitOptimization.settingsView.chartLabels.weather.globalRadiation'),
        type: 'line',
        yAxis: 1,
        color: '#ab8a50',
        data: this.modifyWeatherForecastTime.global_radiation_horizontal,
      },
    ];
  }

  get langPath() {
    return 'mlModel.HeatingCircuitOptimization.settingsView.forecastTabs';
  }

  get charts() {
    if (this.mpc) {
      return this.mpc?.data?.meta.charts;
    }
    return null;
  }

  /**
   * Converts time to milliseconds for every item of weather_forecast array
   */
  get modifyWeatherForecastTime() {
    if (this.charts) {
      const res = Object.entries(this.charts.weather_forecast).map((item: any) => {
        return [item[0], item[1].map((i: any) => [i[0] * 1000, i[1]])];
      });
      let obj: any = {};
      res.forEach((item: any) => obj = { ...obj, ...{ [item[0]]: item[1] } });
      return obj;
    } else {
      return {
        air_temperature_forecast: [],
        diffuse_radiation_horizontal: [],
        direct_normal_radiation: [],
        global_radiation_horizontal: [],
        global_radiation_inclined: [],
      };
    }
  }

  /**
   * Converts time to milliseconds for every item of optimized_control array
   */
  get optimizedControl() {
    if (this.charts) {
      return this.charts.optimized_control.map((item: any) => [item[0] * 1000, item[1]]);
    } else {
      return [];
    }
  }

  /**
   * Converts time to milliseconds for every item of room_temperature_forecast array
   */
  get roomTemperatureForecast() {
    if (this.charts) {
      return this.charts.room_temperature_forecast.map((item: any) => [item[0] * 1000, item[1]]);
    } else {
      return [];
    }
  }

  get settingsViewLang() {
    return 'mlModel.HeatingCircuitOptimization.settingsView.chartTabs';
  }

  get tabs() {
    return [
      {
        title: 'Temp/Valve',
        locale: this.langPath,
        component: 'BaseChart',
        chartType: 'line',
        chartWidth: null,
        series: this.seriesTempValve,
        yAxis: this.yAxisTempValve,
      },
      {
        title: 'Weather',
        locale: this.langPath,
        component: 'BaseChart',
        chartType: 'line',
        chartWidth: null,
        series: this.seriesWeather,
        yAxis: this.yAxisWeather,
      },
    ];
  }

  /**
   * Updates chart data every 90 seconds
   */
  fetchChartData() {
    this.timer = setInterval(async () => {
      this.mpc = await this.fetchMPCData(this.deviceId);
      this.rerenderKey += 1;
    }, 900000);
  }

  async mounted() {
    this.mpc = await this.fetchMPCData(this.deviceId);
    this.fetchChartData();
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }
}
