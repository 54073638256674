
import { Vue, Component, Prop } from 'vue-property-decorator';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import AnimatedLine from '@/ui/components/devices/components/EnergyParts/AnimatedLine/index.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';

/**
 * PV single system settings.
 */
@Component({
  components: {
    AnimatedLine,
    OutputFieldBase,
    LabelUnitWrapper,
  },
})
export default class PVSystemSettings extends Vue {
  @Prop({ default: null }) instanceData!: any;

  get outputFieldActualValue() {
    return {
      'OutputField_actualValue': this.instanceData.power,
    };
  }
}
