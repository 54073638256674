import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { ILeadsState } from '@/store/modules/leads/types';
import { RootState } from '@/store/types';
import i18n from '@/ui/plugins/i18n';
import LeadsService from '@/services/LeadsService';

const state: ILeadsState = {
  leads: [],
};

const getters: GetterTree<ILeadsState, RootState> = {
  leads(state) {
    return state.leads;
  },
};

const mutations: MutationTree<ILeadsState> = {
  setLeads(state, payload) {
    state.leads = payload;
  },
};

const actions: ActionTree<ILeadsState, RootState> = {
  async fetchLeads({ commit, rootGetters }) {
    try {
      const user = rootGetters['app/getUser'];
      let data;
      if (user.super_admin) {
        data = await LeadsService.fetchLeads();
        commit('setLeads', data);
      } else {
        data = await LeadsService.fetchLeadsNonAdmin();
        commit('setLeads', data);
      }
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  async createLead({ commit, dispatch, rootGetters }, lead) {
    try {
      const user = rootGetters['app/getUser'];
      if (user.super_admin) {
        await LeadsService.createLead(lead);
      } else {
        await LeadsService.createLeadNonAdmin(lead);
      }
      dispatch('fetchLeads');
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.createLead'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  async updateLead({ commit, dispatch, rootGetters }, { id, lead, showMessage, skipFetch }) {
    try {
      const user = rootGetters['app/getUser'];
      if (user.super_admin) {
        await LeadsService.updateLead(id, lead);
      } else {
        await LeadsService.updateLeadNonAdmin(id, lead);
      }

      if (!skipFetch) {
        dispatch('fetchLeads');
      }

      if (showMessage) {
        commit('app/setReport', {
          type: 'success',
          message: i18n.t('uiComponents.reportMessages.updateLead'),
          value: true,
        }, { root: true });
      }
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  async deleteLeads({ commit, dispatch }, id) {
    try {
      await LeadsService.deleteLead(id);
      dispatch('fetchLeads');
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.deleteLead'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
};

export const leads: Module<ILeadsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
