import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import { CanAccessCheck } from '@/types/members.types';
import { IPublish, IPublishRecord } from '@/types/mqtt.types';

export enum SendingState {
  Sending = 'sending',
  Success = 'success',
  Error = 'error',
  Finished = 'finished',
  None = 'none',
}

/**
 * BasicControl class.
 * Contains send method which used to send records from Basic Controls.
 * Also contains list of measurements.
 */
@Component
export default class BasicControl extends Vue {
  @State('measurements') measurementsState!: IVariablesState;
  @Getter('members/canMemberAccess') canMemberAccess!: CanAccessCheck;
  @Action('mqttClient/publish') publish!: IPublish;

  sendingState: SendingState = SendingState.None;

  /**
   * Timeout for resetting the sending state.
   */
  private stateResetTimeout?: NodeJS.Timeout;

  get measurements() {
    return this.measurementsState.measurements;
  }

  /**
   * Clears variableData keys from Basic control name
   * Example:
   * - incoming variable data { Switch_on: "variable", Switch_off: "variable" }
   * - return variable data { on: "variable", off: "variable" }
   * @return {object}
   */
  get mappingsClean() {
    const { variableData, instance } = this as any;
    return Object.entries(variableData)
      .filter((mappings: [string, any]) => mappings[0].split('_')[0] === instance)
      .reduce((acc: { [key: string]: any }, el: [string, any]) => ({ ...acc, ...{ [el[0].split('_')[1]]: el[1] } }), {});
  }

  publishCallback() {
    return (error?: Error) => {
      if (error) {
        this.sendingState = SendingState.Error;
      }
    };
  }

  send(data: IPublishRecord[]) {
    this.publish({ records: data, callback: this.publishCallback() });
  }

  /**
   * Returns `true` if any of the required mappings is not set.
   * Needs to implemented by all non-abstract classes that extend from this.
   */
  get isNotMapped(): boolean {
    return false;
  }

  /**
   * Returns `true` if the device should be disabled.
   */
  get isDisabled(): boolean {
    return this.isNotMapped || !this.canMemberAccess('Devices', 'BasicControls');
  }

  /**
   * For handling the sending state when the message has been sent.
   */
  onSendStart() {
    this.sendingState = SendingState.Sending;
  }

  /**
   * Sets the sending state to success, and resets it after 2 seconds.
   */
  onSendSuccess() {
    this.setStateAndReset(SendingState.Success);
  }

  /**
   * Sets the sending state to error, and resets it after 2 seconds.
   */
  onSendError() {
    this.setStateAndReset(SendingState.Error);
  }

  /**
   * Sets the state to the given state, and resets it after 2 seconds.
   * @param state the desired `SendingState`
   */
  setStateAndReset(state: SendingState) {
    this.sendingState = state;
    this.stateResetTimeout = setTimeout(() => {
      this.sendingState = SendingState.None;
    }, 2000);
  }

  /**
   * Important: call super.destroyed() in child class
   */
  destroyed() {
    clearTimeout(this.stateResetTimeout);
  }
}
