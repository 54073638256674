import { render, staticRenderFns } from "./FinalLoggerPage.vue?vue&type=template&id=8ca3c628&scoped=true&"
import script from "./FinalLoggerPage.vue?vue&type=script&lang=ts&"
export * from "./FinalLoggerPage.vue?vue&type=script&lang=ts&"
import style0 from "./FinalLoggerPage.vue?vue&type=style&index=0&id=8ca3c628&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ca3c628",
  null
  
)

export default component.exports