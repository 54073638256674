
import { Vue, Component } from 'vue-property-decorator';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    DeviceLayout,
  },
})
export default class ElectricityMeterPreview extends Vue {
  get previewNameLang() {
    return this.$t('devices.ElectricityMeter.previewName');
  }
}
