
import { clone } from 'lodash';
import moment from 'moment-timezone';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';

/**
 * Component that represent TimezoneChooser
 */
@Component({
  components: {
    Validation,
  },
})
export default class TimezoneChooser extends mixins(Validation) {
  @Prop() currentValue!: string;
  @Prop({ default: false }) fullSize!: boolean;
  @Prop({ default: false }) disabled!: boolean;

  localValue!: string;
  timezones = moment.tz.names();

  get fieldWidth() {
    return this.fullSize ? {} : { width: '200px!important' };
  }

  created() {
    this.localValue = clone(this.currentValue);
  }

  onChange() {
    this.$emit('onChange', this.localValue);
  }
}
