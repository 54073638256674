var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('WizardContentView',{attrs:{"valid":_vm.valid,"handle-back":_vm.handleDecrement,"handle-next":_vm.handleNext},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',[_vm._v(_vm._s(_vm.$t('installationWizard.defineEmsSettings.generatorSettings.title')))])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"system-part"},[_c('v-form',{ref:"form",staticClass:"general-form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pt-0 mt-0"},[_c('v-col',{staticClass:"pt-0 mt-0"},[_c('div',{staticClass:"d-flex"},[_c('h3',[_vm._v(_vm._s(_vm.$t('installationWizard.defineEmsSettings.generatorSettings.socSettings')))]),_c('div',{staticClass:"pl-2"},[_c('InfoTooltip',{attrs:{"position":'bottom'},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('lynus-icon',{attrs:{"name":"info-in-circle","color":_vm.doesHover ? 'accent' : 'theme',"size":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('installationWizard.defineEmsSettings.generatorSettings.socTooltip'))+" ")]},proxy:true}])})],1)])])],1),_vm._l((_vm.socVariables),function([variableKey, variableData]){return _c('v-row',{key:variableKey},[_c('v-col',{staticClass:"py-1",attrs:{"lg":"6","md":"6","sm":"12","cols":"12"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"label":_vm.$t(`installationWizard.defineEmsSettings.generatorSettings.labels.${variableKey}`),"type":"number","color":"accent","outlined":"","flat":"","min":"0","max":"100","suffix":"%","step":1,"rules":[
                    _vm.socValidation,
                    _vm.rules.noCommas,
                    _vm.rules.required,
                    ..._vm.minMaxValidation(variableData.value, _vm.socFieldsLimits[variableKey].min, _vm.socFieldsLimits[variableKey].max),
                  ]},on:{"input":function($event){return _vm.handleSocChange()}},model:{value:(variableData.value),callback:function ($$v) {_vm.$set(variableData, "value", _vm._n($$v))},expression:"variableData.value"}})],1)])],1)}),_c('v-row',[_c('v-col',{staticClass:"py-1 d-flex",attrs:{"lg":"6","md":"6","sm":"12","cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('installationWizard.defineEmsSettings.generatorSettings.labels.LockTime'),"color":"accent","true-value":1,"false-value":0},on:{"change":function($event){return _vm.handleLockTimeChange($event)}},model:{value:(_vm.lockTime.value),callback:function ($$v) {_vm.$set(_vm.lockTime, "value", $$v)},expression:"lockTime.value"}}),_c('div',{staticClass:"ml-2 mt-5"},[_c('InfoTooltip',{attrs:{"position":'bottom'},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('lynus-icon',{attrs:{"name":"info-in-circle","color":_vm.doesHover ? 'accent' : 'theme',"size":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('installationWizard.defineEmsSettings.generatorSettings.timeSettingsTooltip'))+" ")]},proxy:true}])})],1)],1)],1),_vm._l((_vm.timeVariables),function([variableKey, variableData]){return _c('v-row',{key:variableKey},[_c('v-col',{staticClass:"py-1",attrs:{"lg":"6","md":"6","sm":"12","cols":"12"}},[_c('v-text-field',{key:_vm.lockTime.value,attrs:{"label":_vm.$t(`installationWizard.defineEmsSettings.generatorSettings.labels.${variableKey}`),"type":"number","color":"accent","outlined":"","flat":"","disabled":_vm.disableTimeLockSettings,"min":_vm.variablesOptions[variableKey].min,"max":_vm.variablesOptions[variableKey].max,"step":1,"rules":[
                  _vm.rules.noCommas,
                  _vm.rules.required,
                  ..._vm.minMaxValidation(
                    variableData.value,
                    _vm.variablesOptions[variableKey].min,
                    _vm.variablesOptions[variableKey].max,
                  ),
                ]},model:{value:(variableData.value),callback:function ($$v) {_vm.$set(variableData, "value", _vm._n($$v))},expression:"variableData.value"}})],1)],1)})],2)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }