
import { Vue, Component } from 'vue-property-decorator';
import Temperature from '@/ui/components/devices/previews/devices/Temperature.vue';
import LightDimmer from '@/ui/components/devices/previews/devices/LightDimmer.vue';
import LightSwitch from '@/ui/components/devices/previews/devices/LightSwitch.vue';
import VariableInputField from '@/ui/components/devices/previews/devices/VariableInputField.vue';
import VariableOutputField from '@/ui/components/devices/previews/devices/VariableOutputField.vue';
import LightPushButton from '@/ui/components/devices/previews/devices/LightPushButton.vue';
import SocketSwitch from '@/ui/components/devices/previews/devices/SocketSwitch.vue';
import LightRGB from '@/ui/components/devices/previews/devices/LightRGB.vue';
import ControlBlinds from '@/ui/components/devices/previews/devices/ControlBlinds.vue';
import ControlShutter from '@/ui/components/devices/previews/devices/ControlShutter.vue';
import ControlAwning from '@/ui/components/devices/previews/devices/ControlAwning.vue';
import ThermostatDigital from '@/ui/components/devices/previews/devices/ThermostatDigital.vue';
import ThermostatAnalog from '@/ui/components/devices/previews/devices/ThermostatAnalog.vue';
import AirHumiditySensor from '@/ui/components/devices/previews/devices/AirHumiditySensor.vue';
import BrightnessSensor from '@/ui/components/devices/previews/devices/BrightnessSensor.vue';
import SensorCO2 from '@/ui/components/devices/previews/devices/SensorCO2.vue';
import SensorLevel from '@/ui/components/devices/previews/devices/SensorLevel.vue';
import WeatherStation from '@/ui/components/devices/previews/devices/WeatherStation.vue';
import HotWaterMeter from '@/ui/components/devices/previews/devices/HotWaterMeter.vue';
import ColdWaterMeter from '@/ui/components/devices/previews/devices/ColdWaterMeter.vue';
import HeatingMeter from '@/ui/components/devices/previews/devices/HeatingMeter.vue';
import ElectricityMeter from '@/ui/components/devices/previews/devices/ElectricityMeter.vue';
import Gauge from '@/ui/components/devices/previews/devices/Gauge.vue';
import MotionSensor from '@/ui/components/devices/previews/devices/MotionSensor.vue';
import Battery from '@/ui/components/devices/previews/devices/Battery.vue';
import PVSystem from '@/ui/components/devices/previews/devices/PVSystem.vue';
import HouseConsumption from '@/ui/components/devices/previews/devices/HouseConsumption.vue';
import Generator from '@/ui/components/devices/previews/devices/Generator.vue';
import MainsConnection from '@/ui/components/devices/previews/devices/MainsConnection.vue';
import ElectricChargingStation from '@/ui/components/devices/previews/devices/ElectricChargingStation.vue';
import VariableTextOutputField from '@/ui/components/devices/previews/devices/VariableTextOutputField.vue';
import VariableTextInputField from '@/ui/components/devices/previews/devices/VariableTextInputField.vue';
import ElectronicBoiler from '@/ui/components/devices/previews/devices/ElectronicBoiler.vue';
import VentilatorSwitch from '@/ui/components/devices/previews/devices/VentilatorSwitch.vue';
import MusicSystem from '@/ui/components/devices/previews/devices/MusicSystem.vue';
import TV from '@/ui/components/devices/previews/devices/TV.vue';
import MotorWithoutVFD from '@/ui/components/devices/previews/devices/MotorWithoutVFD.vue';
import PumpWithoutVFD from '@/ui/components/devices/previews/devices/PumpWithoutVFD.vue';
import MotorWithVFD from '@/ui/components/devices/previews/devices/MotorWithVFD.vue';
import PumpWithVFD from '@/ui/components/devices/previews/devices/PumpWithVFD.vue';
import MixingValve from '@/ui/components/devices/previews/devices/MixingValve.vue';
import Ventilation from '@/ui/components/devices/previews/devices/Ventilation.vue';
import DropDownInputFieldWithVariablePreview from '@/ui/components/devices/previews/devices/DropDownInputFieldWithVariablePreview.vue';
import DropDownOutputFieldWithVariablePreview from '@/ui/components/devices/previews/devices/DropDownOutputFieldWithVariablePreview.vue';
import EnergyView from '@/ui/components/devices/previews/devices/EnergyViewPreview.vue';
import GeneralSwitchV2 from '@/ui/components/devices/previews/devices/GeneralSwitchV2.vue';
import EMSV2 from '@/ui/components/devices/previews/devices/EMSV2/index.vue';
import { envDeviceLibraryCategories } from '@/utils/env';

@Component({
  components: {
    GeneralSwitchV2,
    EnergyView,
    DropDownInputFieldWithVariablePreview,
    DropDownOutputFieldWithVariablePreview,
    Ventilation,
    MixingValve,
    MotorWithVFD,
    PumpWithVFD,
    PumpWithoutVFD,
    MotorWithoutVFD,
    TV,
    MusicSystem,
    VentilatorSwitch,
    ElectronicBoiler,
    VariableTextInputField,
    VariableTextOutputField,
    MotionSensor,
    SensorLevel,
    SensorCO2,
    BrightnessSensor,
    AirHumiditySensor,
    Temperature,
    LightDimmer,
    LightSwitch,
    VariableOutputField,
    VariableInputField,
    LightPushButton,
    SocketSwitch,
    LightRGB,
    ControlBlinds,
    ControlShutter,
    ControlAwning,
    ThermostatDigital,
    ThermostatAnalog,
    WeatherStation,
    HotWaterMeter,
    ColdWaterMeter,
    HeatingMeter,
    ElectricityMeter,
    Gauge,
    Battery,
    PVSystem,
    HouseConsumption,
    Generator,
    MainsConnection,
    ElectricChargingStation,
    EMSV2,
  },
})
export default class DevicesView extends Vue {
  tab = null

  get devicesLib() {
    const defaultSizes: any = { lg: '3', md: '6' };
    return [
      {
        name: 'Energy',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.energy',
        data: [
          { device: 'Battery', sizes: defaultSizes },
          { device: 'PVSystem', sizes: defaultSizes },
          { device: 'HouseConsumption', sizes: defaultSizes },
          { device: 'Generator', sizes: defaultSizes },
          { device: 'MainsConnection', sizes: defaultSizes },
          { device: 'ElectricChargingStation', sizes: defaultSizes },
          { device: 'ElectronicBoiler', sizes: defaultSizes },
          { device: '', sizes: defaultSizes },
          { device: 'EnergyView', sizes: { lg: '12', md: '12' } },
          { device: 'EMSV2', sizes: { lg: '12', md: '12' } },
        ],
      },
      {
        name: 'Gauges',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.gauges',
        data: [{ device: 'Gauge', sizes: { lg: '6', md: '12' } }],
      },
      {
        name: 'Multimedia',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.multimedia',
        data: [
          { device: 'MusicSystem', sizes: defaultSizes },
          { device: 'TV', sizes: defaultSizes },
        ],
      },
      {
        name: 'Light',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.light',
        data: [
          { device: 'LightDimmer', sizes: defaultSizes },
          { device: 'LightSwitch', sizes: defaultSizes },
          { device: 'LightRGB', sizes: defaultSizes },
          { device: 'LightPushButton', sizes: defaultSizes },
        ],
      },
      {
        name: 'Sensors',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.sensors',
        data: [
          { device: 'AirHumiditySensor', sizes: defaultSizes },
          { device: 'BrightnessSensor', sizes: defaultSizes },
          { device: 'SensorCO2', sizes: defaultSizes },
          { device: 'SensorLevel', sizes: defaultSizes },
          { device: 'Temperature', sizes: defaultSizes },
          { device: 'WeatherStation', sizes: defaultSizes },
          { device: 'MotionSensor', sizes: defaultSizes },
        ],
      },
      {
        name: 'Meters',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.meters',
        data: [
          { device: 'HotWaterMeter', sizes: defaultSizes },
          { device: 'ColdWaterMeter', sizes: defaultSizes },
          { device: 'HeatingMeter', sizes: defaultSizes },
          { device: 'ElectricityMeter', sizes: defaultSizes },
        ],
      },
      {
        name: 'Motion',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.motion',
        data: [
          { device: 'MotorWithoutVFD', sizes: defaultSizes },
          { device: 'MotorWithVFD', sizes: defaultSizes },
          { device: 'PumpWithoutVFD', sizes: defaultSizes },
          { device: 'PumpWithVFD', sizes: defaultSizes },
          { device: 'MixingValve', sizes: defaultSizes },
        ],
      },
      {
        name: 'Shading',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.shading',
        data: [
          { device: 'ControlBlinds', sizes: defaultSizes },
          { device: 'ControlShutter', sizes: defaultSizes },
          { device: 'ControlAwning', sizes: defaultSizes },
        ],
      },
      {
        name: 'Variable I/O Fields',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.variableFields',
        data: [
          { device: 'VariableOutputField', sizes: defaultSizes },
          { device: 'VariableInputField', sizes: defaultSizes },
          { device: 'VariableTextOutputField', sizes: defaultSizes },
          { device: 'VariableTextInputField', sizes: defaultSizes },
          { device: 'DropDownInputFieldWithVariablePreview', sizes: defaultSizes },
          { device: 'DropDownOutputFieldWithVariablePreview', sizes: defaultSizes },
        ],
      },
      {
        name: 'Sockets',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.sockets',
        data: [{ device: 'SocketSwitch', sizes: defaultSizes }],
      },
      {
        name: 'Heating/Cooling',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.heatingCooling',
        data: [
          { device: 'ThermostatDigital', sizes: defaultSizes },
          { device: 'ThermostatAnalog', sizes: defaultSizes },
        ],
      },
      {
        name: 'Ventilation',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.ventilation',
        data: [
          { device: 'VentilatorSwitch', sizes: defaultSizes },
          { device: 'Ventilation', sizes: defaultSizes },
        ],
      },
      {
        name: 'Switch',
        locale: 'uiComponents.devicesLibrary.buildingAutomationTabs.switch',
        data: [{ device: 'GeneralSwitchV2', sizes: defaultSizes }],
      },
    ];
  }

  get devicesLibFiltered() {
    return this.devicesLib.filter((e) => envDeviceLibraryCategories['Building Automation'].includes(e.name));
  }
}
