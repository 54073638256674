import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/store/types';
import { merge } from 'lodash';
import i18n from '@/ui/plugins/i18n';
import api from '@/services/api';
import { IWizardPage } from '@/types/wizards/wizard.general.types';
import { ITenantWizardState } from './types';
import { ITenantModel } from '@/types/wizards/tenant.types';

const defaultWizardState = () => {
  return {
    currentPage: 0,
    currentStep: 1,
    currentPageIndex: 0,
    pages: [
      {
        title: 'WelcomePage',
        view: 'WelcomePage',
        step: 1,
        page: 0,
        dependencies: [],
      },
      {
        title: 'SystemInformation',
        view: 'SystemInformation',
        step: 1,
        page: 1,
        dependencies: [],
      },
      {
        title: 'BasicBillingInformation',
        view: 'BasicBillingInformation',
        step: 1,
        page: 2,
        dependencies: [],
      },
      {
        title: 'PaymentInformation',
        view: 'PaymentInformation',
        step: 1,
        page: 3,
        dependencies: [],
      },
      {
        title: 'FlatsInformation',
        view: 'FlatsInformation',
        step: 1,
        page: 4,
        dependencies: [],
      },
      {
        title: 'TariffSettings',
        view: 'TariffSettings',
        step: 1,
        page: 5,
        dependencies: [],
      },
      {
        title: 'ExtraCosts',
        view: 'ExtraCosts',
        step: 1,
        page: 6,
        dependencies: [],
      },
      {
        title: 'AutoMapping',
        view: 'AutoMapping',
        step: 1,
        page: 7,
        dependencies: [],
      },
      {
        title: 'Electricity',
        view: 'Electricity',
        step: 2,
        page: 0,
        dependencies: ['electricity'],
      },
      {
        title: 'Heating',
        view: 'Heating',
        step: 2,
        page: 1,
        dependencies: ['heating'],
      },
      {
        title: 'Cooling',
        view: 'Cooling',
        step: 2,
        page: 2,
        dependencies: ['cooling'],
      },
      {
        title: 'ColdWater',
        view: 'ColdWater',
        step: 2,
        page: 3,
        dependencies: ['coldWater'],
      },
      {
        title: 'WarmWater',
        view: 'WarmWater',
        step: 2,
        page: 4,
        dependencies: ['serviceWater'],
      },
      {
        title: 'ConsumptionComparison',
        view: 'ConsumptionComparison',
        step: 3,
        page: 0,
        dependencies: [],
      },
      {
        title: 'BillingAndDocumentOutput',
        view: 'BillingAndDocumentOutput',
        step: 4,
        page: 0,
        dependencies: [],
      },
      {
        title: 'FinalPage',
        view: 'FinalPage',
        step: 5,
        page: 0,
        dependencies: [],
      },
    ],
    tenant: {
      header: {
        image_Id: '', // will contain the file as base64
        headerText: '',
        providerName: '',
        contractCountry: '',
        ownerAddress: {
          street: '',
          zip: '',
          city: '',
          country: '',
        },
        ownerMobile: '',
        ownerMail: '',
        ownerRegistrationNumber: '',
        ownerName: '',
      },
      general: {
        paymentDetails: {
          currency: '€',
          tax: 0, // 0 - 100 %
          coldWaterTax: 0, // 0 - 100 %
          serviceWaterTax: 0, // 0 - 100 %
          heatingTax: 0, // 0 - 100 %
          productionTax: 0, // 0 - 100 %
          sideCostsTax: 0, // 0 - 100 %
          coolingTax: 0, // 0 - 100 %,
          isTaxIncluded: false,
          bankName: '',
          iban: '', // add regex for user input
          swift: '',
          additionalText: '',
        },
        billingComponents: {
          electricity: false,
          serviceWater: false,
          coldWater: false,
          heating: false,
          cooling: false,
        },
        flats: {
          flatCount: 0,
          flatConfigurations: [],
        },
        tariffSettings: {
          electricity: {
            lowTariffProduction: 0,
            highTariffProduction: 0,
            lowTariffGrid: 0,
            highTariffGrid: 0,
            allDayTariffGrid: 0,
            allDayTariffProduction: 0,
            residualElectricityComposition: [], // user can select as many sources as he likes, sum of all percentage values must be 100 %
            lowTariffTimesProduction: {
              start: '00:00',
              end: '00:00',
            },
            lowTariffTimesGrid: {
              start: '00:00',
              end: '00:00',
            },
            residualElectricityText: '',
          },
          water: {
            priceServiceWater: 0, // should have same currency like selected inside "general.paymentDetails.currency"
            priceColdWater: 0, // should have same currency like selected inside "general.paymentDetails.currency"
            priceHeating: 0, // should have same currency like selected inside "general.paymentDetails.currency"
            priceCooling: 0, // should have same currency like selected inside "general.paymentDetails.currency"
          },
        },
        sideCosts: [{
          name: '',
          amount: 0,
        }],
        timeZone: '',
        productionCounters: [],
        gridCounter: '',
        gridProductionCounter: '',
        overallCounters: {
          energyCounter: [], // id of the Energy Counter of the Flat
          heatingCounter: [], // id of the Heating Counter of the Flat
          serviceWaterCounter: [], // id of the Service Water Counter of the Flat
          coldWaterCounter: [], // id of the Cold Water Counter of the Flat
          coolingCounter: [], // id of the Cooling Counter of the Flat
        },
      },
      referenceInformation: {
        // calculate values later inside the export file with the selected interval (billingSettings.interval)
        consumption1PersonHousehold: 2400, // kWh/Year default 2400
        consumption2PersonHousehold: 3000, // kWh/Year default 3000
        consumption3PersonHousehold: 3600, // kWh/Year default 3600
        consumption4PersonHousehold: 4000, // kWh/Year default 4000
        additionalText: '',
      },
      billingSettings: {
        start: 0, // will contain date when to start
        interval: 'Year',
        mail: '',
        subject: 'subject',
        content: 'from {{start}} to {{end}}', // default:  from {{start}} to {{end}}
      },
      lastMonthUpdate: 0,
      lastYearUpdate: 0,
      lastPeriod: {},
    },
    wasTenantDone: false,
    wasTenantRestarted: false,
    isAutoMappingEnabled: true, // if true, automapping is set for all counter pages
    hasLynusInverter: false, // if false we have tenant only if yes we have a hybrid system
    overallProductionMeasurement: '',
  } as ITenantWizardState;
};

const state: ITenantWizardState = defaultWizardState();

const getters: GetterTree<ITenantWizardState, RootState> = {
  currentPage(state: ITenantWizardState): number {
    return state.currentPage;
  },
  currentStep(state: ITenantWizardState): number {
    return state.currentStep;
  },
  // returns based on the dependencies of the pages
  wizardPages(state: ITenantWizardState): IWizardPage[] {
    return state.pages.filter((page: IWizardPage) => {
      return page.dependencies?.every((dependency: string) => {
        return (state.tenant.general.billingComponents as any)[dependency] === true;
      });
    });
  },
  considerProjectStatus(): boolean {
    // this can be used to control whether we show the "project offline" alerts etc.
    return process.env.NODE_ENV !== 'development';
  },
  isAutoMappingEnabled(): boolean {
    return state.isAutoMappingEnabled;
  },
  wasTenantDone(state: ITenantWizardState): boolean {
    return state.wasTenantDone;
  },
  defaultWizardStatePages(): IWizardPage[] {
    return defaultWizardState().pages;
  },
  hasLynusInverter(state: ITenantWizardState): boolean {
    return state.hasLynusInverter;
  },
  overallProductionMeasurement(state: ITenantWizardState): string {
    return state.overallProductionMeasurement;
  },
};

const mutations: MutationTree<ITenantWizardState> = {
  setCurrentPage(state: ITenantWizardState, data: number) {
    state.currentPage = data;
  },
  setCurrentStep(state: ITenantWizardState, data: number) {
    state.currentStep = data;
  },
  updateTenantObject(state: ITenantWizardState, data: ITenantModel) {
    state.tenant = data;
  },
  reset(state: ITenantWizardState) {
    state = merge(state, defaultWizardState());
    // to avoid of merge not resetting tenant object we do it manually
    state.tenant = defaultWizardState().tenant;
  },
  setWasTenantDoneState(state: ITenantWizardState, data: boolean) {
    state.wasTenantDone = data;
  },
  resetPageStepIndex(state: ITenantWizardState) {
    state.currentPageIndex = 0;
    state.currentPage = 0;
    state.currentStep = 1;
  },
  setTenant(state: ITenantWizardState, data: ITenantModel) {
    state.tenant = data;
  },
  setIsAutoMappingEnabled(state: ITenantWizardState, data: boolean) {
    state.isAutoMappingEnabled = data;
  },
  setWasTenantRestarted(state: ITenantWizardState, data: boolean) {
    state.wasTenantRestarted = data;
  },
  setPages(state: ITenantWizardState, data: IWizardPage[]) {
    state.pages = data;
  },
  setHasLynusInverter(state: ITenantWizardState, data: boolean) {
    state.hasLynusInverter = data;
  },
  setOverallProductionMeasurement(state: ITenantWizardState, data: string) {
    state.overallProductionMeasurement = data;
  },
};

const actions: ActionTree<ITenantWizardState, RootState> = {
  handleIncrement({ state, getters }) {
    if (state.currentPageIndex + 1 < getters.wizardPages.length) {
      state.currentPageIndex++;
      state.currentPage = getters.wizardPages[state.currentPageIndex].page;
      state.currentStep = getters.wizardPages[state.currentPageIndex].step;
    }
  },
  handleDecrement({ state, getters }) {
    if (state.currentPageIndex !== 0) {
      state.currentPageIndex--;
      state.currentPage = getters.wizardPages[state.currentPageIndex].page;
      state.currentStep = getters.wizardPages[state.currentPageIndex].step;
    }
  },
  async loadTenant({ commit }, project_id: string) {
    try {
      const result = await api.fetch(`/projects/${project_id}/renters`, 'GET');
      // if tenant was never done before endpoint will return object with empty values so we do not need to replace the current one
      if (result.header.ownerMail !== '') {
        // is needed for old tenant models where the billingComponents did not contain cooling
        result.general.billingComponents.cooling = result.general.billingComponents.cooling ?? false;
        commit('setTenant', result);
      }
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  async updateTenant({ commit }, data: { project_id: string; tenant: any}) {
    try {
      delete data.tenant.general.overallCounters.serviceWaterConter;
      delete data.tenant.general.overallCounters.coldWaterConter;
      await api.fetch(`/projects/${data.project_id}/renters`, 'PUT', data.tenant);
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.updatedTenantModel'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
  async downLoadTenant({ commit }, data: {start: number; end: number; project_id: string}) {
    try {
      const file = await api.fetch(`/projects/${data.project_id}/renters/report?id=${data.project_id}&start=${data.start}&end=${data.end}`, 'GET', undefined, undefined, 'blob');
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.downloadFileSuccess'),
        value: true,
      }, { root: true });
      return file;
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
  async deleteTenant({ commit }, project_id: string) {
    try {
      await api.fetch(`/projects/${project_id}/renters`, 'DELETE');
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.deletedTenantModel'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
};

export const tenantWizard: Module<ITenantWizardState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
