
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseChart from '@/ui/components/devices/mpc/components/BaseChart.vue';
import { Action } from 'vuex-class';
import ChartColors from '@/ui/components/devices/charts/charts/ChartColors';

/**
 * Component that represent forecast chart.
 */
@Component({
  components: {
    BaseChart,
  },
})
export default class Forecast extends Vue {
  @Prop() mpcId!: any;
  @Prop({ default: null }) chartsScaling!: any;
  @Action('mpc/fetchMPCData') fetchMPCData!: any;

  rerenderKey = 0

  mpc: any = null
  timer: any = null
  get chartColors() {
    return ChartColors.colors(this.$vuetify.theme.dark);
  }

  get charts() {
    if (this.mpc) {
      return this.mpc?.data?.meta.charts;
    }
    return null;
  }

  /**
   * Converts time to milliseconds for every item of predictedEnergy array
   */
  get predictedEnergy() {
    if (this.charts?.predictedEnergy) {
      return this.charts.predictedEnergy.pv.map((el: any) => [el[0] * 1000, el[1]]);
    } else {
      return [];
    }
  }

  /**
   * Converts time to milliseconds for every item of predictedPower array
   */
  get predictedPower() {
    if (this.charts?.predictedPower) {
      return this.charts.predictedPower.pv.map((el: any) => [el[0] * 1000, el[1]]);
    } else {
      return [];
    }
  }

  /**
   * Prepares series for chart
   */
  get seriesForecast() {
    return [
      {
        name: this.$t('mlModel.PVProductionService.settingsView.chartLabels.forecast.predictedEnergy'),
        type: 'line',
        yAxis: 0,
        color: this.chartColors[0],
        data: this.predictedEnergy,
      },
      {
        name: this.$t('mlModel.PVProductionService.settingsView.chartLabels.forecast.predictedPower'),
        type: 'line',
        yAxis: 1,
        color: this.chartColors[1],
        data: this.predictedPower,
      },
    ];
  }

  /**
   * Prepares Y-axis for chart
   */
  get yAxisForecast() {
    return [
      {
        title: null,
        opposite: false,
        showFirstLabel: true,
        showLastLabel: true,
        endOnTick: false,
        gridLineWidth: 1,
        min: 0,
        max: this.chartsScaling * 12,
        lineColor: this.chartColors[0],
        labels: {
          format: '{value} kWh',
          style: {
            color: this.chartColors[0],
          },
        },
      },
      {
        title: null,
        opposite: false,
        showFirstLabel: true,
        showLastLabel: true,
        endOnTick: false,
        gridLineWidth: 0,
        min: 0,
        max: this.chartsScaling * 2,
        lineColor: this.chartColors[1],
        labels: {
          format: '{value} kW',
          style: {
            color: this.chartColors[1],
          },
        },
      },
    ];
  }

  get chartData() {
    return {
      chartType: 'line',
      chartWidth: null,
      chartHeight: 700,
      series: this.seriesForecast,
      yAxis: this.yAxisForecast,
    };
  }

  /**
   * Updates chart data every 90 seconds
   */
  fetchChartData() {
    this.timer = setInterval(async () => {
      this.mpc = await this.fetchMPCData(this.mpcId);
      this.rerenderKey += 1;
    }, 900000);
  }

  async mounted() {
    this.mpc = await this.fetchMPCData(this.mpcId);
    this.fetchChartData();
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }
}
