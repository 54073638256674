
import { Vue, Component } from 'vue-property-decorator';
import HeatingCircuitOptimization from '@/ui/components/devices/previews/mpc/HeatingCircuitOptimization/index.vue';
import PVProductionService from '@/ui/components/devices/previews/mpc/PVProductionService/index.vue';
import ConsumptionService from '@/ui/components/devices/previews/mpc/ConsumptionService/index.vue';
import PVMonitoringService from '@/ui/components/devices/previews/mpc/PVMonitoringService/index.vue';
import EMS from '@/ui/components/devices/previews/mpc/EMS/index.vue';
import SetpointOptimizer from '@/ui/components/devices/previews/mpc/SetpointOptimizer/index.vue';
import HppProductionService from '@/ui/components/devices/previews/mpc/HeatingProduction/index.vue';
import { envMPCDeviceList, envMPCLibraryTabs } from '@/utils/env';

@Component({
  components: {
    HeatingCircuitOptimization,
    PVProductionService,
    ConsumptionService,
    EMS,
    PVMonitoringService,
    SetpointOptimizer,
    HppProductionService,
  },
})
export default class MPCPreviewsList extends Vue {
  tab = null;
  get devicesLib() {
    return [
      {
        name: 'Energy',
        locale: 'uiComponents.aimlLibrary.mpcSubTabs.energyTab',
        data: [
          { device: 'PVProductionService', sizes: { lg: 3, md: 6, sm: 12 } },
          { device: 'ConsumptionService', sizes: { lg: 3, md: 6, sm: 12 } },
          { device: 'PVMonitoringService', sizes: { lg: 3, md: 6, sm: 12 } },
          { device: 'EMS', sizes: { lg: 12, md: 12, sm: 12 } },
        ].filter((element: any) => envMPCDeviceList.includes(element.device)),
      },
      {
        name: 'Heating',
        locale: 'uiComponents.aimlLibrary.mpcSubTabs.heatingTab',
        data: [
          { device: 'SetpointOptimizer', sizes: { lg: 6, md: 12, sm: 12 } },
          { device: 'HppProductionService', sizes: { lg: 3, md: 6, sm: 12 } },
          { device: 'HeatingCircuitOptimization', sizes: { lg: 3, md: 6, sm: 12 } },
        ].filter((element: any) => envMPCDeviceList.includes(element.device)),
      },
    ].filter((element: any) => envMPCLibraryTabs.includes(element.name));
  }
}
