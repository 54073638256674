
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import HourPicker from '@/ui/components/devices/charts/components/HourPicker.vue';
import { formatDate, formatDateForPeriod, fromDatePickerString, toSecondsSinceEpoch } from '@/utils/utilsFunctions';
import moment from 'moment';

@Component({
  components: {
    HourPicker,
  },
})
export default class DefaultCalendar extends Vue {
  @Prop({ default: 'day' }) periodName!: string;
  @Prop() calendarMin?: string;
  @Prop() calendarMax?: string;

  // contains an array of date strings
  date: string[] = [];

  pickerTypes: any = {
    day: 'DATE',
    week: 'DATE',
    month: 'MONTH',
    year: 'YEAR',
  }

  @Watch('date')
  onDateChange(val: string) {
    // emits the currently selected (start-) date
    // also for week, only the week start is emitted
    this.$emit('input', this.dateInSeconds);
  }

  /**
   * date model object as an array of javascript Date objects.
   */
  get dateArray(): Date[] {
    return this.date.map(d => fromDatePickerString(d));
  }

  /**
   * date model object as an array of seconds since epoch timestamps.
   */
  get dateInSeconds() {
    return [toSecondsSinceEpoch(this.dateArray[0])];
  }

  handleMonth(date: any) {
    this.date = [date.toString()];

    if (this.periodName === 'month') {
      // reset activePicker to stay in month view
      (this.$refs.calendar as any).activePicker = this.pickerTypes.month;
    }
  }

  // year needs this special function in order to interrupt the calendar after year is selected
  handleYear(year: number) {
    this.date = [year.toString()];

    if (this.periodName === 'year') {
      // reset activePicker to stay in year view
      (this.$refs.calendar as any).activePicker = this.pickerTypes.year;
    }
  }

  // date expected to be in format YYYY-MM-DD
  handleWeek(date: any) {
    // clear second date in date array if week was selected before
    if (this.periodName === 'day') {
      this.date = [date];
    }
    if (this.periodName === 'week') {
      // moment object of the clicked date (e.g. wednesday of some week)
      const clickedDateMoment = moment(this.date[0]);

      const weekStartString = clickedDateMoment
        // monday of this week
        .isoWeekday(1)
        .format('yyyy-MM-DD');
      const weekEndString = clickedDateMoment
        // monday 00:00 of the following week
        .isoWeekday(7)
        .format('yyyy-MM-DD');

      this.date = [weekStartString, weekEndString];
    }
  }

  titleDateFormat(date: string[]) {
    return formatDateForPeriod(this.periodName, date);
  }

  get calendarType() {
    return ({
      day: 'date',
      week: 'date',
      month: 'month',
      year: 'month',
    } as any)[this.periodName];
  }

  mounted() {
    (this.$refs.calendar as any).activePicker = this.pickerTypes[this.periodName];
  }
}
