
import { Component, Prop, Watch } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';

/**
 * Component that represent DropDown Basic Control
 */
@Component({
  components: { FeedbackProvider },
})
export default class DropDownBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop() label!: string;
  @Prop({ default: false }) isOutput!: boolean;
  @Prop({ required: true }) textMapping!: any; // of format {0: "Entry0", 1: "Entry1", ...}
  @Prop({ default: false }) enableSendButton!: boolean;
  @Prop({ default: false }) enableVariableButtonColor!: boolean;

  selectedKey!: string;
  selectedForButtonSend!: any;
  items: any = null;

  currentValue: any = null;

  @Watch('textMapping')
  onMappingChange() {
    this.items = this.handleTextMappings();
  }

  get selected(): string {
    const textKeys = Object.keys(this.textMapping);
    const value = this.measurements.get(this.mappingsClean.targetValue);
    this.currentValue = value;

    if (value === undefined || !textKeys.includes(value.toString())) {
      // invalid value from backend
      if (this.selectedKey === undefined) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedKey = Object.keys(this.textMapping)[0];
        return this.selectedKey;
      }
    } else {
      // valid value from backend
      this.selectedKey = value.toString();
    }
    return this.selectedKey;
  }
  set selected(value: string) {
    // result contains the selected obj of the text Mapping format: {name:string,value:number}
    const result = this.items.filter((object: any) => {
      return object.value === value;
    });
    this.selectedForButtonSend = value;
  }

  get sendButtonColor() {
    // default way Button only has one color
    const isStateEqualToOne = this.measurements.get(this.mappingsClean.state) === 1;
    if (isStateEqualToOne || !this.enableVariableButtonColor) return 'accent';
    else return '#4b6978';
  }

  handleTextMappings() {
    return Object.keys(this.textMapping).map((key: string) => {
      return {
        name: this.textMapping[key],
        value: key,
      };
    });
  }
  handleValue() {
    if (!this.enableSendButton && !this.isOutput) {
      this.onSendStart();
      this.send([{ v: parseInt(this.selectedForButtonSend, 10), n: this.mappingsClean.targetValue, u: '' }]);
      this.onSendSuccess();
    }
  }

  sendVal() {
    this.onSendStart();
    this.send([
      { v: parseInt(this.selectedForButtonSend, 10), n: this.mappingsClean.targetValue, u: '' },
    ]);
    this.onSendSuccess();
  }

  created() {
    this.items = this.handleTextMappings();
  }

  get isNotMapped(): boolean {
    return this.mappingsClean.targetValue === '';
  }
}
