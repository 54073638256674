
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import InputFieldNumber from '@/ui/components/components/InputFieldNumber.vue';

/**
 * EMS additional field component.
 * Manges targetObject scaling properties.
 */
@Component({
  components: {
    InputFieldNumber,
  },
})
export default class EMSAdditionalFields extends mixins(Validation) {
  @Prop({ default: null }) targetObject!: any;
}
