
import { Vue, Component } from 'vue-property-decorator';
import { IPartnerWorkspace, ISolarCalculationTableItem } from '@/types/partnerWorkspace.types';
import { formatDate } from '@/utils/utilsFunctions';
import { Action, Getter, State } from 'vuex-class';
import { IAppState } from '@/store/modules/app/types';

@Component({
  components: {},
})
export default class SolarCalculator extends Vue {
  @State('app') appState!: IAppState;
  @Getter('partnerWorkspaces/workspace') workspace!: IPartnerWorkspace;
  @Action('partnerWorkspaces/fetchWorkspaces') fetchWorkspaces!: () => Promise<void>;
  @Action('partnerWorkspaces/updateWorkspace') updateWorkspace!: (data: {project_id: string; workspace: IPartnerWorkspace; showMessage: boolean}) => Promise<void>;

  showSolarCalculator = false;
  solarCalculationList: ISolarCalculationTableItem[] = [];
  newSolarCalculatorId = '';

  get headers() {
    return [
      {
        text: this.$t('partners.partnerWorkspace.pages.solarCalculator.tableHeaders.project_name'),
        value: 'name',
        sortable: false,
        width: '25%',
      },
      {
        text: this.$t('partners.partnerWorkspace.pages.solarCalculator.tableHeaders.created_at'),
        value: 'created_at',
        sortable: false,
        width: '25%',
      },
      {
        text: this.$t('partners.partnerWorkspace.pages.solarCalculator.tableHeaders.created_by'),
        value: 'created_by',
        sortable: false,
        width: '25%',
      },
      {
        text: this.$t('partners.partnerWorkspace.pages.solarCalculator.tableHeaders.calculatorResult'),
        value: 'fileData',
        sortable: false,
        width: '25%',
      },
    ];
  }

  get user() {
    return this.appState.user;
  }

  /**
   * Open the result of the solar calculator in a new tab
   */
  openResult(item: ISolarCalculationTableItem) {
    window.open(`https://solarrechner.lynus.io/results/${item.solarcalculator_id}`);
  }

  async handleShowSolarCalculator() {
    await this.fetchWorkspaces();
    this.showSolarCalculator = !this.showSolarCalculator;
  }

  /**
   * Is triggered when the user is downloading the pdf inside the solar calculator
   */
  messageEvent(event: MessageEvent) {
    if (!event.data) return;
    // when user downloads the pdf inside the iframe we get the id we recieve the id of the new solar calculator result
    if (event.data.type === 'pdfCreated') {
      this.newSolarCalculatorId = event.data.id;
      const newSolarResult = {
        name: event.data.name,
        created_at: new Date().toISOString(),
        created_by: `${this.user.first_name} ${this.user.last_name}`,
        solarcalculator_id: this.newSolarCalculatorId,
      };
      this.solarCalculationList.push(newSolarResult);
      const workspaceCopy = { ...this.workspace };
      this.workspace.meta.solar_calculation_results = this.solarCalculationList;

      this.updateWorkspace({ project_id: this.workspace.id, workspace: workspaceCopy, showMessage: false });
      this.newSolarCalculatorId = '';
    }
  }

  getFormatedDate(date: string) {
    let currentDate: Date | string = new Date(date);
    currentDate = formatDate(currentDate);
    return currentDate;
  }

  created() {
    window.addEventListener('message', this.messageEvent, false);
    if (this.workspace.meta.solar_calculation_results) {
      this.solarCalculationList = this.workspace.meta.solar_calculation_results;
    }
  }

  beforeDestroy() {
    window.removeEventListener('message', this.messageEvent);
  }
}
