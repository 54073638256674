
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { cloneDeep } from 'lodash';
import { minMaxValidation } from '@/utils/utilsFunctions';
import { IProject } from '@/types/project.types';
import { ITenantModel } from '@/types/wizards/tenant.types';

@Component({
  methods: { minMaxValidation },
  components: {
    WizardContentView,
  },
})
export default class PaymentInformation extends mixins(Validation) {
  @Getter('projects/project') project!: IProject;
  @State('tenantWizard') tenantState!: ITenantWizardState
  @Mutation('tenantWizard/updateTenantObject') updateTenantObject!: (data: ITenantModel) => void;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;
  valid = false;

  paymentInformation: ITenantModel['general']['paymentDetails'] = {
    currency: '€',
    tax: 0,
    coldWaterTax: 0,
    serviceWaterTax: 0,
    heatingTax: 0,
    productionTax: 0,
    sideCostsTax: 0,
    coolingTax: 0,
    isTaxIncluded: false,
    bankName: '',
    iban: '',
    swift: '',
    additionalText: '',
  };

  handleNext() {
    this.updateTenant();
    this.handleIncrement();
  }

  updateTenant() {
    const tenantCopy = cloneDeep(this.tenantState.tenant);
    tenantCopy.general.paymentDetails.currency = this.paymentInformation.currency as ITenantModel['general']['paymentDetails']['currency'];
    const taxKeys = ['tax', 'coldWaterTax', 'serviceWaterTax', 'heatingTax', 'productionTax', 'sideCostsTax', 'coolingTax'];
    taxKeys.forEach((key) => {
      if (typeof (this.paymentInformation as any)[key] === 'string') {
        (this.paymentInformation as any)[key] = (this.paymentInformation as any)[key] === '' ? 0 : parseInt((this.paymentInformation as any)[key], 10);
      }
    });
    tenantCopy.general.paymentDetails = { ...this.paymentInformation };
    this.updateTenantObject(tenantCopy);
  }

  created() {
    if (!this.project.meta.wasTenantWizardCompleted) {
      this.paymentInformation.additionalText = this.$t('tenantWizard.paymentInformation.defaultText') as string;
    } else {
      this.paymentInformation = cloneDeep(this.tenantState.tenant.general.paymentDetails);
      const taxKeys = ['tax', 'coldWaterTax', 'serviceWaterTax', 'heatingTax', 'productionTax', 'sideCostsTax', 'coolingTax'];
      // if some tax is not set, set it to 0
      // this is necessary because new tax fields where added in later versions
      taxKeys.forEach((key) => {
        if (!(this.paymentInformation as any)[key]) {
          (this.paymentInformation as any)[key] = 0;
        }
      });
    }
  }

  mounted() {
    (this.$refs.form as any).validate();
  }
}
