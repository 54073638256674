
import { cloneDeep } from 'lodash';
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { Periods } from '../charts/ChartUtils';

/**
 * Component that represent periods navigation in charts
 */
@Component
export default class PeriodSelection extends Vue {
  @Prop({ default: 0 }) countLines!: number;
  @Prop({ default: 0 }) countColumns!: number;
  @Prop({ default: false }) disableNavButton!: boolean;
  @Prop({ default: Periods.DAY }) period!: string;
  @Prop({ default: null }) navigationItemsToExclude!: any; // array of ids buttons to exclude, example: ['week', 'day']
  @Prop({ default: null }) modularDefaultPeriod!: string;

  active = 2;
  defaultItems: any[] = [
    { id: 'live', langPath: 'uiComponents.chartNavigation.LIVE', data: Periods.LIVE },
    { id: 'hour', langPath: 'uiComponents.chartNavigation.HOUR', data: Periods.HOUR },
    { id: 'day', langPath: 'uiComponents.chartNavigation.DAY', data: Periods.DAY },
    { id: 'week', langPath: 'uiComponents.chartNavigation.WEEK', data: Periods.WEEK },
    { id: 'month', langPath: 'uiComponents.chartNavigation.MONTH', data: Periods.MONTH },
    { id: 'year', langPath: 'uiComponents.chartNavigation.YEAR', data: Periods.YEAR },
  ]
  items!: any[];

  @Watch('period')
  onPeriodChange() {
    this.active = this.items.map(e => e.id).indexOf(this.period);
  }

  @Watch('countColumns')
  updateVisibilityButtons() {
    if (this.items.length === 4 && this.isLineChart) {
      this.items = cloneDeep(this.defaultItems);
      this.active = 0;

      this.excludeNotWantedItems();
    }
    if (this.items.length === 5 && this.isColumnChart) {
      this.items = this.items.filter((el: any) => el.id !== 'live' || el.id !== 'hour');
      this.active = 0;

      this.excludeNotWantedItems();
    }
  }

  get isLineChart() {
    return this.countLines > 0 && this.countColumns === 0;
  }
  get isColumnChart() {
    return this.countLines === 0 && this.countColumns > 0;
  }

  onItemChange(x: string) {
    this.$emit('selected', x);
  }

  /**
   * Excludes periods from navigation
   */
  excludeNotWantedItems() {
    if (this.navigationItemsToExclude) {
      this.navigationItemsToExclude.forEach((btn: any) => {
        this.items = this.items.filter((el: any) => el.id !== btn);
      });
    }
  }

  created(): void {
    this.items = cloneDeep(this.defaultItems);

    // Disable the LiveButton for Charts with only Column Series
    if (this.countColumns) {
      const periodsToExclude: string[] = ['live', 'hour'];
      this.items = this.items.filter((el: any) => !periodsToExclude.some((per: string) => per === el.id));
      this.active = 0;
    }
    this.excludeNotWantedItems();
    if (this.modularDefaultPeriod !== null) {
      this.active = this.items.findIndex((element: any) => {
        return element.id === this.modularDefaultPeriod;
      });
    } else {
      if (this.navigationItemsToExclude !== null && !this.navigationItemsToExclude.includes('day')) {
        this.active = this.items.findIndex((element: any) => {
          return element.id === 'day';
        });
      }
    }
  }
  mounted(): void {
    this.onItemChange(this.items[this.active].data);
  }
}
