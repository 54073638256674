import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { IPartnerState } from '@/store/modules/partners/types';
import { RootState } from '@/store/types';
import PartnerService from '@/services/PartnerService';
import i18n from '@/ui/plugins/i18n';

const state: IPartnerState = {
  partners: [],
  partner: null,
};

const getters: GetterTree<IPartnerState, RootState> = {
  partners(state) {
    return state.partners;
  },
  partner(state) {
    return state.partner;
  },
  getPartnerById: (state) => (id: string) => {
    return state.partners.find((partner) => partner.id === id);
  },
};

const mutations: MutationTree<IPartnerState> = {
  setPartners(state, payload) {
    state.partners = payload;
  },
  setPartner(state, payload) {
    state.partner = payload;
  },
};

const actions: ActionTree<IPartnerState, RootState> = {
  async fetchPartners({ commit, rootGetters }) {
    try {
      const user = rootGetters['app/getUser'];
      let data;
      if (user.super_admin) {
        data = await PartnerService.fetchPartners();
        commit('setPartners', data);
      } else {
        data = await PartnerService.fetchPartnerNonAdmin();
        commit('setPartners', [data]);
        commit('setPartner', data);
      }
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  async createPartner({ commit, dispatch }, partner) {
    try {
      await PartnerService.createPartner(partner);
      dispatch('fetchPartners');
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.createPartner'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  async fetchPartner({ commit, rootGetters }, data: { id: string }) {
    try {
      const user = rootGetters['app/getUser'];
      let partner;
      if (user.super_admin) {
        partner = await PartnerService.fetchPartner(data.id);
      } else {
        partner = await PartnerService.fetchPartnerNonAdmin();
      }
      commit('setPartner', partner);
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  async updatePartner({ commit, dispatch, rootGetters }, { id, partner, showMessage }) {
    try {
      const user = rootGetters['app/getUser'];
      if (user.super_admin) {
        await PartnerService.updatePartner(id, partner);
      } else {
        await PartnerService.updatePartnerNonAdmin(id, partner);
      }
      dispatch('fetchPartners');
      if (showMessage) {
        commit('app/setReport', {
          type: 'success',
          message: i18n.t('uiComponents.reportMessages.updatePartner'),
          value: true,
        }, { root: true });
      }
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  async deletePartner({ commit, dispatch }, id) {
    try {
      await PartnerService.deletePartner(id);
      dispatch('fetchPartners');
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.deletePartner'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
};

export const partners: Module<IPartnerState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
