
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component is used in many areas of the project to give uniformity to the form fields.
 * Also performs validation if the variable value has not been filled.
 */
@Component
export default class LabelUnitWrapper extends Vue {
  @Prop({ default: true }) isDefaultView!: boolean;
  @Prop({ default: 'line' }) view!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: null }) variablesListForCheck!: any;
  @Prop({ default: false }) labelHideTextThatDoesNotFit!: boolean;
  @Prop({ default: true }) isValue!: any;
  @Prop({ default: true }) isLabel!: any;

  get isControlDisabled() {
    if (this.variablesListForCheck) return this.variablesListForCheck.some((el: string) => !el?.length);
    else return false;
  }
}
