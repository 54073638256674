import { rules } from '@/store/modules/rules';
import { variables } from '@/store/modules/variables';
import { installationWizard } from '@/store/modules/installationWizard';
import { tenantWizard } from '@/store/modules/tenantWizard';
import { navigation } from '@/store/modules/navigation';
import { projects } from '@/store/modules/projects';
import { devices } from '@/store/modules/devices';
import { members } from '@/store/modules/members';
import { rooms } from '@/store/modules/rooms';
import { measurements } from '@/store/modules/measurements';
import { charts } from '@/store/modules/charts';
import { anomalyDetection } from '@/store/modules/anomalyDetection';
import { mpc } from '@/store/modules/mpc';
import { app } from '@/store/modules/app';
import { report } from '@/store/modules/report';
import { router } from '@/store/modules/router';
import { events } from '@/store/modules/events';
import { mqttClient } from '@/store/modules/mqtt';
import { loggerWizard } from '@/store/modules/loggerWizard';
import { partners } from '@/store/modules/partners';
import { leads } from '@/store/modules/leads';
import { partnerWorkspaces } from '@/store/modules/partnerWorkspaces';
import { serialNumber } from '@/store/modules/serialNumber';

export default {
  app,
  navigation,
  projects,
  devices,
  rooms,
  measurements,
  charts,
  members,
  anomalyDetection,
  rules,
  mpc,
  report,
  router,
  events,
  mqttClient,
  variables,
  installationWizard,
  tenantWizard,
  loggerWizard,
  partners,
  leads,
  partnerWorkspaces,
  serialNumber,
};
