
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import EnergyVisualisationPreview from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyVisualisationPreview.vue';
import { batteryCapacity, BatteryType, deyeBatteryTypes, deyeTypes, inverterPowers } from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import { IProject } from '@/types/project.types';
import { IMeasurements } from '@/types/measurements.types';
import { itemsContainValue } from '@/utils/utilsFunctions';
import { plcVersionDate } from '@/utils/versionManagementUtils';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';

@Component({
  methods: { itemsContainValue },
  components: {
    InfoTooltip,
    EnergyVisualisationPreview,
  },
})
export default class InverterComponentSettings extends mixins(Validation, BasicControl) {
  @State('installationWizard') wizardState!: IInstallationWizardState;
  @Getter('projects/project') project!: IProject;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  rerenderKey = 0;

  valid = false;
  doesHover = false;

  @Watch('wizardState.inverterCount', { deep: true })
  updateValidation() {
    this.$nextTick(() => {
      this.validate();
      this.rerenderKey++;
    });
  }

  validate() {
    (this.$refs.form as any).validate();
    this.$emit('validationStatus', { system: 'battery', status: this.valid });
  }

  handleInverterQuantityChange(event: any) {
    this.$emit('changeInverterQuantity', event);
  }

  async mounted() {
    this.$nextTick(() => {
      this.validate();
    });
  }
}
