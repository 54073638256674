var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"primary notifications-settings",attrs:{"flat":""}},[(!_vm.isWizard)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"energyPrice-settings-title"},[_vm._v(" "+_vm._s(_vm.$t('uiComponents.settings.energyPrice.title'))+" ")]),_c('div',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('uiComponents.settings.energyPrice.description'))+" ")])]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-form',{ref:"form",staticClass:"general-form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"px-0 content",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"12","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.currencySelectionItems,"label":_vm.$t('modals.manageReport.textFields.currencyField'),"color":"accent","outlined":"","hide-details":"","hide-selected":"","item-value":"value","item-text":"name","rules":[
                _vm.rules.required,
                _vm.rules.noEmptyString
              ]},model:{value:(_vm.selectedCurrency),callback:function ($$v) {_vm.selectedCurrency=$$v},expression:"selectedCurrency"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"12","xs":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('uiComponents.settings.energyPrice.form.buyPrice'),"prefix":_vm.selectedCurrency === '' ? '€' : _vm.selectedCurrency,"color":"accent","outlined":"","type":"number","step":"0.01","min":"0","rules":[
                _vm.rules.required,
                _vm.fieldMoreThan(0),
                _vm.rules.twoCommas,
                _vm.rules.onlyNumbersRule,
              ]},model:{value:(_vm.energyPrice.buyPrice),callback:function ($$v) {_vm.$set(_vm.energyPrice, "buyPrice", _vm._n($$v))},expression:"energyPrice.buyPrice"}}),_c('v-text-field',{attrs:{"label":_vm.$t('uiComponents.settings.energyPrice.form.sellPrice'),"prefix":_vm.selectedCurrency === '' ? '€' : _vm.selectedCurrency,"color":"accent","outlined":"","type":"number","step":"0.01","min":"0","rules":[
                _vm.rules.required,
                _vm.fieldMoreThan(0),
                _vm.rules.twoCommas,
                _vm.rules.onlyNumbersRule,
              ]},model:{value:(_vm.energyPrice.sellPrice),callback:function ($$v) {_vm.$set(_vm.energyPrice, "sellPrice", _vm._n($$v))},expression:"energyPrice.sellPrice"}}),(!_vm.isWizard)?_c('v-btn',{staticClass:"lynus-small-btn",attrs:{"color":"accent","elevation":"0","disabled":!_vm.valid},on:{"click":_vm.handleGeneralSettings}},[_c('span',{staticClass:"btn-text"},[_vm._v(_vm._s(_vm.$t('uiComponents.buttons.save')))])]):_vm._e()],1)],1)],1)],1)],1),_c('LeaveConfirmationModal',{ref:"confirmationModal",staticStyle:{"height":"0"},attrs:{"page-about-to-leave":_vm.$t('uiComponents.settings.energyPrice.title'),"additional-information":_vm.$t('uiComponents.routeLeave.text')}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }