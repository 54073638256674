
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent rotating backlight for Preview.vue, PreviewMQTTControls.vue
 */
@Component
export default class RotatingBacklight extends Vue {
  @Prop({ default: '#dedede' }) color!: string;
  @Prop({ default: false }) noData!: boolean;

  get borderStyle() {
    return {
      borderTop: `2px solid ${this.color}`,
    };
  }
}
