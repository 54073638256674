
import { Component, Prop } from 'vue-property-decorator';
import { Utils } from '@/ui/components/devices/mpc/SetpointOptimizer/charts/utils';
import { mixins } from 'vue-class-component';
import SystemsNavigation
  from '@/ui/components/devices/mpc/SetpointOptimizer/components/SystemsNavigation.vue';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';

/**
 * Component that represent chart Room Temperatures.
 */
@Component({
  components: {
    SystemsNavigation,
    BaseChartWrapper,
  },
})
export default class ChartsRoomTemperatures extends mixins(Utils) {
  @Prop({ default: null }) systemsMappings!: any;

  reRenderKey = 0
  currentRoomTemperatureIndex = 0
  currentSystem: any = {
    system: null,
    instances: [],
  }

  get currentSystemInstanceRoomTemperatures() {
    const { system, instances } = this.currentSystem;
    if (system) {
      const instanceName = instances[0];
      const { room_temperatures } = this.systemsMappings[system].components[instanceName];
      return room_temperatures;
    }
    return null;
  }

  /**
   * Division of group systems into subgroups depending on the value of the separator.
   */
  get currentSystemInstanceRoomTemperaturesArray() {
    if (this.currentSystemInstanceRoomTemperatures) {
      return this.divisionIntoEqualParts('_', this.currentSystemInstanceRoomTemperatures, 9, true);
    }
    return null;
  }

  /**
   * Creates array of systems groups and system instances.
   * @return {array} list of system groups. Example: ['battery', [['battery1', 'battery2']]]
   */
  get systemSchema() {
    const filteredSystems = Object.entries(this.systemsMappings)
      .filter((system: any) => this.systems.some((el: any) => el === system[0] && system[1].count));
    return filteredSystems.map((el: any) => this.divisionIntoEqualParts(el[0], el[1].components, 1));
  }

  /**
   * Creates an understandable object for the graphs component
   */
  get currentChartData() {
    const array = this.currentSystemInstanceRoomTemperaturesArray[this.currentRoomTemperatureIndex];
    const chartOptions = array.map((temp: any, inx: number) => {
      // define name for option
      const getName = () => {
        const systemInstance = this.currentSystem.instances[0];
        const systemName = this.systemsMappings[this.currentSystem.system].components[systemInstance].name;
        const variableName: any = this.currentSystemInstanceRoomTemperatures[temp]?.title || this.currentSystemInstanceRoomTemperatures[temp]?.variable;
        return `${systemName}: ${variableName}`;
      };
      return {
        agg: 'last',
        name: getName(),
        scaling: {
          min: 0,
          max: 40,
        },
        seriesType: 'View',
        type: 'line',
        unit: '°C',
        var: this.currentSystemInstanceRoomTemperatures[temp].variable,
      };
    });

    return {
      title: null,
      data: {
        chartTitle: 'Chart title',
        chartWidth: null,
        chartHeight: 400,
        chartWrapperHeight: 500,
        chartData: {
          name: '',
          data: {
            chartOptions,
            selectedStackingOptions: 'normal',
            selectedWidth: 'full',
            type: 'chart',
          },
        },
        navigationItemsToExclude: ['live', 'hour'],
      },
    };
  }

  handleCurrentChart({ system, arr }: any) {
    this.currentRoomTemperatureIndex = 0;
    this.currentSystem = { system, instances: arr };
    this.reRenderKey += 1;
  }

  /**
   * Creates a name for navigation tabs.
   * @param {number} inx index of tab item
   * @return {string} navigation tab name. Example: 1-9
   */
  defineNameForRoomTemperaturesButton(inx: number) {
    const numberOfItems = this.currentSystemInstanceRoomTemperaturesArray[inx].length;
    const first = inx * 9 + 1;
    const last = first + numberOfItems - 1;
    return `${first}${last === first ? '' : `-${last}`}`;
  }

  handleRoomTemperature(inx: number) {
    this.currentRoomTemperatureIndex = inx;
  }

  mounted() {
    this.currentSystem = this.initFirstSystemByDefault(this.systemSchema);
  }
}
