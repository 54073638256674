const navigation = require('./navigation.json');
const uiComponents = require('./uiComponents.json');
const devicesDescriptions = require('./devicesDescriptions.json');
const basicControls = require('./basicControls.json');
const devices = require('./devices.json');
const mlModelsDescriptions = require('./mlModelsDescriptions.json');
const mlModel = require('./mlModel.json');
const anomalyDetectionDescriptions = require('./anomalyDetectionDescriptions.json');
const chartsDescription = require('./chartsDescription.json');
const modals = require('./modals.json');
const userManual = require('./userManual.json');
const validationRules = require('./validationRules.json');
const anomalyDetection = require('./anomalyDetection.json');
const errorMessages = require('./errorMessages.json');
const installationWizard = require('./installationWizard.json');
const tenantWizard = require('./tenantWizard.json');
const loggerWizard = require('./loggerWizard.json');
const partners = require('./partners.json');

export default {
  navigation,
  uiComponents,
  devicesDescriptions,
  basicControls,
  devices,
  mlModelsDescriptions,
  anomalyDetectionDescriptions,
  mlModel,
  modals,
  chartsDescription,
  userManual,
  validationRules,
  anomalyDetection,
  errorMessages,
  installationWizard,
  tenantWizard,
  loggerWizard,
  partners,
};
