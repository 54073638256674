
import { Component, Vue } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Action, Getter } from 'vuex-class';
import { IProject } from '@/types/project.types';
import { cloneDeep } from 'lodash';
import { IIncludedSystemsTypesLogger, ILoggerInformation } from '@/types/wizards/loggerWizard.types';

@Component({
  components: {
    WizardContentView,
  },
})
export default class FinalLoggerPage extends Vue {
  @Getter('projects/project') project!: IProject;
  @Getter('loggerWizard/loggerInformation') getLoggerInformation !: ILoggerInformation[];
  @Getter('loggerWizard/includedSystemsTypes') includedSystemsTypes !: IIncludedSystemsTypesLogger;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;

  localProject: IProject | null = null;
  loggerInformationForBackend: {id: string; type: string}[] = [];

  created() {
    this.loggerInformationForBackend = this.getLoggerInformation.map((logger: ILoggerInformation) => {
      return {
        id: logger.id,
        type: logger.type,
      };
    });
  }

  async handleNext() {
    this.localProject = cloneDeep(this.project);

    const solarmanLoggerInfoObject = {
      battery: {
        definition: this.includedSystemsTypes.battery.definition,
      },
      generator: {
        definition: this.includedSystemsTypes.generator.definition,
      },
    };
    await this.updateProject({
      ...this.localProject,
      meta: {
        ...this.localProject.meta,
        wizard_completed_at: new Date().toISOString(),
        wasInstallationWizardCompleted: true,
        loggerInformation: this.loggerInformationForBackend,
        solarmanLoggerInfo: solarmanLoggerInfoObject,
      },
    });
    this.$router.push(`/projects/${this.$route.params.id}/favorites`);
  }
}
