
import { Vue, Component, Prop } from 'vue-property-decorator';
import PlusBase from '@/ui/components/devices/devices/base/PlusBase.vue';
import MinusBase from '@/ui/components/devices/devices/base/MinusBase.vue';
import SwitchBase from '@/ui/components/devices/devices/base/SwitchBase.vue';
import PlayBase from '@/ui/components/devices/devices/base/PlayBase.vue';
import StopBase from '@/ui/components/devices/devices/base/StopBase.vue';
import PauseBase from '@/ui/components/devices/devices/base/PauseBase.vue';
import ReverseBase from '@/ui/components/devices/devices/base/ReverseBase.vue';
import ForwardBase from '@/ui/components/devices/devices/base/ForwardBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent MusicSystem device
 */
@Component({
  components: {
    PlusBase,
    MinusBase,
    SwitchBase,
    PlayBase,
    StopBase,
    PauseBase,
    ReverseBase,
    ForwardBase,
    DeviceLayout,
  },
})
export default class MusicSystem extends Vue {
  @Prop() deviceData!: IDevice;
}
