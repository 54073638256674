
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseChart from '@/ui/components/devices/charts/charts/BaseChart.vue';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { Getter } from 'vuex-class';
import { ChartData } from '@/types/chart.types';

@Component({
  components: {
    BaseChart,
    InfoTooltip,
  },
})
export default class AnomalyDetection extends Vue {
  @Prop() deviceData!: ChartData;
  @Prop() chartWidth!: number;
  @Getter('measurements/measurementsKeys') measurementsKeys!: any;

  showAnomalyTooltip = false;

  created() {
    // we are having an anomaly chart -> add anomaly score series, if it has data. otherwise show info tooltip.
    const newList = this.deviceData.data.chartOptions.filter((element: any) => element.name !== 'Anomaly Score');
    const localName: any = this.$t('modals.manageAnomalyDetection.form.anomalyScore');
    const anomalyScoreVariable = `AN.${this.deviceData?.id?.replace(/-/g, '_')}.score`;

    if (this.measurementsKeys.includes(anomalyScoreVariable)) {
      newList.push({
        agg: 'avg',
        name: localName,
        scaling: {
          min: 0,
          max: 100,
        },
        seriesType: 'AnomalyScore',
        type: 'line',
        unit: '%',
        var: anomalyScoreVariable,
      });
    } else {
      this.showAnomalyTooltip = true;
    }
    this.deviceData.data.chartOptions = newList;
  }
}
