
import { Vue, Component } from 'vue-property-decorator';
import DeviceDescription from '@/ui/components/devices/actions/DeviceDescription.vue';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import PreviewActions from '@/ui/components/devices/actions/PreviewActions.vue';
import SliderMock from '@/ui/components/devices/previews/components/SliderMock.vue';
import SettingsView from '@/ui/components/devices/components/SettingsView.vue';
import { envThemes } from '@/utils/env';

@Component({
  components: {
    SettingsView,
    SliderMock,
    DeviceDescription,
    PreviewActions,
    SwitchMock,
  },
})
export default class RobotPreview extends Vue {
  statePosition = 'Test';
  redColorVar = 'red';
  focValue = 20;
  speedValue = 20;
  isSettingsView = false;
  // value of DateTimePicker
  dateValue: any = null;
  // used for the accent color in the DateTimePicker
  letAccentColorForCalendar = this.$vuetify.theme.dark ? envThemes.dark.accent : envThemes.light.accent;
  disableSendButton = true;
  alignRight=true
  itemsTypes = [
    { name: 'Muster 1', value: 0 },
    { name: 'Muster 2', value: 1 },
  ];
  selected = 0;

  get description() {
    return this.$t('devicesDescriptions.Robot');
  }
  get previewNameLang() {
    return this.$t('devices.Robot.previewName');
  }

  switchSettingsView(setting: boolean) {
    this.isSettingsView = setting;
  }
}
