
import { Vue, Component, Prop } from 'vue-property-decorator';
import DeviceActions from '@/ui/components/devices/actions/DeviceActions.vue';
import SettingsView from '@/ui/components/devices/components/SettingsView.vue';
import ActualView from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/ActualView/index.vue';
import EnergyFlowView from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyFlowView/index.vue';
import EMSCharts from '@/ui/components/devices/devices/EMS/components/charts/index.vue';
import SettingsEMS from '@/ui/components/devices/devices/EMS/components/SettingsEMS.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';
import { getSystems, isHybridEnergyDeviceSystem } from '@/utils/mpcUtils';
import { IProject } from '@/types/project.types';
import { Getter } from 'vuex-class';

/**
 * Component that represent EMS
 */
@Component({
  components: {
    EnergyFlowView,
    DeviceActions,
    SettingsView,
    ActualView,
    EMSCharts,
    SettingsEMS,
    CircleSpinner,
    MPCLayout,
  },
})
export default class EMSV2 extends Vue {
  @Prop() deviceData!: any;
  @Getter('projects/project') project!: IProject;

  isSettingsView = false
  loaded = true;

  /**
   * Gets all systems from EMS controllerMappings
   * @return {object|null} collection of systems
   */
  get allSystems() {
    if (this.deviceData?.data?.meta?.controllerMappings) {
      const {
        pv,
        generator,
        grid,
        battery,
        house,
        charge_station,
        electric_heating,
        heating_pump,
        big_consumer,
      } = this.deviceData.data.meta.controllerMappings;
      return {
        pv,
        generator,
        battery,
        grid,
        house,
        charge_station,
        electric_heating,
        heating_pump,
        big_consumer,
      };
    } else {
      return null;
    }
  }

  get prediction() {
    if (this.deviceData?.data?.meta?.charts?.prediction) return this.deviceData.data.meta.charts.prediction;
    else return null;
  }

  get predictedPower() {
    if (this.prediction) {
      return getSystems(this.prediction, '_power');
    } else return null;
  }

  get predictedTargetPower() {
    if (this.prediction) {
      return getSystems(this.prediction, '_target_power');
    } else return null;
  }

  get predictedEnergy() {
    if (this.prediction) {
      return getSystems(this.prediction, '_energy');
    } else return null;
  }

  get predictedSOC() {
    if (this.prediction) {
      return getSystems(this.prediction, '_soc');
    } else return null;
  }

  get emsScaling() {
    return this.deviceData?.data?.meta?.scaling;
  }

  /**
   * Convert MPC id from "id-id-id" to "id_id_id"
   */
  get mpcIdMod() {
    if (this.deviceData.id) return this.deviceData.id.replace(/-/g, '_');
    return null;
  }

  get showEventGeneralErrorWarning() {
    return {
      'ShowEvent_errorWarningState': this.deviceData?.data?.meta?.controllerMappings?.errorWarning,
    };
  }

  /**
   * Checks if a system is a combination of the Lynus and Solarman Logger System
   */
  get isHybridSystem() {
    return isHybridEnergyDeviceSystem(this.deviceData, this.project);
  }

  get displayEnergyFlowHouseInfo() {
    const houseCount = this.deviceData.data.meta.controllerMappings.house.count ?? 0;
    return this.project.meta.wasTenantWizardCompleted === true && this.project.meta.hasLynusInverter === true && houseCount > 1;
  }

  switchSettingsView(setting: boolean) {
    this.isSettingsView = setting;
  }

  async toggleLoading(status: boolean) {
    this.loaded = !status;
  }
}
