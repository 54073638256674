
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import HourPicker from '@/ui/components/devices/charts/components/HourPicker.vue';
import DefaultCalendar from '@/ui/components/devices/charts/components/DefaultCalendar.vue';
import { formatDate, toSecondsSinceEpoch } from '@/utils/utilsFunctions';
import moment from 'moment';

@Component({
  components: {
    HourPicker,
    DefaultCalendar,
  },
})
export default class MultiSelectionCalendar extends Vue {
  @Prop({ default: null }) periodName!: any;
  @Prop({ default: null }) calendarMin!: any;
  @Prop({ default: null }) calendarMax!: any;

  readonly dateSelectionLimit = 4;
  date: any = null;
  dates: Date[] = [];

  @Watch('dates')
  onDateChange() {
    this.$emit('input', this.datesInS);
    this.date = undefined;
  }

  addCurrentDate() {
    if (this.date) {
      const date = new Date(
        (this.date instanceof Array
          ? this.date[0]
          : this.date) * 1000,
      );

      // check if date not already contained in list
      if (this.dates.find(d => d.getTime() === date.getTime()) === undefined) {
        // date not contained -> add
        this.dates.push(date);
      }
    }
    // reset date+time on hour picker if it is defined
    (this.$refs.hourPicker as any)?.reset();
  }

  get formattedDates(): string[] {
    return this.dates.map(d => {
      let format;
      switch (this.periodName) {
        case 'hour': format = 'HH:mm DD.MM.yyyy'; break;
        case 'month': format = 'MM.yyyy'; break;
        case 'year': format = 'yyyy'; break;
      }

      if (this.periodName === 'week') {
        // show "week_start - week_end"
        return `${formatDate(d)} - ${formatDate(moment(d).isoWeekday(7).toDate())}`;
      } else {
        return formatDate(d, format);
      }
    });
  }

  get datesInS(): number[] {
    return this.dates.map(d => toSecondsSinceEpoch(d) ?? 0);
  }

  handleRemoval(index: number) {
    this.dates.splice(index, 1);
  }
}
