
import { Component, Prop } from 'vue-property-decorator';
import ArrowUpBase from '@/ui/components/devices/devices/base/ArrowUpBase.vue';
import ArrowDownBase from '@/ui/components/devices/devices/base/ArrowDownBase.vue';
import SliderBase from '@/ui/components/devices/devices/base/SliderBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import ShowEventBase from '@/ui/components/devices/devices/base/ShowEventBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent ControlShutter device
 */
@Component({
  components: {
    ArrowUpBase,
    ArrowDownBase,
    SliderBase,
    OutputFieldBase,
    PushButtonBase,
    InputFieldBase,
    ShowEventBase,
    DeviceLayout,
    LabelUnitWrapper,
  },
})
export default class ControlShutter extends mixins(Validation) {
  @Prop() deviceData!: IDevice;
  @State('measurements') measurementsState!: IVariablesState;

  get langPath() {
    return `devices.${this.deviceData.data.type}`;
  }

  get measurements() {
    return this.measurementsState.measurements;
  }
  get sliderActualValue() {
    return this.measurements.get(this.deviceData.data.mappings.OutputField_actualValue);
  }
}
