import { CirclePosition } from './EnergyCircleDisplayData';

export enum EnergyCircleType {
  // EMS, EnergyView
  pv = 'pv',
  chp = 'chp',
  generator = 'generator',
  battery = 'battery',
  grid = 'grid',
  house = 'house',
  charge_station = 'charge_station',
  electric_heating = 'electric_heating',
  heating_pump = 'heating_pump',
  big_consumer = 'big_consumer',
  // setpoint optimizer
  heating_air_systems = 'heating_air_systems',
  heating_water_systems = 'heating_water_systems',
  cooling_air_systems = 'cooling_air_systems',
  cooling_water_systems = 'cooling_water_systems',
  hybrid_water_systems = 'hybrid_water_systems',
  hybrid_air_systems = 'hybrid_air_systems'
}

export enum IOEnergyCircleType {
  // EnergyIO
  inputs = 'inputs',
  outputs = 'outputs',
  hybrids = 'hybrids',
}

export enum EnergyDevicesCircleTypes {
  // EMS, EnergyView
  pv = 'pv',
  chp = 'chp',
  generator = 'generator',
  battery = 'battery',
  grid = 'grid',
  house = 'house',
  charge_station = 'charge_station',
  electric_heating = 'electric_heating',
  heating_pump = 'heating_pump',
  big_consumer = 'big_consumer',
}

export const allEnergyCircleTypes: EnergyCircleType[] = Object.values(EnergyCircleType);
export const allIOEnergyCircleTypes: IOEnergyCircleType[] = Object.values(IOEnergyCircleType);
export const allEnergyDevicesCircleTypes: EnergyDevicesCircleTypes[] = Object.values(EnergyDevicesCircleTypes);

export const positionMappings: Record<EnergyCircleType | IOEnergyCircleType, CirclePosition> = {
  // EMS, EnergyView
  [EnergyCircleType.pv]: CirclePosition.top,
  [EnergyCircleType.chp]: CirclePosition.top,
  [IOEnergyCircleType.inputs]: CirclePosition.top,
  [EnergyCircleType.generator]: CirclePosition.top,
  [EnergyCircleType.battery]: CirclePosition.middleLeft,
  [IOEnergyCircleType.hybrids]: CirclePosition.middleLeft,
  [EnergyCircleType.grid]: CirclePosition.middleRight,
  [IOEnergyCircleType.outputs]: CirclePosition.bottom,
  [EnergyCircleType.house]: CirclePosition.bottom,
  [EnergyCircleType.charge_station]: CirclePosition.bottom,
  [EnergyCircleType.electric_heating]: CirclePosition.bottom,
  [EnergyCircleType.heating_pump]: CirclePosition.bottom,
  [EnergyCircleType.big_consumer]: CirclePosition.bottom,
  // setpoint optimizer
  [EnergyCircleType.heating_water_systems]: CirclePosition.top,
  [EnergyCircleType.heating_air_systems]: CirclePosition.top,
  [EnergyCircleType.hybrid_water_systems]: CirclePosition.middleRight,
  [EnergyCircleType.hybrid_air_systems]: CirclePosition.middleLeft,
  [EnergyCircleType.cooling_air_systems]: CirclePosition.bottom,
  [EnergyCircleType.cooling_water_systems]: CirclePosition.bottom,
};

export const isProducer = (type: EnergyCircleType): boolean => positionMappings[type] === CirclePosition.top;
export const isHybrid = (type: EnergyCircleType): boolean => positionMappings[type] === CirclePosition.middleLeft || positionMappings[type] === CirclePosition.middleRight;
export const isConsumer = (type: EnergyCircleType): boolean => positionMappings[type] === CirclePosition.bottom;
export const getCirclePositionForType = (type: EnergyCircleType | IOEnergyCircleType): CirclePosition => positionMappings[type];
export const producers = allEnergyCircleTypes.filter(isProducer);
export const consumers = allEnergyCircleTypes.filter(isConsumer);
export const hybrids = allEnergyCircleTypes.filter(isHybrid);

export const iconMappings: Record<EnergyCircleType, string> = {
  // EMS, EnergyView
  [EnergyCircleType.pv]: 'photovoltaic',
  [EnergyCircleType.chp]: 'chp_xrgi',
  [EnergyCircleType.battery]: 'battery',
  [EnergyCircleType.grid]: 'main-connection',
  [EnergyCircleType.generator]: 'generator',
  [EnergyCircleType.house]: 'house-consumption',
  [EnergyCircleType.charge_station]: 'electric-charging-station',
  [EnergyCircleType.electric_heating]: 'electric-charging-boiler',
  [EnergyCircleType.heating_pump]: 'heating-capacity',
  [EnergyCircleType.big_consumer]: 'socket-switch',
  // setpoint optimizer
  [EnergyCircleType.heating_air_systems]: 'heating_air',
  [EnergyCircleType.heating_water_systems]: 'hot-water',
  [EnergyCircleType.cooling_air_systems]: 'cooling_air',
  [EnergyCircleType.cooling_water_systems]: 'cold-water',
  [EnergyCircleType.hybrid_water_systems]: 'hybrid_water',
  [EnergyCircleType.hybrid_air_systems]: 'hybrid_air',
};
export const energyCircleIconForType = (type: EnergyCircleType) => iconMappings[type];

/**
 * Systems that show alternative values, if the actual power variables are not
 * mapped.
 */
export const systemsWithAlternativeValues = [
  EnergyCircleType.charge_station,
  EnergyCircleType.heating_pump,
  EnergyCircleType.electric_heating,
  EnergyCircleType.big_consumer,
];
export const isSystemWithAlternativeValue = (type: EnergyCircleType) => systemsWithAlternativeValues.includes(type);
