
import { Component } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import EnergyVisualisationPreview
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyVisualisationPreview.vue';
import {
  allEnergyCircleTypes,
} from '@/types/energyVisualisation/EnergyCircleType';
import { cloneDeep } from 'lodash';
import BatteryComponentsSettings
  from '@/ui/components/wizards/installationWizard/steps/defineComponents/singleSystems/BatteryComponentSettings.vue';
import { IProject } from '@/types/project.types';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { IDevice } from '@/types/devices.types';
import {
  IIncludedSystemsBatteryDefinition,
  NavigationDirection,
} from '@/types/wizards/installationWizard.types';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';
import { IIncludedSystemsTypesLogger, ILoggerInformation, ILoggerBatteryDefinition, ILoggerPvDefinition, ILoggerAcPvDefinition, ILoggerGeneratorDefinition } from '@/types/wizards/loggerWizard.types';
import { IloggerWizardState } from '@/store/modules/loggerWizard/types';
import { plcVersionDate } from '@/utils/versionManagementUtils';
import { batteryCapacity, LoggerType, batteryOptions } from '../../wizardSettings/systemTypes';
import { gridMappingEnergyView, houseMappingEnergyView, pvMappingEnergyView, batteryMappingEnergyView, generatorMappingEnergyView } from '../../wizardSettings/defaultMappings';

@Component({
  components: {
    CircleSpinner,
    InfoTooltip,
    EnergyVisualisationPreview,
    WizardContentView,
    BatteryComponentsSettings,
  },
})
export default class LoggerComponentsPage extends mixins(Validation) {
  @State('loggerWizard') wizardState!: IloggerWizardState;
  @Getter('projects/batterySystemType') batterySystemType!: LoggerType | undefined;
  @Getter('loggerWizard/loggerInformation') getLoggerInformation !: ILoggerInformation[];
  @Getter('loggerWizard/hasGridMeasurement') hasGridMeasurement!: boolean;
  @Getter('loggerWizard/emsDevice') emsDevice!: IDevice;
  @Getter('projects/project') project!: IProject;
  @Getter('loggerWizard/producerOptionsEnabled') producerOptionsEnabled!: boolean;
  @Getter('loggerWizard/consumerOptionsEnabled') consumerOptionsEnabled!: boolean;
  @Getter('loggerWizard/emsLimits') emsLimits!: Record<string, number>;
  @Getter('loggerWizard/wasComponentsPageDone') wasComponentsPageDone!: boolean;
  @Getter('loggerWizard/navigationDirection') navigationDirection!: NavigationDirection;
  @Getter('loggerWizard/wasLoggerWizardCompleted') wasLoggerWizardCompleted!: boolean;
  @Getter('loggerWizard/energyViewDevice') energyViewDevice!: IDevice;
  @Mutation('loggerWizard/setWasComponentsPageDone') setWasComponentsPageDone!: () => void;
  @Mutation('loggerWizard/updateEnergyViewDeviceData') updateEnergyViewDeviceData!: (payload: Partial<IDevice>) => void;
  @Mutation('loggerWizard/setIncludedSystemsTypes') setIncludedSystemsTypes!: (payload: IIncludedSystemsTypesLogger) => void;
  @Action('loggerWizard/handleIncrement') handleIncrement!: () => void;
  @Action('loggerWizard/handleDecrement') handleDecrement!: () => void;
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => Promise<IDevice>;

  localbatteryOptions = batteryOptions;
  localBatteryCapacity: any = batteryCapacity;
  doesHover = false;
  valid = false;
  rerenderKey = 0;
  systems: any = {};
  dataLoaded = false;
  localIncludedSystemsTypes: IIncludedSystemsTypesLogger = {
    battery: {
      isSelected: false,
      count: 0,
      definition: [],
      systems: [],
    },
    pv: {
      isSelected: false,
      count: 0,
      definition: [],
    },
    generator: {
      isSelected: false,
      count: 0,
      definition: [],
    },
    acPV: {
      isSelected: false,
      count: 0,
      definition: [],
    },
  };

  get generatorPreviewInfo() {
    return { state: this.localIncludedSystemsTypes.generator.isSelected, count: this.localIncludedSystemsTypes.generator.definition.length };
  }
  /**
   * returns the Energy View device based on the selected systems inside the wizard
   * for pv systems it will also add the ac pv system if its selected and add it to the pv count
   */
  get energyViewForPreview() {
    const deviceObject = cloneDeep(this.energyViewDevice);
    // if grid is selected we add it to the mappings so it will be shown inside the preview
    deviceObject.data.meta.controllerMappings.grid.count = this.hasGridMeasurement ? 1 : 0;
    // if generator is selected we add it to the mappings so it will be shown inside the preview
    deviceObject.data.meta.controllerMappings.generator.count = this.generatorPreviewInfo.state ? this.generatorPreviewInfo.count : 0;
    // contains count of all pv systems (pv + acpv)
    const pvCount = this.localIncludedSystemsTypes.pv.definition.length + this.localIncludedSystemsTypes.acPV.definition.length;
    deviceObject.data.meta.controllerMappings.pv.count = pvCount;
    // if battery is selected we add it to the mappings so it will be shown inside the preview
    deviceObject.data.meta.controllerMappings.battery.count = this.localIncludedSystemsTypes.battery.definition.length;
    return deviceObject;
  }
  /**
   * Returns the logger overall type based on the selected systems
   * if all selected systems are string the overall type will be string
   * if all selected systems are hybrid the overall type will be hybrid
   * if the selected systems are mixed the overall type will be mixed
  */
  get loggerOverallType() {
    if (this.getLoggerInformation.every((logger: ILoggerInformation) => logger.type === 'hybrid')) {
      return 'hybrid';
    }
    if (this.getLoggerInformation.every((logger: ILoggerInformation) => logger.type === 'string')) {
      return 'string';
    }
    return 'mixed';
  }
  get includedSystemsTypes() {
    return this.wizardState.includedSystemsTypes;
  }
  get projectBatteryType() {
    // contains type of system that's selected inside the project settings
    const { batterySystemType } = this.project?.meta?.controller;
    return batterySystemType === undefined ? '' : batterySystemType;
  }

  /**
   * Returns Battery items filtered by System Type and availableFrom date
   */
  getBatteryItems(system: IIncludedSystemsBatteryDefinition) {
    if (!system.systemType) return [];
    const batteryCapacityItems = this.getBatteryCapacityItems(system);
    return batteryCapacityItems.filter((capacityElement: { name: string; value: string; availableFrom: Date }) => capacityElement.availableFrom.getTime() < plcVersionDate(this.project).getTime());
  }
  batteryItemValue(system: IIncludedSystemsBatteryDefinition) {
    return this.getBatteryItems(system).map((capacityElement: { name: string; value: string; availableFrom: Date }) => capacityElement.value);
  }
  getBatteryCapacityItems(system: IIncludedSystemsBatteryDefinition) {
    return system.batteryType
      ? this.localBatteryCapacity[system.systemType][system.batteryType]
      : [];
  }
  /**
   * Updates the battery definition based on user input
   * @param index index of the battery definition
   * @param key key of the battery definition
   */
  handleBatteryChange(index: number, key: string, value: string) {
    this.localIncludedSystemsTypes.battery.definition[index][key as keyof ILoggerBatteryDefinition] = value;
    // needed to so changes are detected
    this.localIncludedSystemsTypes = cloneDeep(this.localIncludedSystemsTypes);
    // trigger validation
    this.$nextTick(() => {
      this.validate();
    });
  }

  handleRerender() {
    this.rerenderKey++;
  }
  validate() {
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  /**
   * Initializes the systems object with the min and max values and the includedSystemsTypesKey
   * Each system that is added here will be shown inside the template
   */
  initSystemRow() {
    this.systems = {
      pv: {
        min: 0,
        max: this.emsLimits.pv,
        includedSystemsTypesKey: 'pv',
      },
      battery: {
        min: 1,
        max: this.emsLimits.battery,
        includedSystemsTypesKey: 'battery',
      },
      generator: {
        min: 0,
        max: this.emsLimits.generator,
        includedSystemsTypesKey: 'generator',
      },
      acPV: {
        min: 0,
        max: this.emsLimits.acPV,
        includedSystemsTypesKey: 'acPV',
      },
    };
  }

  /**
   * Fills the included systems types with the selected systems from the previous page
   */
  fillIncludedSystemTypes() {
    // if component page was already done we dont need to fill the included systems types because they are already filled
    if (this.navigationDirection === NavigationDirection.backward) return;
    this.getLoggerInformation.forEach((logger: { id: string; hasPv: boolean; hasBattery: boolean; selectedProducer: string }) => {
      // if logger has PV selected we add it to the included systems types
      if (logger.hasPv) {
        this.localIncludedSystemsTypes.pv.isSelected = true;
        this.localIncludedSystemsTypes.pv.count++;
        this.localIncludedSystemsTypes.pv.definition.push({
          loggerName: logger.id,
          isSelected: true,
        });
      }
      // if logger has battery selected we add it to the included systems types
      if (logger.hasBattery) {
        this.localIncludedSystemsTypes.battery.isSelected = true;
        this.localIncludedSystemsTypes.battery.count++;
        this.localIncludedSystemsTypes.battery.definition.push({
          loggerName: logger.id,
          systemType: this.projectBatteryType,
          batteryType: '',
          batteryCapacity: '',
        });
      }
      // if logger has generator selected we add it to the included systems types
      if (logger.selectedProducer !== '') {
        if (logger.selectedProducer === 'generator') {
          this.localIncludedSystemsTypes.generator.isSelected = true;
          this.localIncludedSystemsTypes.generator.count++;
          this.localIncludedSystemsTypes.generator.definition.push({
            loggerName: logger.id,
            power: 0,
          });
        }
        // if logger has acPV selected we add it to the included systems types
        if (logger.selectedProducer === 'acpv') {
          this.localIncludedSystemsTypes.acPV.isSelected = true;
          this.localIncludedSystemsTypes.acPV.count++;
          this.localIncludedSystemsTypes.acPV.definition.push({
            loggerName: logger.id,
            isSelected: true,
          });
        }
      }
    });

    // fill battery & generator definition with information from solarman logger info
    // PV and AC PV are not needed because the dont have any settings
    const solarmanLoggerInfo = this.project.meta?.solarmanLoggerInfo;
    if (this.project.meta?.solarmanLoggerInfo && this.project.meta?.wasInstallationWizardCompleted) {
      this.localIncludedSystemsTypes.battery.definition.forEach((element: ILoggerBatteryDefinition, index: number) => {
        const infoElement = solarmanLoggerInfo?.battery.definition.find((info: ILoggerBatteryDefinition) => info.loggerName === element.loggerName);
        if (infoElement !== undefined) {
          this.localIncludedSystemsTypes.battery.definition[index] = infoElement;
        }
      });
      this.localIncludedSystemsTypes.generator.definition.forEach((element: ILoggerGeneratorDefinition, index: number) => {
        const infoElement = solarmanLoggerInfo?.generator.definition.find((info: ILoggerGeneratorDefinition) => info.loggerName === element.loggerName);
        if (infoElement !== undefined) {
          this.localIncludedSystemsTypes.generator.definition[index] = infoElement;
        }
      });
    }
  }

  /**
   * Fills the included systems types with the selected systems from the previous page
   */
  fillOnNavigationBackward() {
    this.localIncludedSystemsTypes = cloneDeep(this.includedSystemsTypes);
  }

  async created() {
    this.initSystemRow();
    if (this.navigationDirection === NavigationDirection.backward) {
      // fill included systems types with selected systems from previous page when navigating backwards
      this.fillOnNavigationBackward();
    } else {
      // fill included systems types with selected systems from previous page
      this.fillIncludedSystemTypes();
    }
    // All needed data available
    this.dataLoaded = true;
  }
  async mounted() {
    // trigger inital validation
    await this.$nextTick();
    this.validate();
  }

  /**
   * Clears all counts and components of the energy view mappings so we can fill them with the user selected systems
   */
  clearEnergyDevices(mappings: any) {
    allEnergyCircleTypes.forEach((systemType: string) => {
      if (mappings[systemType] === undefined) {
        return;
      }
      mappings[systemType].count = 0;
      mappings[systemType].components = {};
    });
    return mappings;
  }

  fillEnergyViewControllerMappings() {
    let energyViewMappings = cloneDeep(this.energyViewDevice.data.meta.controllerMappings);

    // clear all energy devices
    energyViewMappings = this.clearEnergyDevices(energyViewMappings);

    // House is default 1
    // If has grid measurement was selected on previous page we add the grid to the mappings
    const houseMapping = houseMappingEnergyView();
    const gridMapping = gridMappingEnergyView();
    // based on the logger overall type we add suffix to the mappings
    if (this.loggerOverallType === 'string' || this.loggerOverallType === 'hybrid') {
      // house
      houseMapping.energy_counter = houseMapping.energy_counter.concat(`.${this.loggerOverallType}`);
      houseMapping.power = houseMapping.power.concat(`.${this.loggerOverallType}`);
      // grid
      gridMapping.energy_counter = gridMapping.energy_counter.concat(`.${this.loggerOverallType}`);
      gridMapping.reverse_energy_counter = gridMapping.reverse_energy_counter.concat(`.${this.loggerOverallType}`);
      gridMapping.power = gridMapping.power.concat(`.${this.loggerOverallType}`);
    } else {
      // house
      houseMapping.energy_counter = houseMapping.energy_counter.concat('.string');
      houseMapping.power = houseMapping.power.concat('.hybrid');
      // grid
      gridMapping.energy_counter = gridMapping.energy_counter.concat('.string');
      gridMapping.reverse_energy_counter = gridMapping.reverse_energy_counter.concat('.string');
      gridMapping.power = gridMapping.power.concat('.hybrid');
    }
    energyViewMappings.house.count = 1;
    energyViewMappings.house.components.house1 = houseMapping;
    if (this.hasGridMeasurement) {
      energyViewMappings.grid.count = 1;
      energyViewMappings.grid.components.grid1 = gridMapping;
    }

    const keysToFill = ['pv', 'acPV', 'battery', 'generator'];
    // fill controller mappings of energy view
    keysToFill.forEach((systemKey: string) => {
      this.localIncludedSystemsTypes[systemKey as keyof IIncludedSystemsTypesLogger].definition.forEach(
        (systemDefinition: ILoggerBatteryDefinition | ILoggerPvDefinition | ILoggerAcPvDefinition | ILoggerGeneratorDefinition) => {
          const loggerIndex = this.getLoggerInformation.findIndex((logger: ILoggerInformation) => logger.id === systemDefinition.loggerName);
          switch (systemKey) {
            case 'pv': {
              // add pv to pv mappings
              const currentMappingIndex = energyViewMappings[systemKey].count + 1;
              energyViewMappings.pv.components[`pv${currentMappingIndex}`] = pvMappingEnergyView(systemDefinition.loggerName, false, loggerIndex);
              energyViewMappings[systemKey].count++;
              break;
            }
            case 'acPV': {
              // add acpv to pv mappings
              const currentMappingIndex = energyViewMappings.pv.count + 1;
              energyViewMappings.pv.components[`pv${currentMappingIndex}`] = pvMappingEnergyView(systemDefinition.loggerName, true, loggerIndex);
              energyViewMappings.pv.count++;
              break;
            }
            case 'battery': {
              // add battery to battery mappings
              const currentMappingIndex = energyViewMappings[systemKey].count + 1;
              energyViewMappings.battery.components[`battery${currentMappingIndex}`] = batteryMappingEnergyView(systemDefinition.loggerName, loggerIndex);
              energyViewMappings[systemKey].count++;
              break;
            }
            case 'generator': {
              // add generator to generator mappings
              const currentMappingIndex = energyViewMappings[systemKey].count + 1;
              energyViewMappings.generator.components[`generator${currentMappingIndex}`] = generatorMappingEnergyView(systemDefinition.loggerName, loggerIndex);
              energyViewMappings[systemKey].count++;
              break;
            }
          }
        },
      );
    });
    this.updateEnergyViewDeviceData({ data: { meta: { controllerMappings: energyViewMappings } } });
  }

  async handleNext() {
    this.fillEnergyViewControllerMappings();
    if (this.energyViewDevice.id) await this.updateDevice({ device: this.energyViewDevice, skipReport: false });
    this.setIncludedSystemsTypes(this.localIncludedSystemsTypes);
    this.setWasComponentsPageDone();
    this.handleIncrement();
  }

  handleBack() {
    this.handleDecrement();
  }
}
