
import { Vue, Component } from 'vue-property-decorator';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';
import MainView from '@/ui/components/devices/previews/mpc/SetpointOptimizer/MainView.vue';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';
import Charts from '@/ui/components/devices/previews/mpc/SetpointOptimizer/Charts.vue';

@Component({
  components: {
    MPCLayout,
    MainView,
    ShowEventDotMock,
    Charts,
  },
})
export default class SetpointOptimizer extends Vue {
  get previewNameLang() {
    return this.$t('mlModel.SetpointOptimizer.previewName');
  }
}
