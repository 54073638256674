
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

/**
 * Webhook action component.
 * Manages webhook.
 */
@Component
export default class WebhookAction extends Vue {
  @Prop({ default: {} }) params: any;
  @Prop({ default: false }) disableBody?: boolean;

  headers: any = []

  get methodItems() {
    return this.disableBody
      ? ['POST', 'PUT', 'DELETE', 'GET', 'PATCH']
      : ['POST', 'PUT', 'DELETE', 'PATCH'];
  }

  @Watch('headers', { deep: true })
  onHeadersChange() {
    this.params.headers = this.headers.reduce((acc: any, cur: any) => {
      acc[cur.key] = cur.value;
      return acc;
    }, {});
  }

  addHeader() {
    this.headers.push({ key: '', value: '' });
  }
  removeHeader(index: number | string) {
    this.headers.splice(Number(index), 1);
  }

  created() {
    this.headers = Object.keys(this.params.headers).map(k => ({ key: k, value: this.params.headers[k] }));
  }
}
