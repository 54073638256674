import { toSeconds } from '@/utils/utilsFunctions';

export function isDot(method) {
  return method === '*' || method === '/';
}

export function isVariable(calculationElement) {
  return calculationElement.agg !== undefined && calculationElement.agg !== null;
}

export function isView(chartOptions) {
  return chartOptions?.seriesType !== 'Calculation';
}

export function isCalculation(chartOptions) {
  return chartOptions?.seriesType === 'Calculation';
}

export function isNumber(number) {
  return !Number.isNaN(number);
}

export function calc(val1, val2, method) {
  switch (method) {
    case '+': return val1 + val2;
    case '-': return val1 - val2;
    case '*': return val1 * val2;
    case '/': return val1 / val2;
    default: return 0;
  }
}

/**
 * Returns an alternative name for the series to display in the chart legend.
 * @param {number} index
 * @param {object} device device data
 * @return {string|unknown}
 */
export function getAlternativeVariableName(index, device) {
  if (device.data.mappings) {
    // old chart mappings -> return mapped variable as name
    return Object.values(device.data.mappings)[index];
  }

  if (isView(device.data.chartOptions[index])) {
    // dealing with new chartoptions and type is view -> return mapped variable name
    return device.data.chartOptions[index].var;
  }

  // dealing with new chartoptions and type is calc -> return "calc_{index}"
  return `Calculation_${index}`;
}

/**
 * Returns the respective object containing the chart options.
 * For older charts, this is the data.options object's values (an array).
 * For newer charts, it is data.chartOptions (an array).
 * @param {object} device device data
 * @return {unknown[]|*}
 */
export function getChartOptions(device) {
  return device.data.options
    ? Object.values(device.data.options)
    : device.data.chartOptions;
}

/**
 * Returns live period bounds
 * @return {{start: number, end: number, endChart: number}}
 */
export function getLiveBounds() {
  const date = new Date();
  const now = toSeconds(date.getTime());
  const start = now - 900;

  return { start, end: now, endChart: now };
}

/**
 * Returns hour period bounds
 * @param {Date} date
 * @return {{start: number, end: number, endChart: number}}
 */
export function getHourBounds(date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  const start = toSeconds(new Date(year, month, day, hour, minute).getTime());
  const end = toSeconds(new Date(year, month, day, hour + 1, minute).getTime());

  return { start, end, endChart: end };
}

/**
 * Returns day period bounds
 * @param {Date} date
 * @return {{start: number, end: number, endChart: number}}
 */
export function getDayBounds(date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const start = toSeconds(new Date(year, month, day).getTime());
  const end = toSeconds(new Date(year, month, day + 1).getTime());

  return { start, end, endChart: end };
}

/**
 * Returns week period bounds
 * @param {Date} date
 * @return {{start: number, end: number, endChart: number}}
 */
export function getWeekBounds(date) {
  const month = date.getMonth();
  const year = date.getFullYear();
  const firstDayOfWeek = date.getDate() - date.getDay() + 1;
  const start = toSeconds(new Date(year, month, firstDayOfWeek).getTime());
  const end = toSeconds(new Date(year, month, firstDayOfWeek + 7).getTime());

  return { start, end, endChart: end };
}

/**
 * Returns month period bounds
 * @param {Date} date
 * @return {{start: number, end: number, endChart: number}}
 */
export function getMonthBounds(date) {
  const year = date.getFullYear();
  const month = date.getMonth();

  const start = toSeconds(new Date(year, month, 1).getTime());
  const end = toSeconds(new Date(year, month + 1, 1).getTime());
  const endChart = toSeconds(new Date(year, month + 1).getTime());

  return { start, end, endChart };
}

/**
 * Returns year period bounds
 * @param {Date} date
 * @return {{start: number, end: number, endChart: number}}
 */
export function getYearBounds(date) {
  const year = date.getFullYear();

  const start = toSeconds(new Date(year, 0).getTime());
  const end = toSeconds(new Date(year + 1, 0).getTime());

  return { start, end, endChart: end };
}

export function getRoundedDate(minutes, d) {
  const ms = 1000 * 60 * minutes; // convert minutes to ms
  const roundedDate = new Date(Math.round(d.getTime() / ms) * ms);

  return roundedDate;
}

export function checkAllScalingsEqual(chartOptions) {
  const allMinScalings = chartOptions.map((e) => e.scaling.min);
  const allMaxScalings = chartOptions.map((e) => e.scaling.max);
  const allUnits = chartOptions.map((e) => e.unit);
  const allEqual = arr => arr.every(val => val !== null && val === arr[0]);

  return allEqual(allMinScalings) && allEqual(allMaxScalings) && allEqual(allUnits);
}

export const Periods = {
  LIVE: 'live',
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
  FORECAST6H: 'forecast6h',
  FORECAST24H: 'forecast24h',
};

/**
 * Dict of periodConfigurations. Access with periodConfigurations["day"] etc.
 */
export const periodConfigurations = {
  live: {
    title: Periods.LIVE,
    interval: '10s',
    intervalInSeconds: 10,
    period: () => {
      const date = new Date();
      const now = Math.trunc(date.getTime() / 1000);
      const start = now - 900;

      return {
        start,
        end: now,
        endChart: now,
      };
    },
    tickInterval: 2 * 60 * 1000,
    tooltipFormat: '%H:%M:%S',
    format: '%H:%M',
  },
  hour: {
    calendarType: 'date',
    title: Periods.HOUR,
    interval: '5s',
    intervalInSeconds: 5,
    period: () => {
      const date = new Date();
      const now = Math.trunc(date.getTime() / 1000);
      const start = now - 3600;

      return {
        start,
        end: now,
        endChart: now,
      };
    },
    tickInterval: 5 * 60 * 1000,
    format: '%H:%M',
    multiSelectFormat: '%H:%M',
    tooltipFormat: '%H:%M:%S',
    multiSelectTooltipFormat: '%H:%M:%S',
  },
  day: {
    calendarType: 'date',
    title: Periods.DAY,
    interval: '5m',
    intervalInSeconds: 300,
    period: () => {
      const date = new Date();
      const now = Math.trunc(date.getTime() / 1000);
      const start = Math.trunc(date.setHours(0, 0, 0, 0) / 1000);

      return {
        start,
        end: now,
        endChart: start + 90000,
      };
    },
    tickInterval: 4 * 3600 * 1000,
    format: '%H:%M',
    multiSelectFormat: '%H:%M',
    tooltipFormat: '%H:%M',
    multiSelectTooltipFormat: '%H:%M',
  },
  week: {
    calendarType: 'date',
    title: Periods.WEEK,
    interval: '1h',
    intervalInSeconds: 3600,
    period: () => {
      const date = new Date();
      let selectedDay = null;
      let now = null;
      let start = null;
      if (date.getDay() === 0) {
        selectedDay = (date.getDay());
        now = Math.trunc(date.getTime() / 1000); // day from sunday on, mon = 1
        start = Math.trunc(date.setHours(0, 0, 0, 0) / 1000);
        start -= (86400 * 7);
      } else {
        selectedDay = (date.getDay()) - 1;
        now = Math.trunc(date.getTime() / 1000); // day from sunday on, mon = 1
        start = Math.trunc(date.setHours(0, 0, 0, 0) / 1000);
        start -= (86400 * selectedDay);
      }

      return {
        start,
        end: now,
        endChart: start + (86400 * 7), // 1 week
      };
    },
    tickInterval: 24 * 3600 * 1000,
    format: '%a %d. %b',
    multiSelectFormat: '%a',
    tooltipFormat: '%A, %H:%M',
    multiSelectTooltipFormat: '%A',
  },
  month: {
    calendarType: 'month',
    title: Periods.MONTH,
    interval: '6h',
    intervalInSeconds: 21600,
    period: () => {
      const date = new Date();
      const start = new Date(date.getFullYear(), date.getMonth(), 1);
      const end = new Date(date.getFullYear(), date.getMonth() + 1, 1);

      return {
        start: Math.trunc(start.getTime() / 1000),
        end: Math.trunc(date.getTime() / 1000),
        endChart: Math.trunc(end.getTime() / 1000),
      };
    },
    tickInterval: 24 * 3600 * 1000,
    format: '%d.%b',
    multiSelectFormat: '%d',
    tooltipFormat: '%d.%m',
    multiSelectTooltipFormat: '%d',
  },
  year: {
    title: Periods.YEAR,
    interval: '1d',
    intervalInSeconds: 86400,
    period: () => {
      const date = new Date();
      const now = Math.trunc(date.getTime() / 1000);

      const start = new Date(date.getFullYear(), 0);
      const endChart = new Date(date.getFullYear() + 1, 0);

      return {
        start: Math.trunc(start.getTime() / 1000),
        end: now,
        endChart: Math.trunc(endChart.getTime() / 1000),
      };
    },
    format: '%B %Y',
    multiSelectFormat: '%B',
    tickInterval: 30 * 24 * 3600 * 1000,
    tooltipFormat: '%m.%y',
    multiSelectTooltipFormat: '%m',
  },
  forecast6h: {
    title: Periods.FORECAST6H,
    interval: '5m',
    intervalInSeconds: 300,
    period: () => {
      let date = new Date();

      date = getRoundedDate(15, date);
      const start = Math.trunc(date.getTime() / 1000);

      const end = start + 21600; // 6 hours after start

      return {
        start,
        end,
        endChart: end,
      };
    },
    tickInterval: 0.5 * 3600 * 1000, // 1/2 h interval
    format: '%H:%M',
    multiSelectFormat: '%H:%M',
    tooltipFormat: '%H:%M',
    multiSelectTooltipFormat: '%H:%M',
  },
  forecast24h: {
    title: Periods.FORECAST24H,
    interval: '5m',
    intervalInSeconds: 300,
    period: () => {
      let date = new Date();

      date = getRoundedDate(15, date);
      const start = Math.trunc(date.getTime() / 1000);

      const end = start + 21600 * 4; // 24 hours after start

      return {
        start,
        end,
        endChart: end,
      };
    },
    tickInterval: 0.5 * 3600 * 1000, // 1/2 h interval
    format: '%H:%M',
    multiSelectFormat: '%H:%M',
    tooltipFormat: '%H:%M',
    multiSelectTooltipFormat: '%H:%M',
  },
};

export const langLoadingTexts = {
  'de': 'Laden...',
  'en': 'Loading...',
  'it': 'Caricamento...',
};

export function getDateBoundsByPeriod(date, period) {
  const inputDate = new Date(date * 1000);
  switch (period) {
    case Periods.HOUR:
      return getHourBounds(inputDate);
    case Periods.DAY:
      return getDayBounds(inputDate);
    case Periods.WEEK:
      return getWeekBounds(inputDate);
    case Periods.MONTH:
      return getMonthBounds(inputDate);
    case Periods.YEAR:
      return getYearBounds(inputDate);
    default: throw new Error('Invalid period.');
  }
}
