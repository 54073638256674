
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component represent central circle which used in EMS, EnergyView...
 */
@Component
export default class AutarkiegradCircle extends Vue {
  @Prop({ default: null }) value!: any;
}
