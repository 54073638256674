import {
  EnergyCircleType, IOEnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';
import { getVariableValue } from '@/ui/components/devices/components/EnergyParts/EMS/utils';
import {
  IAggregationProps,
  IExpressionProps,
  ICalculationProps,
  IEnergyIOCalculationProps, IEnergyIOAggregationProps, IEnergyIOExpressionProps,
} from '@/types/energyVisualisation/energyVisualisation.types';
import { IDevice } from '@/types/devices.types';

export function getAggregation({
  system,
  variable,
  externalMeasurementsList,
}: IEnergyIOAggregationProps, defaultAggregation = 'avg') {
  switch (system) {
    // case IOEnergyCircleType.hybrids: return [variable, ...externalMeasurementsList].fill(defaultAggregation);
    // case EnergyCircleType.battery: return variable === 'soc' ? ['last'] : [defaultAggregation];
    default:
      return [defaultAggregation];
  }
}

export function getExpression({
  system,
  variableValue,
  expressionWrapperFn,
  externalMeasurementsList,
}: IEnergyIOExpressionProps) {
  switch (system) {
    // case IOEnergyCircleType.house: {
    //   const exp = [variableValue, ...externalMeasurementsList].join(' - ');
    //   return typeof expressionWrapperFn === 'function' ? expressionWrapperFn(exp) : exp;
    // }
    default:
      return typeof expressionWrapperFn === 'function' ? expressionWrapperFn(variableValue) : variableValue;
  }
}

export function getCalculation({
  system,
  instance,
  variable,
  device,
  expressionWrapperFn,
  externalMeasurementsList,
  defaultAggregation = 'avg',
}: IEnergyIOCalculationProps) {
  const variableValue = getVariableValue(device.data.meta.controllerMappings, system, instance, variable);
  return {
    aggregations: getAggregation({ system, variable, externalMeasurementsList }, defaultAggregation),
    expression: getExpression({
      system,
      variableValue,
      expressionWrapperFn,
      externalMeasurementsList,
    }),
  };
}
