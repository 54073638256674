
import { Vue, Component } from 'vue-property-decorator';
import Downloads from '@/ui/components/components/Downloads.vue';

@Component({
  components: { Downloads },
})
export default class EdgeDevice extends Vue {
  downloadsChips = [
    {
      title: 'modbusTCPEdgeDevice',
      source: './files/Doku ModbusTCP Edge Device Lynus.pdf',
      isLoaded: false,
    },
    {
      title: 'globalVariablesList',
      source: './files/GVL for Helper TCP.txt',
      isLoaded: false,
    },
    {
      title: 'xmlForWinCE',
      source: './files/TcModbusSrv.xml',
      isLoaded: false,
    },
    {
      title: 'xmlForWinUpperWinCE',
      source: './files/XMLModbusTCPConfig.xml',
      isLoaded: false,
    },
  ]
}
