
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Alert action component.
 * Manages actions.
 */
@Component
export default class EmailAction extends Vue {
  @Prop({ default: {} }) params: any;

  get notificationTypes() {
    return [
      { state: this.$t('modals.alertAction.notificationTypes.info'), abbr: 0 },
      { state: this.$t('modals.alertAction.notificationTypes.warning'), abbr: 1 },
      { state: this.$t('modals.alertAction.notificationTypes.error'), abbr: 2 },
    ];
  }
}
