
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent OutputFieldBase component
 */
@Component
export default class OutputFieldBase extends Vue {
  @Prop() min!: number;
  @Prop() max!: number;
  @Prop({ default: false }) isDecimal?: boolean;
  @Prop() actualValueState!: any;

  get actualValue(): any {
    return this.actualValueState;
  }

  /**
   * Round actualValue
   */
  get mod() {
    if (!this.isDecimal) return Math.round(this.actualValue);
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return +(`${Math.round(`${this.actualValue}e+3`)}e-3`);
  }

  /**
   * Leave a maximum of two characters after comma
   */
  get filtered(): any {
    return Number.isInteger(this.mod) ? this.mod : this.mod.toFixed(2);
  }
  get localisation() {
    const filteredByMinMax = () => {
      if (this.min > +this.filtered) return this.min;
      if (this.max < +this.filtered) return this.max;
      return this.filtered;
    };
    return Intl.NumberFormat('de-GE').format(filteredByMinMax());
  }
}
