
import { Vue, Component } from 'vue-property-decorator';
import PushButtonMock from '@/ui/components/devices/previews/components/PushButtonMock.vue';
import SliderMock from '@/ui/components/devices/previews/components/SliderMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    PushButtonMock,
    SliderMock,
    DeviceLayout,
  },
})
export default class MixingValvePreview extends Vue {
  get mainViewLang() {
    return 'devices.MixingValve.mainView';
  }
  get previewNameLang() {
    return this.$t('devices.MixingValve.previewName');
  }
}
