
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import RotatingBacklight from '@/ui/components/devices/components/EnergyParts/RotatingBacklight.vue';
import _ from 'lodash';
import { INavigationState } from '@/store/modules/navigation/types';
import AutarkiegradCircle from '@/ui/components/devices/components/EnergyParts/AutarkiegradCircle.vue';
import { periodConfigurations, Periods } from '@/ui/components/devices/charts/charts/ChartUtils';
import { IVariablesState } from '@/store/modules/measurements/types';
import HourPicker from '@/ui/components/devices/charts/components/HourPicker.vue';
import HourPickerMultiSelect from '@/ui/components/devices/charts/components/HourPickerMultiSelect.vue';
import DefaultCalendar from '@/ui/components/devices/charts/components/DefaultCalendar.vue';
import MultiSelectionCalendar from '@/ui/components/devices/charts/components/multiselection-calendar/MultiSelectionCalendar.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import PeriodSelection from '@/ui/components/devices/charts/components/PeriodSelection.vue';
import { roundNumber } from '@/utils/utilsFunctions';
import { kilogramUnit, kiloWattHourUnit } from '@/utils/unit/unitConstants';
import EnergyVisualisation from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/index.vue';
import ActualViewSystemDialog from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/ActualView/ActualViewSystemDialog.vue';
import { circlesDisplayDataMock, linesDisplayDataMock} from '@/ui/components/devices/previews/mockObjects/energyDevicesMockObjects';
import { EnergyCircleType } from '@/types/energyVisualisation/EnergyCircleType';
import { EnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import { IProject } from '@/types/project.types';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent EMS EnergyFlow view
 */
@Component({
  components: {
    CircleSpinner,
    AutarkiegradCircle,
    RotatingBacklight,
    PeriodSelection,
    HourPicker,
    HourPickerMultiSelect,
    DefaultCalendar,
    MultiSelectionCalendar,
    EnergyVisualisation,
    ActualViewSystemDialog,
  },
})
export default class EnergyFlowView extends Vue {
  @Prop() data!: IDevice;
  @Prop({ default: '650px' }) energyVisualisationHeight!: string;
  @State('navigation') navigationState!: INavigationState;
  @State('mpc') mpcState!: any;
  @State('measurements') measurementsState!: IVariablesState;
  @Getter('projects/startOfTheProject') createdAt!: number;
  @Getter('projects/project') currentProject!: IProject;
  @Getter('projects/projectCurrency') projectCurrency!: '€' | 'CHF' | undefined;

  readonly multiSelectLimit: number = 5;

  @Provide('onCircleClick') onCircleClick = this.onCircleClickCallback;

  showDialog = false;
  showSystemSettingsDialog = false;
  openedSystem?: EnergyCircleType;

  onCircleClickCallback(circleData: EnergyCircleDisplayData) {
    this.openedSystem = circleData.id as EnergyCircleType;
    this.showDialog = true;
  }

  reRenderKey = 0;
  canvasSize: any = null;
  canvasCenter: any = null;
  canvasLines: any = {};
  power = '';

  currentPeriod: string = Periods.DAY;
  tabsTimer: any = null;

  startDate = periodConfigurations[this.currentPeriod].period().start;
  endDate = periodConfigurations[this.currentPeriod].period().end;

  localCountColumns = 0;
  localCountLines = 0;
  modularDefaultPeriod = 'day';

  date: number[] = [];
  costSavings = 0;
  emissionReduction = 0;
  totalConsumption = 0;

  dataLoaded = false;

  changeCurrency = false;
  currentExchangeRate = 0.97;
  direction = 1;

  navigationItemsToExclude = ['live', 'hour'];
  calculatedConsumerSystems: any = [];
  calculatedProducerSystems: any = [];
  calculatedBattery: any = {};
  instancesCanvasSize: any = null;
  instancesCanvasCenter: any = null;
  disableNavButton = false;

  localCirclesDisplayData = circlesDisplayDataMock;
  localLinesDisplayData = linesDisplayDataMock;

  get measurements() {
    return this.measurementsState.measurements;
  }

  get now() {
    return new Date().toISOString();
  }

  get start() {
    return new Date(this.createdAt).toISOString();
  }

  customApproximationValue() {
    return { day: 4, week: 1, month: 1, year: 1 };
  }

  get groups() {
    return this.mpcState.mlModelTypes.EMS.groups;
  }

  changeDirection() {
    this.direction = this.direction === 1 ? 2 : 1;
  }

  async mounted() {
    this.dataLoaded = true;
    if (this.projectCurrency !== undefined) {
      this.changeCurrency = this.projectCurrency === 'CHF';
    }
    this.updateData('day');
  }

  async handleNavigation(period: string) {
    if (period === this.currentPeriod) {
      return;
    }
    this.disableNavButton = true;
    setTimeout(async () => {
      await this.updateData(period);
      this.currentPeriod = period;
      this.disableNavButton = false;
      this.reRenderKey += 1;
    }, 500);
  }

  updateData(period: string) {
    const defaultCostSavings = (Math.random() * 20 + 1);
    const defaultEmissionReduction = (Math.random() * 20 + 1);
    const defaultTotalConsumption = (Math.random() * 20 + 1);
    let mulilpicator = 1;
    switch (period) {
      case 'day': {
        mulilpicator = 1;
        break;
      }
      case 'week': {
        mulilpicator = 7;
        break;
      }
      case 'month': {
        mulilpicator = 30;
        break;
      }
      case 'year': {
        mulilpicator = 117;
        break;
      }
    }

    this.costSavings = parseFloat(defaultCostSavings.toFixed(2)) * mulilpicator;
    this.emissionReduction = parseFloat(defaultEmissionReduction.toFixed(2)) * mulilpicator;
    this.totalConsumption = parseFloat(defaultTotalConsumption.toFixed(2)) * mulilpicator;
  }

  get periodConfigurations() {
    const periodConfigs: any = _.cloneDeep(periodConfigurations); // copy default periodConfigurations
    periodConfigs[this.currentPeriod].interval = '15m';
    return periodConfigs;
  }
}
