
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { cloneDeep, toNumber } from 'lodash';
import VueI18n from 'vue-i18n';
import ManageChartsView from '@/ui/components/modals/ManageChart/ManageChartsView.vue';
import ManageChartsCalculation from '@/ui/components/modals/ManageChart/ManageChartsCalculation.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { FormUpdateTracker } from '@/ui/mixins/formUpdateTracker';
import { Validation } from '@/ui/mixins/validation';
import { defaultChartColors } from '@/ui/components/devices/charts/charts/LynusChart';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IRoom } from '@/types/rooms.types';
import { IMember } from '@/types/members.types';

/**
 * A component that allows to create or modify charts
 */
@Component({
  components: {
    ManageChartsView,
    ManageChartsCalculation,
    CircleSpinner,
    ModalWindow,
  },
})
export default class ManageCharts extends mixins(FormUpdateTracker, Validation) {
  @Prop() formTitle?: string | VueI18n.TranslateResult;
  @Prop({ default: '' }) activeRoomId!: string;
  @Prop({
    default: () => ({
      name: '',
      data: {
        type: 'chart',
        selectedWidth: '',
        selectedStackingOptions: '',
        chartOptions: [],
      },
      collection_id: '',
    }),
  }) deviceData!: any;
  @State('measurements') measurementsState!: IVariablesState;
  @Getter('rooms/rooms') rooms!: IRoom[];
  @Getter('measurements/measurementsKeys') measurementsKeys!: string[];
  @Getter('members/currentMember') currentMember!: IMember;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;

  isTooltip = false;
  stage = 1;
  showLoadingSpinner = false;
  localDeviceData: any = {
    name: '',
    data: {
      type: 'chart',
      chartOptions: [],
    },
    collection_id: '',
  };

  get isEditModalProp() {
    return !!this.localDeviceData?.name?.length;
  }
  get selectionTypes() {
    return [
      { text: this.$t('modals.manageCharts.form.selectionTypes.view'), value: 'View' },
      { text: this.$t('modals.manageCharts.form.selectionTypes.calc'), value: 'Calculation' },
    ];
  }
  get chartWidthOptions() {
    return [
      { text: this.$t('modals.manageCharts.form.widthOptions.full'), value: 'full' },
      { text: this.$t('modals.manageCharts.form.widthOptions.half'), value: 'half' },
    ];
  }
  get scalingMethods() {
    return [
      { text: this.$t('modals.manageCharts.form.scalingMethods.auto'), value: 'automatic' },
      { text: this.$t('modals.manageCharts.form.scalingMethods.manual'), value: 'manual' },
    ];
  }
  get chartTypes() {
    return [
      { text: this.$t('modals.manageCharts.form.chartTypes.line'), value: 'line' },
      { text: this.$t('modals.manageCharts.form.chartTypes.area'), value: 'area' },
      { text: this.$t('modals.manageCharts.form.chartTypes.column'), value: 'column' },
    ];
  }
  get aggregationMethods() {
    return [
      { state: this.$t('modals.manageCharts.form.aggregationMethods.averageOfPeriod'), abbr: 'avg' },
      { state: this.$t('modals.manageCharts.form.aggregationMethods.minimumOfPeriod'), abbr: 'min' },
      { state: this.$t('modals.manageCharts.form.aggregationMethods.maximumOfPeriod'), abbr: 'max' },
      { state: this.$t('modals.manageCharts.form.aggregationMethods.lastOfPeriod'), abbr: 'last' },
      { state: this.$t('modals.manageCharts.form.aggregationMethods.firstOfPeriod'), abbr: 'first' },
      { state: this.$t('modals.manageCharts.form.aggregationMethods.difference'), abbr: 'diff' },
    ];
  }

  // modal steps validation
  get nameTypeValidation() {
    return !!(this.localDeviceData.name.length);
  }
  get optionsValid() {
    if (!this.localDeviceData.data.chartOptions) return false;
    const list = this.localDeviceData.data.chartOptions.map((option: any) => {
      if (!option?.seriesType?.length) return false;
      else {
        if (option.seriesType === 'View') return !!option?.var?.length;
        else return !!option?.calculation?.expression;
      }
    });
    return list.every((el: boolean) => el);
  }
  get settingsValidation() {
    return !!this.localDeviceData.collection_id.length;
  }

  addButtonVisibility(inx: number | string) {
    return Object.keys(this.localDeviceData.data.chartOptions).length - 1 === inx ? 'visible' : 'hidden';
  }
  // changes the options based on the index given and the selected item
  changeOptions(value: 'View' | 'Calculation', index: number | string) {
    if (value === 'View') {
      this.localDeviceData.data.chartOptions.splice(index, 1, {
        scaling: {
          min: null,
          max: null,
        },
        unit: '',
        type: 'line',
        agg: 'last',
        name: '',
        seriesType: 'View',
        var: '',
        seriesColor: defaultChartColors[Number(index)],
      });
    } else {
      this.localDeviceData.data.chartOptions.splice(index, 1, {
        scaling: {
          min: null,
          max: null,
        },
        unit: '',
        type: 'line',
        name: '',
        seriesType: 'Calculation',
        agg: 'last',
        seriesColor: defaultChartColors[Number(index)],
        calculation: {
          expression: '',
          aggregations: [],
        },
      });
    }
  }
  async addVariable() {
    const count = this.localDeviceData.data.chartOptions.length;
    this.localDeviceData.data.chartOptions.splice(count, 1, {
      scaling: {
        min: null,
        max: null,
      },
      unit: '',
      type: 'line',
      agg: 'last',
      name: '',
      seriesColor: defaultChartColors[count] });

    await this.$nextTick();
    this.validateSelects();
  }

  /**
   * Removes selected chart variable
   * @param index number, index of variable
   */
  async deleteVariable(index: number | string) {
    this.showLoadingSpinner = true;
    this.localDeviceData.data.chartOptions.splice(index, 1);

    // TODO: check if we need this timeout
    const timeOutLength = this.localDeviceData.data.chartOptions.length * 300;
    await new Promise(resolve => {
      setTimeout(() => {
        this.showLoadingSpinner = false;
        resolve(null);
      }, timeOutLength);
    });

    await this.$nextTick();
    this.validateSelects();
  }

  /**
   * Parses the scalings to numbers
   */
  parseScalings() {
    this.localDeviceData.data.chartOptions.forEach((option: any, index: number) => {
      if (option.scaling.min !== null) {
        this.localDeviceData.data.chartOptions[index].scaling.min = toNumber(option.scaling.min);
      }
      if (option.scaling.max !== null) {
        this.localDeviceData.data.chartOptions[index].scaling.max = toNumber(option.scaling.max);
      }
    });
  }

  sendForm() {
    if (this.localDeviceData.name && Object.keys(this.localDeviceData.data.chartOptions).length > 0 && this.localDeviceData.collection_id.length > 0) {
      // if nothing is selected the default width Value will be full
      if (this.localDeviceData.data.selectedWidth === '') {
        this.localDeviceData.data.selectedWidth = 'full';
      }
      // sets default value for stacking options
      this.localDeviceData.data.selectedStackingOptions = 'normal';

      this.parseScalings();

      this.stage = 1;
      this.$emit('handleControl', this.localDeviceData);

      if (!this.isEditModalProp) {
        // delete chart instance here
        this.localDeviceData = {
          name: '',
          data: {
            type: 'chart',
            selectedWidth: '',
            chartOptions: [],
          },
          collection_id: '',
        };
        // adds the default Variable
        // when you create more than 1 Chart in a row in the Workbench without leaving
        this.addVariable();
      }
      (this.$refs.ModalWindowInstance as ModalWindow).onConfirmationDialogClose();
    }
  }

  validateSelects() {
    this.localDeviceData.data.chartOptions.forEach((_: any, inx: number) => {
      (this.$refs[`Select${inx}`] as any)[0].validate(true);
    });
  }

  async initData() {
    this.localDeviceData = cloneDeep(this.deviceData);
    if (this.activeRoomId.length) this.localDeviceData.collection_id = this.activeRoomId;

    // set one default variable for new Charts
    if (Object.keys(this.localDeviceData.data.chartOptions).length === 0) {
      this.addVariable();
    }

    this.dataSnapshot = cloneDeep(this.localDeviceData);
  }
  reset() {
    this.stage = 1;
  }

  async onDialogChange(isOpen: boolean) {
    if (isOpen) {
      await this.fetchVariables(this.$route.params.id);
      await this.initData();
    } else {
      this.reset();
    }
  }
}
