
import { Vue, Component, Prop } from 'vue-property-decorator';
import Forecast from '@/ui/components/devices/mpc/HeatingCircuitOptimization/SettingsCharts/Forecast.vue';
import Charts from '@/ui/components/devices/mpc/HeatingCircuitOptimization/SettingsCharts/Charts.vue';

/**
 * Component that represent wrapper for charts tabs navigation.
 * Used in HeatingCircuitOptimization.
 */
@Component({
  components: {
    Forecast,
    Charts,
  },
})
export default class SettingsCharts extends Vue {
  @Prop() deviceId!: any;

  tab: any = null

  get langPath() {
    return 'mlModel.HeatingCircuitOptimization.settingsView.chartTabs';
  }

  get tabs() {
    return [
      {
        title: 'Chart',
        locale: this.langPath,
        component: 'Charts',
        deviceData: this.deviceId,
      },
      {
        title: 'Forecast',
        locale: this.langPath,
        component: 'Forecast',
        deviceData: this.deviceId,
      },
    ];
  }
}
