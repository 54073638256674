
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent Circle Spinner
 */
@Component
export default class CircleSpinner extends Vue {
  @Prop({ default: 40 }) size!: number;
  @Prop({ default: 'default' }) color!: string; // possible values: default, accent, secondary (colors are taken from config.ts/VUE_APP_THEMES)
}
