
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import Switch2VBase from '@/ui/components/devices/devices/base/Switch2VBase.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';

/**
 * CHP single system settings.
 */
@Component({
  components: {
    OutputFieldBase,
    Switch2VBase,
    InputFieldBase,
    LabelUnitWrapper,
    ShowEventDotBase,
  },
})
export default class CHPSystemSettings extends mixins(Validation) {
  @Prop({ default: null }) instanceData!: any;

  get switchEnableVariables() {
    return {
      Switch2V_onOff: this.instanceData.switch_enable,
      Switch2V_state: this.instanceData.state_enable,
    };
  }
  get switch2VActivateCooling() {
    return {
      Switch2V_onOff: this.instanceData.switch_activate_cooling,
      Switch2V_state: this.instanceData.state_activate_cooling,
    };
  }
  get switch2VActivateHeating() {
    return {
      Switch2V_onOff: this.instanceData.switch_activate_heating,
      Switch2V_state: this.instanceData.state_activate_heating,
    };
  }

  get inputFieldActivateSOC() {
    return {
      InputField_targetValue: this.instanceData.enable_soc,
    };
  }
  get inputFieldDeactivateSOC() {
    return {
      InputField_targetValue: this.instanceData.disable_soc,
    };
  }
  get inputFieldParallelGridMode() {
    return {
      InputField_targetValue: this.instanceData.parallel_grid_mode,
    };
  }
  get inputFieldParallelGridTargetPower() {
    return {
      InputField_targetValue: this.instanceData.parallel_grid_target_power,
    };
  }
  get inputFieldBufferStorageCapacity() {
    return {
      InputField_targetValue: this.instanceData.buffer_storage_capacity,
    };
  }
  get inputFieldThermalPowerInput() {
    return {
      InputField_targetValue: this.instanceData.thermal_power_input,
    };
  }

  get showEventDotStatus() {
    return {
      ShowEventDot_errorWarningState: this.instanceData.status,
    };
  }
  get showEventDotOperation() {
    return {
      ShowEventDot_errorWarningState: this.instanceData.operation,
    };
  }

  get outputFieldFlowTemperature() {
    return {
      OutputField_actualValue: this.instanceData.flow_temperature,
    };
  }
  get outputFieldReturnTemperature() {
    return {
      OutputField_actualValue: this.instanceData.return_temperature,
    };
  }
  get outputFieldBufferTemperatureTop() {
    return {
      OutputField_actualValue: this.instanceData.buffer_temperature_top,
    };
  }
  get outputFieldBufferTemperatureBottom() {
    return {
      OutputField_actualValue: this.instanceData.buffer_temperature_bottom,
    };
  }
  get outputFieldTargetPower() {
    return {
      OutputField_actualValue: this.instanceData.target_power,
    };
  }
  get outputFieldEnergyCounter() {
    return {
      OutputField_actualValue: this.instanceData.power,
    };
  }
  get outputFieldEnergyCounterThermal() {
    return {
      OutputField_actualValue: this.instanceData.thermal_power_output,
    };
  }
}
