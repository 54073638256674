import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import { IAnomalyDetectionState } from '@/store/modules/anomalyDetection/types';
import anomalyDetectionTypes from '@/store/modules/anomalyDetection/anomalyDetectionTypes';
import i18n from '@/ui/plugins/i18n';

const state: IAnomalyDetectionState = {
  anomalyDetectionTypes,
  lastCreatedAnomalyDetection: '',
};

const getters: GetterTree<IAnomalyDetectionState, RootState> = {
  /**
   * Filters from MPC devices Anomaly Detected devices
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   */
  anomalyDetectionDevices(state, getters, rootState, rootGetters) {
    return rootGetters['mpc/mpcControllers'].filter((device: any) => Object.keys(state.anomalyDetectionTypes).some((el: string) => el === device.data.type));
  },

  /**
   * Goes through anomalyDetectionTypes and create options
   * (Example: { value: key, title: i18n translation }) for every item
   * @param state
   */
  anomalyDetectionTypesWithLocaleNamesList(state) {
    return Object.keys(state.anomalyDetectionTypes)
      .map((itemType: string) => ({ value: itemType, title: i18n.t(`anomalyDetection.${itemType}.previewName`) }));
  },
  lastCreatedAnomalyDetection(state: IAnomalyDetectionState) {
    return state.lastCreatedAnomalyDetection;
  },
};

const mutations: MutationTree<IAnomalyDetectionState> = {
  setLastCreatedAnomalyDetection(state: IAnomalyDetectionState, device: any) {
    state.lastCreatedAnomalyDetection = device;
  },
};

const actions: ActionTree<IAnomalyDetectionState, RootState> = {};

export const anomalyDetection: Module<IAnomalyDetectionState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
