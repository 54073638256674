
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

/**
 * Component that represent FillLevelDisplay Basic Control
 */
@Component
export default class FillLevelDisplayBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop() min!: number;
  @Prop() max!: number;
  @Prop({ default: false }) isDecimal?: boolean;
  @Prop({ default: 'accent' }) costumBarColor!: string;
  @Prop({ default: '#fff' }) costumBGColor!: string;
  @Prop({ default: 30 }) costumHeight!: number;
  @Prop({ default: false }) isText!: boolean;

  get actualValue(): any {
    return this.measurements.get(this.mappingsClean.actualValue);
  }

  /**
   * Round actualValue
   */
  get rounded() {
    if (!this.isDecimal) return Math.round(this.actualValue);
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return +(`${Math.round(`${this.actualValue}e+2`)}e-2`);
  }

  /**
   * round up the value if it is out of range
   */
  get filteredValue() {
    if (this.rounded < this.min) return this.min;
    if (this.rounded > this.max) return this.max;
    return this.rounded;
  }
  get fieldFilteredValue() {
    if (this.max) return (this.rounded * 100) / this.max;
    return this.rounded;
  }
  get isNotMapped(): boolean {
    return this.mappingsClean.actualValue === '';
  }
}
