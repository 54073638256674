export class ValueUnit {
  constructor(value: number | undefined, unit: string) {
    this.value = value;
    this.unit = unit;
  }

  value?: number;
  unit: string;

  get valueFormatted() {
    return this.value !== undefined ? Intl.NumberFormat('de-GE', { maximumFractionDigits: 1 }).format(this.value) : '';
  }

  get formattedWithUnit() {
    return `${this.valueFormatted} ${this.unit}`;
  }
}
