
import { Component, Prop, Vue } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

/**
 * Component that represent ShowEventBase component.
 * Looks like flashing frame.
 */
@Component
export default class ShowEventBase extends Vue {
  @Prop() warningState!: number;

  get warningClass() {
    if (this.warningState === 1) return 'device-warning-state';
    if (this.warningState === 2) return 'device-error-state';
    return null;
  }
}
