
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import ManageAnomalyDetection from '@/ui/components/modals/ManageAnomalyDetection/index.vue';
import { calculatePageCount } from '@/utils/utilsFunctions';
import Workbench from '@/ui/views/Project/Workbench.vue';

/**
 * Component that shows table of Anomaly Detection devices
 */
@Component({
  components: {
    DeleteModalForm,
    ManageAnomalyDetection,
  },
})
export default class AnomalyDetectionTable extends Vue {
  @Prop({ default: 100 }) itemsPerPage !: number;
  @State('devices') devicesState!: any;
  @Getter('anomalyDetection/anomalyDetectionDevices') anomalyDetectionDevices!: any;
  @Getter('rooms/rooms') rooms!: any;
  @Action('mpc/updateMPC') updateMPC!: (mpc: any) => Promise<void>;
  @Action('mpc/deleteMPC') deleteMPC!: (mpcId: any) => Promise<void>;

  currentPage = 1;

  get pageNumber() {
    return calculatePageCount(this.filteredBySearch.length, this.itemsPerPage);
  }

  get headers() {
    return [
      { text: this.$t('uiComponents.workbenchTable.name'), align: 'start', value: 'name' },
      { text: this.$t('uiComponents.workbenchTable.type'), value: 'data.type' },
      { text: this.$t('uiComponents.workbenchTable.area'), value: '' },
      { text: this.$t('uiComponents.workbenchTable.actions'), value: 'actions', sortable: false },
    ];
  }

  get devicesFilter() {
    return this.devicesState.devicesFilter;
  }
  /**
   * Filters the existing list of Anomaly Detection devices by type, name, area affiliation
   * @return filtered Anomaly Detection devices
   */
  get filteredBySearch() {
    return this.devicesFilter ? this.anomalyDetectionDevices.filter((device: any) => `${device.name} ${this.deviceRoomName(device.collection_id)} ${device.data.type}`
      .toLowerCase().includes(this.devicesFilter.toLowerCase())) :
      this.anomalyDetectionDevices;
  }

  /**
   * Defines the name of the room to which the Anomaly Detection device belongs
   * @param roomId id of the room
   * @return room name if room has named or empty string
   */
  deviceRoomName(roomId: any) {
    if (this.rooms.length) {
      const room: any = this.rooms.find((room: any) => room.id === roomId);
      return room?.name || null;
    }
    return '';
  }

  editControl(payload: any) {
    this.updateMPC(payload);
  }
  deleteControlHandle(control: any) {
    this.deleteMPC(control);
  }
}
