
import { IProject } from '@/types/project.types';
import { Component, Vue } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { buildCommaSeparatedString } from '@/utils/utilsFunctions';

/**
 * Component that represent general info banner on Favorites page
 */
@Component
export default class GeneralInfoBanner extends Vue {
  @State('app') appState!: any;
  @Getter('projects/project') project!: IProject;

  get weatherData() {
    return this.appState.weatherData || { main: {}, wind: {} };
  }

  get currentTemperature() {
    if (this.weatherData.main.temp) return Math.round(this.weatherData.main.temp);
    return '...';
  }

  get currentDate() {
    const d = new Date();
    return this.$d(d, 'long');
  }

  get locationData() {
    const displayName = this.project.meta?.location?.display_name;
    if (displayName) {
      const partsOfName = displayName.split(',');
      return buildCommaSeparatedString(partsOfName, 4);
    }
    return '';
  }

  weatherIcon() {
    if (!this.weatherData.weather) {
      return { icon: '', main: '' };
    }
    return this.weatherData.weather[0];
  }
}
