var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('WizardContentView',{attrs:{"valid":_vm.valid,"handle-back":_vm.handleDecrement,"handle-next":_vm.handleNext},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',[_vm._v(_vm._s(_vm.$t('installationWizard.defineEmsSettings.gridDrmSettings.title')))])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"system-part"},[_c('v-form',{ref:"form",staticClass:"general-form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_vm._l((_vm.drmVariables),function([variableKey, variableData],index){return _c('v-row',{key:variableKey},[_c('v-col',{staticClass:"py-1 d-flex",attrs:{"lg":"6","md":"6","sm":"12","cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('installationWizard.defineEmsSettings.gridDrmSettings.labels.drm', { variable: index + 1 }),"type":"number","color":"accent","outlined":"","flat":"","min":"0","max":"100","suffix":"%","step":1,"rules":[
                  _vm.rules.required,
                  _vm.rules.noCommas,
                  ..._vm.minMaxValidation(variableData.value, 0, 100)
                ]},model:{value:(variableData.value),callback:function ($$v) {_vm.$set(variableData, "value", _vm._n($$v))},expression:"variableData.value"}}),_c('div',{staticClass:"ml-2 mt-4"},[_c('InfoTooltip',{attrs:{"position":'bottom'},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('lynus-icon',{attrs:{"name":"info-in-circle","color":_vm.doesHover ? 'accent' : 'theme',"size":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('installationWizard.defineEmsSettings.gridDrmSettings.drmStageTooltip'))+" ")]},proxy:true}],null,true)})],1)],1)],1)}),_vm._l((_vm.otherVariables),function([variableKey, variableData]){return _c('v-row',{key:variableKey},[(!_vm.isNoBatteryPath)?_c('v-col',{staticClass:"py-1 d-flex",attrs:{"lg":"6","md":"6","sm":"12","cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('installationWizard.defineEmsSettings.gridDrmSettings.labels.maxSellPowerToGrid'),"type":"number","color":"accent","outlined":"","flat":"","min":"10","max":"50000","suffix":"W","step":1,"rules":[
                  _vm.rules.required,
                  _vm.rules.noCommas,
                  ..._vm.minMaxValidation(variableData.value, 10, 50000),
                ]},model:{value:(variableData.value),callback:function ($$v) {_vm.$set(variableData, "value", _vm._n($$v))},expression:"variableData.value"}}),_c('div',{staticClass:"ml-2 mt-4"},[_c('InfoTooltip',{attrs:{"position":'bottom'},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('lynus-icon',{attrs:{"name":"info-in-circle","color":_vm.doesHover ? 'accent' : 'theme',"size":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('installationWizard.defineEmsSettings.gridDrmSettings.maxFeedInTooltip'))+" ")]},proxy:true}],null,true)})],1)],1):_vm._e()],1)})],2)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }