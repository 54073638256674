
import { debounce, throttle } from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { NavigationButton } from '@/types/wizards/wizard.general.types';

@Component
export default class Navigation extends Vue {
  @Prop({ default: () => [] }) buttons!: NavigationButton[];

  disabled = false;

  async handleButtonClick(button: any) {
    button.onClick();
    this.disabled = true;
    await new Promise((resolve) => setTimeout(resolve, 250));
    this.disabled = false;
  }

  buttonClickThrottle = debounce(this.handleButtonClick, 50);
}
