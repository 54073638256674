
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

/**
 * Component that represent StatusIcon Basic Control
 */
@Component
export default class StatusIconBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop() iconMapping!: any;
  @Prop({ default: false }) showNumberValue!: boolean;
  @Prop({ default: false }) setErrorWarningColorSwitch!: boolean;
  @Prop({ default: 2 }) errorValue!: number;
  @Prop({ default: 1 }) warningValue!: number;
  @Prop({ default: 0 }) okValue!: number;
  @Prop({ default: -1 }) min!: number;
  @Prop({ default: 100 }) max!: number;

  items = Object.keys(this.iconMapping).map((key: string) => {
    return {
      name: this.iconMapping[key],
      value: key,
    };
  });
  tempName = '';

  get currentValue(): any {
    let resultCurrentValue:any = this.measurements.get(this.mappingsClean.currentValue);
    if( resultCurrentValue >= this.max){
      return this.max;
    }else if(resultCurrentValue <= this.min){
      return this.min;
    }
    return this.measurements.get(this.mappingsClean.currentValue);
  }

  /**
   * gets Current Icon for status bar
   */
  get currentIcon() {
    Object.entries(this.items).forEach((object: any, index: number) => {
      // input number > lowest Value && input number < highestValue
      if (this.currentValue >= object[1].name[0] && this.currentValue <= object[1].name[1]) {
        this.tempName = object[1].value;
        return object[1].value;
      }
    });
    // if value = undefined than the first Icon of the mapping will be shown
    if (this.currentValue === undefined){
      return this.items[0].value;
    }
    return this.tempName;
  }

  get iconColorErrorWarning(){
    if(this.setErrorWarningColorSwitch === true){
      if(this.currentValue === this.errorValue){
        return "red";
      }else if(this.currentValue === this.warningValue){
        return "warning";
      }
    }
    return "theme";
  }

  get isNotMapped(): boolean {
    return this.mappingsClean.currentValue;
  }
}
