
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import EnergyComponent from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import { EnergyCircleType } from '@/types/energyVisualisation/EnergyCircleType';
import { EnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import SystemDialog from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/SystemDialog.vue';
import PVSystemSettings from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/PVSystem/Actual/Settings.vue';
import GeneratorSystemSettings from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/GeneratorSystem/Actual/Settings.vue';
import BatterySystemSettings from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/BatterySystem/Actual/Settings.vue';
import GridSystemSettings from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/GridSystem/Actual/Settings.vue';
import HouseSystemSettings from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/HouseSystem/Actual/Settings.vue';
import ChargeStationSystemSettings from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/ChargeStationSystem/Actual/Settings.vue';
import ElectricHeatingSystemSettings from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/ElectricHeatingSystem/Actual/Settings.vue';
import HeatingPumpSystemSettings from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/HeatingPumpSystem/Actual/Settings.vue';
import BigConsumerSystemSettings from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/BigConsumerSystem/Actual/Settings.vue';
import HeatingBufferInput
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/ActualView/HeatingBufferInput.vue';
import CHPSystemSettings
  from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/CHPSystem/Actual/Settings.vue';
import { IDevice } from '@/types/devices.types';
import CoolingAirControls from '@/ui/components/devices/mpc/SetpointOptimizer/system/SystemInstanceControls/CoolingAirControls.vue';
import CoolingWaterControls from '@/ui/components/devices/mpc/SetpointOptimizer/system/SystemInstanceControls/CoolingWaterControls.vue';
import HeatingAirControls from '@/ui/components/devices/mpc/SetpointOptimizer/system/SystemInstanceControls/HeatingAirControls.vue';
import HeatingWaterControls from '@/ui/components/devices/mpc/SetpointOptimizer/system/SystemInstanceControls/HeatingWaterControls.vue';
import HybridAirControls from '@/ui/components/devices/mpc/SetpointOptimizer/system/SystemInstanceControls/HybridAirControls.vue';
import HybridWaterControls from '@/ui/components/devices/mpc/SetpointOptimizer/system/SystemInstanceControls/HybridWaterControls.vue';

/**
 * Actual view for EnergyView/EMS
 */
@Component({
  components: {
    SystemDialog,
    HeatingBufferInput,
  },
})
export default class ActualViewSystemSettingsDialog extends mixins(EnergyComponent) {
  // whether the dialog is currently shown (v-model)
  @Prop() value!: boolean;
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop() openedSystem!: EnergyCircleType;
  @Prop() circleData!: EnergyCircleDisplayData;
  @Prop({ default: null }) controls!: any;

  systemToSettingsMapping = {
    // EMS, EnergyView
    [EnergyCircleType.pv]: {
      component: PVSystemSettings,
      width: 450,
    },
    [EnergyCircleType.chp]: {
      component: CHPSystemSettings,
      width: 1200,
    },
    [EnergyCircleType.generator]: {
      component: GeneratorSystemSettings,
      width: 800,
    },
    [EnergyCircleType.battery]: {
      component: BatterySystemSettings,
      width: 1000,
    },
    [EnergyCircleType.grid]: {
      component: GridSystemSettings,
      width: 750,
    },
    [EnergyCircleType.house]: {
      component: HouseSystemSettings,
      width: 580,
    },
    [EnergyCircleType.charge_station]: {
      component: ChargeStationSystemSettings,
      width: 1000,
    },
    [EnergyCircleType.electric_heating]: {
      component: ElectricHeatingSystemSettings,
      width: 1500,
    },
    [EnergyCircleType.heating_pump]: {
      component: HeatingPumpSystemSettings,
      width: 1500,
    },
    [EnergyCircleType.big_consumer]: {
      component: BigConsumerSystemSettings,
      width: 1000,
    },
    // setpoint optimizer
    [EnergyCircleType.cooling_air_systems]: {
      component: CoolingAirControls,
      width: 1000,
    },
    [EnergyCircleType.heating_water_systems]: {
      component: HeatingWaterControls,
      width: 1000,
    },
    [EnergyCircleType.heating_air_systems]: {
      component: HeatingAirControls,
      width: 1000,
    },
    [EnergyCircleType.cooling_water_systems]: {
      component: CoolingWaterControls,
      width: 1000,
    },
    [EnergyCircleType.hybrid_air_systems]: {
      component: HybridAirControls,
      width: 1000,
    },
    [EnergyCircleType.hybrid_water_systems]: {
      component: HybridWaterControls,
      width: 1000,
    },
  };

  get circleDataTitle() {
    return this.circleData?.title;
  }

  get openedSettings() {
    return this.systemToSettingsMapping[this.openedSystem as EnergyCircleType].component;
  }
  get openedSettingsWidth() {
    return this.systemToSettingsMapping[this.openedSystem as EnergyCircleType].width;
  }

  // get opened single system's component data
  get openedSystemComponent() {
    return this.getSystemComponents(this.deviceData, this.openedSystem as EnergyCircleType)[
      this.circleData.id
    ];
  }

  get isHeatingBufferMappingFilled() {
    return this.deviceData.data.meta?.controllerMappings?.[this.openedSystem]?.components?.[this.circleData.id]?.heating_buffer;
  }
}
