
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { cloneDeep, has } from 'lodash';
import UserService from '@/services/UserService';
import { UserRoleCode } from '@/utils/userRoles';
import ManageCollectionsModal from '@/ui/components/components/ManageCollectionsAccessModal.vue';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { IProjectsState } from '@/store/modules/projects/types';
import { IRule } from '@/types/rules.types';
import { IAppState } from '@/store/modules/app/types';
import { IRulesState } from '@/store/modules/rules/types';
import { IRoom } from '@/types/rooms.types';
import { IDevice } from '@/types/devices.types';
import { IReportBox } from '@/types/app.types';
import { IFetchPermissionUserResponse } from '@/types/common.types';
import { IMember } from '@/types/members.types';
import { defaultRoomIdObject, addDefaultRoomToUser, getIsMemberInRulesAsRecipient, addMemberToRuleActions, deleteRecipientFromRules } from '@/utils/membersUtils';

/**
 * Permissions view is responsible for managing users and their
 * permissions. It uses the members api
 */
@Component<Permissions>({
  computed: {
    UserRoleCode: () => UserRoleCode,
  },
  components: {
    ModalWindow,
    ManageCollectionsModal,
    DeleteModalForm,
    CircleSpinner,
  },
})
export default class Permissions extends Vue {
  @Prop({ default: false }) isInWizard!: boolean;

  @State('rules') rulesState!: IRulesState;
  @State('app') appState!: IAppState;
  @State('projects') projectsState!: IProjectsState;
  @Getter('members/members') members!: IMember[];
  @Getter('rooms/rooms') rooms!: IRoom[];
  @Getter('devices/devicesByRoom') devicesByRoom!: (roomId: string) => IDevice[];
  @Mutation('app/setReport') setReport!: (payload: IReportBox) => void;
  @Action('members/createMember') createMember!: (data: {member: IMember; showMessage: boolean}) => Promise<IMember>;
  @Action('members/updateMember') updateMember!: (m: IMember) => Promise<IMember>;
  @Action('members/deleteMember') deleteMember!: (m: IMember) => Promise<IMember>;
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;
  @Action('rooms/fetchRooms') fetchRooms!: (projectId: string) => Promise<void>;
  @Action('rules/loadRules') loadRules!: (project_id: string) => Promise<IRule[]>;
  @Action('rules/modifyRule') modifyRule!: (payload: { id: string; rule_id: string; ruleModel: IRule }) => Promise<void>;
  @Action('projects/updateProject') updateProject!: (project: any) => Promise<void>;

  model = null;
  search = '';
  items: IFetchPermissionUserResponse[] = [];
  loading = false;
  rerenderKey = false;
  conModel: any = {
    actions: [],
    enabled: false,
  };
  showLoader = false;
  notificationCheckboxLoading = false;

  get userRoleItems() {
    return [
      { name: 'Admin', value: UserRoleCode.admin, disabled: !this.isCurrentMemberAdmin },
      { name: 'User', value: UserRoleCode.user },
      { name: 'Readonly', value: UserRoleCode.readOnly },
    ];
  }

  get defaultRoomIdObject() {
    return defaultRoomIdObject(this.rooms);
  }

  @Watch('model')
  async onSelected(val: IMember | null) {
    if (val === null) return;

    // use the first items because there will always
    // only be one result
    const newMember = await this.createMember({ member: this.items[0] as any, showMessage: true });
    if (newMember !== null) {
      this.handleAddDefaultRooms(UserRoleCode.readOnly, newMember);
    }

    // clear the autocomplete
    this.model = null;
    this.items = [];
    this.search = '';
  }

  async handleAddDefaultRooms(role: UserRoleCode, newMember: IMember) {
    await addDefaultRoomToUser(role, newMember, this.defaultRoomIdObject, this.$route.params.id);
  }

  @Watch('search')
  async fetchUsers(val?: string) {
    if (this.loading || val == null) return;
    if (!this.isEmailValid(val)) {
      this.items = [];
      return;
    }

    this.loading = true;
    try {
      this.items = await UserService.fetchUserByEmail(val);
    } catch (e) {
      this.setReport({ type: 'error', message: e.message, value: true });
    } finally {
      this.loading = false;
    }
  }

  get tableHeaders() {
    return [
      {
        text: this.$t('uiComponents.settings.permissions.table.name'),
        value: 'name',
      },
      {
        text: this.$t('uiComponents.settings.permissions.table.email'),
        value: 'email',
      },
      ...(this.isCurrentMemberAdmin ? [{
        text: this.$t('uiComponents.settings.permissions.table.owner'),
        value: 'owner',
      }] : []),
      {
        text: this.$t('uiComponents.settings.permissions.table.userRole'),
        value: 'role',
        tooltip: true,
        sortable: false,
      },
      {
        text: this.$t('uiComponents.settings.permissions.table.notification'),
        value: 'notification',
        tooltip: true,
        sortable: false,
      },
      {
        text: this.$t('uiComponents.settings.permissions.table.actions'),
        value: 'actions',
        sortable: false,
      },
    ];
  }
  get tableItems() {
    return this.members.map((m: any) => {
      const { first_name, last_name, email, owner, role } = m;
      return {
        name: `${first_name.trim()} ${last_name.trim()}`,
        email,
        owner,
        role,
        notification: false,
        origin: m,
      };
    });
  }

  get user() {
    return this.appState.user;
  }
  get currentMember() {
    return this.members.find((m) => m.id === this.user.id) as IMember;
  }
  get isCurrentMemberAdmin() {
    return this.currentMember?.role === UserRoleCode.admin;
  }
  get rulesList() {
    return this.rulesState.rulesList;
  }
  get project() {
    return this.projectsState.project;
  }

  /**
   * Updates role
   * @param {number} value v-select (code of user role)
   * @param {object} member current member
   */
  async onRoleChange(value: UserRoleCode, member: IMember) {
    const updatedUser = await this.updateMember({ ...member, ...{ role: value } });
    if (value === UserRoleCode.admin) return;
    this.handleAddDefaultRooms(value, updatedUser);
  }

  /**
   * Updates owner
   * @param {object} event event object of v-switch
   * @param {object} member current member
   */
  onOwnerChange(event: MouseEvent, member: IMember) {
    event.stopPropagation();
    event.preventDefault();

    this.updateMember({ ...member, ...{ owner: !member.owner } });
  }

  // clear items @blur, otherwise it will still show
  // the old result when focusing the autocomplete
  clearItems() {
    this.items = [];
    this.search = '';
  }

  /**
   * Validates email
   * @param {string} s email
   */
  isEmailValid(s: string): boolean {
    // https://stackoverflow.com/a/574698
    if (s.length < 3 || s.length > 254) return false;

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(s.toLowerCase());
  }

  get membersNotificationMap() {
    return this.members.reduce((acc: Record<string, boolean>, m: IMember) => {
      return { ...acc, [m.email]: getIsMemberInRulesAsRecipient(m, this.rulesList, this.conModel) };
    }, {});
  }
  get rulesWithEmailAction(): any {
    return this.rulesState.rulesList.map((rule: IRule) => {
      if (rule.actions.some((action) => action.type === 'email')) {
        return rule;
      } else {
        return undefined;
      }
    }).filter((rule) => rule !== undefined);
  }
  get rulesWithNoMailAction() {
    return this.rulesState.rulesList.map((rule: IRule) => {
      if (!rule.actions.some((action) => action.type === 'email')) {
        return rule;
      } else {
        return undefined;
      }
    }).filter((rule) => rule !== undefined);
  }
  get notActiveRules() {
    return this.rulesState.rulesList.filter((rule: IRule) => !rule.active);
  }

  get isNotificationForSomeMembersEnabled() {
    return this.members.some((m) => getIsMemberInRulesAsRecipient(m, this.rulesList, this.conModel));
  }

  async handleUpdateRules(event: MouseEvent, email: string) {
    event.stopPropagation();
    event.preventDefault();
    this.showLoader = true;
    const hasEmail = has(this.membersNotificationMap, email);
    if (hasEmail && this.membersNotificationMap[email]) {
      this.notificationCheckboxLoading = true;
      await deleteRecipientFromRules(email, this.project.id, this.rulesWithEmailAction, this.conModel);
    } else if (hasEmail && !this.membersNotificationMap[email]) {
      this.notificationCheckboxLoading = true;
      await addMemberToRuleActions(email, this.project.id, this.rulesWithEmailAction, this.rulesWithNoMailAction, this.conModel);
    }
    this.notificationCheckboxLoading = false;
    this.showLoader = false;

    if (this.isInWizard) {
      this.$emit('updateRules', { someUserSelected: this.isNotificationForSomeMembersEnabled });
    }
  }

  showMembersNotificationCheckbox(key: string) {
    return has(this.membersNotificationMap, key);
  }

  async created() {
    this.notificationCheckboxLoading = true;
    await Promise.allSettled([
      this.loadRules(this.$route.params.id),
      this.fetchRooms(this.$route.params.id),
      this.fetchMembers(this.$route.params.id),
    ]).finally(() => {
      this.conModel = cloneDeep(this.project.connectivity);
      this.notificationCheckboxLoading = false;
    });
  }
}
