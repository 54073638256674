
import { Vue, Component, Prop } from 'vue-property-decorator';
import { State, Getter } from 'vuex-class';
import DefaultSchema from '@/ui/components/modals/ManageAnomalyDetection/schemas/DefaultSchema.vue';
import StreamAnomalyDetection
  from '@/ui/components/devices/previews/aiml/StreamAnomalyDetection.vue';
import HistoryAnomalyDetection
  from '@/ui/components/devices/previews/aiml/HistoryAnomalyDetection.vue';
import { IAnomalyDetectionState } from '@/store/modules/anomalyDetection/types';
import PreviewImage from '@/ui/components/components/PreviewImage.vue';
import i18n from '@/ui/plugins/i18n';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';

/**
 * A component that allows to create or modify Anomaly Detection device
 */
@Component({
  components: {
    PreviewImage,
    DefaultSchema,
    StreamAnomalyDetection,
    HistoryAnomalyDetection,
    ModalWindow,
  },
})
export default class ManageAnomalyDetection extends Vue {
  @Prop({ default: '' }) formTitle?: string;
  @Prop({
    default: () => ({
      name: '',
      data: {
        type: '',
        meta: {},
      },
      collection_id: '',
    }),
  }) deviceData?: any;
  @Prop({ default: '' }) activeRoomId!: string;
  @State('anomalyDetection') anomalyDetectionState!: IAnomalyDetectionState;
  @Getter('anomalyDetection/anomalyDetectionTypesWithLocaleNamesList') anomalyDetectionTypesWithLocaleNamesList!: any;
  @Getter('anomalyDetection/lastCreatedAnomalyDetection') lastCreatedAnomalyDetection!: any;

  deviceType = { title: '', value: '' }
  deviceFormView = false
  currentSelectedTheme = '';
  anomalyDetectionTypesArrayList = [];
  isConfirmationDialog = false;

  handleModalWindowStatus(status: boolean) {
    if (status) {
      this.deviceFormView = !!this.deviceData.data.type;
      if (this.deviceData.created_at) {
        this.deviceType.value = this.deviceData.data.type;
      }
      this.anomalyDetectionTypesArrayList = JSON.parse(JSON.stringify(this.anomalyDetectionTypesWithLocaleNamesList));
    } else {
      this.deviceFormView = false;
      this.$store.commit('anomalyDetection/setLastCreatedAnomalyDetection', this.deviceType.value);
      this.anomalyDetectionTypesArrayList = [];
    }
    this.currentSelectedTheme = this.$vuetify.theme.dark ? 'themeDark' : 'themeLight';
  }

  created() {
    this.deviceType.value = this.lastCreatedAnomalyDetectionType;
    this.deviceType.title = this.deviceType.value ? i18n.t(`anomalyDetection.${this.deviceType.value}.previewName`).toString() : '';
  }

  get lastCreatedAnomalyDetectionType() {
    return this.lastCreatedAnomalyDetection;
  }

  /**
   * Define Anomaly Detection preview image according to type
   * @return path to preview image or null if Anomaly Detection don`t have it
   */
  get currentPreviewImage() {
    const currentImgPreview = this.deviceType.value;
    if (currentImgPreview) {
      try {
        return require(`../../../assets/images/previews/${this.currentSelectedTheme}/${currentImgPreview}.png`);
      } catch (e) {
        // maybe load fallback image?
        console.log(e);
        return null;
      }
    } else {
      return null;
    }
  }

  get anomalyDetectionTypes() {
    return this.anomalyDetectionState.anomalyDetectionTypes;
  }
  get currentAnomalyDetectionType() {
    if (this.deviceType.value) {
      return this.anomalyDetectionTypes[this.deviceType.value];
    }
    return {};
  }

  // steps validation
  get typeValidation() {
    return !!this.deviceType?.value?.length;
  }

  get anomalyDetectionDataWithType() {
    const clone = JSON.parse(JSON.stringify(this.deviceData));
    clone.data.type = this.deviceType.value;
    return clone;
  }

  onFormUnchanged(value: boolean) {
    this.isConfirmationDialog = !value;
  }

  closeDialog() {
    (this.$refs.ModalWindowInstance as any).closeDialog();
  }

  handleControl(device: any) {
    this.$emit('handleControl', device);
  }
}
