import api from '@/services/api';

export default {
  async fetchDevices(projectId: string) {
    return api.fetch(`/projects/${projectId}/devices`, 'GET');
  },

  async createDevice(projectId: string, device: any) {
    return api.fetch(`/projects/${projectId}/devices`, 'POST', device);
  },

  async updateDevice(projectId: string, deviceId: string, device: any) {
    return api.fetch(`/projects/${projectId}/devices/${deviceId}`, 'PUT', device);
  },

  async deleteDevice(projectId: string, deviceId: string) {
    return api.fetch(`/projects/${projectId}/devices/${deviceId}`, 'DELETE');
  },

  async addToFavorites(projectId: string, deviceId: string) {
    return api.fetch(`/projects/${projectId}/favorites/${deviceId}`, 'PUT');
  },

  async deleteFromFavorites(projectId: string, deviceId: string) {
    return api.fetch(`/projects/${projectId}/favorites/${deviceId}`, 'DELETE');
  },

  async fetchEnergyData(projectId: string, deviceId: string, start: number, end: number) {
    return api.fetch(`/projects/${projectId}/devices/${deviceId}/energy?start=${start}&end=${end}`, 'GET');
  },

  async transferToRegelenergie(projectId: string, device: any) {
    return api.fetch(`/projects/${projectId}/regelenergie`, 'POST', device);
  },
};
