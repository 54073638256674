
import { Vue, Component, Prop } from 'vue-property-decorator';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import PVSystemCharts from '@/ui/components/devices/devices/devicesCharts/PVSystemCharts.vue';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent PVSystem device
 */
@Component({
  components: {
    OutputFieldBase,
    DeviceLayout,
    LabelUnitWrapper,
    PVSystemCharts,
    InfoTooltip,
  },
})
export default class PVSystem extends Vue {
  @Prop() deviceData!: IDevice;

  get isActualValueVariableFilled() {
    return !!this.deviceData.data.mappings?.OutputField_actualValue?.length;
  }
  get isActualVoltageVariableFilled() {
    return !!this.deviceData.data.mappings?.OutputField2_actualValue?.length;
  }
  get isCurrentFlowVariableFilled() {
    return !!this.deviceData.data.mappings?.OutputField3_actualValue?.length;
  }
}
