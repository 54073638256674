
import { Vue, Component, Prop } from 'vue-property-decorator';
import AnimatedLineArrow
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/AnimatedLineArrow.vue';
import { EnergyLineDisplayData } from '@/types/energyVisualisation/EnergyLineDisplayData';
import LineCross from './LineCross.vue';

@Component({
  components: { AnimatedLineArrow, LineCross },
})
export default class AnimatedLine extends Vue {
  @Prop({}) displayDataItem!: EnergyLineDisplayData;
  @Prop({ default: null }) width!: number;
  @Prop({ default: null }) height!: number;
  @Prop({ default: true }) isLineActive!: boolean;

  ctx: CanvasRenderingContext2D | null = null

  /**
   * Set line color
   * @param {object} context canvas context object
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} x2 end x position
   * @param {number} y2 end y position
   * @param {string} color1 start gradient color
   * @param {string} color2 end gradient color
   */
  lineColor(context: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number, color1: string, color2: string) {
    const grad: any = context.createLinearGradient(x1, y1, x2, y2);
    grad.addColorStop(0, color1);
    grad.addColorStop(1, color2);
    context.strokeStyle = grad;
    context.lineWidth = 2;
  }

  /**
   * Draw line body
   * @param {object} context canvas context object
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} x2 end x position
   * @param {number} y2 end y position
   */
  lineBody(context: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number) {
    context.beginPath();
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);
    context.stroke();
    context.closePath();
  }

  /**
   * Draw line
   * @param {object} context canvas context object
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} x2 end x position
   * @param {number} y2 end y position
   * @param {string} color1 start gradient color
   * @param {string} color2 end gradient color
   */
  drawLine(context: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number, color1: string, color2: string) {
    this.lineColor(context, x1, y1, x2, y2, color1, color2);
    this.lineBody(context, x1, y1, x2, y2);
  }

  changeDirection() {
    this.$emit('changeDirection');
  }

  mounted() {
    const canvas = this.$refs.line as HTMLCanvasElement;
    this.ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    this.drawLine(
      this.ctx,
      this.displayDataItem.endPoint.x,
      this.displayDataItem.endPoint.y,
      this.displayDataItem.startPoint.x,
      this.displayDataItem.startPoint.y,
      this.displayDataItem.colors.colorAtCenter,
      this.displayDataItem.colors.colorAtCircle,
    );
  }
}
