
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component render list of questions for User Manual page
 */
@Component
export default class HeaderList extends Vue {
  @Prop() list!: any;
}
