
import { Vue, Component, Prop } from 'vue-property-decorator';
import DeviceActions from '@/ui/components/devices/actions/DeviceActions.vue';
import ActualViewIO from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/ActualViewIO/index.vue';
import EnergyFlowViewIO from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/EnergyFlowViewIO/index.vue';
import EMSCharts from '@/ui/components/devices/devices/EnergyIO/components/charts/index.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { getSystems } from '@/utils/mpcUtils';
import { IProject } from '@/types/project.types';
import { Getter } from 'vuex-class';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';

/**
 * Component that represent EMS
 */
@Component({
  components: {
    MPCLayout,
    EnergyFlowViewIO,
    ActualViewIO,
    DeviceActions,
    EMSCharts,
    CircleSpinner,
  },
})
export default class EnergyIO extends Vue {
  @Prop() deviceData!: any;
  @Getter('projects/project') project!: IProject;

  loaded = true;

  /**
   * Gets all systems from EMS controllerMappings
   * @return {object|null} collection of systems
   */
  get allSystems() {
    if (this.deviceData?.data?.meta?.controllerMappings) {
      const {
        inputs,
        outputs,
        hybrids,
      } = this.deviceData.data.meta.controllerMappings;
      return {
        inputs,
        outputs,
        hybrids,
      };
    } else {
      return null;
    }
  }

  get mappedDeviceData() {
    return this.deviceData;
  }

  get prediction() {
    if (this.deviceData?.data?.meta?.charts?.prediction) return this.deviceData.data.meta.charts.prediction;
    else return null;
  }

  get predictedPower() {
    if (this.prediction) {
      return getSystems(this.prediction, '_power');
    } else return null;
  }

  get predictedTargetPower() {
    if (this.prediction) {
      return getSystems(this.prediction, '_target_power');
    } else return null;
  }

  get predictedEnergy() {
    if (this.prediction) {
      return getSystems(this.prediction, '_energy');
    } else return null;
  }

  get predictedSOC() {
    if (this.prediction) {
      return getSystems(this.prediction, '_soc');
    } else return null;
  }

  get emsScaling() {
    return this.deviceData?.data?.meta?.scaling;
  }

  /**
   * Convert MPC id from "id-id-id" to "id_id_id"
   */
  get mpcIdMod() {
    if (this.deviceData.id) return this.deviceData.id.replace(/-/g, '_');
    return null;
  }

  async toggleLoading(status: boolean) {
    this.loaded = !status;
  }
}
