import { cloneDeep, merge } from 'lodash';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import { resetEnergyDeviceMappings } from '@/utils/installationWizardUtilsFunctions';
import {
  emsLimitsByType,
} from '@/ui/components/wizards/installationWizard/wizardSettings/wizardLimits';
import {
  shouldSendVariableForType,
} from '@/ui/components/wizards/installationWizard/wizardSettings/mqttVariables';
import { IProject } from '@/types/project.types';
import { IDevice } from '@/types/devices.types';
import {
  IBatterySystem,
  IChargeStationSystem,
  IElectricHeatingElementSystem, IHeatingPumpSystem,
  IIncludedSystemsBigConsumerDefinition,
  IIncludedSystemsChargeStationDefinition,
  IIncludedSystemsHeatingPumpDefinition,
  IIncludedSystemsTypes,
  IMQTTVariable,
  NavigationDirection, WizardPath,
} from '@/types/wizards/installationWizard.types';
import { IWizardLoadingState, IWizardPage } from '@/types/wizards/wizard.general.types';
import { defaultFeedbackTimeoutSeconds } from '@/utils/constants';
import {
  hybridVersionDate,
  plcVersionDate,
} from '@/utils/versionManagementUtils';
import {
  IInstallationWizardState,
} from './types';
import { MQTTFeedbackError } from './MQTTFeedbackError';
import {
  IGNORE_FEEDBACK
} from '@/ui/components/wizards/installationWizard/wizardSettings/developmentConfig';
import { Vue } from 'vue-property-decorator';

const defaultWizardState = (): IInstallationWizardState => {
  return {
    emsDevice: {},
    energyViewDevice: {},
    pilotSystemCount: 0,
    inverterCount: 1,
    stringInverterCount: 0,
    // energy components (not dummy components)
    includedSystemsTypes: {
      battery: {
        isSelected: false,
        count: 0,
        definition: [],
        systems: [],
      },
      electric_heating: {
        isSelected: false,
        count: 0,
        definition: [],
        systems: [],
      },
      charge_station: {
        isSelected: false,
        count: 0,
        definition: [],
        systems: [],
      },
      pv: {
        isSelected: false,
        count: 0,
        definition: [],
      },
      generator: {
        isSelected: false,
        count: 0,
        definition: [],
      },
      heating_pump_consumer: {
        isSelected: false,
        count: 0,
        definition: [],
      },
      heating_pump: {
        isSelected: false,
        count: 0,
        definition: [],
        systems: [],
      },
      big_consumer: {
        isSelected: false,
        count: 0,
        definition: [],
      },
      load_shedding: {
        isSelected: false,
        count: 0,
        definition: [],
      },
      chp: {
        isSelected: false,
        count: 0,
        definition: [],
      },
    },
    currentStep: 1,
    currentPage: 0,
    pages: [
      {
        title: 'information',
        view: 'Information',
        step: 1,
        page: 0,
        dependencies: [],
        path: [WizardPath.STRING_INVERTER, WizardPath.TENANT, WizardPath.TENANT_STRING_INVERTER],
      },
      {
        title: 'ManualsPage',
        view: 'ManualsPage',
        step: 1,
        page: 1,
        dependencies: ['Deye', 'Solarmax'],
        path: [WizardPath.STRING_INVERTER, WizardPath.TENANT, WizardPath.TENANT_STRING_INVERTER],
      },
      {
        title: 'GeneralInformations',
        view: 'GeneralInformations',
        step: 1,
        page: 2,
        dependencies: [],
        path: [WizardPath.STRING_INVERTER, WizardPath.TENANT, WizardPath.TENANT_STRING_INVERTER],
      },
      {
        title: 'CommonEnergyPriceSettings',
        view: 'CommonEnergyPriceSettings',
        step: 1,
        page: 3,
        dependencies: [],
        path: [WizardPath.STRING_INVERTER, WizardPath.TENANT, WizardPath.TENANT_STRING_INVERTER],
      },
      {
        title: 'Questionnaire',
        view: 'Questionnaire',
        step: 1,
        page: 4,
        dependencies: ['Deye', 'Solarmax'],
        availableFrom: hybridVersionDate.getTime(),
        path: [WizardPath.STRING_INVERTER, WizardPath.TENANT, WizardPath.TENANT_STRING_INVERTER],
      },
      {
        title: 'InviteUsersToProject',
        view: 'InviteUsersToProject',
        step: 2,
        page: 0,
        dependencies: [],
        path: [WizardPath.STRING_INVERTER, WizardPath.TENANT, WizardPath.TENANT_STRING_INVERTER],
      },
      {
        title: 'ComponentsPage',
        view: 'ComponentsPage',
        step: 3,
        page: 0,
        dependencies: [],
        path: [WizardPath.STRING_INVERTER, WizardPath.TENANT_STRING_INVERTER],
      },
      {
        title: 'InverterConfiguration',
        view: 'InverterConfiguration',
        step: 4,
        page: 0,
        dependencies: ['Deye'],
        availableFrom: hybridVersionDate.getTime(),
        path: [WizardPath.STRING_INVERTER, WizardPath.TENANT_STRING_INVERTER],
      },
      {
        title: 'PilotSpecification',
        view: 'PilotSpecification',
        step: 4,
        page: 1,
        dependencies: [],
        path: [WizardPath.STRING_INVERTER],
      },
      {
        title: 'AddStringLoggersPage',
        view: 'AddStringLoggersPage',
        step: 4,
        page: 2,
        dependencies: ['Deye'],
      },
      {
        title: 'BatteryGridSettings',
        view: 'BatteryGridSettings',
        step: 5,
        page: 0,
        dependencies: [],
      },
      {
        title: 'GridDrmSettings',
        view: 'GridDrmSettings',
        step: 5,
        page: 1,
        dependencies: ['Deye', 'Solarmax'],
        path: [WizardPath.STRING_INVERTER, WizardPath.TENANT_STRING_INVERTER],
      },
      {
        title: 'GeneratorSettings',
        view: 'GeneratorSettings',
        step: 5,
        page: 2,
        dependencies: ['generator', 'Deye'],
      },
      {
        title: 'ChargingStationSettings',
        view: 'ChargingStationSettings',
        step: 5,
        page: 3,
        dependencies: ['charge_station'],
      },
      {
        title: 'HeatingElementSettings',
        view: 'HeatingElementSettings',
        step: 5,
        page: 4,
        dependencies: ['electric_heating'],
      },
      {
        title: 'HeatingPumpSettings',
        view: 'HeatingPumpSettings',
        step: 5,
        page: 5,
        dependencies: ['heating_pump | heating_pump_consumer', 'Deye', 'Solarmax'],
      },
      {
        title: 'BigConsumerSettings',
        view: 'BigConsumerSettings',
        step: 5,
        page: 6,
        dependencies: ['big_consumer', 'Deye', 'Solarmax'],
      },
      {
        title: 'ChpSettings',
        view: 'ChpSettings',
        step: 5,
        page: 7,
        dependencies: ['chp', 'Deye'],
      },
      {
        title: 'GeneralSettingsEMS',
        view: 'GeneralSettingsEMS',
        step: 5,
        page: 8,
        dependencies: [],
      },
      {
        title: 'SystemTestPage',
        view: 'SystemTestPage',
        step: 6,
        page: 0,
        dependencies: ['Deye'],
      },
      {
        title: 'InstallationPage',
        view: 'InstallationPage',
        step: 6,
        page: 1,
        dependencies: ['!Solarmax'],
      },
      {
        title: 'FinalPageInstallationWizard',
        view: 'FinalPageInstallationWizard',
        step: 6,
        page: 2,
        dependencies: [],
        path: [WizardPath.TENANT_STRING_INVERTER, WizardPath.STRING_INVERTER, WizardPath.TENANT],
      },
    ],
    pagesMobileBatteryDeye: [
      {
        title: 'information',
        view: 'Information',
        step: 1,
        page: 0,
        dependencies: [],
      },
      {
        title: 'GeneralInformations',
        view: 'GeneralInformations',
        step: 1,
        page: 2,
        dependencies: [],
      },
      {
        title: 'InviteUsersToProject',
        view: 'InviteUsersToProject',
        step: 2,
        page: 0,
        dependencies: [],
      },
      {
        title: 'FinalPageInstallationWizard',
        view: 'FinalPageInstallationWizard',
        step: 6, // keep the step at 6 so translations are correct, navigation works also fine
        page: 2,
        dependencies: [],
      },
    ],
    loadingState: {
      isLoading: false,
      loadingCount: 0,
      loadingTotal: 0,
    },
    customTimerLoadingState: {
      isLoading: false,
      loadingCount: 0,
      loadingTotal: 0,
    },
    // Pilot physical devices
    pilot: {
      isSelected: false,
      definition: [],
      count: 0,
    },
    // AC PV Systems
    ACPV: {
      isSelected: false,
      count: 0,
    },
    // external visualisation devices / dummy devices
    externalVisualisations: {
      isSelected: false,
      count: 0,
      definition: [],
    },
    currentPageIndex: 0,
    isPilotPageDone: false,
    wasComponentsPageDone: false,
    savedPilotMapping: {
      ctRelationship: 100,
      lineConfig: [
        { device: { text: '', value: '' }, name: '', disabled: false },
        { device: { text: '', value: '' }, name: '', disabled: false },
        { device: { text: '', value: '' }, name: '', disabled: false },
        { device: { text: '', value: '' }, name: '', disabled: false },
      ],
    },
    navigationDirection: NavigationDirection.forward,
    wasInstallationWizardCompleted: false,
    producerOptionsEnabled: false,
    consumerOptionsEnabled: false,
    externallyOccupiedPilotLines: [],
    disablePilotDevices: false,
    acpvAvailable: false,
    acpvOnLoadAvailable: false,
    serialNumberCheckDone: false,
    wizardBlocked: false,
    isHybrid: false,
    restartSent: false,
    disabledWhileOffline: false,
    hybridSettings: {
      useBothBatteryInputs: false,
    },
    wizardPath: WizardPath.INSTALLATION,
  };
};

const state: IInstallationWizardState = defaultWizardState();

const MAX_RETRIES = 1;
const CHECK_INTERVAL_MS = 1000;
const MAX_CHECKS = defaultFeedbackTimeoutSeconds;

const setCounts = (mappings: Partial<IDevice>) => {
  const controllerMappings = cloneDeep(mappings.data.meta.controllerMappings);
  Object.keys(state.includedSystemsTypes).forEach((mapping: string) => {
    if (controllerMappings[mapping]) {
      controllerMappings[mapping].count = Object.keys(controllerMappings[mapping].components).length;
    }
  });
  mappings.data.meta.controllerMappings = controllerMappings;
  return mappings;
};

const getters: GetterTree<IInstallationWizardState, RootState> = {
  emsDevice(state: IInstallationWizardState): IDevice {
    return state.emsDevice as IDevice;
  },
  energyViewDevice(state: IInstallationWizardState): IDevice {
    return state.energyViewDevice as IDevice;
  },
  pilotSystemCount(state: IInstallationWizardState): number {
    return state.pilotSystemCount;
  },
  includedSystemsTypes(state: IInstallationWizardState): IIncludedSystemsTypes {
    return state.includedSystemsTypes;
  },
  currentPage(state: IInstallationWizardState): number {
    return state.currentPage;
  },
  currentStep(state: IInstallationWizardState): number {
    return state.currentStep;
  },
  loadingState(state: IInstallationWizardState): IWizardLoadingState {
    return state.loadingState;
  },
  customTimerLoadingState(state: IInstallationWizardState): IWizardLoadingState {
    return state.customTimerLoadingState;
  },
  isHybrid(state: IInstallationWizardState): boolean {
    return state.isHybrid;
  },
  wizardPages(state: IInstallationWizardState, _, __, rootGetters): IWizardPage[] {
    const isMobileDeye = rootGetters['projects/isMobileDeye'];
    if (isMobileDeye) {
      // only show the pages that are relevant for the mobile battery deye
      return state.pagesMobileBatteryDeye;
    }

    return state.pages.filter((page: IWizardPage) => {
      const project = rootGetters['projects/project'];
      if (page.availableFrom && plcVersionDate(project).getTime() < page.availableFrom) {
        return false;
      }

      const isDeye = rootGetters['projects/isDeye'];
      const isSolarmax = rootGetters['projects/isSolarmax'];
      const includesSolarmaxDependency = page.dependencies?.includes('Solarmax');
      const includesDeyeDependency = page.dependencies?.includes('Deye');

      let pathAllowed = true;
      if (state.wizardPath === WizardPath.STRING_INVERTER || state.wizardPath === WizardPath.TENANT_STRING_INVERTER || state.wizardPath === WizardPath.TENANT) {
        pathAllowed = page.path ? page.path.some(path => path === state.wizardPath) : false;
      }

      return pathAllowed && page.dependencies?.every((dependency: string) => {
        const isDeyeDependency = dependency.includes('Deye');
        const isSolarmaxDependency = dependency.includes('Solarmax');
        const negate = dependency[0] === '!';
        if ((isDeyeDependency || isSolarmaxDependency) && (includesDeyeDependency && includesSolarmaxDependency)) {
          return (isDeye || isSolarmax) && !negate;
        } else if (isDeyeDependency && includesDeyeDependency && !includesSolarmaxDependency) {
          return isDeye && !negate;
        } else if (isSolarmaxDependency && includesSolarmaxDependency && !includesDeyeDependency) {
          return isSolarmax && !negate;
        } else if (isSolarmaxDependency && negate && !isSolarmax) {
          return !isSolarmax && negate; // dependency '!Solarmax'
        } else {
          if (dependency.includes('|')) {
            // to get the possibility of dependencies that are evaluated with a logical OR
            const orDependencies = dependency.split('|').map((orDependency) => orDependency.trim());
            return orDependencies.some((orDependency) => {
              return (state.includedSystemsTypes as any)[orDependency]?.count > 0;
            });
          }
          return (state.includedSystemsTypes as any)[dependency]?.count > 0;
        }
      });
    });
  },
  considerProjectStatus(): boolean {
    // this can be used to control whether we show the "project offline" alerts etc.
    return process.env.NODE_ENV !== 'development';
  },
  pilot(state: IInstallationWizardState) {
    return state.pilot;
  },
  externalVisualisation(state: IInstallationWizardState) {
    return state.externalVisualisations;
  },
  generatorsOnGeneratorConnections(state: IInstallationWizardState) {
    return state.includedSystemsTypes.generator;
  },
  isPilotPageDone(state: IInstallationWizardState) {
    return state.isPilotPageDone;
  },
  savedPilotMapping(state: IInstallationWizardState) {
    return state.savedPilotMapping;
  },
  navigationDirection(state: IInstallationWizardState) {
    return state.navigationDirection;
  },
  shouldSendVariable: (_, __, ___, rootGetters) => (variable: string) => {
    return shouldSendVariableForType(rootGetters['projects/batterySystemType'], variable);
  },
  emsLimits(_, __, ___, rootGetters) {
    return emsLimitsByType(rootGetters['projects/batterySystemType'], rootGetters['projects/project']);
  },
  ACPV(state: IInstallationWizardState) {
    return state.ACPV;
  },
  wasInstallationWizardCompleted(state: IInstallationWizardState) {
    return state.wasInstallationWizardCompleted;
  },
  connectedLines(state: IInstallationWizardState) {
    const chargeStationExternal = state.includedSystemsTypes.charge_station.definition.filter((value: IIncludedSystemsChargeStationDefinition) => typeof value.connectWithLine === 'number').map((value: IIncludedSystemsChargeStationDefinition) => value.connectWithLine);
    const heatingPumpExternal = state.includedSystemsTypes.heating_pump.definition.filter((value: IIncludedSystemsHeatingPumpDefinition) => typeof value.connectWithLine === 'number').map((value: IIncludedSystemsHeatingPumpDefinition) => value.connectWithLine);
    const heatingPumpConsumerExternal = state.includedSystemsTypes.heating_pump_consumer.definition.filter((value: IIncludedSystemsHeatingPumpDefinition) => typeof value.connectWithLine === 'number').map((value: IIncludedSystemsHeatingPumpDefinition) => value.connectWithLine);
    const bigConsumerExternal = state.includedSystemsTypes.big_consumer.definition.filter((value: IIncludedSystemsBigConsumerDefinition) => typeof value.connectWithLine === 'number').map((value: IIncludedSystemsBigConsumerDefinition) => value.connectWithLine);
    const consumerLines = [...chargeStationExternal, ...heatingPumpExternal, ...heatingPumpConsumerExternal, ...bigConsumerExternal, ...state.externallyOccupiedPilotLines];
    let pilotCount = state.externalVisualisations.count - state.externallyOccupiedPilotLines.length;
    const restOfPilotLines = [];
    for (let i = 1; i <= 4; i++) {
      if (!consumerLines.includes(i) && pilotCount > 0) {
        restOfPilotLines.push(i);
        pilotCount--;
      }
    }
    return [...consumerLines, ...restOfPilotLines];
  },
  wasComponentsPageDone(state: IInstallationWizardState) {
    return state.wasComponentsPageDone;
  },
  producerOptionsEnabled(state: IInstallationWizardState) {
    return state.producerOptionsEnabled ?? false;
  },
  consumerOptionsEnabled(state: IInstallationWizardState) {
    return state.consumerOptionsEnabled ?? false;
  },
  getDisablePilotDevices(state: IInstallationWizardState) {
    return state.disablePilotDevices;
  },
  wizardBlocked(state: IInstallationWizardState) {
    return state.wizardBlocked;
  },
  serialNumberCheckDone(state: IInstallationWizardState) {
    return state.serialNumberCheckDone;
  },
  disabledWhileOffline(state: IInstallationWizardState) {
    return state.disabledWhileOffline;
  },
};

const mutations: MutationTree<IInstallationWizardState> = {
  updateEMSDeviceData(state, device: Partial<IDevice>) {
    state.emsDevice.data.meta.controllerMappings = device.data.meta.controllerMappings;
    state.emsDevice = setCounts(state.emsDevice);
  },
  updateEMSDevice(state, device: IDevice) {
    state.emsDevice = device;
  },
  updateEnergyViewDeviceData(state, device: Partial<IDevice>) {
    state.energyViewDevice.data.meta.controllerMappings = device.data.meta.controllerMappings;
    state.energyViewDevice = setCounts(state.energyViewDevice);
  },
  updateEnergyViewDevice(state, device: IDevice) {
    state.energyViewDevice = device;
  },
  setPilotSystemCount(state: IInstallationWizardState, data: number) {
    state.pilotSystemCount = data;
  },
  setACPVSystemCount(state: IInstallationWizardState, data: number) {
    state.ACPV.count = data;
  },
  setACPVSystemSelection(state: IInstallationWizardState, data: boolean) {
    state.ACPV.isSelected = data;
  },
  setIncludedSystemsTypes(state: IInstallationWizardState, includedSystemsTypes) {
    state.includedSystemsTypes = includedSystemsTypes;
  },
  setCurrentPage(state: IInstallationWizardState, data: number) {
    state.currentPage = data;
  },
  setCurrentStep(state: IInstallationWizardState, data: number) {
    state.currentStep = data;
  },
  setLoadingState(state: IInstallationWizardState, data: IWizardLoadingState) {
    state.loadingState = data;
  },
  setCustomTimerLoadingState(state: IInstallationWizardState, data: IWizardLoadingState) {
    state.customTimerLoadingState = data;
  },
  incrementLoadingSentCount(state: IInstallationWizardState) {
    state.loadingState.loadingCount++;
  },
  resetLoadingState(state: IInstallationWizardState) {
    state.loadingState = {
      isLoading: false,
      loadingCount: 0,
      loadingTotal: 0,
    };
  },
  resetChargeStationDefinition(state: IInstallationWizardState, { index }: { index: number }) {
    const defaultDefinition = {
      systemType: '',
      connectWithLine: '',
      noFreeLoading: false,
      webastoType: 0,
      title: '',
    };
    Vue.set(state.includedSystemsTypes.charge_station.definition, index, defaultDefinition);
  },
  addSystemPropertyValues(
    state: IInstallationWizardState,
    { systemType, data }: {
      systemType: string;
      data: IBatterySystem | IChargeStationSystem | IElectricHeatingElementSystem | IHeatingPumpSystem;
    },
  ) {
    (state.includedSystemsTypes[systemType as keyof IIncludedSystemsTypes] as any).systems.push(data);
  },
  updateSystemPropertyValues(
    state: IInstallationWizardState,
    { systemType, data, systemIndex }: {
      systemType: string;
      data: IBatterySystem | IChargeStationSystem | IElectricHeatingElementSystem | IHeatingPumpSystem;
      systemIndex: number;
    },
  ) {
    (state.includedSystemsTypes[systemType as keyof IIncludedSystemsTypes] as any).systems[systemIndex] = data;
  },
  handleIncludedSystemsTypesSystemSystemsProps(
    state: IInstallationWizardState,
    { systemName, systemIndex, prop, value }: {
      systemName: string;
      systemIndex: number;
      prop: string;
      value: any;
    },
  ) {
    (state.includedSystemsTypes as any)[systemName].systems[systemIndex][prop].value = value;
  },
  handleIncludedSystemsTypesSystemDefinitionProps(
    state: IInstallationWizardState,
    { systemName, systemIndex, prop, value }: {
      systemName: string;
      systemIndex: number;
      prop: string;
      value: any;
    },
  ) {
    (state.includedSystemsTypes as any)[systemName].definition[systemIndex][prop] = value;
  },
  handleIncludedSystemsTypesSystem(
    state: IInstallationWizardState,
    { system, key, data }: { system: string; key: string; data: any },
  ) {
    (state.includedSystemsTypes[system as keyof IIncludedSystemsTypes] as any)[key] = data;
  },
  setEMSSystemCount(state: IInstallationWizardState, { system, count }: any) {
    state.emsDevice.data.meta.controllerMappings[system].count = count;
  },
  setEnergyViewSystemCount(state: IInstallationWizardState, { system, count }: any) {
    state.energyViewDevice.data.meta.controllerMappings[system].count = count;
  },
  reset(state: IInstallationWizardState) {
    state = merge(state, defaultWizardState());
  },
  setPilot(state: IInstallationWizardState, data: any) {
    state.pilot = data;
  },
  setExternalVisualisation(state: IInstallationWizardState, data: any) {
    state.externalVisualisations = data;
  },
  setIsPilotPageDone(state: IInstallationWizardState, data: {}) {
    state.savedPilotMapping = data;
    state.isPilotPageDone = true;
  },
  setNavigationDirection(state: IInstallationWizardState, data: NavigationDirection) {
    state.navigationDirection = data;
  },
  setGeneratorsOnGeneratorConnection(state: IInstallationWizardState, data: any) {
    state.includedSystemsTypes.generator = data;
  },
  setIsPilotPageNotDone(state: IInstallationWizardState) {
    state.isPilotPageDone = false;
    state.savedPilotMapping = {
      ctRelationship: 100,
      lineConfig: [
        { device: { text: '', value: '' }, name: '', disabled: false },
        { device: { text: '', value: '' }, name: '', disabled: false },
        { device: { text: '', value: '' }, name: '', disabled: false },
        { device: { text: '', value: '' }, name: '', disabled: false },
      ],
    };
  },
  setInverterCount(state: IInstallationWizardState, data: number) {
    state.inverterCount = data;
    state.serialNumberCheckDone = false;
  },
  resetSystemsPropInIncludedSystemTypes(state: IInstallationWizardState, systemType: 'battery' | 'electric_heating' | 'charge_station' | 'heating_pump') {
    state.includedSystemsTypes[systemType].systems = [];
  },
  setWasComponentsPageDone(state: IInstallationWizardState) {
    state.wasComponentsPageDone = true;
  },
  setProducerOptionsEnabled(state: IInstallationWizardState, data: boolean) {
    state.producerOptionsEnabled = data;
  },
  setConsumerOptionsEnabled(state: IInstallationWizardState, data: boolean) {
    state.consumerOptionsEnabled = data;
  },
  setExternallyOccupiedPilotLine(state: IInstallationWizardState, data: number) {
    state.externallyOccupiedPilotLines.push(data);
  },
  removeExternallyOccupiedPilotLine(state: IInstallationWizardState, data: number) {
    state.externallyOccupiedPilotLines = state.externallyOccupiedPilotLines.filter((value: number) => value !== data);
  },
  setDisablePilotDevices(state: IInstallationWizardState, data: boolean) {
    state.disablePilotDevices = data;
  },
  setWizardBlocked(state: IInstallationWizardState, data: boolean) {
    state.wizardBlocked = data;
  },
  setSerialNumberCheckDone(state: IInstallationWizardState) {
    state.serialNumberCheckDone = true;
  },
  setRestartSent(state: IInstallationWizardState, data: boolean) {
    state.restartSent = data;
  },
  setDisabledWhileOffline(state: IInstallationWizardState, data: boolean) {
    state.disabledWhileOffline = data;
  },
};

const actions: ActionTree<IInstallationWizardState, RootState> = {
  handleIncrement({ state, getters }) {
    if (state.currentPageIndex + 1 < getters.wizardPages.length) {
      state.currentPageIndex++;
      state.currentPage = getters.wizardPages[state.currentPageIndex].page;
      state.currentStep = getters.wizardPages[state.currentPageIndex].step;
    }
    this.commit('installationWizard/setNavigationDirection', NavigationDirection.forward);
  },

  handleDecrement({ state, getters }) {
    if (state.currentPageIndex !== 0) {
      state.currentPageIndex--;
      state.currentPage = getters.wizardPages[state.currentPageIndex].page;
      state.currentStep = getters.wizardPages[state.currentPageIndex].step;
    }
    this.commit('installationWizard/setNavigationDirection', NavigationDirection.backward);
  },

  initDevices({ commit, rootGetters }) {
    // Define Wizard Settings
    state.wasInstallationWizardCompleted = rootGetters['projects/project'].meta?.wasInstallationWizardCompleted ?? false;
    state.producerOptionsEnabled = rootGetters['projects/project'].meta?.wizardSettings?.producerOptionsEnabled ?? false;
    state.consumerOptionsEnabled = rootGetters['projects/project'].meta?.wizardSettings?.consumerOptionsEnabled ?? false;
    state.hybridSettings = rootGetters['projects/project'].meta?.wizardSettings?.hybridSettings ?? {
      useBothBatteryInputs: false,
    };
    const findByType = (type: string) => rootGetters['devices/allDevices'].find((element: IDevice) => {
      return element.data.type === type;
    });
    const project: IProject = rootGetters['projects/project'];

    // TODO: currently throws error if no EnergyView in project
    const energyView = resetEnergyDeviceMappings(
      cloneDeep(findByType('EnergyViewV2')),
      emsLimitsByType(project.meta.controller.batterySystemType, project),
      state.wasInstallationWizardCompleted,
      rootGetters['projects/batterySystemType'],
      state.consumerOptionsEnabled,
      state.producerOptionsEnabled,
    );
    // TODO: currently throws error if no EMS in project
    const ems = resetEnergyDeviceMappings(
      cloneDeep(findByType('EMSV2')),
      emsLimitsByType(project.meta.controller.batterySystemType, project),
      state.wasInstallationWizardCompleted,
      rootGetters['projects/batterySystemType'],
      state.consumerOptionsEnabled,
      state.producerOptionsEnabled,
    );

    commit('updateEMSDevice', ems);
    commit('updateEnergyViewDevice', energyView);
  },

  publishVariable({ dispatch }, { variableToCheck, value }) {
    return dispatch('mqttClient/publish', {
      records: [{
        n: variableToCheck,
        v: value,
        u: '',
      }],
    }, { root: true });
  },

  async waitForVariableValue({ dispatch, rootState }, { variable, expectedValue }) {
    let checks = 0;
    const resolved = new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        await dispatch('measurements/fetchMeasurements', rootState.projects.projectId, { root: true });
        checks++;
        if (await dispatch('checkVariableMatch', { variable, expectedValue })) {
          clearInterval(interval);
          resolve();
        } else if (checks >= MAX_CHECKS) {
          clearInterval(interval);
          reject();
        }
      }, CHECK_INTERVAL_MS);
    });
    return resolved.then(() => {
      return true;
    }).catch(() => false);
  },

  checkVariableMatch({ rootGetters }, { variable, expectedValue }) {
    try {
      return rootGetters['measurements/measurements'][variable] === expectedValue;
    } catch (error) {
      return false;
    }
  },

  async handleMaxRetries({ dispatch }, { variableToCheck }) {
    if (variableToCheck.isBoolean) {
      const oppositeValue = variableToCheck.expectedValue ? 0 : 1;

      // Try to send the opposite value
      dispatch('publishVariable', { variableToCheck: variableToCheck.sentVariable, value: oppositeValue });
      if (await dispatch('waitForVariableValue', { variable: variableToCheck.name, expectedValue: oppositeValue })) {
        // If the feedback for the opposite value is received, then send the original value again
        dispatch('publishVariable', { variableToCheck: variableToCheck.sentVariable, value: variableToCheck.expectedValue });
        if (!await dispatch('waitForVariableValue', { variable: variableToCheck.name, expectedValue: variableToCheck.expectedValue })) {
          throw new MQTTFeedbackError(
            `Expected MQTT feedback for variable ${variableToCheck.name} with value ${variableToCheck.expectedValue}, but timeout was reached.`,
            variableToCheck.name,
          );
        }
      } else {
        // No feedback after sending the opposite value
        throw new MQTTFeedbackError(
          `Expected MQTT feedback for variable ${variableToCheck.name} with value ${oppositeValue}, but timeout was reached.`,
          variableToCheck.name,
        );
      }
    } else {
      throw new MQTTFeedbackError(
        `Expected MQTT feedback for variable ${variableToCheck.name} with value ${variableToCheck.expectedValue}, but timeout was reached.`,
        variableToCheck.name,
      );
    }
  },

  async retryOnFail({ dispatch, commit, rootGetters, rootState }, { retries = 0, variableToCheck }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      await dispatch('measurements/fetchMeasurements', rootState.projects.projectId, { root: true });
      // Wait for initially sent value and resolve if feedback is received
      if (await dispatch('waitForVariableValue', { variable: variableToCheck.name, expectedValue: variableToCheck.expectedValue })) {
        return resolve();
      }

      // If feedback is not received, try to send the value again
      if (retries < MAX_RETRIES) {
        try {
          dispatch('publishVariable', { variableToCheck: variableToCheck.sentVariable, value: variableToCheck.expectedValue });
          await dispatch('retryOnFail', { retries: retries + 1, variableToCheck });
          return resolve();
        } catch (error) {
          commit('resetLoadingState');
          return reject(error);
        }
      } else {
        try {
          await dispatch('handleMaxRetries', { variableToCheck });
          resolve();
        } catch (error) {
          commit('resetLoadingState');
          reject(error);
        }
      }
    });
  },

  async waitForMQTTFeedback(
    { dispatch, commit },
    variableToCheck?: { name: string; sentVariable: string; expectedValue: string | number; isBoolean?: boolean; customTimeout?: number; quickSend?: boolean },
  ): Promise<void> {
    const check = IGNORE_FEEDBACK;

    if (variableToCheck && variableToCheck.customTimeout) {
      return new Promise((resolve) => {
        let i = variableToCheck.customTimeout ?? 0;
        const interval = setInterval(() => {
          if (variableToCheck.customTimeout && i > 0) {
            commit('setCustomTimerLoadingState', {
              isLoading: true,
              loadingCount: i,
              loadingTotal: variableToCheck.customTimeout,
            });
            i--;
          } else {
            commit('setCustomTimerLoadingState', {
              isLoading: false,
              loadingCount: 0,
              loadingTotal: 0,
            });
            clearInterval(interval);
            resolve();
          }
        }, 1000);
      });
    }

    if (check || !variableToCheck || (variableToCheck.quickSend && !variableToCheck.customTimeout)) {
      // no variable to check was passed -> we just wait 1s and resolve
      const timeout = variableToCheck?.quickSend ? 200 : 1000;
      return new Promise((resolve) => setTimeout(() => {
        resolve();
      }, timeout));
    }

    // we have some variable to check on -> check for 15 seconds if the expectd result comes back and retry after first try
    await dispatch('retryOnFail', { retries: 0, variableToCheck });
  },

  async sendVariables({ dispatch, commit, rootGetters, rootState }, variablesToSend: IMQTTVariable[]): Promise<void> {
    // start loading
    commit('setLoadingState', {
      isLoading: true,
      loadingCount: 0,
      loadingTotal: variablesToSend.length,
    });

    // call send function for every this.settings property in for loop in typescript
    // eslint-disable-next-line no-restricted-syntax
    for (const element of variablesToSend) {
      // eventually parse float (leaves ints unchanged)
      const valueToSend = parseFloat(element.value.toString());
      // Fetch measurements for updated value online
      await dispatch('measurements/fetchMeasurements', rootState.projects.projectId, { root: true });
      const variable = rootGetters['measurements/measurements'][element.variable];

      if (element.variable !== '') {
        dispatch('mqttClient/publish', {
          records: [{
            n: element.variable,
            v: valueToSend,
            u: '',
          }],
        }, { root: true });
      }
      commit('incrementLoadingSentCount');
      const noFeedback = element.quickSend ? { quickSend: true, customTimeout: element.customTimeout ?? 0 } : undefined;
      const shouldWaitForFeedback = element.feedbackVariable !== undefined && variable !== valueToSend;
      const isBoolean = !!element.isBoolean;
      // eslint-disable-next-line no-nested-ternary
      await dispatch('waitForMQTTFeedback', shouldWaitForFeedback
        ? {
          name: element.feedbackVariable,
          sentVariable: element.variable,
          expectedValue: !element.customExpectedValue ? valueToSend : element.customExpectedValue,
          isBoolean,
          customTimeout: element.customTimeout,
        }
        : noFeedback);
    }
    commit('resetLoadingState');
  },
};

export const installationWizard: Module<IInstallationWizardState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
