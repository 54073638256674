
import { Vue, Component } from 'vue-property-decorator';
import StreamAnomalyDetection from '@/ui/components/devices/previews/aiml/StreamAnomalyDetection.vue';
import HistoryAnomalyDetection from '@/ui/components/devices/previews/aiml/HistoryAnomalyDetection.vue';

@Component({
  components: {
    StreamAnomalyDetection,
    HistoryAnomalyDetection,
  },
})
export default class AnomalyDetectionPreviewsList extends Vue {
  aimlDevices = ['StreamAnomalyDetection', 'HistoryAnomalyDetection']
}
