
import { Vue, Component } from 'vue-property-decorator';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';
import PushButtonMock from '@/ui/components/devices/previews/components/PushButtonMock.vue';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    InputFieldMock,
    ShowEventDotMock,
    PushButtonMock,
    SwitchMock,
    DeviceLayout,
  },
})
export default class VentilationPreview extends Vue {
  get mainViewLang() {
    return 'devices.Ventilation.mainView';
  }
  get previewNameLang() {
    return this.$t('devices.Ventilation.previewName');
  }
}
