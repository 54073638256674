
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseChartWrapper
  from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';
import OfflineChart from '@/ui/components/devices/previews/charts/components/OfflineChart.vue';
import SystemsNavigation
  from '@/ui/components/devices/mpc/SetpointOptimizer/components/SystemsNavigation.vue';
import {
  defineInstancesGroups,
  getVariableValue,
  getChartSystemInstanceName,
} from '@/ui/components/devices/components/EnergyParts/EMS/utils';
import { ICurrentSystem, ISystemsVars } from '@/types/common.types';

@Component({
  components: {
    BaseChartWrapper,
    SystemsNavigation,
    OfflineChart,
  },
})

export default class EnergyFlow extends Vue {
  @Prop() scaling!: any;

  reRenderKey = 0;
  systems: string[] = ['charge_station', 'electric_heating', 'heating_pump', 'big_consumer'];
  systemsInstancesSplitBy = 8;
  systemsVars: ISystemsVars = {
    charge_station: ['power'],
    electric_heating: ['power'],
    heating_pump: ['power'],
    big_consumer: ['power'],
  }
  currentChart: ICurrentSystem = {
    system: null,
    instances: [],
  }

  /**
   * returns mock mockSystemsMappings
   */
  get mockSystemsMappings() {
    const mappings: any = {
      battery: {
        components: {},
        count: 0,
      },
      big_consumer: {
        components: {},
        count: 0,
      },
      charge_station: {
        components: {},
        count: 0,
      },
      electric_heating: {
        components: {},
        count: 0,
      },
      generator: {
        components: {},
        count: 0,
      },
      grid: {
        components: {},
        count: 0,
      },
      heating_pump: {
        components: {},
        count: 0,
      },
      house: {
        components: {},
        count: 0,
      },
      pv: {
        components: {},
        count: 0,
      },
    };

    Object.entries(mappings).forEach((mapping: any) => {
      const count = Math.floor(Math.random() * 10) + 1;
      for (let i = 0; i < count; i++) {
        const name = `${mapping[0]}${i + 1}`;
        mappings[mapping[0]].components[name] = {};
      }
      mapping[1].count = count;
    });
    return mappings;
  }

  /**
   * Creates array of systems groups and system instances.
   * @return {array} list of system groups. Example: ['battery', [['battery1', 'battery2']]]
   */
  get systemSchema() {
    const filteredSystems = Object.entries(this.mockSystemsMappings)
      .filter((system: any) => this.systems.some((el: any) => el === system[0] && system[1].count));
    return filteredSystems.map((el: any) => {
      return defineInstancesGroups(el, this.systemsInstancesSplitBy, '', this.mockSystemsMappings);
    }).filter((system: any) => system?.[1].length);
  }

  /**
   * Collection of systems scaling
   */
  get systemsScaling(): any {
    return {
      charge_station: {
        power: { min: undefined, max: undefined },
      },
      electric_heating: {
        power: { min: undefined, max: undefined },
      },
      heating_pump: {
        power: { min: undefined, max: undefined },
      },
      big_consumer: {
        power: { min: undefined, max: undefined },
      },
    };
  }

  /**
   * House system options data
   */
  get houseConsumptionPowerVariablesList() {
    return Object.values(this.mockSystemsMappings.house.components)
      .map((instance: any) => instance.power)
      .filter((variable: string) => variable);
  }
  get houseConsumptionPowerVariablesExpression() {
    return this.houseConsumptionPowerVariablesList.join('+');
  }
  get houseChartOptions() {
    return {
      calculation: {
        aggregations: [...this.houseConsumptionPowerVariablesList].fill('avg'),
        expression: `max(${this.houseConsumptionPowerVariablesExpression}, 0)`,
      },
      name: 'House Consumption',
      scaling: {
        min: undefined,
        max: undefined,
      },
      seriesType: 'Calculation',
      type: 'line',
      unit: 'kW',
    };
  }

  /**
   * Systems options data
   */
  get systemsChartOptions() {
    if (!this.currentChart.instances.length) return [];

    const arr2d = this.currentChart.instances.map((instance: string) => {
      return this.systemsVars[this.currentChart.system as string].map((variable: string) => {
        return {
          calculation: {
            aggregations: ['avg'],
            expression: `max(${getVariableValue(this.mockSystemsMappings, this.currentChart.system as string, instance, variable)}, 0)`,
          },
          name: getChartSystemInstanceName(this.mockSystemsMappings, this.currentChart.system as string, instance, variable),
          scaling: this.systemsScaling[this.currentChart.system as string][variable],
          seriesType: 'Calculation',
          type: 'line',
          unit: 'kW',
        };
      });
    });
    return arr2d.flat();
  }

  /**
   * Creates an understandable object for the graphs component
   */
  get currentChartData() {
    const chartOptions = [this.houseChartOptions, ...this.systemsChartOptions];
    return {
      title: null,
      data: {
        chartTitle: '',
        chartWidth: null,
        chartHeight: 600,
        chartWrapperHeight: 750,
        chartData: {
          data: { chartOptions },
        },
        navigationItemsToExclude: ['live', 'hour'],
      },
    };
  }

  get showCurrentChartData() {
    return this.currentChartData?.data?.chartData?.data?.chartOptions?.length;
  }

  handleCurrentChart(params: {system: string; arr: string[]}) {
    this.currentChart = { system: params.system, instances: params.arr };
    this.reRenderKey += 1;
  }

  /**
   * Init what system chart will load when open page.
   */
  initFirstSystemByDefault() {
    const filtered: any = this.systemSchema.filter((el: any) => el[1].length);
    this.currentChart = filtered.length
      ? {
        system: filtered[0][0],
        instances: filtered[0][1][0],
      } : {
        system: null,
        instances: [],
      };
  }

  created() {
    this.initFirstSystemByDefault();
  }
}
