
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ShowEventDotMock extends Vue {
  @Prop() color!: string;
  @Prop() isActive!: number;
  @Prop({ default: 20 }) size!: number | string;

  get warningColor() {
    return this.isActive ? this.color : '#bebebe';
  }
}
