
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import LeaveConfirmationModal from '@/ui/components/modals/LeaveConfirmationModal.vue';

@Component({
  components: { LeaveConfirmationModal },
})
export default class ModalWindow extends Vue {
  @Prop({ default: null }) maxWidth!: number | string;
  @Prop({ default: true }) padding!: boolean;
  @Prop({ default: false }) disableCloseButton!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) persistent!: boolean;
  @Prop({ default: 'unset' }) width!: string | number;
  @Prop({ default: false }) isConfirmationDialog!: boolean;

  dialog = false;

  bodyStyle = {
    padding: this.padding ? '20px' : '0',
  };

  @Watch('dialog')
  dialogChange(status: boolean) {
    this.$emit('dialogStatus', status);
  }

  onConfirmationDialogClose() {
    this.dialog = false;
  }

  async closeDialog() {
    if (this.isConfirmationDialog) {
      await (this.$refs.confirmationModal as LeaveConfirmationModal).confirmAction();
    } else {
      this.dialog = false;
    }
  }
}
