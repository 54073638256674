
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import ReportExpansionPanel from '@/ui/components/modals/ManageReport/components/ReportsExpansionPanel.vue';
import { IReport } from '@/types/report.types';
import { IReportState } from '@/store/modules/report/types';

enum ReportType {
  normal = 'normal',
  zev = 'zev',
  heatingReport = 'heatingReport',
  coldWaterReport = 'coldWaterReport',
  hotWaterReport = 'hotWaterReport',
  electricityReport = 'electricityReport',
  otherReport = 'otherReport',
}

/**
 * Report page that shows reports list
 */
@Component({
  components: {
    ReportExpansionPanel,
  },
})
export default class Report extends Vue {
  @State('report') reportState!: IReportState;
  @Getter('report/getReportFilter') getReportFilter!: string;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;
  @Action('report/loadReports') loadReports!: (project_id: string) => Promise<void>;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;

  isReportsLoaded = false;

  reportsTypesListToShow = [
    ReportType.normal,
    ReportType.zev,
    ReportType.heatingReport,
    ReportType.coldWaterReport,
    ReportType.hotWaterReport,
    ReportType.electricityReport,
    ReportType.otherReport,
  ];

  // All reports stored in store
  get reportList() {
    return this.reportState.reportList;
  }

  get reportListFilteredBySearch() {
    if (this.getReportFilter?.length) {
      return this.reportList
        .filter((currentReport: IReport) => currentReport.name.toLowerCase()
          .includes(this.getReportFilter.toLowerCase()));
    } else {
      return this.reportList;
    }
  }

  get reportsListToShow() {
    return this.reportsTypesListToShow.map((type: ReportType) => {
      return {
        key: type,
        items: this.reportFilter(this.reportListFilteredBySearch, type),
      };
    });
  }
  get reportsListToShowLength() {
    return this.reportsListToShow
      .flatMap((item: { key: ReportType; items: IReport[] }) => item.items)
      .length;
  }

  reportFilter(reports: IReport[], type: ReportType) {
    if ([ReportType.normal, ReportType.zev].includes(type)) {
      return reports.filter((report: IReport) => report.type === type && (report.meta.showInList === type || !report.meta.showInList));
    } else {
      return reports.filter((report: IReport) => report.meta.showInList === type);
    }
  }

  async created() {
    this.setProjectLoader(true);
    await Promise.allSettled([
      this.loadReports(this.$route.params.id),
      this.fetchMeasurements(this.$route.params.id),
      this.fetchVariables(this.$route.params.id),
    ]).finally(() => {
      this.setProjectLoader(false);
      this.isReportsLoaded = true;
    });
  }
}
