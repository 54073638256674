
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import { IDevice } from '@/types/devices.types';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';

@Component({
  components: {
    BaseChartWrapper,
    ModalWindow,
  },
})
export default class ElectricHeatingTemperatureChart extends mixins(EnergyComponent) {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: 1 }) startIndex!: number; // start index of the current page opened in the SystemDialog
  @Prop({ default: 10 }) maxOfSystemsVisible!: number; // max amount of systems visible in the SystemDialog

  chartSeriesArray: any = [];
  isTooltip = false;
  variablesToCheck = ['temperature']; // variables that will be added to the chartSeriesArray and shown as chart series

  /**
   * Generates chart series data based on the startIndex of the current page opened in the SystemDialog and the maxOfSystemsVisible
   */
  generateChartSeriesData() {
    this.chartSeriesArray = [];
    const electricHeatingComponents = this.deviceData.data.meta.controllerMappings.electric_heating.components;
    for (let index = this.startIndex; index <= this.startIndex + this.maxOfSystemsVisible; index++) {
      if (electricHeatingComponents[`electric_heating${index}`]) {
        const mappings = electricHeatingComponents[`electric_heating${index}`];
        const name = `${this.$t('devices.EnergyView.ChartsWindow.legend.electric_heating.temperature')} - ${mappings.title}`;
        this.chartSeriesArray.push({
          agg: 'avg',
          name,
          scaling: {
            max: 120,
            min: 0,
          },
          type: 'line',
          unit: '°C',
          var: mappings.temperature,
        });
      }
    }
  }

  /**
   * returns chart config
   */
  chartConfig() {
    return {
      chartWidth: null,
      chartHeight: 600,
      chartData: {
        name: '',
        data: {
          chartOptions: this.chartSeriesArray,
          selectedStackingOptions: null,
          selectedWidth: 'full',
          type: 'chart',
        },
      },
      navigationItemsToExclude: ['live', 'hour'],
    };
  }
}
