
import { Vue, Component, Prop } from 'vue-property-decorator';
import PlusBase from '@/ui/components/devices/devices/base/PlusBase.vue';
import MinusBase from '@/ui/components/devices/devices/base/MinusBase.vue';
import ForwardBase from '@/ui/components/devices/devices/base/ForwardBase.vue';
import ReverseBase from '@/ui/components/devices/devices/base/ReverseBase.vue';
import SwitchBase from '@/ui/components/devices/devices/base/SwitchBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent TV device
 */
@Component({
  components: {
    PlusBase,
    MinusBase,
    ForwardBase,
    ReverseBase,
    SwitchBase,
    DeviceLayout,
  },
})
export default class TV extends Vue {
  @Prop() deviceData!: IDevice;
}
