
import { Vue, Component } from 'vue-property-decorator';
import OfflineChart from '@/ui/components/devices/previews/charts/components/OfflineChart.vue';

@Component({
  components: { OfflineChart },
})
export default class EnergyFlowConsumptionChart extends Vue {
  chartData: any = null;
  chartRendered = false;
  multiplier: any = {
    day: 1,
    week: 7,
    month: 30,
    year: 300,
  }

  randomNumber(min = 0, max = 10, percantage=false) {
    const multiplier = percantage ? 1 : this.multiplier[(this.$refs.Chart as any).periodName];
    return this.roundNumber(((Math.random() * (max - min)) + min) * multiplier);
  }

  // contains Chart Object
  get mockChartData() {
    let currentChartOptions: any = [];
    currentChartOptions = [
      {
        agg: 'first',
        name: this.$t('mlModel.EMS.charts.chartDifference.directConsumption').toString(),
        scaling: {
          max: '100',
          min: '0',
        },
        seriesType: 'View',
        type: 'line',
        unit: 'kWh',
        var: '',
      }, {
        agg: 'first',
        name: this.$t('mlModel.EMS.charts.chartDifference.batteryToConsumer').toString(),
        scaling: {
          max: '100',
          min: '0',
        },
        seriesType: 'View',
        type: 'line',
        unit: 'kW',
        var: '',
      }, {
        agg: 'first',
        name: this.$t('mlModel.EMS.charts.chartDifference.productionBattery').toString(),
        scaling: {
          max: '100',
          min: '0',
        },
        seriesType: 'View',
        type: 'line',
        unit: 'kW',
        var: '',
      }, {
        agg: 'first',
        name: this.$t('mlModel.EMS.charts.chartDifference.gridToConsumer').toString(),
        scaling: {
          max: '100',
          min: '0',
        },
        seriesType: 'View',
        type: 'line',
        unit: 'kW',
        var: '',
      },
    ];

    const currentData = {
      data: {
        chartOptions: currentChartOptions,
        selectedStackingOptions: 'normal',
        selectedWidth: 'full',
        type: 'chart',
      },
      created_at: '2021-10-07T07:43:33.554983Z',
    };
    return currentData;
  }

  roundNumber(num: number) {
    return Number(num.toFixed(2));
  }

  created() {
    this.chartData = this.mockChartData;
  }

  mounted() {
    this.chartRendered = true;
  }
}
