
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import HeatingCircuitOptimizationPreview from '@/ui/components/devices/previews/mpc/HeatingCircuitOptimization/index.vue';
import PVProductionServicePreview from '@/ui/components/devices/previews/mpc/PVProductionService/index.vue';
import ConsumptionServicePreview from '@/ui/components/devices/previews/mpc/ConsumptionService/index.vue';
import HppProductionServicePreview from '@/ui/components/devices/previews/mpc/HeatingProduction/index.vue';
import PVMonitoringServicePreview
  from '@/ui/components/devices/previews/mpc/PVMonitoringService/index.vue';
import EMSPreview from '@/ui/components/devices/previews/mpc/EMS/index.vue';
import ServicesSchema from '@/ui/components/modals/ManageMLModel/schemas/ServicesSchema.vue';
import HCOSchema from '@/ui/components/modals/ManageMLModel/schemas/HCOSchema.vue';
import EMSSchema from '@/ui/components/modals/ManageMLModel/schemas/EMSSchema.vue';
import DefaultSchema from '@/ui/components/modals/ManageMLModel/schemas/DefaultSchema.vue';
import PreviewImage from '@/ui/components/components/PreviewImage.vue';
import i18n from '@/ui/plugins/i18n';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { IMlModelSchemas } from '@/types/mpc/mlModelSchemas.types';
import { IMLModel } from '@/types/mpc/mpc.types';

/**
 * A component that allows to create or modify Ml Model device
 */
@Component({
  components: {
    PreviewImage,
    PVMonitoringServicePreview,
    EMSPreview,
    HCOSchema,
    ServicesSchema,
    HeatingCircuitOptimizationPreview,
    PVProductionServicePreview,
    ConsumptionServicePreview,
    EMSSchema,
    DefaultSchema,
    HppProductionServicePreview,
    ModalWindow,
  },
})
export default class ManageMLModel extends Vue {
  @Prop({ default: '' }) formTitle?: string;
  @Prop({
    default: () => ({
      name: '',
      data: {
        type: '',
        meta: {},
      },
      collection_id: '',
    }),
  }) deviceData?: IMLModel;
  @Prop({ default: '' }) activeRoomId!: string;
  @Getter('mpc/mlModelsTypesWithLocaleNamesList') mlModelsTypesWithLocaleNamesList!: { title: string; value: string }[];
  @Getter('mpc/mlModelTypes') mlModelTypes!: IMlModelSchemas;
  @Getter('mpc/lastCreatedModel') lastCreatedModel!: string;

  isTooltip = false;
  deviceType = { title: '', value: '' }
  deviceFormView = false
  currentSelectedTheme = '';
  imageMaxHeight = window.innerHeight * 0.7;
  mlModelsTypesArrayList = [];
  isConfirmationDialog = false;

  /**
   * Controls deviceFormView status, deviceType, currentSelectedTheme according to dialog status
   * @param status dialog status
   */
  handleModalWindowStatus(status: boolean) {
    if (status) {
      this.deviceFormView = !!this.deviceData?.data.type;
      if (this.deviceData?.created_at) {
        this.deviceType.value = this.deviceData.data.type;
      }
      this.mlModelsTypesArrayList = JSON.parse(JSON.stringify(this.mlModelsTypesWithLocaleNamesList));
    } else {
      this.deviceFormView = false;
      this.$store.commit('mpc/setLastCreatedModel', this.deviceType.value);
      this.mlModelsTypesArrayList = [];
    }
    this.currentSelectedTheme = this.$vuetify.theme.dark ? 'themeDark' : 'themeLight';
  }

  get isEditModal() {
    return !!this.deviceData?.data.type;
  }

  get lastCreatedModelType() {
    return this.lastCreatedModel;
  }

  /**
   * Define ML Model preview image according to type
   * @return path to preview image or null if Ml Model don`t have it
   */
  get currentPreviewImage() {
    this.currentSelectedTheme = this.$vuetify.theme.dark ? 'themeDark' : 'themeLight';
    const currentImgPreview = this.deviceType.value;
    if (currentImgPreview) {
      try {
        return require(`../../../assets/images/previews/${this.currentSelectedTheme}/${currentImgPreview}.png`);
      } catch (e) {
        // maybe load fallback image?
        console.log(e);
        return null;
      }
    } else {
      return null;
    }
  }

  // steps validation
  get typeValidation() {
    return !!this.deviceType?.value?.length;
  }

  get currentMLModelType() {
    if (this.deviceType.value) {
      return this.mlModelTypes[this.deviceType.value as keyof IMlModelSchemas];
    }
    return { manageSchema: '' };
  }
  get mlModelDataWithType() {
    const clone = JSON.parse(JSON.stringify(this.deviceData));
    clone.data.type = this.deviceType.value;
    return clone;
  }

  onFormUnchanged(value: boolean) {
    this.isConfirmationDialog = !value;
  }

  closeDialog() {
    (this.$refs.ModalWindowInstance as ModalWindow).closeDialog();
  }

  handleControl(device: IMLModel) {
    this.$emit('handleControl', device);
  }

  created() {
    this.deviceType.value = this.lastCreatedModelType;
    this.deviceType.title = this.deviceType.value
      ? i18n.t(`mlModel.${this.deviceType.value}.previewName`).toString()
      : '';
  }
}
