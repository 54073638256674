
import { Vue, Component, Prop } from 'vue-property-decorator';
import DataExport from '@/ui/components/devices/charts/components/DataExport.vue';
import stockInit from 'highcharts/modules/stock';
import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';

stockInit(Highcharts);

@Component({
  components: {
    highcharts: Chart,
    DataExport,
  },
})
export default class SimpleChart extends Vue {
  @Prop() chartType!: string;
  @Prop() chartTitle!: string;
  @Prop() series!: any;
  @Prop() yAxis!: any;
  @Prop({ default: null }) chartWidth!: any;
  @Prop({ default: 400 }) chartHeight!: any;

  get chartOptions() {
    return {
      chart: {
        animation: false,
        backgroundColor: this.$vuetify.theme.currentTheme.deviceBackground?.toString(),
        width: null,
        height: null,
        events: {
          redraw: (e: any) => {
            this.handleFullScreenChartName(e);
          },
        },
      },
      exporting: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      series: this.series,
      legend: {
        enabled: true,
        itemStyle: {
          color: this.$vuetify.theme.dark ? '#fff' : '#000',
        },
      },
      plotOptions: {
        series: {
          connectNulls: true,
        },
      },
      xAxis: {},
      yAxis: this.yAxis,
    };
  }

  handleFullScreen() {
    const { chart } = this.$refs.hc as any;
    chart.fullscreen.open();
  }
  handleFullScreenChartName(e: any) {
    const { chart } = this.$refs.hc as any;
    if (!chart.fullscreen.isOpen) {
      chart.setTitle({ text: null });
    } else {
      chart.setTitle({ text: this.chartTitle });
    }
  }
}
