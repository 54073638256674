
import { Vue, Component, Prop } from 'vue-property-decorator';
import SystemsInstanceControlsTemplate
  from '@/ui/components/devices/mpc/SetpointOptimizer/system/SystemInstanceControls/SystemsInstanceControlsTemplate.vue';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';
import colors from '@/ui/components/devices/mpc/colors';

@Component({
  components: {
    SystemsInstanceControlsTemplate,
    InputFieldMock,
    LabelUnitWrapper,
    ShowEventDotMock,
  },
})
export default class DefaultSettings extends Vue {
  @Prop({ default: null }) systemInstanceType!: string;

  get isAdditionalFields() {
    const systems: any = ['heating_air_systems', 'cooling_air_systems'];
    return systems.some((el: string) => el === this.systemInstanceType);
  }

  get currentShowEventColor() {
    return {
      heating_air_systems: this.colors.red,
      heating_water_systems: this.colors.red,
      cooling_air_systems: this.colors.blue,
      cooling_water_systems: this.colors.blue,
    };
  }

  get colors() {
    return colors;
  }
}
