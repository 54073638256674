import mqtt, { PacketCallback } from 'mqtt';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IMQTTState } from '@/store/modules/mqtt/types';
import Vue from 'vue';
import router from '@/router';
import { RootState } from '@/store/types';
import { IPublishRecord } from '@/types/mqtt.types';
import MeasurementService from '@/services/MeasurementService';
import { defaultFeedbackTimeoutMilliSeconds } from '@/utils/constants';

const state: IMQTTState = {
  client: null,
  online: false,
  internet: false,
  timer: null,
  internetTimer: null,
  lastHeartbeatMessage: 0,
  lastInternetHeartbeatMessage: 0,
};

const getters: GetterTree<IMQTTState, RootState> = {
  mqttClientInfo(state, getters, rootState, rootGetters) {
    if (state.client) {
      const {
        hostname, port, password, username,
      } = state.client.options;
      return {
        username,
        password,
        port: '8883',
        hostname,
        topicPublish: `projects/${rootGetters['projects/project'].id}/messages`, // configuration valid for a client's device
        topicSubscribe: `projects/${rootGetters['projects/project'].id}/messages2`, // configuration valid for a client's device
      };
    }
    return null;
  },
  isProjectOnline(state: IMQTTState): boolean {
    return state.online;
  },
  isProjectConnected(state: IMQTTState): boolean {
    return state.internet;
  },
};

const mutations: MutationTree<IMQTTState> = {
  setOnline(state, status) {
    state.online = status;
  },
  setInternet(state, status) {
    state.internet = status;
  },
  clearTimer(state) {
    clearTimeout(state.timer);
    state.timer = null;
  },
  clearInternetTimer(state) {
    clearTimeout(state.internetTimer);
    state.internetTimer = null;
  },
  setLastHeartbeatMessage(state, value) {
    state.lastHeartbeatMessage = value;
  },
  setInternetHeartbeatMessage(state, value) {
    state.lastInternetHeartbeatMessage = value;
  },
};

const actions: ActionTree<IMQTTState, RootState> = {
  publish({ commit, state, getters }, { records, callback }: { records: IPublishRecord[]; callback: PacketCallback }) {
    const string = JSON.stringify(records);
    state.client?.publish(getters.mqttClientInfo?.topicSubscribe!, string, {}, callback);
    records.forEach((record: any) => commit('measurements/setMeasurement', record, { root: true }));
  },
  async createConnection({ commit, state, getters, rootGetters, dispatch }) {
    // generate more random clientId than default. use same as in app. (length 23)
    const project = rootGetters['projects/project'];
    let host = `wss://api.lynus.io/v1/projects/${project.id}/mqtt?token=${Vue.prototype.$keycloak.token}`; // eslint-disable-line
    state.client = mqtt.connect(host, {
      username: 'test',
      password: 'test',
      transformWsUrl: () => {
        return `wss://api.lynus.io/v1/projects/${project.id}/mqtt?token=${Vue.prototype.$keycloak.token}`;
      },
    });
    state.client.on('connect', () => {
      // topicPublish on purpose used here, as topics are reversed with respect to client's devices
      state.client?.subscribe(getters.mqttClientInfo?.topicPublish!, (err: any) => {
        if (err) console.error(err);
      });
    });

    state.client.on('message', (topic: string, message: any) => {
      const formatting = String.fromCharCode.apply(null, message);
      const isHeartbeatMessage: boolean = formatting.includes('"vs":"hb"');
      const isOnlineMessage: boolean = formatting.includes('"vs":"hb2"');

      const isSerialNumberMessage: boolean = formatting.includes('sI_SN');

      if (isSerialNumberMessage) {
        const records = JSON.parse(formatting) as any[];
        records.forEach((record: any) => {
          if (record.n.includes('sI_SN')) {
            const serialNumber = {
              name: record.n,
              value: record.vs,
            };
            if (serialNumber.value !== '-1' && serialNumber.value !== '#EMPTY#') {
              commit('serialNumber/setSerialNumber', serialNumber, { root: true });
            }
          }
        });
      }

      if (router.currentRoute?.meta?.mqtt) {
        const records = JSON.parse(formatting) as any[];
        records.forEach((record: any) => {
          if (record.vs === 'hb') {
            if (record.n) {
              const { vs, ...rec } = record;
              commit('measurements/setMeasurement', rec, { root: true });
            }
          } else {
            commit('measurements/setMeasurement', record, { root: true });
          }
        });
      }

      // if heartbeat message arrives at least once every 30 seconds, set online to true
      if (isHeartbeatMessage) {
        commit('setLastHeartbeatMessage', Date.now());
        commit('setOnline', true);

        commit('clearTimer');
        // if it has been more than 30 seconds since the last heartbeat message
        const heartbeatOffset = 30000;
        state.timer = setTimeout(() => {
          commit('setOnline', false);
        }, heartbeatOffset);
      }

      if (isOnlineMessage) {
        commit('setInternetHeartbeatMessage', Date.now());
        commit('setInternet', true);

        commit('clearInternetTimer');
        // if it has been more than 45 seconds since the last heartbeat message
        const heartbeatOffset = 45000;
        state.internetTimer = setTimeout(() => {
          commit('setInternet', false);
        }, heartbeatOffset);
      }
    });

    await dispatch('measurements/fetchMeasurements', project.id, { root: true });
    const measurements = rootGetters['measurements/measurements'];
    const current = Math.trunc(new Date().getTime() / 1000);

    // initial check if a heartbeat message was received in the last 60 seconds
    if (measurements.$hb) {
      const lastHb = Math.trunc(new Date(measurements.$hb).getTime() / 1000);
      if (current - lastHb < 60) {
        commit('setOnline', true);
      }
    }

    // initial check if a internet heartbeat message was received in the last 60 seconds
    if (measurements.$hb2) {
      const lastInternetHb = Math.trunc(new Date(measurements.$hb2).getTime() / 1000);
      if (current - lastInternetHb < 60) {
        commit('setInternet', true);
      }
    }
  },
  async removeConnection({ state, commit }) {
    commit('clearTimer');
    commit('clearInternetTimer');
    commit('setOnline', false);
    commit('setInternet', false);
    commit('setLastHeartbeatMessage', 0);
    commit('setInternetHeartbeatMessage', 0);
    state.client?.end(true, {}, () => {
      state.client = null;
    });
  },
};

export const mqttClient: Module<IMQTTState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
