
import { Component, Vue } from 'vue-property-decorator';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import DropDownBase from '@/ui/components/devices/devices/base/DropDown.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    OutputFieldBase,
    DropDownBase,
    DeviceLayout,
  },
})
export default class DropDownInputFieldWithVariablePreview extends Vue {
  textMapping = {
    0: 'Example Text 1',
    1: 'Example Text 2',
    2: 'Example Text 3',
    3: 'Example Text 4',
    4: 'Example Text 5',
  };

  get previewNameLang() {
    return this.$t('devices.DropDownInputFieldWithVariable.previewName');
  }
}
