
import { Vue, Component } from 'vue-property-decorator';

/**
 * Component that represent PlaceHolderImage which show when no image
 */
@Component({})
export default class PlaceHolderImage extends Vue {
  get fillColor() {
    return this.$vuetify.theme.dark ? '#fff' : '#000';
  }
}
