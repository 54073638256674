
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

/**
 * Component that represent devices actions list for previews
 */
@Component
export default class PreviewActions extends Vue {
  @Prop() deviceType!: string;
  @Getter('mpc/mlModelIsSettings') mlModelIsSettings!: any;
  @Getter('devices/devicesIsSettings') devicesIsSettings!: any;

  isMenuOpen = false
  isCloseOnClick = true

  /**
   * List of all possible actions
   */
  get items() {
    return [
      {
        name: 'Add To Favorites',
        locale: 'uiComponents.deviceActions.addToFavorites',
        handle: () => {},
        visible: true,
      },
      {
        name: 'Settings',
        locale: 'uiComponents.deviceActions.settings',
        handle: () => this.$emit('switchSettingsView', true),
        visible: this.isSettings,
      },
      {
        name: 'Edit',
        locale: 'uiComponents.deviceActions.edit',
        handle: () => {},
        visible: true,
      },
      {
        name: 'Delete',
        locale: 'uiComponents.deviceActions.delete',
        handle: () => {},
        visible: true,
      },
    ];
  }

  get allDevices() {
    return [...this.mlModelIsSettings, ...this.devicesIsSettings];
  }

  /**
   * Defines for which devices preview show settings item
   */
  get isSettings() {
    const map: any = new Map(this.allDevices);
    return map.get(this.deviceType);
  }
}
