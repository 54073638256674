
import { argMax } from '@/utils/utilsFunctions';
import _, { cloneDeep } from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getAlternativeVariableName, getChartOptions } from './ChartUtils';

/**
 * Component that represent data export button
 */
@Component
export default class DataExport extends Vue {
  @Prop() dataToExport!: any;
  @Prop() periodTitle!: string;
  @Prop({ default: false }) isThresholdLine!: boolean;
  @Prop({ default: () => ({ data: { type: '' } }) }) chartData?: any;
  @Prop({ default: false }) enableMultiSelect!: boolean;
  @Prop() multiSelectData!: any;
  @Prop() seriesSuffixes!: any;
  @Prop({ default: () => {} }) chartScores!: Record<string, {value: number; unit: string}>;

  /**
   * Preparation of data for export into csv
   */
  exportData() {
    let localDataToExport: any = JSON.parse(JSON.stringify(this.dataToExport));
    const localChartData: any = JSON.parse(JSON.stringify(this.chartData));

    if (this.isThresholdLine) {
      localDataToExport = localDataToExport.slice(1);
    }

    let fileName = '';
    const doc = 'data:text/csv;charset=utf-8,';
    let tableHeader = '';
    let sumForMultiselect = 0;

    // if chart has a name use that for Filename
    if (localChartData.name !== undefined && localChartData.name !== '') {
      fileName = localChartData.name;
    } else {
      fileName = 'export';
    }
    if (this.enableMultiSelect === false && this.multiSelectData.length <= 1) {
      tableHeader = 'time';
      // get name of all Variables for the filename
      getChartOptions(localChartData).forEach((optionsItem: any, index: number) => {
        let { name } = optionsItem;

        if (!name || name.length === 0) {
          // name not set
          name = getAlternativeVariableName(index, localChartData);
        }

        tableHeader += `;${name}`;
      });
    } else {
      tableHeader = `time ${this.seriesSuffixes[0]}`;
      this.multiSelectData.forEach((chart: any, index: number) => {
        chart.forEach((element: any) => {
          if (index < this.seriesSuffixes.length) {
            if (sumForMultiselect !== 0) {
              tableHeader += `;time ${this.seriesSuffixes[index]}`;
            }
            tableHeader += `;${element.name} ${this.seriesSuffixes[index]}`;
            sumForMultiselect++;
          }
        });
      });
    }

    if (this.chartScores !== null) {
      // if chart has scores, all scores names will be added into the frist row
      Object.keys(this.chartScores).forEach((name: string) => {
        tableHeader += `;${name}`;
      });
    }

    let table = doc + tableHeader;

    // get length of longest array
    let len = 0;
    if (localDataToExport !== undefined) {
      Object.values(localDataToExport).forEach((value: any) => {
        if (value.length > len) {
          len = value.length;
        }
      });
      // get the amount of Variables in dataToExport
      let numVariables = localDataToExport.length;
      if (this.enableMultiSelect === true) {
        numVariables = sumForMultiselect;
      }

      if (this.enableMultiSelect === false) {
        // Load one Value from each Variable and get the time from the first one
        for (let index = 0; index < len; index++) {
          const rowArray = [];
          // gets Current time
          const curTime = this.convertTimestamp(localDataToExport[0][index][0]);
          rowArray.push(curTime);
          for (let i = 0; i < numVariables; i++) {
            const num = localDataToExport[i][index][1];
            if (localDataToExport[i][index][1] !== null) {
              // change to "," for format in the csv
              rowArray.push(num.toString().replace('.', ','));
            }
          }
          // puts all the data of an row into one string and joins it with ';' in order to be displayed in seperate cells
          let item = rowArray.join(';');
          if (this.chartScores !== null && index === 0) {
            item = this.addScoresValues(item);
          }
          // put the item + new line into the table of the csv
          // eslint-disable-next-line prefer-template
          table += '\r\n' + item;
        }
      } else {
        // will contain an array with all the chart series of all the charts inside of it
        const seriesDataArray: any = [];
        this.dataToExport.forEach((currentChart: any) => {
          currentChart.forEach((singleSeries: any) => {
            seriesDataArray.push(singleSeries);
          });
        });
        const seriesLengths = seriesDataArray.map((series: any) => series.length);
        const indexLargestArray = argMax(seriesLengths);
        // contains an array of all the timestamps of the series with the most values inside of it
        const timeStampsArray = cloneDeep(seriesDataArray[indexLargestArray].map((element: any) => element[0]));
        timeStampsArray.forEach((timeStamp: number, timeStampIndex: number) => {
          const rowArray = [];

          for (let i = 0; i < seriesDataArray.length; i++) {
            const series = seriesDataArray[i];
            // converts the unix timestamp to the needed format (e.x. hh:mm)
            const curTime = this.convertTimestamp(series[timeStampIndex]?.[0]);
            if (curTime !== 'NaN:NaN') {
              rowArray.push(curTime);
            } else {
              // if there is no datevalue there will be an empty cell added
              rowArray.push(' ');
            }
            // gets the current value of the current series to add it in the csv
            const num = series[timeStampIndex]?.[1];
            if (num !== undefined) {
              // change to "," for format in the csv
              rowArray.push(num.toString().replace('.', ','));
            } else {
              rowArray.push('');
            }
          }
          // puts all the data of an row into one string and joins it with ';' in order to be displayed in seperate cells
          let item = rowArray.join(';');
          if (this.chartScores !== null && timeStampIndex === 0) {
            item = this.addScoresValues(item);
          }
          // put the item + new line into the table of the csv
          // eslint-disable-next-line prefer-template
          table += '\r\n' + item;
        });
      }
    } else {
      table += '\r\n No Data for Variables found';
    }

    const data = encodeURI(table);
    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', `${fileName}.csv`);
    link.click();
  }

  /**
   * Converts time from milliseconds to string format
   * @param {number} timestamp time in milliseconds
   * @return {string} time in format according to period
   */
  convertTimestamp(timestamp: number): string {
    // fill in value that is passed
    const timestampToConvert = new Date(timestamp);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = timestampToConvert.getFullYear();
    const month = months[timestampToConvert.getMonth()];
    const date = timestampToConvert.getDate();
    let hour = timestampToConvert.getHours();
    const min = timestampToConvert.getMinutes();
    const sec = timestampToConvert.getSeconds();
    let time = '';

    // format date for different chases based on Navigation Button selected
    switch (this.periodTitle) {
      case 'hour':
        time = `${hour}:${min}:${sec}`;
        break;
      case 'day':
        time = `${hour}:${min}`;
        break;
      case 'week':
        time = `${date}.${month}.${year} ${hour}:00`;
        break;
      case 'month':
        hour -= 1;
        time = `${date}.${month}.${year}, ${hour}:${min}0`;
        break;
      case 'year':
        time = `${date}.${month}.${year}`;
        break;
      case 'live':
        time = `${hour}:${min}:${sec}`;
        break;
    }

    return time;
  }

  /**
   * Adds values with unit to the first row of series values
   * @param {string} item current row item with values of normal series
   * @return {string} current row item with values of normal series, with values of each score
   */
  addScoresValues(item: string) {
    Object.values(this.chartScores).forEach((score: {value: number; unit: string}) => {
      item += `;${score.value.toString().replace('.', ',')}${score.unit}`;
    });
    return item;
  }
}
