
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import EmailAction from '@/ui/components/modals/actions/EmailAction.vue';
import WebhookAction from '@/ui/components/modals/actions/WebhookAction.vue';
import PrintReport from '@/ui/components/modals/ManageReport/PrintReport.vue';
import TriggerActions from '@/ui/components/modals/ManageReport/TriggerActions.vue';
import { ReportFormRules } from '@/ui/components/modals/ManageReport/mixins/rules';
import { Action, Getter, State } from 'vuex-class';
import moment from 'moment-timezone';
import { UserRoleCode } from '@/utils/userRoles';
import { IMember } from '@/types/members.types';

/**
 * A component that allows to manage Normal report form
 */
@Component({
  computed: {
    UserRoleCode: () => UserRoleCode,
  },
  components: {
    email: EmailAction,
    webhook: WebhookAction,
    PrintReport,
    TriggerActions,
  },
})
export default class NormalReportNew extends mixins(ReportFormRules) {
  @Prop({ default: null }) report!: null | any;
  @Prop() isNewReport!: boolean;
  @Getter('measurements/measurementsKeys') measurementsKeys!: string[];
  @Getter('variables/variablesForComboBox') variablesForComboBox!: [];
  @Getter('members/currentMember') currentMember!: IMember;

  formValid = true;
  localReportData: any = {
    type: 'normal',
    name: '',
    currency: 'EUR',
    timezone: moment.tz.guess(),
    address: {
      street: '',
      city: '',
      country: '',
    },
    variables: [{ name: '', title: '', unit_cost: 0, unit: '' }],
    actions: [],
    meta: undefined,
    active: false,
  };

  get currencyOptions() {
    return [
      { state: 'EUR', abbr: 'EUR' },
      { state: 'CHF', abbr: 'CHF' },
    ];
  }

  get formatOptions() {
    return this.localReportData.type === 'zev'
      ? [
        { state: 'pdf', abbr: 'pdf' },
        { state: 'xml', abbr: 'xml' },
      ] : [
        { state: 'json', abbr: 'json' },
        { state: 'pdf', abbr: 'pdf' },
        { state: 'csv', abbr: 'csv' },
      ];
  }

  get reportDataToSend() {
    return this.localReportData;
  }

  @Watch('formValid')
  onFormValidStatusChange(status: boolean) {
    this.$emit('onFormValidStatusChange', status);
  }

  async addVariable() {
    this.localReportData.variables.push({ name: '', title: '', unit_cost: 0, unit: '' });
    await this.$nextTick();
    this.formValidate();
  }
  removeVariableByIndex(index: number) {
    this.localReportData.variables.splice(index, 1);
  }

  /**
   * Function that add new action to report
   * @param type string, action type key
   */
  addAction(type: string) {
    const newAction: any = { 'type': type, 'format': 'pdf' };

    switch (type) {
      case 'webhook':
        newAction.params = {
          headers: {
            'content-type': 'application/json',
          },
          method: 'POST',
          url: '',
        };
        break;
      case 'email':
        newAction.params = {
          recipients: ['example@mail.com'],
          subject: '{{name}}',
          body: 'Report from {{start}} to {{end}}',
        };
        break;
    }
    this.$set(this.localReportData.actions, this.localReportData.actions.length, newAction);
  }
  deleteAction(index: number) {
    this.localReportData.actions.splice(index, 1);
  }

  formValidate() {
    (this.$refs.form as any).validate();
  }

  created() {
    if (this.report) {
      this.localReportData = JSON.parse(JSON.stringify(this.report));
    }
  }
  mounted() {
    this.formValidate();
  }
}
