/**
 * Chart scaling for charts (in EnergyView/EMS) that contain mixed types of systems
 */
export const mixedEnergyChartScaling = {
  min: -2,
  max: 5,
};

/**
 * Chart scaling for charts (in EnergyView/EMS) that contain single systems,
 * which are not hybrid (battery, grid)
 */
export const singleEnergyChartScaling = {
  min: 0,
  max: 5,
};

/**
 * Chart scaling for charts (in EnergyView/EMS) that contain single systems,
 * which are hybrid (battery, grid)
 */
export const singleEnergyChartScalingHybrid = {
  min: -5,
  max: 5,
};

export const temperatureScaling = {
  min: 0,
  max: 40,
};
