
import { Vue, Component, Prop } from 'vue-property-decorator';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import ArrowDirectionLeftBase from '@/ui/components/devices/devices/base/ArrowDirectionLeftBase.vue';
import ArrowDirectionRightBase from '@/ui/components/devices/devices/base/ArrowDirectionRightBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent PumpWithoutVFD device
 */
@Component({
  components: {
    PushButtonBase,
    OutputFieldBase,
    ArrowDirectionLeftBase,
    ArrowDirectionRightBase,
    DeviceLayout,
    LabelUnitWrapper,
  },
})
export default class PumpWithoutVFD extends Vue {
  @Prop() deviceData!: IDevice;

  get langPath() {
    return `devices.${this.deviceData.data.type}`;
  }
}
