
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent EMS
 */
@Component({
  components: {

  },
})
export default class IconValueFieldWithTooltip extends Vue {
  @Prop({ default: '' }) icon!: string;
  @Prop({ default: '' }) value!: string;
  @Prop({ default: '' }) text!: string;
  @Prop({ default: '' }) unit!: string;
}
