
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent battery
 * Used in EMS, EnergyView device
 */
@Component
export default class BatteryLevel extends Vue {
  @Prop({ default: 0 }) batteryState!: any;

  /**
   * Checks if the battery value is outside the limits and sets to agreed boundaries
   * @return {number} battery level
   */
  get boundedBatteryState() {
    if (this.batteryState > 100) {
      return 100;
    } else if (this.batteryState < 0) {
      return 0;
    } else {
      return Math.trunc(this.batteryState);
    }
  }
}
