
import { Vue, Component } from 'vue-property-decorator';
import PushButtonMock from '@/ui/components/devices/previews/components/PushButtonMock.vue';
import SliderMock from '@/ui/components/devices/previews/components/SliderMock.vue';
import ArrowButtonMock from '@/ui/components/devices/previews/components/ArrowButtonMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    PushButtonMock,
    SliderMock,
    ArrowButtonMock,
    DeviceLayout,
  },
})
export default class PumpWithVFDPreview extends Vue {
  get mainViewLang() {
    return 'devices.PumpWithVFD.mainView';
  }
  get previewNameLang() {
    return this.$t('devices.PumpWithVFD.previewName');
  }
}
