
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

/**
 * Form field Range Date Picker, give possibility to handle start, end date
 */
@Component
export default class RangeDatePicker extends Vue {
  @Prop({ default: null }) startDate!: number; // Timestamp seconds
  @Prop({ default: null }) endDate!: number; // Timestamp seconds

  start: any = null
  end: any = null
  isCalendar = false

  @Watch('isCalendar')
  onCalendarDialogChange(val: boolean) {
    if (val) {
      this.start = this.startDate;
      this.end = this.endDate;
    }
  }

  /**
   * Converts seconds to date YYYY-MM-DD
   * @param seconds number of seconds
   * @return string date in format YYYY-MM-DD
   */
  convertToDate(seconds: number) {
    if (!seconds) return '';
    const [day, month, year] = new Date(seconds * 1000).toLocaleDateString('en-GB').split('/');
    return `${year}-${month}-${day}`;
  }

  /**
   * Converts date YYYY-MM-DD to seconds
   * @param date in format YYYY-MM-DD
   * @return date in seconds
   */
  convertToSeconds(date: string) {
    const [year, month, day] = date.split('-');
    return Date.UTC(+year, +month - 1, +day) / 1000;
  }

  handleStartDate(e: any) {
    this.start = this.convertToSeconds(e);
  }
  handleEndDate(e: any) {
    this.end = this.convertToSeconds(e);
  }

  acceptDate() {
    this.$emit('handleStartStopDate', { startDate: this.start, endDate: this.end });
    this.isCalendar = false;
  }
  cancelDate() {
    this.isCalendar = false;
  }
}
