
import { Vue, Component } from 'vue-property-decorator';
import Permissions from '@/ui/views/Project/Settings/Permissions.vue';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import LynusPopup from '@/ui/components/components/LynusPopup.vue';
import { Action, Getter, State } from 'vuex-class';
import { IRulesState } from '@/store/modules/rules/types';
import { cloneDeep } from 'lodash';
import { IProjectsState } from '@/store/modules/projects/types';
import { getIsMemberInRulesAsRecipient } from '@/utils/membersUtils';
import { IMember } from '@/types/members.types';

@Component({
  components: {
    Permissions,
    WizardContentView,
    LynusPopup,
  },
})
export default class InviteUsersToProject extends Vue {
  @State('rules') rulesState!: IRulesState;
  @State('projects') projectsState!: IProjectsState;
  @Getter('members/members') members!: IMember[];
  @Action('installationWizard/handleIncrement') handleIncrement!: () => void;
  @Action('installationWizard/handleDecrement') handleDecrement!: () => void;

  showPopup = false;
  conModel: any = {
    actions: [],
    enabled: false,
  };
  isNotificationForSomeMembersEnabled = false

  get rulesList() {
    return this.rulesState.rulesList;
  }

  get project() {
    return this.projectsState.project;
  }

  handleUpdateRules(value: { someUserSelected: boolean}) {
    this.isNotificationForSomeMembersEnabled = value.someUserSelected;
  }

  handleContinue() {
    this.showPopup = false;
    this.handleIncrement();
  }

  handleNext() {
    if (this.isNotificationForSomeMembersEnabled) {
      this.handleIncrement();
    } else {
      this.showPopup = true;
    }
  }

  handleClose() {
    this.showPopup = false;
  }

  created() {
    this.conModel = cloneDeep(this.project.connectivity);
  }

  mounted() {
    this.isNotificationForSomeMembersEnabled = this.members.some((m) => getIsMemberInRulesAsRecipient(m, this.rulesList, this.conModel));
  }
}
