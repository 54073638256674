
import { Vue, Component, Prop } from 'vue-property-decorator';
import RotatingBacklight from '@/ui/components/devices/components/EnergyParts/RotatingBacklight.vue';
import BatteryLevel from '@/ui/components/devices/components/EnergyParts/BatteryLevel.vue';
import ShowEventBaseGroup from '@/ui/components/devices/mpc/base/ShowEventBase.vue';
import Unit from '@/utils/unit/unit';
import tinycolor from 'tinycolor2';
import { kiloWattUnit } from '@/utils/unit/unitConstants';

/**
 * Component that represent circle system view.
 * Used in EMS, EnergyView, Setpoint Optimizer
 */
@Component({
  components: {
    RotatingBacklight,
    BatteryLevel,
    ShowEventBaseGroup,
  },
})
export default class Preview extends Vue {
  @Prop({ default: 0 }) actualValue!: number;
  @Prop({ default: null }) secondDirectionValue!: number;
  @Prop({ default: false }) isEnergyFlow!: boolean;
  @Prop({ default: null }) previewTitle!: string;
  @Prop({ default: 'top' }) previewTitlePosition!: string; // top, bottom
  @Prop({ default: true }) showActualValue!: boolean;
  @Prop({ default: () => kiloWattUnit }) actualValueUnit!: Unit;
  @Prop({ default: 'photovoltaic' }) previewIcon!: string;
  @Prop({ default: '30' }) previewIconSize!: string | number;
  @Prop({ default: false }) isBatterySOC!: boolean;
  @Prop({ default: 92 }) batterySOCValue!: number;
  @Prop({ default: '#111616' }) rotatingBacklightColor!: string;
  @Prop({ default: null }) systemCount!: number;
  @Prop({ default: null }) errorWarningLocal!: any;
  @Prop({ default: false }) noData!: boolean;
  @Prop({ default: null }) highlightValue!: number;

  get empty() {
    return this.actualValue === 0 && this.actualValueUnit.unit === '';
  }

  get isDark() {
    return (this.$vuetify.theme as any).isDark;
  }

  get secondValueUnit() {
    return this.actualValueUnit?.unitForValue(this.secondDirectionValue);
  }

  get firstValueUnit() {
    return this.actualValueUnit?.unitForValue(this.actualValue);
  }

  get highlightColor() {
    return (this.isDark
      ? tinycolor(this.rotatingBacklightColor).lighten().toHexString()
      : this.rotatingBacklightColor);
  }

  highlight(direction: number) {
    if (this.highlightValue === null || this.noData) return {};
    if (direction === this.highlightValue) {
      return {
        transition: 'all 0.5s',
        color: this.highlightColor,
        textShadow: `-0.3px -0.3px 0 ${this.highlightColor}, 0.3px -0.3px 0 ${this.highlightColor}, -0.3px 0.3px 0 ${this.highlightColor}, 0.3px 0.3px 0 ${this.highlightColor}`,
        '-webkit-transition': 'all 0.5s',
        '-moz-transition': 'all 0.5s',
        '-o-transition': 'all 0.5s',
      };
    } else {
      return {
        transition: 'color 0.5s',
      };
    }
  }
}
