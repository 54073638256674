
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { Validation } from '@/ui/mixins/validation';
import { IWorker } from '@/types/partnerWorkspace.types';
import { formatIsoDate } from '@/utils/utilsFunctions';
import UserService from '@/services/UserService';
import { IFetchPermissionUserResponse } from '@/types/common.types';
import { IMember } from '@/types/members.types';
import { Mutation } from 'vuex-class';

@Component({
  methods: { formatIsoDate },
  components: {
    ModalWindow,
  },
})
export default class CreatePartnerProject extends mixins(Validation) {
  @Prop({ default: () => false }) handleAsEdit!: boolean;
  @Prop({ default: () => ({}) }) worker!: IWorker;
  @Mutation('app/setReport') setReport!: (payload: any) => void;

  currentWorker: IWorker = {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    role: 1001,
  }
  valid = false;

  // User Search
  model = null;
  search = '';
  items: IFetchPermissionUserResponse[] = [];
  loading = false;
  selectedUserObject: IMember | null = null;
  wasSelected = false;

  @Watch('model')
  async onSelected(val: IMember | null) {
    if (val === null) return;

    this.selectedUserObject = { ...(this.items[0] as any) };
    if (this.selectedUserObject === null) return;

    this.currentWorker.first_name = this.selectedUserObject.first_name;
    this.currentWorker.last_name = this.selectedUserObject.last_name;
    this.currentWorker.email = this.selectedUserObject.email;
    this.currentWorker.role = 1001;
    this.currentWorker.id = this.selectedUserObject.id;
    this.wasSelected = true;

    // clear the autocomplete
    this.model = null;
    this.items = [];
    this.search = '';
  }

  @Watch('search')
  async fetchUsers(val?: string) {
    if (this.loading || val == null) return;
    if (!this.isEmailValid(val)) {
      this.items = [];
      return;
    }
    this.loading = true;
    try {
      this.items = await UserService.fetchUserByEmail(val);
    } catch (e) {
      this.setReport({ type: 'error', message: e.message, value: true });
    } finally {
      this.loading = false;
    }
  }

  // clear items @blur, otherwise it will still show
  // the old result when focusing the autocomplete
  clearItems() {
    this.items = [];
    this.search = '';
  }

  /**
   * Validates email
   * @param {string} s email
   */
  isEmailValid(s: string): boolean {
    // https://stackoverflow.com/a/574698
    if (s.length < 3 || s.length > 254) return false;

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(s.toLowerCase());
  }
  // end User Search

  handleSave() {
    if (!this.handleAsEdit) {
      this.$emit('addWorker', this.currentWorker);
    } else {
      this.$emit('editWorker', this.currentWorker);
    }
    (this.$refs.ModalWindowInstance as ModalWindow).onConfirmationDialogClose();
  }

  async onDialogStatusChange(status: boolean) {
    if (status) {
      if (this.worker && this.handleAsEdit) {
        this.currentWorker = { ...this.worker };
        this.wasSelected = true;
      } else {
        this.wasSelected = false;
      }
      await this.$nextTick();
      this.validate();
    } else {
      this.currentWorker = {
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        role: 1001,
      };
    }
  }

  @Watch('currentLang', { deep: true })
  handleLangChange() {
    this.validate();
  }

  validate() {
    (this.$refs.form as any)?.validate();
  }

  mounted() {
    this.$nextTick(() => {
      this.validate();
    });
  }
}
