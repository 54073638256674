import { cloneDeep } from 'lodash';
import { timeToMinutes } from '@/utils/utilsFunctions';

/**
 * @param currentModel contains the whole report model
 * @returns the report model with the final Times inserted
 * This function only runs when day grouping is enabled by the User.
 * This function gets the report and fills up the missing day values for Monday-Friday and Saturday and Sunday to be the same
 */
export function fillUpTariffTimes(currentModel: any) {
  for (let i = 0; i <= 6; i++) {
    const internalTimes = currentModel.meta.zev.tariffs.internal.time;
    const currentInternalDaySettings = currentModel.meta.zev.arrayDaySettingsInternal;
    const mondayInternalSettings = currentInternalDaySettings[0];
    const saturdayInternalSettings = currentInternalDaySettings[5];
    const internalMonday = internalTimes[0];
    const internalSaturday = internalTimes[5];
    const externalTimes = currentModel.meta.zev.tariffs.external.time;
    const currentExternalDaySettings = currentModel.meta.zev.arrayDaySettingsExternal;
    const mondayExternalSettings = currentExternalDaySettings[0];
    const saturdayExternalSettings = currentExternalDaySettings[5];
    const externalMonday = externalTimes[0];
    const externalSaturday = externalTimes[5];

    // weekdays
    if (i > 0 && i < 5) {
      currentInternalDaySettings[i] = mondayInternalSettings;
      currentExternalDaySettings[i] = mondayExternalSettings;
      internalTimes[i] = cloneDeep(internalMonday);
      externalTimes[i] = cloneDeep(externalMonday);
    }
    // sunday
    if (i === 6) {
      currentInternalDaySettings[i] = saturdayInternalSettings;
      currentExternalDaySettings[i] = saturdayExternalSettings;
      internalTimes[i] = cloneDeep(internalSaturday);
      externalTimes[i] = cloneDeep(externalSaturday);
    }
  }
  return currentModel;
}
/**
 * @param currentReport contains the whole report model
 * @returns the report model with the converted Times inserted
 * This function goes through all the Tariff times and converts the times from format 'HH:MM' to time in minutes (02:00 -> 120)
 * This function is needed for API compatibility
 */
export function convertTimes(currentReport: any) {
  for (let i = 0; i <= 6; i++) {
    // internal Times
    const internalTimes: any[][] = currentReport.meta.zev.tariffs.internal.time;
    // map every start/end time from its minute value to an actual time in HH:mm format
    // e.g. [["00:00", "06:00"]] -> [["0", "360"]]
    internalTimes[i] = internalTimes[i].map((e: any[]) => e.map(time => timeToMinutes(time)));

    // external Times
    const externalTimes = currentReport.meta.zev.tariffs.external.time;
    // map every start/end time from its minute value to an actual time in HH:mm format
    // e.g. [["00:00", "06:00"]] -> [["0", "360"]]
    externalTimes[i] = externalTimes[i].map((e: any[]) => e.map(time => timeToMinutes(time)));
  }
  return currentReport;
}
