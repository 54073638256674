
import { Vue, Component } from 'vue-property-decorator';
import Robot from '@/ui/components/devices/previews/devices/RobotPreview.vue'
import SurveyClient from '@/ui/components/devices/previews/devices/SurveyClientPreview.vue'

@Component({
  components: {
    Robot,
    SurveyClient
  },
})
export default class RonovatecVue extends Vue {
  tab = null
}
