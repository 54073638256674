
import { Component, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import EnergyVisualisation
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/index.vue';
import {
  getDefaultArrowDirectionSetpointOptimizer,
  getDefaultLineColorsSetpointOptimizer,
  getDefaultSpinnerColorSetpointOptimizer,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyVisualisationUtils';
import {
  celsiusUnit,
} from '@/utils/unit/unitConstants';
import SystemDialogSetpointOptimizer
  from '@/ui/components/devices/mpc/SetpointOptimizer/system/components/SystemDialogSetpointOptimizer.vue';
import { IDevice } from '@/types/devices.types';
import { EnergyCircleDisplayData, IconContent, SocContent } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import { EnergyCircleType, allEnergyCircleTypes, getCirclePositionForType, energyCircleIconForType } from '@/types/energyVisualisation/EnergyCircleType';
import { EnergyLineDisplayData } from '@/types/energyVisualisation/EnergyLineDisplayData';

type SystemInstances = Record<string, SystemInstance>
type SystemInstance = Record<string, string>

const devicesWithDynamicBottomValue: string[] = ['EMS', 'EMSV2'];

/**
 * Actual view for SetPointOptimizer
 */
@Component({
  components: {
    EnergyVisualisation,
    SystemDialogSetpointOptimizer,
  },
  computed: {
    devicesWithDynamicBottomValue: () => devicesWithDynamicBottomValue,
  },
})
export default class ActualView extends mixins(EnergyComponent) {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: null }) forecastData!: any;
  @Prop({ default: false }) enableMinWidth!: boolean;

  showDialog = false;
  showSystemSettingsDialog = false;
  openedSystem?: EnergyCircleType;

  @Provide('onCircleClick') onCircleClick = this.onCircleClickCallback;

  handleClose() {
    this.showDialog = false;
    this.showSystemSettingsDialog = false;
  }

  onCircleClickCallback(circleData: EnergyCircleDisplayData) {
    this.openedSystem = circleData.id as EnergyCircleType;
    this.showDialog = true;
  }

  sum(systemInstances: Record<string, Record<string, string>>, mappedVariable = 'power') {
    const values = Object.values(systemInstances).map(
      (systemInstanceMappings: Record<string, string>) => {
        const measurement = systemInstanceMappings[mappedVariable];
        return this.measurements[measurement];
      },
    );
    if (values.every((value: any) => typeof value !== 'number')) return;

    return values.reduce((acc: number, value: any) => {
      const valueNumber = typeof value === 'number' ? value : 0;
      return acc + valueNumber;
    }, 0);
  }

  avg(systemInstances: Record<string, Record<string, string>>, mappedVariable = 'soc') {
    const values: number[] = Object.values(systemInstances).map(
      (systemInstanceMappings: Record<string, string>) => {
        const measurement = systemInstanceMappings[mappedVariable];
        return this.measurements[measurement] as number;
      },
    );
    const sum: number = values.reduce((acc: number, value: number) => acc + value, 0);
    const result = sum / values.length;
    return Number.isNaN(result) ? 0 : result;
  }

  getErrorWarningMode(systemInstances: SystemInstances) {
    const systemInstancesErrorValues = Object.values(systemInstances)
      .map((instance: SystemInstance) => this.measurements[instance.error])
      .filter((el: any) => typeof el === 'number');

    return systemInstancesErrorValues.length ? Math.max.apply(null, systemInstancesErrorValues as number[]) : undefined;
  }

  get shownSystems() {
    return this.filterPresentSystems(this.deviceData, allEnergyCircleTypes);
  }

  get circlesDisplayData() {
    return this.shownSystems.map((systemType: EnergyCircleType) => {
      const components = this.getSystemComponents(this.deviceData, systemType);
      const position = getCirclePositionForType(systemType);
      let bottomValue;

      // eslint-disable-next-line prefer-const
      bottomValue = this.getAverageOfSystemInstancesValues({
        systemInstances: components,
        mappedVariable: 'flow_temperature',
        measurements: this.measurements,
      });

      const centerContent: IconContent | SocContent = {
        type: 'icon',
        value: energyCircleIconForType(systemType),
      };

      const alternativeValue = 0;

      return {
        id: systemType,
        bottomValue,
        centerContent,
        position,
        systemCount: this.getSystemCount(this.deviceData, systemType),
        spinnerColor: getDefaultSpinnerColorSetpointOptimizer(systemType, bottomValue ?? alternativeValue),
        disableSpinner: false,
        alternativeValue,
        errorWarningMode: this.getErrorWarningMode(components),
        unit: celsiusUnit,
        hideValue: false,
      } as EnergyCircleDisplayData;
    });
  }

  get linesDisplayData() {
    return this.shownSystems.map((systemType: EnergyCircleType, index: number) => {
      const displayData = this.circlesDisplayData[index];

      const arrowDirection = getDefaultArrowDirectionSetpointOptimizer(systemType, displayData);
      const colors = getDefaultLineColorsSetpointOptimizer(systemType, displayData);

      return {
        id: displayData.id,
        arrowDirection,
        colors,
      } as Partial<EnergyLineDisplayData>;
    });
  }
}
