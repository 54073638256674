
import { Component, Vue } from 'vue-property-decorator';
import Highcharts from 'highcharts';
import solidGauge from 'highcharts/modules/solid-gauge';
import highchartsMore from 'highcharts/highcharts-more';
import { Chart } from 'highcharts-vue';

highchartsMore(Highcharts);
solidGauge(Highcharts);

@Component({
  components: {
    highcharts: Chart,
  },
})
export default class MonitorGaugeMock extends Vue {
  val = 40;
  gaugeWidth = 320;
  chartKey = 1;

  get description() {
    return this.$t('devicesDescriptions.Gauge');
  }
  get previewNameLang() {
    return this.$t('devices.Gauge.previewName');
  }

  mounted() {
    this.$nextTick(() => this.resizeChart());
    window.addEventListener('resize', this.resizeChart);
  }

  resizeChart() {
    const element: any = this.$refs.chartDiv;
    if (element !== undefined) {
      if (element.clientWidth > 320) {
        this.gaugeWidth = 320;
      } else {
        this.gaugeWidth = element.clientWidth;
      }
      this.chartKey += 1;
    }
  }

  destroyed() {
    window.removeEventListener('resize', this.resizeChart);
  }
}
