
import { Vue, Component, Prop } from 'vue-property-decorator';
import ShowEventBase from '@/ui/components/devices/devices/base/ShowEventBase.vue';
import Switch2VBase from '@/ui/components/devices/devices/base/Switch2VBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent GeneralSwitchV2 device
 */
@Component({
  components: {
    ShowEventBase,
    Switch2VBase,
    DeviceLayout,
  },
})
export default class GeneralSwitchV2 extends Vue {
  @Prop() deviceData!: IDevice;
}
