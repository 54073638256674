
import { Vue, Component } from 'vue-property-decorator';
import SolarCalculator from '@/ui/components/partner/workspace/pages/SolarCalculator.vue';
import PartnerInformation from '@/ui/components/partner/workspace/pages/PartnerInformation.vue';
import Offer from '@/ui/components/partner/workspace/pages/Offer.vue';
import Permissions from '@/ui/components/partner/workspace/pages/Permissions.vue';
import ProjectPlan from '@/ui/components/partner/workspace/pages/ProjectPlan.vue';
import Documentation from '@/ui/components/partner/workspace/pages/Documentation.vue';
import ConnectWithProject from '@/ui/components/partner/workspace/pages/ConnectWithProject.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { IPartner } from '@/types/partner.types';
import { IPartnerWorkspace } from '@/types/partnerWorkspace.types';
import { IAppState } from '@/store/modules/app/types';
import { IProject } from '@/types/project.types';

@Component({
  components: {
    SolarCalculator,
    Offer,
    PartnerInformation,
    Permissions,
    ProjectPlan,
    Documentation,
    ConnectWithProject,
    CircleSpinner,
  },
})
export default class PartnerProjectView extends Vue {
  @State('app') appState!: IAppState;
  @Getter('projects/projects') projects!: IProject[];
  @Getter('partnerWorkspaces/workspaceById') workspaceById!: (id: string) => any;
  @Mutation('partnerWorkspaces/setWorkspace') setWorkspace!: (workspace: IPartnerWorkspace) => void;
  @Action('partners/fetchPartners') fetchPartners!: () => IPartner[];
  @Action('partnerWorkspaces/fetchWorkspaces') fetchWorkspaces!: () => Promise<void>;
  @Action('projects/loadProjects') loadProjects!: () => Promise<void>;

  currentComponent = 'SolarCalculator';
  projectLoader = false;

  get navigationItems() {
    return [
      {
        title: 'Solar Calculator',
        locale: 'partners.partnerWorkspace.navigation.solarCalculator',
        component: 'SolarCalculator',
      },
      {
        title: 'Offer/AB/Contract',
        locale: 'partners.partnerWorkspace.navigation.offer',
        component: 'Offer',
      },
      {
        title: 'Documentation',
        locale: 'partners.partnerWorkspace.navigation.documentation',
        component: 'Documentation',
      },
      {
        title: 'Project Plan',
        locale: 'partners.partnerWorkspace.navigation.projectPlan',
        component: 'ProjectPlan',
      },
      {
        title: 'Permissions',
        locale: 'partners.partnerWorkspace.navigation.permissions',
        component: 'Permissions',
      },
      {
        title: 'ConnectWithProject',
        locale: 'partners.partnerWorkspace.navigation.connectWithProject',
        component: 'ConnectWithProject',
      },
      {
        title: 'Partner Information',
        locale: 'partners.partnerWorkspace.navigation.partnerInformation',
        component: 'PartnerInformation',
      },
    ];
  }

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  async created() {
    this.projectLoader = true;
    await this.fetchWorkspaces();
    await this.fetchPartners();
    await this.loadProjects();

    // set workspace in store
    const { id } = this.$route.params;
    const workspace = this.workspaceById(id);
    if (workspace) {
      this.setWorkspace(workspace);
    }
    this.projectLoader = false;
  }
}
