
import { Component, Prop } from 'vue-property-decorator';
import SwitchBase from '@/ui/components/devices/devices/base/SwitchBase.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent Ventilation device
 */
@Component({
  components: {
    SwitchBase,
    InputFieldBase,
    OutputFieldBase,
    ShowEventDotBase,
    PushButtonBase,
    DeviceLayout,
    LabelUnitWrapper,
  },
})
export default class Ventilation extends mixins(Validation) {
  @Prop() deviceData!: IDevice;

  get langPath() {
    return `devices.${this.deviceData.data.type}`;
  }
}
