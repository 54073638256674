
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { IProjectsState } from '@/store/modules/projects/types';
import {
  pvStringLoggerLimit
} from '@/ui/components/wizards/installationWizard/wizardSettings/wizardLimits';
import { IDevice } from '@/types/devices.types';
import { cloneDeep } from 'lodash';
import { IProject } from '@/types/project.types';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';

@Component({
  computed: {
    pvStringLoggerLimit: () => pvStringLoggerLimit,
  },
  components: {
    ModalWindow,
    WizardContentView,
  },
})
export default class AddStringLoggersPage extends WizardComponent {
  @State('projects') projectsState!: IProjectsState;
  @Getter('installationWizard/emsDevice') emsDevice!: IDevice;
  @Getter('installationWizard/energyViewDevice') energyViewDevice!: IDevice;
  @Mutation('installationWizard/updateEMSDeviceData') updateEMSDeviceData!: (payload: Partial<IDevice>) => void;
  @Mutation('installationWizard/updateEnergyViewDeviceData') updateEnergyViewDeviceData!: (payload: Partial<IDevice>) => void;
  @Action('devices/updateDevice') updateDevice!: (data: {
    device: IDevice;
    skipReport: boolean
  }) => Promise<IDevice>;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;

  isValid = false;
  doesHover = false;
  loggerInformation: { id: string; type: string }[] = [];
  showLoggerOptions = false;

  get imageSrc() {
    return require('../../../../../assets/images/installation wizard/logger.png');
  }

  /**
   * Checks if there are duplicate logger ids
   */
  get duplicateLoggerIds() {
    const array = this.loggerInformation.map((logger: { id: string; type: string }) => logger.id);
    const count: Record<string, number> = {};
    // Iterate over each element in the array
    for (let i = 0; i < array.length; i++) {
      const value = array[i];

      // If the value is already in the count object, it's a duplicate
      if (count[value] && value !== '') {
        return this.$t('loggerWizard.pages.DefineLoggerIds.duplicateLoggerIdNotAllowed', {value});
      }

      // Otherwise, add the value to the count object
      count[value] = 1;
    }
    return true;
  }

  /**
   * Returns the selectable logger types
   * currently it is only possible to add string inverters
   */
  get selectableLoggerTypes() {
    return [
      {
        state: this.$t('loggerWizard.pages.DefineLoggerIds.selectableLoggerTypes.stringInverter'),
        abbr: 'string',
        disabled: this.loggerInformation.length >= pvStringLoggerLimit,
      },
    ];
  }

  get project() {
    return this.projectsState.project;
  }

  updateEnergyDeviceMappings() {
    const emsMappings = cloneDeep(this.emsDevice.data.meta.controllerMappings);
    const energyViewMappings = cloneDeep(this.energyViewDevice.data.meta.controllerMappings);

    // for each logger add a new PV mapping to EMS and EnergyView
    // mappings will contain variables similar to the once inside the logger wizard
    this.loggerInformation.forEach((logger: { id: string; type: string }, loggerIndex: number) => {
      emsMappings.pv.count++;
      energyViewMappings.pv.count++;

      const loggerMappings = {
        power: `solarman.${logger.id}.PV.power`,
        error: '',
        title: `PV String Inverter ${loggerIndex + 1} (Logger)`,
        energy_counter: `solarman.${logger.id}.PV.counter`,
      };
      if (!this.allreadyCreatedCheck(loggerMappings.power, emsMappings)) {
        // add the logger mappings to the ems and energy view mappings if it is not allready created
        emsMappings.pv.components[`pv${emsMappings.pv.count}`] = loggerMappings;
        energyViewMappings.pv.components[`pv${energyViewMappings.pv.count}`] = loggerMappings;
      }
    });

    this.updateEMSDeviceData({data: {meta: {controllerMappings: emsMappings}}});
    this.updateEnergyViewDeviceData({data: {meta: {controllerMappings: energyViewMappings}}});
  }

  /**
   * Checks if the variable is already created in the mappings
   * @param powerVariable
   * @param emsMappings
   * @returns boolean true if allready mapped, if not false
   */
  allreadyCreatedCheck(powerVariable: string, emsMappings: any) {
    const mappingsWithThisVarible = Object.values(emsMappings.pv.components).filter((component: any) => component.power === powerVariable);
    return mappingsWithThisVarible.length > 0;
  }

  /**
   * Updates the project meta information for the loggers
   * loggerInformation will contain a list of all loggers with their id and type
   */
  async updateProjectMeta() {
    const localProject = cloneDeep(this.project);

    await this.updateProject({
      ...localProject,
      meta: {
        ...localProject.meta,
        loggerInformation: this.loggerInformation,
      },
    });
  }

  async prepareVariablesToSend() {
    this.updateEnergyDeviceMappings();
    await this.updateProjectMeta();
    // update the devices after the PV mappings were updated
    if (this.emsDevice.id) await this.updateDevice({device: this.emsDevice, skipReport: false});
    if (this.energyViewDevice.id) await this.updateDevice({
      device: this.energyViewDevice,
      skipReport: false
    });
  }

  /**
   * Add one logger and validate the form
   */
  handleYesButtonClicked() {
    this.loggerInformation = [{id: '', type: 'string'}];
    setTimeout(() => {
      (this.$refs.form as any).validate();
    }, 100);
  }

  /**
   * Remove all loggers and validate the form
   */
  handleNoLoggersClicked() {
    this.loggerInformation = [];
    this.showLoggerOptions = false;
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  addLogger() {
    // add new logger object to array
    this.loggerInformation.push({id: '', type: 'string'});
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  removeItem(index: number) {
    this.loggerInformation.splice(index, 1);
  }

  created() {
    if (this.wasInstallationWizardCompleted) {
      // if the installation wizard was already completed, we check the logger options
      if (this.project.meta?.loggerInformation && this.project.meta.loggerInformation.length > 0) {
        // if there are already loggers defined, set them in the loggerInformation array
        this.loggerInformation = this.project.meta.loggerInformation;
        this.showLoggerOptions = true;
      }
    }
  }

  mounted() {
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }
}
