
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { IProject } from '@/types/project.types';

@Component({
  components: {
    WizardContentView,
  },
})
export default class LoggerInformation extends Vue {
  @Getter('mqttClient/isProjectOnline') isProjectOnline!: boolean;
  @Getter('loggerWizard/considerProjectStatus') considerProjectStatus !: boolean;
  @Getter('projects/project') project!: IProject;
  @Action('loggerWizard/initDevices') initDevices!: () => void;
  @Action('loggerWizard/handleDecrement') handleDecrement!: () => void;
  @Action('loggerWizard/handleIncrement') handleIncrement!: () => void;

  handleStart() {
    // initialise EnergyView and EMS device object in store
    this.initDevices();
    this.handleIncrement();
  }
}
