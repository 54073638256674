
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import ManageRoom from '@/ui/components/modals/ManageRoom.vue';
import PlaceHolderImage from '@/ui/components/components/PlaceHolderImage.vue';
import { envUserRolesAccessBlockList } from '@/utils/env';
import _ from 'lodash';
import DuplicateRoomModal from '@/ui/components/lists/RoomsList/DuplicateRoomModal.vue';
import { IRoom } from '@/types/rooms.types';
import { IProject } from '@/types/project.types';
import { IDevice } from '@/types/devices.types';
import { CanAccessCheck, IMember } from '@/types/members.types';

/**
 * Component that represent room item
 */
@Component({
  components: {
    DeleteModalForm,
    ManageRoom,
    PlaceHolderImage,
    DuplicateRoomModal,
  },
})
export default class RoomItem extends Vue {
  @Prop() roomData!: IRoom;
  @Prop() isActive!: boolean;
  @Prop() areasMiniView!: boolean;
  @State('app') appState!: any;
  @Getter('projects/project') project!: IProject;
  @Getter('devices/devicesByRoom') devicesByRoom!: (roomId: string) => IDevice[];
  @Action('rooms/deleteRoom') deleteRoomState!: (id: string) => Promise<void>;
  @Action('rooms/updateRoom') updateRoom!: (room: IRoom) => Promise<void>;
  @Getter('members/currentMember') currentMember!: IMember;
  @Getter('members/currentUserRole') currentUserRole!: any;
  @Getter('members/canMemberAccess') canMemberAccess!: CanAccessCheck;

  isMenuOpen = false
  isMenuOpenMiniView = false
  isCloseOnClick = true
  isAllowedToDelete = true

  get showRoomItemMenuButton() {
    if (this.items.length === envUserRolesAccessBlockList[this.currentUserRole].AreaActions.length) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * Define height of element according to window size
   * @return {number} height of room item
   */
  get breakpoints() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 160;
      case 'sm': return 160;
      case 'md': return 210;
      case 'lg': return 250;
      case 'xl': return 250;
      default: return null;
    }
  }

  /**
   * Define button size according to window size
   * @return {string} vuetify class
   */
  get btnSize() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 'x-small';
      case 'sm': return 'x-small';
      case 'md': return 'small';
      case 'lg': return null;
      case 'xl': return null;
      default: return null;
    }
  }

  get dndStatus() {
    if (this.project) {
      return (this.project.meta as any).isDNDActive;
    }
    return false;
  }

  get devicesLength() {
    return this.devicesByRoom(this.roomData.id).length;
  }

  /**
   * Define which actions to show
   * @return {array} list of manage modals forms
   */
  get items() {
    return [
      { title: 'uiComponents.areasActions.edit', modal: 'ManageRoom', id: 'edit' },
      { title: 'uiComponents.areasActions.duplicate', modal: 'DuplicateRoomModal', id: 'duplicate' },
      { title: 'uiComponents.areasActions.delete', modal: 'DeleteModalForm', id: 'delete' },
    ];
  }
  get currentHandlers() {
    return !this.dndStatus ? { click: this.handleRoom } : null;
  }
  get isImageSource() {
    return !!(this.roomData.meta.imageId || this.roomData.meta.cover);
  }

  /**
   * Converts icon color according to current theme
   * @param {string} source icon id
   * @return {object} styles object
   */
  iconTheme(source: string) {
    const isIcon = /icons/g.test(source);
    return isIcon ? { filter: this.$vuetify.theme.dark ? 'brightness(0) invert(1)' : null } : {};
  }

  handleRoom() {
    this.$emit('handleRoomActiveStatus', this.roomData.id);
  }
  editRoom(room: IRoom) {
    this.updateRoom(room);
  }
  deleteRoom() {
    this.deleteRoomState(this.roomData.id);
  }

  created() {
    Object.values(this.devicesByRoom(this.roomData.id)).forEach((element: any, index: number) => {
      if (element.data.type === 'Robot' || element.data.type === 'SurveyClient') {
        this.isAllowedToDelete = false;
      }
    });
  }
}
