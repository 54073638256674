
import { Vue, Component, Prop } from 'vue-property-decorator';
import Forecast from '@/ui/components/devices/mpc/HeatingProduction/SettingsCharts/Forecast.vue';
import Charts from '@/ui/components/devices/mpc/HeatingProduction/SettingsCharts/Charts.vue';
import EnergyChart from '@/ui/components/devices/mpc/HeatingProduction/SettingsCharts/EnergyChart.vue';

@Component({
  components: {
    Forecast,
    Charts,
    EnergyChart,
  },
})
export default class SettingsCharts extends Vue {
  @Prop() mpcId!: any;
  @Prop({ default: null }) chartsScaling!: any;
  @Prop({
    default: {
      actualPower: '',
      calculatedPower: null,
      calculatedEnergy: null,
      calculatedEnergy2: null,
      actualEnergyChart: null,
    },
  }) mqttChartsVariables!: any;

  tab: any = null

  get langPath() {
    return 'mlModel.HppProductionService.settingsView.chartTabs';
  }

  get tabs() {
    return [
      {
        title: 'chart',
        locale: this.langPath,
        component: 'Charts',
        chartsScaling: this.chartsScaling,
        mqttChartsVariables: this.mqttChartsVariables,
      },
      {
        title: 'energyChart',
        locale: this.langPath,
        component: 'EnergyChart',
        chartsScaling: this.chartsScaling,
        mpcId: this.mpcId,
      },
      {
        title: 'forecast',
        locale: this.langPath,
        component: 'Forecast',
        chartsScaling: this.chartsScaling,
        mpcId: this.mpcId,
      },
    ];
  }
}
