import {
  EnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';
import { getVariableValue } from '@/ui/components/devices/components/EnergyParts/EMS/utils';
import { IAggregationProps, IExpressionProps, ICalculationProps } from '@/types/energyVisualisation/energyVisualisation.types';
import { IDevice } from '@/types/devices.types';

export function getAggregation({ system, variable, externalMeasurementsList }: IAggregationProps, defaultAggregation = 'avg') {
  switch (system) {
    case EnergyCircleType.house: return [variable, ...externalMeasurementsList].fill(defaultAggregation);
    case EnergyCircleType.battery: return variable === 'soc' ? ['last'] : [defaultAggregation];
    default: return [defaultAggregation];
  }
}

export function getExpression({ system, variableValue, expressionWrapperFn, externalMeasurementsList }: IExpressionProps) {
  switch (system) {
    case EnergyCircleType.house: {
      const exp = [variableValue, ...externalMeasurementsList].join(' - ');
      return typeof expressionWrapperFn === 'function' ? expressionWrapperFn(exp) : exp;
    }
    default: return typeof expressionWrapperFn === 'function' ? expressionWrapperFn(variableValue) : variableValue;
  }
}

export function getCalculation({
  system, instance, variable, device, expressionWrapperFn, externalMeasurementsList, defaultAggregation = 'avg',
}: ICalculationProps) {
  const variableValue = getVariableValue(device.data.meta.controllerMappings, system, instance, variable);
  return {
    aggregations: getAggregation({ system, variable, externalMeasurementsList }, defaultAggregation),
    expression: getExpression({ system, variableValue, expressionWrapperFn, externalMeasurementsList }),
  };
}

/**
 * @param array EnergyCircleType array
 * @param variableKey key of variable to be extracted
 * @param deviceData device data of EMS or EnergyView
 * @returns array of all mapped variable names
 */
export function getVariableNames(array: EnergyCircleType[], variableKey: string, deviceData: IDevice) {
  const variableArray: string[] = [];
  array.forEach((systemType: EnergyCircleType) => {
    const systemComponents = deviceData.data.meta.controllerMappings[systemType].components;

    Object.values(systemComponents).forEach((component: any) => {
      const variableName = component[variableKey];
      variableArray.push(variableName);
    });
  });
  return variableArray;
}

/**
 * @param device Device data of EMS or EnergyView
 * @returns calculation object with aggregations and expression
 */
export function getCalculationHouse(device: IDevice) {
  const finalCalculationExpression: { aggregations: string[]; expression: string } = {
    aggregations: [],
    expression: '',
  };
  const producerElements = [
    EnergyCircleType.pv,
    EnergyCircleType.generator,
    EnergyCircleType.battery,
    EnergyCircleType.chp,
    EnergyCircleType.grid,
  ];
  const consumerElements = [
    EnergyCircleType.charge_station,
    EnergyCircleType.electric_heating,
    EnergyCircleType.heating_pump,
    EnergyCircleType.big_consumer,
  ];
  // will contain list of all mapped variables of producer and consumer elements
  const producerVariables: string[] = getVariableNames(producerElements, 'power', device);
  const consumerVariables: string[] = getVariableNames(consumerElements, 'power', device);

  if (producerVariables.length !== 0 && consumerVariables.length !== 0) {
    // case if there are any other consumers present
    finalCalculationExpression.expression = `max(( ${producerVariables.join(' + ')} ) - ( ${consumerVariables.join(' + ')} ), 0)`;
  } else {
    // case if only house is present as consumer
    finalCalculationExpression.expression = `max(( ${producerVariables.join(' + ')} ), 0)`;
  }

  const countOfVariables = producerVariables.length + consumerVariables.length;
  // fill aggregations array with avg for all variables
  finalCalculationExpression.aggregations = [...Array(countOfVariables).fill('avg')];

  return finalCalculationExpression;
}
