
import { Vue, Component, Prop } from 'vue-property-decorator';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import ShowEventBase from '@/ui/components/devices/devices/base/ShowEventBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent HotWaterMeter device
 */
@Component({
  components: {
    OutputFieldBase,
    ShowEventBase,
    DeviceLayout,
    LabelUnitWrapper,
  },
})
export default class HotWaterMeter extends Vue {
  @Prop() deviceData!: IDevice;

  get isActualValueVariableFilled() {
    return !!this.deviceData.data.mappings.OutputField1_actualValue.length;
  }
}
