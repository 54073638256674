var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('WizardContentView',{attrs:{"valid":_vm.valid,"handle-back":_vm.handleDecrement,"handle-next":_vm.handleNext},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',[_vm._v(_vm._s(_vm.$t(`installationWizard.defineEmsSettings.heatingElementSettings.title`)))])]},proxy:true},{key:"content",fn:function(){return [_c('v-form',{ref:"form",staticClass:"general-form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},_vm._l((_vm.includedSystemsElectricHeatingDefinitions),function(element,key){return _c('v-row',{key:key},[_c('v-col',{attrs:{"sm":"12","md":"7","lg":"6"}},[_c('h3',[_c('span',[_vm._v(_vm._s(_vm.$t(`installationWizard.defineEmsSettings.heatingElementSettings.systemName`)))]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(key + 1)+":")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(element.systemType))])]),_c('div',{staticClass:"pt-4"},[_c('v-text-field',{attrs:{"label":_vm.$t(`installationWizard.defineEmsSettings.heatingPumpSettings.labels.title`),"type":"text","color":"accent","outlined":"","flat":"","rules":[_vm.rules.required]},model:{value:(element.title),callback:function ($$v) {_vm.$set(element, "title", $$v)},expression:"element.title"}}),_vm._l((_vm.editableSettings),function(entry,inx){return _c('div',{key:inx,staticClass:"d-flex"},[(entry.property !== 'legionella_protection')?_c('v-text-field',{attrs:{"value":_vm.includedSystemsElectricHeatingSystems[key][entry.property].value,"label":_vm.$t(
                      `installationWizard.defineEmsSettings.heatingElementSettings.${entry.property}.title`,
                    ),"color":"accent","type":"number","outlined":"","rules":[
                    ..._vm.fieldRules[entry.property],
                    ..._vm.minMaxValidation(
                      _vm.includedSystemsElectricHeatingSystems[key][entry.property].value,
                      entry.min,
                      entry.max,
                    ),
                  ]},on:{"input":function($event){return _vm.handleElectricHeatingField({ value: $event, key: entry.property, index: key })}}}):_c('v-select',{attrs:{"label":_vm.$t(
                      `installationWizard.defineEmsSettings.heatingElementSettings.${entry.property}.title`,
                    ),"color":"accent","outlined":"","hide-selected":"","items":entry.selection,"item-text":"text","item-value":"value","rules":_vm.fieldRules[entry.property]},on:{"input":function($event){return _vm.handleElectricHeatingField({ value: $event, key: entry.property, index: key })}},model:{value:(_vm.includedSystemsElectricHeatingSystems[key][entry.property].value),callback:function ($$v) {_vm.$set(_vm.includedSystemsElectricHeatingSystems[key][entry.property], "value", $$v)},expression:"includedSystemsElectricHeatingSystems[key][entry.property].value"}}),_c('div',{staticClass:"d-flex justify-center pt-4 pl-2"},[_c('InfoTooltip',{attrs:{"position":"right"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('lynus-icon',{attrs:{"name":"info-in-circle","color":_vm.doesHover ? 'accent' : 'theme',"size":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t( `installationWizard.defineEmsSettings.heatingElementSettings.${entry.property}.tooltip`, ))+" ")]},proxy:true}],null,true)})],1)],1)})],2)])],1)}),1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }