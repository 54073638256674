import { IProject, IProjectControl, IProjectFilters, IProjectMeta } from '@/types/project.types';

/**
 * @param projectList list of all projects as IProject[]
 * @param filterName filter as string
 * @returns list of projects filtered by name
 */
export function projectsFilteredByName(projectList: IProject[], filterName: string) {
  if (!filterName) return projectList;
  return projectList.filter((project: IProject) => {
    return project.name.toLowerCase().includes(filterName.toLowerCase());
  });
}

/**
 * @param projectList list of all projects as IProject[]
 * @param filterName filter as string
 * @returns list of projects filtered by controller project id
 */
export function projectsFilteredByControllerProjectId(projectList: IProject[], filterName: string) {
  if (!filterName) return projectList;
  return projectList.filter((project: IProject) => {
    return project.meta?.controller?.projectId.toLowerCase().includes(filterName.toLowerCase());
  });
}

/**
 * @param project project as IProject
 * @param filterName filter as string
 * @returns if project name or controller projectId is included in Project Object
 */
export function projectsFilteredByIdAndName(project: IProject, filterName: string) {
  if (!filterName) return project;
  return project.name.toLowerCase().includes(filterName.toLowerCase()) ||
    project.meta?.controller?.projectId?.toLowerCase().includes(filterName.toLowerCase());
}

/**
 * @param project project as IProject
 * @param filterName filter as string
 * @returns list of projects filtered based on the selected project status
 */
export function filterProjectsByStatus(project: IProject, status: string) {
  if (!status || status === '') return project;
  if (!project.stats) return false;
  switch (status) {
    case 'ok':
      return project.stats.warnings === 0 && project.stats.errors === 0;
    case 'suspend':
      return project.stats.warnings !== 0 && project.stats.errors === 0;
    case 'error':
      return project.stats.errors !== 0;
  }
}

/**
 * @param project project as IProject
 * @param key key of IProjectControl
 * @param filter filter as string
 * @returns if filter matches the controller info
 */
export function fuctionFilterByControllerInfo(project: IProject, key: keyof IProjectControl, filter: string) {
  if (project.meta.controller === undefined) return false;
  return project.meta.controller[key] === filter;
}

/**
 * Filter projects based on the given filters (projectTags, projectName, projectStatus, systemType, batterySystemType, projectId)
 * @param projectList list of all projects as IProject[]
 * @param projectFilters filter object as IProjectFilters
 * @returns list of projects filtered by the given filters
 */
export function filterProjects(projectList: IProject[], projectFilters: IProjectFilters) {
  let localProjectList = projectList;
  // check if filter for project tags is set
  if (projectFilters.projectTags !== undefined && projectFilters.projectTags.length > 0) {
    // filter project by project tags
    localProjectList = localProjectList.filter((project: IProject) => {
      const projectTags = project?.meta?.tags ?? [];
      return projectFilters.projectTags.some((tag: string) => projectTags.includes(tag)); // if tagsForFilter is [], it will return true
    });
  }
  // check if filter for project name is set
  if (projectFilters.projectName && projectFilters.projectName !== '') {
    // filter project by project name or controller project id
    localProjectList = localProjectList.filter((project: IProject) => {
      return projectsFilteredByIdAndName(project, projectFilters.projectName);
    });
  }
  // check if filter for project status is set
  if (projectFilters.projectStatus !== undefined && projectFilters.projectStatus !== '') {
    const status = projectFilters.projectStatus ?? '';
    // filter project by project status
    localProjectList = localProjectList.filter((project: IProject) => {
      return filterProjectsByStatus(project, status);
    });
  }
  // check if system type filter is set
  if (projectFilters.systemType !== undefined && projectFilters.systemType !== '') {
    // filter project by system type
    localProjectList = localProjectList.filter((project: IProject) => {
      return fuctionFilterByControllerInfo(project, 'batterySystemType', projectFilters.systemType ?? '');
    });
  }
  // check if battery system type filter is set
  if (projectFilters.batterySystemType !== undefined && projectFilters.batterySystemType !== '') {
    // filter project by battery system type (deyetype)
    localProjectList = localProjectList.filter((project: IProject) => {
      return fuctionFilterByControllerInfo(project, 'deyeType', projectFilters.batterySystemType ?? '');
    });
  }
  return localProjectList;
}
