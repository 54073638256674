
import { Vue, Component, Prop } from 'vue-property-decorator';
import AnimatedLine from '@/ui/components/devices/components/EnergyParts/AnimatedLine/index.vue';
import Preview from '@/ui/components/devices/components/EnergyParts/Preview.vue';

/**
 * Component that represent forecast system instance view.
 */
@Component({
  components: {
    AnimatedLine,
    Preview,
  },
})
export default class InstanceView extends Vue {
  @Prop({ default: null }) instanceData!: any;
  @Prop({ default: null }) instanceForecastData!: any;
  @Prop({ default: null }) instanceCanvasSize!: any;
  @Prop({ default: null }) instanceCanvasCenter!: any;
  @Prop({ default: null }) instanceLineData!: any;
  @Prop({ default: false }) instanceLineReady!: boolean;

  get animatedLineMovement() {
    return this.instanceForecastData[1] < 0;
  }
  get animatedLineActive() {
    return !!this.instanceForecastData[1];
  }
}
