// Default feedback timeout from PLC in seconds
export const defaultFeedbackTimeoutSeconds = 60;

/**
 * Default feedback timeout from PLC in milliseconds
 */
export const defaultFeedbackTimeoutMilliSeconds = defaultFeedbackTimeoutSeconds * 1000;

/**
 * Default timeout how long report messages will be shown in milliseconds
 */
export const defaultTimeoutRepotMessages = 20000;
