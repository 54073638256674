
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';

/**
 * Component that represent modal window with additional form
 * for Preview.vue, PreviewMQTTControls.vue
 */
@Component
export default class SystemModalWindowView extends Vue {
  @Prop({ default: 'Title' }) systemTitle!: string | VueI18n.TranslateResult;
  @Prop({ default: '1200' }) windowWidth!: number | string;
  @Prop({ default: true }) isCallContentClickEvent!: boolean;
  @Prop({ default: true }) enableSystemSettings!: boolean;

  dialog = false

  @Watch('dialog')
  onDialogChange(val: boolean) {
    this.$emit('handleSystemModalWindowContent', val);
  }

  handleDialog() {
    this.dialog = this.isCallContentClickEvent;
  }
}
