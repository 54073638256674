
import { Vue, Component, Prop } from 'vue-property-decorator';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import ManageCharts from '@/ui/components/modals/ManageChart/index.vue';
import { Action, Getter, State } from 'vuex-class';
import { calculatePageCount } from '@/utils/utilsFunctions';
import { IDevicesState } from '@/store/modules/devices/types';
import { IRoom } from '@/types/rooms.types';
import { IDevice } from '@/types/devices.types';

/**
 * Component that shows table of charts
 */
@Component({
  components: {
    DeleteModalForm,
    ManageCharts,
  },
})
export default class ChartsTable extends Vue {
  @Prop({ default: 100 }) itemsPerPage !: number;
  @State('devices') devicesState!: IDevicesState;
  @Getter('rooms/rooms') rooms!: IRoom[];
  @Getter('charts/charts') chartsList!: IDevice[];
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => void;
  @Action('devices/deleteDevice') deleteDevice!: (control: IDevice) => void;

  currentPage = 1;

  get pageNumber() {
    return calculatePageCount(this.filteredBySearch.length, this.itemsPerPage);
  }

  get headers() {
    return [
      { text: this.$t('uiComponents.workbenchTable.name'), align: 'start', value: 'name' },
      { text: this.$t('uiComponents.workbenchTable.type'), value: 'data.type' },
      { text: this.$t('uiComponents.workbenchTable.area'), value: '' },
      { text: this.$t('uiComponents.workbenchTable.actions'), value: 'actions', sortable: false },
    ];
  }

  get devicesFilter() {
    return this.devicesState.devicesFilter;
  }
  /**
   * Filters the existing list of chart by type, name, area affiliation
   * @return filtered charts
   */
  get filteredBySearch() {
    return this.devicesFilter ? this.chartsList
      .filter((device: IDevice) => `${device.name} ${this.deviceRoomName(device.collection_id)} ${device.data.type}`
        .toLowerCase().includes(this.devicesFilter.toLowerCase())) :
      this.chartsList;
  }

  /**
   * Defines the name of the room to which the chart belongs
   * @param roomId id of the room
   * @return room name if room has named or empty string
   */
  deviceRoomName(roomId: string) {
    if (this.rooms.length) {
      const room: any = this.rooms.find((room: any) => room.id === roomId);
      return room?.name || null;
    }
    return '';
  }

  editControl(payload: IDevice) {
    this.updateDevice({ device: payload, skipReport: false });
  }
  deleteControlHandle(control: IDevice) {
    this.deleteDevice(control);
  }
}
