import Highstock from 'highcharts/highstock';
import moment from 'moment';
import { formatDateForPeriod } from '@/utils/utilsFunctions';
import api from '@/services/api';
import i18n from '@/ui/plugins/i18n';
import ChartColors from './ChartColors';
import { getVariableNames, calculate, getScope, addAggsToExpression } from './ChartMath';
import {
  getChartOptions,
  getAlternativeVariableName,
  Periods,
  periodConfigurations,
  isView,
  checkAllScalingsEqual,
  isCalculation,
  getDateBoundsByPeriod,
} from './ChartUtils';

export const defaultChartColors = ChartColors.baseColors;

export default class LynusChart {
  constructor(
    device,
    projectId,
    baseURL,
    isDarkTheme,
    startedAt,
    initialBackgroundColor,
    stackingOP,
    periodConfigs = periodConfigurations,
    chartColors = defaultChartColors,
    customApproximationValue,
    onMouseMove,
    onDataLoaded,
  ) {
    this.chart; // holds the highcharts object
    this.projectId = projectId;
    this.baseURL = baseURL;
    this.device = device;// the device data we got from the API
    this.startedAt = startedAt;// contains the timestamp when the Project was created
    this.periodConfigs = periodConfigs;
    this.period = periodConfigs[Periods.DAY];
    this.countLines = 0;
    this.countColumns = 0;
    this.stackingOptions = stackingOP;
    this.isDarkTheme = isDarkTheme;
    this.backgroundColor = initialBackgroundColor;
    this.chartColors = chartColors;
    this.customApproximationValue = customApproximationValue;
    this.onMouseMove = onMouseMove; // (optional) callback for mouse move on chart container
    this.onDataLoaded = onDataLoaded;
    this.loadingError = false; // variable will trigger showing reload button when there is a error while loading

    const p = this.period.period();
    this.chartBounds = {
      start: p.start,
      end: p.end,
      endChart: p.endChart,
    };

    // stores a mapping from "variable_agg" -> data, to "cache" data and
    // avoid multiple requests for same data
    this.loadedVariables = {};

    this.customLoader = false;
  }

  /**
   * only switches the period to the given one
   * period: Periods (Periods.LIVE, Periods.DAY, ...) or simple string "live", "day", ...
   * @param {string} period current period
   */
  switchPeriod(period) {
    this.period = this.periodConfigs[period];
    const p = this.period.period();
    this.chartBounds = {
      start: p.start,
      end: p.end,
      endChart: p.endChart,
    };

    // clear loadedVariables
    this.loadedVariables = {};
  }

  /**
   * Main function for switching between periods (live, day, week, ...)
   * and loading according data.
   * Period: Periods (Periods.LIVE, Periods.DAY, ...)
   * or simple string "live", "day", ...
   * @param {string} period current period
   * @param {string} authToken auth token
   * @return {Promise<void>} chart data
   */
  async switchPeriodAndLoad(period, authToken) {
    this.switchPeriod(period);

    await this.loadChart(authToken, { start: this.chartBounds.start, end: this.chartBounds.end, endChart: this.chartBounds.endChart }, false);
  }

  // main function for switching calendar range (selected using date picker)
  // the expected parameters are a dateArray containing timestamps, and the authToken for the API
  async loadCalendarRange(date, authToken) {
    const toSeconds = 1000;
    const now = Math.trunc(new Date().getTime() / toSeconds);
    this.chartBounds = getDateBoundsByPeriod(date, this.period.title);
    if (this.chartBounds.end > now) {
      this.chartBounds.end = now;
    }

    await this.loadChart(authToken);
  }

  /**
   * Main entrypoint for updating all variables at once.
   * Updates the variable at the given index with the new value within the chart
   * @param {array} values list of values
   */
  liveUpdateAllVariables(values) {
    const time = new Date().getTime();
    const options = getChartOptions(this.device);

    this.chart.xAxis[0].update({
      min: time - 900000, // update the min for live chart
    });

    // add each data point
    values.forEach((value, index) => {
      // ignore column types
      if (options[index].type === 'column') return;

      const isThreshold = options[index].seriesType === 'Threshold';
      if (!isThreshold) this.chart.series[index].addPoint([time, value]);

      // remove values after given time
      if (this.chart.series[index].data.length > 1100) {
        this.chart.series[index].data.shift();
      }
    });

    this.updateScalingIfDataNotFit();
    // load darkmode if during live event
    this.updateTheme();
  }

  /**
   * Expects string identifier of container
   * @param container HTML element where the chart will be drawn
   */
  buildChart(container) {
    const allSeriesSameScalings = checkAllScalingsEqual(getChartOptions(this.device));
    // if a color is given in the chart series option that color will be used else the default color will be used
    if (this.device.data.chartOptions[0].seriesColor !== undefined) {
      const colorArray = [];
      this.device.data.chartOptions.forEach((element) => {
        colorArray.push(element.seriesColor);
      });
      this.chartColors = colorArray;
    }
    const updatedYAxis = this.updateYAxis();

    // create chart instance
    this.chart = Highstock.chart({
      chart: {
        backgroundColor: this.backgroundColor,
        animation: false,
        renderTo: container,
        legend: {
          itemStyle: {
            color: this.isDarkTheme ? '#ffffff' : '#000000',
          },
        },
        events: {
          redraw: () => {
            if (this.chart !== undefined) {
              this.chart.pointer.chartPosition = null;
              this.chart.render();
            }
          },
        },
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      // set Colors for series in Highcharts
      colors: this.chartColors,
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
      },
      loading: {
        labelStyle: {
          color: 'black',
        },
      },
      yAxis: updatedYAxis,
      series: getChartOptions(this.device).map((optionsItem, index) => {
        let { name } = optionsItem;
        const variableName = optionsItem.var;

        if (!name || name.length === 0) {
          // name not set
          name = getAlternativeVariableName(index, this.device);
        }

        return {
          name,
          variableName,
          data: [],
          yAxis: allSeriesSameScalings ? 0 : index,
          type: optionsItem.type,
          showInLegend: optionsItem.seriesType !== 'Threshold',
        };
      }),
      tooltip: {
        shared: true,
        // xDateFormat now working...
        xDateFormat: '%Y-%m-%d',
        valueDecimals: 2,
      },
      // https://stackoverflow.com/questions/51130861/how-do-i-get-remove-of-data-table-option-from-high-chart-export
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
        area: {
          marker: {
            enabled: false,
          },
        },
        column: {
          stacking: this.stackingOptions,
        },
      },
      time: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        useUTC: false,
      },
    });

    // load darkmode on area change
    this.updateTheme();

    // Add Title if Fullscreen is active
    document.addEventListener('fullscreenchange', event => this.onFullScreenChange());

    // watch ath zoom of browser or a resizing of the browser window
    window.addEventListener('resize', event => {
      setTimeout(() => { this.updateTheme(); }, 100);
    });

    // add mouse (touch) move callbacks to chart
    if (this.onMouseMove) {
      ['mousemove', 'touchmove', 'touchstart'].forEach((eventType) => {
        this.chart.container.addEventListener(
          eventType, (e) => {
            if (e !== undefined) {
              this.onMouseMove(this.chart, e);
            }
          },
        );
      });
    }
  }

  /**
   * Sets the global locale for Highcharts.
   * @param {*} locale the locale as a string, in format 'en', 'de' or 'it'
   */
  setLocale(locale) {
    // set locale and lang options
    moment.locale(locale);
    Highstock.setOptions({
      lang: {
        weekdays: moment.weekdays(),
        shortWeekdays: moment.weekdaysShort(),
        months: moment.months(),
        shortMonths: moment.monthsShort(),
      },
    });
  }

  /**
   * Updates the chart subtitle with the currently selected date in a readable format.
   */
  updateChartTitle() {
    const dates = [new Date(this.chartBounds.start * 1000)];

    // add also end date, in case of week
    if (this.period.title === 'week') dates.push(new Date(this.chartBounds.end * 1000));

    // update the chart title
    this.chart.update({
      title: {
        text: formatDateForPeriod(this.period.title, dates),
        style: {
          color: this.isDarkTheme ? '#fff' : '#000000',
          fontSize: 10,
        },
      },
    });
  }

  setIsDarkTheme(isDarkTheme) {
    this.isDarkTheme = isDarkTheme;
  }

  setDevice(device) {
    this.device = device;
  }

  setChartColors(chartColors) {
    this.chartColors = chartColors;

    const updatedYAxis = this.updateYAxis();
    this.chart.update({
      colors: this.chartColors,
      yAxis: updatedYAxis,
    });
  }

  updateYAxis() {
    const allSeriesSameScalings = checkAllScalingsEqual(getChartOptions(this.device));
    const yAxisArray = allSeriesSameScalings ? getChartOptions(this.device).slice(0, 1) : getChartOptions(this.device);

    const initYAxis = (chartOptions) => {
      return chartOptions.map((data, index) => ({
        labels: {
          formatter(context) {
            if (typeof context.value !== 'number') return '';

            // Use thousands separator for four-digit numbers too
            if (Number.isInteger(this.value)) {
              return `${context.value} ${data.unit || ''}`;
            } else {
              return `${context.value.toFixed(2)} ${data.unit || ''}`;
            }
          },
          style: {
            color: allSeriesSameScalings ? '#949494' : this.chartColors[index],
          },
        },
        title: {
          text: undefined,
        },
        min: data.scaling.min,
        max: data.scaling.max,
        endOnTick: yAxisArray.length === 1 || !allSeriesSameScalings,
        showEmpty: false,
      }));
    };
    return initYAxis(yAxisArray);
  }

  /**
   * Called when theme is changed. requires current theme background color to be passed.
   * @param {string} backgroundColor background color
   */
  onThemeChange(backgroundColor) {
    this.backgroundColor = backgroundColor;
    this.updateTheme();
  }

  onFullScreenChange() {
    this.chart.update({
      title: {
        text: document.fullscreenElement !== null ? this.device.name : undefined,
      },
    });
    // set Tilte color based on darkmode "on" or "off"
    this.chart.title.update({
      style: {
        color: this.isDarkTheme ? '#fff' : '#000000',
        fontSize: 20,
      },
    });

    // set darktheme on toggle fullscreen (timeout is needed)
    setTimeout(() => { this.updateTheme(); }, 10);
  }

  updateColumnLineCounts() {
    getChartOptions(this.device).forEach((variable) => {
      if (variable.type === 'column' || variable.type === 'diff') {
        this.countColumns++;
      } else {
        this.countLines++;
      }
    });
  }

  /**
   * Relies on this.isDarkTheme to be already set correctly
   */
  updateTheme() {
    // set Legend Text Color
    this.chart.legend.update({
      itemStyle: {
        color: this.isDarkTheme ? '#ffffff' : '#000000',
      },
    });
    // set background of Chart
    this.chart.chartBackground.attr({
      fill: this.backgroundColor,
    });
    this.chart.title.update({
      style: {
        color: this.isDarkTheme ? '#fff' : '#000000',
      },
    });
  }

  get isFullScreenOpen() {
    return this.chart.fullscreen.isOpen;
  }
  handleFullScreen() {
    this.chart.fullscreen.open();
  }

  setWidth(width) {
    if (this.chart) {
      this.chart.chartWidth = width;
    }
  }

  showLoading() {
    this.chart.showLoading();
  }

  /**
   * Toggle stacking options
   */
  toggleStacking() {
    if (this.stackingOptions === 'normal') {
      this.stackingOptions = undefined;// to disable
    } else {
      this.stackingOptions = 'normal';// to stack by value
    }
    this.chart.update({ plotOptions: { column: { stacking: this.stackingOptions } } });

    // update theme because otherwise it switches back to light theme
    this.updateTheme();
  }

  /**
   * Add threshold line(0-100%), used only on anomaly detection devices
   * @param {number} value threshold value
   */
  drawThreshold(value) {
    if (typeof value === 'number') {
      this.chart.yAxis[this.chart.yAxis.length - 1].addPlotLine({
        value,
        color: 'green',
        dashStyle: 'Dash',
        width: 3,
        label: {
          text: 'Threshold',
          align: 'right',
          x: -10,
          y: -5,
        },
        zIndex: 1,
      });
    }
  }

  /**
   * If in series data exist value which not fit in scaling, define new scaling regarding data max/min value
   */
  updateScalingIfDataNotFit() {
    this.chart.yAxis.forEach((d) => {
      const { dataMax, max, dataMin, min } = d;
      if (typeof min === 'number') {
        const minVal = dataMin < min ? null : min;
        d.update({ min: minVal });
      }
      if (typeof max === 'number') {
        const maxVal = dataMax > max ? null : max;
        d.update({ max: maxVal });
      }
    });
  }

  /**
   * Main entrypoint for loading chart
   * @param {string} authToken auth token
   * @return {Promise<void>}
   */
  async loadChart(authToken) {
    this.updateChartTitle();
    try {
      this.customLoader = true;
      const data = await this.loadDataAsList(this.baseURL, authToken, this.chartBounds);
      await this.load(data);
      this.onDataLoaded?.(data, this.chart, this.period);
      this.updateScalingIfDataNotFit();
    } catch (e) {
      this.customLoader = false;
      this.loadingError = true;
      this.chart.showLoading(i18n.t('errorMessages.charts.basicChartError', { message: e.message }));
    }
  }

  getChartStart(start) {
    // check if start Timestamp smaller than the Timestamp of the Creation of the Project
    const formattedCreatedAt = Math.trunc(this.startedAt / 1000);// example: 1601301551

    return start < formattedCreatedAt
      ? formattedCreatedAt
      : start;
  }

  async loadDataAsList(baseURL, authToken, bounds) {
    let chartVariablesList = [];
    const converted = [];

    // goes through all mapped variables and makes an array of variables to load from the backend
    getChartOptions(this.device).forEach((optionsItem, index) => {
      if (this.device.data.mappings || isView(optionsItem)) {
        chartVariablesList.push({ name: optionsItem.var, aggregation: optionsItem.agg });
      } else {
        const variableNames = getVariableNames(optionsItem.calculation.expression);
        variableNames.forEach((variable, variableIndex) => {
          chartVariablesList.push({ name: variable, aggregation: optionsItem.calculation.aggregations[variableIndex] });
        });
      }
    });

    // loads list of data from backend
    chartVariablesList = await this.loadDataList(baseURL, this.projectId, authToken, bounds.start, bounds.end, chartVariablesList);

    // gets series list from the loaded data from backend
    chartVariablesList = this.loadSeriesDataList(chartVariablesList);

    Object.values(chartVariablesList).forEach((currentVar) => {
      // convert data to milliseconds [[date_in_unix * 1000, value]]
      if (currentVar !== undefined) {
        const convertedSeries = currentVar.map(list => {
          if (Array.isArray(list)) {
            return [list[0] * 1000, list[1]];
          } else {
            return [];
          }
        });
        converted.push(convertedSeries);
      }
    });
    this.dataToExport = converted;
    return converted;
  }

  /**
   * loads the data from the API. corresponds to api.fetch in ts.
   * @param {string} baseURL base URL
   * @param {string} projectId project id
   * @param {string} chartVariablesList contains map with all variables and aggregations for the request
   * @param {string} authToken auth token
   * @param {string} start start time of chart
   * @param {string} chartEnd end time of chart
   * @return map of loaded Variables key: varName_Agg: [[timeStamp, val], [timeStamp, val]]
   */
  async loadDataList(baseURL, projectId, authToken, start, chartEnd, chartVariablesList) {
    const chartStart = this.getChartStart(start);

    if (this.countLines === 0 && this.countColumns > 0) {
      // subtract 1 second to not get into the subsequent interval
      chartEnd -= 1;
    }

    // delay in order to display timeout if request takes longer
    const delay = new Promise((_, reject) => {
      setTimeout(() => reject(new Error(i18n.t('errorMessages.charts.chartTimeout'))), 60000);
    });

    return Promise.race([delay, this.chartRequest(projectId, chartStart, chartEnd, chartVariablesList)]);
  }

  async chartRequest(projectId, chartStart, chartEnd, chartVariablesList) {
    // remove duplicates from chartVariablesList
    const filteredArray = chartVariablesList.filter((item, index, self) => index === self.findIndex((t) => (
      t.name === item.name && t.aggregation === item.aggregation
    )));

    // split array into chunks of 10 variables
    const variablesSplitInTen = this.splitArrayIntoChunks(filteredArray, 10);

    // load data from backend
    const values = await Promise.all(variablesSplitInTen.map(async (variables) => {
      const res = await api.fetch(
        `/projects/${projectId}/timeseries`,
        'POST',
        { start: chartStart, end: chartEnd, interval: this.period.interval, variables },
      );
      return res;
    }));
    // combine all values into one object with all the data
    const combinedObject = values.reduce((accumulator, currentObject) => {
      return { ...accumulator, ...currentObject };
    }, {});
    return combinedObject;
  }

  splitArrayIntoChunks(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }

  loadSeriesDataList(chartVariablesList) {
    const seriesDataList = [];
    getChartOptions(this.device).forEach((optionsItem, index) => {
      if (this.device.data.mappings || isCalculation(optionsItem)) {
        const variableValues = {};
        const { expression } = optionsItem.calculation;
        const variableNames = getVariableNames(expression);
        const { aggregations } = optionsItem.calculation;

        variableNames.forEach((element, index) => {
          const key = `${element}_${aggregations[index]}`;
          variableValues[key] = chartVariablesList[key];
        });

        // start with first variable, to have an array with [timestamp, value] of correct length
        const firstVariable = Object.values(variableValues)[0];

        // loop over entries [timestamp, value] and calculate the values according to the expression
        const result = firstVariable?.map((element, i) => {
          const timestamp = element[0];
          const scope = getScope(i, variableNames, aggregations, variableValues);
          const value = calculate(addAggsToExpression(expression, aggregations), scope);
          return [timestamp, value];
        });
        seriesDataList[index] = result;
      } else {
        const key = `${optionsItem.var}_${optionsItem.agg}`;
        seriesDataList[index] = chartVariablesList[key];
      }
    });

    return seriesDataList;
  }

  /**
   * loads the passed loadedData into the chart.
   * @param {object} loadedData loaded data
   * @return {Promise<void>}
   */
  async load(loadedData) {
    let endChart = this.chartBounds.endChart * 1000;
    if (this.period.title === Periods.LIVE) {
      endChart = undefined;
    }

    // set data to each chart series
    loadedData.forEach((data, index) => {
      this.chart.series[index].update({
        data,
      });
    });

    // set units for columnChart
    getChartOptions(this.device).forEach((variable, index) => {
      if (variable.type === 'column' || variable.type === 'diff') {
        // set diffrent approximation value
        const approximationValue = this.getApproximationValue(variable.agg);

        // set datagrouping for column
        // costum Values Array is needed to modify the Datagrouping. Example: normal charts with day period has one column every 4 hours.
        // with modulare ones. Heating Prediction Device for example has one every hour
        const units = this.getDataGroupingUnits();
        this.chart.series[index].update({
          dataGrouping: {
            forced: true,
            approximation: approximationValue,
            enabled: true,
            units,
          },
        });
      }
    });

    // set xDateFormatter
    this.chart.tooltip.update({ xDateFormat: this.period.tooltipFormat });

    // Load Options & set zIndex for Chart
    getChartOptions(this.device).forEach((variable, index) => {
      this.chart.series[index].update({
        zIndex: variable.type === 'column' ? 2 : 4,
      });
    });

    // set the min and max values of the xAxis (xAxis[0])
    this.chart.xAxis[0].update({
      min: this.chartBounds.start * 1000,
      max: endChart,
      labels: {
        formatter: (e) => {
          const init = +e.value;
          const d = new Date(init);
          const utc = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds());
          return Highstock.dateFormat(this.period.format, utc);
        },
      },
      tickInterval: this.period.tickInterval,
    });

    // load darkmode if selected
    this.updateTheme();
    this.chart.redraw();

    const hasData = this.chart.series.some((series) => series.options.data.some((point) => point[1] !== null));
    this.customLoader = false;
    if (!hasData) {
      this.chart.showLoading('No data in selected period.');
    }
  }

  getDataGroupingUnits() {
    switch (this.period.title) {
      case 'day':
        return [['hour', [this.customApproximationValue.day]]];
      case 'week':
        return [['day', [this.customApproximationValue.week]]];
      case 'month':
        return [['day', [this.customApproximationValue.month]]];
      case 'year':
        return [['month', [this.customApproximationValue.year]]];
    }
  }

  getApproximationValue(agg) {
    switch (agg) {
      case 'min':
        return 'low';
      case 'max':
        return 'high';
      case 'last':
        return 'close';
      case 'first':
        return 'open';
      case 'diff':
        return 'sum';
      default:
        return 'average';
    }
  }
}
