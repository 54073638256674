
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent Lynus icon custom library
 * List off icons placed in public/icons.css
 */
@Component
export default class LynusIcon extends Vue {
  @Prop({ default: '' }) name!: string;
  @Prop({ default: 'md' }) scale!: string;
  @Prop({ default: null }) size!: number | string;
  @Prop({ default: null }) color!: string;
  @Prop({ default: false }) animation!: string | boolean;

  get scaleClass() {
    return this.scale && !this.size ? `icon-size-${this.scale}` : null;
  }
  get sizeValue() {
    return this.size ? `font-size: ${this.size}px` : null;
  }
}
