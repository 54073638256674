
import { Vue, Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { IProject } from '@/types/project.types';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    WizardContentView,
  },
})
export default class ManualsPage extends Vue {
  @Getter('projects/project') project!: IProject;
  @Action('loggerWizard/handleIncrement') handleIncrement!: () => void;
  @Action('loggerWizard/handleDecrement') handleDecrement!: () => void;

  handleNext() {
    this.handleIncrement();
  }
}
