
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import EnergyVisualisation from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/index.vue';
import ActualViewSystemDialog from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/ActualView/ActualViewSystemDialog.vue';
import { circlesDisplayDataMock, linesDisplayDataMock } from '@/ui/components/devices/previews/mockObjects/energyDevicesMockObjects';
import { EnergyCircleType } from '@/types/energyVisualisation/EnergyCircleType';
import { EnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent EMS EnergyFlow view
 */
@Component({
  components: {
    EnergyVisualisation,
    ActualViewSystemDialog,
  },
})
export default class ActualView extends Vue {
  @Prop() data!: IDevice;
  @Prop({ default: '650px' }) energyVisualisationHeight!: string;

  @Provide('onCircleClick') onCircleClick = this.onCircleClickCallback;

  showDialog = false;
  showSystemSettingsDialog = false;
  openedSystem?: EnergyCircleType;

  onCircleClickCallback(circleData: EnergyCircleDisplayData) {
    this.openedSystem = circleData.id as EnergyCircleType;
    this.showDialog = true;
  }

  localCirclesDisplayData = circlesDisplayDataMock;
  localLinesDisplayData = linesDisplayDataMock;
}
