import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/store/types';
import { IVariableListState } from '@/store/modules/variables/types';
import api from '@/services/api';
import { IVariable } from '@/types/variables.types';

const state: IVariableListState = {
  variables: [],
};

const getters: GetterTree<IVariableListState, RootState> = {
  variables(state: IVariableListState) {
    return state.variables;
  },
  variablesForComboBox(state: IVariableListState) {
    const variableList = state.variables.map((element: IVariable) => {
      if (element.alias !== null) {
        return { text: `${element.variable}  (${element.alias})`, value: element.variable, alias: element.alias };
      } else {
        return { text: element.variable, value: element.variable, alias: element.alias };
      }
    });
    // contains a list of variables that should be filtered out, currently it only contains the heartbeat values
    const variableValuesToFilter = ['$hb', '$hb2'];
    return variableList.filter((element) => !variableValuesToFilter.includes(element.value));
  },
};

const mutations: MutationTree<IVariableListState> = {
  setVariables(state, variables) {
    state.variables = variables;
  },
};

const actions: ActionTree<IVariableListState, RootState> = {
  /**
   * Load project measurements
   * @param commit
   * @param projectId
   */
  async fetchVariables({ commit }, projectId) {
    try {
      const variables = await api.fetch(`/projects/${projectId}/variables`, 'GET');
      commit('setVariables', variables);
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  async modifyVariableAlias({ commit }, { projectId, variable }) {
    try {
      const response = await api.fetch(`/projects/${projectId}/variables/${variable.variable}`, 'PUT', { alias: variable.alias });
      commit('app/setReport', {
        type: 'success',
        message: 'Alias updated',
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  async modifyVariableAliasList({ commit }, { projectId, variablesObject }) {
    try {
      const response = await api.fetch(`/projects/${projectId}/variables`, 'PUT', variablesObject);
      commit('app/setReport', {
        type: 'success',
        message: 'Alias updated',
        value: true,
      }, { root: true });
      return true;
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
      return false;
    }
  },
};

export const variables: Module<IVariableListState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
