import api from '@/services/api';
import { IPartner } from '@/types/partner.types';

export default {
  async fetchPartners() {
    return api.fetch('/admin/partners', 'GET'); // returns all partners
  },
  async createPartner(partner: IPartner) {
    return api.fetch('/admin/partners', 'POST', { data: partner });
  },
  async fetchPartner(id: string) {
    return api.fetch(`/admin/partners/${id}`, 'GET');
  },
  async updatePartner(id: string, partner: IPartner) {
    return api.fetch(`/admin/partners/${id}`, 'PUT', partner);
  },
  async deletePartner(id: string) {
    return api.fetch(`/admin/partners/${id}`, 'DELETE');
  },
  async fetchPartnerNonAdmin() {
    return api.fetch('/partner', 'GET'); // returns the parnter object of the logged in user
  },
  async updatePartnerNonAdmin(id: string, partner: IPartner) {
    return api.fetch('/partner', 'PUT', partner);
  },
};
