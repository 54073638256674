
import { Vue, Component } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import ProjectItem from '@/ui/components/components/ProjectItem.vue';
import { IProject } from '@/types/project.types';
import { filterProjects } from '@/utils/filterUtils';

/**
 * Component that represent all projects in Flex grid.
 * Used in Home page.
 */
@Component({
  components: {
    ProjectItem,
  },
})
export default class ProjectsListFlexGrid extends Vue {
  @State('projects') projectsState!: any;
  @Getter('projects/projects') projects!: IProject[];

  get filteredProjects() {
    const { projectFilters } = this.projectsState;
    return filterProjects(this.projects, projectFilters) ?? [];
  }
}
