// This File will contain all default Mappings for every Device Type (EnergyView)
// This Mappings can be used for the Logger Wizard only !!

// EnergyView Functions
export function pvMappingEnergyView(loggerName: string, isAcPv: boolean, index: number) {
  return {
    power: !isAcPv ? `solarman.${loggerName}.PV.power` : `solarman.${loggerName}.Gen.power`,
    error: '',
    title: !isAcPv ? `PV DC Total Inverter ${index + 1}` : `PV AC Total Inverter ${index + 1}`,
    energy_counter: !isAcPv ? `solarman.${loggerName}.PV.counter` : `solarman.${loggerName}.Gen.counter`,
  };
}

export function generatorMappingEnergyView(loggerName: string, index: number) {
  return {
    energy_counter: `solarman.${loggerName}.Gen.counter`,
    error: '',
    power: `solarman.${loggerName}.Gen.power`,
    title: `Generator Inverter ${index + 1}`,
  };
}

export function batteryMappingEnergyView(loggerName: string, index: number) {
  return {
    energy_counter: `solarman.${loggerName}.Battery.reverseCounter`,
    reverse_energy_counter: `solarman.${loggerName}.Battery.counter`,
    error: '',
    power: `solarman.${loggerName}.Battery.power`,
    soc: `solarman.${loggerName}.Battery.soc`,
    title: `Batterie Inverter ${index + 1}`,
  };
}

export function gridMappingEnergyView() {
  return {
    energy_counter: 'solarman.Grid.reverseCounter', // only hybrid add .hybrid, if only string add .string, for mixed sytems .string
    reverse_energy_counter: 'solarman.Grid.counter', // only hybrid add .hybrid, if only string add .string, for mixed sytems .string
    error: '',
    power: 'solarman.Grid.power', // only hybrid add .hybrid, if only string add .string, for mixed sytems .hybrid
    title: 'Netz',
    is_on_grid: 'solarman.Grid.isOnGrid',
  };
}

export function houseMappingEnergyView() {
  return {
    energy_counter: 'solarman.House.counter', // only hybrid add .hybrid, if only string add .string, for mixed sytems .string
    error: '',
    power: 'solarman.House.power', // only hybrid add .hybrid, if only string add .string, for mixed sytems .hybrid
    title: 'Haus',
  };
}
