
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class PushButtonMock extends Vue {
  @Prop({ default: 'push_button' }) icon!: string;
  @Prop({ default: null }) iconSize!: null | number | string;
  @Prop({ default: 40 }) buttonSize!: string | number;

  isActive = false
}
