
import { Component, Prop, Vue } from 'vue-property-decorator';
import PlaceHolderImage from '@/ui/components/components/PlaceHolderImage.vue';
import StatusIndicatorLine from '@/ui/components/lists/ProjectsList/StatusIndicatorLine.vue';
import { envProjectOpenSubpage, envShowProjectInfo } from '@/utils/env';
import { IProject } from '@/types/project.types';
import { buildCommaSeparatedString, formatDate, roundNumber } from '@/utils/utilsFunctions';
import { IMeasurements } from '@/types/measurements.types';
import { Action, Getter } from 'vuex-class';

/**
 * Component that represent project item
 */
@Component({
  components: {
    StatusIndicatorLine,
    PlaceHolderImage,
  },
})
export default class ProjectItem extends Vue {
  @Prop({ default: null }) project!: IProject;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Getter('measurements/measurements') measurements!: IMeasurements;

  readonly showProjectInfo: boolean = envShowProjectInfo;
  onCard = false;
  onButton = false;
  extended = false;

  savedMeasurements: any = {};

  /**
   * Defines current project city name
   * @return {string} city name
   */
  get projectLocationName(): string {
    const displayName = this.project.meta?.location?.display_name;
    if (displayName) {
      const partsOfName = displayName.split(',');
      return buildCommaSeparatedString(partsOfName, 4);
    }
    return '';
  }

  get wizardState() {
    return !!this.project?.meta?.wasInstallationWizardCompleted;
  }

  get wizardDoneDate() {
    const date = this.project?.meta.wizard_completed_at;
    return date ? formatDate(new Date(date)) : '';
  }

  get pvPower() {
    const pvPowerVariable = 'prgEnergy.fbI.rMaxPowerInverter';
    const power = this.savedMeasurements[pvPowerVariable] as number ?? 0;
    return power ? `${roundNumber(power / 1000, 2)} kW` : '0.0 kW';
  }

  get projectBatteryCapacity() {
    let totalPower = 0;
    for (let i = 1; i <= 20; i++) {
      const powerVariable = `prgEnergy.lrMaxCapacityBattery${i}`;
      totalPower += this.savedMeasurements[powerVariable] as number ?? 0;
    }
    return `${roundNumber(totalPower, 2)} kWh`;
  }

  itemHeight = 340;

  get extension() {
    const extensionValue = this.hasWizardDoneDate ? 120 : 80;
    return this.extended ? extensionValue : 0;
  }

  get hasWizardDoneDate() {
    return !!this.project?.meta.wizard_completed_at;
  }

  /**
   * Define height of element according to window size
   * @return {number} height of project item
   */
  get breakpoints(): number {
    return this.itemHeight + this.extension;
  }

  /**
   * Define project image, icons sizes according to window size
   * @return {object} image, icons sizes
   */
  get styles(): object {
    return {
      image: 210,
      icon: 22,
    };
  }
  get statusIcons() {
    return {
      ok: {
        icon: 'ok',
        text: 'ok',
      },
      warning: {
        icon: 'warning',
        text: 'warning',
      },
      error: {
        icon: 'fault',
        text: 'error',
      },
    };
  }

  /**
   * Checks projects warnings, errors and define status according to them
   * @return {array} current status
   */
  get status(): any {
    const { errors, warnings } = this.project?.stats as any;
    const arr: any = [[errors, 'error'], [warnings, 'warning']];
    return arr.find((el: any) => el[0]) || [null, 'ok'];
  }

  async toggleExtension() {
    if (this.project.id) {
      await this.fetchMeasurements(this.project.id);
    }
    this.savedMeasurements = { ...this.measurements };
    this.extended = !this.extended;
  }

  openProject() {
    this.$store.commit('router/setScrollPosition', {
      x: window.scrollX,
      y: window.scrollY,
    });
    this.$router.push(`/projects/${this.project.id}${envProjectOpenSubpage}`);
  }
}
