
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import Switch2VBase from '@/ui/components/devices/devices/base/Switch2VBase.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import PreviewMQTTControls
  from '@/ui/components/devices/components/EnergyParts/PreviewMQTTControls.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import { formatValue } from '@/utils/utilsFunctions';
import { IDevice } from '@/types/devices.types';

/**
 * Settings for single house system.
 */
@Component({
  components: {
    OutputFieldBase,
    Switch2VBase,
    PushButtonBase,
    PreviewMQTTControls,
    LabelUnitWrapper,
  },
})
export default class HouseSystemSettings extends mixins(EnergyComponent) {
  @Prop({ default: null }) instanceData!: any;
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop() bottomValue!: number | undefined;

  get originalValue() {
    return this.measurements[this.instanceData.power];
  }

  get actualValue() {
    const value = this.bottomValue;
    return typeof value === 'number' ? formatValue(value) : undefined;
  }

  get switch2VEnable() {
    return {
      'Switch2V_onOff': this.instanceData.switch_enable,
      'Switch2V_state': this.instanceData.state_enable,
    };
  }
}
