import {
  BatteryType,
  deyeTypes,
} from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { IIncludedSystemsTypesLogger, ILoggerBatteryDefinition, ILoggerGeneratorDefinition } from './wizards/loggerWizard.types';
import { WizardPath } from '@/types/wizards/installationWizard.types';

export interface IProject {
  id?: string;
  name: string;
  secret?: string;
  created_at?: Date;
  lat: number;
  lon: number;
  connectivity: IProjectConnectivity;
  limits: IProjectLimits;
  meta: IProjectMeta;
  owner_id: string;
  stats?: IProjectStats;
  started_at: string;
  joinable?: boolean;
}

export interface IProjectExpert {
  name: string;
  country: string;
  zip: string;
  city: string;
  street: string;
  email: string;
  phone: string;
  employeeName?: string;
}

export interface IProjectControl {
  type: string;
  serialNumber: string;
  projectId: string;
  batterySystemType: BatteryType;
  deyeType: deyeTypes | null;
  plcPerformanceLevel?: string;
}

export interface IProjectMeta {
  plcLastUpdate: Date | undefined;
  description: string;
  expert: IProjectExpert;
  controller: IProjectControl;
  isDNDActive: boolean;
  isAreasMiniView: boolean;
  imageId?: string;
  location?: IProjectLocation;
  disableDevicesWhenOffline: boolean;
  roomsPositions: any;
  tags: string[];
  energyPrice: IEnergyPrice;
  projectCurrency?: '€' | 'CHF' | '';
  wasInstallationWizardCompleted?: boolean;
  wasTenantWizardCompleted?: boolean;
  wizardSettings?: IWizardSettings;
  wizard_completed_at?: string;
  disableInstallationWizardPopup?: boolean;
  checkProtocol?: {
    signature: string;
    timestamp: string;
    serialNumber: string;
    everyPointChecked: boolean;
  };
  loggerInformation?: { id: string; type: string }[];
  loggerType?: string[];
  solarmanLoggerInfo?: {
    battery: {
        definition: ILoggerBatteryDefinition[];
    };
    generator: {
        definition: ILoggerGeneratorDefinition[];
    };
  };
  overallProductionMeasurement?: string;
  hasLynusInverter?: boolean;
}

export enum ChargeStationType {
  WebastoNext11 = 'WebastoNext11',
  WebastoNext22 = 'WebastoNext22',
  WebastoUnite11 = 'WebastoUnite11',
  WebastoUnite22 = 'WebastoUnite22',
  internalWeidmüller11 = 'internalWeidmüller11',
  internalWeidmüller22 = 'internalWeidmüller22',
  externalWeidmüller11 = 'externalWeidmüller11',
  externalWeidmüller22 = 'externalWeidmüller22',
  internalWeidmüllerMS11 = 'internalWeidmüllerMS11',
  internalWeidmüllerMS22 = 'internalWeidmüllerMS22',
  Schneider11 = 'Schneider11',
  Schneider22 = 'Schneider22',
  AmtronCC11 = 'AmtronCC11',
  AmtronPro11 = 'AmtronPro11',
  AmtronPro22 = 'AmtronPro22',
  AmtronTC22 = 'AmtronTC22',
  AmedioPro22 = 'AmedioPro22',
  Enercharge50 = 'Enercharge50',
  Enercharge100 = 'Enercharge100',
}

export interface IChargeStationSetting {
  type: ChargeStationType;
}

export interface IWizardSettings {
  producerOptionsEnabled: boolean;
  consumerOptionsEnabled: boolean;
  selectedHeatingPumps: string[];
  pvPerInverter: { [key: number]: number };
  isHybrid: boolean;
  wizardPath: WizardPath;
  hybridSettings: {
    useBothBatteryInputs: boolean;
  };
  chargeStationSettings: IChargeStationSetting[];
}

export interface IEnergyPrice {
  buyPrice: number;
  sellPrice: number;
}

export interface IProjectLocation {
  display_name: string;
  lat: string;
  lon: string;
}

export interface IProjectStats {
  errors: number;
  warnings: number;
}

export interface IProjectConnectivity {
  actions: any;
  enabled: boolean;
}

export interface IProjectLimits {
  collections: number;
  devices: number;
  members: number;
}

export interface IProjectMQTTInfo {
  hostname: string;
  password: string;
  port: string;
  topicPublish: string;
  topicSubscribe: string;
  username: string;
}

export interface IProjectFilters {
  projectName: string;
  projectStatus: string | undefined;
  projectTags: string[];
  batterySystemType: string | undefined;
  systemType: string | undefined;
}
