import SerialNumberService from '@/services/SerialNumberService';
import { RootState } from '@/store/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IMQTTState } from '@/store/modules/mqtt/types';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';
import { IInverter, SerialNumberType } from '@/types/serialNumber.types';

export interface IInvertersState {
  inverters: IInverter[];
  serialNumbersFromMqtt: { [key: string]: string };
  inverterFilter: string;
}

const state: IInvertersState = {
  inverters: [],
  serialNumbersFromMqtt: {},
  inverterFilter: '',
};

const getters: GetterTree<IInvertersState, RootState> = {
  inverters: (state: IInvertersState) => state.inverters,
  serialNumbersFromMqtt: (state: IInvertersState) => state.serialNumbersFromMqtt,
  inverterFilter: (state: IInvertersState) => state.inverterFilter,
};

const mutations: MutationTree<IInvertersState> = {
  setInverters(state: IInvertersState, inverters: IInverter[]) {
    state.inverters = inverters;
  },
  setSerialNumber(state: IInvertersState, serialNumber: { name: string; value: string }) {
    state.serialNumbersFromMqtt[serialNumber.name] = serialNumber.value;
  },
  setInverterFilter(state: IInvertersState, inverterFilter: string) {
    state.inverterFilter = inverterFilter;
  },
  appendInverters(state: IInvertersState, inverters: IInverter[]) {
    state.inverters = [...state.inverters, ...inverters];
  },
  replaceInverter(state: IInvertersState, newInverter: IInverter) {
    const inverterIndex: number = state.inverters.findIndex((inverter: IInverter) => inverter.id === newInverter.id);
    if (inverterIndex !== -1) {
      state.inverters[inverterIndex] = newInverter;
    }
  },
  removeInverter(state: IInvertersState, inverterId: string) {
    state.inverters = state.inverters.filter((inverter: IInverter) => inverter.id !== inverterId);
  },
};

const actions: ActionTree<IInvertersState, RootState> = {
  async fetchInverters({ commit }) {
    const serialNumbers: IInverter[] = await SerialNumberService.fetchInverters();
    commit('setInverters', serialNumbers);
  },
  async createInverters({ state, dispatch, commit }, serialNumbers: { id: string; description: string; type: SerialNumberType }[]) {
    const response = await SerialNumberService.createInverters(serialNumbers);
    commit('appendInverters', serialNumbers.filter((inverter: { id: string; description: string; type: SerialNumberType }) => !state.inverters.some((inverter2: IInverter) => inverter2.id === inverter.id)));
  },
  async updateSerialNumber({ commit }, serialNumber: IInverter) {
    const inverter: IInverter = await SerialNumberService.updateInverter(serialNumber);
    commit('replaceInverter', inverter);
  },
  async deleteSerialNumber({ commit }, id: string) {
    await SerialNumberService.deleteInverter(id);
    commit('removeInverter', id);
  },
  async activateSerialNumbers({ commit }, payload: { projectId: string; inverters: { id: string; type: SerialNumberType }[] }) {
    const { projectId, inverters } = payload;
    const status: any = await SerialNumberService.checkInverters(projectId, inverters.map((inverter: { id: string; type: SerialNumberType }) => ({ id: inverter.id, type: inverter.type })));
    return status.success;
  },
};

export const serialNumber: Module<IInvertersState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
