import api from '@/services/api';
import { IProject } from '@/types/project.types';
import { IInverter, SerialNumberType } from '@/types/serialNumber.types';

export default {
  async fetchInverters() {
    return api.fetch('/admin/inverters', 'GET');
  },
  async createInverters(inverters: { id: string; description: string; type: SerialNumberType }[]) {
    return api.fetch('/admin/inverters', 'POST', inverters);
  },
  async updateInverter(inverter: IInverter) {
    return api.fetch(`/admin/inverters/${inverter.id}`, 'PUT', inverter);
  },
  async deleteInverter(inverterId: string) {
    return api.fetch(`/admin/inverters/${inverterId}`, 'DELETE');
  },
  async checkInverters(projectId: string, inverters: { id: string; type: SerialNumberType }[]) {
    return api.fetch(`projects/${projectId}/activate`, 'POST', inverters);
  },
};
