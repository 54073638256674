
import { IPartnerWorkspace } from '@/types/partnerWorkspace.types';
import { buildCommaSeparatedString } from '@/utils/utilsFunctions';
import { IProject, IProjectStats } from '@/types/project.types';
import { envProjectOpenSubpage } from '@/utils/env';
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {},
})
export default class ConnectWithProject extends Vue {
  @Getter('projects/projects') projects!: IProject[];
  @Getter('partnerWorkspaces/workspace') workspace!: IPartnerWorkspace;
  @Action('partnerWorkspaces/updateWorkspace') updateWorkspace!: (data: {project_id: string; workspace: IPartnerWorkspace; showMessage: boolean}) => Promise<void>;

  selectedProjectId = '';
  userNotInProject = false;
  currentProject: null | IProject = null;

  get projectSelectionItems() {
    return this.projects.map((project: IProject) => ({
      text: project.name,
      value: project.id,
    }));
  }

  get statusIcons() {
    return {
      ok: {
        icon: 'ok',
        text: 'ok',
      },
      warning: {
        icon: 'warning',
        text: 'warning',
      },
      error: {
        icon: 'fault',
        text: 'error',
      },
    };
  }

  /**
   * Checks projects warnings, errors and define status according to them
   * @return {array} current status
   */
  get status(): string {
    if (!this.currentProject) return 'ok';
    const { errors, warnings } = this.currentProject?.stats as IProjectStats;
    const arr: any = [[errors, 'error'], [warnings, 'warning']];
    return arr.find((el: any) => el[0]) || [null, 'ok'];
  }

  /**
   * get project name by id
   */
  getProjectNameById(id: string) {
    const project = this.projects.find((p: IProject) => p.id === id);
    return project ? project.name : '';
  }

  /**
   * update workspace with selected project_id
   */
  async setProject(event: any) {
    const workspaceCopy = {
      ...this.workspace,
    };
    workspaceCopy.meta.project_id = event;
    await this.updateWorkspace({ project_id: this.workspace.id, workspace: workspaceCopy, showMessage: true });
    // update selectedProjectId so project card will be visible
    const project = this.projects.find((p: IProject) => p.id === event);
    if (project) {
      this.currentProject = project;
    }
    this.selectedProjectId = this.workspace.meta.project_id ?? '';
  }

  /**
   * Defines current project city name
   * @return {string} city name
   */
  getProjectLocationName(project: IProject): string {
    if (!project.meta.location) return '';
    const displayName = project.meta.location.display_name;
    if (displayName) {
      const partsOfName = displayName.split(',');
      return buildCommaSeparatedString(partsOfName, 4);
    }
    return '';
  }

  /**
   * navigate to connected project
   */
  enterProject() {
    this.$router.push(`/projects/${this.selectedProjectId}${envProjectOpenSubpage}`);
  }

  /**
   * reset selectedProjectId and update workspace
   */
  async handleDelete() {
    // reset selectedProjectId so project selection will be visible
    this.selectedProjectId = '';
    const workspaceCopy = {
      ...this.workspace,
    };
    workspaceCopy.meta.project_id = this.selectedProjectId;
    await this.updateWorkspace({ project_id: this.workspace.id, workspace: workspaceCopy, showMessage: true });
  }

  created() {
    // if workspace has a project_id, set it as selected
    if (this.workspace.meta.project_id) {
      const project = this.projects.find((p: IProject) => p.id === this.workspace.meta.project_id);
      // if project_id is not found this means that the user is not inside the connected project
      if (!project) {
        this.userNotInProject = true;
        return;
      }
      this.selectedProjectId = this.workspace.meta.project_id;
    }

    const project = this.projects.find((p: IProject) => p.id === this.selectedProjectId);
    if (project) {
      this.currentProject = project;
    }
  }
}
