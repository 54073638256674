import { Vue, Component } from 'vue-property-decorator';
import {
  celsiusUnit,
  emptyUnit,
  kilogramUnit,
  kiloWattHourUnit,
  kiloWattUnit,
} from '@/utils/unit/unitConstants';

/**
 * Unit Mixins to use globally
 */
@Component
export class UnitMixin extends Vue {
  emptyUnit = emptyUnit;
  kiloWattUnit = kiloWattUnit;
  kiloWattHourUnit = kiloWattHourUnit;
  kilogramUnit = kilogramUnit;
  celsiusUnit = celsiusUnit;
}
