
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SystemDialog extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ default: 1024 }) maxWidth!: number | string;
  @Prop({ default: false }) disableCloseButton!: boolean;

  closeDialog() {
    this.$emit('input', false);
  }
}
