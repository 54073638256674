
import { Component, Prop } from 'vue-property-decorator';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import ChartDevice from '@/ui/components/devices/charts/charts/ChartDevice.vue';
import { hasSystem } from '@/utils/utilsFunctions';
import { IDevice } from '@/types/devices.types';
import { mixins } from 'vue-class-component';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import {
  EnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';

/**
 * Component that represent chart Production/Consumption.
 */
@Component({
  components: {
    BaseChartWrapper,
    CircleSpinner,
    ChartDevice,
  },
})
export default class ChartProdCons extends mixins(EnergyComponent) {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop() mpcId!: any;
  @Prop() scaling!: any;
  @Prop() settingsWrapperHeight!: number;

  tab = null
  autarchy: any = null

  get autarchyValid() {
    if (!this.autarchy) return null;
    else {
      if (this.autarchy.autarchy_score > 100) return 100;
      if (this.autarchy.autarchy_score < 0) return 0;
      return this.autarchy.autarchy_score;
    }
  }

  /**
   * Prepares chart options for Actual Power
   */
  get pvActualPower() {
    const pvSystemComponents = this.getSystemComponents(this.deviceData, EnergyCircleType.pv);
    const list: any = Object.keys(pvSystemComponents);
    const aggregations: any = list.map((el: string) => 'avg');
    const expression: any = list.map((el: string) => pvSystemComponents[el].power).join(' + ');
    return {
      aggregations,
      expression,
    };
  }

  /**
   * Prepares chart options for Calculated Power
   */
  get pvCalculatedPower() {
    const list: any = Object.keys(this.getSystemComponents(this.deviceData, EnergyCircleType.pv));
    const aggregations: any = list.map((el: string) => 'avg');
    const expression: any = list.map((el: string) => `${el}.${this.mpcId}.pow`).join(' + ');
    return {
      aggregations,
      expression,
    };
  }

  /**
   * Prepares chart options for consumption power
   */
  get consumptionActualPower() {
    const systems = ['grid', 'battery', 'pv', 'generator'];
    const filteredByNeededSystems: any = Object.entries(this.getSystems(this.deviceData)).filter((group: any) => systems.some((el: any) => el === group[0]));
    const allVariables: any = filteredByNeededSystems.map((system: any) => Object.values(system[1].components).map((el: any) => el.power));
    const allVarsFlat: any = allVariables.reduce((acc: any, el: any) => acc.concat(el), []);
    const aggregations: any = allVarsFlat.map((el: any) => 'avg');
    const expression: any = allVarsFlat.length ? allVarsFlat.join(' + ') : null;
    return {
      aggregations,
      expression,
    };
  }
  get consumptionCalculatedPower() {
    return `con.${this.mpcId}.pow`;
  }

  get pvScaling() {
    if (this.scaling?.pv) {
      return +this.scaling.pv;
    }
    return null;
  }
  get consumptionScaling() {
    if (this.scaling?.consumption) {
      return +this.scaling.consumption;
    }
    return null;
  }

  /**
   * Prepares an option object understandable for the chart component.
   * List of charts.
   */
  get items() {
    const chartOptions: any = [];
    hasSystem('pv', this.getSystems(this.deviceData)) && chartOptions.push(
      {
        title: this.$t('mlModel.EMS.charts.chartProdConst.powerPV.title'),
        id: 'pvPower',
        component: 'BaseChartWrapper',
        data: {
          chartTitle: 'Power PV',
          chartWidth: null,
          chartData: {
            name: '',
            data: {
              chartOptions: [
                {
                  calculation: this.pvActualPower,
                  name: this.$t('mlModel.EMS.charts.chartProdConst.powerPV.actualPVPower'),
                  scaling: {
                    min: 0,
                    max: typeof this.pvScaling === 'number' ? this.pvScaling * 2 : null,
                  },
                  seriesType: 'Calculation',
                  type: 'line',
                  unit: 'kW',
                },
                {
                  calculation: this.pvCalculatedPower,
                  name: this.$t('mlModel.EMS.charts.chartProdConst.powerPV.calculatedPVPower'),
                  scaling: {
                    min: 0,
                    max: typeof this.pvScaling === 'number' ? this.pvScaling * 2 : null,
                  },
                  seriesType: 'Calculation',
                  type: 'line',
                  unit: 'kW',
                },
              ],
              selectedStackingOptions: 'normal',
              selectedWidth: 'full',
              type: 'chart',
            },
          },
          navigationItemsToExclude: ['live', 'hour'],
        },
      },
    );
    const items = [
      ...chartOptions,
      {
        title: this.$t('mlModel.EMS.charts.chartProdConst.powerConsumption.title'),
        id: 'consumptionPower',
        component: 'BaseChartWrapper',
        data: {
          chartTitle: 'Power Consumption',
          chartWidth: null,
          chartData: {
            name: '',
            data: {
              chartOptions: [
                {
                  calculation: this.consumptionActualPower,
                  name: this.$t('mlModel.EMS.charts.chartProdConst.powerConsumption.actualPower'),
                  scaling: {
                    min: typeof this.consumptionScaling === 'number' ? 0 - (this.consumptionScaling * 2) : null,
                    max: typeof this.consumptionScaling === 'number' ? this.consumptionScaling * 2 : null,
                  },
                  seriesType: 'Calculation',
                  type: 'line',
                  unit: 'kW',
                },
                {
                  agg: 'avg',
                  name: this.$t('mlModel.EMS.charts.chartProdConst.powerConsumption.calculatedPower'),
                  scaling: {
                    min: typeof this.consumptionScaling === 'number' ? 0 - (this.consumptionScaling * 2) : null,
                    max: typeof this.consumptionScaling === 'number' ? this.consumptionScaling * 2 : null,
                  },
                  seriesType: 'View',
                  type: 'line',
                  unit: 'kW',
                  var: this.consumptionCalculatedPower,
                },
              ],
              selectedStackingOptions: 'normal',
              selectedWidth: 'full',
              type: 'chart',
            },
          },
          navigationItemsToExclude: ['live', 'hour'],
        },
      },
    ];
    return items;
  }

  /**
   * Define parameters for Autarkiegrad request.
   * @return {string} params for request. Example: grid=1&grid=2
   */
  get paramsForAutarkiegrad() {
    const systems = ['grid', 'battery', 'pv'];
    const filteredByNeededSystems: any = Object.entries(this.getSystems(this.deviceData))
      .filter((group: any) => systems.some((el: any) => el === group[0]));
    const allVariables: any = filteredByNeededSystems
      .map((system: any) => Object.values(system[1].components).map((el: any) => `${system[0]}=${el.power}`));
    const allVarsFlat: any = allVariables.reduce((acc: any, el: any) => acc.concat(el), []);
    return allVarsFlat.length ? allVarsFlat.join('&') : null;
  }
}
