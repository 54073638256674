
import { Vue, Component } from 'vue-property-decorator';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import SliderMock from '@/ui/components/devices/previews/components/SliderMock.vue';
import PushButtonMock from '@/ui/components/devices/previews/components/PushButtonMock.vue';
import ArrowButtonMock from '@/ui/components/devices/previews/components/ArrowButtonMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    InputFieldMock,
    SliderMock,
    PushButtonMock,
    ArrowButtonMock,
    DeviceLayout,
  },
})
export default class ControlAwningPreview extends Vue {
  get settingsViewLang() {
    return 'devices.ControlAwning.settingsView';
  }
  get previewNameLang() {
    return this.$t('devices.ControlAwning.previewName');
  }
}
