
import { Component } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import EnergyVisualisationPreview
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyVisualisationPreview.vue';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';
import {
  availableExternalMeasurementDeyeTypes,
  availableExternalMeasurementTypes,
  BatteryType,
  deyeTypes,
  electricHeatingTypes,
  getChargeStationPower,
  getChargeStationTypeInformationBySystemType,
  getRawValueBySystemTypeElectricHeating,
  webastoChargeStationTypes,
} from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { getSelectedSystem } from '@/utils/installationWizardUtilsFunctions';
import _, { cloneDeep } from 'lodash';
import BatteryComponentsSettings
  from '@/ui/components/wizards/installationWizard/steps/defineComponents/singleSystems/BatteryComponentSettings.vue';
import ElectricHeatingSettings
  from '@/ui/components/wizards/installationWizard/steps/defineComponents/singleSystems/ElectricHeatingSettings.vue';
import ChargeStationComponentsSettings
  from '@/ui/components/wizards/installationWizard/steps/defineComponents/singleSystems/ChargeStationComponentSettings.vue';
import { ChargeStationType, IProject } from '@/types/project.types';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { convertBitToArray, convertDecimalNumberToBinary } from '@/utils/utilsFunctions';
import {
  shouldSendVariableForType,
} from '@/ui/components/wizards/installationWizard/wizardSettings/mqttVariables';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { IDevice } from '@/types/devices.types';
import SystemComponent
  from '@/ui/components/wizards/installationWizard/steps/defineComponents/components/SystemComponent.vue';
import {
  DEFINITIONS,
  Definitions,
  IHandleStatusChangeParams,
  IIncludedSystemsBigConsumerDefinition,
  IIncludedSystemsChargeStationDefinition,
  IIncludedSystemsElectricHeatingDefinition,
  IIncludedSystemsHeatingPumpConsumerDefinition,
  IIncludedSystemsHeatingPumpDefinition,
  IIncludedSystemsLoadSheddingDefinition,
  IIncludedSystemsTypes, IMQTTVariable,
  NavigationDirection,
  WizardPath,
} from '@/types/wizards/installationWizard.types';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import {
  emsLimitsByType,
} from '@/ui/components/wizards/installationWizard/wizardSettings/wizardLimits';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';
import {
  chargingStationV2Feature,
  hybridVersionDate,
  legionellaProtectionDate, mennekesEnerchargeDate,
  newChargeStationLimitDate,
  plcVersionDate,
  tenantUpdateWagoCounters,
} from '@/utils/versionManagementUtils';

import InverterComponentSettings
  from '@/ui/components/wizards/installationWizard/steps/defineComponents/singleSystems/InverterComponentSettings.vue';
import StringInverterComponentSettings
  from '@/ui/components/wizards/installationWizard/steps/defineComponents/singleSystems/StringInverterComponentSettings.vue';
import {
  CHECKS_DISABLED,
} from '@/ui/components/wizards/installationWizard/wizardSettings/developmentConfig';
import DeyeConsumerComponentSettings from './singleSystems/DeyeConsumerComponentSettings.vue';
import DeyeProducerComponentSettings from './singleSystems/DeyeProducerComponentSettings.vue';
import installationWizardVariables from '../../installationWizardVariables';
import {
  chargeStationMapTypes,
  defineSystemMap,
  heatingPumpMapTypes,
  IWizardComponentMap,
} from '../../../loggerWizard/wizardSettings/systemTypes';

@Component({
  computed: {
    WizardPath() {
      return WizardPath;
    },
    deyeTypes() {
      return deyeTypes;
    },
  },
  components: {
    StringInverterComponentSettings,
    InverterComponentSettings,
    BaseChartWrapper,
    ModalWindow,
    SystemComponent,
    CircleSpinner,
    InfoTooltip,
    EnergyVisualisationPreview,
    WizardContentView,
    BatteryComponentsSettings,
    ElectricHeatingSettings,
    ChargeStationComponentsSettings,
    DeyeConsumerComponentSettings,
    DeyeProducerComponentSettings,
  },
})
export default class ComponentsPage extends WizardComponent {
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>
  @State('installationWizard') wizardState!: IInstallationWizardState;
  @Mutation('installationWizard/setEMSSystemCount') setEMSSystemCount!: ({
    system,
    count,
  }: any) => void;
  @Mutation('installationWizard/setEnergyViewSystemCount') setEnergyViewSystemCount!: ({
    system,
    count,
  }: any) => void;
  @Mutation('installationWizard/handleIncludedSystemsTypesSystem') handleIncludedSystemsTypesSystem!: (
    { system, key, data }: { system: keyof IIncludedSystemsTypes; key: string; data: any }
  ) => void;
  @Mutation('installationWizard/removeExternallyOccupiedPilotLine') removeExternallyOccupiedPilotLine!: (data: number) => void;
  @Mutation('installationWizard/setExternallyOccupiedPilotLine') setExternallyUsedPilotLine!: (data: number) => void;
  @Getter('installationWizard/emsDevice') emsDevice!: IDevice;
  @Getter('projects/project') project!: IProject;
  @Getter('installationWizard/producerOptionsEnabled') producerOptionsEnabled!: boolean;
  @Getter('installationWizard/consumerOptionsEnabled') consumerOptionsEnabled!: boolean;
  @Getter('installationWizard/pilotSystemCount') pilotSystemCount!: number;
  @Getter('installationWizard/externalVisualisation') externalVisualisation!: {
    isSelected: boolean;
    count: number;
    definition: [];
  };
  @Getter('installationWizard/emsLimits') emsLimits!: Record<string, number>;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('installationWizard/isHybrid') isHybrid!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('devices/allDevices') allDevices!: IDevice[];
  @Getter('installationWizard/connectedLines') connectedLines!: number[];
  @Getter('installationWizard/wasComponentsPageDone') wasComponentsPageDone!: boolean;
  @Getter('installationWizard/getDisablePilotDevices') getDisablePilotDevices!: boolean;
  @Mutation('installationWizard/setPilotSystemCount') setPilotSystemCount!: (payload: number) => void;
  @Mutation('installationWizard/setExternalVisualisation') setExternalVisualisation!: (payload: any) => void;
  @Mutation('installationWizard/setIsPilotPageNotDone') setIsPilotPageNotDone!: () => void;
  @Mutation('installationWizard/setIsPilotPageDone') setIsPilotPageDone!: (payload: {}) => void;
  @Mutation('installationWizard/setWasComponentsPageDone') setWasComponentsPageDone!: () => void;
  @Getter('installationWizard/navigationDirection') navigationDirection!: NavigationDirection;

  // Confirmation that is needed for Weco Systems
  confirmed = false;
  doesHover = false;
  valid = false;

  // process started for charge station and heating pump
  chargeStationProcessWasStarted = false;
  heatingPumpProcessWasStarted = false;
  intervalCS: any = null;
  intervalHP: any = null;

  rerenderKey = 0;
  // is currently a fixed value because there can only be one pilot system
  emptyPilotLines = 4;
  systems: any = {};
  // variables are needed to use those types inside template
  localExternalVisualisaton: any = {
    isSelected: false,
    count: 0,
  };
  validationStatusSingleSystems = {
    battery: false,
    charge_station: true,
    electric_heating: true,
    deyeProducer: true,
    deyeConsumer: true,
  }
  chargeStationTypeCurrentIndex = {
    webasto: 0,
    weidmüller: 2,
  };
  connectedLinesConfig: any = {
    1: { device: { text: '', value: '' }, name: '', disabled: false },
    2: { device: { text: '', value: '' }, name: '', disabled: false },
    3: { device: { text: '', value: '' }, name: '', disabled: false },
    4: { device: { text: '', value: '' }, name: '', disabled: false },
  };
  bitBySystem: { [key: string]: { [key: string]: boolean } } = {
    'charge_station': {
      setWebastoNext: false,
      setWebastoUnite: false,
      setWeidmüller: false,
      setSchneider: false,
    },
    'heating_pump': {
      setLambda: false,
      setSoltop: false,
    },
  };

  dataLoaded = false;

  /**
   * returns the ems device based on the selected systems inside the wizard
   * for pv systems it will also add the ac pv system if its selected and add it to the pv count
   */
  get emsDeviceForPreview() {
    const deviceObject = cloneDeep(this.emsDevice);
    const isAcPV = this.wizardState.ACPV.isSelected;
    // if ac pv is selected we add one pv to show correct count in preview
    deviceObject.data.meta.controllerMappings.pv.count = isAcPV ?
      deviceObject.data.meta.controllerMappings.pv.count + 1 :
      deviceObject.data.meta.controllerMappings.pv.count;

    if (this.isHybridVersion) {
      const { stringInverterCount } = this.wizardState;
      // add string inverters to preview if some are selected
      deviceObject.data.meta.controllerMappings.pv.count = this.includedSystemsTypes.pv.count + stringInverterCount;

      if (isAcPV) {
        deviceObject.data.meta.controllerMappings.pv.count += 1; // add 1 if ac pv is selected
      }
      const amountOfInverters = this.wizardState.inverterCount;
      // if we have multiple inverters we need to multiply the amount of batteries to show the correct amount in preview
      if (this.wizardState.isHybrid && amountOfInverters > 1) {
        const batteryIncludesSystems = this.includedSystemsTypes.battery;
        deviceObject.data.meta.controllerMappings.battery.count = batteryIncludesSystems.count * amountOfInverters;
      }
    }

    // if project meta contains logger information we add pv to show the correct count in preview
    if (this.project.meta?.loggerInformation) {
      deviceObject.data.meta.controllerMappings.pv.count += this.project.meta.loggerInformation.length;
    }
    return deviceObject;
  }

  get includedSystemsTypes() {
    return this.wizardState.includedSystemsTypes;
  }

  get projectBatteryType() {
    // contains type of system that's selected inside the project settings
    const { batterySystemType } = this.project?.meta?.controller;
    return batterySystemType === undefined ? '' : batterySystemType;
  }

  get showChargeStationV2Feature() {
    return plcVersionDate(this.project).getTime() > chargingStationV2Feature.getTime();
  }

  get hasLynusInverter() {
    return this.project.meta.hasLynusInverter ?? true;
  }

  get hasOnlyStringInverter() {
    return (this.wizardState.wizardPath === WizardPath.STRING_INVERTER) || (this.wizardState.wizardPath === WizardPath.TENANT_STRING_INVERTER);
  }

  handleRerender() {
    this.rerenderKey++;
  }

  countDummies(system: string) {
    return Object.values(this.emsDevice.data.meta.controllerMappings[system].components).filter((element: any) => element.external_energy_measurement && element.error === undefined).length;
  }

  countComponentsFromTenantWizard(system: string) {
    return Object.values(this.emsDevice.data.meta.controllerMappings[system].components).filter((element: any) => element.power.includes('prgEM.lrPower_E_')).length;
  }

  filterDummies(system: string) {
    const definitionArray = this.includedSystemsTypes[system as keyof IIncludedSystemsTypes].definition;
    return definitionArray.filter((element: any) => !element.external_energy_measurement);
  }

  handleInverterQuantityChange(event: any, stringInverter: boolean) {
    if (stringInverter) {
      this.wizardState.stringInverterCount = event;
    } else {
      this.wizardState.inverterCount = event;
    }
    this.wizardState.serialNumberCheckDone = false;
  }

  handleStatusChange(value: IHandleStatusChangeParams) {
    this.rerenderKey++;
    this.validationStatusSingleSystems[value.system] = value.status;
    this.validate();
  }

  filterConnectWithLine(systemType: any[]): any[] {
    return systemType.map((element: any) => {
      return typeof element.connectWithLine !== 'string' ? element.connectWithLine : undefined;
    }).filter(element => element !== undefined);
  }

  // returns count of how many pilot lines are allready occupied
  get countHasLineConnected() {
    if (this.wizardState !== undefined) {
      const chargeStationArray = this.includedSystemsTypes.charge_station.definition.filter((def: any) => {
        const info = getChargeStationTypeInformationBySystemType(def.systemType);
        return def?.connectWithLine !== '' && info !== undefined && info?.isExternal === true && def?.connectWithLine !== 'noLine';
      });
      const heatingPumpArray: any[] = this.filterConnectWithLine(this.includedSystemsTypes.heating_pump.definition);
      const bigConsumerArray: any[] = this.filterConnectWithLine(this.includedSystemsTypes.big_consumer.definition);
      const array = [heatingPumpArray, chargeStationArray, bigConsumerArray].flat();
      return array.length;
    } else {
      return 0;
    }
  }

  get externalVisualisatonLimit() {
    return {
      min: 0,
      max: this.emptyPilotLines - this.countHasLineConnected,
    };
  }

  /**
   * Handles checkbox click events for pilot checkbox only
   */
  handleCheckboxPilot(event: boolean) {
    if (event) {
      this.localExternalVisualisaton.count = 1;
      this.localExternalVisualisaton.isSelected = true;
    } else {
      this.removeExternalVisualisation(4);
      this.localExternalVisualisaton.count = 0;
      this.localExternalVisualisaton.isSelected = false;
    }
  }

  handleAcpvChange(event: boolean) {
    if (event) {
      this.wizardState.acpvOnLoadAvailable = false;
    }
  }

  handleAcpvOnLoadChange(event: boolean) {
    if (event) {
      this.wizardState.acpvAvailable = false;
    }
  }

  /**
   * Handles quantity change for pilot system
   */
  handleSelectPilot(newCount: number) {
    const oldCount = this.localExternalVisualisaton.count;
    this.localExternalVisualisaton.count = newCount;
    this.localExternalVisualisaton.isSelected = !!newCount;
    this.updatePilotCount(oldCount);
  }

  updatePilotCount(oldCount: number) {
    if (oldCount > this.localExternalVisualisaton.count) {
      const difference = oldCount - this.localExternalVisualisaton.count;
      this.removeExternalVisualisation(difference);
    }
    this.setExternalVisualisation(this.localExternalVisualisaton);
  }

  removeExternalVisualisation(difference: number) {
    for (let i = 0; i < difference; i++) {
      const lastItem = Object.entries(this.connectedLinesConfig).filter((element: any) => element[1].name !== '' && !element[1].disabled).pop();
      if (!lastItem) return;
      this.connectedLinesConfig[lastItem[0]] = {
        device: { text: '', value: '' },
        name: '',
        disabled: false,
      };
      this.removeExternallyOccupiedPilotLine(parseInt(lastItem[0], 10));
    }
  }

  setVariablesToSend(variableName: string, value: number | string = 0, systemIndex = 0) {
    this.variablesToSend.set(variableName + systemIndex, installationWizardVariables(variableName, 'componentsPage', value, systemIndex));
  }

  getFaqLink(batteryType: string) {
    if (batteryType === deyeTypes.WecoBatteryStack) {
      return 'https://lynus.zammad.com/help/de-de/163-weco-batterien-stack';
    }
    return 'https://lynus.zammad.com/help/de-de/164-weco-batterie-slim';
  }

  async checkConfiguration(processStarted: boolean, processVariableNotOk: string) {
    if (this.project.id) {
      await this.fetchMeasurements(this.project.id);
    }
    const processSucceded = this.measurements[processVariableNotOk] === 0;
    return processSucceded && processStarted;
  }

  noSystemMapped(systemType: string) {
    return this.includedSystemsTypes[systemType as keyof IIncludedSystemsTypes].definition.length === 0;
  }

  allBitsIdentical(systemType: string) {
    return Object.values(this.bitBySystem[systemType]).every((element: boolean) => element);
  }

  // After everything has been sent we check if configuration has been successful
  async checkProcessStatus() {
    if (CHECKS_DISABLED) return true;
    if (this.allBitsIdentical('charge_station')) this.chargeStationProcessWasStarted = true;
    if (this.allBitsIdentical('heating_pump')) this.heatingPumpProcessWasStarted = true;

    const chargeStationProcessSucceeded = await this.checkConfiguration(this.chargeStationProcessWasStarted, 'prgCS.bConfigIsNotOK');
    const heatingPumpProcessSucceeded = await this.checkConfiguration(this.heatingPumpProcessWasStarted, 'prgHP.bConfigIsNotOK');
    if ((chargeStationProcessSucceeded || this.noSystemMapped('charge_station')) && (heatingPumpProcessSucceeded || this.noSystemMapped('heating_pump'))) {
      return true;
    }

    // clear intervals
    clearInterval(this.intervalCS);
    clearInterval(this.intervalHP);

    // Define what system failed or if both failed
    if ((!chargeStationProcessSucceeded && !this.noSystemMapped('charge_station')) && (!heatingPumpProcessSucceeded && !this.noSystemMapped('heating_pump'))) {
      this.processFailedMessage = this.$t('installationWizard.configurationFailedMessage').toString();
    } else if (!chargeStationProcessSucceeded) {
      this.processFailedMessage = this.$t('installationWizard.configurationFailedMessageChargeStation').toString();
    } else {
      this.processFailedMessage = this.$t('installationWizard.configurationFailedMessageHeatingPump').toString();
    }
    return false;
  }

  // function that checks in a one second interval if the process has been started
  checkIfProcessGetsStarted() {
    // reset variables when prepareVariablesToSend is called again
    this.chargeStationProcessWasStarted = false;
    this.heatingPumpProcessWasStarted = false;

    // Separate intervals for each process
    if (this.includedSystems.charge_station.definition.length) {
      this.intervalCS = setInterval(async () => {
        if (this.project.id) {
          await this.fetchMeasurements(this.project.id);
        }
        if (this.measurements['prgCS.bConfigIsInProgress'] === 1) this.chargeStationProcessWasStarted = true;
        if (this.chargeStationProcessWasStarted) {
          clearInterval(this.intervalCS);
        }
      }, 1000);
    }
    if (this.includedSystems.heating_pump.definition.length) {
      this.intervalHP = setInterval(async () => {
        if (this.project.id) {
          await this.fetchMeasurements(this.project.id);
        }
        if (this.measurements['prgHP.bConfigIsInProgress'] === 1) this.heatingPumpProcessWasStarted = true;
        if (this.heatingPumpProcessWasStarted) {
          clearInterval(this.intervalHP);
        }
      }, 1000);
    }
  }

  async prepareStringInverterOnlyVariablesToSend() {
    this.prepareStringInverterVariables();
    this.setVariablesToSend('enableStringInverter', this.wizardState.stringInverterCount > 0 ? 1 : 0);

    this.disableAllSystemsStringInverterOnly();

    // Last variable that loads data for some seconds
    const lastVariable = installationWizardVariables('lastVariable', 'componentsPage');
    this.variablesToSend.set('lastVaribale0', lastVariable);
  }

  async prepareVariablesToSend() {
    // disable EMS
    // this is needed so ems will detect that systems have been added or removed
    this.setVariablesToSend('disableEMS');

    if (this.hasOnlyStringInverter) {
      await this.prepareStringInverterOnlyVariablesToSend();
      return;
    }

    if (!this.isSolarmax) this.prepareBatteryVariables();
    this.prepareElectricHeatingVariables();
    if (this.isNewChargeStationPlcVersion) this.checkIfProcessGetsStarted();
    this.prepareChargeStationVariables();
    if (this.isNewChargeStationPlcVersion) this.prepareHeatingPumpVariables();
    if (this.isDeye || this.isSolarmax) {
      this.prepareHeatingPumpConsumerVariables();
      this.prepareBigConsumerVariables();
      this.prepareLoadSheddingVariables();
    }
    if (this.isDeye || this.isSolarmax) {
      this.prepareProducerVariables();
    }
    const localProject: IProject = this.prepareAdditionalEnergyVariables();

    await this.updateProject(localProject);
  }

  prepareStringInverterVariables() {
    this.setVariablesToSend('stringInverterCount', this.wizardState.stringInverterCount);
  }

  prepareBatteryVariables() {
    const { inverterPower } = this.includedSystemsTypes.battery.definition.length === 0 ? { inverterPower: '0 kW' } : this.includedSystemsTypes.battery.definition[0];
    const power = parseFloat(inverterPower.replace(/[^\d.]+/g, ''));
    if (shouldSendVariableForType(this.projectBatteryType as BatteryType, 'prgEnergy.fbI.rMaxPowerInverter') || (this.isDeye && power === 0)) {
      this.setVariablesToSend('maxPowerInverter', power * 1000);
    }

    if (!this.isDeye && !this.isHybrid && !this.isSolarmax || !this.isHybridVersion) {
      // for azzurro systems we can only have one battery so we only need to set the capacity once
      // also if the date is too old we can also only set one battery
      const batteryCapacity = this.includedSystemsTypes.battery.definition.length === 0 ? '0' : this.includedSystemsTypes.battery.definition[0].batteryCapacity.replace(/[^\d.]+/g, '');
      this.setVariablesToSend('batteryCapacity', batteryCapacity, 0);
      return;
    }
    const batteryCapacity = this.includedSystemsTypes.battery.definition.length === 0 ? 0 : this.includedSystemsTypes.battery.definition[0].batteryCapacity.replace(/[^\d.]+/g, '');
    // for the amount of batteries selected multiplied by the amount of inverters selected use the value of the first battery to set the capacity of the batteries
    for (let i = 0; i < this.wizardState.inverterCount * this.includedSystemsTypes.battery.definition.length; i++) {
      this.setVariablesToSend('batteryCapacity', batteryCapacity, i);
    }

    // and disable the rest, max index is the amount of batteries * inverters
    for (let i = this.wizardState.inverterCount * this.includedSystemsTypes.battery.definition.length; i < 10; i++) {
      this.setVariablesToSend('batteryCapacity', '0', i);
    }

    if (!this.isDeye) {
      this.setVariablesToSend('batteryInput', 0, 0);
      this.setVariablesToSend('oneBatteryInput', 0, 0);
    }
  }

  prepareElectricHeatingVariables() {
    const maximumElectricHeatingIndices = Array.from(
      {
        length:
          emsLimitsByType(this.project.meta.controller.batterySystemType, this.project).electric_heating || 4,
      },
      (_, i) => i + 1,
    );

    // electric heating part
    this.includedSystemsTypes.electric_heating.definition.forEach((value: IIncludedSystemsElectricHeatingDefinition, index: number) => {
      this.setVariablesToSend('electricHeatingPower', getRawValueBySystemTypeElectricHeating(value.systemType), index);
      // Splice the array to get only non-selected systems
      maximumElectricHeatingIndices.splice(maximumElectricHeatingIndices.indexOf(index + 1), 1);
    });

    // disable all electric heating elements that are not used
    maximumElectricHeatingIndices.forEach((index) => {
      const variableToSend = `prgEHE.fbEHE${index}.bEnable`;
      this.variablesToSend.set(variableToSend, {
        variable: variableToSend,
        value: 0,
        feedbackVariable: `prgEHE.fbEHE${index}.stDataEHE.bEnabled`,
        isBoolean: true,
      });
      const { created_at }: any = this.project!.created_at;
      if ((this.isDeye || this.isSolarmax) && (new Date(created_at).getTime() > legionellaProtectionDate.getTime())) {
        this.setVariablesToSend('disableLegionellaProtection', 0, index);
      }
    });
  }

  prepareOutdatedChargeStationVariables() {
    this.chargeStationTypeCurrentIndex = {
      webasto: 0,
      weidmüller: 2,
    };
    const maximumChargeStationIndices = Array.from({ length: emsLimitsByType(this.project.meta.controller.batterySystemType, this.project).charge_station || 4 }, (_, i) => i + 1);
    // charge station part
    this.includedSystemsTypes.charge_station.definition.forEach((chargeStation: IIncludedSystemsChargeStationDefinition, index: number) => {
      const chargeStationPower = getChargeStationPower(chargeStation.systemType);
      const chargeStationInformations = getChargeStationTypeInformationBySystemType(chargeStation.systemType);
      let indexCurrentChargeStation = 0;

      switch (chargeStationInformations.type) {
        case 'Webasto': {
          indexCurrentChargeStation = this.chargeStationTypeCurrentIndex.webasto + 1;
          this.setVariablesToSend('chargeStationPower', chargeStationPower, indexCurrentChargeStation);
          this.chargeStationTypeCurrentIndex.webasto++;

          if (this.showChargeStationV2Feature) {
            // send webastoType
            this.setVariablesToSend('webastoType', chargeStation.webastoType, index);
          }
          break;
        }
        case 'Weidmüller': {
          indexCurrentChargeStation = this.chargeStationTypeCurrentIndex.weidmüller + 1;
          this.setVariablesToSend('chargeStationPower', chargeStationPower, indexCurrentChargeStation);
          this.chargeStationTypeCurrentIndex.weidmüller++;

          if (this.showChargeStationV2Feature) {
            // set webastoType to default value
            this.setVariablesToSend('webastoType', 0, index);
          }
          break;
        }
      }
      // Splice the array to get only non-selected systems
      maximumChargeStationIndices.splice(maximumChargeStationIndices.indexOf(indexCurrentChargeStation), 1);

      // only send those states for the first devices because dummy charge stations can not be controlled
      if (index < this.emsLimits.charge_station) {
        if (!chargeStation.systemType.includes('Enercharge')) this.setVariablesToSend('useNotFreeCharge', chargeStation.noFreeLoading ? 1 : 0, indexCurrentChargeStation);
      }
    });

    // Disable all not used charge stations
    maximumChargeStationIndices.forEach((ind: number) => {
      const variableToSend = `prgCS.fbCS${ind}.bEnable`;
      this.variablesToSend.set(variableToSend, {
        variable: variableToSend,
        value: 0,
        feedbackVariable: `prgCS.fbCS${ind}.stDataECS.bEnabled`,
        isBoolean: true,
      });
    });
  }

  configureSystemVariables(systemMap: IWizardComponentMap, system: string) {
    const activeIndices: number[] = [];
    const paramsToSendToChargeStation: any[] = [];
    const activeDoubleChargePointIndices: number[] = [];
    Object.entries(systemMap).forEach(([key, value]) => {
      const activeSystemSpecificIndices: number[] = [];
      const isMennekesDoubleChargePoint = ['Amedio', 'AmtronTC'].includes(key);
      value.forEach((element: number | number[], index: number) => {
        const ind = system === 'heating_pump' ? index + 1 : index;
        if (element) {
          if (isMennekesDoubleChargePoint) activeDoubleChargePointIndices.push(index + 1);
          else activeSystemSpecificIndices.push(index + 1);
          activeIndices.push(ind);
        } else return;
        const component = this.includedSystemsTypes[system as keyof IIncludedSystemsTypes].definition[index];
        if (system === 'charge_station') {
          paramsToSendToChargeStation.push({ index: ind, element, component });
        }
      });

      const calculatedBit = activeSystemSpecificIndices.length === 0 ? '0' : this.convertArrayToBit(activeSystemSpecificIndices);

      if (!isMennekesDoubleChargePoint) {
        const bitAsDecimal = parseInt(calculatedBit, 2);
        const isMennekessChargeStation = key === 'Amedio' || key === 'AmtronTC' || key === 'Amtron';
        this.checkBitIdentical(system, key, bitAsDecimal, isMennekessChargeStation);
        this.setSystemActive(key, bitAsDecimal);
      }
    });

    if (this.isMennekesEnerchargeVersion && system === 'charge_station') {
      const calculatedBit = activeDoubleChargePointIndices.length === 0 ? '0' : this.convertArrayToBit(activeDoubleChargePointIndices);
      const bitAsDecimal = parseInt(calculatedBit, 2);
      this.checkBitIdentical(system, 'Amedio', bitAsDecimal, true);
      this.setSystemActive('Amedio', bitAsDecimal);
    }

    paramsToSendToChargeStation.forEach((element) => {
      this.sendChargeStationVariables(element.index, element.element, element.component.noFreeLoading);
    });

    // disable inactive indices
    const typeLimit = emsLimitsByType(this.project.meta.controller.batterySystemType, this.project)[system];
    for (let i = activeIndices.length; i < typeLimit; i++) {
      const enableVariable = system === 'heating_pump' ? 'heatingPumpEnable' : 'chargeStationEnable';
      const ind = system === 'heating_pump' ? i + 1 : i;
      this.setVariablesToSend(enableVariable, 0, ind);
    }
  }

  async checkBitIdentical(system: string, key: string, bit: number, isMennekessChargeStation: boolean) {
    const systemKey = key.split(' ')[0];
    let variable: IMQTTVariable;
    if (!isMennekessChargeStation) {
      variable = installationWizardVariables(`set${systemKey}`, 'componentsPage', bit);
    } else {
      if (systemKey === 'Amedio' || systemKey === 'AmtronTC') {
        variable = installationWizardVariables('setMennekesDoubleChargePoint', 'componentsPage', bit);
        const currentBit = this.measurements[variable.variable];
        if (currentBit === bit) {
          this.bitBySystem[system].setAmtronTC = true;
          this.bitBySystem[system].setAmedio = true;
        }
        return;
      } else {
        const variableSingleChargePoint = installationWizardVariables('setMennekesSingleChargePoint', 'componentsPage', bit);
        const currentBitSingleChargePoint = this.measurements[variableSingleChargePoint.variable];
        if (currentBitSingleChargePoint === bit) this.bitBySystem[system].setAmtron = true;
        return;
      }
    }
    const currentBit = this.measurements[variable.variable];
    if (currentBit === bit) this.bitBySystem[system][`set${systemKey}`] = true;
  }

  setSystemActive(systemType: string, bit: number) {
    let system = systemType.split(' ')[0];
    const isMennekesDoubleChargePoint = ['Amedio', 'AmtronTC'].includes(system);
    const isMennekesSingleChargePoint = system === 'Amtron';
    if (isMennekesDoubleChargePoint) system = 'MennekesDoubleChargePoint';
    if (isMennekesSingleChargePoint) system = 'MennekesSingleChargePoint';
    this.setVariablesToSend(`set${system}`, bit);
  }

  convertArrayToBit(array: number[]) {
    // Find the maximum number in the array to determine the size of the bit string
    const maxNum = Math.max(...array);
    // Create a bit string filled with 0s of length maxNum
    const bitString = Array(maxNum).fill('0');

    // Iterate through the array and set the corresponding bit position to 1
    array.forEach(num => {
      bitString[maxNum - num] = '1';
    });

    // Join the bit string array back into a single string
    return bitString.join('');
  }

  sendChargeStationVariables(systemIndex: number, power: number, freeCharge: boolean) {
    if (!this.includedSystemsTypes.charge_station.definition[systemIndex].systemType.includes('Enercharge')) {
      this.setVariablesToSend('chargeStationFreeCharge', freeCharge ? 1 : 0, systemIndex);
    }
    this.setVariablesToSend('chargeStationMaxPower', power, systemIndex);
  }

  prepareNewChargeStationVariables() {
    const chargeStationMap = defineSystemMap(chargeStationMapTypes, this.emsLimits.charge_station);
    let lastChargeStation: IIncludedSystemsChargeStationDefinition = this.includedSystemsTypes.charge_station.definition[0];
    this.includedSystemsTypes.charge_station.definition.forEach((chargeStation: IIncludedSystemsChargeStationDefinition, index: number) => {
      const currentChargeStation = chargeStation.occupiedByTwinPoint ? lastChargeStation : chargeStation;
      const chargeStationPower = getChargeStationPower(currentChargeStation.systemType);
      lastChargeStation = chargeStation;
      const chargeStationInformations = getChargeStationTypeInformationBySystemType(currentChargeStation.systemType);
      if (chargeStationInformations.type === 'Webasto') {
        const webastoType = webastoChargeStationTypes.find((element) => element.value === currentChargeStation.webastoType)?.name;
        const key = `${chargeStationInformations.type}${webastoType}`;
        chargeStationMap[key][index] = chargeStationPower;
      } else {
        chargeStationMap[chargeStationInformations.type][index] = chargeStationPower;
      }
    });
    this.configureSystemVariables(chargeStationMap, 'charge_station');
  }

  prepareChargeStationVariables() {
    if (this.isNewChargeStationPlcVersion) {
      this.prepareNewChargeStationVariables();
      return;
    }
    this.prepareOutdatedChargeStationVariables();
  }

  prepareHeatingPumpVariables() {
    // heating pump part
    const heatingPumpMap = defineSystemMap(heatingPumpMapTypes, this.emsLimits.heating_pump);
    this.includedSystemsTypes.heating_pump.definition.forEach((heatingPump: IIncludedSystemsHeatingPumpDefinition, index: number) => {
      heatingPumpMap[heatingPump.systemType][index] = [1, 1];
    });
    this.configureSystemVariables(heatingPumpMap, 'heating_pump');
  }

  prepareHeatingPumpConsumerVariables() {
    // heating pump part
    (this.includedSystems.heating_pump_consumer.definition.filter((value: IIncludedSystemsHeatingPumpConsumerDefinition) => !!value.powerSGR1)).forEach((value: IIncludedSystemsHeatingPumpConsumerDefinition, index: number) => {
      this.setVariablesToSend('heatingPump', value.powerSGR1, 0);
      this.setVariablesToSend('heatingPump', value.powerSGR2, 1);
    });
  }

  prepareBigConsumerVariables() {
    // big consumer part
    this.includedSystems.big_consumer.definition.forEach((value: IIncludedSystemsBigConsumerDefinition, index: number) => {
      this.setVariablesToSend('bigConsumerPower', value.power, index);
    });
  }

  prepareLoadSheddingVariables() {
    if (!this.consumerOptionsEnabled) {
      // if load shedding is enabled we need to manualy disable all big consumer and heating pump devices
      this.disableNotSelectedSystems();
      return;
    }
    // load shedding part
    this.includedSystems.load_shedding.definition.forEach((value: IIncludedSystemsLoadSheddingDefinition) => {
      this.setVariablesToSend('loadShedding', value.maxBatteryPower);
      this.setVariablesToSend('maxPowerDrop', value.powerConsumer1, 1);
      this.setVariablesToSend('maxPowerDrop', value.powerConsumer2, 2);

      // if load shedding is enabled we need to manualy disable all big consumer and heating pump devices
      this.disableNotSelectedSystems();
    });
  }

  disableNotSelectedSystems() {
    // if load shedding is enabled we need to manualy disable all big consumer and heating pump devices
    this.setVariablesToSend('disableBigConsumer', 0, 1);
    this.setVariablesToSend('disableBigConsumer', 0, 2);
    this.setVariablesToSend('disableHeatingPump');
  }

  prepareProducerVariables() {
    if (this.isSolarmax) {
      this.setVariablesToSend('isACPV', this.producerOptionsEnabled ? 1 : 0);
      return;
    }
    // generator part
    if (!this.producerOptionsEnabled) {
      this.setVariablesToSend('useAsGenerator');
      return;
    }
    if (this.includedSystems.generator.count > 0) {
      this.setVariablesToSend('powerOfGenerator', this.includedSystems.generator.definition[0].power);
      this.setVariablesToSend('useAsGenerator');
    } else if (this.includedSystems.chp.count > 0) {
      // CHP Part
      this.setVariablesToSend('numberOfBHKW', this.includedSystems.chp.definition.length);
      this.setVariablesToSend('allBHKWOnOneBattery', (this.includedSystems.chp.definition.length > this.wizardState.inverterCount) ? 1 : 0);
      this.setVariablesToSend('useAsBHKW');
    } else {
      // AC PV part
      this.setVariablesToSend('useAsACPV');
    }
  }

  prepareAdditionalEnergyVariables() {
    if (this.isSolarmax || (this.isDeye && this.isHybridVersion)) this.setVariablesToSend('numberOfInverters', this.wizardState.inverterCount);
    if (this.isDeye && this.isHybridVersion) this.prepareStringInverterVariables();

    if (!this.isSolarmax) {
      // additional energy values
      if (shouldSendVariableForType(this.projectBatteryType as BatteryType, 'prgEnergy.fbI.bPVPowerExt')) {
        this.setVariablesToSend('pvPowerExt');
      }

      this.setVariablesToSend('internalPV');
    }

    this.setVariablesToSend('parallelControl', this.wizardState.inverterCount > 1 ? 1 : 0);

    // Enable Battery
    if (this.hasLynusInverter) {
      this.setVariablesToSend('enableBattery', 1);
    }

    if (this.isDeye && this.isHybridVersion) this.setVariablesToSend('enableStringInverter', this.wizardState.stringInverterCount > 0 ? 1 : 0);

    // Last variable that loads data for some seconds
    const lastVariable = installationWizardVariables('lastVariable', 'componentsPage');
    this.variablesToSend.set('lastVariable0', lastVariable);

    if (!this.wasInstallationWizardCompleted) {
      this.setIsPilotPageNotDone();
    }
    this.setWasComponentsPageDone();
    this.setExternalVisualisation(this.localExternalVisualisaton);
    // Update pilot system count depending on if systems are connected
    this.setPilotSystemCount(this.connectedLines.length > 0 ? 1 : 0);

    // Write Deye Type to Project Meta if Deye is selected
    if (this.projectBatteryType === BatteryType.DEYE) {
      this.project.meta.controller.deyeType = this.includedSystemsTypes.battery.definition.length !== 0 ? this.includedSystems.battery.definition[0].batteryType as deyeTypes ?? null : null;
    }

    // update selected heating pumps in project meta
    const selectedHeatingPumps: string[] = [];
    this.includedSystemsTypes.heating_pump.definition.forEach((value: IIncludedSystemsHeatingPumpDefinition, index: number) => {
      selectedHeatingPumps.push(value.systemType);
    });

    // Save selection for producer and consumer options in project meta
    this.project.meta.wizardSettings = {
      isHybrid: this.wizardState.isHybrid,
      hybridSettings: {
        useBothBatteryInputs: this.wizardState.hybridSettings.useBothBatteryInputs,
      },
      pvPerInverter: this.project.meta.wizardSettings?.pvPerInverter ?? {},
      wizardPath: this.wizardState.wizardPath,
      consumerOptionsEnabled: this.consumerOptionsEnabled,
      producerOptionsEnabled: this.producerOptionsEnabled,
      selectedHeatingPumps,
      chargeStationSettings: this.project.meta.wizardSettings?.chargeStationSettings ?? [],
    };

    return JSON.parse(JSON.stringify(this.project));
  }

  validate() {
    // checks if every single component setting is valid based on the valid status from each component
    this.valid = Object.values(this.validationStatusSingleSystems).every((element: boolean) => element);
  }

  countStringInverters() {
    if (!this.isHybridVersion) {
      // for older versions the string inverter count is not relevant
      return 0;
    }
    // get count previously set for string inverters from measurements else set it to 0
    return this.measurements['prgACPV.byNumberOfInverters'] ?? 0;
  }

  initIncludedSystems() {
    // updates includedSystemsTypes object based on the current state of ems inside the project
    Object.keys(this.includedSystemsTypes).forEach((systemKey: string) => {
      const key = (systemKey as 'pv' | 'battery' | 'charge_station' | 'electric_heating' | 'heating_pump' | 'heating_pump_consumer' | 'load_shedding' | 'chp' | 'generator');
      const heatingPumpComponents = this.emsDevice.data.meta.controllerMappings.heating_pump.components;
      const onlyDummies = Object.values(heatingPumpComponents).every((element: any) => element.external_energy_measurement && element.error === undefined);
      const hasAdditionalHeatingPump = heatingPumpComponents.heating_pump1 && !onlyDummies && Object.values(heatingPumpComponents.heating_pump1.max_power)[0] === 'prgHP.fbHP.stSetupHP.rMaxPower1';
      if (key === 'heating_pump_consumer') {
        const count = hasAdditionalHeatingPump ? 1 : 0;
        const initialDefinitions = JSON.parse(JSON.stringify(
          Array(count).fill({ ...DEFINITIONS[key as keyof Definitions] }),
        ));
        // update included system types with count, isSelected and definitions
        this.selectSystem(count, key as keyof IIncludedSystemsTypes, hasAdditionalHeatingPump, initialDefinitions);
        return;
      }
      const mappedEmsDeviceMappings: any = {
        components: {},
        count: 0,
      };
      if (key === 'heating_pump' && hasAdditionalHeatingPump) {
        Object.entries(this.emsDevice.data.meta.controllerMappings[key].components).forEach(([componentKey, componentValue]) => {
          if ((componentValue as any).power.includes('prgHP.lrPowerHP_')) {
            mappedEmsDeviceMappings.components[componentKey] = { ...(componentValue as any) };
            mappedEmsDeviceMappings.count++;
          }
        });
      }
      const currentStateEMS = (key === 'heating_pump' && hasAdditionalHeatingPump) ? mappedEmsDeviceMappings : this.emsDevice.data.meta.controllerMappings[key];
      if (key === 'load_shedding') {
        return;
      }
      const numberOfDummies = this.countDummies(key);
      // get string inverter count from measurements
      const numberOfStringInverters = systemKey === 'pv' ? this.countStringInverters() : 0;
      const numberOfAcpv = systemKey === 'pv' ? Object.values(this.emsDevice.data.meta.controllerMappings[systemKey].components).filter((element: any) => element.power.includes('lrACPVPower')).length : 0;
      const numberOfFullSystems = Object.values(this.emsDevice.data.meta.controllerMappings[key].components ?? {}).length - numberOfDummies;
      const emsLimit = this.emsLimits[key] * (key === 'pv' ? this.wizardState.inverterCount : 1);
      let countForSystem = 0;
      if (systemKey === 'pv' && this.wasInstallationWizardCompleted && this.isHybridVersion) {
        // calculate remaining pv's that are not string inverters or acpv and set it as count
        const normalPVs = currentStateEMS.count - (this.isDeye ? numberOfStringInverters : 0) - numberOfAcpv;
        countForSystem = normalPVs + numberOfStringInverters + numberOfAcpv;
      } else {
        // default case
        countForSystem = (currentStateEMS.count >= emsLimit ? emsLimit : currentStateEMS.count);
      }

      if (numberOfDummies > 0) {
        if (numberOfFullSystems !== (countForSystem + ((key === 'heating_pump' && hasAdditionalHeatingPump) ? 1 : 0))) {
          countForSystem -= numberOfDummies;
        }
      }
      countForSystem -= numberOfAcpv;
      countForSystem -= numberOfStringInverters;

      // if path does not have a battery we need to remove the full system again after all other pv types have been counted
      if (this.isNoBatteryPath && systemKey === 'pv') {
        countForSystem -= numberOfFullSystems;
      }
      if (systemKey === 'pv' && !this.wasInstallationWizardCompleted && (this.isDeye || this.isSolarmax)) {
        countForSystem = this.systems.pv.max;
      }

      if (this.wasInstallationWizardCompleted && this.hasLynusInverter && plcVersionDate(this.project).getTime() > tenantUpdateWagoCounters.getTime()) {
        // pv can contain additional components from tenant wizard if in the tenant hybrid system was selected
        // therefore we need to subtract the amount of components from the tenant wizard for the components page to work correctly
        if (systemKey === 'pv') {
          const amount = this.countComponentsFromTenantWizard(systemKey);
          countForSystem -= amount;
        }
      }

      if (countForSystem + ((key === 'heating_pump' && hasAdditionalHeatingPump) ? 1 : 0) < 0) return;
      // contains new array of device definitions
      let initialDefinitions = JSON.parse(JSON.stringify(
        Array(countForSystem).fill({ ...DEFINITIONS[systemKey as keyof Definitions] }),
      ));

      if (key === 'battery' && this.wizardState.inverterCount > 1 && this.isHybridVersion) {
        const customLimit = this.emsLimits.battery * this.wizardState.inverterCount;
        countForSystem = (currentStateEMS.count >= customLimit ? customLimit : currentStateEMS.count); // get overall count of batteries
        countForSystem /= this.wizardState.inverterCount; // set systemcount per inverter
        initialDefinitions = initialDefinitions.slice(0, countForSystem);
      }

      // update included system types with count, isSelected and definitions
      this.selectSystem(countForSystem, systemKey as keyof IIncludedSystemsTypes, countForSystem > 0, initialDefinitions);
    });

    // will set the correct Consumer and Producer for Deye Systems
    if (this.isDeye || this.isSolarmax) {
      this.defineProducerAndConsumer();

      if (this.isHybridVersion && !this.isNoBatteryPath) {
        this.definePvPerInverter();
      }
    }
  }

  definePvPerInverter() {
    const localProject = _.cloneDeep(this.project);
    const pvPerInverter = localProject.meta.wizardSettings?.pvPerInverter;
    const definitionLength = this.wizardState.includedSystemsTypes.pv.definition.length;
    if (pvPerInverter) {
      // pvPerInverter is an object with the key as the system type and the value as the amount of pv per inverter
      // for the amount per inverter we need to add the inverter number to the pv.inverter property
      let j = 0;
      Object.entries(pvPerInverter).forEach(([inverterIndexString, pvPerInverterValue]) => {
        for (let i = 0; i < pvPerInverterValue; i++) {
          if (j >= definitionLength) break;
          this.wizardState.includedSystemsTypes.pv.definition[j].inverter = parseInt(inverterIndexString, 10);
          j++;
        }
      });
    } else {
      this.wizardState.includedSystemsTypes.pv.definition[0].inverter = 0;
    }
  }

  get isNoBatteryPath(): boolean {
    const noBatteryPaths = [WizardPath.TENANT_INSTALLATION_NO_BATTERY, WizardPath.TENANT_STRING_INVERTER, WizardPath.STRING_INVERTER];
    return noBatteryPaths.includes(this.wizardState.wizardPath);
  }

  defineProducerAndConsumer() {
    const allProducers = ['ac_pv', 'chp', 'generator'];
    const selectedProducer = this.isNoBatteryPath ? 'generator' : getSelectedSystem(this.emsDevice.data.meta.controllerMappings, allProducers, 'ac_pv');
    if (selectedProducer !== 'ac_pv' && !this.isNoBatteryPath) {
      let count = 1;
      if (selectedProducer === 'chp') {
        count = this.includedSystemsTypes.chp.definition.length;
        if (count > 0) this.selectSystem(count, selectedProducer, true);
        this.wizardState.producerOptionsEnabled = !!count;
      } else {
        this.selectSystem(count, selectedProducer, true);
      }
    }
    if (this.wasInstallationWizardCompleted) {
      this.getDeyeConsumerInformation();
    }
  }

  selectSystem(count: number, selectedSystem: string, select = true, initialDefinitions?: any) {
    this.handleIncludedSystemsTypesSystem({
      system: selectedSystem as keyof IIncludedSystemsTypes,
      key: 'count',
      data: count,
    });
    this.handleIncludedSystemsTypesSystem({
      system: selectedSystem as keyof IIncludedSystemsTypes,
      key: 'isSelected',
      data: select,
    });
    this.handleIncludedSystemsTypesSystem({
      system: selectedSystem as keyof IIncludedSystemsTypes,
      key: 'definition',
      data: initialDefinitions ?? Array.from({ length: count }, (_, index) => index + 1).map((index) => ({ ...DEFINITIONS[selectedSystem as keyof Definitions] })),
    });
  }

  defineBatterySystem() {
    // contains type of system thats selected inside the project settings
    const { batterySystemType, deyeType } = this.project.meta.controller;
    DEFINITIONS.battery.systemType = batterySystemType;

    // Load battery system type from project meta
    if (this.wasInstallationWizardCompleted) {
      if (deyeType) {
        DEFINITIONS.battery.batteryType = deyeType;
      }
      // Get Capacity
      const capacity = this.measurements['prgEnergy.lrMaxCapacityBattery1'];
      DEFINITIONS.battery.batteryCapacity = `${capacity} kWh`;

      // Get Inverter Power
      const inverterPower = this.measurements['prgEnergy.fbI.rMaxPowerInverter'];
      DEFINITIONS.battery.inverterPower = `${inverterPower} kW`;
    }
  }

  defineElectricHeatingElements() {
    // Get Heating Element by sent MQTT Value
    this.includedSystems.electric_heating.definition.forEach((element: IIncludedSystemsElectricHeatingDefinition, index: number) => {
      const heatingElement = this.measurements[`prgEHE.fbEHE${index + 1}.stSetupEHE.rMaxPower1`];
      element.systemType = electricHeatingTypes.find((type: any) => type.rawValue === heatingElement)?.name ?? '';
      element.title = (this.emsDevice.data.meta.controllerMappings.electric_heating.components[`electric_heating${index + 1}`] as any).title ?? element.systemType + (index + 1);
    });
  }

  defineHeatingPumpElements() {
    const systemTranslation = this.$t('installationWizard.defineHardware.pilotSpecificationPage.deviceSelectionOption.heating_pump').toString();
    this.includedSystems.heating_pump_consumer.definition.forEach((element: IIncludedSystemsHeatingPumpConsumerDefinition, index: number) => {
      element.title = (Object.values(this.emsDevice.data.meta.controllerMappings.heating_pump.components).filter((value) => {
        return !(value as any).power.includes('prgHP.lrPowerHP_');
      })[index] as any)?.title ?? `${systemTranslation} (SG Ready) ${index + 1}`;
    });
    // Get Heating Pump by sent MQTT Value
    this.includedSystems.heating_pump.definition.forEach((element: IIncludedSystemsHeatingPumpDefinition, index: number) => {
      element.systemType = this.project.meta.wizardSettings?.selectedHeatingPumps[index] ?? '';
      element.title = (Object.values(this.emsDevice.data.meta.controllerMappings.heating_pump.components).filter((value) => {
        return (value as any).power.includes('prgHP.lrPowerHP_');
      })[index] as any)?.title ?? `${element.systemType} ${index + 1}`;
    });
  }

  // gets information on what deye consumer is inside the device object and sets the variable values for the component
  getDeyeConsumerInformation() {
    const allConsumers = ['heating_pump', 'big_consumer', 'load_shedding'];
    const selectedConsumer = getSelectedSystem(this.emsDevice.data.meta.controllerMappings, allConsumers, 'load_shedding');

    // Count actual consumers
    let count = this.emsDevice.data.meta.controllerMappings[selectedConsumer]?.count ?? 1;
    const key = selectedConsumer === 'heating_pump' ? 'heating_pump_consumer' : selectedConsumer;
    if (this.wasInstallationWizardCompleted && selectedConsumer !== 'load_shedding') {
      count = this.filterDummies(key as keyof IIncludedSystemsTypes).length;
    }

    // created definitions for selected consumer with empty values
    const initialConsumerDefinition = JSON.parse(JSON.stringify(
      Array(count).fill({ ...DEFINITIONS[key as keyof Definitions] }),
    ));

    // set values for the selected consumer and if its a external measurement it gets added to the connectedLines object
    switch (selectedConsumer) {
      case 'heating_pump': {
        this.updateConsumerDefinition(initialConsumerDefinition, 'heating_pump', (element: any, component: any, index: number, isExternal: boolean) => {
          element.powerSGR1 = this.measurements['prgHP.fbHP.stSetupHP.rMaxPower1'] ?? 0;
          element.powerSGR2 = this.measurements['prgHP.fbHP.stSetupHP.rMaxPower2'] ?? 0;
        });
        break;
      }
      case 'big_consumer': {
        this.updateConsumerDefinition(initialConsumerDefinition, 'big_consumer', (element: any, component: any, index: number, isExternal: boolean) => {
          element.power = this.measurements[`prgOBC.fbBC${index + 1}.stSetupOBC.rMaxPower`] ?? 0;
        });
        break;
      }
      case 'load_shedding': {
        initialConsumerDefinition.forEach((element: any) => {
          element.maxBatteryPower = this.measurements['prgIO.rPowerLDrop'] ?? 0;
          element.powerConsumer1 = this.measurements['prgIO.rMaxPowerLDrop_C1'] ?? 0;
          element.powerConsumer2 = this.measurements['prgIO.rMaxPowerLDrop_C2'] ?? 0;
        });
        break;
      }
    }

    this.selectSystem(count, key as keyof IIncludedSystemsTypes, true, initialConsumerDefinition);
  }

  updateConsumerDefinition(initialConsumerDefinition: any, consumerKey: string, mappingCallback: Function) {
    initialConsumerDefinition.forEach((element: any, index: number) => {
      const componentKey = `${consumerKey}${index + 1}`;
      const component = this.emsDevice.data.meta.controllerMappings[consumerKey].components[componentKey];
      const { title } = component;
      const powerMapping = component.power;
      const isExternal = powerMapping.includes('prgPilot');

      mappingCallback(element, component, index, isExternal);

      if (isExternal) {
        element.connectWithLine = parseInt(powerMapping.slice(-1), 10);
        this.connectedLinesConfig[element.connectWithLine] = this.createLineConfigEntry(title, consumerKey, true);
      }
    });
  }

  checkTypeIndexInMeasurements(index: number): [string, number] {
    let chargeStationSystemType = 'Webasto';
    let webastoType = 0;
    const setVariables = {
      WebastoNext: 'prgCS.wWebasto_Next',
      WebastoUnite: 'prgCS.wWebasto_Unite',
      Weidmüller: 'prgCS.wWeidmueller',
      Schneider: 'prgCS.wSchneider',
      MennekesSingleChargePoint: 'prgCS.wMennekes1CP',
      MennekesDoubleChargePoint: 'prgCS.wMennekes2CP',
      Enercharge: 'prgCS.wEnercharge',
    };
    const bitMap = {
      WebastoNext: this.measurements[setVariables.WebastoNext],
      WebastoUnite: this.measurements[setVariables.WebastoUnite],
      Weidmüller: this.measurements[setVariables.Weidmüller],
      Schneider: this.measurements[setVariables.Schneider],
      MennekesSingleChargePoint: this.measurements[setVariables.MennekesSingleChargePoint],
      MennekesDoubleChargePoint: this.measurements[setVariables.MennekesDoubleChargePoint] ?? this.convertArrayToBit([0, 1, 3, 4]),
      Enercharge: this.measurements[setVariables.Enercharge] ?? this.convertArrayToBit([2]),
    };
    const chargeStationPositionArray: any = {
      WebastoNext: convertBitToArray(convertDecimalNumberToBinary(bitMap.WebastoNext).toString()),
      WebastoUnite: convertBitToArray(convertDecimalNumberToBinary(bitMap.WebastoUnite).toString()),
      Weidmüller: convertBitToArray(convertDecimalNumberToBinary(bitMap.Weidmüller).toString()),
      Schneider: convertBitToArray(convertDecimalNumberToBinary(bitMap.Schneider).toString()),
      MennekesSingleChargePoint: convertBitToArray(convertDecimalNumberToBinary(bitMap.MennekesSingleChargePoint ?? 0).toString()),
      MennekesDoubleChargePoint: convertBitToArray(convertDecimalNumberToBinary(bitMap.MennekesDoubleChargePoint ?? 0).toString()),
      Enercharge: convertBitToArray(convertDecimalNumberToBinary(bitMap.Enercharge ?? 0).toString()),
    };
    Object.entries(setVariables).forEach(([key, value]) => {
      if (chargeStationPositionArray[key].includes(index + 1)) {
        chargeStationSystemType = key.includes('Webasto') ? 'Webasto' : key;
        if (key === 'WebastoNext') {
          webastoType = 0;
        }
        if (key === 'WebastoUnite') {
          webastoType = 1;
        }
      }
    });
    return [chargeStationSystemType, webastoType];
  }

  getChargeStationType(index: number, isPilot: boolean, componentsIndex: number): [string, boolean, string, number] {
    let external = false;
    let chargeStationSystemType = '';
    let additionalInformation = '';
    let webastoType = 0;

    if (this.isNewChargeStationPlcVersion) {
      [chargeStationSystemType, webastoType] = this.checkTypeIndexInMeasurements(componentsIndex);
    } else {
      if (index > 2) {
        chargeStationSystemType = 'Weidmüller';
      } else {
        chargeStationSystemType = 'Webasto';
      }
    }
    if (chargeStationSystemType === 'Weidmüller') {
      external = isPilot;
      additionalInformation = external ? 'external' : 'internal';
    }
    return [chargeStationSystemType, external, additionalInformation, webastoType];
  }

  get isNewChargeStationPlcVersion() {
    return (plcVersionDate(this.project).getTime() > newChargeStationLimitDate.getTime()) && (this.isDeye || this.isSolarmax);
  }

  get isHybridVersion() {
    return plcVersionDate(this.project).getTime() > hybridVersionDate.getTime();
  }

  get hasStringInverter() {
    const stringInverterPaths = [
      WizardPath.STRING_INVERTER,
      WizardPath.INSTALLATION,
      WizardPath.TENANT_STRING_INVERTER,
      WizardPath.INSTALLATION_TENANT,
      WizardPath.TENANT_INSTALLATION_NO_BATTERY,
    ];
    return stringInverterPaths.includes(this.wizardState.wizardPath);
  }

  loadVariablesForChargeStation(index: number) {
    const powerVariable = this.isNewChargeStationPlcVersion ? `prgCS.rMaxPower_${index}` : `prgCS.fbCS${index}.stSetupECS.rMaxPower`;
    const noFreeLoadingVariable = this.isNewChargeStationPlcVersion ? `prgCS.bUseNotFreeCharge_${index}` : `prgCS.bUseNotFreeCharge_${index}`;
    return {
      chargeStationPower: this.measurements[powerVariable],
      noFreeLoading: this.measurements[noFreeLoadingVariable],
    };
  }

  defineInverterCount() {
    if (this.hasLynusInverter) {
      if (!this.isHybrid) this.wizardState.inverterCount = 1;
      else {
        const valueFromMeasurements = this.measurements[installationWizardVariables('numberOfInverters', 'componentsPage').variable];
        this.wizardState.inverterCount = valueFromMeasurements === 0 ? 1 : valueFromMeasurements ?? 1;
      }
    } else {
      this.wizardState.inverterCount = 0;
    }
  }

  defineStringInverterCount() {
    this.wizardState.stringInverterCount = this.measurements[installationWizardVariables('stringInverterCount', 'componentsPage').variable] ?? 0;
  }

  get isMennekesEnerchargeVersion() {
    return plcVersionDate(this.project).getTime() > mennekesEnerchargeDate.getTime() && (this.isDeye || this.isSolarmax);
  }

  defineChargeStationElements() {
    const { components } = this.wizardState.emsDevice.data.meta.controllerMappings.charge_station;
    let occupyNextChargeStation = false;
    Object.entries(components).forEach(([key, value]: [string, any], index) => {
      const componentDefinition = this.includedSystemsTypes.charge_station.definition[index];

      if (!componentDefinition) return;
      if (!value.error) return;

      const { title } = components[`charge_station${index + 1}`];
      const chargeStationIndex = parseInt(value.error.split('_')[1], 10);
      const isPilot = value.power.includes('prgPilot');

      if (this.isMennekesEnerchargeVersion) {
        const chargeStationType = this.project.meta?.wizardSettings?.chargeStationSettings?.length ? this.project.meta.wizardSettings.chargeStationSettings[index].type : ChargeStationType.WebastoNext11;
        if (occupyNextChargeStation) {
          const previousChargeStation = this.includedSystems.charge_station.definition[index - 1];
          this.includedSystems.charge_station.definition[index].systemType = previousChargeStation.systemType;
          this.includedSystems.charge_station.definition[index].connectWithLine = previousChargeStation.connectWithLine;
          this.includedSystems.charge_station.definition[index].noFreeLoading = previousChargeStation.noFreeLoading;
          this.includedSystems.charge_station.definition[index].occupiedByTwinPoint = true;
          occupyNextChargeStation = false;
          return;
        }
        occupyNextChargeStation = [ChargeStationType.AmtronTC22, ChargeStationType.AmedioPro22].includes(chargeStationType);

        const loadedChargeStation = this.loadVariablesForChargeStation(this.isNewChargeStationPlcVersion ? index + 1 : chargeStationIndex);
        const { noFreeLoading } = loadedChargeStation;

        componentDefinition.systemType = chargeStationType;
        componentDefinition.noFreeLoading = noFreeLoading;

        if ([ChargeStationType.WebastoNext11, ChargeStationType.WebastoNext22].includes(chargeStationType)) componentDefinition.webastoType = 0;
        else if ([ChargeStationType.WebastoUnite11, ChargeStationType.WebastoUnite22].includes(chargeStationType)) componentDefinition.webastoType = 1;

        if ([ChargeStationType.WebastoNext11, ChargeStationType.WebastoUnite11].includes(chargeStationType)) componentDefinition.systemType = 'Webasto11';
        if ([ChargeStationType.WebastoNext22, ChargeStationType.WebastoUnite22].includes(chargeStationType)) componentDefinition.systemType = 'Webasto22';

        const isPilot = value.power.includes('prgPilot');
        if ([ChargeStationType.externalWeidmüller11, ChargeStationType.externalWeidmüller22, ChargeStationType.internalWeidmüller11, ChargeStationType.internalWeidmüller22, ChargeStationType.internalWeidmüllerMS11, ChargeStationType.internalWeidmüllerMS22].includes(chargeStationType)) {
          // Get connected pilot line from power mapping
          if (!isPilot && [ChargeStationType.externalWeidmüller11, ChargeStationType.externalWeidmüller22].includes(chargeStationType)) {
            this.includedSystems.charge_station.definition[index].connectWithLine = 'noLine';
          } else if (isPilot) {
            const connectWithLine = parseInt(value.power[value.power.length - 1], 10);
            this.includedSystems.charge_station.definition[index].connectWithLine = connectWithLine;
            this.connectedLinesConfig[connectWithLine] = this.createLineConfigEntry(title, 'charge_station', true);
          }
        }
      } else {
        const [chargeStationSystemType, external, additionalInformation, webastoType] = this.getChargeStationType(chargeStationIndex, isPilot, index);
        const {
          chargeStationPower,
          noFreeLoading,
        } = this.loadVariablesForChargeStation(this.isNewChargeStationPlcVersion ? index + 1 : chargeStationIndex);

        componentDefinition.systemType = `${additionalInformation}${chargeStationSystemType}${chargeStationPower}`;
        componentDefinition.noFreeLoading = noFreeLoading;
        // if charge station type is set we add it to the charge station definition
        if (this.measurements[`prgCS.byTypeECS_${index + 1}`]) {
          componentDefinition.webastoType = this.measurements[`prgCS.byTypeECS_${index + 1}`] ?? 0;
        } else if (chargeStationSystemType === 'Webasto') {
          componentDefinition.webastoType = webastoType;
        }

        if (external) {
          // Get connected pilot line from power mapping
          const connectWithLine = parseInt(value.power[value.power.length - 1], 10);
          this.includedSystems.charge_station.definition[index].connectWithLine = connectWithLine;
          this.connectedLinesConfig[connectWithLine] = this.createLineConfigEntry(title, 'charge_station', true);
        }
      }

      componentDefinition.title = value.title ?? '';
    });
  }

  // checks all Systems that can be a external visualisation and sets the count correctly
  defineExternalMeasurementDevices() {
    const keyArray = [...availableExternalMeasurementTypes, ...(this.isDeye || this.isSolarmax ? availableExternalMeasurementDeyeTypes : []), 'heating_pump_consumer'];
    const currentEmsControllerMappings = this.allDevices.find((device: IDevice) => device.data.type === 'EMSV2')?.data.meta.controllerMappings;
    let measurementsCounter = 0;
    keyArray.forEach((systemType: string) => {
      let system = systemType;
      let filterFunction = (component: any) => true;
      if (systemType === 'heating_pump') {
        filterFunction = (component: any) => component.power.includes('prgHP.lrPowerHP_');
      }
      if (systemType === 'heating_pump_consumer') {
        system = 'heating_pump';
        filterFunction = (component: any) => !component.power.includes('prgHP.lrPowerHP_');
      }

      Object.values(currentEmsControllerMappings[system].components)
        .filter(filterFunction)
        .forEach((component: any) => {
          // check if component is external measurement and its a dummy component becasue all ohter external measurement devices allready get addded to lines
          if (component.external_energy_measurement && !component.error) {
            // count for dummy components
            measurementsCounter++;
            this.connectedLinesConfig[parseInt(component.power.slice(-1), 10)] = this.createLineConfigEntry(component.title, system, false);
            this.setExternallyUsedPilotLine(parseInt(component.power.slice(-1), 10));
          }
        });
    });
    if (measurementsCounter > 0) {
      this.localExternalVisualisaton.isSelected = true;
      this.localExternalVisualisaton.count = measurementsCounter;

      // if user goes back to components page we check if the count was changed from the base device and if yes we set the new value
      if (this.externalVisualisation.count !== measurementsCounter && this.wasComponentsPageDone) {
        this.localExternalVisualisaton.count = this.externalVisualisation.count;
        this.localExternalVisualisaton.isSelected = this.localExternalVisualisaton.count > 0;
      }
    }
    this.setPilotConfig();
  }

  // adds a device to the pilot lines config
  createLineConfigEntry(name: string, type: string, disableLine: boolean) {
    return {
      name,
      device: {
        text: this.$t(`installationWizard.defineHardware.pilotSpecificationPage.deviceSelectionOption.${type}`),
        value: type,
      },
      disabled: disableLine,
    };
  }

  initSystemRows() {
    this.systems = {
      ...this.systems,
      electric_heating: {
        min: 0,
        max: this.emsLimits.electric_heating,
      },
      charge_station: {
        min: 0,
        max: this.emsLimits.charge_station,
      },
    };
    if (this.isDeye || this.isSolarmax) {
      this.systems = { pv: { min: 0, max: this.emsLimits.pv - 1 }, ...this.systems };
    }
    if ((this.isDeye || this.isSolarmax) && this.isNewChargeStationPlcVersion) {
      this.systems = { ...this.systems, heating_pump: { min: 0, max: this.emsLimits.heating_pump } };
    }
    if (!this.isSolarmax) {
      this.systems = {
        battery: {
          min: 1,
          max: this.emsLimits.battery,
        },
        ...this.systems,
      };
    }
  }

  initEmsSystems() {
    const isFilled = Object.values(this.includedSystemsTypes).some((element: any) => element.count > 0);
    if (!isFilled) {
      // Check and load battery system if available in meta
      this.defineBatterySystem();

      // Initialize included systems in store
      this.initIncludedSystems();
    }

    if (this.isNoBatteryPath) this.clearBatteryIncludedSystems();
  }

  clearBatteryIncludedSystems() {
    this.handleIncludedSystemsTypesSystem({
      system: 'battery',
      key: 'count',
      data: 0,
    });
    this.handleIncludedSystemsTypesSystem({
      system: 'battery',
      key: 'isSelected',
      data: false,
    });
    this.handleIncludedSystemsTypesSystem({
      system: 'battery',
      key: 'definition',
      data: [],
    });
    this.setEMSSystemCount({ system: 'battery', count: 0 });
    this.setEnergyViewSystemCount({ system: 'battery', count: 0 });
  }

  disableAllSystemsStringInverterOnly() {
    this.prepareBatteryVariables();
    this.prepareElectricHeatingVariables();
    this.prepareChargeStationVariables();
    this.prepareHeatingPumpVariables();
    this.disableNotSelectedSystems();

    this.prepareProducerVariables();
    this.prepareAdditionalEnergyVariables();
  }

  // to not have to add a lot of if statements in the created function
  async onlyStringInverterCreated() {
    this.defineStringInverterCount();
  }

  async created() {
    this.confirmed = false;

    if (this.hasOnlyStringInverter) {
      await this.onlyStringInverterCreated();
      this.validationStatusSingleSystems.battery = true;
      this.dataLoaded = true;
      return;
    }

    if (this.isMennekesEnerchargeVersion) {
      this.bitBySystem.charge_station = {
        ...this.bitBySystem.charge_station,
        setAmedio: false,
        setAmtronTC: false,
        setAmtron: false,
        setEnercharge: false,
      };
    }

    // if the system can have a battery but is deselected we need to set the validation status to true
    if (!this.isNoBatteryPath && this.wizardState.includedSystemsTypes.battery.count === 0) {
      this.validationStatusSingleSystems.battery = true;
    }
    // As Battery Component is not available in the wizard for solarmax we need to set its validation status to true
    if (this.isSolarmax) this.validationStatusSingleSystems.battery = true;

    this.localExternalVisualisaton = cloneDeep(this.externalVisualisation);
    if (this.project.id) {
      await this.fetchMeasurements(this.project.id);
    }

    // First get inverter count in case if the wizard was already completed
    if (this.wasInstallationWizardCompleted) {
      if (this.isSolarmax || (this.isDeye && this.isHybridVersion)) this.defineInverterCount();
      else {
        this.wizardState.inverterCount = 1;
      }

      // same for string inverter count
      if (this.isDeye && this.isHybridVersion && this.includedSystemsTypes.chp.definition.length === 0) this.defineStringInverterCount();
    }

    this.initSystemRows();
    this.initEmsSystems();

    if (!this.hasLynusInverter && plcVersionDate(this.project).getTime() > tenantUpdateWagoCounters.getTime()) {
      delete this.systems.pv;
      delete this.systems.battery;
      this.validationStatusSingleSystems.battery = true;
    }

    // load acpv available from measurements if its already set
    const acpvValueFromMeasurements = this.measurements[installationWizardVariables('acpvOnGrid', 'componentsPage', 0).variable];
    const acpvOnLoadValueFromMeasurements = this.measurements[installationWizardVariables('acpvOnLoad', 'componentsPage', 0).variable];
    const onBatteryValueFromMeasurements = this.measurements[installationWizardVariables('oneBatteryInput', 'componentsPage', 0).variable];
    if (acpvValueFromMeasurements !== undefined) {
      this.wizardState.acpvAvailable = acpvValueFromMeasurements === 1;
    }
    if (acpvOnLoadValueFromMeasurements !== undefined) {
      this.wizardState.acpvOnLoadAvailable = acpvOnLoadValueFromMeasurements === 1;
    }
    if (onBatteryValueFromMeasurements !== undefined) {
      this.wizardState.hybridSettings.useBothBatteryInputs = onBatteryValueFromMeasurements === 1;
    }

    if (this.wasInstallationWizardCompleted) {
      this.defineElectricHeatingElements();
      if (this.navigationDirection !== 0) {
        if (this.isNewChargeStationPlcVersion) this.defineHeatingPumpElements();
        this.defineChargeStationElements();
      }
      this.defineExternalMeasurementDevices();
    }

    // All needed data available
    this.dataLoaded = true;
  }

  addPilotVariables() {
    this.setVariablesToSend('numberOfMeters', this.pilotSystemCount);
    this.setVariablesToSend('disablePilot');
  }

  async confirmedNext() {
    this.confirmed = true;
    (this.$refs.confirmationModal as any).dialog = false;
    await this.onNext();
  }

  async onNext() {
    if (this.isDeye && this.includedSystemsTypes.battery.definition.length > 0 && [deyeTypes.WecoBatteryStack, deyeTypes.WecoSlimStack].includes(this.includedSystemsTypes.battery.definition[0].batteryType as deyeTypes) && !this.confirmed) {
      // Open the modal window
      (this.$refs.confirmationModal as any).dialog = true;
    } else {
      if (this.isDeye) {
        if (this.doesMeasurementExist(installationWizardVariables('acpvOnGrid', 'componentsPage').variable)) {
          this.setVariablesToSend('acpvOnGrid', this.wizardState.acpvAvailable ? 1 : 0);
        }
        if (this.isHybridVersion && this.doesMeasurementExist(installationWizardVariables('acpvOnLoad', 'componentsPage').variable)) {
          this.setVariablesToSend('acpvOnLoad', this.wizardState.acpvOnLoadAvailable ? 1 : 0);
        }
      }
      this.definePilotConfig();
      if (this.pilotSystemCount === 0 && this.localExternalVisualisaton.count === 0) {
        this.addPilotVariables();
      }

      // Save selected heating pumps in project meta
      await this.handleNext();
    }
  }

  doesMeasurementExist(variableName: string) {
    // check measurements
    return this.measurements[variableName] != null;
  }

  definePilotConfig() {
    const pilotLines = Object.keys(this.connectedLinesConfig).filter((element: any) => !this.connectedLines.includes(parseInt(element, 10)));
    pilotLines.forEach((line: string) => {
      this.connectedLinesConfig[line] = { device: { text: '', value: '' }, name: '', disabled: false };
    });
    this.setPilotConfig();
  }

  setPilotConfig() {
    const pilotConfig = {
      ctRelationship: 100,
      lineConfig: Object.values(this.connectedLinesConfig),
    };
    // sets pilot config to show it correctly on the pilot page
    this.setIsPilotPageDone(pilotConfig);
  }

  async mounted() {
    // trigger inital validation
    await this.$nextTick();
    this.validate();
  }
}
