
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';

/**
 * Text field component which used in manage modal forms
 */
@Component
export default class TextField extends Vue {
  @Prop() value!: string;
  @Prop({ default: null }) label!: null | string | VueI18n.TranslateResult;
  @Prop({ default: true }) optional!: boolean;
  @Prop() maxLength!: number | string;

  get customRules() {
    if (!this.optional) return (value: number) => (value === 0 || !!value) || this.$t('validationRules.required');
    else return true;
  }

  mounted() {
    (this.$refs.customField as any).validate(true);
  }
}
