import api from '@/services/api';

export default {
  async fetchControllersByProject(projectId: string) {
    return api.fetchMPC(`/controllers/${projectId}`, 'GET');
  },

  async fetchController(controllerId: string) {
    return api.fetchMPC(`/${controllerId}`, 'GET');
  },

  async createController(controller: any) {
    return api.fetchMPC('/controllers/', 'POST', controller);
  },

  async updateController(controllerId: string, controller: any) {
    return api.fetchMPC(`/controllers/${controllerId}`, 'PUT', controller);
  },

  async deleteController(controllerId: string) {
    return api.fetchMPC(`/controllers/${controllerId}`, 'DELETE');
  },

  async addToFavorites(controllerId: string) {
    return api.fetchMPC(`/controllers/${controllerId}/favorites`, 'PUT');
  },

  async deleteFromFavorites(controllerId: string) {
    return api.fetchMPC(`/controllers/${controllerId}/favorites`, 'DELETE');
  },

  async updateControllerTiming(controllerId: string, controller: any) {
    return api.fetchMPC(`/controllers/${controllerId}/timing`, 'PUT', controller);
  },

  async updateControllerSettings(controllerId: string, controller: any) {
    return api.fetchMPC(`/controllers/${controllerId}/settings`, 'PUT', controller);
  },
};
