var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{class:[
    'lynus-icon',
    `icon-${_vm.name}`,
    _vm.scaleClass,
    `icon-color-class-${_vm.color}`,
    { 'icon-animation': _vm.animation },
  ],style:(_vm.sizeValue)})
}
var staticRenderFns = []

export { render, staticRenderFns }