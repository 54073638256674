
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { cloneDeep } from 'lodash';
import {
  convertBitToArray,
  convertDecimalNumberToBinary,
  minMaxValidation,
} from '@/utils/utilsFunctions';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { allEnergyCircleTypes } from '@/types/energyVisualisation/EnergyCircleType';
import {
  ChargeModeOptionsKeys,
} from '@/ui/components/devices/devices/base/ChargeStationMinPowerSelection.vue';
import { IDevice } from '@/types/devices.types';
import installationWizardVariables
  from '@/ui/components/wizards/installationWizard/installationWizardVariables';
import {
  IIncludedSystemsChargeStationDefinition,
  IIncludedSystemsTypes,
  ILineConfig, IMQTTVariable,
  IPilotMappings,
  NavigationDirection, WizardPath,
} from '@/types/wizards/installationWizard.types';
import {
  hybridVersionDate,
  newChargeStationLimitDate, paragraph14Date,
  mennekesEnerchargeDate,
  plcVersionDate,
  tenantUpdateWagoCounters,
} from '@/utils/versionManagementUtils';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { filterNumberFromString } from '@/utils/tenantWizardUtilsFunctions';
import {
  batteryMappingEMSTenant,
  batteryMappingEnergyViewTenant,
} from '@/ui/components/wizards/tenantWizard/wizardSettings/defaultMappings';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';
import { IInverter, SerialNumberType } from '@/types/serialNumber.types';
import { ChargeStationType, IChargeStationSetting, IProject } from '@/types/project.types';
import {
  availableExternalMeasurementDeyeTypes,
  availableExternalMeasurementTypes,
  getChargeStationNameByValue,
  getChargeStationTypeInformationBySystemType,
} from '../../wizardSettings/systemTypes';
import {
  batteryMappingEMS,
  batteryMappingEnergyView,
  chargeStationMappingEMS,
  chargeStationMappingEnergyView,
  chpMappingsEMS,
  chpMappingsEnergyView,
  dummyComponent,
  electricHeatingMappingEMS,
  electricHeatingMappingEnergyView,
  generatorMappingEnergyView,
  generatorMappingsEMS,
  gridMappingEMS,
  gridMappingEnergyView,
  heatingPumpMappingEMS,
  houseMappingEMS,
  houseMappingEnergyView,
  multiBatteryMappingEMS,
  multiBatteryMappingEnergyView,
  newChargeStationMappingEnergyView,
  newChargeStationMappingsEMS,
  newHeatingPumpMappingsEMS,
  newHeatingPumpMappingsEnergyView,
  otherBigConsumerMappingsEMS,
  pvMappingEMS,
  pvMappingEMSDeye,
  pvMappingEnergyView,
  pvMappingEnergyViewDeye, stringInverterMappingEMS,
} from '../../wizardSettings/defaultMappings';

enum InitiationMode {
  skip,
  default,
  alreadyDone,
}

@Component({
  methods: { minMaxValidation },
  components: {
    InfoTooltip,
    WizardContentView,
  },
})
export default class PilotSpecification extends WizardComponent {
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @State('installationWizard') installationWizardState!: IInstallationWizardState;
  @Getter('installationWizard/emsDevice') emsDevice!: IDevice;
  @Getter('serialNumber/serialNumbersFromMqtt') serialNumbersFromMqtt!: { [key: string]: string };
  @Getter('installationWizard/energyViewDevice') energyViewDevice!: IDevice;
  @Getter('installationWizard/pilotSystemCount') pilotSystemCount!: number;
  @Getter('installationWizard/isPilotPageDone') isPilotPageDone!: IIncludedSystemsTypes;
  @Getter('installationWizard/savedPilotMapping') savedPilotMapping!: IPilotMappings;
  @Getter('installationWizard/navigationDirection') navigationDirection!: NavigationDirection;
  @Getter('installationWizard/ACPV') ACPV!: any;
  @Getter('installationWizard/isHybrid') isHybrid!: boolean;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('projects/project') project!: any;
  @Getter('installationWizard/pilot') pilot!: {
    isSelected: boolean;
    definition: [];
    count: number;
  };
  @Getter('installationWizard/serialNumberCheckDone') serialNumberCheckDone!: boolean;
  @Getter('tenantWizard/overallProductionMeasurement') overallProductionMeasurement!: string;
  @Getter('installationWizard/externalVisualisation') externalVisualisation!: {
    count: number;
    definition: [];
  };
  @Getter('installationWizard/getDisablePilotDevices') getDisablePilotDevices!: boolean;
  @Getter('serialNumber/inverters') inverters!: IInverter[];
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>
  @Action('serialNumber/fetchInverters') fetchInverters!: (projectId: string) => Promise<void>;
  @Action('serialNumber/activateSerialNumbers') activateSerialNumbers!: (payload: {
    projectId: string;
    inverters: { id: string; type: SerialNumberType }[];
  }) => Promise<boolean>;
  @Action('devices/updateDevice') updateDevice!: (data: {
    device: IDevice;
    skipReport: boolean;
  }) => Promise<IDevice>;
  @Action('installationWizard/initDevices') initDevices!: () => void;
  @Action('tenantWizard/loadTenant') loadTenant!: (project_id: string) => Promise<void>;
  @Mutation('installationWizard/setIsPilotPageDone') setIsPilotPageDone!: (payload: {}) => void;
  @Mutation('installationWizard/updateEMSDeviceData') updateEMSDeviceData!: (payload: Partial<IDevice>) => void;
  @Mutation('installationWizard/updateEnergyViewDeviceData') updateEnergyViewDeviceData!: (payload: Partial<IDevice>) => void;
  @Mutation('installationWizard/setWizardBlocked') setWizardBlocked!: (payload: boolean) => void;
  @Mutation('installationWizard/setSerialNumberCheckDone') setSerialNumberCheckDone!: () => void;
  @Mutation('serialNumber/setInverters') setInverters!: (inverters: {
    id: string;
    type: SerialNumberType;
  }[]) => void;

  pilotMapping: IPilotMappings[] = [];
  availableDeviceItems: string[] = [];
  selectedItems: { text: string; value: string }[] = [];
  valid = false;
  loading = false;
  // contains the amount of lines that must be filled
  amountOfFilledPilotLines = 0;
  chargeStationTypeCurrentIndex = {
    webasto: 0,
    weidmüller: 2,
  };

  /**
   * creates an array of all Pilot lines that have a device connected in order to create 'dummy devices'
   */
  get pilotLineConfigs(): {
    element: { device: { text: string; value: string }; name: string; disabled: boolean };
    pilotIndex: number;
    lineIndex: number;
  }[] {
    const pilotLines: any = this.pilotMapping.map((value: IPilotMappings, pilotIndex: number) => {
      return value.lineConfig
        .map((element: {
          device: { text: string; value: string };
          name: string;
          disabled: boolean;
        }, lineIndex: number) => {
          if (element.device.value !== '') {
            return { element, pilotIndex: pilotIndex + 1, lineIndex: lineIndex + 1 };
          }
          return undefined;
        }).filter(element => element !== undefined);
    });
    return [].concat(...pilotLines);
  }

  get disableEmptyLineConfigFields() {
    if (this.pilotMapping[0]) {
      const selectedFields = this.pilotMapping[0].lineConfig.filter((element: ILineConfig) => {
        return element.device.text !== '';
      });
      return selectedFields.length >= this.amountOfFilledPilotLines;
    }
    return false;
  }

  get lineDeviceItems() {
    return this.availableDeviceItems
      .filter((item1: any) => !this.selectedItems.some(item2 => item2.text === item1.text));
  }

  get hasLynusInverter(): boolean {
    return this.project.meta.hasLynusInverter ?? true;
  }

  /**
   * Contains paths where it is required to clear all none acpv components
   * Only String_Inverter Path is missing because this is handled in a different case
   */
  get filterNoneAcPvSystems() {
    return this.installationWizardState.wizardPath === WizardPath.TENANT_INSTALLATION_NO_BATTERY;
  }
  get isNoBatteryPath(): boolean {
    const noBatteryPaths = [WizardPath.TENANT_INSTALLATION_NO_BATTERY, WizardPath.TENANT_STRING_INVERTER, WizardPath.STRING_INVERTER];
    return noBatteryPaths.includes(this.installationWizardState.wizardPath);
  }
  get isHybridVersion() {
    return plcVersionDate(this.project).getTime() > hybridVersionDate.getTime();
  }
  get tenantPaths() {
    const tenantPaths = [WizardPath.INSTALLATION_TENANT, WizardPath.TENANT, WizardPath.TENANT_INSTALLATION_NO_BATTERY, WizardPath.TENANT_STRING_INVERTER];
    return tenantPaths.includes(this.installationWizardState.wizardPath);
  }

  initPilotMappings() {
    this.pilotMapping = Array(this.pilotSystemCount).fill({
      ctRelationship: 100,
      lineConfig: [
        { device: { text: '', value: '' }, name: '', disabled: false },
        { device: { text: '', value: '' }, name: '', disabled: false },
        { device: { text: '', value: '' }, name: '', disabled: false },
        { device: { text: '', value: '' }, name: '', disabled: false },
      ],
    });
  }

  /**
   * Prefills pilotMapping (lineConfig) and selectedItem based on the given input from the user inside Components Page
   * currently its only working for charge_stations because its the only device that can be connected to a external measurement
   * inside the Components Page
   */
  initAlreadySelectedItems() {
    Object.entries(this.includedSystems).forEach(([systemName, systemOptions]) => {
      let mappings = [];
      if (systemName === 'charge_station') {
        mappings = systemOptions.definition.map((systemInfo: any, index: number) => {
          if (typeof systemInfo?.connectWithLine === 'number') {
            // given charge station is an external visualisation
            const text = getChargeStationNameByValue(systemInfo.systemType) ?? '';
            const value = `${systemName}--${index}`;

            // prefill pilotMapping and selectedItem array
            this.fillPilotMapping(text, systemInfo, value);
            this.addSelectedItem(text, systemInfo, value);

            return { text, value };
          } else return undefined;
        }).filter((value: any) => value !== undefined);
      }
      if (systemName === 'heating_pump' || systemName === 'big_consumer') {
        systemOptions.definition.forEach((systemInfo: any, index: number) => {
          // eslint-disable-next-line no-restricted-globals
          if (typeof systemInfo?.connectWithLine === 'number' && !isNaN(systemInfo?.connectWithLine)) {
            // given charge station is an external visualisation
            const hpTranslation = this.$t('installationWizard.defineHardware.pilotSpecificationPage.deviceSelectionOption.heating_pump').toString();
            const bcTranslation = this.$t('installationWizard.defineHardware.pilotSpecificationPage.deviceSelectionOption.big_consumer').toString();

            const text = systemName === 'heating_pump' ? hpTranslation : bcTranslation;
            const value = `${systemName}--${index}`;

            // prefill pilotMapping and selectedItem array
            this.fillPilotMapping(text, systemInfo, value);
            this.addSelectedItem(text, systemInfo, value);
            mappings.push({ text, value });
          }
        });
      }

      this.availableDeviceItems = this.availableDeviceItems.concat(mappings);
    });
  }

  fillPilotMapping(text: string, systemInfo: {
    connectWithLine: number;
    systemType: string;
  }, value: string) {
    // fill pilotMapping device, disabled
    const lineConfigInstance = this.pilotMapping[0].lineConfig[systemInfo?.connectWithLine - 1];
    lineConfigInstance.device = { text: text ?? systemInfo.systemType, value };
    lineConfigInstance.name = text ?? systemInfo.systemType;
    lineConfigInstance.disabled = true;
  }

  addSelectedItem(text: string, systemInfo: {
    connectWithLine: number;
    systemType: string;
  }, value: string) {
    // fill selectedItems
    this.selectedItems.push({ text: text ?? systemInfo.systemType, value });
  }

  initOtherSelectionOptions() {
    const otherMappings: any = [...availableExternalMeasurementTypes, ...((this.isDeye || this.isSolarmax) ? availableExternalMeasurementDeyeTypes : [])].map(((value: string) => {
      const optionText = this.$t(`installationWizard.defineHardware.pilotSpecificationPage.deviceSelectionOption.${value}`);
      return { text: optionText, value: `${value}` };
    }));
    this.availableDeviceItems = this.availableDeviceItems.concat(otherMappings);
  }

  /**
   * Clears all counts and components of the EMS/ EnergyView mappings so we can fill them with the user selected systems
   */
  clearEnergyDevices(mappings: any) {
    let elementsToSkip: any = [];
    if (plcVersionDate(this.project).getTime() > tenantUpdateWagoCounters.getTime()) {
      if (!this.hasLynusInverter) {
        // all items from the following list are set by the tenant therefor we skip them
        elementsToSkip = ['grid', 'house', 'pv', 'battery'];
      } else if (this.hasLynusInverter) {
        elementsToSkip = ['house']; // house is set by tenant therefor we skip it
      }
    }
    allEnergyCircleTypes.forEach((systemType: string) => {
      if (elementsToSkip.includes(systemType)) {
        // because those systems are allready set from tenant they should not be cleared
        return;
      }
      if (mappings[systemType] === undefined) {
        return;
      }
      mappings[systemType].components = {};
    });
    return mappings;
  }

  async savePvPerInverter(pvPerInverter: { [key: number]: number[] }) {
    const localProject = JSON.parse(JSON.stringify(this.project));
    const pvPerInverterAsNumbers: { [key: string]: number } = {};
    Object.entries(pvPerInverter).forEach(([key, value]) => {
      pvPerInverterAsNumbers[key.toString()] = value.length;
    });
    localProject.meta.wizardSettings.pvPerInverter = pvPerInverterAsNumbers;

    await this.updateProject(localProject);
  }

  get isP14Version() {
    return plcVersionDate(this.project).getTime() > paragraph14Date.getTime();
  }

  fillPvMappings(emsMappings: any, energyViewMappings: any) {
    if (!this.hasLynusInverter) return; // skip pv if tenant only
    if (this.isDeye || this.isSolarmax) {
      // deye has 4 normal pv mappings and can have one acpv mapping
      // loop fills in the pv's

      // pvIndices should be the selected amount multiplied by the number of inverters selected
      let maxIndex = 0;
      const pvPerInverter: { [key: number]: number[] } = {};
      for (let i = 0; i < this.installationWizardState.inverterCount; i++) {
        pvPerInverter[i] = Array.from({ length: this.includedSystems.pv.definition.filter((element: any) => element.inverter === i).length }, (_, i) => i);
      }
      Object.entries(pvPerInverter).forEach(([inverterIndexStr, pvIndices]) => {
        const inverterIndex = Number(inverterIndexStr);
        pvIndices.forEach((_, index: number) => {
          emsMappings.pv.components[`pv${maxIndex + 1}`] = pvMappingEMSDeye(index + 1, false, inverterIndex + 1);
          energyViewMappings.pv.components[`pv${maxIndex + 1}`] = pvMappingEnergyViewDeye(index + 1, false, inverterIndex + 1);
          maxIndex++;
        });
      });
      this.savePvPerInverter(pvPerInverter);

      if (this.ACPV.isSelected) {
        const index = Object.keys(emsMappings.pv.components).length + 1;
        // if ac pv is selected we set the acpv mapping and set the count
        emsMappings.pv.components[`pv${index}`] = pvMappingEMSDeye(maxIndex + 1, true);
        energyViewMappings.pv.components[`pv${index}`] = pvMappingEnergyViewDeye(maxIndex + 1, true);
        energyViewMappings.pv.count = index;
      }
    } else {
      // azzurro can only have one pv
      emsMappings.pv.components.pv1 = pvMappingEMS(0);
      energyViewMappings.pv.components.pv1 = pvMappingEnergyView(0);
    }
  }

  get isMennekesEnerchargeVersion() {
    return plcVersionDate(this.project).getTime() > mennekesEnerchargeDate.getTime() && (this.isDeye || this.isSolarmax);
  }

  getChargeStationType(chargeStations: IIncludedSystemsChargeStationDefinition[], index: number) {
    let { systemType } = chargeStations[index];
    const { webastoType } = chargeStations[index];

    if (chargeStations[index].occupiedByTwinPoint) {
      systemType = this.includedSystems.charge_station.definition[index - 1].systemType;
    }

    let chargeStationType: ChargeStationType;
    switch (systemType) {
      case 'Webasto11':
        chargeStationType = webastoType === 0 ? ChargeStationType.WebastoNext11 : ChargeStationType.WebastoUnite11;
        break;
      case 'Webasto22':
        chargeStationType = webastoType === 0 ? ChargeStationType.WebastoNext22 : ChargeStationType.WebastoUnite22;
        break;
      default:
        chargeStationType = systemType as ChargeStationType;
        break;
    }
    return chargeStationType;
  }

  /** adds all components to EnergyView and EMS objects, that are not dummy components  */
  async fillEnergyDevicesControllerMappings() {
    let emsMappings = cloneDeep(this.emsDevice.data.meta.controllerMappings);
    let energyViewMappings = cloneDeep(this.energyViewDevice.data.meta.controllerMappings);

    // clear all energy devices
    emsMappings = this.clearEnergyDevices(emsMappings);
    energyViewMappings = this.clearEnergyDevices(energyViewMappings);

    if (this.hasOnlyStringInverter) {
      // clear all pv mappings and add the correct amount of pv's selected by the user
      emsMappings.pv = {
        components: {},
        count: 0,
      };
      energyViewMappings.pv = {
        components: {},
        count: 0,
      };
      for (let i = 0; i < this.installationWizardState.stringInverterCount; i++) {
        emsMappings.pv.components[`pv${i + 1}`] = stringInverterMappingEMS(i);
        energyViewMappings.pv.components[`pv${i + 1}`] = stringInverterMappingEMS(i);
        emsMappings.pv.count++;
        energyViewMappings.pv.count++;
      }

      // remove battery and grid in case of string inverter only as there will only be acpv and house
      emsMappings.battery = {
        components: {},
        count: 0,
      };
      energyViewMappings.battery = {
        components: {},
        count: 0,
      };

      // for string wizard only systems remove grid as it cant display any values
      emsMappings.grid.components = {};
      energyViewMappings.grid.components = {};
      emsMappings.grid.count = 0;
      energyViewMappings.grid.count = 0;
    } else {
      // check if date is newer and a value is set by hasLynusInverter
      if (plcVersionDate(this.project).getTime() > tenantUpdateWagoCounters.getTime() && this.project.meta.hasLynusInverter !== undefined) {
        // skip grid if we dont have a lynus inverter, because it is set by tenant
        if (this.hasLynusInverter) {
          // Grid and House is default 1
          const gridMappingsEMS = gridMappingEMS();
          // add the size in amps to the grid mapping
          gridMappingsEMS.size_in_amps = this.emsDevice.data.meta.controllerMappings.grid.components.grid1?.size_in_amps ?? 0;
          emsMappings.grid.components.grid1 = gridMappingsEMS;
          energyViewMappings.grid.components.grid1 = gridMappingEnergyView();
          emsMappings.grid.count = 1;
          energyViewMappings.grid.count = 1;

          if (emsMappings.house.mappings === 0 && energyViewMappings.house.mappings === 0) {
            // add check to not add the wrong count if the houses are set inside the tenant
            emsMappings.house.count = 1;
            energyViewMappings.house.count = 1;
          }
        } else {
          if (this.installationWizardState.wizardPath === WizardPath.TENANT_INSTALLATION_NO_BATTERY) {
            // if no battery is selected we add the grid mapping
            emsMappings.grid.components.grid1 = gridMappingEMS();
            energyViewMappings.grid.components.grid1 = gridMappingEnergyView();
            emsMappings.grid.count = 1;
            energyViewMappings.grid.count = 1;
          }
        }
      } else {
        // Grid and House is default 1
        emsMappings.house.components.house1 = houseMappingEMS(1);
        energyViewMappings.house.components.house1 = houseMappingEnergyView(1);
        emsMappings.house.count = 1;
        energyViewMappings.house.count = 1;

        const gridMappingsEMS = gridMappingEMS();
        // add the size in amps to the grid mapping
        gridMappingsEMS.size_in_amps = this.emsDevice.data.meta.controllerMappings.grid.components.grid1?.size_in_amps ?? 0;
        emsMappings.grid.components.grid1 = gridMappingsEMS;
        energyViewMappings.grid.components.grid1 = gridMappingEnergyView();
        emsMappings.grid.count = 1;
        energyViewMappings.grid.count = 1;
      }

      this.chargeStationTypeCurrentIndex = {
        webasto: 0,
        weidmüller: 2,
      };
      let amountOfOtherHeatingPumps = 0;
      const chargeStationTypes: IChargeStationSetting[] = [];

      Object.entries(this.includedSystems).forEach(([type, system]) => {
        if (system.definition.length === 0 && type === 'battery') this.fillPvMappings(emsMappings, energyViewMappings);
        system.definition.forEach((device: any, deviceIndex: number) => {
          const systemName = `${type}${deviceIndex + 1}`;
          const pilotMappingKey = `${type}--${deviceIndex}`;

          if (this.isNoBatteryPath) {
            emsMappings.battery.components = {};
            energyViewMappings.battery.components = {};
          }

          switch (type) {
            case 'battery': {
              // skip because components are set by tenant
              if (!this.hasLynusInverter && plcVersionDate(this.project).getTime() > tenantUpdateWagoCounters.getTime()) break;

              if (this.isSolarmax || (this.isDeye && this.isHybridVersion)) {
                // battery amount is dependent on amount of inverters -> selected amount of batteries multiplied by the number of inverters selected
                // create array to loop over
                const batteries = Array.from({ length: this.includedSystems.battery.definition.length * this.installationWizardState.inverterCount }, (_, i) => i + 1);
                batteries.forEach((_, index) => {
                  emsMappings.battery.components[`battery${index + 1}`] = multiBatteryMappingEMS(index, this.includedSystems.battery.definition.length, this.isP14Version);
                  energyViewMappings.battery.components[`battery${index + 1}`] = multiBatteryMappingEnergyView(index, this.includedSystems.battery.definition.length);
                });
              } else {
                // adds all the "battery" Mappings to Device controller mappings
                emsMappings[type].components[systemName] = batteryMappingEMS(deviceIndex, this.isP14Version);
                energyViewMappings[type].components[systemName] = batteryMappingEnergyView(deviceIndex);
              }
              // if one battery is selected there must be one PV System
              if (deviceIndex === 0) {
                this.fillPvMappings(emsMappings, energyViewMappings);
              }
              break;
            }
            case 'charge_station': {
              // will contain Charge Station Type and is External information for later mapping
              let chargeStationInformations = getChargeStationTypeInformationBySystemType(device.systemType);

              // there is the posibility that Weidmüller Charge Stations with external energy measurement are not connected to a line
              // solution if charge station needs to have internal mappings for power and counter
              if (device.connectWithLine === 'noLine' && !device.systemType.includes('external')) {
                chargeStationInformations.isExternal = false;
              }

              // adds all the "charge station" Mappings to Device controller mappings
              // search line config to get name
              const systemObject = this.pilotLineConfigs.find((object: any) => {
                return object.element.device.value === pilotMappingKey;
              });

              const pilotPowerMapping = `prgPilot.lrPower_${systemObject?.pilotIndex}_${systemObject?.lineIndex}`;
              const pilotCounterMapping = `prgPilot.lrCounter_Cons_${systemObject?.pilotIndex}_${systemObject?.lineIndex}`;

              const isSecondPointChargeStation = device.occupiedByTwinPoint;
              if (plcVersionDate(this.project).getTime() > newChargeStationLimitDate.getTime() && (this.isDeye || this.isSolarmax)) {
                let deviceName = getChargeStationNameByValue(device.systemType) ?? '';

                if (isSecondPointChargeStation) {
                  // use system type from previous index to get device name
                  const previousIndex = deviceIndex - 1;
                  deviceName = `${getChargeStationNameByValue(this.includedSystems.charge_station.definition[previousIndex].systemType)} (2)` ?? '';
                }
                let chargeStationType: ChargeStationType;
                if (this.isMennekesEnerchargeVersion) {
                  // if its a webasto we need to check if its a Next or a Unite
                  chargeStationType = this.getChargeStationType(this.includedSystems.charge_station.definition, deviceIndex);
                  chargeStationInformations = { type: chargeStationType, isExternal: false };
                  chargeStationTypes.push({ type: chargeStationType });
                }
                // adds all the "battery" Mappings to Device controller mappings
                const defaultChargeStationEMSMappings = newChargeStationMappingsEMS(deviceIndex, deviceName, this.isP14Version);
                const defaultChargeStationEnergyViewMappings = newChargeStationMappingEnergyView(deviceIndex, deviceName);

                if (chargeStationInformations.isExternal && chargeStationInformations.type === 'Weidmüller' && device.connectWithLine !== 'noLine') {
                  defaultChargeStationEMSMappings.power = pilotPowerMapping;
                  defaultChargeStationEMSMappings.energy_counter = pilotCounterMapping;

                  defaultChargeStationEnergyViewMappings.power = pilotPowerMapping;
                  defaultChargeStationEnergyViewMappings.energy_counter = pilotCounterMapping;

                  defaultChargeStationEMSMappings.calculate_as_external_device = true;
                  defaultChargeStationEnergyViewMappings.calculate_as_external_device = true;
                }

                const chargeStationComponents = this.emsDevice.data.meta.controllerMappings.charge_station.components;
                const onlyDummies = Object.values(chargeStationComponents).every((element: any) => element.external_energy_measurement && element.error === undefined);
                const componentName = `charge_station${onlyDummies ? Object.keys(chargeStationComponents).length + deviceIndex + 1 : deviceIndex + 1}`;

                const title = this.emsDevice.data.meta.controllerMappings[type].components[componentName]?.title ?? defaultChargeStationEMSMappings.title;
                this.includedSystems.charge_station.definition[deviceIndex].title = title;
                emsMappings[type].components[systemName] = defaultChargeStationEMSMappings;
                energyViewMappings[type].components[systemName] = defaultChargeStationEnergyViewMappings;
                emsMappings[type].components[systemName].title = title;
                energyViewMappings[type].components[systemName].title = title;

                emsMappings[type].components[systemName].minimum_power_selection = this.emsDevice.data.meta.controllerMappings[type].components[systemName]?.minimum_power_selection ?? ChargeModeOptionsKeys.minimum;
                emsMappings[type].components[systemName].current = this.emsDevice.data.meta.controllerMappings[type].components[systemName]?.current ?? 0;
                break;
              }

              const deviceName = getChargeStationNameByValue(device.systemType) ?? '';

              switch (chargeStationInformations.type) {
                case 'Webasto': {
                  // adds a new charge station of type webasto to charge_station mappings at the usual device index.
                  emsMappings = this.addDefaultMappingChargeStationEMS(this.chargeStationTypeCurrentIndex.webasto, deviceIndex, type, deviceName, emsMappings);
                  energyViewMappings = this.addDefaultMappingChargeStationEV(this.chargeStationTypeCurrentIndex.webasto, deviceIndex, type, deviceName, energyViewMappings);
                  this.chargeStationTypeCurrentIndex.webasto++;
                  break;
                }
                case 'Weidmüller': {
                  if (chargeStationInformations.isExternal && device.connectWithLine !== 'noLine') {
                    // adds a new charge station of type waidmüller to charge_station mappings with the needed varibale changes that it needs to be a external measurement device
                    const name = systemObject?.element.name;
                    // external_energy_measurement needs to be set to true in this case
                    const defaultStationMappingEMS = chargeStationMappingEMS(this.chargeStationTypeCurrentIndex.weidmüller, name, this.isP14Version);
                    defaultStationMappingEMS.power = pilotPowerMapping;
                    defaultStationMappingEMS.energy_counter = pilotCounterMapping;
                    defaultStationMappingEMS.calculate_as_external_device = true;

                    emsMappings[type].components[systemName] = defaultStationMappingEMS;

                    const defaultStationMappingEnergyView = chargeStationMappingEnergyView(this.chargeStationTypeCurrentIndex.weidmüller, name);
                    defaultStationMappingEnergyView.power = pilotPowerMapping;
                    defaultStationMappingEnergyView.energy_counter = pilotCounterMapping;
                    defaultStationMappingEnergyView.calculate_as_external_device = true;

                    energyViewMappings[type].components[systemName] = defaultStationMappingEnergyView;
                  } else {
                    emsMappings = this.addDefaultMappingChargeStationEMS(this.chargeStationTypeCurrentIndex.weidmüller, deviceIndex, type, deviceName, emsMappings);
                    if (device.connectWithLine !== 'noLine') {
                      energyViewMappings = this.addDefaultMappingChargeStationEV(this.chargeStationTypeCurrentIndex.weidmüller, deviceIndex, type, deviceName, energyViewMappings);
                    }
                  }
                  this.chargeStationTypeCurrentIndex.weidmüller++;
                  break;
                }
              }
              emsMappings[type].components[systemName].minimum_power_selection = this.emsDevice.data.meta.controllerMappings[type].components[systemName]?.minimum_power_selection ?? ChargeModeOptionsKeys.minimum;
              emsMappings[type].components[systemName].current = this.emsDevice.data.meta.controllerMappings[type].components[systemName]?.current ?? 0;

              const title = this.emsDevice.data.meta.controllerMappings[type].components[systemName]?.title ?? emsMappings[type].components[systemName].title;
              emsMappings[type].components[systemName].title = title;
              if (energyViewMappings[type].components[systemName]) energyViewMappings[type].components[systemName].title = title;
              this.includedSystems.charge_station.definition[deviceIndex].title = title;
              break;
            }
            case 'electric_heating': {
              // adds all the "electric heating" Mappings to Device controller mappings
              emsMappings[type].components[systemName] = electricHeatingMappingEMS(deviceIndex, 'Askoma', this.isP14Version);
              energyViewMappings[type].components[systemName] = electricHeatingMappingEnergyView(deviceIndex);

              const systemTranslation = this.$t('installationWizard.defineHardware.pilotSpecificationPage.deviceSelectionOption.electric_heating');
              const electricHeatingComponents = this.emsDevice.data.meta.controllerMappings.electric_heating.components;
              const onlyDummies = Object.values(electricHeatingComponents).every((element: any) => element.external_energy_measurement && element.error === undefined);
              const componentName = `electric_heating${onlyDummies ? Object.keys(electricHeatingComponents).length + deviceIndex + 1 : deviceIndex + 1}`;
              const title = this.emsDevice.data.meta.controllerMappings[type].components[componentName]?.title ?? `${systemTranslation} ${deviceIndex + 1}: ${device.systemType}`;
              emsMappings[type].components[systemName].title = title;
              energyViewMappings[type].components[systemName].title = title;
              this.includedSystems.electric_heating.definition[deviceIndex].title = title;
              break;
            }
            case 'heating_pump_consumer': {
              const systemObject = this.pilotLineConfigs.find((object: any) => {
                return object.element.device.value === pilotMappingKey;
              });
              const name = systemObject?.element.name ?? this.$t('installationWizard.defineHardware.pilotSpecificationPage.deviceSelectionOption.heating_pump');
              const hpSystemName = `heating_pump${deviceIndex + 1 + amountOfOtherHeatingPumps}`;
              // update included systems type name
              const heatingPumpComponents = this.emsDevice.data.meta.controllerMappings.heating_pump.components;
              const onlyDummies = Object.values(heatingPumpComponents).every((element: any) => element.external_energy_measurement && element.error === undefined);
              const componentName = `heating_pump${onlyDummies ? Object.keys(heatingPumpComponents).length + deviceIndex + 1 : deviceIndex + 1}`;
              const title = this.emsDevice.data.meta.controllerMappings.heating_pump.components[componentName]?.title ?? `${name} (SG Ready)`;

              if (typeof device.connectWithLine === 'number') {
                const emsObject = heatingPumpMappingEMS(title, this.isP14Version);
                emsObject.power = `prgPilot.lrPower_${systemObject?.pilotIndex}_${systemObject?.lineIndex}`;
                emsObject.energy_counter = `prgPilot.lrCounter_Cons_${systemObject?.pilotIndex}_${systemObject?.lineIndex}`;
                emsMappings.heating_pump.components[hpSystemName] = emsObject;
                // for energyView it is only needed when its a external visualisation
                energyViewMappings.heating_pump.components[hpSystemName] = {
                  title,
                  power: `prgPilot.lrPower_${systemObject?.pilotIndex}_${systemObject?.lineIndex}`,
                  energy_counter: `prgPilot.lrCounter_Cons_${systemObject?.pilotIndex}_${systemObject?.lineIndex}`,
                  external_energy_measurement: true,
                };
              } else {
                // creates ems entry for heating pump
                emsMappings.heating_pump.components[hpSystemName] = heatingPumpMappingEMS(title, this.isP14Version);
              }
              this.includedSystems.heating_pump_consumer.definition[deviceIndex].title = title;
              break;
            }
            case 'heating_pump': {
              const amountOfConsumerHeatingPumps = Object.values(this.emsDevice.data.meta.controllerMappings.heating_pump.components).filter((element: any) => {
                return (!element.power.includes('prgHP.lrPowerHP_') && !(element.external_energy_measurement && element.error === undefined));
              }).length;
              const hpSystemName = `heating_pump${deviceIndex + 1 + amountOfOtherHeatingPumps}`;
              // adds all the "electric heating" Mappings to Device controller mappings
              const systemTranslation = this.$t('installationWizard.defineHardware.pilotSpecificationPage.deviceSelectionOption.heating_pump');
              const heatingPumpComponents = this.emsDevice.data.meta.controllerMappings.heating_pump.components;

              // components index starts at either 0 or 1 depending on consumers selected
              let componentIndex = amountOfConsumerHeatingPumps;
              let startIndexOfNormalHeatingPumps: number | null = Object.values(heatingPumpComponents).findIndex((element: any) => element.power.includes('prgHP.lrPowerHP_'));
              if (startIndexOfNormalHeatingPumps === -1) startIndexOfNormalHeatingPumps = null;
              const countDummies = Object.values(heatingPumpComponents).filter((element: any) => element.external_energy_measurement && element.error === undefined).length;
              const countNormalHeatingPumps = Object.values(heatingPumpComponents).filter((element: any) => element.power.includes('prgHP.lrPowerHP_')).length;

              // Normal heating pumps already exist
              if (startIndexOfNormalHeatingPumps !== null) {
                if ((deviceIndex + 1 + amountOfConsumerHeatingPumps) <= (countNormalHeatingPumps + amountOfConsumerHeatingPumps)) {
                  componentIndex = deviceIndex + 1 + amountOfConsumerHeatingPumps;
                } else {
                  componentIndex = Object.keys(heatingPumpComponents).length + deviceIndex + 1;
                }
              } else {
                componentIndex = countDummies + deviceIndex + 1 + amountOfConsumerHeatingPumps;
              }

              const componentName = `heating_pump${componentIndex}`;
              const title = this.emsDevice.data.meta.controllerMappings.heating_pump.components[componentName]?.title ?? `${systemTranslation} ${deviceIndex + 1}: ${device.systemType}`;
              emsMappings[type].components[hpSystemName] = newHeatingPumpMappingsEMS(deviceIndex + 1, title, this.isP14Version);
              energyViewMappings[type].components[hpSystemName] = newHeatingPumpMappingsEnergyView(deviceIndex + 1, title);
              this.includedSystems.heating_pump.definition[deviceIndex].title = title;
              break;
            }
            case 'big_consumer': {
              const systemObject = this.pilotLineConfigs.find((object: any) => {
                return object.element.device.value === pilotMappingKey;
              });
              const systemTranslation = this.$t('installationWizard.defineHardware.pilotSpecificationPage.deviceSelectionOption.big_consumer');
              const name = systemObject?.element.name ?? systemTranslation.toString();

              const bigConsumerComponents = this.emsDevice.data.meta.controllerMappings.big_consumer.components;
              const onlyDummies = Object.values(bigConsumerComponents).every((element: any) => element.external_energy_measurement && element.error === undefined);
              const componentName = `big_consumer${onlyDummies ? Object.keys(bigConsumerComponents).length + deviceIndex + 1 : deviceIndex + 1}`;
              const title = this.emsDevice.data.meta.controllerMappings.heating_pump.components[componentName]?.title ?? `${name} ${deviceIndex + 1}`;

              if (typeof device.connectWithLine === 'number') {
                const emsObject = otherBigConsumerMappingsEMS(deviceIndex, name, this.isP14Version);
                emsObject.power = `prgPilot.lrPower_${systemObject?.pilotIndex}_${systemObject?.lineIndex}`;
                emsObject.energy_counter = `prgPilot.lrCounter_Cons_${systemObject?.pilotIndex}_${systemObject?.lineIndex}`;
                emsMappings[type].components[systemName] = emsObject;
                // for energyView it is only needed when its a external visualisation
                energyViewMappings[type].components[systemName] = {
                  title: `${name} ${deviceIndex + 1}`,
                  power: `prgPilot.lrPower_${systemObject?.pilotIndex}_${systemObject?.lineIndex}`,
                  energy_counter: `prgPilot.lrCounter_Cons_${systemObject?.pilotIndex}_${systemObject?.lineIndex}`,
                  external_energy_measurement: true,
                };
              } else {
                // creates ems entry for big consumer
                emsMappings[type].components[systemName] = otherBigConsumerMappingsEMS(deviceIndex, name, this.isP14Version);
              }
              this.includedSystems.big_consumer.definition[deviceIndex].title = title;
              break;
            }
            case 'generator': {
              if (!this.isNoBatteryPath) {
                emsMappings[type].components[systemName] = generatorMappingsEMS('Generator');
                energyViewMappings[type].components[systemName] = generatorMappingEnergyView(1);
              } else {
                emsMappings[type].components = {};
                energyViewMappings[type].components = {};
              }
              break;
            }
            case 'chp':
              emsMappings[type].components[systemName] = chpMappingsEMS(deviceIndex);
              energyViewMappings[type].components[systemName] = chpMappingsEnergyView(deviceIndex);
              break;
          }
        });
        amountOfOtherHeatingPumps = Object.keys(emsMappings.heating_pump.components).length;
      });
      if (this.filterNoneAcPvSystems) {
        // clear mappings for pv as only acpv's are allowed in mappings
        emsMappings.pv = {
          components: {},
          count: 0,
        };
        energyViewMappings.pv = {
          components: {},
          count: 0,
        };
      }

      if (this.isMennekesEnerchargeVersion) {
        const projectCopy: IProject = JSON.parse(JSON.stringify(this.project));
        if (projectCopy.meta?.wizardSettings) projectCopy.meta!.wizardSettings!.chargeStationSettings = chargeStationTypes;
        await this.updateProject(projectCopy);
      }

      // add string inverters just like acpv
      const pvCount = Object.keys(emsMappings.pv.components).length;
      for (let i = 0; i < this.installationWizardState.stringInverterCount; i++) {
        emsMappings.pv.components[`pv${pvCount + i + 1}`] = stringInverterMappingEMS(i);
        energyViewMappings.pv.components[`pv${pvCount + i + 1}`] = stringInverterMappingEMS(i);
      }
      const newPvCount = Object.keys(emsMappings.pv.components).length;
      emsMappings.pv.count = newPvCount;
      energyViewMappings.pv.count = newPvCount;
    }

    // if no battery is defined but a inverter is defined we the errorWarning variable from the battery to the grid instead
    const inverterWizardPaths = [WizardPath.INSTALLATION, WizardPath.INSTALLATION_TENANT];
    if (emsMappings.battery.count === 0 && inverterWizardPaths.includes(this.installationWizardState.wizardPath)) {
      emsMappings.grid.components.grid1.error = batteryMappingEMS(0, this.isP14Version).error;
      energyViewMappings.grid.components.grid1.error = batteryMappingEMS(0, this.isP14Version).error;
    }

    this.updateEMSDeviceData({ data: { meta: { controllerMappings: emsMappings } } });
    this.updateEnergyViewDeviceData({ data: { meta: { controllerMappings: energyViewMappings } } });
  }

  addDefaultMappingChargeStationEMS(chargeStationTypeIndex: number, deviceIndex: number, type: string, deviceName: string, emsMappings: any) {
    emsMappings[type].components[`${type}${deviceIndex + 1}`] = plcVersionDate(this.project).getTime() > newChargeStationLimitDate.getTime() && (this.isDeye || this.isSolarmax)
      ? newChargeStationMappingsEMS(deviceIndex, deviceName, this.isP14Version)
      : chargeStationMappingEMS(chargeStationTypeIndex, deviceName, this.isP14Version);
    return emsMappings;
  }

  addDefaultMappingChargeStationEV(chargeStationTypeIndex: number, deviceIndex: number, type: string, deviceName: string, energyViewMappings: any) {
    energyViewMappings[type].components[`${type}${deviceIndex + 1}`] = plcVersionDate(this.project).getTime() > newChargeStationLimitDate.getTime() && (this.isDeye || this.isSolarmax)
      ? newChargeStationMappingEnergyView(deviceIndex, deviceName)
      : chargeStationMappingEnergyView(chargeStationTypeIndex, deviceName);
    return energyViewMappings;
  }

  /** Add all dummy components to EnergyView and EMS objects */
  async addExternalVisualisationSystems() {
    const emsMappings = cloneDeep(this.emsDevice.data.meta.controllerMappings);
    const energyViewMappings = cloneDeep(this.energyViewDevice.data.meta.controllerMappings);
    this.pilotLineConfigs.forEach((pilotConfig: any) => {
      // creates all pilot devices that are not already created (because they are not preset from components page)
      if (!pilotConfig.element.device.value.includes('--')) {
        const currentIndex = Object.keys(emsMappings[pilotConfig.element.device.value].components).length + 1;
        const deviceType = pilotConfig.element.device.value;
        const deviceName = pilotConfig.element.name;
        emsMappings[deviceType].components[`${deviceType}${currentIndex}`] = dummyComponent(deviceName, pilotConfig.lineIndex, 1);
        energyViewMappings[deviceType].components[`${deviceType}${currentIndex}`] = dummyComponent(deviceName, pilotConfig.lineIndex, 1);
      }
    });
    this.updateEMSDeviceData({ data: { meta: { controllerMappings: emsMappings } } });
    this.updateEnergyViewDeviceData({ data: { meta: { controllerMappings: energyViewMappings } } });
  }

  // add components that where added with the tenant wizard
  reAddTenantComponents() {
    const emsMappings = cloneDeep(this.emsDevice.data.meta.controllerMappings);
    const energyViewMappings = cloneDeep(this.energyViewDevice.data.meta.controllerMappings);
    // check if overall production measurement is set
    if (this.project.meta.overallProductionMeasurement === '') {
      // add the production mappings from the tenant to the pv mappings
      let productionMappings = this.tenantState.tenant.general.productionCounters ?? [];
      const variablesToFilter = [
        'prgEnergy.lrCounterProdTotal',
        'prgEM.lrCounterProdTotal_2',
      ];
      const containsInternalCounter = productionMappings.filter((counter: string) => variablesToFilter.includes(counter)).length > 0;
      // remove the counters that are not needed for the pv mappings
      productionMappings = productionMappings.filter((counter: string) => !variablesToFilter.includes(counter));
      if (!containsInternalCounter) {
        // if digital counters are not present we do not need to add any pv's
        productionMappings = [];
      }
      productionMappings.forEach((counter: string) => {
        // get the number from the counter string
        const number = parseInt(filterNumberFromString(counter), 10) ?? 0;
        // check if the component is already set
        // if not set add it to the mappings in EMS and EnergyView
        emsMappings.pv.count++;
        const element = pvMappingEMS(emsMappings.pv.count);
        element.title = `PV ${emsMappings.pv.count}`;
        element.energy_counter = counter;
        element.power = `prgEM.lrPower_E_${filterNumberFromString(counter)}`;
        emsMappings.pv.components[`pv${emsMappings.pv.count}`] = element;

        energyViewMappings.pv.count++;
        const elementEnergyView = pvMappingEnergyView(energyViewMappings.pv.count);
        elementEnergyView.title = `PV ${energyViewMappings.pv.count}`;
        elementEnergyView.energy_counter = counter;
        elementEnergyView.power = `prgEM.lrPower_E_${filterNumberFromString(counter)}`;
        energyViewMappings.pv.components[`pv${energyViewMappings.pv.count}`] = elementEnergyView;
      });
    }

    // will contain the ac battery numbers if present
    const acBatteryIds = this.getAcBatteryNumbers();
    // add ac batteries if they are present. but only if we have a lynus inverter
    if (acBatteryIds) {
      acBatteryIds.forEach((id: number, acBatteryIndex: number) => {
        // check if the battery is already set
        // if battery is not set add it to the mappings in EMS and EnergyView
        emsMappings.battery.count++;
        const currentIndex = emsMappings.battery.count;
        const element = batteryMappingEMSTenant();
        element.power = `prgEM.lrPower_E_${id}`;
        element.title = `AC Batterie ${acBatteryIndex + 1}`;
        element.energy_counter = `prgEM.lrCounter_E_P_${id}`;
        element.reverse_energy_counter = `prgEM.lrCounter_E_C_${id}`;
        emsMappings.battery.components[`battery${currentIndex}`] = element;

        energyViewMappings.battery.count++;
        const elementEnergyView = batteryMappingEnergyViewTenant(currentIndex);
        elementEnergyView.power = `prgEM.lrPower_E_${id}`;
        elementEnergyView.title = `AC Batterie ${acBatteryIndex + 1}`;
        elementEnergyView.energy_counter = `prgEM.lrCounter_E_P_${id}`;
        elementEnergyView.reverse_energy_counter = `prgEM.lrCounter_E_C_${id}`;
        energyViewMappings.battery.components[`battery${currentIndex}`] = elementEnergyView;
      });
    }

    this.updateEMSDeviceData({ data: { meta: { controllerMappings: emsMappings } } });
    this.updateEnergyViewDeviceData({ data: { meta: { controllerMappings: energyViewMappings } } });
  }

  /**
   * Get the ac battery numbers from the saved value
   */
  getAcBatteryNumbers() {
    // if set saved value will be the decimal equivalent of the bit set with the ac battery numbers
    let savedValue = this.measurements['prgEM.lwPosOfACBattery'];

    // if saved value is undefined we do not need to do anything
    if (!savedValue) return;

    if (typeof savedValue === 'string') {
      savedValue = parseInt(savedValue, 10);
    }
    // if saved value is 0 we do not need to do anything
    if (savedValue === 0) return;

    // convert the decimal number to binary and then to an array of numbers used for the ac battery mapping
    const binaryNumber = convertDecimalNumberToBinary(savedValue).toString();
    return convertBitToArray(binaryNumber) ?? [];
  }

  deleteInput(index: number) {
    this.$set(this.pilotMapping[0].lineConfig, index, {
      device: { text: '', value: '' },
      name: '',
      disabled: false,
    });
  }

  setVariablesToSend(variableName: string, value: number | string = 0, systemIndex = 0, page = 'pilotPage') {
    this.variablesToSend.set(variableName + systemIndex, installationWizardVariables(variableName, page, value, systemIndex));
  }

  get hasOnlyStringInverter() {
    return (this.installationWizardState.wizardPath === WizardPath.STRING_INVERTER) || (this.installationWizardState.wizardPath === WizardPath.TENANT_STRING_INVERTER);
  }

  async prepareVariablesToSend() {
    if (this.pilotSystemCount > 0) {
      this.setVariablesToSend('numberOfMeters', this.pilotSystemCount);
      this.setVariablesToSend('enablePilot');
      this.pilotMapping.forEach((value: IPilotMappings, index: number) => {
        this.setVariablesToSend('ctRelationship', this.pilotMapping[0].ctRelationship, index);
      });
    }

    // sends all the needed mqtt values
    await this.fillEnergyDevicesControllerMappings();
    await this.addExternalVisualisationSystems();
    if (this.hasLynusInverter && this.project.meta.wasTenantWizardCompleted && plcVersionDate(this.project).getTime() > tenantUpdateWagoCounters.getTime()) {
      // components only need to be readded if a lynus inverter is present because they get cleared in the fillEnergyDevicesControllerMappings function
      // if there is no lynus inverter all the components are set by the tenant and clearing those is disabled in the fillEnergyDevicesControllerMappings function
      if (this.isHybridVersion) {
        if (this.tenantPaths) {
          // only readd the components if there is a path selected that includes a tenant wizard
          await this.reAddTenantComponents();
        }
      } else {
        // for older version paths are not needed to be checked
        await this.reAddTenantComponents();
      }
    }
    if (this.emsDevice.id) await this.updateDevice({ device: this.emsDevice, skipReport: false });
    if (this.energyViewDevice.id) {
      await this.updateDevice({
        device: this.energyViewDevice,
        skipReport: false,
      });
    }
    this.setIsPilotPageDone(this.pilotMapping[0]);
  }

  async validate() {
    await this.$nextTick();

    (this.$refs.form as any).validate();
  }

  skipPilotPage(direction: 0 | 1) {
    if (direction) {
      this.handleNext();
    } else {
      this.handleDecrement();
    }
  }

  defineInitiation(): InitiationMode {
    if (this.pilotSystemCount === 0) {
      return InitiationMode.skip;
    }
    if (!this.isPilotPageDone) {
      return InitiationMode.default;
    } else {
      if (this.savedPilotMapping === undefined) {
        // case when no external measurement system is selected
        return InitiationMode.default;
      } else {
        // case when external measurement system is selected
        return InitiationMode.alreadyDone;
      }
    }
  }

  handleModeInitiation(mode: InitiationMode) {
    switch (mode) {
      case InitiationMode.skip:
        this.skipPilotPage(this.navigationDirection);
        break;
      case InitiationMode.default:
        this.initPilotMappings();
        break;
      case InitiationMode.alreadyDone:
        this.handleAlreadyDone();
        break;
    }
    this.initAlreadySelectedItems();
    this.amountOfFilledPilotLines = this.availableDeviceItems.length + this.externalVisualisation.count;
    this.initOtherSelectionOptions();
  }

  handleAlreadyDone() {
    this.initDevices();
    if (this.savedPilotMapping.lineConfig.filter((element: any) => element.name !== '').length && this.externalVisualisation.count !== 0) {
      this.$set(this.pilotMapping, 0, cloneDeep(this.savedPilotMapping));
    } else {
      this.initPilotMappings();
    }
  }

  async created() {
    if (this.hasLynusInverter && this.project.meta.wasTenantWizardCompleted && plcVersionDate(this.project).getTime() > tenantUpdateWagoCounters.getTime()) {
      // if tenant was done and hybrid selected we need to load the tenant to readd the components that where added with the tenant wizard
      await this.loadTenant(this.project.id);
    }

    this.handleMode();
  }

  handleMode() {
    const mode = this.defineInitiation();
    this.handleModeInitiation(mode);
  }

  mounted() {
    if (this.pilotSystemCount > 0) {
      this.validate();
    }
  }
}
