
import { Component, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Getter } from 'vuex-class';
import SystemDialog
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/SystemDialog.vue';
import { IOEnergyCircleType } from '@/types/energyVisualisation/EnergyCircleType';
import { roundNumber } from '@/utils/utilsFunctions';
import {
  CirclePosition,
  IOEnergyCircleDisplayData,
} from '@/types/energyVisualisation/EnergyCircleDisplayData';
import {
  energyConsumerColors,
  energyHybridColors,
  energyGreyColors,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/constants';
import { IMeasurements } from '@/types/measurements.types';
import {
  ArrowDirection,
  EnergyLineDisplayData,
} from '@/types/energyVisualisation/EnergyLineDisplayData';
import { kiloWattHourUnit } from '@/utils/unit/unitConstants';
import { IDevice } from '@/types/devices.types';
import {
  IOEnergyDataByPeriodResponse,
} from '@/types/energyVisualisation/energyVisualisation.types';
import IOSystemDialogView
  from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/IOSystemDialogView.vue';
import EnergyIOComponent from '../utils/EnergyIOComponent';
import {
  getDefaultArrowDirectionIO,
  getDefaultLineColorsIO
} from '../utils/EnergyIOVisualisationUtils';

type SystemInstances = Record<string, SystemInstance>
type SystemInstance = Record<string, string>

@Component({
  components: {
    IOSystemDialogView,
    SystemDialog,
  },
  methods: { roundNumber },
})
export default class EnergyFlowViewSystemDialogIO extends mixins(EnergyIOComponent) {
  // whether the dialog is currently shown (v-model)
  @Prop() value!: boolean;
  @Prop() openedSystemId!: string;
  @Prop() openedSystem!: IOEnergyCircleType;
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop() energyDataByPeriodResponse!: IOEnergyDataByPeriodResponse | null;
  @Getter('measurements/measurements') measurements!: IMeasurements;

  @Provide('onDialogLineAnimationEnd') onDialogLineAnimationEnd =
    this.onDialogLineAnimationEndCallback;
  dialogHybridHighlightBottomValue = false;
  dialogHybridArrowDirection = ArrowDirection.toCenter;
  dialogHybridLineColors = energyHybridColors;

  get systemTitle() {
    return this.deviceData.data.meta.controllerMappings[this.openedSystem as any].components[this.openedSystemId].title;
  }

  onDialogLineAnimationEndCallback() {
    this.dialogHybridHighlightBottomValue = !this.dialogHybridHighlightBottomValue;
    this.dialogHybridArrowDirection =
      this.dialogHybridArrowDirection === ArrowDirection.toCenter
        ? ArrowDirection.awayFromCenter
        : ArrowDirection.toCenter;
    this.dialogHybridLineColors =
      this.dialogHybridLineColors === energyHybridColors
        ? energyConsumerColors
        : energyHybridColors;
  }
  get systemDialogCircles() {
    if (!this.openedSystem) return [];

    const system = this.getIoSystemComponents(this.openedSystem)[this.openedSystemId];
    const mappedSystems: IOEnergyCircleDisplayData[] = [];

    if (this.openedSystem === IOEnergyCircleType.hybrids) {
      let value = (this.energyDataByPeriodResponse?.energies as any)[this.openedSystem as string][`${this.openedSystemId}_from_production`] ?? 0;
      if (this.dialogHybridHighlightBottomValue && this.openedSystem === IOEnergyCircleType.hybrids) value = (this.energyDataByPeriodResponse?.energies as any)[this.openedSystem as string][`${this.openedSystemId}_to_consumer`] ?? 0;
      const spinnerColor = this.defaultColorByType(this.openedSystem, value, this.openedSystem === IOEnergyCircleType.hybrids ? this.dialogHybridLineColors.colorAtCircle : null);

      mappedSystems.push({
        id: this.openedSystemId,
        title: this.$t(`mlModel.${this.deviceData.data.type}.groups.${this.openedSystem}.system`) as string,
        systemType: this.openedSystem,
        spinnerColor,
        position: CirclePosition.top,
        disableSpinner: false,
        unit: kiloWattHourUnit,
        bottomValue: value,
      });
      return mappedSystems;
    }

    Object.entries(system.energy_counter).forEach(([key, systemComponent], index: number) => {
      let bottomValue;
      if (this.energyDataByPeriodResponse?.energies) bottomValue = (this.energyDataByPeriodResponse?.energies as any)[this.openedSystem as string][this.openedSystemId][key];
      const spinnerColor = this.defaultColorByType(this.openedSystem, bottomValue);
      mappedSystems.push({
        id: key,
        title: `${this.$t(`mlModel.${this.deviceData.data.type}.groups.${this.openedSystem}.system`)} ${index + 1}`,
        systemType: this.openedSystem,
        spinnerColor,
        position: CirclePosition.top,
        disableSpinner: false,
        unit: kiloWattHourUnit,
        bottomValue,
      });
    });
    return mappedSystems;
  }

  get systemDialogLines(): Partial<EnergyLineDisplayData>[] {
    if (!this.openedSystem) return [];

    return this.systemDialogCircles.map((displayData: IOEnergyCircleDisplayData) => {
      if (displayData.systemType === IOEnergyCircleType.hybrids) {
        return {
          id: displayData.id,
          arrowDirection: displayData.bottomValue === 0 ? ArrowDirection.none : this.dialogHybridArrowDirection,
          colors: displayData.bottomValue === 0 ? energyGreyColors : this.dialogHybridLineColors,
        } as Partial<EnergyLineDisplayData>;
      }
      return {
        id: displayData.id,
        arrowDirection: getDefaultArrowDirectionIO(this.openedSystem, displayData),
        colors: getDefaultLineColorsIO(this.openedSystem, displayData),
      } as Partial<EnergyLineDisplayData>;
    });
  }
}
