import { EnergyLineColors } from '@/types/energyVisualisation/EnergyLineDisplayData';

export const energyYellow = '#FFC800';
export const energyGreen = '#218608';
export const energyOrange = '#ff9900';
export const energyRed = '#D70827';
export const energyBlue = '#0F72BA';
export const energyGrey = '#707070';
export const coolingBlue = '#0953f3';
export const heatingRed = '#c02530';

export const energyProducerColors: EnergyLineColors = {
  colorAtCenter: energyBlue,
  colorAtCircle: energyYellow,
};
export const energyHybridColors: EnergyLineColors = {
  colorAtCenter: energyBlue,
  colorAtCircle: energyRed,
};
export const energyConsumerColors: EnergyLineColors = {
  colorAtCenter: energyBlue,
  colorAtCircle: energyGreen,
};
export const energyGreyColors: EnergyLineColors = {
  colorAtCenter: energyGrey,
  colorAtCircle: energyGrey,
};
export const energyHeatingColors: EnergyLineColors = {
  colorAtCenter: energyBlue,
  colorAtCircle: heatingRed,
};
export const energyCoolingColors: EnergyLineColors = {
  colorAtCenter: energyBlue,
  colorAtCircle: coolingBlue,
};
