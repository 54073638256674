
import { Component, Prop } from 'vue-property-decorator';
import { Action, Mutation } from 'vuex-class';
import DeviceActions from '@/ui/components/devices/actions/DeviceActions.vue';
import SettingsView from '@/ui/components/devices/components/SettingsView.vue';
import MinusBase from '@/ui/components/devices/mpc/base/MinusBase.vue';
import PlusBase from '@/ui/components/devices/mpc/base/PlusBase.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import ShowEventDotBaseHTTP from '@/ui/components/devices/mpc/base/ShowEventDotBase.vue';
import OutputFieldBaseHTTP from '@/ui/components/devices/mpc/base/OutputFieldBase.vue';
import InputFieldExternal from '@/ui/components/devices/components/InputFields/InputFieldExternal.vue';
import SettingsCharts from '@/ui/components/devices/mpc/HeatingCircuitOptimization/SettingsCharts/index.vue';
import ShowEventBase from '@/ui/components/devices/devices/base/ShowEventBase.vue';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';

/**
 * Component that represent HeatingCircuitOptimization MPC device
 */
@Component({
  components: {
    SettingsCharts,
    ShowEventBase,
    DeviceActions,
    SettingsView,
    MinusBase,
    PlusBase,
    ShowEventDotBase,
    ShowEventDotBaseHTTP,
    OutputFieldBaseHTTP,
    InputFieldExternal,
    MPCLayout,
  },
})
export default class HeatingCircuitOptimization extends mixins(Validation) {
  @Prop() deviceData!: any;
  @Action('mpc/fetchMPCData') fetchMPCData!: any;
  @Action('mpc/updateMPCTiming') updateMPCTiming!: any;
  @Mutation('app/setReport') setReport!: any;

  isSettingsView = false
  mpc: any = null
  actualValueState: any = null
  isLoweringMode = false
  // default values for modes
  loweringModeStarts: any = {
    hours: '22',
    minutes: '0',
  }
  loweringModeEnds: any = {
    hours: '6',
    minutes: '0',
  }
  loweringModeActive = false
  normalMode: any = null
  loweringMode: any = null
  energySavingMode = null

  serviceOffTemperature: any = 15

  tab: any = null

  get energySavingModesList() {
    const t: any = this.$t('mlModel.HeatingCircuitOptimization.settingsView.energySavingModesList');
    return [
      { title: t.comfort, value: 0 },
      { title: t.balanced, value: 1 },
      { title: t.energySaving, value: 2 },
    ];
  }

  get loweringModeTabs() {
    const locale = this.$t(`${this.langPath}.settingsView.loweringModeTabs`);
    return [
      { locale },
      { locale },
      { locale },
    ];
  }

  get langPath() {
    return `mlModel.${this.deviceData.data.type}`;
  }

  get btnValidation() {
    const minMax = this.loweringMode < 5 || this.loweringMode > 30;
    const pattern = /^\-?\d+(?:\,|.\d{1,2})?$/;
    const lowMin = this.loweringModeStarts.minutes < 0 || this.loweringModeStarts.minutes > 59;
    const normMin = this.loweringModeEnds.minutes < 0 || this.loweringModeEnds.minutes > 59;
    const lowHours = this.loweringModeStarts.hours < 0 || this.loweringModeStarts.hours > 23;
    const normHours = this.loweringModeEnds.hours < 0 || this.loweringModeEnds.hours > 23;
    const serviceOff = this.serviceOffTemperature < 15 || this.serviceOffTemperature > 20;
    return minMax || !pattern.test(this.loweringMode) || lowMin || lowHours || normHours || normMin || serviceOff;
  }

  get mpcId() {
    if (this.deviceData) return this.deviceData.id;
    return null;
  }

  get heating() {
    return this.deviceData?.data?.meta?.controllerMappings?.newValvePosition;
  }
  get ready() {
    return this.deviceData?.data?.meta?.controllerMappings?.mpcReady;
  }

  get errorWarningMqtt() {
    return {
      ShowEvent_errorWarningState: this.deviceData?.data?.meta?.controllerMappings?.errorWarning,
    };
  }
  get heatingMqtt() {
    return {
      ShowEventDot1_errorWarningState: this.heating,
    };
  }
  get readyMqtt() {
    return {
      ShowEventDot2_errorWarningState: this.ready,
    };
  }

  get serviceTooWarm() {
    return this.mpc?.data?.meta?.too_warm;
  }

  async setActualState() {
    const { lowering_mode_active } = this.deviceData.data.meta.timing;
    if (typeof lowering_mode_active === 'boolean' && lowering_mode_active === false) {
      this.actualValueState = this.normalMode;
    } else {
      this.actualValueState = !this.isLoweringMode ? this.normalMode : this.loweringMode;
    }

    // this.actualValueState = !this.isLoweringMode ? this.normalMode : this.loweringMode;
  }
  async initTimings() {
    this.normalMode = this.deviceData.data.meta.timing.high_set_temperature;
    this.loweringMode = this.deviceData.data.meta.timing.low_set_temperature;
    this.serviceOffTemperature = this.deviceData.data.meta.timing.stop_heating;
    this.loweringModeStarts.hours = this.deviceData.data.meta.timing.low_set_temperature_start;
    this.loweringModeStarts.minutes = this.deviceData.data.meta.timing.low_set_temperature_start_min;
    this.loweringModeEnds.hours = this.deviceData.data.meta.timing.high_set_temperature_start;
    this.loweringModeEnds.minutes = this.deviceData.data.meta.timing.high_set_temperature_start_min;
    this.loweringModeActive = this.deviceData.data.meta.timing.lowering_mode_active;
    this.energySavingMode = this.deviceData.data.meta.timing.energy_type;
  }
  async initLoweringModeActive() {
    this.loweringModeActive = this.deviceData.data.meta.timing.lowering_mode_active;
  }
  async defineLoweringMode() {
    const currentHours = new Date().getHours();
    const currentMinutes = new Date().getMinutes();
    const isLoweringHoursEqual = currentHours === +this.loweringModeStarts.hours;
    const isLoweringHours = currentHours > +this.loweringModeStarts.hours;
    const isLoweringMinutes = currentMinutes >= +this.loweringModeStarts.minutes;
    const isStart = () => {
      if (isLoweringHours) return true;
      if (isLoweringHoursEqual) return isLoweringMinutes;
      return false;
    };
    // console.log('isLoweringStarts: ', isStart());
    const isLoweringHoursEqual2 = currentHours === +this.loweringModeEnds.hours;
    const isLoweringHours2 = currentHours < +this.loweringModeEnds.hours;
    const isLoweringMinutes2 = currentMinutes <= +this.loweringModeEnds.minutes;
    const isEnd = () => {
      if (isLoweringHours2) return false;
      if (isLoweringHoursEqual2) return !isLoweringMinutes2;
      return true;
    };
    // console.log('isLoweringEnds: ', isEnd());
    if ((!isStart() && !isEnd()) || (isStart() && !isEnd()) || (isStart() && isEnd())) this.isLoweringMode = true;
    else this.isLoweringMode = false;
  }

  async sendModeSettings() {
    const { high_set_temperature, low_set_temperature, lowering_mode_active } = this.deviceData.data.meta.timing;

    const newTiming = {
      high_set_temperature,
      high_set_temperature_start: +this.loweringModeEnds.hours,
      high_set_temperature_start_min: +this.loweringModeEnds.minutes,
      low_set_temperature: +this.loweringMode,
      low_set_temperature_start: +this.loweringModeStarts.hours,
      low_set_temperature_start_min: +this.loweringModeStarts.minutes,
      lowering_mode_active,
      stop_heating: +this.serviceOffTemperature,
    };
    const copy = JSON.parse(JSON.stringify(this.deviceData));
    copy.data.meta.timing = newTiming;
    await this.updateMPCTiming(copy);
    await this.defineLoweringMode();
    await this.setActualState();
  }

  async handleLoweringModeActive(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    const copy = JSON.parse(JSON.stringify(this.deviceData));
    copy.data.meta.timing = { ...copy.data.meta.timing, lowering_mode_active: !this.loweringModeActive };
    await this.updateMPCTiming(copy);
    await this.initLoweringModeActive();
    await this.setActualState();
  }
  async handleEnergySavingMode(e: MouseEvent) {
    const copy = JSON.parse(JSON.stringify(this.deviceData));
    copy.data.meta.timing = { ...copy.data.meta.timing, energy_type: this.energySavingMode };
    await this.updateMPCTiming(copy);
    await this.initLoweringModeActive();
    await this.setActualState();
  }

  async handleMinus() {
    if (this.actualValueState <= 5) return;
    this.actualValueState -= 0.5;
    const copy = JSON.parse(JSON.stringify(this.deviceData));

    if (!this.isLoweringMode) {
      this.normalMode = this.actualValueState;
      copy.data.meta.timing = { ...copy.data.meta.timing, ...{ high_set_temperature: this.actualValueState } };
      await this.updateMPCTiming(copy);
      this.setReport({
        type: 'success',
        message: this.$t('mlModel.HeatingCircuitOptimization.mainView.handleMinusMessage'),
        value: true,
      });
    } else {
      this.loweringMode = this.actualValueState;
      copy.data.meta.timing = { ...copy.data.meta.timing, ...{ low_set_temperature: this.actualValueState } };
      await this.updateMPCTiming(copy);
      this.setReport({
        type: 'success',
        message: this.$t('mlModel.HeatingCircuitOptimization.mainView.handleMinusMessage'),
        value: true,
      });
    }
  }
  handlePlus() {
    if (this.actualValueState >= 30) return;
    this.actualValueState += 0.5;
    const copy = JSON.parse(JSON.stringify(this.deviceData));

    if (!this.isLoweringMode) {
      this.normalMode = this.actualValueState;
      copy.data.meta.timing = { ...copy.data.meta.timing, ...{ high_set_temperature: this.actualValueState } };
      this.updateMPCTiming(copy);
    } else {
      this.loweringMode = this.actualValueState;
      copy.data.meta.timing = { ...copy.data.meta.timing, ...{ low_set_temperature: this.actualValueState } };
      this.updateMPCTiming(copy);
    }
  }

  switchSettingsView(setting: boolean) {
    this.isSettingsView = setting;
  }

  async mounted() {
    this.mpc = await this.fetchMPCData(this.deviceData.id);
    // console.log(`HCO (${this.deviceData.name}): `, this.mpc);
    await this.initTimings();
    await this.defineLoweringMode();
    await this.setActualState();
  }
}
