
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import DaySelectionModal from '@/ui/components/partner/components/DayRangeSelectionModal.vue';
import { Validation } from '@/ui/mixins/validation';
import ManageTask from '@/ui/components/modals/Partner/ManageTask.vue';
import TaskTableRow from '@/ui/components/modals/Partner/TasksTable/TaskTableRow.vue';
import { IPartnerWorkspace, ITasksObject } from '@/types/partnerWorkspace.types';
import { formatIsoDate } from '@/utils/utilsFunctions';
import { Action, Getter } from 'vuex-class';
import draggable from 'vuedraggable';

@Component({
  methods: { formatIsoDate },
  components: {
    ModalWindow,
    DaySelectionModal,
    ManageTask,
    TaskTableRow,
    draggable,
  },
})
export default class TasksTable extends mixins(Validation) {
  @Prop({}) tasks!: ITasksObject[];
  @Getter('partnerWorkspaces/workspace') workspace!: IPartnerWorkspace;
  @Action('partnerWorkspaces/updateWorkspace') updateWorkspace!: (data: {project_id: string; workspace: IPartnerWorkspace; showMessage: boolean}) => Promise<void>;

  currentTaskList: ITasksObject[] = [];
  rerenderKey = false;
  editId = ''; // will contain the id of the task as long as it is edited

  get headers() {
    return [
      { text: 'Name', value: 'name', width: '20%' },
      { text: 'Dependencies', value: 'dependencies', width: '35%' },
      { text: 'Start', value: 'start', width: '20%' },
      { text: 'End', value: 'end', width: '20%' },
      { text: 'Actions', value: 'actions', width: '5%' },
    ];
  }

  /**
   * Callback function for the draggable component
   * @param event event object
   */
  onDropCallback(event: { oldIndex: number; newIndex: number }) {
    const { oldIndex, newIndex } = event;
    const movedTask = this.currentTaskList[oldIndex];
    this.currentTaskList.splice(oldIndex, 1);
    this.currentTaskList.splice(newIndex, 0, movedTask);
    this.saveTaskChanges();
  }

  // deletes the task from array and from backend
  handleDelete(id: string) {
    const index = this.currentTaskList.findIndex((task) => task.id === id);
    this.currentTaskList.splice(index, 1);
    this.removeTaskFromDependencies(id);
    this.saveTaskChanges();
  }

  // Checks all tasks and removes the given task id from the dependencies
  removeTaskFromDependencies(id: string) {
    this.currentTaskList.forEach((task) => {
      const index = task.dependencies.findIndex((dependency) => dependency === id);
      if (index !== -1) {
        task.dependencies.splice(index, 1);
      }
    });
  }

  handleTaskCreated(task: ITasksObject) {
    this.currentTaskList.push(task);
    this.rerenderKey = !this.rerenderKey; // trigger rerender of table
    this.saveTaskChanges();
  }

  handleTaskUpdated(task: ITasksObject) {
    const index = this.currentTaskList.findIndex((t) => t.id === this.editId); // find index of task to be updated
    this.currentTaskList.splice(index, 1, task); // replace old task with new task
    this.rerenderKey = !this.rerenderKey; // trigger rerender of table
    if (this.editId !== task.id) {
      this.updateDependenciesWithNewID(this.editId, task.id); // update dependencies with new id
    }
    this.editId = ''; // reset edit id
    this.saveTaskChanges();
  }

  // Checks all tasks and updates the given task id with the new id
  // This is needed when a task is edited and the id changes
  updateDependenciesWithNewID(oldId: string, newId: string) {
    this.currentTaskList.forEach((task) => {
      const index = task.dependencies.findIndex((dependency) => dependency === oldId);
      if (index !== -1) {
        task.dependencies.splice(index, 1, newId);
      }
    });
  }

  // save changes in backend
  saveTaskChanges() {
    const workspaceCopy = { ...this.workspace };
    workspaceCopy.meta.tasks = this.currentTaskList;
    this.updateWorkspace({ project_id: this.workspace.id, workspace: workspaceCopy, showMessage: false });
  }

  async onDialogStatusChange(status: boolean) {
    if (!status) {
      // emit close event to trigger refresh of gantt chart
      this.$emit('windowClosed', this.currentTaskList);
    }
  }

  created() {
    this.currentTaskList = [...this.tasks];
  }
}
