import api from '@/services/api';

export default {
  async fetchDay(
    mpcId: string,
    start: { year: number; month: number; date: number },
  ) {
    return api.fetchMPC(`/${mpcId}/energy/${start.year}/${start.month}/${start.date}`, 'GET');
  },

  async fetchWeek(
    mpcId: string,
    start: { year: number; month: number; date: number },
    end: { year: number; month: number; date: number },
  ) {
    return api.fetchMPC(`/${mpcId}/energy/start/${start.year}/${start.month}/${start.date}/end/${end.year}/${end.month}/${end.date}`, 'GET');
  },

  async fetchMonth(
    mpcId: string,
    start: { year: number; month: number },
  ) {
    return api.fetchMPC(`/${mpcId}/energy/${start.year}/${start.month}`, 'GET');
  },

  async fetchYear(
    mpcId: string,
    start: { year: number },
  ) {
    return api.fetchMPC(`/${mpcId}/energy/${start.year}`, 'GET');
  },
};
