
import { Component, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { has } from 'lodash';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import {
  energyCircleIconForType,
  EnergyCircleType,
  isSystemWithAlternativeValue,
} from '@/types/energyVisualisation/EnergyCircleType';
import {
  EnergyCircleDisplayData,
} from '@/types/energyVisualisation/EnergyCircleDisplayData';
import SystemDialog
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/SystemDialog.vue';
import SystemDialogView
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/SystemDialogView.vue';
import {
  getDefaultArrowDirection,
  getDefaultLineColors,
  getDefaultSpinnerColor,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyVisualisationUtils';
import {
  EnergyLineDisplayData,
} from '@/types/energyVisualisation/EnergyLineDisplayData';
import ActualViewSystemSettingsDialog
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/ActualView/ActualViewSystemSettingsDialog.vue';
import {
  externalEnergyMeasurementsSelected,
  SYSTEMS_WHERE_SETTINGS_WINDOW_ACTIVE,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/systemDialog';
import { IDevice } from '@/types/devices.types';
import {
  energyGreyColors,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/constants';

/**
 * Actual view for EnergyView/EMS
 */
@Component({
  components: {
    SystemDialog,
    SystemDialogView,
    ActualViewSystemSettingsDialog,
  },
})
export default class ActualViewSystemDialog extends mixins(EnergyComponent) {
  // whether the dialog is currently shown (v-model)
  @Prop() value!: boolean;
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop() openedSystem!: EnergyCircleType;
  @Prop({ default: () => [] }) devicesWithDynamicBottomValue!: string;
  @Prop({ default: false }) isHybridSystem!: boolean; // if the system is a hybrid system (normal components and pv components)
  @Prop() actualViewCiclesDisplayData!: EnergyCircleDisplayData[];

  showSystemSettingsDialog = false;
  openedSettingsSystemData?: EnergyCircleDisplayData;
  previousHouseValue = -1;

  @Provide('onSingleCircleClick') onSingleCircleClick = this.onSingleCircleClickCallback;
  onSingleCircleClickCallback(circleData: EnergyCircleDisplayData) {
    const isSettingsActive = SYSTEMS_WHERE_SETTINGS_WINDOW_ACTIVE.includes(this.deviceData.data.type);
    const isExternalEnergyMeasurements = externalEnergyMeasurementsSelected({
      system: this.openedSystem,
      systemInstance: circleData.id,
      device: this.deviceData,
    });
    if (!isSettingsActive || isExternalEnergyMeasurements) return;

    this.showSystemSettingsDialog = true;
    this.openedSettingsSystemData = circleData;
  }

  @Provide('onDialogLineAnimationEnd') onDialogLineAnimationEnd =
    this.onDialogLineAnimationEndCallback;
  onDialogLineAnimationEndCallback(id: string) {
    if (has(this.electricHeatingInstancesAnimationStatus, id)) {
      this.electricHeatingInstancesAnimationStatus[id] = !this.electricHeatingInstancesAnimationStatus[id];
    } else {
      this.$set(this.electricHeatingInstancesAnimationStatus, id, false);
    }
  }
  electricHeatingInstancesAnimationStatus: Record<string, boolean> = {};

  get systemDialogCircles(): EnergyCircleDisplayData[] {
    if (!this.openedSystem) return [];

    const systems = this.getSystemComponents(this.deviceData, this.openedSystem);
    return Object.entries(systems).map(([key, systemComponent]) => {
      let bottomValue: any;
      let unit;
      let bottomDynamicValue;
      let disableSpinner;
      let additionalContent: any = {};

      if (this.openedSystem === EnergyCircleType.house) {
        const houseSystemCount = this.getSystemCount(this.deviceData, EnergyCircleType.house);
        // get display data for house
        const houseCircleData = this.actualViewCiclesDisplayData.find(
          (circleData: EnergyCircleDisplayData) => circleData.id === EnergyCircleType.house,
        );
        if (this.previousHouseValue < 0 && houseSystemCount === 1) {
          // if previous value is negative (only initialy) use calculated value (positive and negative)
          bottomValue = houseCircleData?.bottomValue;
          // if there is a hybrid system and the house value is negative, show the loading animation until the house value is positive or 0
          additionalContent = {
            showLoadingAnimationHouseCircle: this.isHybridSystem && bottomValue < 0 && this.previousHouseValue < 0,
          };
        } else if (this.previousHouseValue >= 0 && houseSystemCount === 1) {
          // if the previous value is positive and there is a hybrid system, show 0 else show the calculated value (if positive)
          bottomValue = (houseCircleData?.bottomValue as number) < 0 ? this.previousHouseValue : houseCircleData?.bottomValue;
          additionalContent = {
            showLoadingAnimationHouseCircle: false,
          };
        } else {
          // if there is no hybrid system, show the normal house value from plc
          bottomValue = this.getHouseValue(
            this.measurements[systemComponent.power],
            this.sumOfAllEnabledExternalMeasurementsSystemsValues,
          );
        }
        if ((bottomValue as number) < 0) {
          // if the house value is negative, show 0 or the previous value if it was positive
          if (this.previousHouseValue < 0) {
            bottomValue = 0;
          } else {
            bottomValue = this.previousHouseValue;
          }
        }
        const defaultHouseValue = this.getHouseValue(
          this.measurements[systemComponent.power],
          this.sumOfAllEnabledExternalMeasurementsSystemsValues,
        );
        this.previousHouseValue = bottomValue;
        if (!this.isHybridSystem) {
          // if there is no hybrid system we never show the loading animation
          additionalContent = {
            showLoadingAnimationHouseCircle: false,
          };
        }
      } else if (this.openedSystem === EnergyCircleType.electric_heating) {
        if (this.devicesWithDynamicBottomValue.includes(this.deviceData.data.type)) {
          const { v, u, isTemperature } = this.getElectricHeatingValue({
            power: this.measurements[systemComponent.power],
            temperature: this.measurements[systemComponent.temperature],
            status: this.electricHeatingInstancesAnimationStatus?.[key],
          });
          bottomValue = v;
          unit = u;
          bottomDynamicValue = isTemperature;
          const power = this.measurements[systemComponent.power];

          // if the power is negative and the temperature is visible, disable the spinner
          disableSpinner = isTemperature && power <= 0;
        } else {
          bottomValue = this.measurements[systemComponent.power];
        }
      } else {
        bottomValue = this.measurements[systemComponent.power];
      }

      // consumers (except house) look on target_power, if power is not mapped, for arrow animation
      let alternativeValue;
      if (isSystemWithAlternativeValue(this.openedSystem) && !bottomValue) {
        alternativeValue = this.measurements[systemComponent.target_power];
      }

      let currentSpinnerColor = getDefaultSpinnerColor(this.openedSystem, bottomValue ?? alternativeValue);
      if (this.openedSystem === EnergyCircleType.electric_heating && this.devicesWithDynamicBottomValue.includes(this.deviceData.data.type) && !disableSpinner) {
        // if the boiler temperature is visible, the spinner color is dependent on the power value not the temperature value
        // we then also force the animations to be visible
        const power: any = this.measurements[systemComponent.power];
        currentSpinnerColor = getDefaultSpinnerColor(this.openedSystem, power);
        additionalContent.forceAnimation = power > 0;
      } else {
        additionalContent.forceAnimation = false;
      }

      if ((bottomValue as number) >= 0.01 && (bottomValue as number) < 0.1) {
        // if value is between 0.01 and 0.1, but the bottom value is 0, show 0.1
        bottomValue = 0.1;
      }

      return {
        id: key,
        centerContent:
          this.openedSystem === EnergyCircleType.battery
            ? { type: 'soc', value: this.measurements[systemComponent.soc] }
            : { type: 'icon', value: energyCircleIconForType(this.openedSystem) },
        bottomValue,
        errorWarningMode: this.measurements[systemComponent.error],
        title: systemComponent.title,
        spinnerColor: currentSpinnerColor,
        alternativeValue,
        unit,
        bottomDynamicValue,
        disableSpinner,
        additionalDisplayData: additionalContent,
      } as EnergyCircleDisplayData;
    });
  }

  get systemDialogLines(): Partial<EnergyLineDisplayData>[] {
    if (!this.openedSystem) return [];

    return this.systemDialogCircles.map(
      (displayData: EnergyCircleDisplayData) => {
        let arrowDirection;
        let colors;

        // if the temperature is visible and the power is negative, the arrow should not be visible else it should be visible (currently only for electric_heating systems)
        if (this.openedSystem === EnergyCircleType.electric_heating && displayData.bottomDynamicValue === true && displayData.disableSpinner) {
          arrowDirection = 0;
          colors = energyGreyColors;
        } else {
          arrowDirection = getDefaultArrowDirection(this.openedSystem, displayData);
          colors = getDefaultLineColors(this.openedSystem, displayData);
        }

        return {
          id: displayData.id,
          arrowDirection,
          colors,
        } as Partial<EnergyLineDisplayData>;
      },
    );
  }

  beforeDestroy() {
    this.electricHeatingInstancesAnimationStatus = {};
  }
}
