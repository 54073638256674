import { Map } from 'immutable';
import ProjectService from '@/services/ProjectService';
import WeatherService from '@/services/WeatherService';
import AssetService from '@/services/AssetService';
import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/store/types';
import { IAppState } from './types';
import i18n from '@/ui/plugins/i18n';
import { IUser } from '@/types/app.types';

const state: IAppState = {
  user: {
    email: '',
    first_name: '',
    last_name: '',
    id: '',
    super_admin: false,
  },
  weatherData: null,
  reportBox: {
    message: '',
    type: '',
    value: false,
    shouldShow: true,
    timeout: 2000,
  },
  workbenchCurrentTab: 0,
};

const getters: GetterTree<IAppState, RootState> = {
  getReportMessageAndType(state) {
    return state.reportBox;
  },
  getUser(state) {
    return state.user;
  },
};

const mutations: MutationTree<IAppState> = {
  setReport(state, payload) {
    state.reportBox = { ...state.reportBox, ...payload };
  },
  setWeatherData(state, payload) {
    state.weatherData = payload;
  },
  setUser(state, user: IUser) {
    state.user = user;
  },
  setWorkbenchTab(state, payload) {
    state.workbenchCurrentTab = payload;
  },
};

const actions: ActionTree<IAppState, RootState> = {
  /**
   * Load weather data
   * @param commit
   * @param location location data. Example: { display_name: name, lat: lat, lon: lon }
   */
  async fetchWeather({ commit }, location) {
    if (!location || !Object.values(location).length) {
      commit('setWeatherData', null);
      return;
    }
    const { lat, lon } = location;
    await WeatherService.fetchWeather({ lat, lon })
      .then(res => {
        commit('setWeatherData', res);
      });
  },

  /**
   * Clear store when leave project
   * @param state
   * @param rootState
   */
  async clear({ state, rootState }) {
    rootState.projects.projectId = null;
    rootState.projects.project = {};
    rootState.projects.mqttInfo = {};
    rootState.measurements.measurements = Map();
    rootState.devices.devices = Map();
    rootState.rooms.rooms = Map();
    state.weatherData = null;
    rootState.mpc.mpcControllers = Map();
    rootState.members.currentMember = null;
    rootState.rules.rulesList = [];
    rootState.events.eventList = [];
    rootState.members.members = Map();
  },

  /**
   * Load files
   * @param commit
   * @param rootState
   */
  async loadFiles({ commit, rootState }) {
    try {
      return await ProjectService.fetchProjectDocuments(rootState.projects.projectId as string);
    } catch (e) {
      commit('setReport', {
        type: 'error',
        message: e.message,
        value: true,
      });
    }
  },

  /**
   * Remove selected file
   * @param commit
   * @param rootState
   * @param {string} fileId file id
   */
  async deleteFile({ commit, rootState }, fileId) {
    await ProjectService.deleteProjectDocument(rootState.projects.projectId as string, fileId);
    commit('setReport', {
      type: 'success',
      message: 'File has been deleted',
      value: true,
    });
  },

  /**
   * Upload file
   * @param commit
   * @param state
   * @param {File} asset file object
   */
  async postAsset({ commit, state }, asset: any) {
    try {
      if (!asset || !asset.name) return;
      const data = new FormData();
      data.append('file', new Blob([asset]));
      return await AssetService.saveAsset(data);
    } catch (e) {
      const handledErrors = [413];
      if (handledErrors.includes((e as any).response.status)) {
        commit('setReport', {
          type: 'error',
          message: i18n.t(`errorMessages.images.${e.response.status}`),
          value: true,
        });
      } else {
        commit('setReport', {
          type: 'error',
          message: i18n.t('errorMessages.images.uploadFailed'),
          value: true,
        });
      }
    }
  },
};

export const app: Module<IAppState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
