
import { Vue, Component } from 'vue-property-decorator';
import DeviceDescription from '@/ui/components/devices/actions/DeviceDescription.vue';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import PreviewActions from '@/ui/components/devices/actions/PreviewActions.vue';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import PushButtonMock from '@/ui/components/devices/previews/components/PushButtonMock.vue';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';

@Component({
  components: {
    ShowEventDotMock,
    PushButtonMock,
    InputFieldMock,
    DeviceDescription,
    PreviewActions,
    SwitchMock,
  },
})
export default class SurveyClientPreview extends Vue {
  stateFieldname = 'Test';
  itemsTypes = [
    { name: 'Type 1', value: 0 },
    { name: 'Type 2', value: 1 },
  ];
  selected= 0;
  redColorVar = 'red'

  get description() {
    return this.$t('devicesDescriptions.SurveyClient');
  }
  get previewNameLang() {
    return this.$t('devices.SurveyClient.previewName');
  }
}
