
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Email action component.
 * Manages emails.
 */
@Component
export default class EmailAction extends Vue {
  @Prop({ default: {} }) params: any;
  @Prop({ default: false }) enableNoRecipients!: boolean;

  subjectRule = [
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.subjectRule'),
  ]
  bodyRule = [
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.bodyRule'),
  ]

  deleteRecipient(index: number) {
    this.params.recipients.splice(index, 1);
    this.$set(this.params, 'recipients', this.params.recipients);
  }
  addRecipient() {
    if (!this.params.recipients) {
      this.$set(this.params, 'recipients', []);
    }
    this.params.recipients.push('mail@example.com');
    this.$set(this.params, 'recipients', this.params.recipients);
  }
}
