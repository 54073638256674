
import { Component, Prop } from 'vue-property-decorator';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import SliderBase from '@/ui/components/devices/devices/base/SliderBase.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import Switch2VBase from '@/ui/components/devices/devices/base/Switch2VBase.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';

/**
 * Big consumer single system settings.
 */
@Component({
  components: {
    InputFieldBase,
    OutputFieldBase,
    SliderBase,
    ShowEventDotBase,
    Switch2VBase,
    LabelUnitWrapper,
  },
})
export default class BigConsumerSystemSettings extends mixins(Validation) {
  @Prop({ default: null }) instanceData!: any;
  @State('measurements') measurementsState!: IVariablesState;

  get measurements() {
    return this.measurementsState.measurements;
  }

  get sliderMappings() {
    return {
      'Slider_targetValue': this.instanceData.slider_manual,
    };
  }
  get sliderActualValue() {
    return {
      'OutputField_actualValue': this.instanceData.slider_target_power,
    };
  }
  get sliderActualValueState() {
    return this.measurements.get(this.instanceData.slider_target_power);
  }
  get sliderIsActive() {
    return true;
  }

  get outputFieldActualPower() {
    return {
      'OutputField_actualValue': this.instanceData.power,
    };
  }
  get showEventDotState() {
    return {
      'ShowEventDot_errorWarningState': this.instanceData.state_consumer,
    };
  }
  get switchManualOn() {
    return {
      'Switch2V_onOff': this.instanceData.switch_manual,
      'Switch2V_state': this.instanceData.state_manual,
    };
  }
  get switchOnEmergencyPowerOff() {
    return {
      'Switch2V_onOff': this.instanceData.switch_emergency,
      'Switch2V_state': this.instanceData.state_emergency,
    };
  }
  get switchEnable() {
    return {
      'Switch2V_onOff': this.instanceData.switch_enable,
      'Switch2V_state': this.instanceData.state_enable,
    };
  }
  get outputFieldTargetPower() {
    return {
      'OutputField_actualValue': this.instanceData.target_power,
    };
  }

  get inputFieldPriorityEMS() {
    return {
      'InputField_targetValue': this.instanceData.priority,
    };
  }
  get inputFieldEnableSoc() {
    return {
      'InputField_targetValue': this.instanceData.enable_soc,
    };
  }
  get inputFieldDisableSOC() {
    return {
      'InputField_targetValue': this.instanceData.disable_soc,
    };
  }
  get inputFieldDisableMaxPower() {
    return {
      'InputField_targetValue': this.instanceData.max_power,
    };
  }
  get switchP14A() {
    return {
      'Switch2V_onOff': this.instanceData.switch_p14a,
      'Switch2V_state': this.instanceData.state_p14a,
    };
  }
}
