
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    ComparisonCharts: () => import('@/ui/components/devices/charts/charts/ComparisonCharts.vue'),
  },
})
export default class CompareModal extends Vue {
  @Prop() deviceData!: any;
  @Prop() chartWidth!: number;
  @Prop({ default: 'day' }) currentPropInChart!: any;

  dialog = false;
  isTooltip = false;
  showModal = false;

  get currentPeriod() {
    return this.currentPropInChart;
  }
}
