
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Form field Date Picker Custom, give possibility to handle date
 */
@Component
export default class DatePickerCustom extends Vue {
  @Prop() value!: number; // Timestamp in seconds
  @Prop({ default: 'date' }) label!: string;
  @Prop() startingDate!: number; // Timestamp seconds
  @Prop() endDate!: number; // Timestamp seconds

  isCalendar = false

  get lastCalendarDate() {
    const [day, month, year] = new Date().toLocaleDateString('en-GB').split('/');
    return `${year}-${month}-${day}`;
  }

  cancelStartDate() {
    this.$emit('datePickerCustomClearDate');
    this.isCalendar = false;
  }
  acceptStartDate() {
    if (!this.value) return null;
    this.isCalendar = false;
  }

  /**
   * Converts seconds to date YYYY-MM-DD
   * @param seconds number of seconds
   * @return string date in format YYYY-MM-DD
   */
  convertToDate(seconds: number) {
    if (!seconds) return '';
    const [day, month, year] = new Date(seconds * 1000).toLocaleDateString('en-GB').split('/');
    return `${year}-${month}-${day}`;
  }

  /**
   * Converts date YYYY-MM-DD to seconds
   * @param date in format YYYY-MM-DD
   * @return date in seconds
   */
  convertToSeconds(date: string) {
    const [year, month, day] = date.split('-');
    return Date.UTC(+year, +month - 1, +day) / 1000;
  }
}
