
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { IAppState } from '@/store/modules/app/types';

@Component
export default class ToggleThemesButton extends Vue {
  @State('app') appState!: IAppState

  visible = true;

  get user() {
    return this.appState.user;
  }

  get isDark() {
    return this.$vuetify.theme.dark;
  }

  set isDark(value: boolean) {
    this.$vuetify.theme.dark = value;
    this.saveTheme();
  }

  get night() {
    return { opacity: this.isDark ? 1 : 0 };
  }

  get sunny() {
    return { opacity: this.isDark ? 0 : 1 };
  }

  saveTheme() {
    const currentLynusTheme: any = localStorage.getItem('lynusTheme');
    const theme = this.$vuetify.theme.dark ? 'dark' : 'light';
    const obj = { [this.user.id]: theme };
    const res = currentLynusTheme ? { ...JSON.parse(currentLynusTheme), ...obj } : { ...obj };
    localStorage.setItem('lynusTheme', JSON.stringify(res));
  }

  loadTheme() {
    const currentLynusTheme: any = localStorage.getItem('lynusTheme');
    if (currentLynusTheme) {
      const theme = JSON.parse(currentLynusTheme)[this.user.id];
      if (theme) {
        this.$vuetify.theme.dark = theme === 'dark';
      }
    }
  }

  get alignment() {
    return { left: this.isDark ? '0px' : '28px' };
  }

  toggleTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    this.saveTheme();
  }

  mounted() {
    this.loadTheme();
  }
}
