
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent device preview description
 */
@Component
export default class DeviceDescription extends Vue {
  @Prop() description: any;
  @Prop({ default: false }) isOverviewCentered!: any;

  dialog = false
}
