
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import GeneralSettingsEMS from '@/ui/components/devices/components/EnergyParts/EMS/components/settings/GeneralSettingsEMS.vue';
import ModeSettings from '@/ui/components/devices/components/EnergyParts/EMS/components/settings/ModeSettings.vue';

/**
 * Component that represent EMS settings view.
 * Contains navigation tabs. EMS settings and Mode settings.
 */
@Component({
  components: {
    ModeSettings,
    GeneralSettingsEMS,
  },
})
export default class SettingsEMS extends mixins(Validation) {
  @Prop({ default: null }) mpcData!: any; // data was loaded from mpc/fetchMPCData (loads full mpc instance data by id)
  @Prop({ default: null }) deviceData!: any; // data was loaded from mpc/fetchMPCListByProject (loads a list of mps but with incomplete data)
  tab = null;

  get tabs() {
    return [
      {
        title: this.$t('mlModel.EMS.settingsView.tabs.emsSettings'),
      },
      {
        title: this.$t('mlModel.EMS.settingsView.tabs.modeSettings'),
      },
    ];
  }
}
