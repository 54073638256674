var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DeviceLayout',{attrs:{"device-data":_vm.deviceData,"device-icon":"electric-charging-station"},scopedSlots:_vm._u([{key:"basic-controls",fn:function(){return [_c('div',{staticClass:"electric-charging-station"},[_c('LabelUnitWrapper',{attrs:{"disabled":!_vm.isActualValueVariableFilled},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('OutputFieldBase',{attrs:{"variable-data":_vm.deviceData.data.mappings,"instance":"OutputField1","min":0,"max":1000,"is-decimal":true}}),_c('div',{staticClass:"pl-2"},[_vm._v("kW")])]},proxy:true}])}),_c('div',{staticClass:"d-flex show-event-section align-center"},[_c('ShowEventDotBase',{attrs:{"variable-data":_vm.deviceData.data.mappings,"instance":"ShowEventDot","width":"15","height":"15"}}),_c('LabelUnitWrapper',{attrs:{"variables-list-for-check":[_vm.deviceData.data.mappings.ShowEventDot_errorWarningState]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t(`${_vm.langPath}.mainView.connected`)))])]},proxy:true}])})],1)],1)]},proxy:true},{key:"settings-view",fn:function(){return [_c('div',{staticClass:"electric-charging-station-settings"},[_c('LabelUnitWrapper',{attrs:{"variables-list-for-check":[
          _vm.deviceData.data.mappings.Switch1_on,
          _vm.deviceData.data.mappings.Switch1_off,
          _vm.deviceData.data.mappings.Switch1_state,
        ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t(`${_vm.langPath}.settingsView.minCharge`)))]},proxy:true},{key:"value",fn:function(){return [_c('SwitchBase',{staticClass:"py-0 my-0",staticStyle:{"margin-left":"10px"},attrs:{"variable-data":_vm.deviceData.data.mappings,"instance":"Switch1"}})]},proxy:true}])}),_c('LabelUnitWrapper',{staticClass:"pt-2",attrs:{"variables-list-for-check":[
          _vm.deviceData.data.mappings.Switch2_on,
          _vm.deviceData.data.mappings.Switch2_off,
          _vm.deviceData.data.mappings.Switch2_state,
        ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t(`${_vm.langPath}.settingsView.manual`)))]},proxy:true},{key:"value",fn:function(){return [_c('SwitchBase',{staticClass:"py-0 my-0",staticStyle:{"margin-left":"10px"},attrs:{"variable-data":_vm.deviceData.data.mappings,"instance":"Switch2"}})]},proxy:true}])}),_c('LabelUnitWrapper',{attrs:{"variables-list-for-check":[_vm.deviceData.data.mappings.OutputField2_actualValue]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.$t(`${_vm.langPath}.settingsView.chargingTime`)[0]))]),_c('OutputFieldBase',{attrs:{"variable-data":_vm.deviceData.data.mappings,"instance":"OutputField2","min":0,"max":2000,"is-decimal":false}}),_c('div',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.$t(`${_vm.langPath}.settingsView.chargingTime`)[1]))])],1)]},proxy:true}])}),_c('InputFieldBase',{staticClass:"pt-2",attrs:{"variable-data":_vm.deviceData.data.mappings,"instance":"InputField","step":0.01,"min":0,"max":1000,"field-rules":[
              _vm.rules.required,
              _vm.rules.twoCommas,
              ],"input-field-styles":{ flexGrow: '1' },"unit-styles":{ width: '100px' },"is-decimal":true},scopedSlots:_vm._u([{key:"unit",fn:function(){return [_vm._v("kW")]},proxy:true}])}),_c('LabelUnitWrapper',{staticClass:"pt-2",attrs:{"variables-list-for-check":[_vm.deviceData.data.mappings.Slider_targetValue, _vm.deviceData.data.mappings.OutputField_actualValue],"view":"column"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"text-center switch-field-text",staticStyle:{"line-height":"1"}},[_vm._v(" "+_vm._s(_vm.$t('devices.ElectricChargingStation.settingsView.sliderLabel'))+" ")])]},proxy:true},{key:"value",fn:function(){return [_c('SliderBase',{attrs:{"variable-data":_vm.deviceData.data.mappings,"instance":"Slider","is-active":true,"state":_vm.sliderActualValue,"sending-timeout":250}})]},proxy:true}])})],1)]},proxy:true},{key:"dnd",fn:function(){return [_vm._t("dnd")]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }