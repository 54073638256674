
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import VueI18n from 'vue-i18n';
import { Action } from 'vuex-class';
import { IMQTTVariable } from '@/types/wizards/installationWizard.types';

/**
 * Modal for when a route is about to change and needs confirmation
 */
@Component
export default class LeaveConfirmationModal extends Vue {
  @Prop({ default: '' }) pageAboutToLeave?: string | VueI18n.TranslateResult;
  @Prop() additionalInformation!: string | VueI18n.TranslateResult;
  @Prop({ default: false }) sendWizardVariables!: boolean;
  @Action('installationWizard/sendVariables') sendVariables!: (variablesToSend: IMQTTVariable[]) => Promise<void>;

  dialog = false
  next = false
  localTo?: Route = undefined;
  localNext?: any = undefined;

  confirmAction(to?: Route, next?: any) {
    this.dialog = true;
    this.localTo = to;
    this.localNext = next;
  }

  handleRouteChange() {
    this.dialog = false;
    this.$router.push({ name: this.localTo?.name as string });
    this.$emit('handleRouteChange');
    this.localNext(true);
  }

  async handleApprove() {
    try {
      if (this.$route.name === 'InstallationWizard' && this.sendWizardVariables) {
        await this.sendVariables([{ variable: 'prgEMS.fbEMS.bEnable', value: 1, feedbackVariable: 'prgEMS.fbEMS.bStateEnable', isBoolean: true }]);
      }
      this.dialog = false;
      if (this.localTo) this.handleRouteChange();
      else this.$emit('handleRouteChange');
    } catch (error) {
      console.log(error);
    }
  }

  handleCancel() {
    this.dialog = false;
  }
  beforeDestroy() {
    this.next = false;
  }
}
