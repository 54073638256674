import { Vue, Component, Watch } from 'vue-property-decorator';
import { ILocales } from '@/types/common.types';
import { debounce } from 'lodash';

@Component
export class ReleasesNotes extends Vue {
  sleekScriptTag: HTMLScriptElement | null = null;
  initialLoadDone = false;
  debouncedLoadSleekplan: any;

  @Watch('$i18n.locale')
  async onLocaleChange(val: ILocales) {
    if (!this.isLynusLatestReleasesNotesSeen() && this.$route.meta?.displayChangelog !== false && this.initialLoadDone) {
      await this.clear();
      await this.debouncedLoadSleekplan(val);
    }
  }

  @Watch('$route', { deep: true })
  async onRouteChange(val: any) {
    const button = document.getElementById('sleek-button');
    if (!button) return;
    if (val.meta.displayChangelog === false) {
      // set button style display: none
      if (button) {
        button.style.display = 'none';
      }
    } else {
      // show button
      if (button) {
        button.style.display = 'block';
      }
    }
  }

  clear(): Promise<void> {
    return new Promise((resolve) => {
      if ((window as any)?.$sleek?.shutdown) {
        (window as any).$sleek.shutdown();
      }

      if (this.sleekScriptTag) {
        document.head.removeChild(this.sleekScriptTag);
        this.sleekScriptTag = null;
      }

      (window as any).$sleek = undefined;
      (window as any).sleek = undefined;
      (window as any).SLEEK_PRODUCT_ID = undefined;
      this.initialLoadDone = false;

      resolve();
    });
  }

  isLynusLatestReleasesNotesSeen(): boolean {
    const localVersion: string | undefined = process.env.VUE_APP_VERSION;
    const lynusLatestReleasesNotesSeen: any = localStorage.getItem('lynusLatestReleasesNotesSeen');

    if (!localVersion) return false;
    else return lynusLatestReleasesNotesSeen === String(localVersion);
  }

  getSleekplanId(locale: ILocales) {
    switch (locale) {
      case 'en': return '397729427';
      case 'de': return '135604594';
      case 'it': return '814476426';
      default: return undefined;
    }
  }

  onSleekClose() {
    // Save seen version in local storage
    localStorage.setItem('lynusLatestReleasesNotesSeen', String(process.env.VUE_APP_VERSION));
    // Remove sleekplan popup, button
    this.clear();
  }

  loadSleekplan(locale: ILocales): Promise<void> {
    return new Promise((resolve, reject) => {
      if ((window as any).$sleek) {
        resolve();
        return;
      }

      this.sleekScriptTag = document.createElement('script');
      this.sleekScriptTag.type = 'text/javascript';
      this.sleekScriptTag.async = true;
      this.sleekScriptTag.src = 'https://client.sleekplan.com/sdk/e.js';
      this.sleekScriptTag.onload = () => {
        this.initSleekplanListeners();
        resolve();
      };
      this.sleekScriptTag.onerror = () => {
        reject(new Error('Failed to load Sleekplan script'));
      };

      document.head.appendChild(this.sleekScriptTag);
      (window as any).$sleek = [];
      (window as any).SLEEK_PRODUCT_ID = this.getSleekplanId(locale);
      this.initialLoadDone = true;
    });
  }

  initSleekplanListeners() {
    if (this.sleekScriptTag === null) return;
    (window as any).$sleek.on('close', this.onSleekClose);
  }

  mounted() {
    if (!this.isLynusLatestReleasesNotesSeen() && this.$route.meta?.displayChangelog === true) {
      this.debouncedLoadSleekplan = debounce((val: any) => this.loadSleekplan(val), 500);
      setTimeout(() => this.debouncedLoadSleekplan(this.$i18n.locale as ILocales), 500);
    }
  }
}
