
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DataExport from '@/ui/components/devices/charts/components/DataExport.vue';
import stockInit from 'highcharts/modules/stock';
import fullscreen from 'highcharts/modules/full-screen';
import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';
import { roundNumber } from '@/utils/utilsFunctions';

stockInit(Highcharts);
fullscreen(Highcharts);

/**
 * Component that represent chart.
 * Used in MPC devices.
 */
@Component({
  components: {
    highcharts: Chart,
    DataExport,
  },
})
export default class BaseChart extends Vue {
  @Prop() chartType!: string;
  @Prop() chartTitle!: string;
  @Prop() chartVariables!: any;
  @Prop() series!: any;
  @Prop() yAxis!: any;
  @Prop({ default: null }) chartWidth!: any;
  @Prop({ default: 400 }) chartHeight!: any;

  get chartOptions() {
    return {
      chart: {
        animation: false,
        backgroundColor: this.$vuetify.theme.dark ? '#313131' : '#f2f2f2',
        width: null,
        height: null,
        events: {
          redraw: (e: any) => {
            this.handleFullScreenChartName(e);
          },
        },
      },
      exporting: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        split: false,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
        area: {
          marker: {
            enabled: false,
          },
        },
      },
      series: this.series,
      legend: {
        enabled: true,
        itemStyle: {
          color: this.$vuetify.theme.dark ? '#ffffff' : '#000000',
        },
      },
      time: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        useUTC: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        crosshair: {
          width: 0,
        },
      },
      yAxis: this.yAxis,
      loading: {
        labelStyle: {
          color: 'black',
        },
      },
    };
  }

  get isChartLoaded() {
    const seriesStatuses = this.series
      .map((s: any) => !!s.data.length)
      .filter((s: any) => s === true);
    return seriesStatuses.length;
  }

  @Watch('isChartLoaded')
  async onChartLoad(val: number) {
    if (val === 0) {
      await this.$nextTick();
      (this.$refs.hc as any).chart.showLoading('No data in selected period.');
    } else {
      await this.$nextTick();
      (this.$refs.hc as any).chart.hideLoading();
    }
  }

  handleFullScreen() {
    const { chart } = this.$refs.hc as any;
    chart.fullscreen.open();
  }
  handleFullScreenChartName(e: any) {
    const { chart } = this.$refs.hc as any;
    if (!chart?.fullscreen?.isOpen) {
      chart.setTitle({ text: null });
    } else {
      chart.setTitle({ text: this.chartTitle });
    }
  }

  round(num: any) {
    return roundNumber(num);
  }
}
