
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import Chart from '@/ui/components/devices/charts/charts/ChartDevice.vue';
import LightSwitch from '@/ui/components/devices/devices/LightSwitch.vue';
import LightDimmer from '@/ui/components/devices/devices/LightDimmer.vue';
import Temperature from '@/ui/components/devices/devices/Temperature.vue';
import VariableOutputField from '@/ui/components/devices/devices/VariableOutputField.vue';
import RoomsList from '@/ui/components/lists/RoomsList/index.vue';
import VariableInputField from '@/ui/components/devices/devices/VariableInputField.vue';
import VariableTextInputField from '@/ui/components/devices/devices/VariableTextInputField.vue';
import VariableTextOutputField from '@/ui/components/devices/devices/VariableTextOutputField.vue';
import LightRGB from '@/ui/components/devices/devices/LightRGB.vue';
import SocketSwitch from '@/ui/components/devices/devices/SocketSwitch.vue';
import LightPushButton from '@/ui/components/devices/devices/LightPushButton.vue';
import ControlBlinds from '@/ui/components/devices/devices/ControlBlinds.vue';
import ManageRoom from '@/ui/components/modals/ManageRoom.vue';
import ControlShutter from '@/ui/components/devices/devices/ControlShutter.vue';
import ControlAwning from '@/ui/components/devices/devices/ControlAwning.vue';
import ThermostatDigital from '@/ui/components/devices/devices/ThermostatDigital.vue';
import ThermostatAnalog from '@/ui/components/devices/devices/ThermostatAnalog.vue';
import BrightnessSensor from '@/ui/components/devices/devices/BrightnessSensor.vue';
import AirHumiditySensor from '@/ui/components/devices/devices/AirHumiditySensor.vue';
import SensorCO2 from '@/ui/components/devices/devices/SensorCO2.vue';
import SensorLevel from '@/ui/components/devices/devices/SensorLevel.vue';
import WeatherStation from '@/ui/components/devices/devices/WeatherStation.vue';
import HotWaterMeter from '@/ui/components/devices/devices/HotWaterMeter.vue';
import ColdWaterMeter from '@/ui/components/devices/devices/ColdWaterMeter.vue';
import ElectricityMeter from '@/ui/components/devices/devices/ElectricityMeter.vue';
import HeatingMeter from '@/ui/components/devices/devices/HeatingMeter.vue';
import MotionSensor from '@/ui/components/devices/devices/MotionSensor.vue';
import Battery from '@/ui/components/devices/devices/Battery.vue';
import PVSystem from '@/ui/components/devices/devices/PVSystem.vue';
import HouseConsumption from '@/ui/components/devices/devices/HouseConsumption.vue';
import Generator from '@/ui/components/devices/devices/Generator.vue';
import MainsConnection from '@/ui/components/devices/devices/MainsConnection.vue';
import ElectricChargingStation from '@/ui/components/devices/devices/ElectricChargingStation.vue';
import ElectronicBoiler from '@/ui/components/devices/devices/ElectronicBoiler.vue';
import VentilatorSwitch from '@/ui/components/devices/devices/VentilatorSwitch.vue';
import MusicSystem from '@/ui/components/devices/devices/MusicSystem.vue';
import TV from '@/ui/components/devices/devices/TV.vue';
import MotorWithoutVFD from '@/ui/components/devices/devices/MotorWithoutVFD.vue';
import PumpWithoutVFD from '@/ui/components/devices/devices/PumpWithoutVFD.vue';
import PumpWithVFD from '@/ui/components/devices/devices/PumpWithVFD.vue';
import MotorWithVFD from '@/ui/components/devices/devices/MotorWithVFD.vue';
import MixingValve from '@/ui/components/devices/devices/MixingValve.vue';
import Ventilation from '@/ui/components/devices/devices/Ventilation.vue';
import Gauge from '@/ui/components/devices/devices/Gauge.vue';
import RegentLighting from '@/ui/components/devices/devices/RegentLighting.vue';
import HeatingCircuitOptimization from '@/ui/components/devices/mpc/HeatingCircuitOptimization/index.vue';
import VueGridLayout from 'vue-grid-layout';
import { mixins } from 'vue-class-component';
import { DND } from '@/ui/mixins/dnd';
import DropDownOutputFieldWithVariable from '@/ui/components/devices/devices/DropDownOutputFieldWithVariable.vue';
import DropDownInputFieldWithVariable from '@/ui/components/devices/devices/DropDownInputFieldWithVariable.vue';
import TSGFrischwasser from '@/ui/components/devices/devices/TSGFrischwasser.vue';
import TSGBrauchwasser from '@/ui/components/devices/devices/TSGBrauchwasser.vue';
import Robot from '@/ui/components/devices/devices/Robot.vue';
import SurveyClient from '@/ui/components/devices/devices/SurveyClient.vue';
import PVProductionService from '@/ui/components/devices/mpc/PVProductionService/index.vue';
import EnergyViewV2 from '@/ui/components/devices/devices/EnergyViewV2/index.vue';
import MpcEnergyView from '@/ui/components/devices/mpc/EnergyView/index.vue';
import ConsumptionService from '@/ui/components/devices/mpc/ConsumptionService/index.vue';
import HppProductionService from '@/ui/components/devices/mpc/HeatingProduction/index.vue';
import TSGLadestationNotAus from '@/ui/components/devices/devices/TSGLadestationNotAus.vue';
import EMS from '@/ui/components/devices/mpc/EMS/index.vue';
import EMSV2 from '@/ui/components/devices/devices/EMS/index.vue';
import GeneralSwitchV2 from '@/ui/components/devices/devices/GeneralSwitchV2.vue';
import PVMonitoringService from '@/ui/components/devices/mpc/PVMonitoringService/index.vue';
import HistoryAnomalyDetection
  from '@/ui/components/devices/anomalyDetection/HistoryAnomalyDetection.vue';
import StreamAnomalyDetection
  from '@/ui/components/devices/anomalyDetection/StreamAnomalyDetection.vue';
import { IProjectsState } from '@/store/modules/projects/types';
import SetpointOptimizer from '@/ui/components/devices/mpc/SetpointOptimizer/index.vue';
import TSGModulLadestation from '@/ui/components/devices/devices/TSGModulLadestation.vue';
import EnergyIO from '@/ui/components/devices/devices/EnergyIO/index.vue';

/**
 * Component that shows filtered devices, charts in the search
 */
@Component({
  components: {
    TSGModulLadestation,
    HppProductionService,
    SetpointOptimizer,
    StreamAnomalyDetection,
    HistoryAnomalyDetection,
    PVMonitoringService,
    GeneralSwitchV2,
    EMS,
    EMSV2,
    EnergyIO,
    TSGLadestationNotAus,
    ConsumptionService,
    PVProductionService,
    MpcEnergyView,
    EnergyViewV2,
    SurveyClient,
    Robot,
    TSGBrauchwasser,
    TSGFrischwasser,
    DropDownInputFieldWithVariable,
    DropDownOutputFieldWithVariable,
    HeatingCircuitOptimization,
    Gauge,
    Ventilation,
    MixingValve,
    MotorWithVFD,
    PumpWithVFD,
    PumpWithoutVFD,
    MotorWithoutVFD,
    TV,
    MusicSystem,
    VentilatorSwitch,
    ElectronicBoiler,
    ElectricChargingStation,
    MainsConnection,
    Generator,
    HouseConsumption,
    PVSystem,
    Battery,
    MotionSensor,
    HeatingMeter,
    ElectricityMeter,
    ColdWaterMeter,
    HotWaterMeter,
    WeatherStation,
    SensorLevel,
    SensorCO2,
    AirHumiditySensor,
    BrightnessSensor,
    ThermostatAnalog,
    ThermostatDigital,
    ControlShutter,
    ControlAwning,
    ControlBlinds,
    LightPushButton,
    SocketSwitch,
    LightRGB,
    LightSwitch,
    LightDimmer,
    Temperature,
    RoomsList,
    ManageRoom,
    VariableOutputField,
    VariableInputField,
    VariableTextInputField,
    VariableTextOutputField,
    RegentLighting,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    chart: Chart,
  },
})
export default class DevicesList extends mixins(DND) {
  @Prop() devicesByRoomLocal!: any;
  @State('mqttClient') mqttState!: any;
  @State('mpc') mpcState!: any;
  @State('devices') devicesState!: any;
  @Getter('devices/devicesTypes') devicesTypes!: any;
  @Getter('rooms/rooms') rooms!: any;
  @Getter('devices/devicesByRoom') devicesByRoom!: (roomId: string) => any;
  @Action('rooms/updateRoom') updateRoom!: (payload: any) => void;
  @State('projects') projectsState!: IProjectsState;

  get isProjectOnline() {
    if (this.projectsState.project.meta.disableDevicesWhenOffline === true) {
      return this.mqttState.online;
    } else {
      return true;
    }
  }

  dndLayoutCharts = []
  dndLayoutDevices = []
  chartInstanceWidth: any = null
  filteredDevicesWithPositions: any = []

  @Watch('filteredDevices')
  onRoomChange() {
    this.initDndCharts();
    this.initDndDevices();
  }

  get mlModelTypes() {
    return this.mpcState.mlModelTypes;
  }
  get allDevicesTypes() {
    return [...Object.keys(this.devicesTypes), ...Object.keys(this.mlModelTypes)];
  }
  get devicesFilter() {
    return this.devicesState.devicesFilter;
  }
  get filteredDevices() {
    return this.devicesByRoomLocal.filter((device: any) => {
      if (this.devicesFilter && this.devicesFilter.length) {
        return device.name.toLowerCase().includes(this.devicesFilter.toLowerCase());
      }
      return device;
    });
  }
  get charts() {
    const charts = ['ColumnChart', 'LineChart', 'StreamAnomalyDetection', 'AreaChart', 'GaugeChart', 'chart'];
    return this.filteredDevices.filter((device: any) => charts.includes(device.data.type));
  }
  get devices() {
    return this.filteredDevices.filter((device: any) => this.allDevicesTypes.includes(device.data.type));
  }

  /**
   * Prepares devices list for Drag and Drop grid
   * @return array, list of devices
   */
  initDndDevices() {
    this.dndLayoutDevices = this.devices.map((device: any, index: number, arr: any) => {
      const sizes = this.sizesForDnD(device.data.type);
      return {
        device,
        x: index % 4,
        y: 0,
        ...sizes,
        i: device.id,
      };
    });
  }

  /**
   * Prepares charts list for Drag and Drop grid
   * @return array, list of charts
   */
  initDndCharts() {
    this.dndLayoutCharts = this.charts.map((device: any, index: number, arr: any) => {
      return {
        device,
        x: (index * 2) % 4,
        y: 0,
        w: device.data.selectedWidth === 'full' ? 4 : 2,
        h: 4,
        i: device.id,
      };
    });
    this.$nextTick(() => {
      this.chartInstanceWidth = Math.round((this.$refs.layout as any).$el.clientWidth / 2) - 20;
    });
  }

  mounted() {
    this.initDndCharts();
    this.initDndDevices();
  }
}
