
import { Component, Prop } from 'vue-property-decorator';
import BaseChart from '@/ui/components/devices/mpc/components/BaseChart.vue';
import TimeTable from '@/ui/components/devices/components/EnergyParts/EMS/components/charts/Forecast/TimeTable.vue';
import ChartColors from '@/ui/components/devices/charts/charts/ChartColors';
import { periodConfigurations, Periods } from '@/ui/components/devices/charts/charts/ChartUtils';
import { Action } from 'vuex-class';
import { filterInstances, getPredictions } from '@/utils/mpcUtils';
import { hasSystem } from '@/utils/utilsFunctions';
import { kiloWattHourUnit } from '@/utils/unit/unitConstants';
import { IDevice } from '@/types/devices.types';
import {
  EnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';
import { mixins } from 'vue-class-component';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';

/**
 * Component that represent wrapper for forecast charts tabs navigation.
 * Used in EMS.
 */
@Component({
  components: {
    BaseChart,
    TimeTable,
  },
})
export default class Forecast extends mixins(EnergyComponent) {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: null }) prediction!: any;
  @Prop({ default: null }) predictedPower!: any;
  @Prop({ default: null }) predictedTargetPower!: any;
  @Prop({ default: null }) predictedEnergy!: any;
  @Prop({ default: null }) predictedSOC!: any;
  @Prop() mpcId!: any;
  @Prop() scaling!: any;
  @Action('mpc/fetchMPCData') fetchMPCData!: any;

  tab: any = null;
  excludeNavItems = [
    this.$t('mlModel.EMS.charts.forecast.pv.title'),
    this.$t('mlModel.EMS.charts.forecast.consumption.title'),
    this.$t('mlModel.EMS.charts.forecast.difference.title'),
    this.$t('mlModel.EMS.charts.forecast.timeTableConsumer.title'),
  ];

  mpcData: any = null;
  chartVariables: any = {};

  get chartColors() {
    return ChartColors.colors(this.$vuetify.theme.dark);
  }

  // scaling
  get pvScaling() {
    return this.scaling?.pv;
  }
  get consumptionScaling() {
    return this.scaling?.consumption;
  }

  // =================== pv data =======================
  /**
   * Converts predictedEnergy data to show in chart.
   * @return {array} [[timestamp, value], [timestamp, value]]
   */
  get pvPredictedEnergy() {
    const pvInstancesList = Object.keys(this.getSystemComponents(this.deviceData, EnergyCircleType.pv));
    const pvInstances: any = filterInstances(this.prediction, 'pv', pvInstancesList, '_energy');

    return getPredictions(pvInstances, '_energy');
  }

  /**
   * Converts predictedPower data to show in chart.
   * @return {array} [[timestamp, value], [timestamp, value]]
   */
  get pvPredictedPower() {
    const pvInstancesList = Object.keys(this.getSystemComponents(this.deviceData, EnergyCircleType.pv));
    const pvInstances: any = filterInstances(this.prediction, 'pv', pvInstancesList, '_power');

    return getPredictions(pvInstances, '_power');
  }

  /**
   * Prepares series for chart
   */
  get pvSeriesForecast() {
    return [
      {
        name: this.$t(
          'mlModel.PVProductionService.settingsView.chartLabels.forecast.predictedEnergy',
        ),
        type: 'line',
        yAxis: 0,
        color: this.chartColors[0],
        data: this.pvPredictedEnergy,
      },
      {
        name: this.$t(
          'mlModel.PVProductionService.settingsView.chartLabels.forecast.predictedPower',
        ),
        type: 'line',
        yAxis: 1,
        color: this.chartColors[1],
        data: this.pvPredictedPower,
      },
    ];
  }

  /**
   * Prepares Y-axis for chart
   */
  get pvYAxisForecast() {
    return [
      {
        title: null,
        opposite: false,
        showFirstLabel: true,
        showLastLabel: true,
        endOnTick: false,
        gridLineWidth: 1,
        min: 0,
        max: typeof this.pvScaling === 'number' ? this.pvScaling * 12 : null,
        lineColor: this.chartColors[0],
        labels: {
          format: '{value} kWh',
          style: {
            color: this.chartColors[0],
          },
        },
      },
      {
        title: null,
        opposite: false,
        showFirstLabel: true,
        showLastLabel: true,
        endOnTick: false,
        gridLineWidth: 0,
        min: 0,
        max: typeof this.pvScaling === 'number' ? this.pvScaling * 2 : null,
        lineColor: this.chartColors[1],
        labels: {
          format: '{value} kW',
          style: {
            color: this.chartColors[1],
          },
        },
      },
    ];
  }

  /**
   * Combine all prepared data in chart options object
   */
  get pvForecastData() {
    return {
      chartType: 'line',
      chartWidth: null,
      chartHeight: 600,
      series: this.pvSeriesForecast,
      yAxis: this.pvYAxisForecast,
    };
  }

  // ================== consumption data ==================
  /**
   * Converts predictedEnergy data to show in chart.
   * @return {array} [[timestamp, value], [timestamp, value]]
   */
  get consumptionPredictedEnergy() {
    if (this.mpcData) {
      const { consumption } = this.mpcData.data.meta.charts.predictedEnergy;
      return consumption ? consumption.map((el: any) => [el[0] * 1000, el[1]]) : [];
    }
    return [];
  }

  /**
   * Converts predictedPower data to show in chart.
   * @return {array} [[timestamp, value], [timestamp, value]]
   */
  get consumptionPredictedPower() {
    if (this.mpcData) {
      const { consumption } = this.mpcData.data.meta.charts.predictedPower;
      return consumption ? consumption.map((el: any) => [el[0] * 1000, el[1]]) : [];
    }
    return [];
  }

  /**
   * Prepares series for chart
   */
  get consumptionSeriesForecast() {
    return [
      {
        name: this.$t(
          'mlModel.ConsumptionService.settingsView.chartLabels.forecast.predictedEnergy',
        ),
        type: 'line',
        yAxis: 0,
        color: this.chartColors[0],
        data: this.consumptionPredictedEnergy,
      },
      {
        name: this.$t(
          'mlModel.ConsumptionService.settingsView.chartLabels.forecast.predictedPower',
        ),
        type: 'line',
        yAxis: 1,
        color: this.chartColors[1],
        data: this.consumptionPredictedPower,
      },
    ];
  }

  /**
   * Prepares Y-axis for chart
   */
  get consumptionYAxisForecast() {
    return [
      {
        title: null,
        opposite: false,
        showFirstLabel: true,
        showLastLabel: true,
        endOnTick: false,
        gridLineWidth: 1,
        min: typeof this.consumptionScaling === 'number' ? 0 - this.consumptionScaling * 20 : null,
        max: typeof this.consumptionScaling === 'number' ? this.consumptionScaling * 20 : null,
        lineColor: this.chartColors[0],
        labels: {
          format: '{value} kWh',
          style: {
            color: this.chartColors[0],
          },
        },
      },
      {
        title: null,
        opposite: false,
        showFirstLabel: true,
        showLastLabel: true,
        endOnTick: false,
        gridLineWidth: 0,
        min: typeof this.consumptionScaling === 'number' ? 0 - this.consumptionScaling * 2 : null,
        max: typeof this.consumptionScaling === 'number' ? this.consumptionScaling * 2 : null,
        lineColor: this.chartColors[1],
        labels: {
          format: '{value} kW',
          style: {
            color: this.chartColors[1],
          },
        },
      },
    ];
  }

  /**
   * Combine all prepared data in chart options object
   */
  get consumptionForecastData() {
    return {
      chartType: 'line',
      chartWidth: null,
      chartHeight: 600,
      series: this.consumptionSeriesForecast,
      yAxis: this.consumptionYAxisForecast,
    };
  }

  // ============== chart difference ===================

  /**
   * Converts predictedPower data to show in chart.
   * @return {array} [[timestamp, value], [timestamp, value]]
   */
  get sumPredictedPVPower() {
    const pvInstancesList = Object.keys(this.getSystemComponents(this.deviceData, EnergyCircleType.pv));
    const pvInstances: any = filterInstances(this.prediction, 'pv', pvInstancesList, '_power');

    return getPredictions(pvInstances, '_power');
  }

  /**
   * Converts predictedPower data to show in chart.
   * @return {array} [[timestamp, value], [timestamp, value]]
   */
  get predictedConsumption() {
    if (this.mpcData) {
      const { consumption } = this.mpcData.data.meta.charts.predictedPower;
      return consumption ? consumption.map((el: any) => [el[0] * 1000, el[1]]) : [];
    }
    return [];
  }

  /**
   * Converts predictedPower data to show in chart.
   * @return {array} [[timestamp, value], [timestamp, value]]
   */
  get predictedGrid() {
    const gridInstancesList = Object.keys(this.getSystemComponents(this.deviceData, EnergyCircleType.grid));
    const gridInstances: any = filterInstances(
      this.prediction,
      'grid',
      gridInstancesList,
      '_power',
    );

    return getPredictions(gridInstances, '_power');
  }

  /**
   * Prepares series for chart
   */
  get differenceSeriesForecast() {
    return [
      ...(hasSystem('pv', this.mpcData?.data.meta.controllerMappings)
        ? [
          {
            name: this.$t('mlModel.EMS.charts.forecast.difference.predictedPVPower'),
            type: 'area',
            yAxis: 0,
            color: this.chartColors[0],
            data: this.sumPredictedPVPower,
          },
        ]
        : []),
      {
        name: this.$t('mlModel.EMS.charts.forecast.difference.predictedConsumption'),
        type: 'area',
        yAxis: 1,
        color: this.chartColors[1],
        data: this.predictedConsumption,
      },
      {
        name: this.$t('mlModel.EMS.charts.forecast.difference.predictedGrid'),
        type: 'area',
        yAxis: 2,
        color: this.chartColors[2],
        data: this.predictedGrid,
      },
    ];
  }

  /**
   * Prepares Y-axis for chart
   */
  get differenceYAxisForecast() {
    return [
      {
        title: null,
        opposite: false,
        showFirstLabel: true,
        showLastLabel: true,
        endOnTick: false,
        gridLineWidth: 1,
        min: typeof this.pvScaling === 'number' ? 0 - this.consumptionScaling * 2 : null,
        max: typeof this.pvScaling === 'number' ? this.consumptionScaling * 2 : null,
        lineColor: this.chartColors[0],
        labels: {
          format: '{value} kW',
          style: {
            color: this.chartColors[0],
          },
        },
      },
      {
        title: null,
        opposite: false,
        showFirstLabel: true,
        showLastLabel: true,
        endOnTick: false,
        gridLineWidth: 0,
        min: typeof this.pvScaling === 'number' ? 0 - this.consumptionScaling * 2 : null,
        max: typeof this.pvScaling === 'number' ? this.consumptionScaling * 2 : null,
        lineColor: this.chartColors[1],
        labels: {
          format: '{value} kW',
          style: {
            color: this.chartColors[1],
          },
        },
      },
      {
        title: null,
        opposite: false,
        showFirstLabel: true,
        showLastLabel: true,
        endOnTick: false,
        gridLineWidth: 0,
        min: typeof this.pvScaling === 'number' ? 0 - this.consumptionScaling * 2 : null,
        max: typeof this.pvScaling === 'number' ? this.consumptionScaling * 2 : null,
        lineColor: this.chartColors[2],
        labels: {
          format: '{value} kW',
          style: {
            color: this.chartColors[2],
          },
        },
      },
    ];
  }

  /**
   * Combine all prepared data in chart options object
   */
  get differenceForecastData() {
    return {
      chartType: 'line',
      chartWidth: null,
      chartHeight: 600,
      series: this.differenceSeriesForecast,
      yAxis: this.differenceYAxisForecast,
    };
  }

  /**
   * Tabs for navigation
   */
  get items() {
    return [
      ...(hasSystem('pv', this.mpcData?.data.meta.controllerMappings)
        ? [
          {
            title: this.$t('mlModel.EMS.charts.forecast.pv.title'),
            component: 'BaseChart',
            data: this.pvForecastData,
            chartVariables: this.chartVariables,
          },
        ]
        : []),
      {
        title: this.$t('mlModel.EMS.charts.forecast.consumption.title'),
        component: 'BaseChart',
        data: this.consumptionForecastData,
      },
      {
        title: this.$t('mlModel.EMS.charts.forecast.difference.title'),
        component: 'BaseChart',
        data: this.differenceForecastData,
      },
      {
        title: this.$t('mlModel.EMS.charts.forecast.timeTableConsumer.title'),
        component: 'TimeTable',
      },
    ];
  }

  sum(array: any) {
    if (array.length === 0) {
      return 0;
    }
    return array.reduce((a: any, b: any) => {
      return a + b;
    });
  }

  sumPredictedPower(system_type: any, all = true) {
    let sum = 0;
    // Summing up power data for new response format
    Object.entries(this.predictedMPCPower[system_type])
      .filter((item: any) => item[0].includes('_power') && !item[0].includes('target_power'))
      .forEach((item: any) => {
        const values: any = [];
        item[1].forEach((i: any) => values.push(i[1]));
        sum += this.sum(all ? values : values.filter((i: any) => i < 0));
      });
    return sum;
  }

  async loadChartVariables() {
    const timeFactor = periodConfigurations[Periods.DAY].intervalInSeconds / 60 / 60;
    const variables: any = {};
    const pv = this.sumPredictedPower('pv') * timeFactor;
    const battery = -1 * this.sumPredictedPower('battery', false) * timeFactor;
    const grid = -1 * this.sumPredictedPower('grid', false) * timeFactor;

    if (hasSystem('pv', this.mpcData?.data.meta.controllerMappings)) {
      variables[this.$t('mlModel.EMS.charts.chartDifference.predictedPvProduction').toString()] = {
        value: kiloWattHourUnit.unitForValue(pv).value,
        unit: kiloWattHourUnit.unitForValue(pv).unit,
      };
    }

    if (hasSystem('battery', this.mpcData?.data.meta.controllerMappings)) {
      variables[
        this.$t('mlModel.EMS.charts.chartDifference.predictedProductionBattery').toString()
      ] = {
        value: kiloWattHourUnit.unitForValue(battery).value,
        unit: kiloWattHourUnit.unitForValue(battery).unit,
      };
    }

    if (hasSystem('grid', this.mpcData?.data.meta.controllerMappings)) {
      variables[
        this.$t('mlModel.EMS.charts.chartDifference.predictedProductionGrid').toString()
      ] = {
        value: kiloWattHourUnit.unitForValue(grid).value,
        unit: kiloWattHourUnit.unitForValue(grid).unit,
      };
    }
    if (hasSystem('pv', this.mpcData?.data.meta.controllerMappings)) {
      variables[this.$t('mlModel.EMS.charts.chartDifference.predictedDirectConsumption').toString()] =
        {
      value: kiloWattHourUnit.unitForValue(pv - battery - grid).value,
      unit: kiloWattHourUnit.unitForValue(pv - battery - grid).unit,
    };
    }
    this.chartVariables = variables;
  }

  async mounted() {
    const id = this.mpcId.replace(/_/g, '-');
    this.mpcData = await this.fetchMPCData(id);
    await this.loadChartVariables();
  }

  get predictedMPCPower() {
    return this.mpcData ? this.mpcData.data.meta.charts.prediction : {};
  }
}
