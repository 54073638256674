
import { Vue, Component } from 'vue-property-decorator';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import PushButtonMock from '@/ui/components/devices/previews/components/PushButtonMock.vue';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    InputFieldMock,
    PushButtonMock,
    SwitchMock,
    DeviceLayout,
  },
})
export default class MotionSensorPreview extends Vue {
  switchState = false

  get hideIfCrossingPoint() {
    return this.$vuetify.breakpoint.width < 1400 && this.$vuetify.breakpoint.width > 1264;
  }

  get mainViewLang() {
    return 'devices.MotionSensor.mainView';
  }
  get previewNameLang() {
    return this.$t('devices.MotionSensor.previewName');
  }
}
