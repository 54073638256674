import { Vue, Component } from 'vue-property-decorator';

@Component
export class DND extends Vue {
  /**
   * Returns device dimensions according to type,
   * which used in Drag and Drop grid on Favorites, Rooms pages
   * @param componentType type of device, MPC
   * @return object with props w (width), h (height)
   */
  sizesForDnD(componentType: string) {
    const devicesSizesForDnD: any = {
      GaugeChart: { w: 2, h: 2 },
      ControlBlinds: { w: 1, h: 2 },
      MotorWithoutVFD: { w: 1, h: 2 },
      WeatherStation: { w: 1, h: 2 },
      MotionSensor: { w: 1, h: 2 },
      Gauge: { w: 2, h: 2 },
      MusicSystem: { w: 1, h: 2 },
      RegentLighting: { w: 3, h: 5 },
      MotorWithVFD: { w: 1, h: 2 },
      PumpWithoutVFD: { w: 1, h: 2 },
      PumpWithVFD: { w: 1, h: 2 },
      MixingValve: { w: 1, h: 2 },
      Ventilation: { w: 1, h: 2 },
      TSGFrischwasser: { w: 2, h: 2 },
      TSGBrauchwasser: { w: 2, h: 2 },
      PVProductionService: { w: 1, h: 2 },
      PVMonitoringService: { w: 1, h: 2 },
      ConsumptionService: { w: 1, h: 2 },
      HppProductionService: { w: 1, h: 2 },
      Robot: { w: 1, h: 5 },
      SurveyClient: { w: 1, h: 5 },
      EnergyDevice: { w: 4, h: 5 },
      EMSV2: { w: 4, h: 6 },
      EMS: { w: 4, h: 6 },
      EnergyIO: { w: 4, h: 6 },
      EnergyViewV2: { w: 4, h: 6 },
      MpcEnergyView: { w: 4, h: 6 },
      TSGLadestationNotAus: { w: 1, h: 2 },
      SetpointOptimizer: { w: 2, h: 5 },
      TSGModulLadestation: { w: 1, h: 2 },
      ElectricChargingStationV2: { w: 1, h: 2 },
    };
    const isChart = (type: string) => {
      const charts = ['HistoryAnomalyDetection', 'StreamAnomalyDetection', 'chart'];
      return charts.some((item: string) => item === type) ? { w: 4, h: 4 } : false;
    };
    return devicesSizesForDnD[componentType] || isChart(componentType) || { w: 1, h: 1 };
  }
}
