
import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent PVSystem Charts
 */
@Component({
  components: {
    ModalWindow,
    BaseChartWrapper,
  },
})
export default class PVSystemCharts extends Vue {
  @Prop() deviceData!: IDevice;

  isTooltip = false;

  get isActualVoltageVariableFilled() {
    return !!this.deviceData.data.mappings?.OutputField2_actualValue?.length;
  }
  get isCurrentFlowVariableFilled() {
    return !!this.deviceData.data.mappings?.OutputField3_actualValue?.length;
  }

  get chartObject() {
    const localChartOptions = [
      {
        agg: 'avg',
        name: this.$t('devices.PVSystem.ChartsWindow.seriesTitles.actualPower'),
        scaling: {
          max: null,
          min: null,
        },
        type: 'line',
        unit: 'kW',
        var: this.deviceData.data.mappings.OutputField_actualValue,
      },
      ...(this.isActualVoltageVariableFilled ? [{
        agg: 'avg',
        name: this.$t('devices.PVSystem.ChartsWindow.seriesTitles.actualVoltage'),
        scaling: {
          max: null,
          min: null,
        },
        type: 'line',
        unit: 'V',
        var: this.deviceData.data.mappings.OutputField2_actualValue,
      }] : []),
      ...(this.isCurrentFlowVariableFilled ? [{
        agg: 'avg',
        name: this.$t('devices.PVSystem.ChartsWindow.seriesTitles.currentFlow'),
        scaling: {
          max: null,
          min: null,
        },
        type: 'line',
        unit: 'A',
        var: this.deviceData.data.mappings.OutputField3_actualValue,
      }] : []),
    ];
    return {
      name: '',
      data: {
        chartOptions: localChartOptions,
        selectedStackingOptions: 'normal',
        selectedWidth: 'full',
        type: 'chart',
      },
    };
  }

  get chartData() {
    return {
      chartWidth: null,
      chartHeight: 600,
      chartData: this.chartObject,
      navigationItemsToExclude: ['live', 'hour'],
    };
  }
}
