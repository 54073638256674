
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Mutation, State } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { cloneDeep } from 'lodash';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { ITenantModel } from '@/types/wizards/tenant.types';

@Component({
  components: {
    InfoTooltip,
    WizardContentView,
  },
})
export default class ConsumptionComparison extends mixins(Validation) {
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Mutation('tenantWizard/updateTenantObject') updateTenantObject!: (data: ITenantModel) => void;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;

  valid = false;
  localReferenceInformation= {
    consumption1PersonHousehold: 2400, // kWh/Year default 2400
    consumption2PersonHousehold: 3000, // kWh/Year default 3000
    consumption3PersonHousehold: 3600, // kWh/Year default 3600
    consumption4PersonHousehold: 4000, // kWh/Year default 4000
    additionalText: '',
  };

  get currentLang() {
    return this.$i18n.locale;
  }

  handleNext() {
    this.updateTenant();
    this.handleIncrement();
  }

  updateTenant() {
    const tenantCopy = cloneDeep(this.tenantState.tenant);
    tenantCopy.referenceInformation = this.localReferenceInformation;
    this.updateTenantObject(tenantCopy);
  }

  created() {
    if (this.tenantState.tenant.referenceInformation.additionalText === '') {
      this.localReferenceInformation.additionalText = this.$t('tenantWizard.consumptionComparison.additionalText.default') as string;
    } else {
      this.localReferenceInformation = this.tenantState.tenant.referenceInformation;
    }
  }
}
