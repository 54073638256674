
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { IEventState } from '@/store/modules/events/types';
import { UserRoleCode } from '@/utils/userRoles';
import { IUser } from '@/types/app.types';
import { IEvent } from '@/types/events.types';
import { IMember } from '@/types/members.types';

/**
 * Event list page that shows table of events
 */
@Component
export default class EventList extends Vue {
  @State('events') eventsState!: IEventState;
  @Getter('events/getEventList') getEventList!: IEvent[];
  @Getter('events/getIsAllAccepted') getIsAllAccepted!: IEvent[];
  @Getter('members/members') membersList!: IMember[];
  @Getter('app/getUser') currentUser!: IUser;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;
  @Mutation('events/clean') clean!: () => void;
  @Action('events/loadEvents') loadEvents!: (payload: { page: number; bol: boolean }) => Promise<void>;
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;
  @Action('events/acceptOneEvent') acceptOneEvent!: (payload: { id: string }) => Promise<void>;
  @Action('events/acceptEveryEvent') acceptEveryEvent!: () => Promise<void>;

  eventListDataLoaded = false;
  tab = null;
  notAcceptedEventsPaginationPage = 1;
  acceptedEventsPaginationPage = 1;

  get isUserAdmin() {
    const member: IMember | undefined = this.membersList.find((member: IMember) => member.id === this.currentUser.id);
    if (member?.role === UserRoleCode.admin) {
      return true;
    }
    return false;
  }

  /**
   * Contains all headers for the EventTable
   */
  get notAcceptedEventsTableHeaders() {
    return [
      {
        text: this.$t('uiComponents.eventListTable.headers.type'),
        align: 'start',
        width: '10%',
        sortable: false,
      },
      {
        text: this.$t('uiComponents.eventListTable.headers.text'),
        align: 'start',
        sortable: false,
      },
      {
        text: this.$t('uiComponents.eventListTable.headers.date'),
        align: 'start',
        width: '15%',
        sortable: false,
      },
      {
        text: this.$t('uiComponents.eventListTable.headers.accept'),
        align: 'start',
        width: '15%',
        sortable: false,
        value: 'accepted',
      },
    ];
  }
  get acceptedEventsTableHeaders() {
    return [
      {
        text: this.$t('uiComponents.eventListTable.headers.type'),
        align: 'start',
        width: '10%',
        sortable: false,
      },
      {
        text: this.$t('uiComponents.eventListTable.headers.text'),
        align: 'start',
        sortable: false,
      },
      {
        text: this.$t('uiComponents.eventListTable.headers.date'),
        align: 'start',
        width: '15%',
        sortable: false,
      },
      {
        text: this.$t('uiComponents.eventListTable.headers.accept'),
        align: 'start',
        width: '10%',
        sortable: false,
        value: 'accepted',
      },
      {
        text: this.$t('uiComponents.eventListTable.headers.acceptedBy'),
        align: 'start',
        width: '15%',
        sortable: false,
        value: 'acceptedBy',
      },
    ];
  }

  get tables() {
    return [
      {
        title: this.$t('uiComponents.eventListTable.eventListTabs.events'),
        headers: this.notAcceptedEventsTableHeaders,
      },
      {
        title: this.$t('uiComponents.eventListTable.eventListTabs.accepted'),
        headers: this.acceptedEventsTableHeaders,
      },
    ];
  }

  get eventsListTable() {
    return this.getEventList.map((event: IEvent) => ({
      ...event,
      created_at: event.created_at ? this.parseDate(event.created_at) : null,
      accepted_at: event?.accepted_at ? this.parseDate(event.accepted_at) : null,
    }));
  }

  get numberOfPages() {
    return this.eventsState.numberOfPages;
  }

  /**
   * pads the given number to 2 digits. e.g. pad(1) -> "01"
   * @param n number of occupancy
   * @return string of 2 digits
   * @private
   */
  pad(n: number) {
    return n.toString().padStart(2, '0');
  }
  parseDate(dateString: string) {
    return new Date(dateString);
  }
  typeFromNumberCode(type: number) {
    switch (type) {
      case 1:
        return 'Warning';
      case 2:
        return 'Error';
      default:
        return 'Info';
    }
  }
  /**
   * Convert date string to specific format
   * @param date date string
   * @return date in format MM.DD.YYYY - HH.MM.SS
   */
  formatDate(date: Date) {
    return `${this.pad(date.getDate())}.${this.pad(date.getMonth() + 1)}.${date.getFullYear()} - ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
  }

  /**
   * Define member who accepted event
   * @param acceptedBy member id
   * @return full name of member
   */
  getAcceptedByMember(acceptedBy: string) {
    // get Username based on the given ID
    const member: IMember | undefined = this.membersList.find(member => member.id === acceptedBy);
    if (member !== undefined) return `${member?.first_name} ${member?.last_name}`;
    else return '';
  }

  async handlePagination(page: number) {
    await this.loadEvents({ page, bol: !!this.tab });
    if (!this.tab) this.notAcceptedEventsPaginationPage = page;
    else this.acceptedEventsPaginationPage = page;
  }

  async handleEvents(inx: number) {
    const page = this.tab ? this.notAcceptedEventsPaginationPage : this.acceptedEventsPaginationPage;
    await this.loadEvents({ page, bol: !!inx });
  }

  async acceptEvent(id: string) {
    if (!this.isUserAdmin) return;
    await this.acceptOneEvent({ id });
  }
  async acceptAllEvents() {
    await this.acceptEveryEvent();
  }

  async created() {
    this.setProjectLoader(true);
    await Promise.allSettled([
      this.loadEvents({ page: 1, bol: false }),
      this.fetchMembers(this.$route.params.id),
    ]).finally(() => {
      this.setProjectLoader(false);
      this.eventListDataLoaded = true;
    });
  }
  async destroyed() {
    this.clean();
  }
}
