
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import EnergyComponent from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import { IOEnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import SystemDialog from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/SystemDialog.vue';
import PVSystemSettings from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/PVSystem/Actual/Settings.vue';
import GeneratorSystemSettings from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/GeneratorSystem/Actual/Settings.vue';
import CHPSystemSettings
  from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/CHPSystem/Actual/Settings.vue';
import { IDevice } from '@/types/devices.types';
import { IOEnergyCircleType } from '@/types/energyVisualisation/EnergyCircleType';

/**
 * Actual view for EnergyView/EMS
 */
@Component({
  components: {
    SystemDialog,
  },
})
export default class ActualViewIOSystemSettingsDialog extends mixins(EnergyComponent) {
  // whether the dialog is currently shown (v-model)
  @Prop() value!: boolean;
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop() openedSystem!: IOEnergyCircleType;
  @Prop() circleData!: IOEnergyCircleDisplayData;
  @Prop({ default: null }) controls!: any;

  systemToSettingsMapping = {
    // EMS, EnergyView
    [IOEnergyCircleType.inputs]: {
      component: PVSystemSettings,
      width: 450,
    },
    [IOEnergyCircleType.hybrids]: {
      component: CHPSystemSettings,
      width: 1200,
    },
    [IOEnergyCircleType.outputs]: {
      component: GeneratorSystemSettings,
      width: 800,
    },
  };

  get circleDataTitle() {
    return this.circleData?.title;
  }

  get openedSettings() {
    return this.systemToSettingsMapping[this.openedSystem as IOEnergyCircleType].component;
  }
  get openedSettingsWidth() {
    return this.systemToSettingsMapping[this.openedSystem as IOEnergyCircleType].width;
  }

  getIoSystemComponents(type: IOEnergyCircleType): Record<string, any> {
    return this.deviceData.data.meta.controllerMappings[type]?.components ?? {};
  }

  // get opened single system's component data
  get openedSystemComponent() {
    return this.getIoSystemComponents(this.openedSystem as IOEnergyCircleType)[
      this.circleData.id
    ];
  }
}
