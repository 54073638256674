import api from '@/services/api';

// TODO: move interface to types/weather.types.ts when will be created
interface IWeatherLocation {
  lat: number;
  lon: number;
}

export default {
  async fetchWeather({ lat, lon }: IWeatherLocation) {
    return api.fetch(`/weather?lat=${lat}&lon=${lon}`, 'GET');
  },
};
