
import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseChart from '@/ui/components/devices/charts/charts/BaseChart.vue';
import { ChartData } from '@/types/chart.types';

@Component({
  components: {
    BaseChart,
  },
})

/**
 * Component that represent chart
 */
export default class ChartDevice extends Vue {
  @Prop() chartData!: ChartData;
  @Prop() chartWidth!: number;
  @Prop({ default: true }) showDeviceActions!: boolean;
  @Prop({ default: () => ({ day: 4, week: 1, month: 1, year: 1 }) }) customApproximationValue!: any;

  chartBodyHeight = 0;

  mounted() {
    const chartBody = this.$refs.chartBody as any;
    this.chartBodyHeight = chartBody.clientHeight - 140;
  }
}
