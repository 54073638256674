
import { Vue, Component } from 'vue-property-decorator';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import DropDownBase from '@/ui/components/devices/devices/base/DropDown.vue';
import TimezoneChooser from '@/ui/components/components/TimezoneChooser.vue';

@Component({
  components: {
    InputFieldMock,
    SwitchMock,
    DropDownBase,
    TimezoneChooser,
  },
})
export default class Settings extends Vue {
  tab = null
  sizeMainFuseDialog = false

  tariffMode: null | number = null

  start_low: any = null
  end_low: any = null
  price_low: any = null
  price_high: any = null
  timezone = '';

  get tabs() {
    return [
      {
        title: this.$t('mlModel.EMS.settingsView.tabs.emsSettings'),
      },
      {
        title: this.$t('mlModel.EMS.settingsView.tabs.modeSettings'),
      },
    ];
  }

  get textMappingOperation() {
    const lang: any = this.$t('mlModel.EMS.settingsView.mappingOperation');
    return {
      0: lang.allOff,
      1: lang.selfConsumption,
      2: lang.peakLoadCapping,
      3: lang.loadManagement,
    };
  }
  get textMappingMessage() {
    const lang: any = this.$t('mlModel.EMS.settingsView.mappingMessage');
    return {
      0: lang.disabled,
      1: lang.enabled,
      2: lang.allPrioritiesAtMinimum,
      3: lang.allPrioritiesAtMaximum,
      4: lang.missingPowerValuesOnDeviceSetup,
      5: lang.tooManyFunctionsInThisProject,
    };
  }

  // mode settings
  get emsSettingsModesItems() {
    const lang: any = this.$t('mlModel.EMS.settingsView.tariffModes');
    return [
      { value: 0, name: lang.energyOptimized },
      { value: 1, name: lang.balancedOptimization },
      { value: 2, name: lang.tariffOptimized },
    ];
  }
}
