
import { Vue, Component } from 'vue-property-decorator';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import PVSystemChartsPreview from '@/ui/components/devices/previews/devices/devicesCharts/PVSystemsChartPreview.vue';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';

@Component({
  components: {
    DeviceLayout,
    PVSystemChartsPreview,
    InfoTooltip,
  },
})
export default class PVSystemPreview extends Vue {
  get previewNameLang() {
    return this.$t('devices.PVSystem.previewName');
  }
}
