
import { Component, Prop, Watch } from 'vue-property-decorator';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import EnergyVisualisationPreview
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyVisualisationPreview.vue';

@Component({
  components: {
    EnergyVisualisationPreview,
    WizardContentView,
  },
})
export default class BatteryStatusBar extends WizardComponent {
  @Prop({ default: 0 }) state!: number;
  @Prop({ default: 0 }) duration!: number;
  @Prop({ default: false }) started!: boolean;
  @Prop({ default: false }) finished!: boolean;
  @Prop({ default: false }) hasError!: boolean;
  @Prop({ default: { 0: 90, 1: 250, 2: 250 } }) durationPerState!: { [key: number]: number };
  colors = {
    0: '#414141',
    1: '#218608',
    2: '#d70827',
  }
  progress = 0;
  percent = 0;

  @Watch('state')
  onStateChange(val: number, oldVal: number) {
    if (val > oldVal) {
      this.percent = this.calculatePercentage(val);
    }
  }

  calculatePercentage(val: number) {
    let percentage = 0;
    switch (val) {
      case 0:
        percentage = 0;
        break;
      case 1:
        percentage = (30 / 210) * 100;
        break;
      case 2:
        percentage = (120 / 210) * 100;
        break;
    }
    return percentage;
  }

  @Watch('duration')
  handleDurationChange(val: number) {
    this.progress = this.calculateProgress();
  }

  calculateProgress() {
    if (this.finished) {
      return 100;
    } else {
      const value = this.state === 0 ? 1 : 2;
      const durationPercentage = ((100 / this.durationPerState[this.state]) * this.duration) * (this.calculatePercentage(value) / 100);
      return this.percent + (durationPercentage);
    }
  }

  textColors = {
    true: '#30bf54',
    false: 'white',
  }
  get textColor() {
    if (this.finished) {
      return this.hasError ? '#d70827' : '#30bf54';
    } else {
      return 'white';
    }
  }
  get finishedStyle() {
    const color = !this.hasError ? '#30bf54' : '#d70827';
    return !this.finished ? '' : `border: 2px solid ${color};`;
  }
  get translations() {
    return {
      0: this.$t('installationWizard.systemTestPage.states.notStarted'),
      1: this.$t('installationWizard.systemTestPage.states.charging'),
      2: this.$t('installationWizard.systemTestPage.states.discharging'),
      3: !this.hasError ? this.$t('installationWizard.systemTestPage.states.finished') : this.$t('installationWizard.systemTestPage.states.error'),
    };
  }
}
