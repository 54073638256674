
import { Component, Prop, Watch } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import { throttle } from 'lodash';

/**
 * Component that represent CurrentValueGaugeChart Basic Control
 */
@Component
export default class CurrentValueGaugeChartBase extends BasicControl {
  @Prop() deviceData!: any;
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop() scaling!: any;
  @Prop({ default: 'unit' }) unit!: string;
  @Prop({ default: false }) isDecimal?: boolean;
  @Prop({ default: null }) rerenderKey!: number;
  @Prop({ default: null }) dndGridContainerWidth!: any;

  options: any = {
    hasNeedle: false,
    rangeLabel: [],
    arcDelimiters: [],
    centralLabel: '',
    chartWidth: null,
    arcColors: [],
  }

  @Watch('$vuetify.theme.dark')
  handleThemeChange() {
    this.setOptions({ arcColors: [this.$vuetify.theme.currentTheme.accent, 'lightgray'] });
    this.$emit('handleRerenderKey');
  }
  @Watch('rerenderKey')
  handleDeviceChange() {
    this.setOptions({
      rangeLabel: [this.scaling.min, this.scaling.max],
      arcDelimiters: [this.defineArcDelimiters],
      centralLabel: `${this.rounded}${this.unit}`,
    });
  }
  @Watch('filteredValue')
  onFilteredValueChange() {
    this.$emit('handleRerenderKey');
  }

  get actualValue(): any {
    return this.measurements.get(this.mappingsClean.actualValue);
  }
  get rounded() {
    if (!this.isDecimal) return Math.round(this.actualValue);
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    return +(`${Math.round(`${this.actualValue}e+2`)}e-2`);
  }
  get filteredValue() {
    if (this.rounded < this.scaling.min) return +this.scaling.min;
    if (this.rounded > this.scaling.max) return +this.scaling.max;
    return this.rounded;
  }

  get defineArcDelimiters() {
    const arcState: number = this.filteredValue * (100 / parseInt(this.scaling.max, 10));
    if (arcState === 0) return 0.01;
    else if (arcState === 100) return 99.99;
    else return arcState;
  }

  setOptions(optionsConfig: any) {
    const copy: any = JSON.parse(JSON.stringify(optionsConfig));
    this.options = { ...this.options, ...copy };
  }

  resizeChart() {
    const element: any = this.$refs.chartDivNormal;
    const isElementWidth: boolean = typeof element?.clientWidth === 'number' && element?.clientWidth < 480;
    const width = isElementWidth ? element.clientWidth : 480;
    this.setOptions({ chartWidth: width });

    this.$emit('handleRerenderKey');
  }
  resizeChartThrottle = throttle(this.resizeChart, 200)

  async mounted() {
    await this.$nextTick();

    this.setOptions({
      rangeLabel: [this.scaling.min, this.scaling.max],
      arcDelimiters: [this.defineArcDelimiters],
      centralLabel: `${this.rounded}${this.unit}`,
      arcColors: [this.$vuetify.theme.currentTheme.accent, 'lightgray'],
    });
    this.resizeChart();
    window.addEventListener('resize', this.resizeChartThrottle);

    if (!this.$vuetify.breakpoint.mobile) {
      const initWidth: number = +(this.dndGridContainerWidth / 2 - 60);
      const width: number = initWidth > 480 ? 480 : initWidth;
      this.setOptions({ chartWidth: width });
    }
  }

  destroyed() {
    window.removeEventListener('resize', this.resizeChartThrottle);
  }

  get isNotMapped(): boolean {
    return this.mappingsClean.actualValue === '';
  }
}
