
import { Component } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';
import { IProjectsState } from '@/store/modules/projects/types';
import { UserRoleCode } from '@/utils/userRoles';
import { convertTimestampToDate } from '@/utils/utilsFunctions';
import { IMember } from '@/types/members.types';
import { IFlatsConfig, ICountersObject } from '@/types/wizards/tenant.types';
import { filterNumberFromString } from '@/utils/tenantWizardUtilsFunctions';
import { IMeasurements } from '@/types/measurements.types';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    BaseChartWrapper,
    InfoTooltip,
  },
})
export default class TenantStatistic extends mixins(Validation) {
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @State('projects') projectState!: IProjectsState;
  @Getter('members/currentMember') currentMember!: IMember;
  @Getter('measurements/measurements') measurements!: IMeasurements;

  errorTimer: any = null;
  showTenantCommunicationStatus = false;
  tenantCommunicationStatusError = false;
  selectedSeriesOption = 'meterReading';
  currentSelectedTenant = 0;
  chartSeriesArrays: Record<string, any[]> = {
    waterCounter: [],
    heatingCounter: [],
    energyCounter: [],
  }
  chartSeriesArraysPower: Record<string, any[]> = {
    energyCounter: [],
  }
  chartSeriesArraysOverall: Record<string, any[]> = {
    waterCounter: [],
    heatingCounter: [],
    energyCounter: [],
  }
  chartSeriesArraysOverallPower: Record<string, any[]> = {
    energyCounter: [],
  }
  availableTenants: any[] = [];
  tenantModel: any = {};
  displayOverallChart = false;
  displayAllDayTariff = false;

  get selectionOptionsChart() {
    const powerMappingLength = this.chartSeriesArraysOverallPower.energyCounter.length ?? this.chartSeriesArraysPower.energyCounter.length ?? 0;
    return [
      { text: `${this.$t('tenantWizard.tenantStatistics.chartSelectionOptions.meterReading')} (kWh)`, value: 'meterReading' },
      ...(powerMappingLength > 0 ?
        [{ text: `${this.$t('tenantWizard.tenantStatistics.chartSelectionOptions.power')} (kW)`, value: 'power' }]
        : []
      ),
    ];
  }

  // enable filter again
  get isCurrentMemberAdmin() {
    return this.currentMember.role === UserRoleCode.admin;
  }

  get consumptionStatDate() {
    if (this.tenantState.tenant.lastPeriod === null) return null;
    if (Object.keys(this.tenantState.tenant.lastPeriod).length === 0) return null;
    return { start: this.tenantState.tenant.lastPeriod.flat_0.start, end: this.tenantState.tenant.lastPeriod.flat_0.end };
  }

  get consumptionStats() {
    if (this.tenantState.tenant.lastPeriod === null) return null;
    if (Object.keys(this.tenantState.tenant.lastPeriod).length === 0) return null;
    if (!this.tenantState.tenant.lastPeriod[`flat_${this.currentSelectedTenant}`]) {
      return {
        pv: 0,
        grid: 0,
      };
    }
    return {
      pv: this.tenantState.tenant.lastPeriod[`flat_${this.currentSelectedTenant}`].pv,
      grid: this.tenantState.tenant.lastPeriod[`flat_${this.currentSelectedTenant}`].grid,
    };
  }

  get energyPrice() {
    const currentSettings = this.tenantState.tenant.general.tariffSettings.electricity;
    const { currency } = this.tenantState.tenant.general.paymentDetails;
    if (currentSettings.allDayTariffGrid !== 0 && currentSettings.allDayTariffProduction !== 0) {
      // returns all day price of production and grid. with value, unit and text
      return {
        priceProduction: {
          value: currentSettings.allDayTariffProduction,
          unit: `${currency}/kWh`,
          text: this.$t('tenantWizard.tenantStatistics.prices.productionTariff'),
        },
        priceGrid: {
          value: currentSettings.allDayTariffGrid,
          unit: `${currency}/kWh`,
          text: this.$t('tenantWizard.tenantStatistics.prices.gridTariff'),
        },
      };
    } else {
      // returns high and low tariff price of production and grid. with value, unit and text
      return {
        highTariffProduction: {
          value: currentSettings.highTariffProduction,
          unit: `${currency}/kWh`,
          text: this.$t('tenantWizard.tenantStatistics.prices.productionHighTariff'),
        },
        lowTariffProduction: {
          value: currentSettings.lowTariffProduction,
          unit: `${currency}/kWh`,
          text: this.$t('tenantWizard.tenantStatistics.prices.productionLowTariff'),
        },
        highTariffGrid: {
          value: currentSettings.highTariffGrid,
          unit: `${currency}/kWh`,
          text: this.$t('tenantWizard.tenantStatistics.prices.gridHighTariff'),
        },
        lowTariffGrid: {
          value: currentSettings.lowTariffGrid,
          unit: `${currency}/kWh`,
          text: this.$t('tenantWizard.tenantStatistics.prices.gridLowTariff'),
        },
      };
    }
  }

  localConvertTimestampToDate(timestamp: number) {
    return convertTimestampToDate(timestamp);
  }

  created() {
    const currentSettings = this.tenantState.tenant.general.tariffSettings.electricity;
    if (currentSettings.allDayTariffGrid !== 0 && currentSettings.allDayTariffProduction !== 0) {
      this.displayAllDayTariff = true;
    }

    // generate available tenants for v-select field (info: field is only visible for admin user)
    this.availableTenants = this.tenantState.tenant.general.flats.flatConfigurations.map((currentFlat: IFlatsConfig, index: number) => {
      return { text: currentFlat.name, value: index };
    });
    this.generateOverallChartOptions();
  }

  checkTenantCommunicationStatus() {
    if (this.measurements['prgEM.bErrorEM'] !== undefined) {
      this.showTenantCommunicationStatus = true;
      this.tenantCommunicationStatusError = this.measurements['prgEM.bErrorEM'] === 1;
    }
  }

  startErrorTimer() {
    this.errorTimer = setInterval(() => {
      // if variable is defined we can show the tenant communication status
      this.checkTenantCommunicationStatus();
    }, 60000);
  }

  mounted() {
    if (this.isCurrentMemberAdmin) {
      // just load first tenant inside of the list
      this.handleTenantChange();

      this.checkTenantCommunicationStatus();
      this.startErrorTimer();
    } else {
      // filter out all flats where user is assigned to
      const availableFlats = this.tenantState.tenant.general.flats.flatConfigurations.filter((currentFlat: IFlatsConfig) => currentFlat.mail === this.currentMember.email);
      this.availableTenants = availableFlats.map((currentFlat: IFlatsConfig, index: number) => {
        return { text: currentFlat.name, value: index };
      });
      this.handleTenantChange();
    }
  }

  destroyed() {
    clearInterval(this.errorTimer);
  }

  /**
   * Generates all series for Energy, Heating, Cold Water and Service Water Charts (flat specific)
   */
  handleTenantChange() {
    this.chartSeriesArrays = {
      waterCounter: [],
      heatingCounter: [],
      energyCounter: [],
    };
    this.chartSeriesArraysPower = {
      energyCounter: [],
    };
    const tenantCounters: any = this.tenantState.tenant.general.flats.flatConfigurations[this.currentSelectedTenant].counters;
    Object.entries(tenantCounters).forEach((value: [string, any]) => {
      // if value or counter list is null, return
      if (!value || tenantCounters[value[0]] === null) return;
      tenantCounters[value[0]].forEach((counter: any, index: number) => {
        const seriesName = counter.name ?? '';
        let unit = '';
        let key = '';

        if (value[0] === 'coldWaterCounter' || value[0] === 'serviceWaterCounter') {
          unit = 'm³';
          key = 'waterCounter';
        } else if (value[0] === 'heatingCounter' || value[0] === 'energyCounter' || value[0] === 'coolingCounter') {
          unit = 'kWh';
          if (value[0] === 'heatingCounter' || value[0] === 'coolingCounter') {
            key = 'heatingCounter';
          } else {
            key = 'energyCounter';
          }
        }

        this.chartSeriesArrays[key].push({
          agg: 'diff',
          name: seriesName,
          scaling: {
            max: 5,
            min: 0,
          },
          type: 'column',
          unit,
          var: counter.id,
        });

        // add power series to the chart (currently only avaliable for electricity counters)
        const counterId = counter.id;
        if (counterId.includes('prgEM.')) {
          const number = filterNumberFromString(counterId);
          this.chartSeriesArraysPower[key].push({
            agg: 'avg',
            name: seriesName,
            scaling: {
              max: null,
              min: null,
            },
            type: 'line',
            unit: 'kW',
            var: `prgEM.lrPower_E_${number}`,
          });
        }
      });
    });
  }

  /**
   * Generates chart options (flat specific)
   */
  chartOptionByType(type: string) {
    if (this.selectedSeriesOption === 'power') {
      return {
        name: '',
        data: {
          chartOptions: this.chartSeriesArraysPower[type],
          selectedStackingOptions: null,
          selectedWidth: 'full',
          type: 'chart',
        },
      };
    }
    return {
      name: '',
      data: {
        chartOptions: this.chartSeriesArrays[type],
        selectedStackingOptions: null,
        selectedWidth: 'full',
        type: 'chart',
      },
    };
  }

  /**
   * Generates chart (flat specific)
   */
  chartDataByType(type: string) {
    return {
      chartWidth: null,
      chartHeight: 400,
      chartData: this.chartOptionByType(type),
      navigationItemsToExclude: ['live', 'hour'],
    };
  }

  /**
   * generates chart options needed for the overall counters chart
   */
  generateOverallChartOptions() {
    this.chartSeriesArraysOverall = {
      waterCounter: [],
      heatingCounter: [],
      energyCounter: [],
    };
    this.chartSeriesArraysOverallPower = {
      energyCounter: [],
    };
    Object.keys(this.chartSeriesArraysOverall).forEach((key: string) => {
      let tenantCounters: any = [];
      switch (key) {
        case 'energyCounter':
          tenantCounters = this.tenantState.tenant.general.overallCounters.energyCounter ?? [];
          break;
        case 'waterCounter':
          tenantCounters = [...this.tenantState.tenant.general.overallCounters.coldWaterCounter ?? [], ...this.tenantState.tenant.general.overallCounters.serviceWaterCounter ?? []];
          break;
        case 'heatingCounter':
          tenantCounters = [...this.tenantState.tenant.general.overallCounters.heatingCounter ?? [], ...this.tenantState.tenant.general.overallCounters.coolingCounter ?? []];
          break;
      }
      tenantCounters.forEach((counter: ICountersObject) => {
        const seriesName = counter.name ?? '';
        let unit = '';

        if (key === 'waterCounter') {
          unit = 'm³';
        } else if (key === 'heatingCounter' || key === 'energyCounter') {
          unit = 'kWh';
        }

        this.chartSeriesArraysOverall[key].push({
          agg: 'diff',
          name: seriesName,
          scaling: {
            max: 30,
            min: 0,
          },
          type: 'column',
          unit,
          var: counter.id,
        });

        // add power series to the chart (currently only avaliable for electricity counters)
        const counterId = counter.id;
        if (counterId.includes('prgEM.')) {
          const number = filterNumberFromString(counterId);
          this.chartSeriesArraysOverallPower[key].push({
            agg: 'avg',
            name: seriesName,
            scaling: {
              max: null,
              min: null,
            },
            type: 'line',
            unit: 'kW',
            var: `prgEM.lrPower_E_${number}`,
          });
        }
      });
    });
  }

  /**
   * Generates chart options (flat specific)
   */
  overallChartOptionByType(type: string) {
    if (this.selectedSeriesOption === 'power') {
      return {
        name: '',
        data: {
          chartOptions: this.chartSeriesArraysOverallPower[type],
          selectedStackingOptions: null,
          selectedWidth: 'full',
          type: 'chart',
        },
      };
    }
    return {
      name: '',
      data: {
        chartOptions: this.chartSeriesArraysOverall[type],
        selectedStackingOptions: null,
        selectedWidth: 'full',
        type: 'chart',
      },
    };
  }

  /**
   * Generates chart (flat specific)
   */
  overallChartDataByType(type: string) {
    return {
      chartWidth: null,
      chartHeight: 400,
      chartData: this.overallChartOptionByType(type),
      navigationItemsToExclude: ['live', 'hour'],
    };
  }

  /**
   * Checks if chart should be shown. based on the amount of series in the chart
   */
  shouldShowChart(isOverall: boolean, key: any) {
    if (isOverall) {
      if (this.selectedSeriesOption === 'power') {
        return this.chartSeriesArraysOverallPower[key].length !== 0;
      } else {
        return this.chartSeriesArraysOverall[key].length !== 0;
      }
    } else {
      if (this.selectedSeriesOption === 'power') {
        return this.chartSeriesArraysPower[key].length !== 0;
      } else {
        return this.chartSeriesArrays[key].length !== 0;
      }
    }
  }
}
