
import { Vue, Component } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';
import stockInit from 'highcharts/modules/stock';
import Highcharts from 'highcharts';
import DeviceDescription from '@/ui/components/devices/actions/DeviceDescription.vue';

stockInit(Highcharts);

@Component({
  components: {
    highcharts: Chart,
    DeviceDescription,
  },
})
export default class HistoryAnomalyDetection extends Vue {
  chartWidth: null | number = null

  get description() {
    return this.$t('anomalyDetectionDescriptions.HistoryAnomalyDetection');
  }

  get chartOptions() {
    return {
      chart: {
        animation: false,
        backgroundColor: this.$vuetify.theme.currentTheme.deviceBackground,
        width: this.chartWidth,
      },
      exporting: {
        enabled: false,
      },
      rangeSelector: {
        enabled: false,
      },
      navigator: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
      },
      series: [
        {
          name: 'Temperature',
          type: 'spline',
          yAxis: 0,
          color: '#0037FF',
          data: [
            [951861600000, 1200],
            [951948000000, 2000],
            [952034400000, 900],
            [952120800000, 1950],
            [952207200000, 600],
            [952293600000, 600],
            [952380000000, 1900],
            [952466400000, 800],
            [952552800000, 1900],
            [952639200000, 800],
            [952725600000, 1750],
            [952812000000, 500],
            [952898400000, 500],
            [952984800000, 1700],
            [953071200000, 1000],
          ],
        },
        {
          name: 'AnomalyScore',
          type: 'line',
          yAxis: 1,
          color: '#FF0000',
          data: [
            [951861600000, 5],
            [951948000000, 4],
            [952034400000, 6],
            [952120800000, 5],
            [952121200000, 5],
            [952207200000, 6],
            [952293000000, 22],
            [952293600000, 4],
            [952294000000, 5],
            [952380000000, 6],
            [952466400000, 7],
            [952552800000, 4],
            [952639200000, 6],
            [952725600000, 6],
            [952812000000, 6],
            [952898400000, 20],
            [952984800000, 4],
            [953071200000, 6],
          ],
        },
      ],
      legend: {
        enabled: true,
        itemStyle: {
          color: this.$vuetify.theme.dark ? '#ffffff' : '#000000',
        },
      },
      plotOptions: {
        series: {
          connectNulls: true,
        },
      },
      xAxis: {},
      yAxis: [
        {
          title: null,
          opposite: false,
          showFirstLabel: true,
          showLastLabel: true,
          endOnTick: false,
          min: 0,
          max: 2000,
          lineColor: '#0037FF',
          labels: {
            format: '{value} kW',
            style: {
              color: '#0037FF',
            },
          },
        },
        {
          title: null,
          plotLines: [{
            value: 25,
            color: '#cccc00',
            dashStyle: 'solid',
            width: 2,
            label: {
              text: 'Threshold (0-100%)',
              align: 'right',
              y: -5,
              x: -10,
            },
          }],
          opposite: false,
          showFirstLabel: true,
          showLastLabel: true,
          endOnTick: false,
          gridLineWidth: 0,
          min: 0,
          max: 100,
          lineColor: '#FF0000',
          labels: {
            format: '{value} %',
            style: {
              color: '#FF0000',
            },
          },
        },
      ],
    };
  }

  async mounted() {
    await this.$nextTick();
    this.chartWidth = (this.$refs['hc-wrapper'] as any).$el?.clientWidth;
  }
}
