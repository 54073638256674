var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"chartDiv",staticClass:"gauge",attrs:{"id":"chartDiv"}},[_c('VueGauge',{key:_vm.chartKey,staticStyle:{"margin-top":"10px"},attrs:{"refid":"previewPVMonitoring","options":{
      arcLabels:["25%","50%","75%"],
      needleValue: 80,
      needleColor: "black",
      needleStartValue:0,
      hasNeedle: true,
      rangeLabel: ["0","100"],
      arcDelimiters: [25,50,75],
      chartWidth: `${_vm.gaugeWidth}`,
      arcColors: [`${_vm.$vuetify.theme.currentTheme.error}`,`${_vm.$vuetify.theme.currentTheme.error}`,"#FF9800","#30BF54"
    ]}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }