
import { Component, Prop, Watch } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

@Component
export default class SliderRGBCanvas extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop() isActive?: boolean;
  @Prop() state!: any;

  timer2: any
  valueChangingTimer: any

  sliderValue: any = {
    r: 255,
    g: 0,
    b: 0,
  }
  sliderValuesStorage: any = []
  sendingValues: any = []
  isSliderValueChanging = false
  isSliderValueChangingWithTimeout = false

  @Watch('isActive')
  onDimmerOff(val: boolean) {
    if (val) {
      this.sliderValue = this.state;
    }
    if (!val) {
      clearInterval(this.valueChangingTimer);
      this.isSliderValueChangingWithTimeout = false;
      this.sliderValue = { r: 255, g: 0, b: 0 };
    }
  }
  @Watch('state', { deep: true })
  onStateChange(val: any) {
    if (!this.isSliderValueChangingWithTimeout) {
      this.sliderValue = val;
    }
  }
  @Watch('isSliderValueChangingWithTimeout')
  onSliderValueChangingWithTimeout(val: boolean) {
    if (!val) this.sliderValue = this.state;
  }
  @Watch('isSliderValueChanging')
  onSliderValueChanging(val: boolean) {
    if (val) {
      clearInterval(this.valueChangingTimer);
      this.timer2 = setInterval(() => {
        const snapshot = JSON.stringify(this.sliderValue);
        this.sendingValues.push(snapshot);
        this.send([{ v: this.sliderValue.b, n: this.mappingsClean.targetValue_blue, u: '' }]);
        this.send([{ v: this.sliderValue.g, n: this.mappingsClean.targetValue_green, u: '' }]);
        this.send([{ v: this.sliderValue.r, n: this.mappingsClean.targetValue_red, u: '' }]);

        if (this.sliderValuesStorage[this.sliderValuesStorage.length - 1] === this.sendingValues[this.sendingValues.length - 1]) {
          this.isSliderValueChanging = false;
          this.sendingValues = [];
          this.sliderValuesStorage = [];
        }
      }, 350);
    }
    if (!val) {
      clearInterval(this.timer2);
      this.valueChangingTimer = setTimeout(() => {
        this.isSliderValueChangingWithTimeout = false;
      }, 10000);
    }
  }

  handleState(value: any) {
    this.sliderValue = value;
    this.sliderValuesStorage.push(JSON.stringify(this.sliderValue));
    // clearInterval(this.valueChangingTimer);
    if (JSON.stringify(this.state) !== JSON.stringify(this.sliderValue)) {
      this.isSliderValueChanging = true;
      this.isSliderValueChangingWithTimeout = true;
    }
  }

  /**
   * Used to disable the Base component if there is no variable set in the mappings of the device.
   */
  get isNotMapped() {
    return this.mappingsClean.targetValue_blue === ''
      || this.mappingsClean.targetValue_green === ''
      || this.mappingsClean.targetValue_red === ''
      || this.state === undefined;
  }

  created() {
    this.sliderValue = this.state;
    this.isSliderValueChanging = false;
    this.isSliderValueChangingWithTimeout = false;
  }

  beforeDestroy() {
    clearInterval(this.timer2);
  }
}
