
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';

/**
 * Component that represent Minus Basic Control
 */
@Component({
  components: { FeedbackProvider },
})
export default class MinusBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop({ default: false }) isPushButtonBehavior!: boolean;
  @Prop({ default: 'red' }) color!: string;
  @Prop({ default: false }) fab!: boolean;
  @Prop({ default: 'minus' }) icon!: string;
  @Prop({
    default: 'small',
    validator: (value: any): boolean => [null, 'x-small', 'small', 'large', 'x-large'].some((el: string | null) => el === value),
  }) buttonSizeClass!: string; // vuetify btn classes: x-small, small, large, x-large

  /**
   * Used to disable the Base component if there is no variable set in the mappings of the device.
   */
  get isNotMapped() {
    return this.mappingsClean.commandMinus === '';
  }

  handleMinus() {
    this.send([{ v: 1, n: this.mappingsClean.commandMinus, u: '' }]);
    this.send([{ v: 0, n: this.mappingsClean.commandMinus, u: '' }]);
  }

  down() {
    this.send([{ v: 1, n: this.mappingsClean.commandMinus, u: '' }]);
  }
  up() {
    this.send([{ v: 0, n: this.mappingsClean.commandMinus, u: '' }]);
  }

  onStateChange(fn: any) {
    this.onSendStart();
    fn();
    this.onSendSuccess();
  }
}
