
import { Component, Vue, Prop } from 'vue-property-decorator';
import NormalReport from '@/ui/components/modals/ManageReport/NormalReport.vue';
import ZevReport from '@/ui/components/modals/ManageReport/ZevReport.vue';
import { Action, Getter } from 'vuex-class';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import { IReport } from '@/types/report.types';
import { UserRoleCode } from '@/utils/userRoles';
import { IMember } from '@/types/members.types';

/**
 * A component that allows to create or modify reports
 */
@Component({
  computed: {
    UserRoleCode: () => UserRoleCode,
  },
  components: {
    NormalReport,
    ZevReport,
    DeleteModalForm,
  },
})
export default class EditReport extends Vue {
  @Prop() report!: any;
  @Getter('members/currentMember') currentMember!: IMember;
  @Action('report/modifyReport') modifyReport!:
    ({ id, report_id, reportModel }: { id: string; report_id: string; reportModel: IReport }) => Promise<void>;
  @Action('report/deleteReport') deleteReport!:
    ({ project_id, report_id }: { project_id: string; report_id: string }) => Promise<void>;
  @Action('report/addReport') addReport!: ({ id, reportModel }: { id: string; reportModel: IReport }) => Promise<void>;

  isFormValid = true;
  reportType = '';

  get reportTypesList() {
    const translations: any = this.$t('modals.manageReport.newReportListItems');
    return [
      { state: translations[1], abbr: 'heatingReport' },
      { state: translations[2], abbr: 'coldWaterReport' },
      { state: translations[3], abbr: 'hotWaterReport' },
      { state: translations[4], abbr: 'electricityReport' },
      { state: translations[5], abbr: 'otherReport' },
    ];
  }

  get reportsNames() {
    return {
      normal: 'NormalReport',
      zev: 'ZevReport',
      heatingReport: 'NormalReport',
      coldWaterReport: 'NormalReport',
      hotWaterReport: 'NormalReport',
      electricityReport: 'NormalReport',
      otherReport: 'NormalReport',
    };
  }

  mounted() {
    // new reports all have showInList prop. If it's not there, it's an old report
    if ((this.$refs.CurrentReport as any).reportDataToSend.meta.showInList !== undefined) {
      this.reportType = (this.$refs.CurrentReport as any).reportDataToSend.meta.showInList;
    }
    if (this.report.type === 'zev') {
      this.reportType = 'zev';
    }
  }

  async updateReport() {
    const modelForUpdate = (this.$refs.CurrentReport as any).reportDataToSend;
    if (modelForUpdate.type === 'normal' && this.reportType !== '') {
      modelForUpdate.meta.showInList = this.reportType;
    }
    await this.modifyReport({
      id: this.$route.params.id,
      report_id: (this.$refs.CurrentReport as any).reportDataToSend.id,
      reportModel: (this.$refs.CurrentReport as any).reportDataToSend,
    });
  }
  async removeReport() {
    await this.deleteReport({
      project_id: this.$route.params.id,
      report_id: (this.$refs.CurrentReport as any).reportDataToSend.id,
    });
  }
  async duplicateReport() {
    const newModel = (this.$refs.CurrentReport as any).reportDataToSend;
    delete newModel.id;
    newModel.name += '(copy)';
    await this.addReport({
      id: this.$route.params.id,
      reportModel: newModel,
    });
    // will close the currently open expansion panel
    this.$emit('duplicationDone');
  }
}
