
import { Vue, Component, Prop } from 'vue-property-decorator';
import SystemModalWindowView from '@/ui/components/devices/components/EnergyParts/SystemModalWindowView.vue';
import RotatingBacklight from '@/ui/components/devices/components/EnergyParts/RotatingBacklight.vue';
import InstanceView from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/GeneratorSystem/Forecast/InstanceView.vue';
import TimeSlider from '@/ui/components/devices/components/EnergyParts/TimeSlider.vue';
import AnimatedLine from '@/ui/components/devices/components/EnergyParts/AnimatedLine/index.vue';
import Preview from '@/ui/components/devices/components/EnergyParts/Preview.vue';
import CentralEndpoint from '@/ui/components/devices/components/EnergyParts/CentralEndpoint.vue';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import { throttle } from 'lodash';
import { generateForecastSystems } from '@/ui/components/devices/components/EnergyParts/EMS/utils';

@Component({
  components: {
    SystemModalWindowView,
    AnimatedLine,
    RotatingBacklight,
    InstanceView,
    TimeSlider,
    Preview,
    CentralEndpoint,
  },
})
export default class Forecast extends Vue {
  @Prop({ default: null }) systemData!: any;
  @Prop({ default: null }) allSystemsForecastData!: any;
  @Prop({ default: null }) systemCount!: number;
  @Prop({ default: 0 }) groupSliderState!: number;
  @Prop({ default: null }) canvasSize!: any;
  @Prop({ default: null }) canvasCenter!: any;
  @Prop({ default: null }) lineData!: any;
  @Prop({ default: false }) lineReady!: boolean;
  @State('measurements') measurementsState!: IVariablesState;

  instancesCanvasSize: any = null;
  instancesCanvasCenter: any = null;
  instancesCanvasLines: any = {};

  systemSlider = 0;

  get measurements() {
    return this.measurementsState.measurements;
  }

  get instancesTopLine() {
    return Object.keys(this.systemData).slice(0, 5);
  }

  get instancesBottomLine() {
    return Object.keys(this.systemData).slice(5, 10);
  }

  get systemsForecastData() {
    return generateForecastSystems(Object.keys(this.systemData), this.allSystemsForecastData);
  }

  get sumSystemsForecastDataFiltered() {
    return Object.values(this.systemsForecastData).map(
      (system: any) => system[this.groupSliderState] || [null, null],
    );
  }

  get sumForecast() {
    const values = Object.values(this.sumSystemsForecastDataFiltered).map((el: any) => el[1]);
    const checkEmpty = values.filter((el: any) => typeof el === 'number');
    if (checkEmpty.length) {
      const num: any = values.reduce((a: number, b: number) => a + b);
      return +num.toFixed(2);
    } else {
      return 0;
    }
  }

  get animatedLineMovement() {
    return this.sumForecast > 0;
  }

  get animatedLineActive() {
    return !!this.sumForecast;
  }

  get timeSliderInitArr() {
    if (this.systemsForecastData) {
      return Object.values(this.systemsForecastData)[0];
    }
    return [];
  }

  /**
   * Return system instance values relative to the selected value on the slider
   * @return {object}
   */
  get systemsForecastDataFiltered() {
    let obj = {};
    Object.entries(this.systemsForecastData).forEach((system: any) => {
      const res = system[1][this.systemSlider] || [null, null];
      obj = { ...obj, ...{ [system[0]]: res } };
    });
    return obj;
  }

  /**
   * Load line if all coords and sizes ready
   * @return {boolean} load line status
   */
  get isDataForInstancesAnimatedLineReady() {
    return (
      !!this.instancesCanvasSize &&
      !!this.instancesCanvasCenter &&
      !!Object.values(this.instancesCanvasLines)
    );
  }

  /**
   * Defines coordinates of systems, Central Energy Point.
   * Use vm.$refs to retrieve references to elements.
   * Then it goes through the list of references and creates an options object for each one.
   */
  getInstancesCoords() {
    const central: any = this.$refs.instancesCentralEndpoint;
    const actualViewRef: any = this.$refs.instancesCanvas;
    this.instancesCanvasSize = {
      width: actualViewRef?.clientWidth,
      height: actualViewRef?.clientHeight,
    };
    this.instancesCanvasCenter = {
      x: central.offsetLeft + central.offsetWidth / 2,
      y: central.offsetTop + central.offsetHeight / 2,
      w: central.offsetWidth,
      h: central.offsetHeight,
    };

    [...this.instancesTopLine, ...this.instancesBottomLine].forEach((system: any) => {
      const target: any = this.$refs[system];
      if (!target.length) return;

      this.instancesCanvasLines[system] = {
        key: system,
        x: target[0].offsetLeft + target[0].offsetWidth / 2,
        y: target[0].offsetTop + target[0].offsetHeight / 2,
        w: target[0].offsetWidth,
        h: target[0].offsetHeight,
      };
    });
  }

  getInstancesCoordsThrottle = throttle(this.getInstancesCoords, 500);

  /**
   * When you open the system grid window,
   * get the current coordinates of the systems, Central Energy Point.
   * Define sizes.
   * @param {boolean} isContentVisible system grid visible status
   */
  async handleSystemModalWindowContent(isContentVisible: boolean) {
    if (isContentVisible) {
      await this.$nextTick();
      window.addEventListener('resize', this.getInstancesCoordsThrottle);
      this.getInstancesCoords();
    } else {
      window.removeEventListener('resize', this.getInstancesCoordsThrottle);
    }
    this.systemSlider = this.groupSliderState;
  }
}
