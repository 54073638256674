import { render, staticRenderFns } from "./VariableTextInputField.vue?vue&type=template&id=88bb0aea&scoped=true&"
import script from "./VariableTextInputField.vue?vue&type=script&lang=ts&"
export * from "./VariableTextInputField.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88bb0aea",
  null
  
)

export default component.exports