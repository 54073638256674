
import { Component, Prop } from 'vue-property-decorator';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import SwitchBase from '@/ui/components/devices/devices/base/SwitchBase.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent ThermostatAnalog device
 */
@Component({
  components: {
    InputFieldBase,
    SwitchBase,
    ShowEventDotBase,
    DeviceLayout,
    LabelUnitWrapper,
  },
})
export default class ThermostatAnalog extends mixins(Validation) {
  @Prop() deviceData!: IDevice;
  @State('measurements') measurementsState!: IVariablesState;

  get langPath() {
    return `devices.${this.deviceData.data.type}`;
  }
  get measurements() {
    return this.measurementsState.measurements;
  }
}
