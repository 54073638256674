
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { rules } from '@/store/modules/rules';

/**
 * Component that allows to delete project
 */
@Component({
  computed: {
    rules() {
      return rules;
    },
  },
})
export default class DeleteModalForm extends Vue {
  @Prop({ default: '' }) deletedItemName!: string;
  @Prop({ default: false }) disabled?: boolean;

  dialog = false
  showAbortDelete = false;
  deletionWasAborted = false;
  deletionOnGoing = false;
  enteredName = '';
  totalSeconds = 5;
  secondsLeft = 5;
  progressBarWidth = 0;
  get enteredNameEqualsDeletedItemName() {
    // trim to remove whitespaces at the beginning and end. to avoid confusion
    return this.enteredName.trim() === this.deletedItemName.trim();
  }

  abortDeletion() {
    this.progressBarWidth = 0;
    this.showAbortDelete = false;
    this.deletionWasAborted = true;
    this.enteredName = '';
    this.secondsLeft = this.totalSeconds;
    this.dialog = false;
    this.deletionOnGoing = false;
  }

  resetAll() {
    this.showAbortDelete = false;
    this.enteredName = '';
    this.secondsLeft = this.totalSeconds;
    this.dialog = false;
    this.deletionOnGoing = false;
    this.deletionWasAborted = false;
  }

  handleDelete() {
    this.showAbortDelete = true;
    this.deletionOnGoing = true;
    this.progressBarWidth = 100;

    const interval = setInterval(() => {
      this.secondsLeft--;

      if (this.secondsLeft === 0 || this.deletionWasAborted) {
        clearInterval(interval);
        if (!this.deletionWasAborted) {
          this.$emit('deleteHandler');
        }
        this.resetAll();
      }
    }, 1000);
  }
}
