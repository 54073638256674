import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import { Map } from 'immutable';
import { IMemberState } from '@/store/modules/members/types';
import { IMember } from '@/types/members.types';
import {
  canAccess,
  userRolesKeys,
  UserRoleCode,
  UserRoleName,
} from '@/utils/userRoles';
import i18n from '@/ui/plugins/i18n';
import MemberService from '@/services/MemberService';
import { cloneDeep } from 'lodash';

const state: IMemberState = {
  members: Map(),
  currentMember: null,
};

const getters: GetterTree<IMemberState, RootState> = {
  members(state: IMemberState) {
    return state.members.valueSeq().toJS();
  },
  currentMember(state: IMemberState) {
    return state.currentMember;
  },
  currentMemberAreasPositions(state: IMemberState) {
    return state.currentMember?.meta?.area_positions || [];
  },
  currentMemberDevicesPositions(state: IMemberState) {
    return state.currentMember?.meta?.device_positions;
  },
  currentUserRole(state: IMemberState) {
    if (state.currentMember !== null) {
      return userRolesKeys[state.currentMember.role];
    } else {
      return UserRoleName.readOnly;
    }
  },
  canMemberAccess: (state: IMemberState) => (permissionCategory: string, permissionName: string) => {
    return canAccess(
      state.currentMember?.role ?? UserRoleCode.readOnly,
      permissionCategory,
      permissionName,
    );
  },
  currentMemberFavoriteDevicesPositions(state: IMemberState) {
    return state.currentMember?.meta?.favorite_positions || [];
  },
  currentMemberFavoriteDevices(state: IMemberState) {
    return state.currentMember?.meta?.favorite_devices || [];
  },
};

const mutations: MutationTree<IMemberState> = {
  setMembers(state: IMemberState, members: Map<string, IMember>) {
    state.members = members;
  },
  setMember(state, member: IMember) {
    state.members = state.members.set(member.id, member);
  },
  setCurrentMember(state: IMemberState, member: IMember) {
    state.currentMember = member;
  },
};

const actions: ActionTree<IMemberState, RootState> = {
  /**
   * Creates new member
   * @param commit
   * @param state
   * @param rootState
   * @param member member data
   * @param showMessage
   */
  async createMember({ commit, state, rootState }, data: { member: IMember; showMessage: boolean}) {
    try {
      let roleForCreation = UserRoleCode.readOnly;
      if (data.member?.role !== undefined) {
        roleForCreation = data.member.role;
      }
      const res = await MemberService.createMember(
        rootState.projects.projectId as string,
        { id: data.member.id, role: roleForCreation },
      );
      commit('setMembers', state.members.set(data.member.id, res));
      return res;
    } catch (e) {
      if (data.showMessage) {
        commit('app/setReport', {
          type: 'error',
          message: i18n.t('errorMessages.apiErrorTexts.memberLimitReached'),
          value: true,
        }, { root: true });
      }
      return { status: 'Error', value: data.member };
    }
  },

  /**
   * Updates selected member
   * @param commit
   * @param state
   * @param rootState
   * @param member member data
   */
  async updateMember({ commit, state, rootState }, member: IMember) {
    try {
      const { role, owner, id } = member;

      const res = await MemberService.updateMember(rootState.projects.projectId as string, id, { role, owner });
      commit('setMembers', state.members.set(res.id, res));
      commit('app/setReport', { type: 'success', message: i18n.t('uiComponents.reportMessages.memberUpdated'), value: true }, { root: true });
      return res;
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * Delete selected member
   * @param commit
   * @param state
   * @param rootState
   * @param member member data
   */
  async deleteMember({ commit, state, rootState }, member: IMember) {
    try {
      await MemberService.deleteMember(rootState.projects.projectId as string, member.id);
      commit('setMembers', state.members.delete(member.id));
      commit('app/setReport', { type: 'success', message: i18n.t('uiComponents.reportMessages.memberRemoved'), value: true }, { root: true });
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * Load members attached to project
   * @param commit
   * @param state
   * @param projectId project id
   */
  async fetchMembers({ commit, state }, projectId) {
    try {
      const res = await MemberService.fetchMembers(projectId);
      const members = (res as IMember[]).reduce((acc, cur) => acc.set(cur.id, cur), Map<string, IMember>());
      commit('setMembers', members);
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * Load member
   * @param commit
   * @param state
   * @param rootState
   * @param memberId member id
   * @param projectId project id
   */
  async fetchMember({ commit, state, rootState }, { memberId, projectId }) {
    try {
      const res = await MemberService.fetchMember(projectId, memberId);
      commit('setCurrentMember', res);
    } catch (e) {
      console.log(e);
    }
  },

  /**
   * Update member meta, used to save areas, devices positions by member id`s
   * @param object
   * @param payload
   */
  async updateMemberMeta({ commit, state, rootState }, { project_id, member }) {
    try {
      await MemberService.updateMemberMeta(project_id, member.id, { meta: member.meta });
      commit('setMembers', state.members.set(member.id, member));
      commit('setCurrentMember', member);
      commit('app/setReport', { type: 'success', message: i18n.t('uiComponents.reportMessages.memberUpdated'), value: true }, { root: true });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  /**
   * Update member collections, that is used to filter out rooms that a specific user can or can not see
   * @param projectId Project ID as string
   * @param memberId User ID as string
   * @param collectionsList Array of Room ID's
   */
  async updateMemberCollections({ commit, state }, { project_id, member, collectionsList }) {
    try {
      await MemberService.updateMemberCollections(project_id, member.id, collectionsList);
      const m = cloneDeep(member);
      m.collections = collectionsList;
      commit('setMember', m);
      commit('app/setReport', { type: 'success', message: 'member was updated', value: true }, { root: true });
    } catch (e) {
      commit('app/setReport', { type: 'error', message: e.message, value: true }, { root: true });
    }
  },
};

export const members: Module<IMemberState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
