
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { defineIoSystemType } from '../../../components/EnergyParts/EMS/utils';

@Component({
  methods: {defineIoSystemType}
})
export default class SystemsNavigation extends Vue {
  @Prop({default: 'SetpointOptimizer'}) systemName!: string;
  @Prop({default: null}) items!: any;
  @Prop({default: true}) isListItemsPlural!: boolean;

  // Selected Radio Button in Menu
  selected: any = null;

  // Highlighted Tab
  selectedGroup = 0;

  // Rendered Menu for tab that was clicked on
  currentGroup = 0;

  // Displayed menu when clicked on tab
  menuGroup = 0;
  rerenderKey = 0;

  get themeColor() {
    return this.$vuetify.theme.dark ? '#313131' : '#f2f2f2';
  }

  groupLength(group: any) {
    if (this.systemName === 'EnergyIO') return Object.keys(group[1].energy_counter).length;
    return group[1].length === 1;
  }

  defineMenuItemName(system: string) {
    if (this.isListItemsPlural) return this.$t(`mlModel.${this.systemName}.systems.${system}.title`);
    else return this.$t(`mlModel.${this.systemName}.systems.${system}.single`);
  }

  defineMenuItemCount(arr: any) {
    const filterFromNotNumber = (str: string) => +str.replace(/\D+/g, '');
    if (!arr.length) return null;
    if (arr.length === 1) return filterFromNotNumber(arr[0]);
    else {
      const first: any = arr[0];
      const last: any = arr[arr.length - 1];
      return `${filterFromNotNumber(first)}-${filterFromNotNumber(last)}`;
    }
  }

  // Changes Tab Selection and opens selected chart
  handleCurrentChart(group: any, index: number, groupIndex: number) {
    const system = group[0];
    let arr = [];
    if (this.systemName === 'EnergyIO') {
      if (system.includes('hybrid')) arr = ['instance'];
      else arr = Object.keys(group[1].energy_counter);
    } else {
      // eslint-disable-next-line prefer-destructuring
      arr = group[1][index];
    }
    this.$emit('handleCurrentChart', { system, arr }, groupIndex);
    this.selected = system + index;
    this.selectedGroup = groupIndex;
    this.rerenderKey++;
  }

  // Selects clicked menu and open it using v-menu's "on" function
  handleClick(func: any, index: any, event: any) {
    this.currentGroup = index;
    this.menuGroup = index;
    func.click(event);
  }

  created() {
    this.selected = this.items[0] ? this.items[0][0] + 0 : 0;
  }
}
