
import { Component, Prop } from 'vue-property-decorator';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import SliderBase from '@/ui/components/devices/devices/base/SliderBase.vue';
import Switch2VBase from '@/ui/components/devices/devices/base/Switch2VBase.vue';
import PreviewMQTTControls
  from '@/ui/components/devices/components/EnergyParts/PreviewMQTTControls.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';

/**
 * Electric heating single system settings.
 */
@Component({
  components: {
    InputFieldBase,
    OutputFieldBase,
    ShowEventDotBase,
    SliderBase,
    Switch2VBase,
    PreviewMQTTControls,
    LabelUnitWrapper,
    ModalWindow,
  },
})
export default class ElectricHeatingSystemSettings extends mixins(Validation) {
  @Prop({ default: null }) instanceData!: any;
  @State('measurements') measurementsState!: IVariablesState;

  get measurements() {
    return this.measurementsState.measurements;
  }

  get outputFieldActualPower() {
    return {
      'OutputField_actualValue': this.instanceData.power,
    };
  }
  get outputFieldTemperatureBoiler() {
    return {
      'OutputField_actualValue': this.instanceData.temperature,
    };
  }
  get showEventDotStateHeating() {
    return {
      'ShowEventDot_errorWarningState': this.instanceData.state_electric_heating,
    };
  }
  get outputFieldTargetPowerHeating() {
    return {
      'OutputField_actualValue': this.instanceData.target_power,
    };
  }

  get switchP14A() {
    return {
      'Switch2V_onOff': this.instanceData.switch_p14a,
      'Switch2V_state': this.instanceData.state_p14a,
    };
  }

  get inputFieldHourOn() {
    return {
      'InputField_targetValue': this.instanceData.hour_on,
    };
  }
  get inputFieldMinOn() {
    return {
      'InputField_targetValue': this.instanceData.minute_on,
    };
  }
  get inputFieldHourOff() {
    return {
      'InputField_targetValue': this.instanceData.hour_off,
    };
  }
  get inputFieldMinOff() {
    return {
      'InputField_targetValue': this.instanceData.minute_off,
    };
  }
  get inputFieldTargetTempOn() {
    return {
      'InputField_targetValue': this.instanceData.target_temp_on,
    };
  }
  get inputFieldTargetTempOff() {
    return {
      'InputField_targetValue': this.instanceData.target_temp_off,
    };
  }
  get inputFieldTargetTempMax() {
    return {
      'InputField_targetValue': this.instanceData.target_temp_max,
    };
  }

  get sliderMappings() {
    return {
      'Slider_targetValue': this.instanceData.slider_manual,
    };
  }
  get sliderActualValueState() {
    return this.measurements.get(this.instanceData.slider_target_power);
  }
  get sliderIsActive() {
    return true;
  }

  get inputFieldPriorityEMS() {
    return {
      'InputField_targetValue': this.instanceData.priority,
    };
  }
  get inputFieldEnableSoc() {
    return {
      'InputField_targetValue': this.instanceData.enable_soc,
    };
  }
  get inputFieldDisableSOC() {
    return {
      'InputField_targetValue': this.instanceData.disable_soc,
    };
  }

  get switch2VManualOn() {
    return {
      'Switch2V_onOff': this.instanceData.switch_manual,
      'Switch2V_state': this.instanceData.state_manual,
    };
  }
  get switch2VOnEmergencyPowerOff() {
    return {
      'Switch2V_onOff': this.instanceData.switch_emergency,
      'Switch2V_state': this.instanceData.state_emergency,
    };
  }
  get switch2VActivateTimeControl() {
    return {
      'Switch2V_onOff': this.instanceData.switch_time,
      'Switch2V_state': this.instanceData.state_time,
    };
  }
  get switch2VDisableLegionellaProtection() {
    return {
      'Switch2V_onOff': this.instanceData.switch_disable_protection,
      'Switch2V_state': this.instanceData.state_disable_protection,
    };
  }
  get switch2VEnableHeatingElement() {
    return {
      'Switch2V_onOff': this.instanceData.switch_enable,
      'Switch2V_state': this.instanceData.state_enable,
    };
  }

  get maxPowerList() {
    return Object.keys(this.instanceData.max_power);
  }
}
