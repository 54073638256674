
import { Component, Prop } from 'vue-property-decorator';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import DropDownBase from '@/ui/components/devices/devices/base/DropDown.vue';
import Switch2VBase from '@/ui/components/devices/devices/base/Switch2VBase.vue';
import StatusIconBase from '@/ui/components/devices/devices/base/StatusIconBase.vue';
import { Action, Getter } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import TimezoneChooser from '@/ui/components/components/TimezoneChooser.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import GeneralSettingsEMS from '@/ui/components/devices/components/EnergyParts/EMS/components/settings/GeneralSettingsEMS.vue';

/**
 * Component that represent EMS settings view.
 * Contains navigation tabs. EMS settings and Mode settings.
 */
@Component({
  components: {
    GeneralSettingsEMS,
    ShowEventDotBase,
    InputFieldBase,
    OutputFieldBase,
    DropDownBase,
    Switch2VBase,
    TimezoneChooser,
    LabelUnitWrapper,
    StatusIconBase,
  },
})
export default class SettingsEMS extends mixins(Validation) {
  @Prop({ default: null }) mpcData!: any; // data was loaded from mpc/fetchMPCData (loads full mpc instance data by id)
  get mappingsList(): any {
    return this.mpcData?.data?.meta?.controllerMappings;
  }
}
