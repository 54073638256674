
import { Action, Getter, Mutation } from 'vuex-class';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Component } from 'vue-property-decorator';
import _, { range } from 'lodash';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { setVariableValuesWithMeasurements } from '@/utils/installationWizardUtilsFunctions';
import { IMQTTVariable } from '@/types/wizards/installationWizard.types';
import { IDevice } from '@/types/devices.types';

@Component({
  components: {
    WizardContentView,
  },
})
export default class BigConsumerSettings extends WizardComponent {
  @Getter('installationWizard/emsLimits') emsLimits!: Record<string, number>;
  @Mutation('installationWizard/updateEMSDeviceData') updateEMSDeviceData!: (payload: Partial<IDevice>) => void;
  @Mutation('installationWizard/updateEnergyViewDeviceData') updateEnergyViewDeviceData!: (payload: Partial<IDevice>) => void;
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => Promise<IDevice>;
  @Getter('installationWizard/emsDevice') emsDevice!: IDevice;
  @Getter('installationWizard/energyViewDevice') energyViewDevice!: IDevice;

  valid = false;
  formVariables: Map<string, IMQTTVariable> = new Map();
  backgroundVariables: Map<string, IMQTTVariable> = new Map();

  get includedSystemBigConsumer() {
    return this.includedSystems.big_consumer;
  }

  get batteryDefinitionEmpty() {
    return this.emsDevice.data.meta.controllerMappings.battery.components.count === 0;
  }

  /**
   * Generates the foreground variables for the given count of systems.
   */
  formVariablesForCount(count: number): Map<string, IMQTTVariable> {
    // fill empty object with index-dependent variables
    return range(1, count + 1).reduce((acc, i) => {
      acc.set(
        `priority${i}`,
        {
          variable: `prgOBC.fbBC${i}.stSetupOBC.byPriority`,
          value: 5,
        },
      );
      return acc;
    }, new Map<string, IMQTTVariable>());
  }

  /**
   * Generates the background variables for the given count of systems.
   */
  backgroundVariablesForCount(count: number): Map<string, IMQTTVariable> {
    // fill empty object with index-dependent variables
    return range(1, count + 1).reduce((acc, i) => {
      acc.set(
        `enableSOC${i}`,
        {
          variable: `prgOBC.fbBC${i}.stSetupOBC.byEnableSOC`,
          value: 1,
        },
      );
      acc.set(
        `disableSOC${i}`,
        {
          variable: `prgOBC.fbBC${i}.stSetupOBC.byDisableSOC`,
          value: 0,
        },
      );
      acc.set(
        `enable${i}`,
        {
          variable: `prgOBC.fbBC${i}.bEnable`,
          // if this system is not included, set enable flag to 0
          value: i > this.includedSystemBigConsumer.count ? 0 : 1,
          feedbackVariable: `prgOBC.fbBC${i}.stDataOBC.bEnabled`,
          isBoolean: true,
        },
      );
      return acc;
    }, new Map());
  }
  getVariableValuesFromMeasurements() {
    this.formVariables = setVariableValuesWithMeasurements(this.formVariables, this.measurements);
  }

  async created() {
    // form variables are dependent on the chosen count of systems
    this.formVariables = this.formVariablesForCount(this.includedSystemBigConsumer.count);
    // background variables are always sent for all systems, but with the respective enable flag on/off
    this.backgroundVariables = this.backgroundVariablesForCount(this.emsLimits.big_consumer);
    if (this.wasInstallationWizardCompleted) {
      this.getVariableValuesFromMeasurements();
    }
  }

  prepareVariablesToSend(): void {
    this.variablesToSend = new Map([...this.formVariables, ...this.backgroundVariables]);
    this.updateEMSNaming();
  }

  async updateEMSNaming() {
    const emsMappings = _.cloneDeep(this.emsDevice.data.meta.controllerMappings);
    const energyViewMappings = _.cloneDeep(this.energyViewDevice.data.meta.controllerMappings);

    // Update ems mappings
    Object.values(emsMappings.big_consumer.components)
      .forEach((component: any, index: number) => {
        component.title = this.includedSystems.big_consumer.definition[index]?.title || component.title;
      });

    // Update energy view mappings
    Object.values(energyViewMappings.big_consumer.components)
      .forEach((component: any, index: number) => {
        component.title = this.includedSystems.big_consumer.definition[index].title;
      });

    this.updateEMSDeviceData({ data: { meta: { controllerMappings: emsMappings } } });
    this.updateEnergyViewDeviceData({ data: { meta: { controllerMappings: energyViewMappings } } });
    if (this.emsDevice.id) await this.updateDevice({ device: this.emsDevice, skipReport: false });
    if (this.energyViewDevice.id) await this.updateDevice({ device: this.energyViewDevice, skipReport: false });
  }
}
