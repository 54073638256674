
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Mutation, State } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { combineHouseNumberAndStreet } from '@/utils/tenantWizardUtilsFunctions';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { IFlatsConfig, ITenantModel } from '@/types/wizards/tenant.types';
import { cloneDeep } from 'lodash';
import FlatDetails from './FlatsComponents/FlatDetails.vue';

@Component({
  components: {
    WizardContentView,
    InfoTooltip,
    FlatDetails,
  },
})
export default class FlatsInformation extends mixins(Validation) {
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Mutation('tenantWizard/updateTenantObject') updateTenantObject!: (data: ITenantModel) => void;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;

  valid = false;
  flatsValid = false;
  whereFlatsAllreadyDoneOnce = false;

  billingComponents = {
    electricity: false,
    serviceWater: false,
    coldWater: false,
    heating: false,
    cooling: false,
  };
  buildingAddress = {
    clientName: '',
    street: '',
    zip: '',
    city: '',
    country: '',
  };
  flatsConfigList: IFlatsConfig[] = [
    {
      name: '',
      mail: '',
      clientId: '',
      address: {
        clientName: '',
        street: '',
        zip: '',
        city: '',
        country: '',
      },
      additionalText: this.$t('tenantWizard.flatsInformation.defaultAdditionalText') as string,
      sideCostPercentage: 0,
      hasChargingStation: false,
      counters: {
        energyCounter: [{ id: '', name: '' }],
        heatingCounter: [{ id: '', name: '' }],
        serviceWaterCounter: [{ id: '', name: '' }],
        coldWaterCounter: [{ id: '', name: '' }],
        coolingCounter: [{ id: '', name: '' }],
      },
    },
  ]
  flatsCount = 1;
  oldFlatsCount = 1;

  updateValid(val: { status: boolean }) {
    this.flatsValid = val.status;
  }

  updateFlats(val: { flats: IFlatsConfig[] }) {
    this.flatsConfigList = val.flats;
    this.flatsCount = this.flatsConfigList.length;
  }

  updatebuildingAddress(val: { buildingAddress: { clientName: string; street: string; zip: string; city: string; country: string } }) {
    this.buildingAddress = val.buildingAddress;
  }

  handleNext() {
    this.updateTenant();
    this.handleIncrement();
  }

  /**
   * updates the tenant object in the store
   */
  updateTenant() {
    const tenantCopy = cloneDeep(this.tenantState.tenant);

    if (this.billingComponents.coldWater === false) {
      this.removeNoneSelectedItems('coldWaterCounter');
      tenantCopy.general.overallCounters.coldWaterCounter = [];
    }

    if (this.billingComponents.serviceWater === false) {
      this.removeNoneSelectedItems('serviceWaterCounter');
      tenantCopy.general.overallCounters.serviceWaterCounter = [];
    }

    if (this.billingComponents.heating === false) {
      this.removeNoneSelectedItems('heatingCounter');
      tenantCopy.general.overallCounters.heatingCounter = [];
    }

    if (this.billingComponents.electricity === false) {
      this.removeNoneSelectedItems('energyCounter');
      tenantCopy.general.overallCounters.energyCounter = [];
    }

    if (this.billingComponents.cooling === false) {
      this.removeNoneSelectedItems('coolingCounter');
      tenantCopy.general.overallCounters.coolingCounter = [];
    }

    tenantCopy.general.billingComponents = this.billingComponents;
    tenantCopy.general.flats.flatCount = this.flatsCount;

    this.updateFlatsWithBuildingAddress();
    this.flatsConfigList = combineHouseNumberAndStreet(this.flatsConfigList);
    tenantCopy.general.flats.flatConfigurations = this.flatsConfigList;
    this.updateTenantObject(tenantCopy);
  }

  /**
   * updates the flats with the building address
   */
  updateFlatsWithBuildingAddress() {
    this.flatsConfigList.forEach((flat: any) => {
      flat.address.street = this.buildingAddress.street;
      flat.address.zip = this.buildingAddress.zip;
      flat.address.city = this.buildingAddress.city;
      flat.address.country = this.buildingAddress.country;
    });
  }

  /**
   * removes all selected items from the counters object for each flat
  */
  removeNoneSelectedItems(type: any) {
    this.flatsConfigList.forEach((flat: any) => {
      flat.counters[type] = [];
    });
  }

  mapFlatConfigList() {
    const tenantFlatsCopy = this.tenantState.tenant.general.flats;

    // fill up building address based on saved flat address
    this.buildingAddress.city = tenantFlatsCopy.flatConfigurations[0].address.city;
    this.buildingAddress.country = tenantFlatsCopy.flatConfigurations[0].address.country;
    this.buildingAddress.zip = tenantFlatsCopy.flatConfigurations[0].address.zip;
    // eslint-disable-next-line prefer-destructuring
    this.buildingAddress.street = tenantFlatsCopy.flatConfigurations[0].address.street.split(',')[0];

    // remap housenumber from street to specific houseNumber field in address
    this.flatsConfigList.forEach((flat: any) => {
      // eslint-disable-next-line prefer-destructuring
      flat.address.houseNumber = flat.address.street.split(', ')[1];
      // eslint-disable-next-line prefer-destructuring
      flat.address.street = flat.address.street.split(',')[0];
    });
  }

  created() {
    if (this.tenantState.tenant.general.flats.flatConfigurations[0] !== undefined) {
      this.whereFlatsAllreadyDoneOnce = true;
      this.flatsCount = this.tenantState.tenant.general.flats.flatCount;
      this.oldFlatsCount = this.tenantState.tenant.general.flats.flatCount;
      this.flatsConfigList = cloneDeep(this.tenantState.tenant.general.flats.flatConfigurations);
      this.mapFlatConfigList();
      this.billingComponents = this.tenantState.tenant.general.billingComponents;
    } else {
      this.flatsValid = true;
    }
  }

  mounted() {
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }
}
