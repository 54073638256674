var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DeviceLayout',{attrs:{"preview-data":{ name: _vm.previewNameLang, type: 'Gauge' },"device-size":"x2h","is-preview":true},scopedSlots:_vm._u([{key:"basic-controls",fn:function(){return [_c('div',{ref:"chartDiv",staticClass:"gauge",attrs:{"id":"chartDiv"}},[_c('VueGauge',{key:_vm.chartKey,staticStyle:{"margin-top":"20px"},attrs:{"refid":"previewNormalGauge","options":{
          hasNeedle: false,
          rangeLabel: ["0","100"],
          arcDelimiters: [40],
          centralLabel: "40kW",
          chartWidth: `${_vm.gaugeWidth}`,
          arcColors: [`${_vm.$vuetify.theme.currentTheme.accent}`,"lightgray"
          ]}}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }