
import { Vue, Component, Prop } from 'vue-property-decorator';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import SettingsCharts from '@/ui/components/devices/mpc/HeatingProduction/SettingsCharts/index.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';
import { Action } from 'vuex-class';

/**
 * Component that represent HppProductionService MPC device
 */
@Component({
  components: {
    ShowEventDotBase,
    SettingsCharts,
    OutputFieldBase,
    MPCLayout,
  },
})
export default class HppProductionService extends Vue {
  @Prop() deviceData!: any;
  @Action('mpc/fetchMPCData') fetchMPCData!: any;

  mpc: any = null

  isSettingsView = false

  get isITLocale() {
    return this.$i18n.locale === 'it';
  }

  get langPath() {
    return `mlModel.${this.deviceData.data.type}`;
  }

  get mpcId() {
    if (this.deviceData) return this.deviceData.id;
    return null;
  }

  /**
   * Convert MPC id from "id-id-id" to "id_id_id"
   */
  get mpcIdMod() {
    if (this.mpcId) return this.mpcId.replace(/-/g, '_');
    return null;
  }

  get chartScaling() {
    return this.deviceData?.data?.meta.chartScaling;
  }
  get ready() {
    return this.deviceData?.data?.meta?.controllerMappings?.mpcReady;
  }
  get actualHeating() {
    return this.deviceData?.data?.meta?.controllerMappings?.power;
  }
  get calculatedProduction() {
    return `HPP.${this.mpcIdMod}.pow`;
  }
  get calculatedProductionMainView() {
    return `HPP.${this.mpcIdMod}.mean`;
  }
  get calculatedEnergy() {
    return `HPP.${this.mpcIdMod}.en`;
  }
  get calculatedEnergy2() {
    return `HPP.${this.mpcIdMod}.cen`;
  }
  get actualEnergyChart() {
    return `HPP.${this.mpcIdMod}.aen`;
  }
  get errorWarningMqtt() {
    return {
      ShowEvent_errorWarningState: this.deviceData?.data?.meta?.controllerMappings?.errorWarning,
    };
  }
  get actualHeatingMqtt() {
    return {
      OutputField1_actualValue: this.actualHeating,
    };
  }
  get calculatedProductionMqtt() {
    return {
      OutputField2_actualValue: this.calculatedProductionMainView,
    };
  }
  get calculatedEnergyMqtt() {
    return {
      OutputField3_actualValue: this.calculatedEnergy,
    };
  }
  get readyMqtt() {
    return {
      ShowEventDot1_errorWarningState: this.ready,
    };
  }

  switchSettingsView(setting: boolean) {
    this.isSettingsView = setting;
  }

  async mounted() {
    this.mpc = await this.fetchMPCData(this.deviceData.id);
  }
}
