
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import IconList from '@/ui/components/components/IconList.vue';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { Mutation } from 'vuex-class';
import { IRoom } from '@/types/rooms.types';
import IconService from '@/services/IconService';
import { IReportBox } from '@/types/app.types';
import { Icon } from '@/types/common.types';

/**
 * A component that allows to create or modify a room
 */
@Component({
  components: { IconList, ModalWindow },
})
export default class ManageRoom extends Vue {
  @Prop({ type: String }) formTitle!: string;
  @Prop({ default: () => ({ name: '', meta: {} }) }) roomData!: IRoom;
  @Mutation('app/setReport') setReport!: (payload: IReportBox) => void;

  radioGroup = 0;
  isTooltip = false;
  room = {
    name: '',
    meta: {},
  };
  pictureObject = { name: '' }

  iconObject?: Icon;
  icons: Icon[] = [];

  valid = true;
  rules = {
    required: (value: string) => !!value || 'Required.',
  }

  /**
   * If modal window dialog open copy room data to local property
   * @param status
   */
  handleModalWindowStatus(status: boolean) {
    if (status) this.room = JSON.parse(JSON.stringify(this.roomData));
  }

  /**
   * Search icons by inserted name
   * @param q name of the icon
   * @return list of icons
   */
  search(q: string): Promise<void | Icon[]> {
    // clear icons if the query is smaller than 3 chars
    if (q.length < 3) {
      this.icons = [];
      return Promise.resolve();
    }

    return IconService.fetchIcons(q).then((res: Icon[]) => {
      this.icons = res.map((icon: Icon) => ({ term: icon.term, id: icon.id }));
    });
  }

  onSelected(icon: Icon) {
    this.iconObject = icon;
  }

  /**
   * Creates room with certain parameters
   */
  send() {
    if (this.room.name.length === 0) {
      return;
    }

    // prepares room data for request data
    const args = {
      room: this.room,
    } as any;

    // save picture data in room object if a picture was selected when creating the room
    if (this.radioGroup === 0 && this.pictureObject.name !== '') {
      args.cover = this.pictureObject;
      args.coverType = 'picture';
    }
    // save icon data in room object if icon was selected when creating the room
    if (this.radioGroup === 1 && this.iconObject) {
      args.cover = this.iconObject.id;
      args.coverType = 'icon';
    }

    this.$emit('handleRoom', args);
    (this.$refs.ModalWindowInstance as any).closeDialog();
  }

  @Watch('currentLang', { deep: true })
  handleLangChange() {
    this.validate();
  }

  get currentLang() {
    return this.$i18n.locale;
  }

  validate() {
    if (this.$refs.form !== undefined) {
      (this.$refs.form as any).validate();
      this.send();
    }
  }
}
