
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import {
  EnergyLineDisplayData,
} from '@/types/energyVisualisation/EnergyLineDisplayData';

/**
 * Components that represents arrows for animated line
 */
@Component
export default class AnimatedLineArrow extends Vue {
  @Prop({}) displayDataItem!: EnergyLineDisplayData;
  @Prop({ default: 0 }) width!: number;
  @Prop({ default: 0 }) height!: number;

  @Inject({ from: 'onLineAnimationEnd', default: () => {} }) onLineAnimationEnd?: (id: string) => void;

  ctx: CanvasRenderingContext2D | null = null
  reqFunc: number | null = null

  get speed() {
    return this.displayDataItem.oneArrowOnly ? 300 : 600;
  }

  /**
   * Define line length
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} x2 end x position
   * @param {number} y2 end y position
   * @return {number} length
   */
  lineLength(x1: number, y1: number, x2: number, y2: number) {
    return Math.hypot(x2 - x1, y2 - y1);
  }

  /**
   * Define angle
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} x2 end x position
   * @param {number} y2 end y position
   * @return {object} angle in radians and degrees
   */
  currentAngle(x1: number, y1: number, x2: number, y2: number) {
    const radians: number = Math.atan2(y2 - y1, x2 - x1);
    const degrees: number = Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;
    return { radians, degrees };
  }

  /**
   * Rotate arrow according to angle
   * @param {object} context canvas context object
   * @param {number} cx context x position
   * @param {number} cy context y position
   * @param {number} angle current angle
   */
  rotateArrow(context: CanvasRenderingContext2D, cx: number, cy: number, angle: number) {
    context.translate(cx, cy);
    context.rotate(angle);
    context.translate(-cx, -cy);
  }

  /**
   * Define arrow movement speed
   * @param {number} lineLength line length
   * @return {number} speed
   */
  movementSpeed(lineLength: number) {
    return lineLength / this.speed;
  }

  /**
   * Set arrow color
   * @param {object} context canvas context object
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} x2 end x position
   * @param {number} y2 end y position
   * @param {string} color1 start gradient color
   * @param {string} color2 end gradient color
   */
  arrowColor(context: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number, color1: string, color2: string) {
    const grad: any = context.createLinearGradient(x1, y1, x2, y2);
    grad.addColorStop(0, color1);
    grad.addColorStop(1, color2);
    context.strokeStyle = grad;
    context.lineWidth = 2;
  }

  /**
   * Draw arrow body
   * @param {object} context canvas context object
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} angle current angle
   */
  arrowBody(context: CanvasRenderingContext2D, x1: number, y1: number, angle: number) {
    this.rotateArrow(context, x1, y1, angle);
    if (this.displayDataItem.arrowDirection !== 0) {
      context.beginPath();
      context.moveTo(x1, y1);
      context.lineTo(x1 - 10, y1 - 10);
      context.stroke();
      context.closePath();
      context.beginPath();
      context.moveTo(x1, y1);
      context.lineTo(x1 - 10, y1 + 10);
      context.stroke();
    }
  }

  /**
   * Draw arrow
   * @param {object} context canvas context object
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} x2 end x position
   * @param {number} y2 end y position
   * @param {string} color1 start gradient color
   * @param {string} color2 end gradient color
   * @param {number} angle current angle
   */
  drawArrow(context: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number, color1: string, color2: string, angle: any) {
    let mx: number = x1;
    let my: number = y1;
    let mx2: number = x1;
    let my2: number = y1;
    let mainStep = 0;
    let stepArrow1 = 0;
    let stepArrow2 = 0;

    const lineLength: number = this.lineLength(x1, y1, x2, y2);
    const speed = this.movementSpeed(lineLength);
    this.arrowColor(context, x1, y1, x2, y2, color1, color2);

    // function that defines the drawing location of arrow
    const run = () => {
      mainStep += 0.1;
      if (mainStep > 0) stepArrow1 += 1;
      if (mainStep > 30) stepArrow2 += 1;
      if (mainStep > 6000) {
        mainStep = 0;
        stepArrow1 = 0;
        stepArrow2 = 0;
        mx = x1;
        my = y1;
        mx2 = x1;
        my2 = y1;
      }

      context.clearRect(0, 0, this.width, this.height);
      context.lineWidth = 2;

      // draw first arrow
      context.save();
      if (stepArrow1 > 0) {
        this.arrowBody(context, mx, my, angle.radians);
        mx += speed * Math.cos(angle.radians);
        my += speed * Math.sin(angle.radians);
      }
      if (stepArrow1 > this.speed) {
        // sends outside when animation ends
        this.onLineAnimationEnd?.(this.displayDataItem.id);
        stepArrow1 = 0;
        mx = x1;
        my = y1;
      }
      context.restore();

      if (!this.displayDataItem.oneArrowOnly) {
        // draw second arrow
        context.save();
        if (stepArrow2 > 0) {
          this.arrowBody(context, mx2, my2, angle.radians);
          mx2 += speed * Math.cos(angle.radians);
          my2 += speed * Math.sin(angle.radians);
        }
        if (stepArrow2 > this.speed) {
          stepArrow2 = 0;
          mx2 = x1;
          my2 = y1;
        }
        context.restore();
      }
      this.reqFunc = requestAnimationFrame(run);
    };

    // Indicates to the browser that you want to produce an animation.
    // Number of draws coincides with the refresh rate of the screen.
    this.reqFunc = requestAnimationFrame(run);
  }

  /**
   * Drawing arrow pointing in the opposite direction
   * @param {object} context canvas context object
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} x2 end x position
   * @param {number} y2 end y position
   * @param {string} color1 start gradient color
   * @param {string} color2 end gradient color
   * @param {number} angle current angle
   */
  drawArrowReverse(context: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number, color1: string, color2: string, angle: any) {
    let mx: number = x2;
    let my: number = y2;
    let mx2: number = x2;
    let my2: number = y2;
    let mainStep = 0;
    let stepArrow1 = 0;
    let stepArrow2 = 0;

    const lineLength: number = this.lineLength(x1, y1, x2, y2);
    const speed = this.movementSpeed(lineLength);
    this.arrowColor(context, x1, y1, x2, y2, color1, color2);

    // function that defines the drawing location of arrow
    const run = () => {
      mainStep += 0.1;
      if (mainStep > 0) stepArrow1 += 1;
      if (mainStep > 30) stepArrow2 += 1;
      if (mainStep > 6000) {
        mainStep = 0;
        stepArrow1 = 0;
        stepArrow2 = 0;
        mx = x1;
        my = y1;
        mx2 = x1;
        my2 = y1;
      }

      context.clearRect(0, 0, this.width, this.height);
      context.lineWidth = 2;

      context.save();
      if (stepArrow1 > 0) {
        this.arrowBody(context, mx, my, angle.radians + 180 * Math.PI / 180);
        mx -= speed * Math.cos(angle.radians);
        my -= speed * Math.sin(angle.radians);
      }
      if (stepArrow1 > this.speed) {
        this.onLineAnimationEnd?.(this.displayDataItem.id);
        stepArrow1 = 0;
        mx = x2;
        my = y2;
      }

      context.restore();

      if (!this.displayDataItem.oneArrowOnly) {
        // draw second arrow
        context.save();
        if (stepArrow2 > 0) {
          this.arrowBody(context, mx2, my2, angle.radians + 180 * Math.PI / 180);
          mx2 -= speed * Math.cos(angle.radians);
          my2 -= speed * Math.sin(angle.radians);
        }
        if (stepArrow2 > this.speed) {
          stepArrow2 = 0;
          mx2 = x2;
          my2 = y2;
        }
        context.restore();
      }

      this.reqFunc = requestAnimationFrame(run);
    };

    // Indicates to the browser that you want to produce an animation.
    // Number of draws coincides with the refresh rate of the screen.
    this.reqFunc = requestAnimationFrame(run);
  }

  mounted() {
    const canvas = this.$refs.arrow as HTMLCanvasElement;
    this.ctx = canvas.getContext('2d');
    const angle = this.currentAngle(
      this.displayDataItem.endPoint.x,
      this.displayDataItem.endPoint.y,
      this.displayDataItem.startPoint.x,
      this.displayDataItem.startPoint.y,
    );
    if (this.displayDataItem.arrowDirection === 1) {
      this.drawArrowReverse(
        this.ctx as CanvasRenderingContext2D,
        this.displayDataItem.endPoint.x,
        this.displayDataItem.endPoint.y,
        this.displayDataItem.startPoint.x,
        this.displayDataItem.startPoint.y,
        this.displayDataItem.colors.colorAtCenter,
        this.displayDataItem.colors.colorAtCircle,
        angle,
      );
    }
    if (this.displayDataItem.arrowDirection === 2 || this.displayDataItem.arrowDirection === 0) {
      this.drawArrow(
        this.ctx as CanvasRenderingContext2D,
        this.displayDataItem.endPoint.x,
        this.displayDataItem.endPoint.y,
        this.displayDataItem.startPoint.x,
        this.displayDataItem.startPoint.y,
        this.displayDataItem.colors.colorAtCenter,
        this.displayDataItem.colors.colorAtCircle,
        angle,
      );
    }
  }
  beforeDestroy() {
    cancelAnimationFrame(this.reqFunc as number);
  }
}
