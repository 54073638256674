
import { Vue, Component } from 'vue-property-decorator';
import { State, Mutation } from 'vuex-class';
import { IProjectsState } from '@/store/modules/projects/types';
import { IDevicesState } from '@/store/modules/devices/types';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IRulesState } from '@/store/modules/rules/types';
import { IEventState } from '@/store/modules/events/types';
import { IReportState } from '@/store/modules/report/types';
import { SearchType } from '@/utils/searchUtils';
import { IProjectFilters } from '@/types/project.types';
import { IPartnerWorkspacesState } from '@/store/modules/partnerWorkspaces/types';
import { IInvertersState } from '@/store/modules/serialNumber';

/**
 * Component that represent search
 * Used in AppBar.vue
 * Give possibility to filter devices, projects...
 */
@Component
export default class Search extends Vue {
  @State('projects') projectsState!: IProjectsState;
  @State('devices') devicesState!: IDevicesState;
  @State('measurements') measurementsState!: IVariablesState;
  @State('events') eventsState!: IEventState;
  @State('rules') rulesState!: IRulesState;
  @State('report') reportState!: IReportState;
  @State('serialNumber') serialNumbersState!: IInvertersState;
  @State('partnerWorkspaces') partnerWorkspacesState!: IPartnerWorkspacesState;
  @Mutation('projects/setProjectFilter') setProjectFilter!: (data: { type: keyof IProjectFilters; value: any }) => void;
  @Mutation('devices/setDevicesFilter') setDevicesFilter!: (name: string) => void;
  @Mutation('measurements/setMeasurementsFilter') setMeasurementsFilter!: (name: string) => void;
  @Mutation('events/setEventListFilter') setEventListFilter!: (name: string) => void;
  @Mutation('rules/setRulesFilter') setRulesFilter!: (name: string) => void;
  @Mutation('report/setReportFilter') setReportFilter!: (name: string) => void;
  @Mutation('partnerWorkspaces/setWorkspaceFilter') setWorkspaceFilter!: (filter: string) => IPartnerWorkspacesState;
  @Mutation('serialNumber/setInverterFilter') setInverterFilter!: (filter: string) => IInvertersState;

  get value() {
    switch (this.$route.meta?.searchType) {
      case SearchType.devices: return this.devicesState.devicesFilter;
      case SearchType.measurements: return this.measurementsState.measurementsFilter;
      case SearchType.reports: return this.reportState.reportFilter;
      case SearchType.rules: return this.rulesState.rulesFilter;
      case SearchType.projects: return this.projectsState.projectFilters.projectName;
      case SearchType.events: return this.eventsState.eventListFilter;
      case SearchType.workspaces: return this.partnerWorkspacesState.workspaceFilter;
      case SearchType.serialNumbers: return this.serialNumbersState.inverterFilter;
      default: return '';
    }
  }

  onInput(value: string) {
    switch (this.$route.meta?.searchType) {
      case SearchType.devices:
        this.setDevicesFilter(value);
        break;
      case SearchType.projects:
        this.setProjectFilter({ type: 'projectName', value });
        break;
      case SearchType.measurements:
        this.setMeasurementsFilter(value);
        break;
      case SearchType.events:
        this.setEventListFilter(value);
        break;
      case SearchType.rules:
        this.setRulesFilter(value);
        break;
      case SearchType.reports:
        this.setReportFilter(value);
        break;
      case SearchType.workspaces:
        this.setWorkspaceFilter(value);
        break;
      case SearchType.serialNumbers:
        this.setInverterFilter(value);
        break;
      default: throw new Error(`Invalid searchType: ${this.$route.meta?.searchType}`);
    }
  }
}
