
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import RoomItem from '@/ui/components/lists/RoomsList/RoomItem.vue';
import VueGridLayout from 'vue-grid-layout';
import { State, Getter, Action } from 'vuex-class';
import { IRoomsState } from '@/store/modules/rooms/types';
import { IAppState } from '@/store/modules/app/types';
import { IRoom } from '@/types/rooms.types';
import { IProject } from '@/types/project.types';
import { IMemberMetaMapElement } from '@/types/members.types';

/**
 * Component that represent all rooms in Drag and Drop grid.
 * Used in Areas page.
 */
@Component({
  components: {
    RoomItem,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
})
export default class RoomsList extends Vue {
  @Prop() listOfRooms!: IRoom[];
  @Prop() activeRoomId!: string;
  @Prop() currentRoomData!: IRoom;

  @State('app') appState!: IAppState;
  @State('rooms') roomsState!: IRoomsState;
  @Getter('projects/project') project!: IProject;
  @Getter('members/currentMember') currentMember!: any;
  @Getter('members/currentMemberAreasPositions') currentMemberAreasPositions!: IMemberMetaMapElement[];
  @Action('projects/updateProject') updateProject!: (project: IProject) => void;

  /**
   * Watch on listOfRooms prop, compare changes and save rooms positions in project data
   * @param {array} newRooms actual list of rooms
   * @param {array} oldRooms list of rooms before they are changed
   */
  @Watch('listOfRooms')
  onRoomsChange(newRooms: IRoom[], oldRooms: IRoom[]) {
    if (!newRooms.length) this.$emit('handleRoomActiveStatus', '');
    if (newRooms.length !== oldRooms.length && oldRooms.length && oldRooms) {
      this.$nextTick(() => {
        this.$emit('handleRoomActiveStatus', this.firstSortedRoomByParams);
        this.updateProject(this.project);
      });
    }
    this.initDndRooms(newRooms);
  }

  dndLayout: any = [];
  areasPositions = [];
  projectWithActualRoomsPositions: any = {};

  get isAreasMiniView() {
    if (this.project) return this.project.meta?.isAreasMiniView;
    return false;
  }
  get areasHeight() {
    return this.isAreasMiniView ? 33 : 250;
  }

  get dndStatus() {
    if (this.project) {
      return this.project.meta?.isDNDActive;
    }
    return false;
  }

  /**
   * Defines id of first room in sorted list
   * @return {string} room id
   */
  get firstSortedRoomByParams() {
    if (this.dndLayout.length) {
      const filteredByKeys = this.dndLayout.map((room: any) => ({ x: room.x, y: room.y, i: room.i }));
      const sorted = filteredByKeys.sort((a: any, b: any) => a.x - b.x).sort((a: any, b: any) => a.y - b.y);
      return sorted[0].i;
    }
    return null;
  }

  /**
   * Defines active room
   * @param {string} roomId room id
   * @return {boolean} status of room
   */
  activeRoom(roomId: string) {
    if (this.activeRoomId) {
      return this.activeRoomId === roomId;
    }
    this.$emit('handleRoomActiveStatus', this.firstSortedRoomByParams);
    return this.firstSortedRoomByParams === roomId;
  }

  /**
   * Prepares list of rooms for Drag and Drop grid
   * @param {array} rooms list of rooms
   */
  initDndRooms(rooms: any) {
    // sort rooms by their x, y positions
    const sortedPositions = this.currentMemberAreasPositions.length ? this.currentMemberAreasPositions
      .map((item: any) => ({ x: item.x, y: item.y }))
      .sort((a: any, b: any) => a.x - b.x)
      .sort((a: any, b: any) => a.y - b.y) : null;
    const lastPosition = sortedPositions ? sortedPositions[sortedPositions.length - 1] : null;

    this.dndLayout = rooms.map((room: IRoom, index: number) => {
      const positions = () => {
        if (this.currentMember?.meta?.area_positions?.length) {
          const currentRoomPositions = this.currentMember.meta.area_positions.find((item: any) => item.i === room.id);
          return currentRoomPositions || {
            x: (lastPosition?.x + 1) % 6,
            y: lastPosition?.x < 5 ? lastPosition?.y : lastPosition?.y + 1,
            i: room.id,
          };
        }
        return {
          x: index % 6,
          y: 0,
          i: room.id,
        };
      };
      return {
        room,
        ...positions(),
        w: 1,
        h: 1,
        i: room.id,
      };
    });
  }

  /**
   * Saves actual rooms positions when Drag and Drop layout was updated
   * @param {array} layout Drag and Drop grid items list
   */
  layoutUpdatedEvent(layout: any) {
    this.areasPositions = layout.map((item: any) => ({ x: item.x, y: item.y, i: item.i }));
  }

  handleRoom(id: string) {
    this.$emit('handleRoomActiveStatus', id);
  }

  mounted() {
    this.initDndRooms(this.listOfRooms);
  }
}
