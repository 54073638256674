export function batteryMappingEnergyViewTenant(systemIndex: number) {
  return {
    energy_counter: 'prgEnergy.lrCounterProdBatt',
    reverse_energy_counter: 'prgEnergy.lrCounterConsBatt',
    error: '',
    power: 'prgEnergy.fbI.stDataBattery.lrPower',
    soc: '',
    title: 'Batterie Total',
    external_energy_measurement: true,
  };
}

export function gridMappingEnergyViewTenant() {
  return {
    energy_counter: 'prgEnergy.lrCounterProdGrid',
    reverse_energy_counter: 'prgEnergy.lrCounterConsGrid',
    error: '',
    power: 'prgEnergy.fbGrid.stDataGrid.lrPower',
    title: 'Netz',
    is_on_grid: 'prgEnergy.fbI.bOffGridModeActive',
  };
}

export function houseMappingEnergyViewTenant(systemIndex: number) {
  return {
    energy_counter: 'prgEnergy.lrCounterConsHouse',
    error: '',
    power: 'prgEnergy.lrHousePower',
    title: 'Haus',
  };
}

export function gridMappingEMSTenant() {
  return {
    power: 'prgEnergy.fbGrid.stDataGrid.lrPower',
    error: '',
    title: 'Netz',
    size: 'prgEnergy.fbGrid.rSizeGridConn',
    energy_counter: 'prgEnergy.lrCounterProdGrid',
    reverse_energy_counter: 'prgEnergy.lrCounterConsGrid',
    size_in_amps: 0,
    is_on_grid: 'prgEnergy.fbI.bOffGridModeActive',
  };
}

export function houseMappingEMSTenant(systemIndex: number) {
  return {
    power: 'prgEnergy.lrHousePower',
    state_enable: 'prgEnergy.fbHouse.stDataHouseC.bEnabled',
    switch_enable: 'prgEnergy.fbHouse.bEnable',
    error: '',
    title: `Haus ${systemIndex}`,
    energy_counter: 'prgEnergy.lrCounterConsHouse',
  };
}

export function batteryMappingEMSTenant() {
  return {
    power: 'prgEnergy.fbI.stDataBattery.lrPower',
    target_power: '',
    state_enable: '',
    state_reset: '',
    error: '',
    title: 'Batterie Total',
    soc: '',
    size_capacity: '',
    capacity: '',
    priority: '',
    switch_enable: '',
    switch_reset: '',
    energy_counter: 'prgEnergy.lrCounterProdBatt',
    reverse_energy_counter: 'prgEnergy.lrCounterConsBatt',
    external_energy_measurement: true,
  };
}
