
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import Wizard from '@/ui/components/wizards/baseComponents/Wizard.vue';
import {
  IloggerWizardState,
} from '@/store/modules/loggerWizard/types';
import { LoggerType } from '@/ui/components/wizards/loggerWizard/wizardSettings/systemTypes';
import { IDevice } from '@/types/devices.types';
import { IWizardLoadingState, IWizardPage } from '@/types/wizards/wizard.general.types';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    Wizard,
  },
})
export default class LoggerWizard extends Vue {
  @State('loggerWizard') wizardState!: IloggerWizardState;
  @Getter('devices/allDevices') allDevices!: IDevice[];
  @Getter('loggerWizard/currentStep') activeStep!: number;
  @Getter('loggerWizard/wizardPages') wizardPages!: IWizardPage[];
  @Getter('mqttClient/isProjectOnline') isProjectOnline !: boolean;
  @Getter('projects/batterySystemType') batterySystemType!: LoggerType | undefined;
  @Getter('loggerWizard/considerProjectStatus') considerProjectStatus !: boolean;
  @Getter('loggerWizard/loadingState') loadingState!: IWizardLoadingState;
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;
  @Action('devices/fetchDevices') fetchDevices!: (projectId: string) => Promise<void>;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;

  loading = true;

  get currentPage() {
    return this.wizardState.currentPageIndex;
  }

  get hasBatteryDefined() {
    return !!this.batterySystemType;
  }

  get pages() {
    return this.wizardPages;
  }

  async mounted() {
    await new Promise((resolve, reject) => setTimeout(() => {
      this.loading = false;
      resolve();
    }, 1000));
  }

  async created() {
    this.setProjectLoader(true);
    await Promise.all([
      this.fetchDevices(this.$route.params.id),
      this.fetchMembers(this.$route.params.id),
    ]);
    this.setProjectLoader(false);
  }
}
