import { render, staticRenderFns } from "./ConnectWithProject.vue?vue&type=template&id=04c03412&scoped=true&"
import script from "./ConnectWithProject.vue?vue&type=script&lang=ts&"
export * from "./ConnectWithProject.vue?vue&type=script&lang=ts&"
import style0 from "./ConnectWithProject.vue?vue&type=style&index=0&id=04c03412&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04c03412",
  null
  
)

export default component.exports