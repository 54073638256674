
import { Vue, Component } from 'vue-property-decorator';
import ChartsView from '@/ui/components/devices/previews/components/ChartsView.vue';
import DevicesView from '@/ui/components/devices/previews/components/DevicesView.vue';
import RegentsView from '@/ui/components/devices/previews/components/RegentsView.vue';
import TSGView from '@/ui/components/devices/previews/components/TSGView.vue';
import RonovatecView from '@/ui/components/devices/previews/components/RonovatecView.vue';
import { envDeviceLibraryCategories } from '@/utils/env';

/**
 * Device Library page
 * here are previews of the devices, charts
 */
@Component({
  components: {
    RonovatecView,
    TSGView,
    ChartsView,
    DevicesView,
    RegentsView,
  },
})
export default class Controls extends Vue {
  tab = null

  get previews() {
    return [
      {
        name: 'Building Automation',
        locale: 'uiComponents.devicesLibrary.mainTabs.buildingAutomation',
        view: 'DevicesView',
      },
      {
        name: 'Charts',
        locale: 'uiComponents.devicesLibrary.mainTabs.charts',
        view: 'ChartsView',
      },
      {
        name: 'TSG',
        locale: 'uiComponents.devicesLibrary.mainTabs.tsg',
        view: 'TSGView',
      },
      {
        name: 'Ronovatec Devices',
        locale: 'uiComponents.devicesLibrary.mainTabs.ronovatecDevices',
        view: 'RonovatecView',
      },
    ];
  }

  get previewsFiltered() {
    return this.previews.filter((e: any) => Object.keys(envDeviceLibraryCategories).some((el: string) => el === e.name));
  }
}
