
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Getter, Mutation, State } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { minMaxValidation } from '@/utils/utilsFunctions';

@Component({
  methods: { minMaxValidation },
  components: {
    WizardContentView,
    InfoTooltip,
  },
})
export default class ExtraCosts extends mixins(Validation) {
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Getter('tenantWizard/isAutoMappingEnabled') isAutoMappingEnabled!: boolean;
  @Mutation('tenantWizard/setIsAutoMappingEnabled') setIsAutoMappingEnabled!: (value: boolean) => void;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;

  autoMappingEnable = false;

  created() {
    this.autoMappingEnable = this.isAutoMappingEnabled;
  }

  handleNext() {
    this.setIsAutoMappingEnabled(this.autoMappingEnable);
    this.handleIncrement();
  }
}
