
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IProjectsState } from '@/store/modules/projects/types';
import { IMeasurements } from '@/types/measurements.types';
import { IVariable } from '@/types/variables.types';

/**
 * Measurements page that shows measurements list
 */
@Component
export default class Measurements extends Vue {
  @State('measurements') measurementsState!: IVariablesState;
  @State('projects') projectsState!: IProjectsState;
  @Getter('measurements/measurements') measurements!: IMeasurements;
  @Getter('variables/variables') variables!: IVariable[];
  @Mutation('measurements/setMeasurementsFilterCount') setMeasurementsCount!: (count: number) => void;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;
  @Action('variables/modifyVariableAlias') modifyVariableAlias!: (variable: any) => Promise<void>;

  measurementsDataLoaded = false;
  /**
   * Header for measurements table
   */
  get headers() {
    return [
      { text: this.$t('uiComponents.measurementsTable.headers.name'), value: 'variable' },
      { text: this.$t('uiComponents.measurementsTable.headers.description'), value: 'description' },
      { text: this.$t('uiComponents.measurementsTable.headers.value'), value: 'value', sortable: false },
    ];
  }

  /**
   * set value rounded if it has to many numbers after comma,
   * if the value is a string and is longer than 30 characters, truncates it
   */
  measurementValue(value: any) {
    const roundedValue = (value: any) => {
      return Math.round(value * 1e2) / 1e2;
    };
    if (typeof value === 'number') return roundedValue(value);
    if (typeof value === 'string') return value.slice(0, 30);
    return value;
  }

  get measurementsFilter() {
    return this.measurementsState.measurementsFilter;
  }

  get joinedMeasurements() {
    return this.variables.map((variable: any) => {
      return Object.values(variable).join().toLowerCase();
    });
  }

  get filteredMeasurements() {
    let filteredList = this.measurementsFilter
      ? this.variables
        .filter((variable: any, index: number) => this.joinedMeasurements[index].includes(this.measurementsFilter.toLowerCase()))
      : this.variables;

    // filter out heartbeat variables from list
    const additionalVariablesToFilter = ['$hb', '$hb2'];
    filteredList = filteredList.filter((element: IVariable) => !additionalVariablesToFilter.includes(element.variable));

    this.setMeasurementsCount(filteredList.length);
    return filteredList;
  }

  async updateAlias(item: any) {
    await this.modifyVariableAlias({ projectId: this.$route.params.id, variable: item });
  }

  async created() {
    this.setProjectLoader(true);
    await Promise.allSettled([
      this.fetchMeasurements(this.$route.params.id),
      this.fetchVariables(this.$route.params.id),
    ]).finally(() => {
      this.setProjectLoader(false);
      this.measurementsDataLoaded = true;
    });
  }
}
