
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { envProjectSettingsEnteries } from '@/utils/env';
import { IAppState } from '@/store/modules/app/types';
import { checkProtocolAccessList } from '@/utils/userRoles';
import { CanAccessCheck } from '@/types/members.types';

/**
 * Page allows you to:
 * set up notifications, permissions, general settings;
 * check MQTT information;
 * upload repositories;
 * download certificates;
 */
@Component
export default class Settings extends Vue {
  @State('app') appState!: IAppState;
  @Getter('members/canMemberAccess') canMemberAccess!: CanAccessCheck;
  @Action('rooms/fetchRooms') fetchRooms!: (projectId: string) => any;
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  get accessListCheckProtocolSettings() {
    const userMail = this.appState.user.email;
    return checkProtocolAccessList.includes(userMail);
  }

  get defaultSettingsItems() {
    return [
      {
        title: 'General',
        locale: 'uiComponents.settings.settingsTabs.general',
        component: 'General',
        icon: 'settings',
        route: `/projects/${this.$route.params.id}/settings/general`,
      },
      {
        title: 'EnergyPrice',
        locale: 'uiComponents.settings.settingsTabs.energyPrice',
        component: 'EnergyPrice',
        icon: 'energy_view',
        route: `/projects/${this.$route.params.id}/settings/energy-price`,
      },
      {
        title: 'Downloads',
        locale: 'uiComponents.settings.settingsTabs.downloads',
        component: 'Downloads',
        icon: 'download',
        route: `/projects/${this.$route.params.id}/settings/downloads`,
      },
      {
        title: 'Notification',
        locale: 'uiComponents.settings.settingsTabs.notification',
        component: 'Notification',
        icon: 'notification',
        route: `/projects/${this.$route.params.id}/settings/notification`,
      },
      ...(this.superAdmin ? [{
        title: 'MQTT',
        locale: 'uiComponents.settings.settingsTabs.mqtt',
        component: 'MQTT',
        icon: 'mqtt',
        route: `/projects/${this.$route.params.id}/settings/mqtt`,
      }] : []),
      {
        title: 'Permissions',
        locale: 'uiComponents.settings.settingsTabs.permissions',
        component: 'Permissions',
        icon: 'permission',
        route: `/projects/${this.$route.params.id}/settings/permissions`,
      },
      {
        title: 'SettingsVariables',
        locale: 'uiComponents.settings.settingsTabs.variables',
        component: 'VariablesInSettings',
        icon: 'variables',
        route: `/projects/${this.$route.params.id}/settings/variables`,
      },
      ...(this.accessListCheckProtocolSettings ? [{
        title: 'CheckProtocol',
        locale: 'uiComponents.settings.settingsTabs.checkProtocol',
        component: 'CheckProtocol',
        icon: 'ok',
        route: `/projects/${this.$route.params.id}/settings/check-protocol`,
      }] : []),
    ];
  }

  get settings() {
    return this.defaultSettingsItems
      .filter((item: any) => this.canMemberAccess('ProjectSettingsEntries', item.title));
  }

  get settingsFiltered() {
    return this.settings.filter((e) => envProjectSettingsEnteries.includes(e.title));
  }

  async created() {
    // go to first page that user can access inside settings if user doens't have access to general
    if (!this.canMemberAccess('ProjectSettingsEntries', 'General') && this.settingsFiltered.length > 0) {
      // fix error of duplicate navigation when reloading page
      if (this.$route.path !== this.settingsFiltered[0].route) {
        this.$router.push(this.settingsFiltered[0].route);
      }
    } else {
      if (this.$route.path !== this.settingsFiltered[0].route) {
        this.$router.push(`/projects/${this.$route.params.id}/settings/general`);
      }
    }
    await this.fetchMembers(this.$route.params.id);
  }
}
