
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Select field component which used in manage modal forms
 */
@Component
export default class SelectField extends Vue {
  @Prop() value!: any;
  @Prop({ default: null }) items!: any;
  @Prop({ default: null }) itemValue!: string;
  @Prop({ default: null }) itemText!: string;
  @Prop({ default: null }) label!: null | string;
  @Prop({ default: true }) optional!: boolean;

  get customRules() {
    if (!this.optional) return (value: number) => (value === 0 || !!value) || this.$t('validationRules.required');
    else return true;
  }

  mounted() {
    (this.$refs.customField as any).validate(true);
  }
}
