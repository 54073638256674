import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { isEqual } from 'lodash';
import { IMLModel } from '@/types/mpc/mpc.types';
import { IDevice } from '@/types/devices.types';

@Component
export class FormUpdateTracker extends Vue {
  @Prop() isEditModal!: boolean;
  @Prop() deviceData!: IMLModel | IDevice | any;

  localDeviceData: any = null;
  dataSnapshot: any = {}; // need to save empty object schema when create device

  // Temporary solution. Need until do refactor in ManageChartNew.
  get isEditModalProp() {
    return this.isEditModal;
  }

  get isFormUnchanged() {
    if (this.isEditModalProp) return isEqual(this.deviceData, this.localDeviceData);
    else return isEqual(this.dataSnapshot, this.localDeviceData);
  }

  @Watch('isFormUnchanged', { immediate: true })
  onFormUnchanged(value: boolean) {
    this.$emit('onFormUnchanged', value);
  }
}
