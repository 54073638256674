
import { Vue, Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

/**
 * Component that represent RonovatecPlayPauseButton type for PushButton base control
 */
@Component
export default class RonovatecPlayPauseButton extends BasicControl {
  @Prop({ default: null }) currentState!: number | null; // TODO: check if expected type correct, can be that it boolean
  @Prop({ default: 150 }) buttonWidth!: number;
  @Prop({ default: 36 }) buttonHeight!: number;
  @Prop({ default: 'theme' }) iconColor !: string;
  @Prop() iconSize?: number | string;
  @Prop({ default: 'white' }) buttonColor!: string;

  get playPauseIcon() {
    // TODO: check if expected type correct, can be that it always boolean
    const isValid: boolean = this.currentState === 0 || this.currentState === -1;
    return isValid ? {
      icon: 'ronova_pause',
      text: 'Anhalten',
    } : {
      icon: 'ronova_play',
      text: 'Fortsetzen',
    };
  }
}
