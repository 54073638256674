
import { Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { mixins } from 'vue-class-component';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';
import SystemsNavigation
  from '@/ui/components/devices/mpc/SetpointOptimizer/components/SystemsNavigation.vue';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import {
  defineIoSystemType,
  getChartSystemIOInstanceName,
} from '@/ui/components/devices/components/EnergyParts/EMS/utils';
import { roundNumber } from '@/utils/utilsFunctions';
import { periodConfigurations, Periods } from '@/ui/components/devices/charts/charts/ChartUtils';
import {
  singleEnergyChartScaling,
  singleEnergyChartScalingHybrid,
} from '@/utils/scalingConstants';
import {
  allIOEnergyCircleTypes,
  IOEnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';
import {
  getCalculation,
} from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/utils/energyIOChartsUtils';
import { IDevice } from '@/types/devices.types';
import { ICurrentSystem, ISystemsVars } from '@/types/common.types';
import {
  IOEnergyDataByPeriodResponse,
} from '@/types/energyVisualisation/energyVisualisation.types';
import EnergyIOComponent
  from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/utils/EnergyIOComponent';

const DEFAULT_SYSTEMS_VARS = {
  inputs: ['power', 'energy_counter'],
  outputs: ['power', 'energy_counter'],
  hybrids: ['power', 'energy_counter', 'reverse_energy_counter'],
};

@Component({
  components: {
    BaseChartWrapper,
    SystemsNavigation,
    InfoTooltip,
  },
})
export default class SingleIOSystemsView extends mixins(EnergyIOComponent) {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: '' }) mpcId!: string;
  @Prop() settingsWrapperHeight !: number;
  @Prop({ default: false }) isMpc!: boolean
  @Prop() shownSystems !: string[];
  @Prop({ default: false }) showHouseConsumptionAlways !: boolean;
  @Prop({ default: false }) showScores !: boolean;
  @Prop({ default: false }) setExpressionAsMax!: boolean;
  @Prop({ default: false }) excludeHouse!: boolean;
  @Prop({ default: 9 }) numberOfSeriesPerChart!: number;
  @Prop({ default: () => DEFAULT_SYSTEMS_VARS }) systemsVars!: ISystemsVars;
  @Prop({ default: false }) isHybridSystem!: boolean;
  @Prop({ default: 'avg' }) defaultAggregation!: string;
  @Prop({ default: () => false }) showColumnChart!: boolean;
  @Prop({ default: () => ['live', 'hour'] }) navItemsToExclude!: string[];

  @Action('mpc/fetchDataByPeriodMpc') fetchDataByPeriodMpc!: (
    { id, start, end, period }: { id: string; start: number; end: number; period: string }
  ) => Promise<any>;
  @Action('devices/fetchDataByPeriod') fetchDataByPeriod!: (
    { id, start, end, period }: { id: string; start: number; end: number; period: string }
  ) => Promise<any>;

  reRenderKey = 0;
  currentPeriod = Periods.DAY;
  start = periodConfigurations[this.currentPeriod].period().start;
  end = periodConfigurations[this.currentPeriod].period().end;
  energyResponse: IOEnergyDataByPeriodResponse | null = null;
  systems: string[] = [];
  currentChart: ICurrentSystem = {
    system: null,
    instances: [],
  }
  selectedGroup = 0;
  systemsUnits: any = {
    inputs: {
      energy_counter: 'kWh',
      power: 'kW',
    },
    outputs: {
      energy_counter: 'kWh',
      power: 'kW',
    },
    hybrids: {
      power: 'kW',
      energy_counter: 'kWh',
      reverse_energy_counter: 'kWh',
    },
  }
  showHouseCalculationTooltip = false;

  /**
   * Collection of systems scaling
   */
  get systemsScaling(): any {
    return {
      inputs: {
        energy_counter: { min: 0, max: undefined },
        power: singleEnergyChartScaling,
      },
      outputs: {
        power: singleEnergyChartScaling,
        energy_counter: { min: 0, max: undefined },
      },
      hybrids: {
        power: singleEnergyChartScalingHybrid,
        energy_counter: { min: 0, max: undefined },
        reverse_energy_counter: { min: 0, max: undefined },
      },
    };
  }
  scores: Record<string, number | null> = {};
  scoresForDataExport: Record<string, {value: number; unit: string}> = {};

  getSystemsExpressionWrappers(system: any) {
    switch (system) {
      // case IOEnergyCircleType.house: return (exp: string) => `max(${exp}, 0)`;
      default: return undefined;
    }
  }

  getIOSystems(device: any): Record<string, any> {
    return allIOEnergyCircleTypes.reduce((acc: any, type: IOEnergyCircleType) => {
      return { ...acc, [type]: device.data.meta.controllerMappings[type] };
    }, {});
  }
  getIOSubSystems(device: any): Record<string, any> {
    const systems = allIOEnergyCircleTypes.reduce((acc: any, type: IOEnergyCircleType) => {
      return { ...acc, [type]: device.data.meta.controllerMappings[type] };
    }, {});
    let subSystems: any[] = [];
    Object.entries(IOEnergyCircleType).forEach(([key, value]) => {
      if (systems[value]) {
        subSystems = [...subSystems, ...Object.entries(systems[value].components)];
      }
    });
    return subSystems;
  }
  /**
   * Creates array of systems groups and system instances.
   * @return {array} list of system groups. Example: ['battery', [['battery1', 'battery2']]]
   */
  get systemSchema() {
    return this.getIOSubSystems(this.deviceData)
      .filter((system: any) => {
        return this.systems.some((el: any) => Object.values(system[1]?.energy_counter).length);
      });
  }

  get isSystemWithMultipleChartValues() {
    return this.currentChart.system ? this.systemsVars[defineIoSystemType(this.currentChart.system)].length > 1 : false;
  }

  /**
   * Systems options data
   */
  get systemsChartOptions() {
    if (!this.currentChart.instances.length) return [];

    const chartOptions: any = this.currentChart.instances.map((instance: string, index) => {
      const systemType = defineIoSystemType(this.currentChart.system as string);
      const existingSystemVars = this.systemsVars[systemType].filter((v: string) => {
        if (systemType === 'hybrids') {
          return !!instance;
        }
        return !!this.getVariableValue(this.deviceData, systemType, this.currentChart.system as string, v, instance);
      });

      const mappedChartOptions: any[] = [];
      existingSystemVars.forEach((variable: string) => {
        const chartInstanceName = getChartSystemIOInstanceName(this.getIOSystems(this.deviceData), this.currentChart.system as string, instance, variable, defineIoSystemType(this.currentChart.system as string));

        const name = this.isSystemWithMultipleChartValues
          ? `${this.$t(`devices.EnergyView.ChartsWindow.legend.${this.currentChart.system}.${variable}`)} - ${chartInstanceName}`
          : chartInstanceName;

        const scaling = this.systemsScaling[this.currentChart.system as string];
        const units = this.systemsUnits[this.currentChart.system as string];

        let variableName = this.deviceData.data.meta.controllerMappings[systemType].components[this.currentChart.system as string][variable];
        if (systemType !== IOEnergyCircleType.hybrids) {
          variableName = this.deviceData.data.meta.controllerMappings[systemType].components[this.currentChart.system as string][variable][instance];
        }

        if (!this.showColumnChart) {
          mappedChartOptions.push({
            calculation: getCalculation({
              system: this.currentChart.system as IOEnergyCircleType,
              instance: variableName,
              variable,
              device: this.deviceData,
              expressionWrapperFn: this.setExpressionAsMax
                ? (exp: string) => `max(${exp}, 0)`
                : this.getSystemsExpressionWrappers(this.currentChart.system),
              externalMeasurementsList: [],
              defaultAggregation: this.defaultAggregation,
            }),
            name,
            scaling: scaling ? scaling[variable] : singleEnergyChartScaling,
            seriesType: 'Calculation',
            type: 'line',
            unit: units ? units[variable] : 'kW',
          });
        } else {
          mappedChartOptions.push({
            var: variableName,
            name: `${this.$t(`devices.EnergyView.ChartsWindow.legend.${defineIoSystemType(this.currentChart.system as string)}.${variable}`)} - ${chartInstanceName} ${index + 1}`,
            scaling: scaling ? scaling[variable] : singleEnergyChartScaling,
            seriesType: 'View',
            type: 'column',
            unit: 'kWh',
            agg: 'diff',
          });
        }
      });
      return mappedChartOptions;
    });
    return chartOptions.flat().filter(Boolean);
  }

  getSystemTitle(system: string, instance: string) {
    if (['battery', 'grid'].includes(system)) {
      const { title } = this.getIOSystems(this.deviceData)[system].components[instance.split('_')[0]];
      return title || instance;
    } else {
      const { title } = this.getIOSystems(this.deviceData)[system].components[instance];
      return title || instance;
    }
  }

  /**
   * Creates an understandable object for the graphs component
   */
  get currentChartData() {
    const chartOptions = [...this.systemsChartOptions];

    return {
      title: null,
      data: {
        chartTitle: '',
        chartWidth: null,
        chartHeight: 600,
        chartWrapperHeight: 750,
        chartData: {
          data: { chartOptions },
        },
        navigationItemsToExclude: this.navItemsToExclude,
      },
    };
  }

  get showCurrentChartData() {
    return this.currentChartData?.data?.chartData?.data?.chartOptions?.length;
  }

  handleCurrentChart(params: {system: IOEnergyCircleType; arr: string[]}, selected = 0) {
    this.currentChart = { system: params.system, instances: params.arr };
    this.selectedGroup = selected;
    this.reRenderKey += 1;
  }

  /**
   * Init what system chart will load when open page.
   */
  initFirstSystemByDefault() {
    const filtered: any = this.systemSchema.filter((el: any) => (defineIoSystemType(el[0]) !== IOEnergyCircleType.hybrids ? Object.keys(el[1].energy_counter).length : el[1].energy_counter));
    const instances = defineIoSystemType(filtered[0][0]) === IOEnergyCircleType.hybrids ? ['instance'] : Object.keys(filtered[0][1].energy_counter);
    this.currentChart = filtered.length
      ? {
        system: filtered[0][0],
        instances,
      } : {
        system: null,
        instances: [],
      };
  }

  async chartRangeChange(params: any) {
    this.start = params.start;
    this.end = params.end;
    this.currentPeriod = params.period;
    this.energyResponse = await this.fetchDataByPeriod({
      id: this.mpcId.replaceAll('_', '-'),
      start: this.start,
      end: this.end,
      period: this.currentPeriod,
    });

    const system: IOEnergyCircleType = this.systemSchema[this.selectedGroup][0];
    const instances = this.systemSchema[this.selectedGroup][1];
    const scores: Record<string, number | null> = {};
    const scoresForDataExport: any = {};
    const difference = this.energyResponse?.calculations?.difference;
    const energyResponseSystemData = this.energyResponse?.energies?.[system];

    if (this.systemSchema.length > 0 && energyResponseSystemData) {
      if (this.systemSchema[0][0] !== 'house') {
        Object.entries(energyResponseSystemData).forEach(([instance, value]: [string, number]) => {
          scores[this.getSystemTitle(system, instance)] = Math.max(roundNumber(value), 0);
          scoresForDataExport[this.getSystemTitle(system, instance)] = { value: Math.max(roundNumber(value), 0), unit: 'kWh' };
        });
      } else {
        Object.entries(energyResponseSystemData).forEach(([instance, _]: [string, number]) => {
          scores[this.getSystemTitle(system, instance)] = difference
            ? Math.max(roundNumber(difference / instances.length), 0)
            : null;
          scoresForDataExport[this.getSystemTitle(system, instance)] = {
            value: difference ? Math.max(roundNumber(difference / instances.length)) : null,
            unit: 'kWh',
          };
        });
      }
    }
    this.scores = scores;
    this.scoresForDataExport = scoresForDataExport;
  }

  created() {
    this.systems = this.shownSystems;
    if (this.navItemsToExclude.includes('day')) {
      this.currentPeriod = Periods.WEEK;
    }
    this.initFirstSystemByDefault();
  }
}
