/**
 * Direction of the flow of arrows on a certain energy line.
 */
export enum ArrowDirection {
  none,
  toCenter,
  awayFromCenter,
}

export interface EnergyLineColors {
  /**
   * Start color of the line at the central energy point. Hex-string.
   */
  colorAtCenter: string;
  /**
   * End color of the line at the energy circle. Hex-string.
   * Also used for the energy circle's spinner.
   */
  colorAtCircle: string;
}

export interface EnergyLineDisplayData {
  id: string;
  startPoint: {
    x: number;
    y: number;
  };
  endPoint: {
    x: number;
    y: number;
  };
  /**
   * Start/end color of the energy line.
   */
  colors: EnergyLineColors;
  /**
   * The arrow direction the circle's arrow currently has
   */
  arrowDirection: ArrowDirection;
  /**
   * Whether the line should just display one arrow (instead of two).
   */
  oneArrowOnly: boolean;
  /**
   * Show cross on Line
   */
  showCross?: boolean;
}
