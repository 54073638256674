import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import '@fortawesome/fontawesome-free/css/all.css';
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import HighchartsVue from 'highcharts-vue';
import Vue from 'vue';
import LynusIcon from '@/ui/components/components/LynusIcon.vue';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import { VueEditor } from 'vue2-editor';
import VueKonva from 'vue-konva';
import VueGauge from 'vue-gauge';
import ResizeObserver from 'v-resize-observer';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import i18n from '@/ui/plugins/i18n';
import App from '@/ui/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/ui/plugins/vuetify';
import 'leaflet/dist/leaflet.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import {
  envClientId,
  envFaviconURL,
  envRealm,
  envTitle,
  envProjectOpenSubpage,
  envKeycloakURL,
} from '@/utils/env';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import '@fontsource/clear-sans';
import '@/ui/scss/main.scss';

Vue.config.productionTip = false;

Vue.component('lynus-icon', LynusIcon);

Vue.use(VueKonva);
Vue.use(HighchartsVue);
Vue.use(ResizeObserver);
Vue.component(VueQrcode.name, VueQrcode);
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.component('VueGauge', VueGauge);
Vue.component('VueEditor', VueEditor);

Vue.mixin({
  // make important env variables accessible throughout whole app
  data() {
    return {
      title: envTitle,
      projectOpenSubpage: envProjectOpenSubpage,
    };
  },
  created() {
    // set title and favicon
    document.title = envTitle;
    const favicon = document.getElementById('favicon');
    favicon?.setAttribute('href', envFaviconURL);
  },
});

Vue.use(VueKeyCloak, {
  config: {
    realm: envRealm,
    url: envKeycloakURL,
    clientId: envClientId,
  },
  init: {
    onLoad: 'login-required',
    checkLoginIframe: false,
  },
  logout: {
    redirectUri: document.location.origin,
  },
  // vue-keycloak has no typings...
  onReady: (k: any) => {
    // getting everything from the k.tokenParsed attribute (the JWT content)
    store.commit('app/setUser', {
      id: k.tokenParsed.sub,
      email: k.tokenParsed.email,
      first_name: k.tokenParsed.given_name,
      last_name: k.tokenParsed.family_name,
      super_admin: k.tokenParsed.staff === 'true' || k.tokenParsed.staff === true,
      partner: k.tokenParsed?.partner, // if the user is assigned to a patner, the partner id will be stored else it will be undefined or empty string
    } as any, { root: true });

    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App),
    }).$mount('#app');
  },
});
