
import { Vue, Component, Prop } from 'vue-property-decorator';
import tinycolor from 'tinycolor2';

@Component
export default class EnergyCircleValueIO extends Vue {
  @Prop({ default: '' }) value!: string;
  @Prop({ default: false }) isHighlighted!: boolean;
  @Prop() highlightColor!: string;

  get styles() {
    return {
      color: tinycolor(this.highlightColor).isDark() ? '#ffffff' : '#525252',
      background: this.highlightColor,
      padding: '0px 6px',
      borderRadius: '4px',
    };
  }
}
