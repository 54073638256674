import { config } from '../../default-config';

/**
 * returns environment value
 * @param name the desired environment variable
 */
function getEnv(name: string): any {
  const w = window as any; // js window object

  // take value from window config, or from default config as fallback
  return w.config?.[name] ?? config[name];
}

// export environment variables
export const envApi = getEnv('API');
export const envMpcApi = getEnv('MPC_API');
export const envMqtt = getEnv('MQTT');
export const envTitle = getEnv('TITLE');
export const envLogoURLLight = getEnv('LOGO_URL_LIGHT');
export const envLogoURLDark = getEnv('LOGO_URL_DARK');
export const envFaviconURL = getEnv('FAVICON_URL');
export const envProjectOpenSubpage = getEnv('PROJECT_OPEN_SUBPAGE');
export const envKeycloakURL = getEnv('KEYCLOAK_URL');
export const envRealm = getEnv('REALM');
export const envClientId = getEnv('CLIENT_ID');
export const envDefaultsToDarkmode = getEnv('DEFAULTS_TO_DARKMODE');
export const envThemes = getEnv('THEMES');
export const envDevices = getEnv('DEVICES');
export const envAppShowProjectTypeSelection = getEnv('SHOW_PROJECT_TYPE_SELECTION');
export const envProjectMenuEntries: string[] = getEnv('PROJECT_MENU_ENTRIES');
export const envProjectSettingsEnteries: string[] = getEnv('PROJECT_SETTINGS_ENTRIES');
export const envWorkbenchCategories: string[] = getEnv('WORKBENCH_CATEGORIES');
export const envDeviceLibraryCategories: any = getEnv('DEVICE_LIBRARY_CATEGORIES');
export const envAimlLibraryCategories: string[] = getEnv('AIML_LIBRARY_CATEGORIES');
export const envMainMenuEntries: string[] = getEnv('MAIN_MENU_ENTRIES');
export const envWorkbenchButtons: string[] = getEnv('WORKBENCH_BUTTONS');
export const envSettingsMailSupport: string = getEnv('SETTINGS_MAIL_SUPPORT');
export const envSettingsDownloadList: string[] = getEnv('SETTINGS_DOWNLOAD_LIST');
export const envSettingsShowWeatherService: boolean = getEnv('SETTINGS_SHOW_WEATHER_SERVICE');
export const envDocumentationTabs: string[] = getEnv('DOCUMENTATION_TABS');
export const envLoadMPC: boolean = getEnv('LOAD_MPC');
export const envShowProjectInfo: boolean = getEnv('SHOW_PROJECT_INFO');
export const envMPCDeviceList: string[] = getEnv('MPC_Devices_List');
export const envMPCLibraryTabs: string[] = getEnv('MPC_Library_Tabs');
export const envUserRolesAccessBlockList: any = getEnv('USER_ROLES_ACCESS_BLOCK_LIST');
export const envEnableControllerSettings: any = getEnv('ENABLE_CONTROLLER_SETTINGS');
export const envPartnerProjectMenuEntries: string[] = getEnv('PARTNER_PROJECT_MENU_ENTRIES');
