
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { cloneDeep } from 'lodash';
import InputFieldNumber from '@/ui/components/components/InputFieldNumber.vue';
import ComboboxField from '@/ui/components/modals/components/form/ComboboxField.vue';
import DatePickerCustom from '@/ui/components/modals/components/DatePickerCustom.vue';
import { Validation } from '@/ui/mixins/validation';
import {
  FormUpdateTracker,
} from '@/ui/mixins/formUpdateTracker';
import { mixins } from 'vue-class-component';
import { getDefaultRulesObject } from '@/utils/utilsFunctions';
import { IRoom } from '@/types/rooms.types';

/**
 * Scheme to create, modify a specific ML Model device.
 * Specified in the ML Model device type definition in store/mpc/mlModelTypes.ts
 */
@Component({
  components: {
    ComboboxField,
    InputFieldNumber,
    DatePickerCustom,
  },
})
export default class ServicesSchema extends mixins(Validation, FormUpdateTracker) {
  @Prop({
    default: () => ({
      name: '',
      data: {
        type: '',
        meta: {},
      },
      collection_id: '',
    }),
  }) deviceData!: any;
  @Prop({}) isEditModal!: any;
  @Prop({ default: '' }) activeRoomId!: string;
  @State('variables') variablesState!: any;
  @Getter('mpc/mlModelTypes') mlModelTypes!: any;
  @Getter('rooms/sortedRoomsByName') sortedRoomsByName!: IRoom[];
  @Getter('projects/project') project!: any;
  @Getter('measurements/measurementsKeys') measurementsKeys!: any;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;
  @Action('rules/addRules') addRules!: (payload: any) => any;
  @Action('rules/deleteRule') deleteRule!: (payload: any) => any;
  @Getter('variables/variablesForComboBox') variablesForComboBox!: [];

  stage = 1
  localDeviceData: any = null
  showOptionalFields = false

  @Watch('stage')
  onStageChange(val: number) {
    if (val === 2) {
      this.fetchVariables(this.$route.params.id);
    }
  }

  // steps validation
  get nameValidation() {
    return !!this.localDeviceData?.name?.length;
  }

  /**
   * Checks mappings if they not valid
   * @return true if mapping filled, else false
   */
  mappingValidation() {
    if (this.stage === 2) {
      const optionalFields = Object.entries(this.mlModelMappings)
        .filter((field: any) => !field[1].optional)
        .map((field: any) => field[0]);
      const isOptionalFieldsFilled = optionalFields.map((el: any) => this.localDeviceData.data.meta.controllerMappings[el]).every((el: any) => el);
      return isOptionalFieldsFilled;
    } else {
      return false;
    }
  }
  get settingsValidation() {
    return !!this.localDeviceData.collection_id.length
      && !!this.localDeviceData.data?.meta?.chartScaling?.length;
  }

  get mlModelSchema() {
    return this.mlModelTypes[this.deviceData.data.type];
  }
  get mlModelMappings() {
    return this.mlModelSchema.controllerMappings;
  }
  get mappingsByColumns() {
    if (this.localDeviceData?.data?.type) {
      return this.mlModelSchema.mappingsByColumns;
    } else {
      return [];
    }
  }

  /**
   * Clear date picker value on press button cancel on calendar
   * @param formField date picker field key
   */
  datePickerCustomClearDate(formField: any) {
    this.localDeviceData.data.meta.controllerMappings[formField] = this.deviceData.data.meta?.controllerMappings?.startDate || '';
  }

  /**
   * Checks incoming parameter and return list of measurements or some array according to parameter
   * @param key any type
   * @return if parameter array return this array else return list of measurements
   */
  listDefinition(key: any) {
    if (Array.isArray(key)) {
      return key;
    } else {
      return this.variablesForComboBox;
    }
  }

  /**
   * Goes through the list and creates an option object for each item
   */
  initMappingsForDevice() {
    let mappingsSchema: any = {};
    Object.keys(this.mlModelMappings).forEach((item: string) => {
      mappingsSchema = { ...mappingsSchema, [item]: '' };
    });
    return mappingsSchema;
  }

  /**
   * Creates rules for Ml Model device according to errorWarning field,
   * save ids of created rules in current ML Model device object
   * @param deviceData current Ml Model data object
   */
  async addRulesWhenCreateDevice(deviceData: any) {
    if (!deviceData.data.meta.controllerMappings.errorWarning.length) {
      deviceData.data.meta.warningRule = null;
      deviceData.data.meta.errorRule = null;
    } else {
      const rulesList: any = getDefaultRulesObject(deviceData.name, deviceData.data.meta.controllerMappings.errorWarning, 'Device');
      const res = await this.addRules({
        project_id: this.project.id,
        rulesList,
      });
      deviceData.data.meta.warningRule = res[0].id;
      deviceData.data.meta.errorRule = res[1].id;
    }
  }

  /**
   * Replacing existing rules with new ones when errorWarning field is changed
   * @param deviceData current Ml Model data object
   */
  async addRulesWhenEditDevice(deviceData: any) {
    const oldErrorWarningVar = this.deviceData.data.meta.controllerMappings.errorWarning;
    const newErrorWarningVar = deviceData.data.meta.controllerMappings.errorWarning;
    if (oldErrorWarningVar && !newErrorWarningVar) {
      await Promise.all([
        this.deleteRule({
          project_id: this.project.id,
          rule_id: deviceData.data.meta.warningRule,
        }),
        this.deleteRule({
          project_id: this.project.id,
          rule_id: deviceData.data.meta.errorRule,
        }),
      ]);
    }
    // if errorWarning was changed
    if (oldErrorWarningVar !== newErrorWarningVar) {
      // delete old rules
      if (oldErrorWarningVar) {
        await Promise.all([
          this.deleteRule({
            project_id: this.project.id,
            rule_id: deviceData.data.meta.warningRule,
          }),
          this.deleteRule({
            project_id: this.project.id,
            rule_id: deviceData.data.meta.errorRule,
          }),
        ]);
      }
      // create new rules
      if (newErrorWarningVar) {
        const rulesList: any = getDefaultRulesObject(deviceData.name, deviceData.data.meta.controllerMappings.errorWarning, 'Device');
        const res: any = await this.addRules({
          project_id: this.project.id,
          rulesList,
        });
        const getWarningRule = () => {
          const warningRuleObj: any = res.find((rule: any) => rule.name === `${deviceData.name} Warning Rule`);
          return warningRuleObj.id;
        };
        const getErrorRule = () => {
          const errorRuleObj: any = res.find((rule: any) => rule.name === `${deviceData.name} Error Rule`);
          return errorRuleObj.id;
        };
        deviceData.data.meta.warningRule = getWarningRule();
        deviceData.data.meta.errorRule = getErrorRule();
      }
    }
  }

  /**
   * Save Ml Model data in data base
   */
  async sendForm() {
    this.$emit('onFormUnchanged', true); // disable confirmation dialog when save
    const copy = cloneDeep(this.localDeviceData);
    copy.project_id = this.project.id;

    // rules
    if (!this.isEditModal) {
      await this.addRulesWhenCreateDevice(copy);
    } else {
      await this.addRulesWhenEditDevice(copy);
    }
    this.$emit('handleControl', copy);

    this.$emit('closeDialog');
  }

  async created() {
    this.localDeviceData = cloneDeep(this.deviceData);

    if (this.activeRoomId.length && !this.isEditModal) this.localDeviceData.collection_id = this.activeRoomId;

    // init mappings if create new ml model
    this.$set(this.localDeviceData.data.meta, 'controllerMappings', this.initMappingsForDevice());

    // if edit device
    if (this.isEditModal) {
      // load current mappings
      this.localDeviceData.data.meta.controllerMappings = {
        ...this.localDeviceData.data.meta.controllerMappings,
        ...this.deviceData.data.meta.controllerMappings,
      };
    } else {
      this.$set(this.localDeviceData.data.meta, 'chartScaling', null);
      this.dataSnapshot = cloneDeep(this.localDeviceData);
    }
  }
}
