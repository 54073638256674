
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import EnergyComponent from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import {
  EnergyCircleType,
  allEnergyCircleTypes,
  energyCircleIconForType,
  getCirclePositionForType,
} from '@/types/energyVisualisation/EnergyCircleType';
import { EnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import { EnergyLineDisplayData } from '@/types/energyVisualisation/EnergyLineDisplayData';
import EnergyVisualisation from './index.vue';
import {
  getDefaultArrowDirection,
  getDefaultLineColors,
  getDefaultSpinnerColor,
} from './utils/EnergyVisualisationUtils';

/**
 * A component for previewing the structure of an EnergyVisualisation, given an object with device mappings.
 */
@Component({
  components: {
    EnergyVisualisation,
  },
})
export default class EnergyVisualisationPreview extends mixins(EnergyComponent) {
  @Prop({ default: false }) enableAnimations!: boolean;

  get shownSystems() {
    return this.filterPresentSystems(this.deviceData, allEnergyCircleTypes);
  }

  get circlesDisplayData() {
    return this.shownSystems.map((systemType: EnergyCircleType) => {
      return {
        id: systemType,
        bottomValue: undefined,
        centerContent: {
          type: 'icon',
          value: energyCircleIconForType(systemType),
        },
        position: getCirclePositionForType(systemType),
        systemCount: this.getSystemCount(this.deviceData, systemType),
        spinnerColor: getDefaultSpinnerColor(systemType, this.enableAnimations ? 1 : undefined),
        alternativeValue: this.enableAnimations ? 1 : undefined,
      } as EnergyCircleDisplayData;
    });
  }

  get linesDisplayData() {
    return this.shownSystems.map((systemType: EnergyCircleType, index: number) => {
      const displayData = this.circlesDisplayData[index];

      return {
        id: displayData.id,
        arrowDirection: getDefaultArrowDirection(systemType, displayData),
        colors: getDefaultLineColors(systemType, displayData),
      } as Partial<EnergyLineDisplayData>;
    });
  }
}
