
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import InputFieldNumber from '@/ui/components/components/InputFieldNumber.vue';
import ComboboxField from '@/ui/components/modals/components/form/ComboboxField.vue';
import TextField from '@/ui/components/modals/components/form/TextField.vue';
import SelectField from '@/ui/components/modals/components/form/SelectField.vue';
import ScalingField from '@/ui/components/modals/components/form/ScalingField.vue';
import RangeDatePicker from '@/ui/components/modals/components/RangeDatePicker.vue';
import NavigationTab from '@/ui/components/modals/components/NavigationTab.vue';
import { Validation } from '@/ui/mixins/validation';
import { getDefaultRulesObject } from '@/utils/utilsFunctions';
import { FormUpdateTracker } from '@/ui/mixins/formUpdateTracker';
import { IAnomalyDetectionState } from '@/store/modules/anomalyDetection/types';
import { IAnomalyDetection } from '@/types/anomalyDetection.types';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IVariable } from '@/types/variables.types';
import { IRoom } from '@/types/rooms.types';
import { IProject } from '@/types/project.types';
import { IRule } from '@/types/rules.types';

/**
 * Scheme to create, modify a specific device.
 * Specified in the device type definition in store/anomalyDetection/anomalyDetectionTypes.ts
 */
@Component({
  components: {
    InputFieldNumber,
    ComboboxField,
    TextField,
    SelectField,
    ScalingField,
    RangeDatePicker,
    NavigationTab,
  },
})
export default class DefaultSchema extends mixins(Validation, FormUpdateTracker) {
  @Prop({
    default: () => ({
      name: '',
      data: {
        type: '',
        meta: {},
        threshold: null,
        selectedWidth: null,
      },
      collection_id: '',
    }),
  }) deviceData!: IAnomalyDetection;
  @Prop() isEditModal!: boolean;
  @Prop({ default: '' }) activeRoomId!: string;

  @State('measurements') measurementsState!: IVariablesState;
  @State('anomalyDetection') anomalyDetectionState!: IAnomalyDetectionState;
  @Getter('rooms/sortedRoomsByName') sortedRoomsByName!: IRoom[];
  @Getter('projects/project') project!: IProject;
  @Getter('variables/variablesForComboBox') variablesForComboBox!: IVariable[];
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;
  @Action('rules/addRules') addRules!: (payload: {
    project_id: string; rulesList: IRule[];
  }) => Promise<IRule[]>;
  @Action('rules/deleteRule') deleteRule!: (payload: {
    project_id: string; rule_id: string;
  }) => Promise<void>;

  stage = 1;
  localDeviceData: IAnomalyDetection = JSON.parse(JSON.stringify(this.deviceData));
  showOptionalFields = false;
  tab = 0;

  @Watch('stage')
  onStageChange(val: number) {
    if (val === 2) {
      this.fetchVariables(this.$route.params.id);
    }
  }

  get anomalyDetectionInstanceWidthItems() {
    return [
      { id: 'half', name: 'Half' },
      { id: 'full', name: 'Full' },
    ];
  }

  // stages validations
  get nameValidation() {
    return !!this.localDeviceData?.name?.length;
  }

  /**
   * Checks all mappings of Anomaly Detection device
   * @return object that contains properties (all, variables, general) with a status value
   */
  get mappingsValidation() {
    if (this.stage === 2) {
      const generalMappingsCheck = () => {
        const requiredFields: any = [...this.mappingsByColumns.middleMappings, ...this.mappingsByColumns.outputMappings]
          .filter((el: any) => !this.anomalyDetectionMappings[el]?.optional);
        const arr: any = Object.entries(this.localDeviceData.data.meta.controllerMappings)
          .filter((el: any) => requiredFields.some((field: string) => field === el[0]))
          .map((el) => el[1]);
        return !arr.some((el: string) => el === '');
      };
      const variableMappingsCheck = () => {
        const variablesArr: string[] = this.localDeviceData.data.chartOptions.map((el: any) => el.var);
        return variablesArr.length ? !variablesArr.some((el: string) => el === '') : false;
      };
      const startStopDateCheck = () => {
        return this.isStartStopDateComponent
          ? !!this.localDeviceData.data.meta?.controllerMappings?.startDate && !!this.localDeviceData.data.meta?.controllerMappings?.endDate
          : true;
      };
      return {
        all: variableMappingsCheck() && startStopDateCheck() && generalMappingsCheck(),
        variables: variableMappingsCheck(),
        general: generalMappingsCheck() && startStopDateCheck(),
      };
    }
    return {
      all: false,
      variables: false,
      general: false,
    };
  }

  /**
   * Checks validation on stage 3.
   * @return status of combination collection_id, selectedWidth, threshold fields
   */
  get settingsValidation() {
    const checkThreshold = () => {
      return typeof this.localDeviceData.data.threshold === 'number'
        && this.localDeviceData.data.threshold <= 100
        && this.localDeviceData.data.threshold >= 0;
    };
    return !!this.localDeviceData.collection_id.length
      && !!this.localDeviceData.data.selectedWidth
      && checkThreshold();
  }

  get anomalyDetectionTypes() {
    return this.anomalyDetectionState.anomalyDetectionTypes;
  }
  get anomalyDetectionSchema() {
    return this.anomalyDetectionTypes[this.deviceData.data.type];
  }
  get anomalyDetectionMappings() {
    return this.anomalyDetectionSchema.mappings;
  }
  get mappingsByColumns() {
    if (this.localDeviceData?.data?.type) {
      return this.anomalyDetectionSchema.mappingsByColumns;
    } else {
      return [];
    }
  }

  /**
   * Contains list of properties for aggregation methods
   * @return object of routes to i18n translations
   */
  get aggregationMethods() {
    return [
      { name: this.$t('modals.manageCharts.form.aggregationMethods.averageOfPeriod'), id: 'avg' },
      { name: this.$t('modals.manageCharts.form.aggregationMethods.minimumOfPeriod'), id: 'min' },
      { name: this.$t('modals.manageCharts.form.aggregationMethods.maximumOfPeriod'), id: 'max' },
      { name: this.$t('modals.manageCharts.form.aggregationMethods.lastOfPeriod'), id: 'last' },
      { name: this.$t('modals.manageCharts.form.aggregationMethods.firstOfPeriod'), id: 'first' },
    ];
  }

  get isStartStopDateComponent() {
    return this.anomalyDetectionSchema.isStartStopDate;
  }

  get isMaximumOfVariablesReached() {
    return this.localDeviceData.data.chartOptions.length > 10;
  }

  /**
   * Checks incoming parameter and return list of measurements or some array according to parameter
   * @param key any type
   * @return list of measurements or list of aggregationMethods or array
   */
  listDefinition(key: string | Array<any>) {
    if (Array.isArray(key)) {
      return key;
    } else if (key === 'aggregations') {
      return this.aggregationMethods;
    } else {
      return this.variablesForComboBox;
    }
  }

  /**
   * Init Anomaly Detection mappings
   * @return object mappings form
   */
  initAnomalyDetectionInstanceMappings() {
    const listOfMappings: any = [...this.mappingsByColumns.outputMappings, ...this.mappingsByColumns.middleMappings];
    let mappings: any = {};
    listOfMappings.forEach((mapping: string) => {
      mappings = { ...mappings, [mapping]: '' };
    });
    return { ...mappings, ...{ startDate: null, endDate: null } };
  }

  /**
   * Init Anomaly Detection variables
   * @return object variables form
   */
  initAnomalyDetectionInstanceVariables() {
    let mappings: any = {};
    this.mappingsByColumns.variableMappings.forEach((mapping: string) => {
      if (mapping === 'scaling') {
        mappings = { ...mappings, [mapping]: { min: null, max: null } };
      } else {
        mappings = { ...mappings, [mapping]: '' };
      }
    });
    return { ...mappings, ...{ seriesType: 'View', type: 'line' } };
  }

  /**
   * Creates new variable
   */
  addVariable() {
    const variableOptions: any = this.initAnomalyDetectionInstanceVariables();
    this.localDeviceData.data.chartOptions.push(variableOptions);
  }

  /**
   * Remove selected variable
   * @param idx number, index of selected variable
   */
  removeVariable(idx: number | string) {
    const clone: any = JSON.parse(JSON.stringify(this.localDeviceData.data.chartOptions));
    clone.splice(idx, 1);
    this.localDeviceData.data.chartOptions = clone;
  }

  /**
   * Creates rules for device according to errorWarning field,
   * save ids of created rules in current device object
   * @param deviceData current device data object
   */
  async addRulesWhenCreateDevice(deviceData: any) {
    if (!deviceData.data.meta.controllerMappings.errorWarning.length) {
      deviceData.data.meta.warningRule = null;
      deviceData.data.meta.errorRule = null;
    } else {
      const rulesList: any = getDefaultRulesObject(deviceData.name, deviceData.data.meta.controllerMappings.errorWarning, 'Device');
      if (this.project.id) {
        const res = await this.addRules({
          project_id: this.project.id,
          rulesList,
        });
        deviceData.data.meta.warningRule = res[0].id;
        deviceData.data.meta.errorRule = res[1].id;
      }
    }
  }

  /**
   * Replacing existing rules with new ones when errorWarning field is changed
   * @param deviceData current device data object
   */
  async addRulesWhenEditDevice(deviceData: any) {
    if (!this.project.id) return;
    const oldErrorWarningVar = this.deviceData.data.meta.controllerMappings.errorWarning;
    const newErrorWarningVar = deviceData.data.meta.controllerMappings.errorWarning;
    if (oldErrorWarningVar && !newErrorWarningVar) {
      await Promise.all([
        this.deleteRule({
          project_id: this.project.id,
          rule_id: deviceData.data.meta.warningRule,
        }),
        this.deleteRule({
          project_id: this.project.id,
          rule_id: deviceData.data.meta.errorRule,
        }),
      ]);
    }
    // if errorWarning was changed
    if (oldErrorWarningVar !== newErrorWarningVar) {
      // delete old rules
      if (oldErrorWarningVar) {
        await Promise.all([
          this.deleteRule({
            project_id: this.project.id,
            rule_id: deviceData.data.meta.warningRule,
          }),
          this.deleteRule({
            project_id: this.project.id,
            rule_id: deviceData.data.meta.errorRule,
          }),
        ]);
      }
      // create new rules
      if (newErrorWarningVar) {
        const rulesList: any = getDefaultRulesObject(deviceData.name, deviceData.data.meta.controllerMappings.errorWarning, 'Device');
        const res: any = await this.addRules({
          project_id: this.project.id,
          rulesList,
        });
        const getWarningRule = () => {
          const warningRuleObj: any = res.find((rule: any) => rule.name === `${deviceData.name} Warning Rule`);
          return warningRuleObj.id;
        };
        const getErrorRule = () => {
          const errorRuleObj: any = res.find((rule: any) => rule.name === `${deviceData.name} Error Rule`);
          return errorRuleObj.id;
        };
        deviceData.data.meta.warningRule = getWarningRule();
        deviceData.data.meta.errorRule = getErrorRule();
      }
    }
  }

  handleStartStopDate(e: any) {
    this.localDeviceData.data.meta.controllerMappings = { ...this.localDeviceData.data.meta.controllerMappings, ...e };
  }

  /**
   * Save device data in data base
   */
  async sendForm() {
    this.$emit('onFormUnchanged', true);
    const copy = JSON.parse(JSON.stringify(this.localDeviceData));
    copy.project_id = this.project.id;

    // rules
    if (!this.isEditModal) {
      await this.addRulesWhenCreateDevice(copy);
    } else {
      await this.addRulesWhenEditDevice(copy);
    }

    this.$emit('handleControl', copy);
    this.$emit('closeDialog');
  }

  created() {
    this.localDeviceData = JSON.parse(JSON.stringify(this.deviceData));
    this.$set(this.localDeviceData.data.meta, 'controllerMappings', this.initAnomalyDetectionInstanceMappings());
    this.$set(this.localDeviceData.data, 'chartOptions', []);

    if (this.activeRoomId.length && !this.isEditModal) this.localDeviceData.collection_id = this.activeRoomId;

    if (this.isEditModal) {
      this.localDeviceData.data.meta.controllerMappings = {
        ...this.localDeviceData.data.meta.controllerMappings,
        ...this.deviceData.data.meta.controllerMappings,
      };
      const chartOptionsLocalCopy: any = JSON.parse(JSON.stringify(this.deviceData.data.chartOptions));
      this.localDeviceData.data.chartOptions = chartOptionsLocalCopy.filter((el: any, inx: number, arr: any) => {
        const typesToRemove: string[] = ['Threshold', 'AnomalyScore'];
        const isAnomalyScoreMappingFilled = !!this.deviceData.data.meta.controllerMappings.anomalyScore.length;
        const isLastValue = arr.length - 1 === inx;
        return !typesToRemove.some((type: string) => type === el.seriesType) && !(isLastValue && isAnomalyScoreMappingFilled);
      });
    } else {
      this.addVariable();
      this.dataSnapshot = JSON.parse(JSON.stringify(this.localDeviceData));
    }
    // remove Anomaly Score from chartOptions so it doesnt duplicate Anymore
    const hasAnomalyScore = this.localDeviceData.data.chartOptions.some((element: any) => {
      return element.name === 'Anomaly Score';
    });
    if (hasAnomalyScore === true) {
      this.localDeviceData.data.chartOptions = this.localDeviceData.data.chartOptions.filter((element: any) => element.name !== 'Anomaly Score');
    }
  }
}
