import api from '@/services/api';
import { IRoom } from '@/types/rooms.types';

export default {
  async fetchCollections(projectId: string) {
    return api.fetch(`/projects/${projectId}/collections`, 'GET');
  },

  async createCollection(projectId: string, collection: Partial<IRoom>) {
    return api.fetch(`/projects/${projectId}/collections`, 'POST', collection);
  },

  async updateCollection(projectId: string, collectionId: string, collection: Partial<IRoom>) {
    return api.fetch(`/projects/${projectId}/collections/${collectionId}`, 'PUT', collection);
  },

  async deleteCollection(projectId: string, collectionId: string) {
    return api.fetch(`/projects/${projectId}/collections/${collectionId}`, 'DELETE');
  },
};
