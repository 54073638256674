
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import AppBar from '@/ui/components/core/AppBar.vue';
import AppDrawer from '@/ui/components/core/AppDrawer.vue';
import AppView from '@/ui/components/core/AppView.vue';
import ReportMessage from '@/ui/components/components/ReportMessage.vue';
import { ReleasesNotes } from '@/ui/mixins/releasesNotes';

@Component({
  components: {
    AppBar,
    AppDrawer,
    AppView,
    ReportMessage,
  },
})
export default class App extends mixins(ReleasesNotes) {}
