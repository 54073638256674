import api from '@/services/api';
import { IRule } from '@/types/rules.types';

export default {
  async fetchRules(projectId: string) {
    return api.fetch(`/projects/${projectId}/rules`, 'GET');
  },

  async createRule(projectId: string, rule: IRule) {
    return api.fetch(`/projects/${projectId}/rules`, 'POST', rule);
  },

  async createRules(projectId: string, rules: IRule[]) {
    return api.fetch(`/projects/${projectId}/rules`, 'POST', rules);
  },

  async updateRule(projectId: string, ruleId: string, rule: IRule) {
    return api.fetch(`/projects/${projectId}/rules/${ruleId}`, 'PUT', rule);
  },

  async deleteRule(projectId: string, ruleId: string) {
    return api.fetch(`/projects/${projectId}/rules/${ruleId}`, 'DELETE');
  },
};
