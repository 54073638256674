
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { IRoom } from '@/types/rooms.types';
import { ICollectionItem } from '@/types/common.types';
import { IMember } from '@/types/members.types';

/**
 * Component where Collection/Room Access can be selected for a specific user
 */
@Component({
  components: {
    ModalWindow,
  },
})
export default class ManageCollectionsAccessModal extends Vue {
  @Prop({ default: {} }) memberObject!: IMember;
  @Prop({ default: true }) updateMemberInModal!: boolean;
  @Prop({ default: undefined }) useCustomProjectId!: string;
  @Getter('rooms/rooms') rooms!: IRoom[];
  @Action('members/updateMemberCollections') updateMemberCollections!: (
    { project_id, member, collectionsList }: { project_id: string; member: IMember; collectionsList: string[] }
  ) => Promise<void>;

  collectionList: ICollectionItem[] = [];

  get selectedCollections() {
    return this.collectionList
      .filter((item: ICollectionItem) => item.selected)
      .map((item: ICollectionItem) => item.id);
  }

  get projectIdToUse() {
    return this.useCustomProjectId ? this.useCustomProjectId : this.$route.params.id;
  }

  updateCollections() {
    if (this.updateMemberInModal) {
      this.updateMemberCollections({
        project_id: this.projectIdToUse,
        member: this.memberObject,
        collectionsList: this.selectedCollections,
      });
    } else {
      this.$emit(
        'selectedCollections',
        { id: this.memberObject.id, selections: this.selectedCollections },
      );
    }
    (this.$refs.ModalWindowInstance as any).closeDialog();
  }

  handleItemClick(item: ICollectionItem, index: number) {
    this.collectionList[index].selected = !item.selected;
  }

  async initCollectionList() {
    this.collectionList = Object.values(this.rooms).map((room: IRoom) => {
      const selectedStatus = Array.isArray(this.memberObject?.collections)
        ? this.memberObject.collections.includes(room.id)
        : false;
      return { id: room.id, name: room.name, selected: selectedStatus };
    });
  }

  onDialogStatusChange(status: boolean) {
    if (status) this.initCollectionList();
  }
}
