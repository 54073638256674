
import { Vue, Component, Prop } from 'vue-property-decorator';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import DeviceActions from '@/ui/components/devices/actions/DeviceActions.vue';
import ShowEventBase from '@/ui/components/devices/devices/base/ShowEventBase.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import StatusIconBase from '@/ui/components/devices/devices/base/StatusIconBase.vue';
import OutputFieldTextBase from '@/ui/components/devices/devices/base/OutputFieldTextBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import InputFieldTextBase from '@/ui/components/devices/devices/base/InputFieldTextBase.vue';
import DropDownBase from '@/ui/components/devices/devices/base/DropDown.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent SurveyClient device
 */
@Component({
  components: {
    ShowEventDotBase,
    DropDownBase,
    InputFieldTextBase,
    PushButtonBase,
    OutputFieldBase,
    OutputFieldTextBase,
    StatusIconBase,
    InputFieldBase,
    DeviceActions,
    ShowEventBase,
  },
})
export default class SurveyClient extends mixins(Validation) {
  @Prop() deviceData!: IDevice;
  @State('measurements') measurementsState!: IVariablesState;

  messageBoxObject = {
    ronova_info: [0, 1],
    ronova_warning: [2, 2],
  };
  wifiStatusObject = {
    ronova_wifi_no: [-1, -1],
    ronova_wifi_low: [0, 33],
    ronova_wifi_med: [34, 66],
    ronova_wifi_full: [67, 100],
  };
  satelliteCount = {
    ronova_gps_no_fix: [-1, -1],
    ronova_gps_fix: [0, 100],
  };
  gpsStatus = {
    ronova_lte_no: [-1, -1],
    ronova_lte_low: [0, 33],
    ronova_lte_med: [34, 66],
    ronova_lte_full: [67, 100],
  };
  batteryStatus = {
    ronova_battery_charge: [-1, -1],
    ronova_battery_0: [0, 11],
    ronova_battery_25: [12, 37],
    ronova_battery_50: [38, 63],
    ronova_battery_75: [64, 88],
    ronova_battery_100: [89, 100],
  };
  musterMapping = {
    0: 'none',
    1: 'contour',
    2: 'playfield',
    3: 'obstacles',
    4: 'no_work',
  }

  get statusMessageBox() {
    if (this.measurements.get(this.deviceData.data.mappings.StatusIcon4_currentValue) === undefined) {
      return -1;
    } else {
      return this.measurements.get(this.deviceData.data.mappings.StatusIcon4_currentValue);
    }
  }

  get measurements() {
    return this.measurementsState.measurements;
  }
  get unit() {
    return this.deviceData.data.meta && this.deviceData.data.meta.unit ? this.deviceData.data.meta.unit : '';
  }
}
