
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';

@Component({
  components: {
    BaseChartWrapper,
  },
})
export default class Charts extends Vue {
  @Prop() httpCharts!: any;
  @Prop({ default: null }) chartsScaling!: any;
  @Prop({
    default: {
      actualPower: '',
      calculatedPower: null,
      calculatedEnergy: null,
    },
  }) mqttChartsVariables!: any;

  tab: any = null

  get langPath() {
    return 'mlModel.HppProductionService.settingsView';
  }

  get power() {
    return {
      name: '',
      data: {
        chartOptions: [
          {
            agg: 'avg',
            name: this.$t('mlModel.HppProductionService.settingsView.chartLabels.power.actualPower'),
            scaling: {
              max: this.chartsScaling * 2,
              min: 0,
            },
            type: 'line',
            unit: 'kW',
            var: this.mqttChartsVariables.actualPower,
          },
          {
            agg: 'avg',
            name: this.$t('mlModel.HppProductionService.settingsView.chartLabels.power.calculatedPower'),
            scaling: {
              max: this.chartsScaling * 2,
              min: 0,
            },
            type: 'line',
            unit: 'kW',
            var: this.mqttChartsVariables.calculatedPower,
          },
        ],
        selectedStackingOptions: 'normal',
        selectedWidth: 'full',
        type: 'chart',
      },
    };
  }

  get chartData() {
    return {
      chartWidth: null,
      chartHeight: 600,
      chartData: this.power,
      navigationItemsToExclude: ['live', 'hour'],
    };
  }
}
