
import { Vue, Component, Prop } from 'vue-property-decorator';
import SettingsView from '@/ui/components/devices/components/SettingsView.vue';
import DeviceActions from '@/ui/components/devices/actions/DeviceActions.vue';
import ShowEventBase from '@/ui/components/devices/devices/base/ShowEventBase.vue';
import PreviewActions from '@/ui/components/devices/actions/PreviewActions.vue';
import DeviceDescription from '@/ui/components/devices/actions/DeviceDescription.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that defines the structure of the device
 */
@Component({
  components: {
    SettingsView,
    DeviceActions,
    ShowEventBase,
    PreviewActions,
    DeviceDescription,
  },
})
export default class DeviceLayout extends Vue {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: null }) previewData!: { name: string; type: string };
  @Prop({ default: false }) isPreview!: boolean;
  @Prop({ default: 'default' }) deviceSize!: 'default' | 'x2h' | 'x4h' | 'x5h';
  @Prop({ default: null }) deviceIcon!: string;
  @Prop({ default: '30' }) deviceIconSize!: string | number;
  @Prop({ default: 'theme' }) deviceIconTheme!: string;
  @Prop() settingsModalWidth!: string | number;
  @Prop({ default: false }) isCharts!: boolean; // is device charts visible
  @Prop({ default: false }) deviceModal!: boolean; // is device charts visible
  @Prop({ default: '1750' }) chartsModalWidth!: string | number;
  @Prop({ default: 'x-small' }) chartsButtonSize!: 'x-small' | 'small' | 'large' | 'x-large';
  // makes padding between elements (DeviceDescription, DeviceActions...) smaller. Is needed for "default" size devices inside Preview. When they have an Chart Window.
  @Prop({ default: false }) showSmallerDeviceActions!: boolean;

  isSettingsView = false;

  switchSettingsView(setting: boolean) {
    this.isSettingsView = setting;
    this.$emit('switchSettingsView', setting);
  }
}
