
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

/**
 * Component that represent OutputFieldText Basic Control
 */
@Component
export default class OutputFieldTextBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop() min?: number;
  @Prop() max?: number;

  get actualValue(): any {
    let returnText: any = this.checkFallbackText();
    // cut String at max lenght
    returnText = returnText.substring(0, this.max);
    return returnText;
  }

  checkFallbackText() {
    if (typeof this.measurements.get(this.mappingsClean.actualValue) === 'string') {
      return this.measurements.get(this.mappingsClean.actualValue);
    } else {
      return 'No Text';
    }
  }

  get isNotMapped(): boolean {
    return this.mappingsClean.actualValue === '';
  }
}
