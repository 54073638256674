
import { Component, Prop, Watch } from 'vue-property-decorator';
import EmailAction from '@/ui/components/modals/actions/EmailAction.vue';
import WebhookAction from '@/ui/components/modals/actions/WebhookAction.vue';
import PrintReport from '@/ui/components/modals/ManageReport/PrintReport.vue';
import TriggerActions from '@/ui/components/modals/ManageReport/TriggerActions.vue';
import TariffSettingsTooltip from '@/ui/components/modals/ManageReport/TariffSettingsTooltip.vue';
import { mixins } from 'vue-class-component';
import { ReportFormRules } from '@/ui/components/modals/ManageReport/mixins/rules';
import { minutesToTime, timeToMinutes } from '@/utils/utilsFunctions';
import { fillUpTariffTimes, convertTimes } from '@/ui/components/modals/ManageReport/utils';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { Action, Getter, State } from 'vuex-class';
import TimezoneChooser from '@/ui/components/components/TimezoneChooser.vue';
import { isArray } from 'highcharts';
import { IReport } from '@/types/report.types';
import { UserRoleCode } from '@/utils/userRoles';
import { IMember } from '@/types/members.types';

/**
 * A component that allows to manage Zev report form
 */
@Component({
  computed: {
    UserRoleCode: () => UserRoleCode,
  },
  components: {
    email: EmailAction,
    webhook: WebhookAction,
    TimezoneChooser,
    TariffSettingsTooltip,
    PrintReport,
    TriggerActions,
  },
})
export default class ZevReportNew extends mixins(ReportFormRules) {
  @Prop({ default: null }) report!: null | any;
  @Prop() isNewReport!: boolean;
  @Getter('measurements/measurementsKeys') measurementsKeys!: string[];
  @Getter('members/currentMember') currentMember!: IMember;
  @Getter('variables/variablesForComboBox') variablesForComboBox!: [];
  @Action('report/addReport') addReport!: ({ id, reportModel }: { id: string; reportModel: IReport }) => Promise<void>;

  formValid = true;
  localReportData: any = {
    type: 'zev',
    name: '',
    currency: 'EUR',
    timezone: moment.tz.guess(),
    address: {
      street: '',
      city: '',
      country: '',
    },
    variables: [{ name: '', title: '', unit_cost: 0, unit: '' }],
    actions: [],
    meta: {
      zev: {
        arrayDaySettingsInternal: ['custom', 'custom', 'custom', 'custom', 'custom', 'custom', 'custom'],
        arrayDaySettingsExternal: ['custom', 'custom', 'custom', 'custom', 'custom', 'custom', 'custom'],
        groupingSelected: true,
        innosolv: {
          username: '',
        },
        titles: {
          grid: 'Grid',
          photovoltaik: 'PV',
        },
        consumers: [{ name: '', title: '', metering_id: '' }],
        producers: {
          internal: [{ name: '', title: '' }],
          external: [{ name: '', title: '' }],
          internal_to_external: { name: '', title: '' },
        },
        tariffs: {
          internal: {
            time: [
              [['00:00', '00:00'], ['00:00', '00:00']],
              [['00:00', '00:00'], ['00:00', '00:00']],
              [['00:00', '00:00'], ['00:00', '00:00']],
              [['00:00', '00:00'], ['00:00', '00:00']],
              [['00:00', '00:00'], ['00:00', '00:00']],
              [['00:00', '00:00'], ['00:00', '00:00']],
              [['00:00', '00:00'], ['00:00', '00:00']],
            ],
            low: '',
            high: '',
          },
          external: {
            time: [
              [['00:00', '00:00'], ['00:00', '00:00']],
              [['00:00', '00:00'], ['00:00', '00:00']],
              [['00:00', '00:00'], ['00:00', '00:00']],
              [['00:00', '00:00'], ['00:00', '00:00']],
              [['00:00', '00:00'], ['00:00', '00:00']],
              [['00:00', '00:00'], ['00:00', '00:00']],
              [['00:00', '00:00'], ['00:00', '00:00']],
            ],
            low: '',
            high: '',
          },
        },
      },
    },
    active: false,
  };
  toggleSecondTariff = true;
  tempWeekDay = 0;

  get internalTariffValid() {
    let valid = true;
    this.localReportData.meta.zev.tariffs.internal.time.forEach((tariffs: any[][]) => {
      tariffs.forEach((tariff: any[]) => {
        const tariffTimeValid = tariff.map((t: any) => /[0-9][0-9]:[0-9][0-9]$/.test(t));
        const isValid = !tariffTimeValid.some((el: boolean) => !el);
        if (isValid) {
          if (timeToMinutes(tariff[0]) > timeToMinutes(tariff[1])) {
            valid = false;
          }
        }
      });
    });
    return valid;
  }
  get externalTariffValid() {
    let valid = true;
    this.localReportData.meta.zev.tariffs.external.time.forEach((tariffs: any[][]) => {
      tariffs.forEach((tariff: any[]) => {
        const tariffTimeValid = tariff.map((t: any) => /[0-9][0-9]:[0-9][0-9]$/.test(t));
        const isValid = !tariffTimeValid.some((el: boolean) => !el);
        if (isValid) {
          if (timeToMinutes(tariff[0]) > timeToMinutes(tariff[1])) {
            valid = false;
          }
        }
      });
    });
    return valid;
  }

  get apartmentsTitlesDuplicates() {
    const titles = this.localReportData.meta.zev.consumers.map((data: any) => data.title);
    return titles.some((t: string, inx: number) => inx !== titles.indexOf(t));
  }

  get currentDayOptions() {
    const langPath: any = this.$t('modals.manageReport.daySettings');
    return [
      { state: langPath.customOption, abbr: 'custom' },
      { state: langPath.highOption, abbr: 'high' },
      { state: langPath.lowOption, abbr: 'low' },
    ];
  }

  get formatOptions() {
    return this.localReportData.type === 'zev'
      ? [
        { state: 'pdf', abbr: 'pdf' },
        { state: 'xml', abbr: 'xml' },
      ] : [
        { state: 'json', abbr: 'json' },
        { state: 'pdf', abbr: 'pdf' },
        { state: 'csv', abbr: 'csv' },
      ];
  }

  get currencyOptions() {
    return [
      { state: 'EUR', abbr: 'EUR' },
      { state: 'CHF', abbr: 'CHF' },
    ];
  }

  get weekDayOptions() {
    const langPath: any = this.$t('modals.manageReport.weekDayOptions');
    if (!this.localReportData.meta.zev.groupingSelected) {
      return [
        { state: langPath.mondayOption, abbr: 0 },
        { state: langPath.tuesdayOption, abbr: 1 },
        { state: langPath.wednesdayOption, abbr: 2 },
        { state: langPath.thursdayOption, abbr: 3 },
        { state: langPath.fridayOption, abbr: 4 },
        { state: langPath.saturdayOption, abbr: 5 },
        { state: langPath.sundayOption, abbr: 6 },
      ];
    } else {
      return [
        { state: langPath.workDayGrouping, abbr: 0 },
        { state: langPath.weekendGrouping, abbr: 5 },
      ];
    }
  }

  get fullValidation() {
    return this.formValid && this.externalTariffValid && this.internalTariffValid;
  }

  get reportDataToSend() {
    let reportCopy = cloneDeep(this.localReportData);
    if (this.localReportData.meta.zev.groupingSelected) {
      reportCopy = fillUpTariffTimes(reportCopy);
    }
    reportCopy = convertTimes(reportCopy);
    return reportCopy;
  }

  @Watch('fullValidation')
  onFormValidStatusChange(status: boolean) {
    this.$emit('onFormValidStatusChange', status);
  }

  changeOfDaySetting(isInternal: boolean, currentSelectedDay: number) {
    const currentDaySettingInternal = this.localReportData.meta.zev.arrayDaySettingsInternal[currentSelectedDay];
    const currentDaySettingExternal = this.localReportData.meta.zev.arrayDaySettingsExternal[currentSelectedDay];
    const internalTimes = this.localReportData.meta.zev.tariffs.internal.time;
    const externalTimes = this.localReportData.meta.zev.tariffs.external.time;

    if (isInternal) {
      // changes in internal Day settings
      internalTimes[currentSelectedDay] = this.getAllDaySetting(currentDaySettingInternal);
    } else {
      // changes in external Day settings
      externalTimes[currentSelectedDay] = this.getAllDaySetting(currentDaySettingExternal);
    }
    this.localReportData = cloneDeep(this.localReportData);
    // will trigger fields to show error message for user
    this.$nextTick(() => {
      this.formValidate();
    });
  }

  clearTariffTimes() {
    if (!this.toggleSecondTariff) {
      for (let i = 0; i <= 6; i++) {
        const currentTarifs = this.localReportData.meta.zev.tariffs;
        if (this.localReportData.meta.zev.arrayDaySettingsInternal[i] !== 'high') {
          currentTarifs.internal.time[i] = [currentTarifs.internal.time[i][0]];
        }
        if (this.localReportData.meta.zev.arrayDaySettingsExternal[i] !== 'high') {
          currentTarifs.external.time[i] = [currentTarifs.external.time[i][0]];
        }
      }
    } else {
      for (let i = 0; i <= 6; i++) {
        const currentTarifs = this.localReportData.meta.zev.tariffs;
        if (this.localReportData.meta.zev.arrayDaySettingsInternal[i] !== 'high') {
          currentTarifs.internal.time[i].push(['00:00', '00:00']);
        }
        if (this.localReportData.meta.zev.arrayDaySettingsExternal[i] !== 'high') {
          currentTarifs.external.time[i].push(['00:00', '00:00']);
        }
      }
    }
  }

  /**
   * Used to format times from time in minutes to format HH:MM
   */
  revertTimeFromApi() {
    // internal Times
    // map every start/end time from its minute value to an actual time in HH:mm format
    // e.g. [["0", "360"]] -> [["00:00", "06:00"]]
    this.localReportData.meta.zev.tariffs.internal.time.forEach((tariffs: any[][], tariffsInx: number) => {
      tariffs.forEach((tariff: any[], tariffInx: number) => {
        tariff.forEach((time: any, timeInx: number) => {
          this.localReportData.meta.zev.tariffs.internal.time[tariffsInx][tariffInx][timeInx] = minutesToTime(time);
        });
      });
    });
    // external Times
    // map every start/end time from its minute value to an actual time in HH:mm format
    // e.g. [["0", "360"]] -> [["00:00", "06:00"]]
    this.localReportData.meta.zev.tariffs.external.time.forEach((tariffs: any[][], tariffsInx: number) => {
      tariffs.forEach((tariff: any[], tariffInx: number) => {
        tariff.forEach((time: any, timeInx: number) => {
          this.localReportData.meta.zev.tariffs.external.time[tariffsInx][tariffInx][timeInx] = minutesToTime(time);
        });
      });
    });
  }

  /**
  * Takes the current times for either internal or external tariffs and the current
  * day setting (low/high) and sets it to the respective times so that it is valid for
  * all day.
*/
  getAllDaySetting(currentDaySetting: string) {
    switch (currentDaySetting) {
      case 'low':
        // low is selected
        if (this.toggleSecondTariff) return [['00:00', '11:59'], ['12:00', '23:59']];
        else return [['00:00', '23:59']];
      case 'high': return [];
      case 'custom':
        if (this.toggleSecondTariff) return [['00:00', '00:00'], ['00:00', '00:00']];
        else return [['00:00', '00:00']];
    }
  }

  /**
   * Function that add new action to report
   * @param type string, action type key
   */
  addAction(type: string) {
    const newAction: any = { 'type': type, 'format': 'pdf' };

    switch (type) {
      case 'webhook':
        newAction.params = {
          headers: {
            'content-type': 'application/json',
          },
          method: 'POST',
          url: '',
        };
        break;
      case 'email':
        newAction.params = {
          recipients: ['example@mail.com'],
          subject: '{{name}}',
          body: 'Report from {{start}} to {{end}}',
        };
        break;
    }
    this.$set(this.localReportData.actions, this.localReportData.actions.length, newAction);
  }
  deleteAction(index: number) {
    this.localReportData.actions.splice(index, 1);
  }

  onTimezoneChange(value: string) {
    this.localReportData.timezone = value;
  }

  async addZevVar(type: string) {
    if (type === 'pv') this.localReportData.meta.zev.producers.internal.push({ name: '', title: '' });
    else this.localReportData.meta.zev.consumers.push({ name: '', title: '', metering_id: '' });

    await this.$nextTick();
    this.formValidate();
  }
  removeZevVar(type: string, index: number) {
    if (type === 'pv') this.localReportData.meta.zev.producers.internal.splice(index, 1);
    else this.localReportData.meta.zev.consumers.splice(index, 1);
  }

  formValidate() {
    (this.$refs.form as any).validate();
  }

  created() {
    if (this.report) {
      if (this.report.meta.zev.producers.internal_to_external === undefined || isArray(this.report.meta.zev.producers.internal_to_external)) {
        // is needed in order for the old zev reports to work properly
        const copy = JSON.parse(JSON.stringify(this.report));
        copy.meta.zev.producers.internal_to_external = { name: '', title: '' };
        this.localReportData = copy;
      } else {
        this.localReportData = JSON.parse(JSON.stringify(this.report));
      }
      this.revertTimeFromApi();
    }
  }
  mounted() {
    this.formValidate();
  }
}
