import store from '@/store';
import { IDevice } from '@/types/devices.types';
import { IMember } from '@/types/members.types';
import { IRoom } from '@/types/rooms.types';
import { IRule, IRuleActionsEmailParams } from '@/types/rules.types';
import i18n from '@/ui/plugins/i18n';
import { cloneDeep } from 'lodash';
import { UserRoleCode } from './userRoles';

/**
 * @param member member object
 * @param rulesList list of rules as IRule[]
 * @param conModel connectivity Object from Project Meta
 * @returns return true if member is present in all rules as recipient and in connectivity check
 */
export function getIsMemberInRulesAsRecipient(member: IMember, rulesList: IRule[], conModel: any) {
  // checks if user is present in all rules as recipient
  const isPresentInRules = rulesList.every((currentRule: IRule) => {
    let ruleEmailActions = currentRule.actions.filter((action) => action.type === 'email');
    ruleEmailActions = ruleEmailActions.map((action: any) => {
      if (!(action.params as IRuleActionsEmailParams).recipients) {
        action.params.recipients = [];
      }
      return action;
    });
    return ruleEmailActions.some((action: any) => action.params.recipients.includes(member.email));
  });

  // checks if recipients array is present in actions
  conModel.actions.forEach((action: any) => {
    if (!(action.params as IRuleActionsEmailParams).recipients) {
      action.params.recipients = [];
    }
  });
  // checks if user is present inside connectivity check as recipient
  const isPresentInConnectivityCheck = conModel.actions.some((action: any) => action.params.recipients.includes(member.email));

  return isPresentInRules && isPresentInConnectivityCheck;
}

/**
  * @param rooms - array of rooms
  * Returns object with default room ids, based on the device type inside the room
  * Example:
  *  -  Rooms with EMSV2 or EMS devices will be visible for UserRoleCode.user
  *  -  Rooms with EnergyViewV2 or EnergyView devices will be visible for UserRoleCode.user and UserRoleCode.readOnly
  */
export function defaultRoomIdObject(rooms: IRoom[]) {
  const roomIdObject: Record<number, string[]> = {
    1001: [],
    1002: [],
  };
  rooms.forEach((room) => {
    const devicesInRoom = store.getters['devices/devicesByRoom'](room.id);
    devicesInRoom.forEach((device: IDevice) => {
      if (device.data.type === 'EMSV2' || device.data.type === 'EMS') {
        roomIdObject[1001].push(room.id);
      }
      if (device.data.type === 'EnergyViewV2' || device.data.type === 'EnergyView') {
        roomIdObject[1001].push(room.id);
        roomIdObject[1002].push(room.id);
      }
    });
  });
  return roomIdObject;
}

/**
   * Adds default room access to user
   * @param {UserRoleCode} role user role
   * @param {IMember} newMember new member
   * @param {Record<number, string[]>} defaultRoomIdObject default room id object
   * @param {string} project_id project id
   */
export async function addDefaultRoomToUser(role: UserRoleCode, newMember: IMember, defaultRoomIdObject: Record<number, string[]>, project_id: string) {
  // contains the ids of the default rooms
  const roomsToAdd = defaultRoomIdObject[role];
  let collections = newMember.collections || [];

  // add the default rooms to the user if not allready present inside collections allready
  roomsToAdd.forEach((room: string) => {
    if (collections.includes(room)) {
      return;
    }
    collections.push(room);
  });
  // remove duplicates if there are somehow any inside the array
  collections = collections.filter((item, index) => collections.indexOf(item) === index);

  if (roomsToAdd.length === 0) return;

  store.dispatch('members/updateMemberCollections', {
    project_id,
    member: newMember,
    collectionsList: collections,
  });
}

export function getMailSubject(rule: IRule) {
  const alertAction: any = rule.actions.find((action: any) => action.type === 'alert');
  let subject = '';
  const langPath = 'modals.manageRules.defaultMailSubjectTexts';
  if (alertAction !== undefined) {
    switch (alertAction.params?.type) {
      case 0: {
        subject = i18n.t(`${langPath}.info`) as string;
        break;
      }
      case 1: {
        subject = i18n.t(`${langPath}.warning`) as string;
        break;
      }
      case 2: {
        subject = i18n.t(`${langPath}.error`) as string;
        break;
      }
      default: {
        subject = i18n.t(`${langPath}.system`) as string;
        break;
      }
    }
  }
  return subject;
}

export async function submitChanges(conModel: any) {
  const project = store.getters['projects/project'];
  const localProject = cloneDeep(project);
  localProject.connectivity = conModel;
  store.dispatch('projects/updateProject', localProject);
  store.dispatch('rules/loadRules', localProject.id);
}

/**
   * Update connectivity check recipient. Either the email get removed of added based on user input
   */
export async function updateConnectivityCheck(email: string, conModel: any) {
  const mailActionObject = {
    'type': 'email',
    'params': {
      'recipients': [email],
      'subject': 'Connectivity Check',
      'type': 0,
      'body': '{{rule}} - The Lynus System currently has no internet connection.',
    },
  };

  if (conModel.actions === null || conModel.actions === undefined) {
    conModel.actions = [];
  }

  // if connectivity check is disabled but there are actions, we allways enable it
  if (!conModel.enabled && conModel.actions.length > 0) {
    conModel.enabled = true;
  }

  if (!conModel.actions.some((action: any) => action.type === 'email')) {
    conModel.actions.push(mailActionObject);
  } else {
    conModel.actions.forEach((action: any, actionIndex: number) => {
      if (action.type === 'email') {
        if (!action.params.recipients) {
          action.params.recipients = [];
        }
        // check if member is already in recipients
        if (action.params.recipients.filter((recipient: any) => recipient === email).length === 0) {
          conModel.actions[actionIndex].params.recipients.push(email);
        }
      }
    });
  }

  const hasAlertAction = conModel.actions.some((action: any) => action.type === 'alert');
  if (!hasAlertAction) {
    // if the connectivity check has no alert action add a warning alert
    const alertActionObject = {
      'type': 'alert',
      'params': {
        'type': 1,
        'body': '{{rule}} - The Lynus System currently has no internet connection.',
      },
    };
    conModel.actions.push(alertActionObject);
  }

  await submitChanges(conModel);
}

/**
 * @param email email of the member
 * @param id project id
 * @param rulesWithEmailAction rules with email action as IRule[]
 * @param rulesWithNoMailAction rules without email action as IRule[]
 * @param conModel connectivity model
 * Updated all Rules with email action to add selected member to recipients
 */
export async function addMemberToRuleActions(email: string, id: string, rulesWithEmailAction: IRule[], rulesWithNoMailAction: (IRule | undefined)[], conModel: any) {
  // add selected member to all rules with email action
  rulesWithEmailAction.forEach((rule: any) => {
    const ruleCopy = cloneDeep(rule);
    ruleCopy.actions.forEach((action: any) => {
      if (action.type === 'email') {
        // check if member is already in recipients
        if (!action.params.recipients) {
          action.params.recipients = [];
        }
        if (action.params.recipients.filter((recipient: any) => recipient === email).length === 0) {
          action.params.recipients.push(email);
        }
      }
    });

    // if rule is not active, activate it and set timeout to 120
    if (!rule.active) {
      ruleCopy.active = true;
      ruleCopy.timeout = 120;
    }

    return store.dispatch('rules/modifyRule', {
      id,
      ruleModel: ruleCopy,
      rule_id: ruleCopy.id,
    });
  });

  rulesWithNoMailAction.forEach((rule: any) => {
    // add a email action to rule if it does not have one
    const mailActionObject = {
      'type': 'email',
      'params': {
        'recipients': [email],
        'subject': getMailSubject(rule),
        'type': 0,
        'body': '{{rule}}',
      },
    };
    const ruleCopy = cloneDeep(rule);
    ruleCopy.actions.push(mailActionObject);

    // if rule is not active, activate it and set timeout to 120
    if (!rule.active) {
      ruleCopy.active = true;
      ruleCopy.timeout = 120;
    }

    return store.dispatch('rules/modifyRule', {
      id,
      ruleModel: ruleCopy,
      rule_id: ruleCopy.id,
    });
  });

  await updateConnectivityCheck(email, conModel);
  email = '';
}

/**
 * @param email email of the member
 * @param id project id
 * @param rulesWithEmailAction rules with email action as IRule[]
 * @param conModel connectivity model
 * Updated all Rules with email action to remove selected member from recipients
 */
export async function deleteRecipientFromRules(recipient: string, id: string, rulesWithEmailAction: IRule[], conModel: any) {
  // eslint-disable-next-line no-restricted-syntax
  for (const rule of rulesWithEmailAction) {
    const ruleCopy = cloneDeep(rule);
    ruleCopy.actions.forEach((action: any, actionIndex: number) => {
      // remove recipient
      if (action.type === 'email') {
        if (!action.params.recipients) {
          action.params.recipients = [];
        }
        action.params.recipients = action.params.recipients.filter((name: string) => name !== recipient);
      }
    });
    await store.dispatch('rules/modifyRule', {
      id,
      ruleModel: ruleCopy,
      rule_id: ruleCopy.id,
    });
  }
  if (conModel.actions !== null && conModel.actions !== undefined) {
    conModel.actions.forEach((action: any, actionIndex: number) => {
      if (action.type === 'email') {
        if (!action.params.recipients) {
          action.params.recipients = [];
        }
        conModel.actions[actionIndex].params.recipients = conModel.actions[actionIndex].params.recipients.filter((name: string) => name !== recipient);
      }
    });
    await submitChanges(conModel);
  }
}
