
import { Component, Vue } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { UserRoleCode } from '@/utils/userRoles';
import { IMember } from '@/types/members.types';
import { IAppState } from '@/store/modules/app/types';
import { IProject } from '@/types/project.types';
import { hybridVersionDate, plcVersionDate, tenantUpdateWagoCounters } from '@/utils/versionManagementUtils';

@Component({
  computed: {
    UserRoleCode: () => UserRoleCode,
  },
  components: {
    WizardContentView,
  },
})
export default class WelcomePage extends Vue {
  @State('app') appState!: IAppState;
  @Getter('projects/project') project!: IProject;
  @Getter('measurements/measurements') measurements!: any;
  @Getter('members/currentMember') currentMember!: IMember;
  @Mutation('tenantWizard/setOverallProductionMeasurement') setOverallProductionMeasurement!: (payload: string) => void;
  @Mutation('tenantWizard/setHasLynusInverter') setHasLynusInverter!: (data: boolean) => void;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;

  showAdditionalMessage = false;

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  get wasInstallationWizardCompleted() {
    return this.project.meta.wasInstallationWizardCompleted ?? false;
  }

  get disableStartButton() {
    // If the user is a super admin, the button should always be enabled
    if (this.superAdmin) {
      return false;
    }
    return this.showAdditionalMessage || this.currentMember?.role !== UserRoleCode.admin;
  }

  created() {
    if (this.measurements['prgPilot.bEnable'] && this.measurements['prgPilot.bEnable'] === 1) {
      this.showAdditionalMessage = true;
    }
    if (this.project.meta.overallProductionMeasurement) {
      this.setOverallProductionMeasurement(this.project.meta.overallProductionMeasurement);
    }

    if (this.project.meta) {
      if (plcVersionDate(this.project).getTime() > hybridVersionDate.getTime() && this.project.meta.hasLynusInverter) {
        this.setHasLynusInverter(this.project.meta.hasLynusInverter);
      }
    }
  }

  handleStart() {
    this.handleIncrement();
  }
}
