
import { Vue, Component, Prop } from 'vue-property-decorator';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import ArrowDirectionLeftBase from '@/ui/components/devices/devices/base/ArrowDirectionLeftBase.vue';
import ArrowDirectionRightBase from '@/ui/components/devices/devices/base/ArrowDirectionRightBase.vue';
import SliderBase from '@/ui/components/devices/devices/base/SliderBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent PumpWithVFD device
 */
@Component({
  components: {
    PushButtonBase,
    OutputFieldBase,
    ArrowDirectionLeftBase,
    ArrowDirectionRightBase,
    SliderBase,
    DeviceLayout,
    LabelUnitWrapper,
  },
})
export default class PumpWithVFD extends Vue {
  @Prop() deviceData!: IDevice;
  @State('measurements') measurementsState!: IVariablesState;

  get langPath() {
    return `devices.${this.deviceData.data.type}`;
  }

  get measurements() {
    return this.measurementsState.measurements;
  }
  get sliderActualValue() {
    return this.measurements.get(this.deviceData.data.mappings.OutputField_actualValue);
  }
}
