
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent Preview Image
 */
@Component
export default class PreviewImage extends Vue {
  @Prop({ default: '' }) src!: string;
  @Prop({ default: null }) maxWidth!: any;
  @Prop({ default: null }) maxHeight!: any;
}
