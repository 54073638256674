import { Vue, Component } from 'vue-property-decorator';

@Component
export class ReportFormRules extends Vue {
  nameRules = [
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.nameRule1'),
    (v: any) => v.length <= 100 || this.$t('modals.manageReport.errorMessages.nameRule2'),
  ]
  titleRules = [
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.titleRule1'),
    (v: any) => v.length <= 30 || this.$t('modals.manageReport.errorMessages.titleRule2'),
  ]
  streetRule = [
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.streetRule'),
  ]
  cityRule = [
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.cityRule'),
  ]
  countryRule = [
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.countryRule'),
  ]
  variableNameRule = [
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.variableNameRule'),
  ]
  unitCostRule = [
    (v: any) => /^\d+(\.\d+)*$/.test(v) || this.$t('modals.manageReport.errorMessages.unitCostRule'),
  ]
  unitRule = [
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.unitRule1'),
    (v: any) => v.length <= 3 || this.$t('modals.manageReport.errorMessages.unitRule2'),
  ]
  timeSettingsRule = [
    (v: any) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'only format HH:MM is allowed',
  ]
  meterIDRule =[
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.meterIDRule'),
  ]
  gridRule =[
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.gridRule'),
  ]
  usernameRules = [
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.usernameRule'),
  ]
}
