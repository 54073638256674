
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import ManageMLModel from '@/ui/components/modals/ManageMLModel/index.vue';
import { Action, Getter, State } from 'vuex-class';
import { IDevicesState } from '@/store/modules/devices/types';
import { calculatePageCount } from '@/utils/utilsFunctions';
import { IRoom } from '@/types/rooms.types';
import { IMLModel } from '@/types/mpc/mpc.types';

/**
 * Component that shows table of ML Model devices
 */
@Component({
  components: {
    ManageMLModel,
    DeleteModalForm,
  },
})
export default class MPCTable extends Vue {
  @Prop({ default: 100 }) itemsPerPage !: number;
  @State('devices') devicesState!: IDevicesState;
  @Getter('rooms/rooms') rooms!: IRoom[];
  @Getter('mpc/mpcControllers') mpcControllersList!: IMLModel[];
  @Getter('mpc/mlModelDevices') mlModelDevices!: IMLModel[];
  @Action('mpc/updateMPC') updateMPC!: (mpc: IMLModel) => Promise<void>;
  @Action('mpc/deleteMPC') deleteMPC!: (mpc: IMLModel) => Promise<void>;

  isTooltip = true
  loaders: Record<string, boolean> = {}
  currentPage = 1;

  get pageNumber() {
    return calculatePageCount(this.filteredBySearch.length, this.itemsPerPage);
  }

  /**
   * Triggers a message when attempting to leave the page during device removal
   * @param val object, list of loaders
   */
  @Watch('loaders')
  onLoaders(val: any) {
    const count: number = Object.values(val).length;
    if (count) {
      this.addBlockedAlert();
    } else {
      this.removeBlockedAlert();
    }
  }

  blockedMessage(event: BeforeUnloadEvent) {
    event.returnValue = 'Are you sure you want to leave?';
  }
  addBlockedAlert() {
    window.addEventListener('beforeunload', this.blockedMessage);
  }
  removeBlockedAlert() {
    window.removeEventListener('beforeunload', this.blockedMessage);
  }

  get headers() {
    return [
      { text: this.$t('uiComponents.workbenchTable.name'), align: 'start', value: 'name' },
      { text: this.$t('uiComponents.workbenchTable.type'), value: 'data.type' },
      { text: this.$t('uiComponents.workbenchTable.area'), value: '' },
      { text: this.$t('uiComponents.workbenchTable.actions'), value: 'actions', sortable: false },
    ];
  }

  get devicesFilter() {
    return this.devicesState.devicesFilter;
  }

  /**
   * Filters the existing list of ML Model devices by type, name, area affiliation
   * @return filtered ML Model devices
   */
  get filteredBySearch() {
    return this.devicesFilter ? this.mlModelDevices
      .filter((device: IMLModel) => `${device.name} ${this.deviceRoomName(device.collection_id)} ${device.data.type}`
        .toLowerCase().includes(this.devicesFilter.toLowerCase())) :
      this.mlModelDevices;
  }

  /**
   * Defines the name of the room to which the device belongs
   * @param roomId id of the room
   * @return room name if room has named or empty string
   */
  deviceRoomName(roomId: string) {
    if (this.rooms.length) {
      const room: any = this.rooms.find((room: IRoom) => room.id === roomId);
      return room?.name || null;
    }
    return '';
  }

  editControl(payload: IMLModel) {
    this.updateMPC(payload);
  }
  async deleteControlHandle(control: IMLModel) {
    this.$set(this.loaders, control.id, true);
    await this.deleteMPC(control);
    this.$delete(this.loaders, control.id);
  }
}
