import { cloneDeep } from 'lodash';
import { RootState } from '@/store/types';
import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import i18n from '@/ui/plugins/i18n';
import ReportService from '@/services/ReportService';
import { IReport, IPrintReportPayload, ITriggerReportActionsPayload } from '@/types/report.types';
import { IReportState } from './types';

const state: IReportState = {
  reportList: [],
  reportFilter: '',
};

const getters: GetterTree<IReportState, RootState> = {
  getReportFilter(state: IReportState) {
    return state.reportFilter;
  },
};

const mutations: MutationTree<IReportState> = {
  setReports(state: IReportState, reports: IReport[]) {
    state.reportList = reports;
  },
  setReport(state: IReportState, report: IReport) {
    state.reportList.push(report);
  },
  updateReport(state: IReportState, report: IReport) {
    state.reportList = state.reportList.map((localReport: IReport) => {
      if (localReport.id === report.id) return report;
      else return cloneDeep(localReport);
    });
  },
  deleteReportFromList(state: IReportState, reportId: string) {
    state.reportList = state.reportList.filter((el: IReport) => el.id !== reportId);
  },
  setReportFilter(state: IReportState, name: string) {
    state.reportFilter = name;
  },
};

const actions: ActionTree<IReportState, RootState> = {
  /**
   * load projects reports
   * @param commit
   * @param project_id
   */
  async loadReports({ commit }, project_id: string) {
    try {
      const result = await ReportService.fetchReports(project_id);
      commit('setReports', result);
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Creates new report
   * @param commit
   * @param id current project id
   * @param reportModel report data
   */
  async addReport({ commit }, { id, reportModel }: { id: string; reportModel: IReport }) {
    try {
      const res = await ReportService.createReport(id, reportModel);
      commit('setReport', res);
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.reportWithNameCreated', { name: res.name }),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Updates selected report
   * @param commit
   * @param id current project id
   * @param report_id report id
   * @param reportModel report data
   */
  async modifyReport({ commit }, { id, report_id, reportModel }: { id: string; report_id: string; reportModel: IReport }) {
    try {
      const response = await ReportService.updateReport(id, report_id, reportModel);
      commit('updateReport', response);
      commit('app/setReport', {
        type: 'success',
        message: 'report updated',
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Delete selected project
   * @param commit
   * @param state
   * @param project_id
   * @param report_id
   */
  async deleteReport({ commit, state }, { project_id, report_id }: { project_id: string; report_id: string }) {
    try {
      await ReportService.deleteReport(project_id, report_id);
      commit('deleteReportFromList', report_id);
      commit('app/setReport', {
        type: 'success',
        message: 'report deleted',
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Print selected project to pdf or json
   * @param commit
   * @param state
   * @param payLoad
   */
  async printReport({ commit, state }, payLoad: IPrintReportPayload) {
    try {
      const responseType = payLoad.format === 'pdf' ? 'arraybuffer' : undefined;
      const res = await ReportService.printReport(
        payLoad.project_id,
        payLoad.report_id,
        payLoad.format,
        payLoad.start,
        payLoad.end,
        responseType,
      );
      if (['json', 'csv', 'pdf', 'xml'].includes(payLoad.format)) return res;
      else return null;
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Trigger report action
   * @param commit
   * @param state
   * @param payLoad
   */
  async triggerReportActions({ commit, state }, payLoad: ITriggerReportActionsPayload) {
    try {
      await ReportService.triggerReportActions(
        payLoad.id,
        payLoad.report_id,
        payLoad.format,
        payLoad.start,
        payLoad.end,
        payLoad.reportModel,
      );
      commit('app/setReport', {
        type: 'success',
        message: 'Actions Triggered',
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
};

export const report: Module<IReportState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
