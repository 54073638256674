
import { Vue, Component, Prop } from 'vue-property-decorator';
import AnimatedLineArrow
  from '@/ui/components/devices/components/EnergyParts/AnimatedLine/AnimatedLineArrow.vue';

/**
 * Component that represent Animated line.
 * Used in EMS, EnergyView device.
 */
@Component({
  components: {
    AnimatedLineArrow,
  },
})
export default class AnimatedLine extends Vue {
  @Prop({ default: null }) lineData: any;
  @Prop({ default: null }) width!: number;
  @Prop({ default: null }) height!: number;
  @Prop({ default: null }) center!: any;
  @Prop({ default: false }) isMovementReverse!: boolean;
  @Prop({ default: false }) isEnergyFlow!: boolean;
  @Prop({ default: true }) isLineActive!: boolean;
  @Prop({ default: false }) isReverseMovementDefault!: boolean;
  @Prop({ default: '#2BAAE2' }) color1!: string;
  @Prop({ default: '#525252' }) color2!: string;
  @Prop({ default: '#525252' }) color2Reverse!: string;

  ctx: any = null

  /**
   * Set line color
   * @param {object} context canvas context object
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} x2 end x position
   * @param {number} y2 end y position
   * @param {string} color1 start gradient color
   * @param {string} color2 end gradient color
   */
  lineColor(context: any, x1: number, y1: number, x2: number, y2: number, color1: string, color2: string) {
    const grad: any = context.createLinearGradient(x1, y1, x2, y2);
    grad.addColorStop(0, color1);
    grad.addColorStop(1, color2);
    context.strokeStyle = grad;
    context.lineWidth = 2;
  }

  /**
   * Draw line body
   * @param {object} context canvas context object
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} x2 end x position
   * @param {number} y2 end y position
   */
  lineBody(context: any, x1: number, y1: number, x2: number, y2: number) {
    context.beginPath();
    context.moveTo(x1, y1);
    context.lineTo(x2, y2);
    context.stroke();
    context.closePath();
  }

  /**
   * Draw line
   * @param {object} context canvas context object
   * @param {number} x1 start x position
   * @param {number} y1 start y position
   * @param {number} x2 end x position
   * @param {number} y2 end y position
   * @param {string} color1 start gradient color
   * @param {string} color2 end gradient color
   */
  drawLine(context: any, x1: number, y1: number, x2: number, y2: number, color1: string, color2: string) {
    this.lineColor(context, x1, y1, x2, y2, color1, color2);
    this.lineBody(context, x1, y1, x2, y2);
  }

  changeDirection() {
    this.$emit('changeDirection');
  }

  async mounted() {
    // gets line canvas context object
    const canvas: any = this.$refs.line;
    this.ctx = canvas.getContext('2d');

    if (this.isLineActive) {
      this.drawLine(
        this.ctx,
        this.center.x,
        this.center.y,
        this.lineData.x,
        this.lineData.y,
        '#2BAAE2',
        this.isMovementReverse ? this.color2Reverse : this.color2,
      );
    } else {
      this.drawLine(
        this.ctx,
        this.center.x,
        this.center.y,
        this.lineData.x,
        this.lineData.y,
        '#525252',
        '#525252',
      );
    }
  }
}
