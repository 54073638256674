
import { Vue, Component } from 'vue-property-decorator';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import SliderMock from '@/ui/components/devices/previews/components/SliderMock.vue';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    InputFieldMock,
    SliderMock,
    SwitchMock,
    DeviceLayout,
  },
})
export default class ElectronicBoilerPreview extends Vue {
  get settingsViewLang() {
    return 'devices.ElectronicBoiler.settingsView';
  }
  get previewNameLang() {
    return this.$t('devices.ElectronicBoiler.previewName');
  }
}
