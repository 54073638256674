
import { Component, Prop } from 'vue-property-decorator';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent ColdWaterMeter device
 */
@Component({
  components: {
    OutputFieldBase,
    DeviceLayout,
    LabelUnitWrapper,
  },
})
export default class ColdWaterMeter extends BasicControl {
  @Prop() deviceData!: IDevice;

  get isNotMapped(): boolean {
    return false;
  }
}
