
import { Component, Prop } from 'vue-property-decorator';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import Switch2VBase from '@/ui/components/devices/devices/base/Switch2VBase.vue';
import PreviewMQTTControls
  from '@/ui/components/devices/components/EnergyParts/PreviewMQTTControls.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { minMaxValidation } from '@/utils/utilsFunctions';

/**
 * Generator single system settings.
 */
@Component({
  methods: { minMaxValidation },
  components: {
    PushButtonBase,
    ShowEventDotBase,
    InputFieldBase,
    OutputFieldBase,
    Switch2VBase,
    PreviewMQTTControls,
    LabelUnitWrapper,
  },
})
export default class GeneratorSystemSettings extends mixins(Validation) {
  @Prop({ default: null }) instanceData!: any;

  get outputFieldActualPower() {
    return {
      'OutputField_actualValue': this.instanceData.power,
    };
  }
  get switch2VEnable() {
    return {
      'Switch2V_onOff': this.instanceData.switch_enable,
      'Switch2V_state': this.instanceData.state_enable,
    };
  }
  get switch2VTimelock() {
    return {
      'Switch2V_onOff': this.instanceData.enable_timelock,
      'Switch2V_state': this.instanceData.state_timelock,
    };
  }
  get inputFieldHourOn() {
    return {
      'InputField_targetValue': this.instanceData.hour_on,
    };
  }
  get inputFieldMinOn() {
    return {
      'InputField_targetValue': this.instanceData.minute_on,
    };
  }
  get inputFieldHourOff() {
    return {
      'InputField_targetValue': this.instanceData.hour_off,
    };
  }
  get inputFieldMinOff() {
    return {
      'InputField_targetValue': this.instanceData.minute_off,
    };
  }
  get pushButtonReset() {
    return {
      'PushButton_onOff': this.instanceData.switch_reset,
      'PushButton_state': this.instanceData.state_reset,
    };
  }
  get showEventDotMappings() {
    return {
      'ShowEventDot_errorWarningState': this.instanceData.state_generator,
    };
  }
  get inputFieldEnableSOCMappings() {
    return {
      'InputField_targetValue': this.instanceData.enable_soc,
    };
  }
  get inputFieldDisableSOCMappings() {
    return {
      'InputField1_targetValue': this.instanceData.disable_soc,
    };
  }
}
