
import { Vue, Component, Prop } from 'vue-property-decorator';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import Navigation from '@/ui/components/wizards/baseComponents/Navigation.vue';
import { State } from 'vuex-class';
import { IMQTTState } from '@/store/modules/mqtt/types';
import store from '@/store';
import { wizardStores } from '@/ui/components/wizards';
import { IWizardLoadingState, NavigationButton } from '@/types/wizards/wizard.general.types';
import ProgressBar from '@/ui/components/components/ProgressBar.vue';

@Component({
  components: {
    ProgressBar,
    CircleSpinner,
    Navigation,
  },
})
export default class WizardContentView extends Vue {
  @Prop({ default: 'InstallationWizard' }) wizardName!: string;
  @Prop({ default: true }) valid!: boolean;
  @Prop() handleNext?: () => void;
  @Prop() handleBack?: () => void;
  @State('mqttClient') mqttState!: IMQTTState;

  get projectOnline() {
    return this.mqttState.online;
  }

  get storeName() {
    return wizardStores[this.wizardName];
  }

  get loadingState(): IWizardLoadingState {
    return store.getters['installationWizard/loadingState'];
  }

  get customTimerLoadingState(): IWizardLoadingState {
    return store.getters['installationWizard/customTimerLoadingState'];
  }

  get considerProjectStatus() {
    return store.getters[`${this.storeName}/considerProjectStatus`];
  }

  get notValid() {
    return !this.valid || this.loadingState.isLoading || (this.considerProjectStatus && !this.projectOnline);
  }

  get buttons(): NavigationButton[] {
    return [
      { id: 'back', title: this.$t('uiComponents.buttons.back') as string, onClick: this.handleBack, disabled: this.loadingState.isLoading },
      { id: 'next', title: this.$t('uiComponents.buttons.next') as string, onClick: this.handleNext, disabled: this.notValid },
    ];
  }

  get definedButtons() {
    return this.buttons.filter((button: NavigationButton) => button.onClick);
  }
}
