
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IVariable } from '@/types/variables.types';
import VueI18n from 'vue-i18n';

/**
 * Combobox field component which used in manage modal forms
 */
@Component
export default class ComboboxField extends Vue {
  @Prop() value!: string | number;
  @Prop({ default: null }) items!: IVariable[] | null;
  @Prop({ default: null }) label!: null | string | VueI18n.TranslateResult;
  @Prop({ default: true }) optional!: boolean;
  @Prop() disabled!: boolean;
  @Prop({ default: false }) dense!: boolean;

  get customRules() {
    if (!this.optional) return (value: number) => (value === 0 || !!value) || this.$t('validationRules.required');
    else return true;
  }

  mounted() {
    (this.$refs.customField as any).validate(true);
  }
}
