import { render, staticRenderFns } from "./InputFieldBase.vue?vue&type=template&id=4bf2401e&"
import script from "./InputFieldBase.vue?vue&type=script&lang=ts&"
export * from "./InputFieldBase.vue?vue&type=script&lang=ts&"
import style0 from "./InputFieldBase.vue?vue&type=style&index=0&id=4bf2401e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports