import installationWizardImports from '@/ui/components/wizards/installationWizard/imports';
import tenantWizardImports from '@/ui/components/wizards/tenantWizard/imports';
import loggerWizardImports from '@/ui/components/wizards/loggerWizard/imports';

const imports = {
  ...installationWizardImports,
  ...tenantWizardImports,
  ...loggerWizardImports,
};

export default imports;
