
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import EnergyVisualisationPreview
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyVisualisationPreview.vue';
import {
  electricHeatingTypes,
  heatingElementModelTypes,
} from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { itemsContainValue } from '@/utils/utilsFunctions';
import { IDevice } from '@/types/devices.types';
import { IIncludedSystemsTypes } from '@/types/wizards/installationWizard.types';
import { newChargeStationLimitDate, plcVersionDate } from '@/utils/versionManagementUtils';
import { IProject } from '@/types/project.types';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { SYSTEM_IP_RANGE_START } from '../../../wizardSettings/wizardConstants';

@Component({
  computed: {
    SYSTEM_IP_RANGE_START() {
      return SYSTEM_IP_RANGE_START;
    },
  },
  methods: {
    heatingElementModelTypes() {
      return heatingElementModelTypes;
    },
    itemsContainValue },
  components: {
    InfoTooltip,
    EnergyVisualisationPreview,
    WizardContentView,
  },
})
export default class ElectricHeatingSetting extends WizardComponent {
  @Prop({ default: {} }) includedSystemsTypes!: IIncludedSystemsTypes;
  @Getter('projects/project') project!: IProject;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('installationWizard/emsDevice') emsDevice!: IDevice;
  @Mutation('installationWizard/handleIncludedSystemsTypesSystemDefinitionProps') handleIncludedSystemsTypesSystemDefinitionProps!: (
    payload: { systemName: string; systemIndex: number; prop: string; value: any },
  ) => void;

  rerenderKey = 0;
  valid = false;
  doesHover = false;

  @Watch('includedSystemsTypes.electric_heating.definition', { deep: true })
  updateValidation() {
    this.$nextTick(() => {
      this.validate();
      this.rerenderKey++;
    });
  }

  get isNewChargeStationPlcVersion() {
    return (plcVersionDate(this.project).getTime() > newChargeStationLimitDate.getTime()) && (this.isDeye || this.isSolarmax);
  }

  // returns all system types that can be selected for the current project
  get electricHeatingTypesBeforeProjectCreation() {
    return electricHeatingTypes.filter((systemTypeElement) => systemTypeElement.availableFrom.getTime() < plcVersionDate(this.project).getTime());
  }

  // additional field should only be shown on deye systems after a certain plc date
  get showEHETypeFeature() {
    return (this.isDeye || this.isSolarmax) && (plcVersionDate(this.project).getTime() > newChargeStationLimitDate.getTime());
  }

  // updates IncludedSystemsDefinitions in Store
  async handleChange(system_index: number | string, prop: string, value: any) {
    const index = typeof system_index === 'string' ? parseInt(system_index, 10) : system_index;
    this.handleIncludedSystemsTypesSystemDefinitionProps({ systemName: 'electric_heating', systemIndex: index, prop, value });
    this.rerenderKey++;
    this.validate();
  }

  validate() {
    (this.$refs.form as any).validate();
    this.$emit('validationStatus', { system: 'electric_heating', status: this.valid });
  }

  mounted() {
    setTimeout(() => {
      this.validate();
    }, 10);
  }
}
