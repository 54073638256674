
import { Vue, Component, Prop } from 'vue-property-decorator';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';
import SetpointActualView from '@/ui/components/devices/mpc/SetpointOptimizer/system/components/SetpointActualView.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import SetpointOptimizerCharts
  from '@/ui/components/devices/mpc/SetpointOptimizer/charts/index.vue';
import { Action, State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';

/**
 * Component that represent SetpointOptimizer MPC device
 */
@Component({
  components: {
    MPCLayout,
    ShowEventDotBase,
    SetpointOptimizerCharts,
    SetpointActualView,
  },
})
export default class SetpointOptimizer extends Vue {
  @Prop() deviceData!: any;
  @Prop() dndGridLayoutContainerWidth!: number;
  @State('measurements') measurementsState!: IVariablesState;
  @Action('mpc/fetchMPCData') fetchMPCData!: (mpcId: string) => any;

  mpc: any = null

  get predictedSetpoint() {
    if (!this.mpc?.data) return null;
    return this.mpc.data.meta.charts.predictedSetpoint;
  }

  get showEventDotServiceReady() {
    return {
      'ShowEventDot_errorWarningState': this.mpc?.data?.meta?.controllerMappings?.mpcReady,
    };
  }
  get showEventGeneralErrorWarning() {
    return {
      'ShowEvent_errorWarningState': this.mpc?.data?.meta?.controllerMappings?.errorWarning,
    };
  }

  async rerenderDevice() {
    this.mpc = null;
    this.mpc = await this.fetchMPCData(this.deviceData.id);
  }

  async created() {
    this.mpc = await this.fetchMPCData(this.deviceData.id);
  }
}
