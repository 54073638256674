
import { Component, Inject, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import PaginationTabs from '@/ui/components/components/PaginationTabs.vue';
import { CirclePosition, EnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import { EnergyLineDisplayData } from '@/types/energyVisualisation/EnergyLineDisplayData';
import ElectricHeatingTemperatureChart from '@/ui/components/devices/components/EnergyParts/EMS/components/charts/ElectricHeatingTemperatureChart.vue';
import { EnergyCircleType } from '@/types/energyVisualisation/EnergyCircleType';
import { IDevice } from '@/types/devices.types';
import EnergyVisualisation from './index.vue';
import SystemDialog from './SystemDialog.vue';

/**
 * Actual view for EnergyView/EMS
 */
@Component({
  components: {
    PaginationTabs,
    EnergyVisualisation,
    SystemDialog,
    ElectricHeatingTemperatureChart,
  },
})
export default class SystemDialogView extends mixins(EnergyComponent) {
  @Prop() circlesDisplayData!: EnergyCircleDisplayData[];
  @Prop() linesDisplayData!: Partial<EnergyLineDisplayData>[];
  @Prop() openedSystem!: EnergyCircleType;
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: 'ActualView' }) view!: 'ActualView' | 'EnergyFlowView';

  @Provide('onCircleClick') onCircleClick = this.onSingleSystemClickCallback;
  @Inject({ from: 'onSingleCircleClick', default: () => {} }) onSingleCircleClick?: (
    circleData: EnergyCircleDisplayData,
  ) => void;

  @Provide('onLineAnimationEnd') onLineAnimationEnd = this.onLineAnimationEndCallback;
  @Inject({ from: 'onDialogLineAnimationEnd', default: () => {} }) onDialogLineAnimationEnd!: (id: string) => void;

  readonly elementsPerPage = 10;
  startIndex = 0;

  onLineAnimationEndCallback(id: string) {
    this.onDialogLineAnimationEnd?.(id);
  }

  onSingleSystemClickCallback(circleData: EnergyCircleDisplayData) {
    this.onSingleCircleClick?.(circleData);
  }

  /**
   * Object that contains chart information for each system
   * If yes, what component should be shown,
   * and in what device types it should be shown
   */
  get chartInformations() {
    return {
      pv: { show: false },
      generator: { show: false },
      chp: { show: false },
      battery: { show: false },
      grid: { show: false },
      house: { show: false },
      charge_station: { show: false },
      heating_pump: { show: false },
      big_consumer: { show: false },
      electric_heating: {
        show: true,
        component: ElectricHeatingTemperatureChart,
        visibleDeviceTypes: ['EMS', 'EMSV2'],
      },
    };
  }
  get showChart() {
    // fix for tests
    if ((this.chartInformations as any)[this.openedSystem] === undefined) {
      return false;
    }
    return (this.chartInformations as any)[this.openedSystem].show &&
      (this.chartInformations as any)[this.openedSystem].visibleDeviceTypes.includes(this.deviceData.data.type);
  }
  get circleKeys() {
    return this.circlesDisplayData.map((el: EnergyCircleDisplayData) => el.id);
  }
  get circlesDisplayDataMap() {
    return new Map(this.circlesDisplayData.map((el: EnergyCircleDisplayData) => [el.id, el]));
  }
  get linesDisplayDataMap() {
    return new Map(this.linesDisplayData.map((el: Partial<EnergyLineDisplayData>) => [el.id, el]));
  }
  get circleKeysSorted() {
    const sortedArray = this.circleKeys
      .sort((a, b) => {
        // extract numbers from system keys
        const aNum = Number(a.match(/\d+/)?.[0]);
        const bNum = Number(b.match(/\d+/)?.[0]);
        return aNum - bNum;
      });
    return sortedArray.slice(this.startIndex, this.startIndex + this.elementsPerPage);
  }
  get sortedCirclesDisplayData() {
    return this.circleKeysSorted.map((key: string, index: number) => {
      const circleData = this.circlesDisplayDataMap.get(key);
      return {
        ...circleData,
        position: index < 5 ? CirclePosition.top : CirclePosition.bottom,
      } as EnergyCircleDisplayData;
    });
  }
  get sortedLinesDisplayData() {
    return this.circleKeysSorted.map((key: string) => {
      const lineData = this.linesDisplayDataMap.get(key);
      return { ...lineData } as EnergyLineDisplayData;
    });
  }

  updatePage(index: number) {
    this.startIndex = index;
  }

  async mounted() {
    await this.$nextTick();
    (this.$refs.EnergyVisualisation as any).updateCircles();
  }
}
