
import { Vue, Component } from 'vue-property-decorator';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    SwitchMock,
    DeviceLayout,
  },
})
export default class VentilatorSwitchPreview extends Vue {
  get previewNameLang() {
    return this.$t('devices.VentilatorSwitch.previewName');
  }
}
