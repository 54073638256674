
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class IconLights extends Vue {
  @Prop() icon: any;
  activeColor = '';
  timer: any = null;
  destroyed(): void {
    clearInterval(this.timer);
  }

  mounted() {
    this.timer = setInterval(() => {
      this.activeColor = this.activeColor ? '' : 'red';
    }, 1000);
  }
}
