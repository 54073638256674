
import { IWizardPage } from '@/types/wizards/wizard.general.types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UserInformation extends Vue {
  @Prop() name!: string;
  @Prop({ default: 1 }) activeStep!: number;
  @Prop({ default: 0 }) activePage!: number;
  @Prop() wizardPages!: IWizardPage[];

  get currentStep() {
    return this.wizardPages[this.activePage].page;
  }
  getTranslation(key: string) {
    const translation = this.$t(key);
    const firstStepDescription = this.$t(`${this.name}.steps.${this.activeStep}.pages.0.description`);
    return translation === key ? firstStepDescription : translation;
  }
}
