
import { Vue, Component } from 'vue-property-decorator';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { IProject } from '@/types/project.types';
import { Getter, Action, State } from 'vuex-class';
import { cloneDeep } from 'lodash';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';
import { WizardPath } from '@/types/wizards/installationWizard.types';

@Component({
  components: {
    WizardContentView,
  },
})
export default class FinalPageInstallationWizard extends Vue {
  @State('installationWizard') wizardState!: IInstallationWizardState;
  @Getter('projects/project') project!: IProject;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;
  localProject: any = null;
  async handleNext() {
    this.localProject = cloneDeep(this.project);
    await this.updateProject({
      ...this.localProject,
      meta: {
        ...this.localProject.meta,
        wizard_completed_at: new Date().toISOString(),
        wasInstallationWizardCompleted: true,
      },
    });

    const pathsThatRequireTenant = [WizardPath.INSTALLATION_TENANT, WizardPath.TENANT_STRING_INVERTER, WizardPath.TENANT_INSTALLATION_NO_BATTERY, WizardPath.TENANT];
    if (pathsThatRequireTenant.includes(this.wizardState.wizardPath)) {
      this.$router.push(`/projects/${this.$route.params.id}/tenant-wizard`);
    } else {
      this.$router.push(`/projects/${this.$route.params.id}/favorites`);
    }
  }

  get text() {
    const pathsThatRequireTenant = [WizardPath.INSTALLATION_TENANT, WizardPath.TENANT_STRING_INVERTER, WizardPath.TENANT_INSTALLATION_NO_BATTERY, WizardPath.TENANT];
    if (!pathsThatRequireTenant.includes(this.wizardState.wizardPath)) {
      return this.$t('installationWizard.finalPage.text');
    } else {
      return this.$t('installationWizard.finalPage.textContinue');
    }
  }

  get buttonText() {
    const pathsThatRequireTenant = [WizardPath.INSTALLATION_TENANT, WizardPath.TENANT_STRING_INVERTER, WizardPath.TENANT_INSTALLATION_NO_BATTERY, WizardPath.TENANT];
    if (!pathsThatRequireTenant.includes(this.wizardState.wizardPath)) {
      return this.$t('installationWizard.finalPage.finish');
    } else {
      return this.$t('installationWizard.finalPage.finishAndContinue');
    }
  }
}
