
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action, State, Mutation } from 'vuex-class';
import { cloneDeep } from 'lodash';
import WebhookAction from '@/ui/components/modals/actions/WebhookAction.vue';
import AlertAction from '@/ui/components/modals/actions/AlertAction.vue';
import EmailAction from '@/ui/components/modals/actions/EmailAction.vue';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { getSingleRuleObject } from '@/utils/utilsFunctions';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IRule } from '@/types/rules.types';
import { IReportBox } from '@/types/app.types';

enum timePeriods {
  sec = 'sec',
  min = 'min',
  hour = 'hour',
  day = 'day',
}

@Component({
  components: {
    email: EmailAction,
    webhook: WebhookAction,
    alert: AlertAction,
    DeleteModalForm,
  },
})
export default class ManageRule extends mixins(Validation) {
  @Prop() rule!: any;

  @State('variables') variablesState!: IVariablesState;
  @Getter('variables/variablesForComboBox') variablesForComboBox!: [];
  @Mutation('app/setReport') setReport!: (payload: IReportBox) => void;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;
  @Action('rules/addRule') addRule!: (
    { id, ruleModel }: { id: string; ruleModel: Partial<IRule> }
  ) => Promise<any>;
  @Action('rules/modifyRule') modifyRule!: (
    payload: { id: string; rule_id: string; ruleModel: IRule }
  ) => Promise<void>;
  @Action('rules/deleteRule') deleteRule!: any;

  valid = true;
  localRule = getSingleRuleObject('', '', '', 1);

  nameRule = [
    (v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.nameRule1'),
    (v: any) => v.length <= 100 || this.$t('modals.manageReport.errorMessages.nameRule2'),
  ]
  onlyNumbersRule = [
    (v: any) => /^[+-]?\d+(\.\d+)?$/.test(v) || this.$t('modals.manageReport.errorMessages.onlyNumbersRule'),
  ];
  variableRule = [(v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.variableNameRule')];
  conditionRule = [(v: any) => v.length !== 0 || this.$t('modals.manageReport.errorMessages.conditionRequiredRule')];

  // time options
  selectedTimeOption = timePeriods.sec;
  prevTimeOption = timePeriods.sec;
  isTimeoutRule = false;
  get minValueTimeout() {
    return this.selectedTimeOption === timePeriods.sec ? 10 : 1;
  }
  maxValueTimeout = 604800;
  secondsTimeoutRule = [
    (v: any) => /^[0-9]*$/.test(v) || this.$t('modals.manageReport.errorMessages.onlyNumbersRule'),
    (v: any) => v >= 10 || this.$t('modals.manageReport.errorMessages.secondsTimeoutRule'),
  ];
  otherTimeoutRule = [
    (v: any) => /^[0-9]*$/.test(v) || this.$t('modals.manageReport.errorMessages.onlyNumbersRule'),
    (v: any) => v >= 1 || this.$t('modals.manageReport.errorMessages.otherTimeoutRule'),
  ];
  @Watch('isTimeoutRule')
  handleIsTimeoutRuleChange() {
    if (!this.isTimeoutRule) {
      this.localRule.timeout = 0;
      this.localRule.active = false;
    } else if (this.localRule.timeout === 0) {
      this.localRule.timeout = 240;
    }
  }

  onTimeOptionsChange(period: timePeriods) {
    this.selectedTimeOption = period;
    switch (period) {
      case timePeriods.sec:
        if (this.prevTimeOption === timePeriods.min) {
          this.localRule.timeout *= 60;
        } else if (this.prevTimeOption === timePeriods.hour) {
          this.localRule.timeout *= 3600;
        } else if (this.prevTimeOption === timePeriods.day) {
          this.localRule.timeout *= 86400;
        }
        this.maxValueTimeout = 604800;
        break;
      case timePeriods.min:
        if (this.prevTimeOption === timePeriods.sec) {
          this.localRule.timeout /= 60;
        } else if (this.prevTimeOption === timePeriods.hour) {
          this.localRule.timeout *= 60;
        } else if (this.prevTimeOption === timePeriods.day) {
          this.localRule.timeout *= 1440;
        }
        this.maxValueTimeout = 10080;
        break;
      case timePeriods.hour:
        if (this.prevTimeOption === timePeriods.sec) {
          this.localRule.timeout /= 3600;
        } else if (this.prevTimeOption === timePeriods.min) {
          this.localRule.timeout /= 60;
        } else if (this.prevTimeOption === timePeriods.day) {
          this.localRule.timeout *= 24;
        }
        this.maxValueTimeout = 168;
        break;
      case timePeriods.day:
        if (this.prevTimeOption === timePeriods.sec) {
          this.localRule.timeout /= 86400;
        } else if (this.prevTimeOption === timePeriods.min) {
          this.localRule.timeout /= 1440;
        } else if (this.prevTimeOption === timePeriods.hour) {
          this.localRule.timeout /= 24;
        }
        this.maxValueTimeout = 7;
        break;
    }

    // rounding and replacing Value if timeout would be 0.
    // Because this would be out of the allowed values
    this.localRule.timeout = Math.round(this.localRule.timeout);
    if (this.localRule.timeout === 0) {
      if (this.selectedTimeOption === timePeriods.sec) {
        this.localRule.timeout = 10;
      } else {
        this.localRule.timeout = 1;
      }
    }
    // previous timeOption is needed to reCalculate the Time correctly,
    // it is always changed after the recalculation took place
    this.prevTimeOption = this.selectedTimeOption;
  }
  initSelectedTimeOption() {
    if (this.rule?.timeout % 86400 === 0) {
      this.selectedTimeOption = timePeriods.day;
    } else if (this.rule?.timeout % 3600 === 0) {
      this.selectedTimeOption = timePeriods.hour;
    } else if (this.rule?.timeout % 60 === 0) {
      this.selectedTimeOption === timePeriods.min;
    }
  }

  get andOR() {
    return [
      { state: this.$t('modals.manageRules.conditionsForm.andOr.and'), abbr: true },
      { state: this.$t('modals.manageRules.conditionsForm.andOr.or'), abbr: false },
    ];
  }
  get conditions() {
    return [
      { title: this.$t('modals.manageRules.conditions.less'), value: 'less' },
      { title: this.$t('modals.manageRules.conditions.lessEquals'), value: 'less_equals' },
      { title: this.$t('modals.manageRules.conditions.equals'), value: 'equals' },
      { title: this.$t('modals.manageRules.conditions.notEquals'), value: 'not_equals' },
      { title: this.$t('modals.manageRules.conditions.greaterEquals'), value: 'greater_equals' },
      { title: this.$t('modals.manageRules.conditions.greater'), value: 'greater' },
    ];
  }
  get timeOptions() {
    return [
      { title: this.$t('modals.manageRules.timeOptions.sec'), value: timePeriods.sec },
      { title: this.$t('modals.manageRules.timeOptions.min'), value: timePeriods.min },
      { title: this.$t('modals.manageRules.timeOptions.hour'), value: timePeriods.hour },
      { title: this.$t('modals.manageRules.timeOptions.day'), value: timePeriods.day },
    ];
  }

  get isEditRule() {
    return !!this.rule;
  }

  // Condition
  async deleteCondition(inx: number) {
    this.localRule.conditions.splice(inx, 1);
    await this.validate();
  }
  async addCondition() {
    this.localRule.conditions.push({
      and_or: false,
      target: 0,
      variable: '',
      condition: 'equals',
    });
    await this.validate();
  }

  // Action
  get createActionButtonsList() {
    return [
      { title: this.$t('modals.manageRules.buttons.addEmailAction'), actionType: 'email' },
      { title: this.$t('modals.manageRules.buttons.addWebhookAction'), actionType: 'webhook' },
      { title: this.$t('modals.manageRules.buttons.addAlertAction'), actionType: 'alert' },
    ];
  }
  async addAction(type: string) {
    const action: any = { 'type': type, params: {} };

    switch (type) {
      case 'webhook':
        action.params = {
          headers: {
            'content-type': 'application/json',
          },
          method: 'POST',
          body: '{{rule}} - ',
          url: '',
        };
        break;
      case 'email':
        action.params = {
          recipients: ['example@mail.com'],
          subject: '',
          body: '{{rule}} - ',
        };
        break;
      case 'alert':
        action.params = {
          type: 0,
          body: '{{rule}} - ',
        };
        break;
    }

    this.localRule.actions.push(action);
    await this.validate();
  }
  async deleteAction(inx: number) {
    this.localRule.actions.splice(inx, 1);
    await this.validate();
  }

  async validate() {
    await this.$nextTick();
    (this.$refs.form as any).validate();
  }

  async handleRule() {
    const copy: any = cloneDeep(this.localRule);
    // converts the timeout (could be in min,hour,day)
    // into seconds for the api to store the value in seconds
    if (copy.timeout !== 0) {
      if (this.selectedTimeOption === timePeriods.min) {
        copy.timeout *= 60;
      } else if (this.selectedTimeOption === timePeriods.hour) {
        copy.timeout *= 3600;
      } else if (this.selectedTimeOption === timePeriods.day) {
        copy.timeout *= 86400;
      }
      copy.timeout = parseInt(copy.timeout, 10);
    }

    try {
      if (this.rule) {
        await this.modifyRule({ id: this.$route.params.id, ruleModel: copy, rule_id: this.rule.id });
      } else {
        await this.addRule({ id: this.$route.params.id, ruleModel: copy });
        this.$emit('closeRuleDialog');
        this.setReport({
          type: 'success',
          message: 'created',
          value: true,
        });
      }
    } catch (e) {
      this.setReport({
        type: 'error',
        message: e.message,
        value: true,
      });
    }
  }
  async deleteRuleLocal() {
    await this.deleteRule({ project_id: this.$route.params.id, rule_id: this.localRule.id });
    this.$emit('closePanel');
  }

  async created() {
    if (this.rule) { // when edit rule
      if (this.rule.timeout !== null && this.rule.timeout !== 0) {
        this.isTimeoutRule = true;
      }
      this.localRule = cloneDeep(this.rule);
      this.initSelectedTimeOption();
      this.onTimeOptionsChange(this.selectedTimeOption);
    } else { // when create rule
      this.isTimeoutRule = true;
      await this.fetchVariables(this.$route.params.id);
    }
  }
  async mounted() {
    await this.validate();
  }
}
