
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Scaling } from '@/types/common.types';

/**
 * Scaling field component which used in manage modal forms
 */
@Component
export default class ScalingField extends Vue {
  @Prop({ default: null }) scalingData!: Scaling;

  scalingType = 'manual'

  get scalingTypes() {
    return [
      { title: this.$t('uiComponents.scalingField.scalingMethods.manual'), id: 'manual' },
      { title: this.$t('uiComponents.scalingField.scalingMethods.auto'), id: 'auto' },
    ];
  }

  handleScalingType() {
    if (this.scalingType === 'auto') {
      this.scalingData.min = null;
      this.scalingData.max = null;
    }
  }

  mounted() {
    if (this.scalingData.min === null && this.scalingData.max === null) {
      this.scalingType = 'auto';
    }
  }
}
