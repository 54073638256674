
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { IAppState } from '@/store/modules/app/types';
import { envRealm, envKeycloakURL } from '@/utils/env';
import { IProject } from '@/types/project.types';

/**
 * Component that represent UserProfile
 * Shows list of user actions. Logout, User keycloak settings
 */
@Component
export default class UserProfile extends Vue {
  @State('app') appState!: IAppState;
  @Getter('projects/project') projectModel!: IProject;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;

  get items() {
    return [
      {
        title: this.$t('uiComponents.userProfile.edit'),
        bind: {
          href: `${envKeycloakURL}/realms/${envRealm}/account`,
          target: '_blank',
        },
        on: {},
      },
      {
        title: this.$t('uiComponents.userProfile.logout'),
        bind: {},
        on: {
          click: this.handleLogout,
        },
      },
    ];
  }

  /**
   * Logout from project
   */
  async handleLogout() {
    if (Object.keys(this.projectModel).length === 0) {
      Vue.prototype.$keycloak.logoutFn();
      return;
    }
    if (this.$route.name !== 'Home') {
      if (!this.projectModel?.meta) {
        Vue.prototype.$keycloak.logoutFn();
        return;
      }
      if (this.projectModel?.meta.isDNDActive && this.projectModel !== undefined) {
        await this.updateProject({
          ...this.projectModel,
          meta: {
            ...this.projectModel.meta,
            isDNDActive: false,
          },
        });
      }
    }
    Vue.prototype.$keycloak.logoutFn();
  }

  get hideNameWhenEqual() {
    return this.$vuetify.breakpoint.width < 1600;
  }

  get user() {
    return this.appState.user;
  }
}
