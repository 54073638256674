import api from '@/services/api';
import { IProject } from '@/types/project.types';

export default {
  async fetchProjects() {
    return api.fetch('/projects', 'GET');
  },

  async fetchProject(projectId: string) {
    return api.fetch(`/projects/${projectId}`, 'GET');
  },

  async createProject(project: IProject) {
    return api.fetch('/projects', 'POST', project);
  },

  async updateProject(project: IProject) {
    return api.fetch(`/projects/${project.id}`, 'PUT', project);
  },

  async deleteProject(projectId: string) {
    return api.fetch(`/projects/${projectId}`, 'DELETE');
  },

  async resetQR(projectId: string) {
    return api.fetch(`/projects/${projectId}/join/reset`, 'POST');
  },

  async fetchProjectDocuments(projectId: string) {
    return api.fetch(`/projects/${projectId}/documents`, 'GET');
  },

  async deleteProjectDocument(projectId: string, fileId: string) {
    return api.fetch(`/projects/${projectId}/documents/${fileId}`, 'DELETE');
  },

  async disableQR(projectId: string) {
    return api.fetch(`/projects/${projectId}/join/disable`, 'POST');
  },

};
