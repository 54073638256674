
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';

/**
 * Component that represent chart of actual data according to chosen period.
 */
@Component({
  components: {
    BaseChartWrapper,
  },
})
export default class MqttCharts extends Vue {
  @Prop() deviceId!: any;

  tab: any = null

  get deviceIdMod() {
    if (this.deviceId) return this.deviceId.replace(/-/g, '_');
    return null;
  }

  /**
   * Prepares chart options object suitable for the chart component
   */
  get tempValve() {
    return {
      name: '',
      data: {
        mappings: {
          value_1: `MPC.${this.deviceIdMod}.temp`,
          value_2: `MPC.${this.deviceIdMod}.valve`,
        },
        options: {
          value_1: {
            agg: 'last',
            name: this.$t('mlModel.HeatingCircuitOptimization.settingsView.chartLabels.chart.roomTemp'),
            scaling: {
              max: 40,
              min: 0,
            },
            type: 'line',
            unit: '°C',
          },
          value_2: {
            agg: 'last',
            name: this.$t('mlModel.HeatingCircuitOptimization.settingsView.chartLabels.chart.valvePosition'),
            scaling: {
              max: 100,
              min: 0,
            },
            type: 'line',
            unit: '%',
          },
        },
        selectedWidth: 'full',
        type: 'chart',
      },
    };
  }
}
