
import { Vue, Component } from 'vue-property-decorator';
import ProjectTags from '@/ui/components/components/ProjectTagsField.vue';
import { IPartner } from '@/types/partner.types';
import { Getter, Mutation } from 'vuex-class';
import { IPartnerWorkspacesState } from '@/store/modules/partnerWorkspaces/types';

@Component({
  components: {
    ProjectTags,
  },
})
export default class PartnerProjectFilter extends Vue {
  @Getter('partners/partners') partners!: IPartner[];
  @Mutation('partnerWorkspaces/setFilterByPartner') setFilterByPartner!: (filter: string) => IPartnerWorkspacesState;

  menu = false;
  selectedPartner = '';

  get partnerItems() {
    return this.partners.map((partner) => ({
      text: partner.data.name,
      value: partner.id,
    }));
  }

  get badgeValue() {
    return this.selectedPartner ? 1 : 0;
  }
}
