
import { Vue, Component, Watch } from 'vue-property-decorator';

/**
 * 404 page that shows if path is not found
 */
@Component
export default class Page404 extends Vue {
  light = require('../assets/images/error-page-bg-light.png')
  dark = require('../assets/images/error-page-bg-dark.png')

  currentBg = ''

  // check current theme state and change background image of 404 page
  @Watch('$vuetify.theme.dark')
  onThemeChange(val: any) {
    if (val) this.currentBg = this.dark;
    else this.currentBg = this.light;
  }

  // load dark or light background image for 404 page regarding of current theme state
  mounted() {
    if (this.$vuetify.theme.dark) this.currentBg = this.dark;
    else this.currentBg = this.light;
  }
}
