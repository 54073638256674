import { render, staticRenderFns } from "./ReportMessage.vue?vue&type=template&id=402f749d&"
import script from "./ReportMessage.vue?vue&type=script&lang=ts&"
export * from "./ReportMessage.vue?vue&type=script&lang=ts&"
import style0 from "./ReportMessage.vue?vue&type=style&index=0&id=402f749d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports