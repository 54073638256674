import api from '@/services/api';
import { ILead } from '@/types/leads.types';

export default {
  async fetchLeads() {
    return api.fetch('/admin/leads', 'GET');
  },
  async createLead(lead: Partial<ILead>) {
    return api.fetch('/admin/leads', 'POST', lead);
  },
  async updateLead(id: string, lead: ILead) {
    return api.fetch(`/admin/leads/${id}`, 'PUT', lead);
  },
  async deleteLead(id: string) {
    return api.fetch(`/admin/leads/${id}`, 'DELETE');
  },
  async fetchLeadsNonAdmin() {
    return api.fetch('partner/leads', 'GET');
  },
  async createLeadNonAdmin(lead: Partial<ILead>) {
    return api.fetch('/partner/leads', 'POST', lead);
  },
  async updateLeadNonAdmin(id: string, lead: ILead) {
    return api.fetch(`partner/leads/${id}`, 'PUT', lead);
  },
};
