
import { Vue, Component } from 'vue-property-decorator';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    InputFieldMock,
    DeviceLayout,
  },
})
export default class VariableInputFieldPreview extends Vue {
  get previewNameLang() {
    return this.$t('devices.VariableInputField.previewName');
  }
}
