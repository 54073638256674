
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter, Action, State } from 'vuex-class';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import ManageDevice from '@/ui/components/modals/ManageDevice/index.vue';
import { calculatePageCount } from '@/utils/utilsFunctions';
import { IDevicesState } from '@/store/modules/devices/types';
import { IRoom } from '@/types/rooms.types';
import { IDevice, IDevicesTypes } from '@/types/devices.types';

/**
 * Component that shows table of devices
 */
@Component({
  components: {
    DeleteModalForm,
    ManageDevice,
  },
})
export default class DevicesTable extends Vue {
  @Prop({ default: 100 }) itemsPerPage !: number;
  @State('devices') devicesState!: IDevicesState;
  @Getter('devices/devices') devices!: IDevice[];
  @Getter('devices/devicesTypes') devicesTypes!: IDevicesTypes;
  @Getter('rooms/rooms') rooms!: IRoom[];
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => void;
  @Action('devices/deleteDevice') deleteDevice!: (control: IDevice) => void;

  excludedDevices = ['Robot', 'SurveyClient'];
  currentPage = 1;

  get pageNumber() {
    return calculatePageCount(this.filteredBySearch.length, this.itemsPerPage);
  }

  get headers() {
    return [
      { text: this.$t('uiComponents.workbenchTable.name'), align: 'start', value: 'name' },
      { text: this.$t('uiComponents.workbenchTable.type'), value: 'data.type' },
      { text: this.$t('uiComponents.workbenchTable.area'), value: '' },
      { text: this.$t('uiComponents.workbenchTable.actions'), value: 'actions', sortable: false },
    ];
  }

  get devicesFilter() {
    return this.devicesState.devicesFilter;
  }
  get filterByDevices() {
    const devicesTypes = Object.keys(this.devicesTypes);
    return this.devices.filter((device: IDevice) => devicesTypes.includes(device.data.type));
  }
  /**
   * Filters the existing list of devices by type, name, area affiliation
   * @return filtered devices
   */
  get filteredBySearch() {
    return this.devicesFilter ? this.filterByDevices.filter((device: IDevice) => `${device.name} ${this.deviceRoomName(device.collection_id)} ${device.data.type}`
      .toLowerCase().includes(this.devicesFilter.toLowerCase())) :
      this.filterByDevices;
  }
  /**
   * Defines the name of the room to which the device belongs
   * @param roomId id of the room
   * @return room name if room has named or empty string
   */
  deviceRoomName(roomId: string) {
    if (this.rooms.length) {
      const room = this.rooms.find((room: IRoom) => room.id === roomId);
      return room?.name || null;
    }
    return '';
  }

  editControl(payload: IDevice) {
    this.updateDevice({ device: payload, skipReport: false });
  }
  deleteControlHandle(control: IDevice) {
    this.deleteDevice(control);
  }
}
