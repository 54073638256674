
import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { Periods } from '@/ui/components/devices/charts/charts/ChartUtils';
import PeriodSelection from '@/ui/components/devices/charts/components/PeriodSelection.vue';
import CalendarButtons from '@/ui/components/devices/charts/charts/CalendarButtons.vue';
import { ApproximationValue } from '@/types/chart.types';

@Component({
  components: {
    CalendarButtons,
    PeriodSelection,
    ModalWindow,
  },
})
export default class CalendarNavigation extends Vue {
  @Prop({ default: false }) disableNavButton!: boolean;
  @Prop({ default: false }) enableMultiSelect!: boolean;
  @Prop({ default: false }) hidePeriodNavigation!: boolean;
  @Prop({ default: false }) hideCalendarNavigation!: boolean;
  @Prop({ default: null }) modularDefaultPeriod!: string;
  @Prop({ default: null }) navigationItemsToExclude!: null | string[];
  @Prop({ default: () => ({ day: 4, week: 1, month: 1, year: 1 }) }) customApproximationValue!: ApproximationValue;

  currentPeriod: string = Periods.DAY;
  localCountColumns = 0;
  localCountLines = 0;
  rerenderKey = 0;
  handleNavigation(period: string) {
    this.rerenderKey++;
    this.currentPeriod = period;
    this.$emit('handleNavigation', period);
  }
  closeDialog() {
    (this.$refs.calendarSelectionButtonsInstance as any).closeDialog();
  }
}
