
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';

/**
 * Component that represent Reverse Basic Control
 */
@Component({
  components: { FeedbackProvider },
})
export default class ReverseBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop({ default: '#7c7c7c' }) color!: string;
  @Prop({ default: 'previous' }) icon!: string;
  @Prop({
    default: 'small',
    validator: (value: any): boolean => [null, 'x-small', 'small', 'large', 'x-large'].some((el: string | null) => el === value),
  }) buttonSizeClass!: string; // vuetify btn classes: x-small, small, large, x-large

  get state() {
    if (this.mappingsClean) {
      return !!this.measurements.get(this.mappingsClean.state);
    }
  }

  /**
   * Used to disable the Base component if there is no variable set in the mappings of the device.
   */
  get isNotMapped() {
    return this.mappingsClean.commandReverse === '';
  }

  down() {
    this.send([{ v: 1, n: this.mappingsClean.commandReverse, u: '' }]);
  }
  up() {
    this.onSendStart();
    this.send([{ v: 0, n: this.mappingsClean.commandReverse, u: '' }]);
    this.onSendSuccess();
  }
}
