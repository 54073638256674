
import { Vue, Component, Provide } from 'vue-property-decorator';
import ChartTimetableConsumer from '@/ui/components/devices/previews/mpc/EMS/charts/ChartTimetableConsumer.vue';
import ChartProdCons from '@/ui/components/devices/previews/mpc/EMS/charts/ChartProdCons.vue';
import Forecast from '@/ui/components/devices/previews/mpc/EMS/charts/Forecast/index.vue';
import EnergyFlow from '@/ui/components/devices/previews/mpc/EMS/charts/EnergyFlow/index.vue';

@Component({
  components: {
    ChartTimetableConsumer,
    ChartProdCons,
    Forecast,
    EnergyFlow,
  },
})
export default class EMSCharts extends Vue {
  tab = null

  @Provide('chartColors') provideColors = ['#6ca2d8', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'];

  get items() {
    return [
      {
        title: this.$t('mlModel.EMS.charts.chartDifference.title'),
        view: 'EnergyFlow',
      },
      {
        title: this.$t('mlModel.EMS.charts.chartProdConst.title'),
        view: 'ChartProdCons',
      },
      {
        title: this.$t('mlModel.EMS.charts.chartTimeTableConsumer.title'),
        view: 'ChartTimetableConsumer',
      },
      {
        title: this.$t('mlModel.EMS.charts.forecast.title'),
        view: 'Forecast',
      },
    ];
  }
}
