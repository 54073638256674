export enum SearchType {
  projects = 'projects',
  devices = 'devices',
  measurements = 'measurements',
  events = 'events',
  rules = 'rules',
  reports = 'reports',
  workspaces = 'workspaces',
  serialNumbers = 'serialNumbers',
}
