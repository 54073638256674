import { EnergyCircleDisplayData, IOEnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import {
  IOEnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';
import {
  ArrowDirection,
  EnergyLineColors,
} from '@/types/energyVisualisation/EnergyLineDisplayData';
import {
  energyConsumerColors,
  energyGreyColors,
  energyHybridColors,
  energyProducerColors,
} from '../../EnergyVisualisation/constants';

export function getDefaultLineColorsIO(type: IOEnergyCircleType, displayData: IOEnergyCircleDisplayData): EnergyLineColors {
  const value: any = displayData.bottomValue ?? displayData.alternativeValue;

  if (!value) {
    return energyGreyColors;
  }
  if (type === IOEnergyCircleType.inputs) {
    return energyProducerColors;
  }
  if (type === IOEnergyCircleType.outputs) {
    return energyConsumerColors;
  }

  // hybrid
  if (value > 0) {
    return energyHybridColors;
  }
  if (value < 0) {
    return energyConsumerColors;
  }
  return energyGreyColors;
}
export function getDefaultArrowDirectionIO(type: IOEnergyCircleType, displayData: IOEnergyCircleDisplayData): ArrowDirection {
  const value: any = displayData.bottomValue ?? displayData.alternativeValue;

  if (!value) {
    return ArrowDirection.none;
  }
  if (type === IOEnergyCircleType.inputs) {
    return ArrowDirection.toCenter;
  }
  if (type === IOEnergyCircleType.outputs) {
    return ArrowDirection.awayFromCenter;
  }

  // hybrid
  if (value > 0) {
    return ArrowDirection.toCenter;
  }
  if (value < 0) {
    return ArrowDirection.awayFromCenter;
  }
  return ArrowDirection.none;
}
