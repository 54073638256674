
import { Component, Inject, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import PaginationTabs from '@/ui/components/components/PaginationTabs.vue';
import TimeSlider from '@/ui/components/devices/components/EnergyParts/TimeSlider.vue';
import { EnergyCircleDisplayData, CirclePosition } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import { EnergyLineDisplayData } from '@/types/energyVisualisation/EnergyLineDisplayData';
import EnergyVisualisation from './index.vue';
import SystemDialog from './SystemDialog.vue';
import { getDefaultArrowDirectionSetpointOptimizer, getDefaultLineColorsSetpointOptimizer, getDefaultSpinnerColorSetpointOptimizer } from './utils/EnergyVisualisationUtils';

/**
 * Actual view for EnergyView/EMS
 */
@Component({
  components: {
    PaginationTabs,
    EnergyVisualisation,
    SystemDialog,
    TimeSlider,
  },
})
export default class SystemForeCastView extends mixins(EnergyComponent) {
  @Prop() circlesDisplayData!: EnergyCircleDisplayData[];
  @Prop() linesDisplayData!: Partial<EnergyLineDisplayData>[];
  @Prop({ default: null }) forecastData!: any;

  @Provide('onCircleClick') onCircleClick = this.onSingleSystemClickCallback;
  @Inject({ from: 'onSingleCircleClick', default: () => {} }) onSingleCircleClick?: (
    circleData: EnergyCircleDisplayData,
  ) => void;

  @Provide('onLineAnimationEnd') onLineAnimationEnd = this.onLineAnimationEndCallback;
  @Inject({ from: 'onDialogLineAnimationEnd', default: () => {} }) onDialogLineAnimationEnd!: (id: string) => void;

  readonly elementsPerPage = 10;
  startIndex = 0;

  onLineAnimationEndCallback(id: string) {
    this.onDialogLineAnimationEnd?.(id);
  }

  onSingleSystemClickCallback(circleData: EnergyCircleDisplayData) {
    this.onSingleCircleClick?.(circleData);
  }

  get circleKeys() {
    return this.circlesDisplayData.map((el: EnergyCircleDisplayData) => el.id);
  }
  get circlesDisplayDataMap() {
    return new Map(this.circlesDisplayData.map((el: EnergyCircleDisplayData) => [el.id, el]));
  }
  get linesDisplayDataMap() {
    return new Map(this.linesDisplayData.map((el: Partial<EnergyLineDisplayData>) => [el.id, el]));
  }
  get circleKeysSorted() {
    const sortedArray = this.circleKeys
      .sort((a, b) => {
        // extract numbers from system keys
        const aNum = Number(a.match(/\d+/)?.[0]);
        const bNum = Number(b.match(/\d+/)?.[0]);
        return aNum - bNum;
      });
    return sortedArray.slice(this.startIndex, this.startIndex + this.elementsPerPage);
  }
  get forecastCirclesDisplayData() {
    return this.circleKeysSorted.map((key: string, index: number) => {
      const circleData: any = this.circlesDisplayDataMap.get(key);
      const forecastData: Record<string, any> = this.systemsForecastDataFiltered;
      const currentData = forecastData[key];

      const circleDataWithForecast = {
        ...circleData,
        bottomValue: currentData?.[1],
        spinnerColor: getDefaultSpinnerColorSetpointOptimizer(circleData.id.replace(/[0-9]/g, ''), currentData?.[1] ?? 0),
      };
      return {
        ...circleDataWithForecast,
        position: index < 5 ? CirclePosition.top : CirclePosition.bottom,
      } as EnergyCircleDisplayData;
    });
  }
  get forecastLinesDisplayData() {
    return this.circleKeysSorted.map((key: string) => {
      const lineData: any = this.linesDisplayDataMap.get(key);
      const forecastData: Record<string, any> = this.systemsForecastDataFiltered;
      const currentData = forecastData[key];
      const displayData: any = { bottomValue: currentData?.[1] ?? 0 };
      const lineDataWithForecast = {
        ...lineData,
        arrowDirection: getDefaultArrowDirectionSetpointOptimizer(lineData.id.replace(/[0-9]/g, ''), displayData),
        colors: getDefaultLineColorsSetpointOptimizer(lineData.id.replace(/[0-9]/g, ''), displayData),
      };
      return { ...lineDataWithForecast } as EnergyLineDisplayData;
    });
  }

  updatePage(index: number) {
    this.startIndex = index;
  }

  async mounted() {
    await this.$nextTick();
    (this.$refs.EnergyVisualisation as any).updateCircles();
  }

  systemSlider = 0
  get timeSliderInitArr() {
    if (this.forecastData) {
      return Object.values(this.forecastData)[0];
    }
    return [];
  }

  /**
   * Return system instance values relative to the selected value on the slider
   * @return {object} Example: { "heating_air_systems1": [ 1649856600, 0 ], "hybrid_water_systems1": [ 1649856600, 0 ] }
   */
  get systemsForecastDataFiltered() {
    if (this.forecastData) {
      let obj = {};
      Object.entries(this.forecastData).forEach((system: any) => {
        const res = system[1][this.systemSlider] || [null, null];
        obj = { ...obj, ...{ [system[0]]: res } };
      });
      return obj;
    } else return {};
  }
}
