
import { Component, Mixins } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { Validation } from '@/ui/mixins/validation';
import { IProjectsState } from '@/store/modules/projects/types';
import { loggerLimitByType, solarmanLoggerLimits } from '@/ui/components/wizards/loggerWizard/wizardSettings/wizardLimits';
import { IDevice } from '@/types/devices.types';
import { ILoggerInformation } from '@/types/wizards/loggerWizard.types';
import { NavigationDirection } from '@/types/wizards/installationWizard.types';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: {
    WizardContentView,
    InfoTooltip,
  },
})
export default class DefineLoggerId extends Mixins(Validation) {
  @State('projects') projectsState!: IProjectsState;
  @Getter('loggerWizard/loggerInformation') getLoggerInformation !: ILoggerInformation[];
  @Getter('devices/allDevices') allDevices !: IDevice[];
  @Getter('loggerWizard/energyViewDevice') energyViewDevice!: IDevice;
  @Getter('loggerWizard/navigationDirection') navigationDirection!: NavigationDirection;
  @Mutation('loggerWizard/setLoggerInformation') setLoggerInformation!: (loggerInformation: ILoggerInformation[]) => Promise<void>;
  @Mutation('loggerWizard/setHasGridMeasurement') setHasGridMeasurement!: (hasGrid: boolean) => Promise<void>;
  @Action('loggerWizard/handleIncrement') handleIncrement!: () => void;
  @Action('loggerWizard/handleDecrement') handleDecrement!: () => void;

  isValid = false;
  loggerInformation = [{ id: '', type: '', hasPv: true, hasBattery: true, selectedProducer: 'nothingConnected', showProducer: false }];
  hasGridMeasurement = true;

  /**
   * Checks if there are duplicate logger ids
   */
  get duplicateLoggerIds() {
    const array = this.loggerInformation.map((logger: ILoggerInformation) => logger.id);
    const count: Record<string, number> = {};
    // Iterate over each element in the array
    for (let i = 0; i < array.length; i++) {
      const value = array[i];

      // If the value is already in the count object, it's a duplicate
      if (count[value] && value !== '') {
        return this.$t('loggerWizard.pages.DefineLoggerIds.duplicateLoggerIdNotAllowed', { value });
      }

      // Otherwise, add the value to the count object
      count[value] = 1;
    }
    return true;
  }

  get amountOfHybridLoggers() {
    return this.loggerInformation.filter((value: ILoggerInformation) => value.type === 'hybrid').length;
  }

  get amountOfStringLoggers() {
    return this.loggerInformation.filter((value: ILoggerInformation) => value.type === 'string').length;
  }

  /**
   * Returns the total logger limit based on logger type of master
   */
  get totalLoggerLimit() {
    if (this.loggerInformation[0].type === 'string') {
      return 20; // if master is string, then limit is 20 string loggers
    } else {
      return 30; // if master is hybrid, then limit is 30 in total (10 hybrid + 20 string)
    }
  }

  get loggerName() {
    return {
      string: this.$t('loggerWizard.pages.DefineLoggerIds.selectableLoggerTypes.stringInverter'),
      hybrid: this.$t('loggerWizard.pages.DefineLoggerIds.selectableLoggerTypes.hybridInverter'),
    };
  }

  /**
   * Returns the selectable logger types
   * Also checks if the limit of loggers is reached so that the logger type cant be selected anymore
   * Master can allways select all logger types
   */
  get selectableLoggerTypesMaster() {
    return [
      {
        state: this.$t('loggerWizard.pages.DefineLoggerIds.selectableLoggerTypes.stringInverter'),
        abbr: 'string',
        disabled: this.amountOfStringLoggers >= loggerLimitByType.string,
      },
      {
        state: this.$t('loggerWizard.pages.DefineLoggerIds.selectableLoggerTypes.hybridInverter'),
        abbr: 'hybrid',
        disabled: this.amountOfHybridLoggers >= loggerLimitByType.hybrid,
      },
    ];
  }

  /**
   * Returns the selectable logger types
   * Also checks if the limit of loggers is reached so that the logger type cant be selected anymore
   * Slave can only select logger type string if master is also string
   */
  get selectableLoggerTypesSlave() {
    if (this.loggerInformation[0].type === '') {
      return this.selectableLoggerTypesMaster;
    } else {
      if (this.loggerInformation[0].type === 'string') {
        return this.selectableLoggerTypesMaster.filter((loggerType: { state: TranslateResult; abbr: string }) => loggerType.abbr === 'string');
      }
    }
    return this.selectableLoggerTypesMaster;
  }

  /**
   * Returns the selectable producer options
   */
  get producerOptions() {
    return [
      { state: this.$t('loggerWizard.pages.DefineLoggerIds.producerOptions.generator'), abbr: 'generator' },
      { state: this.$t('loggerWizard.pages.DefineLoggerIds.producerOptions.acPv'), abbr: 'acpv' },
      { state: this.$t('loggerWizard.pages.DefineLoggerIds.producerOptions.nothingConnected'), abbr: 'nothingConnected' },
    ];
  }

  /**
   * Returns the selectable producer options for slave loggers
   * If master logger is generator, then slave logger cant be acpv and vice versa
   */
  get producerOptionsSlave() {
    // filter out options based on selected producer of first hybrid logger
    if (this.loggerInformation[0].selectedProducer === 'generator') {
      return this.producerOptions.filter((option: { state: TranslateResult; abbr: string }) => option.abbr !== 'acpv');
    }
    if (this.loggerInformation[0].selectedProducer === 'acpv') {
      return this.producerOptions.filter((option: { state: TranslateResult; abbr: string }) => option.abbr !== 'generator');
    }
    return this.producerOptions;
  }

  /**
   * Returns the limit of loggers based on logger type
   */
  get limitOfLoggers() {
    return {
      string: loggerLimitByType.string,
      hybrid: loggerLimitByType.hybrid,
    };
  }

  get defaultLoggerObject() {
    return { id: '', type: '', hasPv: true, hasBattery: true, selectedProducer: 'nothingConnected', showProducer: false };
  }

  get project() {
    return this.projectsState.project;
  }

  removeItem(index: number) {
    this.loggerInformation.splice(index, 1);
  }

  addLogger() {
    // add new logger object to array
    this.loggerInformation.push({ ...this.defaultLoggerObject });
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  handleLoggerTypeChange(index: number) {
    if (this.loggerInformation[index].type === 'string') {
      // if logger type is changed to string, then set hasPv to true and hasBattery to false
      this.loggerInformation[index].hasPv = true;
      this.loggerInformation[index].hasBattery = false;
      // if logger type is changed to string, then set selectedProducer to nothing connected
      this.loggerInformation[index].selectedProducer = 'nothingConnected';
      this.loggerInformation[index].showProducer = false;
    } else {
      // if logger type is changed to hybrid, then set hasPv and hasBattery to true
      this.loggerInformation[index].hasPv = true;
      this.loggerInformation[index].hasBattery = true;
    }

    // if master is changed from hybrid to string, then reset all slave loggers that are hybrid
    if (index === 0 && this.loggerInformation[0].type === 'string') {
      this.loggerInformation.forEach((logger: ILoggerInformation, loggerIndex: number) => {
        if (loggerIndex !== 0 && logger.type === 'hybrid') {
          const object = {
            ...this.defaultLoggerObject,
            hasBattery: false,
            id: logger.id,
            type: '',
          };
          this.loggerInformation[loggerIndex] = object;
        }
      });
    } else {
      // if master is changed from string to hybrid, then set grid measurement to true
      this.hasGridMeasurement = true;
    }
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  handleProducerChange(index: number) {
    // if master logger is changed, then reset all slave loggers
    if (index === 0) {
      this.loggerInformation.forEach((logger: ILoggerInformation, loggerIndex: number) => {
        if (loggerIndex !== 0) {
          if (this.loggerInformation[0].selectedProducer === undefined) {
            // if selected producer is undefined, then set it to nothing connected and also hide the producer selection
            this.loggerInformation[loggerIndex].selectedProducer = 'nothingConnected';
            this.loggerInformation[loggerIndex].showProducer = false;
          } else {
            this.loggerInformation[loggerIndex].selectedProducer = 'nothingConnected';
          }
        }
      });
    }
  }

  handleShowProducerChange(index: number) {
    this.loggerInformation[index].selectedProducer = '';
    // if master logger is changed, then reset all slave loggers
    if (index === 0) {
      this.loggerInformation.forEach((logger: ILoggerInformation, loggerIndex: number) => {
        if (loggerIndex !== 0) {
          this.loggerInformation[loggerIndex].showProducer = false;
          this.loggerInformation[loggerIndex].selectedProducer = 'nothingConnected';
        }
      });
    }
  }

  async handleNext() {
    // update logger limits (general will currently allways be 1 house 1 grid)
    const limitKeys = ['pv', 'battery', 'generator'];
    limitKeys.forEach((key: string) => {
      solarmanLoggerLimits[key] = this.loggerInformation.length;
    });

    this.setLoggerInformation(this.loggerInformation);
    this.setHasGridMeasurement(this.loggerInformation[0].type === 'string' ? this.hasGridMeasurement : true);
    this.handleIncrement();
  }

  handleBack() {
    this.handleDecrement();
  }

  created() {
    // if none of the two conditions are true, then we dont need to initialize anything
    if (this.project.meta.loggerInformation && this.navigationDirection === NavigationDirection.forward) {
      // case when user has allready finished setup assistant once
      this.loadDataOnRevsitOfWizard();
    } else if (this.getLoggerInformation.length > 0) {
      // case when user goes back to this page from next page
      this.loggerInformation = this.getLoggerInformation;
    }
  }

  loadDataOnRevsitOfWizard() {
    this.loggerInformation = [];
    // set logger types and
    // fill array with logger objects that currently only contain logger id
    this.project.meta.loggerInformation.forEach((logger: {id: string; type: string}) => {
      this.loggerInformation.push({ id: logger.id, type: logger.type, hasPv: false, hasBattery: false, selectedProducer: '', showProducer: false });
    });

    // fill array with logger objects based on energy view
    // so every logger object contains information about pv, acpv, battery, generator
    const keysToCheck = ['pv', 'battery', 'generator', 'grid'];
    const currentEnergyView: IDevice | undefined = this.allDevices.find((device: IDevice) => device.id === this.energyViewDevice.id);
    keysToCheck.forEach((systemKey: string) => {
      const systemMapping = currentEnergyView?.data.meta.controllerMappings[systemKey].components;
      switch (systemKey) {
        case 'grid': {
          // if systemMapping.count is 0, then there is no grid measurement
          this.hasGridMeasurement = Object.keys(systemMapping).length > 0;
          break;
        }
        case 'battery': {
          // go through all components and check if some logger has a battery
          Object.values(systemMapping).forEach((component: any) => {
            const loggerName = this.getLoggerNameFromVariable(component.power);
            this.updateLoggerInformation(loggerName, 'battery');
          });
          break;
        }
        case 'pv': {
          // go through all components and check if some logger has a pv or acpv
          Object.values(systemMapping).forEach((component: any) => {
            const loggerName = this.getLoggerNameFromVariable(component.power);
            const pvType = component.power.split('.')[2];
            if (pvType === 'PV') {
              this.updateLoggerInformation(loggerName, 'pv');
            } else {
              this.updateLoggerInformation(loggerName, 'acpv');
            }
          });
          break;
        }
        case 'generator': {
          // go through all components and check if some logger has a generator
          Object.values(systemMapping).forEach((component: any) => {
            const loggerName = this.getLoggerNameFromVariable(component.power);
            this.updateLoggerInformation(loggerName, 'generator');
          });
          break;
        }
      }
    });

    // fill up logger information with nothing connected info (selectedProducer)
    this.loggerInformation.forEach((loggerInfo: ILoggerInformation, index: number) => {
      if (loggerInfo.selectedProducer === '' || loggerInfo.selectedProducer === 'nothingConnected') {
        this.loggerInformation[index].selectedProducer = 'nothingConnected';
      }
    });
  }

  /**
   * Updates different logger information based on the system key
   */
  updateLoggerInformation(loggerId: string, systemKey: string) {
    const loggerIndex = this.loggerInformation.findIndex((logger: ILoggerInformation) => logger.id === loggerId);
    switch (systemKey) {
      case 'battery': {
        this.loggerInformation[loggerIndex].hasBattery = true;
        break;
      }
      case 'pv': {
        this.loggerInformation[loggerIndex].hasPv = true;
        break;
      }
      case 'generator': {
        this.loggerInformation[loggerIndex].selectedProducer = 'generator';
        this.loggerInformation[loggerIndex].showProducer = true;
        break;
      }
      case 'acpv': {
        this.loggerInformation[loggerIndex].selectedProducer = 'acpv';
        this.loggerInformation[loggerIndex].showProducer = true;
        break;
      }
    }
  }

  /**
   * Returns the logger name from a variable
   * Variable example: "solarman.${loggerId}.Battery.power}"
   */
  getLoggerNameFromVariable(variable: string) {
    const strings = variable.split('.');
    return strings[1];
  }

  mounted() {
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }
}
