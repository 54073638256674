
import { Vue, Component, Prop } from 'vue-property-decorator';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';
import ActualView from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/ActualView/index.vue';
import EnergyFlowView from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/EnergyFlowView/index.vue';
import EnergyViewCharts from '@/ui/components/devices/components/EnergyParts/EnergyView/components/charts/index.vue';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import { isHybridEnergyDeviceSystem } from '@/utils/mpcUtils';
import { IProject } from '@/types/project.types';
import { Getter } from 'vuex-class';

@Component({
  components: {
    CircleSpinner,
    MPCLayout,
    ActualView,
    EnergyFlowView,
    EnergyViewCharts,
  },
})
export default class EnergyViewV2 extends Vue {
  @Prop() deviceData!: any;
  @Getter('projects/project') project!: IProject;

  reRenderKey = 0;
  loaded = true;

  get showEventGeneralErrorWarning() {
    return {
      'ShowEvent_errorWarningState': this.deviceData?.data?.meta?.controllerMappings?.errorWarning,
    };
  }

  get allSystems() {
    if (this.deviceData?.data?.meta?.controllerMappings) {
      const {
        pv,
        generator,
        grid,
        battery,
        house,
        charge_station,
        electric_heating,
        heating_pump,
        big_consumer,
      } = this.deviceData.data.meta.controllerMappings;
      return {
        pv,
        generator,
        battery,
        grid,
        house,
        charge_station,
        electric_heating,
        heating_pump,
        big_consumer,
      };
    } else {
      return null;
    }
  }

  async toggleLoading(status: boolean) {
    this.loaded = !status;
  }

  /**
   * Checks if a system is a combination of the Lynus and Solarman Logger System
   */
  get isHybridSystem() {
    return isHybridEnergyDeviceSystem(this.deviceData, this.project);
  }

  /**
   * Convert MPC id from "id-id-id" to "id_id_id"
   */
  get mpcIdMod() {
    if (this.deviceData.id) return this.deviceData.id.replace(/-/g, '_');
    return null;
  }

  get displayEnergyFlowHouseInfo() {
    const houseCount = this.deviceData.data.meta.controllerMappings.house.count ?? 0;
    return this.project.meta.wasTenantWizardCompleted && this.project.meta.hasLynusInverter && houseCount > 1;
  }
}
