import { render, staticRenderFns } from "./BrightnessSensor.vue?vue&type=template&id=5fd4ffa2&scoped=true&"
import script from "./BrightnessSensor.vue?vue&type=script&lang=ts&"
export * from "./BrightnessSensor.vue?vue&type=script&lang=ts&"
import style0 from "./BrightnessSensor.vue?vue&type=style&index=0&id=5fd4ffa2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fd4ffa2",
  null
  
)

export default component.exports