
import { Vue, Component, Prop } from 'vue-property-decorator';
import OutputFieldTextBase from '@/ui/components/devices/devices/base/OutputFieldTextBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent VariableTextOutputField device
 */
@Component({
  components: {
    OutputFieldTextBase,
    DeviceLayout,
  },
})
export default class VariableTextOutputField extends Vue {
  @Prop() deviceData!: IDevice;

  get unit() {
    return this.deviceData.data.meta && this.deviceData.data.meta.unit ? this.deviceData.data.meta.unit : '';
  }

  iconTheme() {
    return { filter: this.$vuetify.theme.dark ? 'brightness(0) invert(1)' : null };
  }
}
