
import { Vue, Component, Prop } from 'vue-property-decorator';
import installationWizardVariables
  from '@/ui/components/wizards/installationWizard/installationWizardVariables';
import {
  IIncludedSystemsTypes,
  NavigationDirection, WizardPath,
} from '@/types/wizards/installationWizard.types';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { IProject } from '@/types/project.types';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { IInverter, SerialNumberType } from '@/types/serialNumber.types';
import {
  gridMappingEMS, gridMappingEnergyView,
} from '@/ui/components/wizards/installationWizard/wizardSettings/defaultMappings';
import { IDevice } from '@/types/devices.types';
import _ from 'lodash';
import {
  CHECKS_DISABLED, IGNORE_INVERTER_CHECK
} from '@/ui/components/wizards/installationWizard/wizardSettings/developmentConfig';

@Component({
  computed: {},
  components: { WizardContentView, ModalWindow },
})
export default class InverterConfiguration extends WizardComponent {
  @State('installationWizard') installationWizardState!: IInstallationWizardState;
  @Getter('projects/project') project!: IProject;
  @Getter('serialNumber/serialNumbersFromMqtt') serialNumbersFromMqtt!: { [key: string]: string };
  @Getter('installationWizard/navigationDirection') navigationDirection!: NavigationDirection;
  @Getter('installationWizard/serialNumberCheckDone') serialNumberCheckDone!: boolean;
  @Getter('serialNumber/inverters') inverters!: IInverter[];
  @Getter('installationWizard/includedSystemsTypes') includedSystems!: IIncludedSystemsTypes;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Action('serialNumber/fetchInverters') fetchInverters!: (projectId: string) => Promise<void>;
  @Action('serialNumber/activateSerialNumbers') activateSerialNumbers!: (payload: { projectId: string; inverters: { id: string; type: SerialNumberType }[] }) => Promise<boolean>;
  @Mutation('installationWizard/setWizardBlocked') setWizardBlocked!: (payload: boolean) => void;
  @Mutation('installationWizard/setSerialNumberCheckDone') setSerialNumberCheckDone!: () => void;
  @Mutation('serialNumber/setInverters') setInverters!: (inverters: { id: string; type: SerialNumberType }[]) => void;

  loading = false;
  checkWasSuccessful = false;

  get valid() {
    const noInverters = !this.installationWizardState.inverterCount && !this.installationWizardState.stringInverterCount;
    return this.serialNumberCheckDone || this.checkWasSuccessful || noInverters || IGNORE_INVERTER_CHECK;
  }

  get inverterPower() {
    return this.measurements['prgEnergy.fbI.rMaxPowerInverter'] ?? 0;
  }

  get numberOfInverters() {
    const amountFromMqtt = Object.keys(this.serialNumbersFromMqtt).filter((key) => !key.includes('prgACPV')).length;
    const amountInStore = this.installationWizardState.inverterCount;
    if (amountInStore < amountFromMqtt) return amountInStore;
    return amountFromMqtt;
  }

  get numberOfStringInverters() {
    const amountFromMqtt = Object.keys(this.serialNumbersFromMqtt).filter((key) => key.includes('prgACPV')).length;
    const amountInStore = this.installationWizardState.stringInverterCount;
    if (amountInStore === 0 || amountFromMqtt === 0) return 0;
    if (amountInStore < amountFromMqtt) return amountInStore;
    return amountFromMqtt;
  }

  onNext() {
    this.setSerialNumberCheckDone();
    this.handleNext();
  }

  async checkSerialNumbers() {
    this.loading = true;
    if (this.project.id) await this.fetchMeasurements(this.project.id);
    let allUploaded = false;
    // wait for measurements to have all selected inverters set to a value that is not -1
    let serialNumberVariables: { variable: string; value: string }[] = [];

    // both loops to define which inverters are to be checked
    for (let i = 0; i < this.installationWizardState.inverterCount; i++) {
      const variable = installationWizardVariables('deyeInverterSerialNumber', 'pilotPage', -1, i);
      serialNumberVariables.push({ variable: variable.variable, value: '-1' });
    }
    for (let i = 0; i < this.installationWizardState.stringInverterCount; i++) {
      const variable = installationWizardVariables('stringInverterSerialNumber', 'pilotPage', -1, i);
      serialNumberVariables.push({ variable: variable.variable, value: '-1' });
    }

    serialNumberVariables = serialNumberVariables.map((entry: any) => {
      entry.value = this.serialNumbersFromMqtt[entry.variable] ?? '-1';
      return entry;
    });

    allUploaded = IGNORE_INVERTER_CHECK;
    if (serialNumberVariables.every((variable) => variable.value !== '-1' && variable.value !== undefined)) {
      allUploaded = true;
    }

    if (!allUploaded) return false;
    const inverters = serialNumberVariables.map((entry: any) => {
      const { variable, value } = entry;
      const id = value;
      let type = SerialNumberType.HybridInverter;
      if (variable.includes('prgACPV')) {
        type = SerialNumberType.StringInverter;
      }
      return {
        id,
        type,
      };
    });

    let status = false;
    // only check if not all inverters are confirmed
    if (this.project.id) {
      const filteredInverters = inverters.filter((inverter: { id: string; type: SerialNumberType }) => inverter.id !== '-1');
      status = await this.activateSerialNumbers({
        projectId: this.project.id,
        inverters: filteredInverters,
      });
    }

    if (IGNORE_INVERTER_CHECK) return true;
    return status;
  }

  setVariablesToSend(variableName: string, value: number | string = 0, systemIndex = 0, page = 'pilotPage') {
    this.variablesToSend.set(variableName + systemIndex, installationWizardVariables(variableName, page, value, systemIndex));
  }

  sendBatteryVariables() {
    const pvPowerVariable = installationWizardVariables('maxPowerInverter', 'componentsPage').variable;
    // get inverter power and divide by the number of inverters to check if sps is big enough for next variables
    const power = (this.measurements[pvPowerVariable] as number ?? 0) / this.installationWizardState.inverterCount;

    if (power >= 30000) {
      const separateBatteryInput = this.includedSystems.battery.definition.length > 1 ? 1 : 0;
      this.setVariablesToSend('batteryInput', separateBatteryInput, 0, 'componentsPage');
    } else {
      this.setVariablesToSend('batteryInput', 0, 0, 'componentsPage');
    }
    this.setVariablesToSend('numberOfBatteries', this.includedSystems.battery.count * this.installationWizardState.inverterCount, 0, 'componentsPage');
  }

  async created() {
    // send battery variables
    await this.sendBatteryVariables();
    // check if serial numbers from inverters are being sent and available in backend
    // define all checks
    const navigationDirectionCheck = this.navigationDirection !== NavigationDirection.backward;

    if (navigationDirectionCheck && !this.serialNumberCheckDone) {
      // check if serialNumberCheck is already done and no other inverters were added
      if (this.wasInstallationWizardCompleted) {
        const sameAmountOfInverters = (this.installationWizardState.inverterCount + this.installationWizardState.stringInverterCount) === this.inverters.length;
        const allInvertersAreConfirmed = this.inverters.every((inverter: IInverter) => inverter.project_id === this.project.id);
        if (sameAmountOfInverters && allInvertersAreConfirmed) {
          this.setSerialNumberCheckDone();
        }
      }
      if (this.installationWizardState.inverterCount !== 0 || this.installationWizardState.stringInverterCount !== 0) {
        const status = await this.checkSerialNumbers();
        this.checkWasSuccessful = status;
        this.variablesToSend.set('serialNumbersConfirmed', installationWizardVariables('serialNumbersConfirmed', 'pilotPage', status && this.installationWizardState.inverterCount ? 1 : 0));
        if (this.installationWizardState.stringInverterCount > 0) this.variablesToSend.set('stringInverterSerialNumberConfirmed', installationWizardVariables('stringInverterSerialNumberConfirmed', 'pilotPage', status ? 1 : 0));
        this.variablesToSend.set('lastVariable', installationWizardVariables('lastVariable', 'componentsPage'));
        if (status) {
          this.loading = false;
        } else {
          // if serial numbers are not confirmed we set the wizard blocked
          this.setWizardBlocked(true);
          this.setSerialNumberCheckDone();
        }
      }
    } else if (navigationDirectionCheck && this.serialNumberCheckDone) {
      this.variablesToSend.set('serialNumbersConfirmed', installationWizardVariables('serialNumbersConfirmed', 'pilotPage', this.installationWizardState.inverterCount ? 1 : 0));
      if (this.installationWizardState.stringInverterCount > 0) this.variablesToSend.set('stringInverterSerialNumberConfirmed', installationWizardVariables('stringInverterSerialNumberConfirmed', 'pilotPage', status ? 1 : 0));
      this.variablesToSend.set('lastVariable', installationWizardVariables('lastVariable', 'componentsPage'));
    }
  }
}
