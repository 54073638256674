
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Getter, Mutation, State } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import { cloneDeep } from 'lodash';
import { MQTTFeedbackError } from '@/store/modules/installationWizard/MQTTFeedbackError';
import { IReportBox } from '@/types/app.types';
import { IMQTTVariable } from '@/types/wizards/installationWizard.types';
import { IWizardLoadingState } from '@/types/wizards/wizard.general.types';
import { ICountersObject, IFlatsConfig, ITenantModel } from '@/types/wizards/tenant.types';
import { IMeasurements } from '@/types/measurements.types';
import { convertDecimalNumberToBinary, convertBitToArray } from '@/utils/utilsFunctions';
import { plcVersionDate, tenantAcBatteryUpdate, tenantUpdateWagoCounters } from '@/utils/versionManagementUtils';
import { IProject } from '@/types/project.types';
import { filterNumberFromString } from '@/utils/tenantWizardUtilsFunctions';
import { defaultTimeoutRepotMessages } from '@/utils/constants';
import { IMember } from '@/types/members.types';

@Component({
  components: {
    InfoTooltip,
    WizardContentView,
  },
})
export default class BillingAndDocumentOutput extends mixins(Validation) {
  @Getter('projects/projectCreationTimestamp') projectCreationTimestamp!: number;
  @Getter('measurements/measurements') measurements!: IMeasurements;
  @Getter('tenantWizard/isAutoMappingEnabled') isAutoMappingEnabled!: boolean;
  @Getter('projects/project') project!: IProject;
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Mutation('tenantWizard/updateTenantObject') updateTenantObject!: (data: ITenantModel) => void;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;
  @Action('tenantWizard/downLoadTenant') downLoadTenant!: (data: {start: number; end: number; project_id: string}) => any;
  @Action('installationWizard/sendVariables') sendVariables!: (variablesToSend: IMQTTVariable[]) => Promise<void>;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Mutation('installationWizard/setLoadingState') setLoadingState!: (payload: IWizardLoadingState) => void;
  @Mutation('installationWizard/resetLoadingState') resetLoadingState!: () => void;
  @Mutation('app/setReport') setReport!: (report: IReportBox) => void;
  @Getter('members/currentMember') currentMember!: IMember;

  variablesToSend: IMQTTVariable[] = [];

  valid = false;
  showLoader = false;
  selectedMonth = '';
  wagoCounterCheckEnabled = true;

  get periodOptions() {
    return [
      { value: 'Year', text: this.$t('tenantWizard.billingAndDocumentOutput.periodOptions.Year') },
      { value: 'HalfYear', text: this.$t('tenantWizard.billingAndDocumentOutput.periodOptions.HalfYear') },
      { value: 'QuarterYear', text: this.$t('tenantWizard.billingAndDocumentOutput.periodOptions.QuarterYear') },
      { value: 'Month', text: this.$t('tenantWizard.billingAndDocumentOutput.periodOptions.Month') },
      { value: 'Never', text: this.$t('tenantWizard.billingAndDocumentOutput.periodOptions.Never') },
    ];
  }

  billingSettings = {
    start: '',
    interval: 'Year',
    mail: '',
    subject: 'Mieterstrommodell Abrechnung',
    content: 'Mieterstrommodell Abrechnung',
    download: {
      start: '', // will contain the start date for the accounting period
      end: '', // will contain the end date for the accounting period
    },
  };

  get isCurrentMemberAdmin() {
    return this.currentMember.owner && this.currentMember.email === 'support@lynus.io';
  }

  async handleNext() {
    this.updateTenant();

    const measurementsUpdatedCorrectly = await this.updateMeasurements();
    if (measurementsUpdatedCorrectly) {
      this.handleIncrement();
    }
  }

  async updateMeasurements() {
    await this.fetchMeasurements(this.$route.params.id);
    // the plus 2 is for the Grid Couter and Grid Production Counter that are allways mapped in the tenant object
    let countOverallMeasurements = this.tenantState.tenant.general.overallCounters.energyCounter.length + this.tenantState.tenant.general.productionCounters.length + 1;
    // will contain amount of all flat specific energy counters
    let countFlatSpecificCounters = 0;
    let highestCounter;
    const { productionCounters } = this.tenantState.tenant.general;
    if (!this.isAutoMappingEnabled) {
      if (productionCounters.includes('prgEnergy.lrCounterProdTotal') || productionCounters.includes('prgEM.lrCounterProdTotal_2')) {
        // this is a digital counter thats why it is not needed to count in for the counter amount
        countOverallMeasurements -= 1;
      }
      let savedValue = this.measurements['prgEM.lwPosOfACBattery'];
      if (typeof savedValue === 'string') {
        savedValue = parseInt(savedValue, 10);
      }
      if (plcVersionDate(this.project).getTime() > tenantAcBatteryUpdate.getTime()) {
        // if saved value is set and not 0 we add the ac battery count
        if (savedValue || savedValue !== 0) {
          // convert the decimal number to binary and then to an array of numbers used for the ac battery mapping
          const binaryNumber = convertDecimalNumberToBinary(savedValue).toString();
          const acBatteryNumbers = convertBitToArray(binaryNumber);
          // add all ac battery mappings to counters
          countOverallMeasurements += acBatteryNumbers.length;
        }
      }
      this.tenantState.tenant.general.flats.flatConfigurations.forEach((flat: IFlatsConfig) => {
        countFlatSpecificCounters += flat.counters.energyCounter.length;
      });
    } else {
      const productionCountersFiltered = productionCounters.filter((counter: string) => counter !== 'prgEnergy.lrCounterProdTotal' && counter !== 'prgEM.lrCounterProdTotal_2') ?? [];
      const overallCounters = this.tenantState.tenant.general.overallCounters.energyCounter.map((counter: ICountersObject) => counter.id);
      const allCounters = [...productionCountersFiltered];
      overallCounters.forEach((counter: string) => {
        allCounters.push(counter);
      });
      // add grid counter
      allCounters.push(this.tenantState.tenant.general.gridCounter);
      this.tenantState.tenant.general.flats.flatConfigurations.forEach((flat: IFlatsConfig) => {
        flat.counters.energyCounter.forEach((counter: ICountersObject) => {
          allCounters.push(counter.id);
        });
      });
      let savedValue = this.measurements['prgEM.lwPosOfACBattery'];
      if (typeof savedValue === 'string') {
        savedValue = parseInt(savedValue, 10);
      }
      if (plcVersionDate(this.project).getTime() > tenantAcBatteryUpdate.getTime()) {
        // if saved value is set and not 0 we add the ac battery count
        if (savedValue || savedValue !== 0) {
          // convert the decimal number to binary and then to an array of numbers used for the ac battery mapping
          const binaryNumber = convertDecimalNumberToBinary(savedValue).toString();
          const acBatteryNumbers = convertBitToArray(binaryNumber);
          // add all ac battery mappings to counters
          acBatteryNumbers.forEach((counter: number) => {
            allCounters.push(`${counter}`);
          });
        }
      }
      const counterNumbers = allCounters.map((counter: string) => {
        return filterNumberFromString(counter) !== undefined ? parseInt(filterNumberFromString(counter), 10) : 0;
      }).filter((counter: number) => counter !== 0);
      highestCounter = Math.max(...counterNumbers);
    }

    const val = this.isAutoMappingEnabled ? highestCounter : countOverallMeasurements + countFlatSpecificCounters;
    this.variablesToSend = [
      {
        variable: 'prgEM.byNumberOfEM',
        value: val ?? 0,
      },
    ];

    if (this.wagoCounterCheckEnabled) {
      this.variablesToSend.push({
        variable: 'prgEM.bEnable',
        value: 1,
        feedbackVariable: 'prgEM.bSEnable',
        isBoolean: true,
        customTimeout: this.measurements['prgEM.bErrorEM'] !== undefined ? 30 : undefined, // if error variable exists we set a custom timeout
      });
    } else {
      // super admin can disable the wago counter check
      this.variablesToSend.push({
        variable: 'prgEM.bEnable',
        value: 1,
      });
    }
    try {
      this.setLoadingState({
        isLoading: true,
        loadingCount: 0,
        loadingTotal: this.variablesToSend.length,
      });
      await this.sendVariables(this.variablesToSend);
      if (this.measurements['prgEM.bErrorEM'] !== undefined) {
        // check if variable "prgEM.bErrorEM" is 0 or 1
        // 1 = error, 0 = no error
        await this.fetchMeasurements(this.$route.params.id);
        if (this.measurements['prgEM.bErrorEM'] === 1) {
          this.resetLoadingState();
          this.setReport({
            type: 'error',
            message: this.$t('tenantWizard.billingAndDocumentOutput.electricMeterCommunicationError').toString(),
            value: true,
            timeout: defaultTimeoutRepotMessages,
          });
          return false;
        } else {
          this.resetLoadingState();
          this.handleIncrement();
          return true;
        }
      } else {
        this.resetLoadingState();
        this.handleIncrement();
        return true;
      }
    } catch (e) {
      if (e instanceof MQTTFeedbackError) {
        this.resetLoadingState();
        this.setReport({
          type: 'error',
          message: this.$t('installationWizard.mqttFeedbackErrorMessage', { variable: e.variable }),
          value: true,
          timeout: defaultTimeoutRepotMessages,
        });
      }
      this.resetLoadingState();
      return false;
    }
  }

  /**
   * gets al data from the store and sets it to the local variables
   */
  created() {
    if (this.tenantState.tenant.billingSettings.mail !== '') {
      // convert unix timestamp to date string in order for the calendar to work correctly
      this.billingSettings.start = new Date(this.tenantState.tenant.billingSettings.start * 1000).toISOString();
      this.billingSettings.interval = this.tenantState.tenant.billingSettings.interval;
      this.billingSettings.mail = this.tenantState.tenant.billingSettings.mail;
      this.billingSettings.subject = this.tenantState.tenant.billingSettings.subject;
      this.billingSettings.content = this.tenantState.tenant.billingSettings.content;
      this.getMonth();
    }
  }

  /**
   * updates the tenant object in the store
   */
  updateTenant() {
    const tenantCopy = cloneDeep(this.tenantState.tenant);
    const finalBillingSettings: any = { ...this.billingSettings };
    finalBillingSettings.start = this.getFirstOfMonth();
    tenantCopy.billingSettings = finalBillingSettings;
    this.updateTenantObject(tenantCopy);
  }

  /**
   * returns the first day of the selected month in unix timestamp to save it in the backend
   */
  getFirstOfMonth() {
    const firstDay = new Date(`${this.selectedMonth}-01`);
    return firstDay.getTime() / 1000;
  }

  /**
   * return date string in format YYYY-MM for the v-date-picker. from the saved unix timestamp in the backend
   */
  getMonth() {
    const date = new Date(this.billingSettings.start);
    const selectedMonth = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    this.selectedMonth = `${date.getFullYear()}-${selectedMonth}`;
  }

  mounted() {
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  // contains current date string to set max date for calendar
  get maxDateCalendar() {
    return new Date().toDateString();
  }

  // checks if both date fields are filled for the download
  get areDownloadFieldsFilled() {
    if (this.billingSettings.download.start !== '' && this.billingSettings.download.end !== '' && this.billingSettings.download.start !== null && this.billingSettings.download.end !== null) {
      return true;
    }
    return false;
  }

  // checks if the download dates are valid
  get areDownloadDatesValid() {
    const start = new Date(this.billingSettings.download.start).getTime() / 1000;
    const end = new Date(this.billingSettings.download.end).getTime() / 1000;
    return end > start;
  }
}
