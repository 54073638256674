
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';

/**
 * Navigation tab component, used in manage modals form for navigation
 */
@Component
export default class NavigationTab extends Vue {
  @Prop({ default: '' }) tabTitle!: string | VueI18n.TranslateResult;
  @Prop({ default: false }) isActive!: boolean;
  @Prop({ default: 'accent' }) color!: string;
}
