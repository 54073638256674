
import { Component, Prop } from 'vue-property-decorator';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import DeviceActions from '@/ui/components/devices/actions/DeviceActions.vue';
import ShowEventBase from '@/ui/components/devices/devices/base/ShowEventBase.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import StatusIconBase from '@/ui/components/devices/devices/base/StatusIconBase.vue';
import OutputFieldTextBase from '@/ui/components/devices/devices/base/OutputFieldTextBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import SliderBase from '@/ui/components/devices/devices/base/SliderBase.vue';
import FillLevelDisplayBase from '@/ui/components/devices/devices/base/FillLevelDisplayBase.vue';
import SettingsView from '@/ui/components/devices/components/SettingsView.vue';
import DropDownBase from '@/ui/components/devices/devices/base/DropDown.vue';
import DateTimePickerBase from '@/ui/components/devices/devices/base/DateTimePickerBase.vue';
import _ from 'lodash';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent Robot device
 */
@Component({
  components: {
    DateTimePickerBase,
    DropDownBase,
    SettingsView,
    FillLevelDisplayBase,
    SliderBase,
    PushButtonBase,
    OutputFieldBase,
    OutputFieldTextBase,
    StatusIconBase,
    InputFieldBase,
    DeviceActions,
    ShowEventBase,
  },
})
export default class Robot extends mixins(Validation) {
  @Prop() deviceData!: IDevice;
  @State('measurements') measurementsState!: IVariablesState;

  isSettingsView = false

  messageBoxObject = {
    ronova_info: [0, 1],
    ronova_warning: [2, 2],
  }
  wifiStatusObject = {
    ronova_wifi_no: [-1, -1],
    ronova_wifi_low: [0, 33],
    ronova_wifi_med: [34, 66],
    ronova_wifi_full: [67, 100],
  }
  satelliteCount = {
    ronova_gps_no_fix: [-1, -1],
    ronova_gps_fix: [0, 100],
  }
  gpsStatus = {
    ronova_lte_no: [-1, -1],
    ronova_lte_low: [0, 33],
    ronova_lte_med: [34, 66],
    ronova_lte_full: [67, 100],
  }
  batteryStatus={
    ronova_battery_charge: [-1, -1],
    ronova_battery_0: [0, 11],
    ronova_battery_25: [12, 37],
    ronova_battery_50: [38, 63],
    ronova_battery_75: [64, 88],
    ronova_battery_100: [89, 100],
  }
  musterMapping = {
    0: 'Muster 0',
    1: 'Muster 1',
    2: 'Muster 2',
    3: 'Muster 3',
    4: 'Muster 4',
    5: 'Muster 5',
    6: 'Muster 6',
    7: 'Muster 7',
    8: 'Muster 8',
    9: 'Muster 9',
    10: 'Muster 10',
  }

  focValue: any = 0
  speedValue: any = 0
  currentProgressLevel: any = 0

  //  spindelSpeed
  get spindelSpeedValue() {
    let val: any = this.measurements.get(this.deviceData.data.mappings.OutputField8_actualValue);
    val = _.clamp(val, 0, 9999);
    return val;
  }

  get statusMessageBox() {
    if (this.measurements.get(this.deviceData.data.mappings.StatusIcon5_currentValue) === undefined) {
      return -1;
    } else {
      return this.measurements.get(this.deviceData.data.mappings.StatusIcon5_currentValue);
    }
  }

  //  FOC (Frequency of clip) Value
  get sliderActualValue1() {
    this.focValue = this.measurements.get(this.deviceData.data.mappings.Slider1_targetValue);
    return this.measurements.get(this.deviceData.data.mappings.Slider1_targetValue);
  }

  //  Speed Robot
  get sliderActualValue2() {
    this.speedValue = this.measurements.get(this.deviceData.data.mappings.Slider2_targetValue);
    return this.measurements.get(this.deviceData.data.mappings.Slider2_targetValue);
  }

  get isScrollContainerShown() {
    // contains Buttonstate for upload Job
    return this.measurements.get(this.deviceData.data.mappings.FillLevelDisplay1_actualValue) !== -1;
  }

  get progressMowingJob() {
    return this.measurements.get(this.deviceData.data.mappings.FillLevelDisplay1_actualValue);
  }

  switchSettingsView(setting: boolean) {
    this.isSettingsView = setting;
  }

  get measurements() {
    return this.measurementsState.measurements;
  }
  get unit() {
    return this.deviceData.data.meta && this.deviceData.data.meta.unit ? this.deviceData.data.meta.unit : '';
  }
}
