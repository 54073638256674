
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { cloneDeep } from 'lodash';
import IconService from '@/services/IconService';
import IconList from '@/ui/components/components/IconList.vue';
import ComboboxField from '@/ui/components/modals/components/form/ComboboxField.vue';
import { getDefaultRulesObject } from '@/utils/utilsFunctions';
import { FormUpdateTracker } from '@/ui/mixins/formUpdateTracker';
import {
  IDevicesState,
} from '@/store/modules/devices/types';
import { IVariable } from '@/types/variables.types';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IRoom } from '@/types/rooms.types';
import { IProject } from '@/types/project.types';
import { IRule } from '@/types/rules.types';
import { IDevice, IDevicesTypes, IDeviceMapping } from '@/types/devices.types';
import { Icon } from '@/types/common.types';
import { IMember } from '@/types/members.types';
import { Validation } from '@/ui/mixins/validation';

/**
 * Scheme to create, modify a specific device.
 * Specified in the device type definition in store/devices/devicesTypes.ts
 */
@Component({
  components: {
    IconList,
    ComboboxField,
  },
})
export default class DefaultSchemaWithUserSelection extends mixins(FormUpdateTracker, Validation) {
  @Prop({
    default: () => ({
      name: '',
      data: {
        type: '',
        mappings: {},
      },
      collection_id: '',
    }),
  }) deviceData!: IDevice;
  @Prop({}) isEditModal!: boolean;
  @Prop({ default: '' }) activeRoomId!: string;
  @Getter('variables/variablesForComboBox') variablesForComboBox!: IVariable[];
  @State('measurements') measurementsState!: IVariablesState;
  @State('devices') devicesState!: IDevicesState;
  @Getter('rooms/sortedRoomsByName') sortedRoomsByName!: IRoom[];
  @Getter('projects/project') project!: IProject;
  @Getter('devices/devicesTypes') devicesTypes!: IDevicesTypes;
  @Getter('members/members') members!: IMember[];
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;
  @Action('rules/addRules') addRules!: (payload: {
    project_id: string;
    rulesList: IRule[];
  }) => Promise<IRule[]>;
  @Action('rules/deleteRule') deleteRule!: (payload: { project_id: string; rule_id: string }) => Promise<void>;

  stage = 1;
  localDeviceData: IDevice = JSON.parse(JSON.stringify(this.deviceData));
  showOptionalFields = false;

  unit = ''

  iconObject?: Icon
  icons: Icon[] = []

  // user selection part

  handleGoToStage2() {
    this.stage = 2;
  }

  selectedUsers: { text: string; value: string }[] = [];
  chargeID = '';

  handleDeleteUser(item: { text: string; value: string }) {
    // remove user counter variables if a user is removed
    this.selectedUsers.splice(this.selectedUsers.indexOf(item), 1);
  }

  /**
   * List of members for user selection
   */
  get selectableMembers() {
    return this.members.map((member: IMember) => ({
      value: member.id,
      text: `${member.first_name} ${member.last_name}`,
    }));
  }

  // end of user selection part

  @Watch('stage')
  onStageChange(val: number) {
    if (val === 2) {
      this.fetchVariables(this.$route.params.id);
    }
  }

  removeIcon() {
    this.localDeviceData.data.meta.cover = '';
  }

  iconTheme() {
    return { filter: this.$vuetify.theme.dark ? 'brightness(0) invert(1)' : null };
  }

  // steps validation
  get nameValidation() {
    return !!this.localDeviceData?.name?.length;
  }

  /**
   * Checks mappings if they not valid
   * @return true if mapping filled, else false
   */
  mappingValidation() {
    if (this.stage === 2) {
      const optionalFields = Object.entries(this.deviceMappings)
        .filter((field: any) => !field[1].optional)
        .map((field: any) => field[0]);
      return optionalFields.map((el: string) => this.localDeviceData.data.mappings[el]).every((el: string) => el) && this.chargeID !== '';
    } else {
      return false;
    }
  }
  get settingsValidation() {
    return !!this.localDeviceData.collection_id.length;
  }

  get deviceSchema() {
    return this.devicesTypes[this.deviceData.data.type];
  }
  get deviceMinMaxBorders() {
    const device = this.devicesTypes[this.deviceData.data.type];
    const arrayOfMappings = Object.entries(device.mappings).filter((field: [string, IDeviceMapping]) => field[1].minMaxBorders);
    let obj: any = {};
    if (arrayOfMappings.length) {
      arrayOfMappings.forEach((field: [string, IDeviceMapping]) => obj = { ...obj, [field[0]]: field[1].minMaxBorders });
    }
    return obj;
  }
  get deviceMappings() {
    return this.deviceSchema.mappings;
  }
  get deviceMappingsList() {
    if (this.localDeviceData?.data?.type) {
      return Object.keys(this.deviceSchema.mappings);
    }
    return [];
  }

  /**
   * Search icons by inserted name
   * @param q name of the icon
   * @return list of icons
   */
  async search(q: string) {
    // clear icons if the query is smaller than 3 chars
    if (q.length < 3) {
      this.icons = [];
      return Promise.resolve();
    }

    return IconService.fetchIcons(q).then((res: Icon[]) => {
      this.icons = res.map((icon: Icon) => ({ term: icon.term, id: icon.id }));
    });
  }
  onSelected(icon: Icon) {
    this.iconObject = icon;
  }

  /**
   * Goes through device mappings list and create options for every item
   * @return form of mappings options
   */
  initMappingsForDevice() {
    let mappingsSchema: Record<string, string> = {};
    Object.keys(this.devicesTypes[this.deviceData.data.type].mappings).forEach((item: string) => {
      mappingsSchema = { ...mappingsSchema, [item]: '' };
    });
    return mappingsSchema;
  }

  /**
   * Creates rules for device according to ShowEvent_errorWarningState field,
   * save ids of created rules in current device object
   * @param deviceData current device data object
   */
  async addRulesWhenCreateDevice(deviceData: IDevice) {
    if (!deviceData.data.mappings.ShowEvent_errorWarningState
        || !deviceData.data.mappings.ShowEvent_errorWarningState.length) return;
    const rulesList: any = getDefaultRulesObject(deviceData.name, deviceData.data.mappings.ShowEvent_errorWarningState, 'Device');
    if (this.project.id) {
      const res = await this.addRules({
        project_id: this.project.id,
        rulesList,
      });
      deviceData.data.meta.warningRule = res[0].id;
      deviceData.data.meta.errorRule = res[1].id;
    }
  }

  /**
   * Replacing existing rules with new ones when ShowEvent_errorWarningState field is changed
   * @param deviceData current device data object
   */
  async addRulesWhenEditDevice(deviceData: IDevice) {
    if (!this.project.id) return;
    const oldErrorWarningVar = this.deviceData.data.mappings.ShowEvent_errorWarningState;
    const newErrorWarningVar = deviceData.data.mappings.ShowEvent_errorWarningState;
    // if errorWarning was changed
    if (oldErrorWarningVar && !newErrorWarningVar) {
      await Promise.all([
        this.deleteRule({
          project_id: this.project.id,
          rule_id: deviceData.data.meta.warningRule,
        }),
        this.deleteRule({
          project_id: this.project.id,
          rule_id: deviceData.data.meta.errorRule,
        }),
      ]);
    }
    if (oldErrorWarningVar !== newErrorWarningVar && newErrorWarningVar) {
      // delete old rules
      if (oldErrorWarningVar.length) {
        await Promise.all([
          this.deleteRule({
            project_id: this.project.id,
            rule_id: deviceData.data.meta.warningRule,
          }),
          this.deleteRule({
            project_id: this.project.id,
            rule_id: deviceData.data.meta.errorRule,
          }),
        ]);
      }
      const rulesList: any = getDefaultRulesObject(deviceData.name, deviceData.data.mappings.ShowEvent_errorWarningState, 'Device');
      // create new rules
      const res: any = await this.addRules({
        project_id: this.project.id,
        rulesList,
      });
      const getWarningRule = () => {
        const warningRuleObj: any = res.find((rule: any) => rule.name === `${deviceData.name} Warning Rule`);
        return warningRuleObj.id;
      };
      const getErrorRule = () => {
        const errorRuleObj: any = res.find((rule: any) => rule.name === `${deviceData.name} Error Rule`);
        return errorRuleObj.id;
      };
      deviceData.data.meta.warningRule = getWarningRule();
      deviceData.data.meta.errorRule = getErrorRule();
    }
  }

  /**
   * Save device data in data base
   */
  async sendForm() {
    this.$emit('onFormUnchanged', true); // disable confirmation dialog when save
    const copy: IDevice = cloneDeep(this.localDeviceData);

    copy.data.meta.deviceSchema = { ...this.deviceSchema.devicesSchemas };
    copy.data.meta.minMaxBorders = this.deviceMinMaxBorders;
    copy.data.meta.selectedUsers = this.selectedUsers.map((user: { text: string; value: string }) => user.value);
    copy.data.meta.chargeID = this.chargeID;
    if (copy.data.meta.charge_id) {
      delete copy.data.meta.charge_id;
    }

    if (this.deviceSchema.unitSelection) {
      copy.data.meta.unit = this.unit;
    }

    if (this.deviceSchema.iconSelection) {
      const checkIsCoverExist = () => (this.localDeviceData.data?.meta?.cover ? this.localDeviceData.data?.meta?.cover : null);
      copy.data.meta.cover = this.iconObject ? this.iconObject.id : checkIsCoverExist();
      copy.data.meta.coverType = 'icon';
    }

    // add rules
    if (!this.isEditModal) {
      await this.addRulesWhenCreateDevice(copy);
    } else {
      await this.addRulesWhenEditDevice(copy);
    }

    this.$emit('handleControl', copy);
    this.$emit('closeDialog');
  }

  async created() {
    this.localDeviceData = cloneDeep(this.deviceData);
    if (this.activeRoomId.length && !this.isEditModal) this.localDeviceData.collection_id = this.activeRoomId;
    this.localDeviceData.data.mappings = this.initMappingsForDevice();
    this.localDeviceData.data.mappings = { ...this.localDeviceData.data.mappings, ...this.deviceData.data.mappings };
    if (!this.localDeviceData.data.meta) this.localDeviceData.data.meta = {};

    if (this.deviceSchema.unitSelection) {
      this.unit = this.localDeviceData.data.meta?.unit ? this.localDeviceData.data.meta.unit : '';
    }

    if (this.localDeviceData.data.meta?.selectedUsers) {
      // load selected users
      this.selectedUsers = this.localDeviceData.data.meta.selectedUsers.map((user: string) => {
        const member: any = this.members.find((el: IMember) => el.id === user);
        return { text: `${member.first_name} ${member.last_name}`, value: user };
      });
    }

    if (this.localDeviceData.data.meta?.chargeID) {
      this.chargeID = this.localDeviceData.data.meta.chargeID;
    }

    if (!this.isEditModal) this.dataSnapshot = cloneDeep(this.localDeviceData);
  }
}
