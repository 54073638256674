import { CirclePosition, EnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import { EnergyCircleType, isConsumer, isHybrid, isProducer } from '@/types/energyVisualisation/EnergyCircleType';
import { ArrowDirection, EnergyLineColors, EnergyLineDisplayData } from '@/types/energyVisualisation/EnergyLineDisplayData';
import {
  energyConsumerColors,
  energyGreyColors,
  energyHybridColors,
  energyProducerColors,
  energyHeatingColors,
  energyCoolingColors,
  energyGrey,
  energyRed,
  energyGreen,
  energyYellow,
  heatingRed,
  coolingBlue,
} from '../constants';

export function getDefaultSpinnerColor(type: EnergyCircleType, value?: number): string {
  if (!value) return energyGrey;

  if (type === EnergyCircleType.electric_heating && value > 0) {
    return energyGreen;
  }

  if (isProducer(type)) {
    return energyYellow;
  }
  if (isConsumer(type)) {
    return energyGreen;
  }

  // hybrid
  return value > 0 ? energyRed : energyGreen;
}

export function getDefaultSpinnerColorSetpointOptimizer(type: EnergyCircleType, value?: number): string {
  if (!value) return energyGrey;

  if (isProducer(type)) {
    return heatingRed;
  }
  if (isConsumer(type)) {
    return coolingBlue;
  }

  // hybrid
  return value > 0 ? heatingRed : coolingBlue;
}

export function getDefaultLineColors(type: EnergyCircleType, displayData: EnergyCircleDisplayData): EnergyLineColors {
  const value: any = displayData.bottomValue ?? displayData.alternativeValue;

  // used to force animation for consumers
  if (isConsumer(type) && displayData.additionalDisplayData?.forceAnimation) return energyConsumerColors;

  if (!value) {
    return energyGreyColors;
  }
  if (isProducer(type)) {
    return energyProducerColors;
  }
  if (isConsumer(type)) {
    return energyConsumerColors;
  }

  // hybrid
  if (value > 0) {
    return energyHybridColors;
  }
  if (value < 0) {
    return energyConsumerColors;
  }
  return energyGreyColors;
}

export function getDefaultLineColorsSetpointOptimizer(type: EnergyCircleType, displayData: EnergyCircleDisplayData): EnergyLineColors {

  if (displayData.bottomValue === 0) return energyGreyColors;

  if (isProducer(type)) {
    return energyHeatingColors;
  }
  if (isConsumer(type)) {
    return energyCoolingColors;
  }
  if (displayData.bottomValue !== undefined) {
    if (displayData.bottomValue > 0) {
      return energyHeatingColors;
    } else {
      return energyCoolingColors;
    }
  }
  return energyGreyColors;
}

export function getDefaultArrowDirection(type: EnergyCircleType, displayData: EnergyCircleDisplayData): ArrowDirection {
  const value: any = displayData.bottomValue ?? displayData.alternativeValue;

  // used to force animation for consumers
  if (isConsumer(type) && displayData.additionalDisplayData?.forceAnimation) return ArrowDirection.awayFromCenter;

  if (!value) {
    return ArrowDirection.none;
  }
  if (isProducer(type)) {
    return ArrowDirection.toCenter;
  }
  if (isConsumer(type)) {
    return ArrowDirection.awayFromCenter;
  }

  // hybrid
  if (value > 0) {
    return ArrowDirection.toCenter;
  }
  if (value < 0) {
    return ArrowDirection.awayFromCenter;
  }
  return ArrowDirection.none;
}

export function getDefaultArrowDirectionSetpointOptimizer(type: EnergyCircleType, displayData: EnergyCircleDisplayData): ArrowDirection {
  const value: any = displayData.bottomValue ?? displayData.alternativeValue;
  if (!value) {
    return ArrowDirection.none;
  }

  // hybrid
  if (value > 0) {
    if (isHybrid(type)) {
      return ArrowDirection.awayFromCenter;
    }
    return ArrowDirection.toCenter;
  }
  if (value < 0) {
    if (isHybrid(type)) {
      return ArrowDirection.toCenter;
    }
    return ArrowDirection.awayFromCenter;
  }
  return ArrowDirection.none;
}
