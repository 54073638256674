import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/store/types';
import { Map } from 'immutable';
import ProjectService from '@/services/ProjectService';
import i18n from '@/ui/plugins/i18n';
import { IProject, IProjectFilters, IProjectMQTTInfo } from '@/types/project.types';
import { BatteryType, deyeBatteryTypes } from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { loggerTypes } from '@/ui/components/wizards/loggerWizard/wizardSettings/systemTypes';
import { IProjectsState } from './types';
import { hybridVersionDate, plcVersionDate } from '@/utils/versionManagementUtils';

const state: IProjectsState = {
  projectId: null,
  projects: Map(),
  project: {},
  mqttInfo: {},
  projectLoader: false,
  projectsLoader: false,
  projectFilters: {
    projectName: '',
    projectStatus: '',
    projectTags: [],
    batterySystemType: '',
    systemType: '',
  },
};

const getters: GetterTree<IProjectsState, RootState> = {
  project(state: IProjectsState) {
    return state.project;
  },
  // returns project creation date in seconds
  projectCreationTimestamp(state: IProjectsState) {
    const projectCreationDate = new Date(state.project.created_at);
    return Math.trunc(projectCreationDate.getTime() / 1000);
  },
  projects(state: IProjectsState) {
    return state.projects.valueSeq().toJS();
  },
  startOfTheProject(state: IProjectsState) {
    return new Date(state.project.created_at).getTime();
  },
  projectStartedAt(state: IProjectsState) {
    return state.project.started_at;
  },
  projectStartedAtNumber(state: IProjectsState) {
    return new Date(state.project.started_at).getTime();
  },
  projectFilters(state: IProjectsState) {
    return state.projectFilters;
  },
  projectTagsLists(state, getters, rootState, rootGetters) {
    const tagSet = new Set();
    rootGetters['projects/projects'].forEach((currentProject: IProject) => {
      (currentProject?.meta?.tags ?? []).forEach((currentTag: string) => {
        tagSet.add(currentTag);
      });
    });

    return Array.from(tagSet);
  },
  projectsFilteredByTag(state, getters, rootState, rootGetters) {
    const projectList = rootGetters['projects/projects'];
    const filterTags = state.projectFilters.projectTags;
    if (!filterTags.length) return projectList;
    return projectList.filter((project: IProject) => {
      const projectTags = project?.meta?.tags ?? [];
      return filterTags.some((tag: string) => projectTags.includes(tag)); // if tagsForFilter is [], it will return true
    });
  },
  projectCurrency(state: IProjectsState) {
    return state.project.meta?.projectCurrency;
  },
  batterySystemType(state: IProjectsState): BatteryType | undefined {
    if (!state.project?.meta) return undefined;
    return state.project.meta.controller?.batterySystemType;
  },
  isDeye(_, getters) {
    return deyeBatteryTypes.includes(getters.batterySystemType);
  },
  isSolarmax(_, getters) {
    return getters.batterySystemType === BatteryType.SOLARMAX;
  },
  isLoggerSystem(_, getters) {
    return loggerTypes.includes(getters.batterySystemType);
  },
  isMobileDeye(_, getters) {
    return getters.batterySystemType === BatteryType.MobileBatteryDeye;
  },
};

const mutations: MutationTree<IProjectsState> = {
  setProjectId(state: IProjectsState, projectId: string) {
    state.projectId = projectId;
  },
  setProjects(state: IProjectsState, projects: Map<string, IProject>) {
    state.projects = projects;
  },
  setProject(state: IProjectsState, project: IProject) {
    state.project = project;
    if (!project.id) return;
    state.projectId = project.id;
  },
  setProjectFilter(state: IProjectsState, data: { type: keyof IProjectFilters; value: any }) {
    state.projectFilters[data.type] = data.value;
  },
  setMqttInfo(state: IProjectsState, info: IProjectMQTTInfo) {
    state.mqttInfo = info;
  },
  setProjectLoader(state: IProjectsState, payload: boolean) {
    state.projectLoader = payload;
  },
  setProjectsLoader(state: IProjectsState, payload: boolean) {
    state.projectsLoader = payload;
  },
};

const actions: ActionTree<IProjectsState, RootState> = {
  /**
   * Load project list, creates Map collection from them and set to project state
   * @param commit
   */
  async loadProjects({ commit }) {
    try {
      commit('setProjectsLoader', true);
      const data = await ProjectService.fetchProjects();
      const projects = (data as IProject[]).reduce((acc, cur) => {
        if (!cur.id) return acc;
        return acc.set(cur.id, cur);
      }, Map<string, IProject>());
      commit('setProjects', projects);
      this.commit('router/setHomeDataLoaded', true);
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    } finally {
      commit('setProjectsLoader', false);
    }
  },

  /**
   * Creates new project
   * @param commit
   * @param state
   * @param project
   */
  async createProject({ commit, state }, project) {
    try {
      const result = await ProjectService.createProject(project);
      commit('setProjects', state.projects.set(result.id, result));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.createProject'),
        value: true,
      }, { root: true });
      return result;
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Creates Copy of project
   * @param commit
   * @param state
   * @param project
   */
  async copyProject({ commit, state }, project) {
    try {
      const result = await ProjectService.createProject(project);
      commit('setProjects', state.projects.set(result.id, result));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.copyProject'),
        value: true,
      }, { root: true });
      return result;
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
      return null;
    }
  },

  /**
   * Delete selected project
   * @param commit
   * @param state
   * @param projectId
   */
  async deleteProject({ commit, state }, projectId: string) {
    try {
      await ProjectService.deleteProject(projectId);
      commit('setProjects', state.projects.remove(projectId));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.deleteProject'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Load project
   * @param commit
   * @param rootGetters
   * @param projectId
   */
  async loadProject({ commit, rootGetters }, projectId: string) {
    try {
      const project = await ProjectService.fetchProject(projectId);

      let continueWithoutSerialNumber = true;
      // check for serial number in backend
      if (plcVersionDate(project).getTime() > hybridVersionDate.getTime()) {
        const serialNumbers = rootGetters['serialNumber/serialNumbers'];
        const serialNumber = project.meta?.serial_number;
        if (serialNumber && serialNumbers.includes(serialNumber)) {
          continueWithoutSerialNumber = false;
        }
      }
      commit('setProjectId', project.id);
      commit('setProject', project);
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Updates selected project
   * @param commit
   * @param state
   * @param project
   */
  async updateProject({ commit, state }, project: IProject) {
    try {
      const res = await ProjectService.updateProject(project);
      commit('setProjects', state.projects.set(res.id, res));
      commit('setProject', res);
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.updateProject'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
  async resetQR({ commit, state }, projectId: string): Promise<void> {
    try {
      const res = await ProjectService.resetQR(projectId);
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.resetQR'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
  /**
   * Disables QR code so it can't be used for adding new users anymore
   */
  async disableQR({ commit, state }, projectId: string): Promise<void> {
    try {
      const res = await ProjectService.disableQR(projectId);
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.disableQrCode'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
};

export const projects: Module<IProjectsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
