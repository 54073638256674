
import { Vue, Component } from 'vue-property-decorator';
import TSGFrischwasser from '@/ui/components/devices/previews/devices/TSGFrischwasserPreview.vue';
import TSGBrauchwasser from '@/ui/components/devices/previews/devices/TSGBrauchwasserPreview.vue';
import TSGLadestationNotAus from '@/ui/components/devices/previews/devices/TSGLadestationNotAusPreview.vue';
import TSGModulLadestation from '@/ui/components/devices/previews/devices/TSGModulLadestationPreview.vue';
import { envDeviceLibraryCategories } from '@/utils/env';

@Component({
  components: {
    TSGFrischwasser,
    TSGBrauchwasser,
    TSGLadestationNotAus,
    TSGModulLadestation,
  },
})
export default class TSGView extends Vue {
  tab = null

  get devicesLib() {
    return [
      {
        name: 'TSG Devices',
        locale: 'uiComponents.devicesLibrary.tsgTabs.tsgDevices',
        data: [
          { device: 'TSGFrischwasser', sizes: { lg: 6, md: 12, sm: 12 } },
          { device: 'TSGBrauchwasser', sizes: { lg: 6, md: 12, sm: 12 } },
          { device: 'TSGLadestationNotAus', sizes: { lg: 3, md: 6, sm: 12 } },
          { device: 'TSGModulLadestation', sizes: { lg: 3, md: 6, sm: 12 } },
        ],
      },
    ];
  }

  get devicesLibFiltered() {
    return this.devicesLib.filter((e: any) => envDeviceLibraryCategories.TSG.includes(e.name));
  }
}
