
import { Vue, Component, Prop } from 'vue-property-decorator';
import RotatingBacklight from '@/ui/components/devices/components/EnergyParts/RotatingBacklight.vue';
import BatteryLevel from '@/ui/components/devices/components/EnergyParts/BatteryLevel.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import ShowEventBase from '@/ui/components/devices/devices/base/ShowEventBase.vue';
import { kiloWattUnit } from '@/utils/unit/unitConstants';
import Unit from '@/utils/unit/unit';

/**
 * Component that represent circle system view.
 * Used in EMS, EnergyView, Setpoint Optimizer.
 * Different from Preview.vue is has a flashing frame (ShowEventBase).
 */
@Component({
  components: {
    RotatingBacklight,
    BatteryLevel,
    OutputFieldBase,
    ShowEventBase,
  },
})
export default class PreviewMQTTControls extends Vue {
  @Prop({ default: null }) actualValue!: any; // {'OutputField_actualValue': variable}
  @Prop({ default: null }) actualValueMin!: number;
  @Prop({ default: null }) actualValueMax!: number;
  @Prop({ default: () => kiloWattUnit }) actualValueUnit!: Unit;
  @Prop({ default: true }) showActualValue!: boolean;
  @Prop({ default: false }) isBatterySOC!: boolean;
  @Prop({ default: null }) batterySOCValue!: number;
  @Prop({ default: null }) previewTitle!: string;
  @Prop({ default: 'top' }) previewTitlePosition!: string; // top, bottom
  @Prop({ default: 'photovoltaic' }) previewIcon!: string;
  @Prop({ default: '30' }) previewIconSize!: string | number;
  @Prop({ default: '#525252' }) rotatingBacklightColor!: string;
  @Prop({ default: null }) systemCount!: number;
  @Prop({ default: null }) errorWarning!: any; // {'ShowEvent_errorWarningState': variable}
  @Prop({ default: false }) noData!: boolean;

  get valueUnit() {
    return this.actualValueUnit?.unitForValue(this.actualValue);
  }
}
