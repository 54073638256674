
import { IAppState } from '@/store/modules/app/types';
import { IProject, IProjectFilters } from '@/types/project.types';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Getter, Mutation, State, Action } from 'vuex-class';

/**
 * Home page that shows projects
 */
@Component({
  components: {},
})
export default class ProjectChips extends Vue {
  @Prop({ default: false }) isInsideSettings?: boolean;
  @Prop({ default: false }) isInsideAppbar?: boolean;
  @State('app') appState!: IAppState;
  @Getter('projects/projects') projects!: IProject[];
  @Getter('projects/project') project!: IProject;
  @Getter('projects/projectTagsLists') projectTagsLists!: string[];
  @Getter('projects/projectFilters') projectFilters!: IProjectFilters;
  @Mutation('projects/setProjectFilter') setProjectFilter!: (data: { type: keyof IProjectFilters; value: any }) => any;
  @Action('projects/getProjectTagsLists') getProjectTagsLists!: (projectList: IProject[]) => string[];

  chips: string[] = [];

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  get projectFilterTags() {
    return this.projectFilters.projectTags;
  }

  @Watch('chips')
  handleChipsChange(value: string[]) {
    if (this.isInsideAppbar === true) {
      this.setProjectFilter({ type: 'projectTags', value });
    }
    this.$emit('selectedChips', value);
  }

  mounted() {
    // add Project specific Tags that are inside the Project Meta when entering General Settings
    if (this.isInsideSettings) {
      this.chips = this.project.meta?.tags ? [...this.project.meta.tags] : [];
    }
    // add selected tags again after returning back to Home Page from any Project
    if (this.isInsideAppbar) {
      this.chips = this.projectFilterTags;
    }
  }

  removeChip(item: string) {
    this.chips.splice(this.chips.indexOf(item), 1);
  }
}
