
import { Vue, Component, Prop } from 'vue-property-decorator';
import EnergyFlowProductionChart
  from '@/ui/components/devices/previews/mpc/EMS/charts/EnergyFlow/EnergyFlowProductionChart.vue';
import EnergyFlowConsumptionChart
  from '@/ui/components/devices/previews/mpc/EMS/charts/EnergyFlow/EnergyFlowConsumptionChart.vue';
import EnergyFlowConsumerChart from '@/ui/components/devices/previews/mpc/EMS/charts/EnergyFlow/EnergyFlowConsumerChart.vue';

@Component({
  components: {
    EnergyFlowProductionChart,
    EnergyFlowConsumptionChart,
    EnergyFlowConsumerChart,
  },
})
export default class EnergyFlow extends Vue {
  @Prop() scaling!: any;

  tab = null

  get items() {
    return [
      { title: this.$t('mlModel.EMS.charts.chartDifference.energyFlow'), view: 'EnergyFlowProductionChart' },
      { title: this.$t('mlModel.EMS.charts.chartDifference.energyFlowConsumer'), view: 'EnergyFlowConsumerChart' },
    ];
  }
}
