
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';

/**
 * Component that represent Pause Basic Control
 */
@Component({
  components: { FeedbackProvider },
})
export default class PauseBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop({ default: '#7c7c7c' }) color!: string;
  @Prop({ default: 'pause' }) icon!: string;

  get state() {
    if (this.mappingsClean) {
      return !!this.measurements.get(this.mappingsClean.state);
    }
  }

  /**
   * Used to disable the Base component if there is no variable set in the mappings of the device.
   */
  get isNotMapped() {
    return this.mappingsClean.commandPause === '';
  }

  down() {
    this.send([{ v: 1, n: this.mappingsClean.commandPause, u: '' }]);
  }
  up() {
    this.onSendStart();
    this.send([{ v: 0, n: this.mappingsClean.commandPause, u: '' }]);
    this.onSendSuccess();
  }
}
