
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { Component } from 'vue-property-decorator';
import { minMaxValidation } from '@/utils/utilsFunctions';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import { setVariableValuesWithMeasurements } from '@/utils/installationWizardUtilsFunctions';
import { cloneDeep } from 'lodash';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { IMQTTVariable } from '@/types/wizards/installationWizard.types';

const hourOptions = { min: 0, max: 23 };
const minutesOptions = { min: 0, max: 59 };

@Component({
  methods: { minMaxValidation },
  components: {
    WizardContentView,
    InfoTooltip,
  },
})
export default class GeneratorSettings extends WizardComponent {
  valid = false;
  disableTimeLockSettings = true;
  doesHover = false;

  variablesOptions: any = {
    'HourOn': hourOptions,
    'MinutesOn': minutesOptions,
    'HourOff': hourOptions,
    'MinutesOff': minutesOptions,
  };

  socVariables: Map<string, IMQTTVariable> = new Map([
    [
      'EnableSOC',
      {
        variable: 'prgGEN.fbGen.stSetupGenerator.byEnableSOC',
        value: 10,
      },
    ],
    [
      'DisableSOC',
      {
        variable: 'prgGEN.fbGen.stSetupGenerator.byDisableSOC',
        value: 90,
      },
    ],
  ]);
  socFieldsLimits = {
    'EnableSOC': {
      min: 0,
      max: 100,
    },
    'DisableSOC': {
      min: 0,
      max: 95,
    },
  }
  lockTimeVariables: Map<string, IMQTTVariable> = new Map([
    [
      'LockTime',
      {
        variable: 'prgGEN.fbGen.stSetupGenerator.bLockTime',
        value: 0,
        feedbackVariable: 'prgGEN.fbGen.stDataGenerator.bSLockTime',
        isBoolean: true,
      },
    ],
  ]);
  timeVariables: Map<string, IMQTTVariable> = new Map([
    [
      'HourOn',
      {
        variable: 'prgGEN.fbGen.stSetupGenerator.byHourOn',
        value: 0,
      },
    ],
    [
      'MinutesOn',
      {
        variable: 'prgGEN.fbGen.stSetupGenerator.byMinutesOn',
        value: 0,
      },
    ],
    [
      'HourOff',
      {
        variable: 'prgGEN.fbGen.stSetupGenerator.byHourOff',
        value: 0,
      },
    ],
    [
      'MinutesOff',
      {
        variable: 'prgGEN.fbGen.stSetupGenerator.byMinutesOff',
        value: 0,
      },
    ],
  ]);

  get socValidation(): boolean | string {
    const enableSocValue = this.socVariables.get('EnableSOC')?.value ?? 0;
    const disableSocValue = this.socVariables.get('DisableSOC')?.value ?? 1;
    // SOC is not correct
    if (enableSocValue >= disableSocValue) {
      return this.$t('installationWizard.defineEmsSettings.generatorSettings.socValidation').toString();
    }
    // SOC is correct
    return true;
  }

  handleSocChange() {
    this.socVariables = cloneDeep(this.socVariables);
  }

  handleLockTimeChange(value: any) {
    this.disableTimeLockSettings = !value;
  }

  get lockTime() {
    return this.lockTimeVariables.get('LockTime') as IMQTTVariable;
  }

  prepareVariablesToSend(): void {
    this.variablesToSend = new Map([...this.socVariables, ...this.lockTimeVariables, ...this.timeVariables]);
  }

  getVariableValuesFromMeasurements() {
    // sets all soc & time varibles
    this.socVariables = setVariableValuesWithMeasurements(this.socVariables, this.measurements);
    this.timeVariables = setVariableValuesWithMeasurements(this.timeVariables, this.measurements);

    const defaultMaxPowerValue = this.lockTimeVariables.get('LockTime')?.value;
    const variableName: any = this.lockTimeVariables.get('LockTime')?.variable;
    const currentMaxPowerValue = this.measurements[variableName];
    if (defaultMaxPowerValue !== currentMaxPowerValue) {
      this.lockTimeVariables.set('LockTime', {
        variable: variableName,
        value: currentMaxPowerValue,
      });
    }
  }

  async created() {
    if (this.wasInstallationWizardCompleted) {
      this.getVariableValuesFromMeasurements();
    }
  }

  async mounted() {
    await this.$nextTick();
    (this.$refs.form as any).validate();
  }
}
