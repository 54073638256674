
import { Component, Prop } from 'vue-property-decorator';
import SwitchBase from '@/ui/components/devices/devices/base/SwitchBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import MinusBase from '@/ui/components/devices/devices/base/MinusBase.vue';
import PlusBase from '@/ui/components/devices/devices/base/PlusBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent ThermostatDigital device
 */
@Component({
  components: {
    SwitchBase,
    OutputFieldBase,
    InputFieldBase,
    ShowEventDotBase,
    MinusBase,
    PlusBase,
    DeviceLayout,
    LabelUnitWrapper,
  },
})
export default class ThermostatDigital extends mixins(Validation) {
  @Prop() deviceData!: IDevice;
  @State('measurements') measurementsState!: IVariablesState;

  get langPath() {
    return `devices.${this.deviceData.data.type}`;
  }
  get measurements() {
    return this.measurementsState.measurements;
  }

  get isActualValueVariableFilled() {
    return !!this.deviceData.data.mappings.OutputField_actualValue.length;
  }
}
