
import { Vue, Component, Prop } from 'vue-property-decorator';
import SwitchBase from '@/ui/components/devices/devices/base/SwitchBase.vue';
import SliderBase from '@/ui/components/devices/devices/base/SliderBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import SliderRGBBase from '@/ui/components/devices/devices/base/SliderRGBBase.vue';
import SliderRGBCanvas from '@/ui/components/devices/devices/base/SliderRGBCanvas.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent LightRGB device
 */
@Component({
  components: {
    SwitchBase,
    SliderBase,
    OutputFieldBase,
    SliderRGBBase,
    SliderRGBCanvas,
    DeviceLayout,
  },
})
export default class LightRGB extends Vue {
  @Prop() deviceData!: IDevice;
  @State('measurements') measurementsState!: IVariablesState;

  get measurements() {
    return this.measurementsState.measurements;
  }
  get isActive() {
    return !!this.measurements.get(this.deviceData.data.mappings.Switch_state);
  }
  get sliderRGBActualValue() {
    const r = this.measurements.get(this.deviceData.data.mappings.OutputField2_actualValue);
    const g = this.measurements.get(this.deviceData.data.mappings.OutputField3_actualValue);
    const b = this.measurements.get(this.deviceData.data.mappings.OutputField4_actualValue);
    return { r, g, b };
  }
  get sliderActualValue() {
    return this.measurements.get(this.deviceData.data.mappings.OutputField1_actualValue);
  }
}
