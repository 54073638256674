
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Mutation, State } from 'vuex-class';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { cloneDeep } from 'lodash';
import { ITenantModel } from '@/types/wizards/tenant.types';

@Component({
  components: {
    WizardContentView,
    InfoTooltip,
  },
})
export default class BasicBillingInformation extends mixins(Validation) {
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Mutation('tenantWizard/updateTenantObject') updateTenantObject!: (data: ITenantModel) => void;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;
  @Action('app/postAsset') postAsset!: (asset: any) => Promise<any>;

  currentImage: any = ''
  headerText = '';
  ownerName = '';
  ownerValid = false;
  providerName = '';
  contractCountry = '';
  ownerAddress= {
    street: '',
    zip: '',
    city: '',
    country: '',
  };
  ownerMobile = '';
  ownerMail = '';
  ownerRegistrationNumber = '';

  customToolbar = [
    [{ 'header': [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'indent': '-1' }, { 'indent': '+1' }],
  ];

  get contractCountryItems() {
    return [
      { text: this.$t('tenantWizard.contractCountryItems.DE'), value: 'Germany' },
      { text: this.$t('tenantWizard.contractCountryItems.CH'), value: 'Switzerland' },
    ];
  }

  triggerValidation() {
    (this.$refs.form as any).validate();
  }

  handleNext() {
    this.updateTenant();
    this.handleIncrement();
  }

  /**
   * gets al data from the store and sets it to the local variables
   */
  created() {
    if (this.tenantState.tenant.header.providerName !== '') {
      this.headerText = this.tenantState.tenant.header.headerText;
      this.currentImage = this.tenantState.tenant.header.image_Id;
      this.providerName = this.tenantState.tenant.header.providerName;
      this.ownerAddress = this.tenantState.tenant.header.ownerAddress;
      this.contractCountry = this.tenantState.tenant.header.contractCountry;
      this.ownerMail = this.tenantState.tenant.header.ownerMail;
      this.ownerMobile = this.tenantState.tenant.header.ownerMobile;
      this.ownerName = this.tenantState.tenant.header.ownerName;
      this.ownerRegistrationNumber = this.tenantState.tenant.header.ownerRegistrationNumber;
    }
  }

  mounted() {
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  updateTenant() {
    const tenantCopy = cloneDeep(this.tenantState.tenant);
    tenantCopy.header.headerText = this.headerText;
    tenantCopy.header.image_Id = this.currentImage;
    tenantCopy.header.providerName = this.providerName;
    tenantCopy.header.ownerAddress = this.ownerAddress;
    tenantCopy.header.contractCountry = this.contractCountry;
    tenantCopy.header.ownerMail = this.ownerMail;
    tenantCopy.header.ownerMobile = this.ownerMobile;
    tenantCopy.header.ownerName = this.ownerName;
    tenantCopy.header.ownerRegistrationNumber = this.ownerRegistrationNumber;
    this.updateTenantObject(tenantCopy);
  }
}
