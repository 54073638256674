
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent StatusIndicator of the project
 * Used in AppBar.vue
 * Shows current mqtt connection
 */
@Component
export default class StatusIndicator extends Vue {
  @Prop(Boolean) status!: boolean;
}
