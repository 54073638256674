
import { Vue, Component } from 'vue-property-decorator';
import SimpleChart from '@/ui/components/devices/previews/components/ChartsTypes/SimpleChart.vue';

@Component({
  components: {
    SimpleChart,
  },
})
export default class ForecastMock extends Vue {
  tab: any = null
  fullscreen = false

  tempValveYAxis = [
    {
      title: null,
      opposite: false,
      showFirstLabel: true,
      showLastLabel: true,
      endOnTick: false,
      gridLineWidth: 0,
      min: 0,
      max: 100,
      lineColor: '#3450ae',
      labels: {
        format: '{value} °C',
        style: {
          color: '#3450ae',
        },
      },
    },
    {
      title: null,
      opposite: false,
      showFirstLabel: true,
      showLastLabel: true,
      endOnTick: false,
      gridLineWidth: 0,
      min: 0,
      max: 100,
      lineColor: '#50ab7e',
      labels: {
        format: '{value}',
        style: {
          color: '#50ab7e',
        },
      },
    },
  ]
  weatherYAxis = [
    {
      title: null,
      opposite: false,
      showFirstLabel: true,
      showLastLabel: true,
      endOnTick: false,
      gridLineWidth: 0,
      min: -20,
      max: 40,
      lineColor: '#ab5050',
      labels: {
        format: '{value} °C',
        style: {
          color: '#ab5050',
        },
      },
    },
    {
      title: null,
      opposite: false,
      showFirstLabel: true,
      showLastLabel: true,
      endOnTick: false,
      gridLineWidth: 0,
      min: 0,
      max: 1500,
      lineColor: '#ab8a50',
      labels: {
        format: '{value} W/m2',
        style: {
          color: '#ab8a50',
        },
      },
    },
  ]

  get tempValveSeries() {
    const lang: any = this.$t(`${this.settingsViewLang}.chartLabels.tempValve`);
    return [
      {
        name: lang.valvePosition,
        type: 'line',
        yAxis: 0,
        color: '#3450ae',
        data: [
          [1583100000000, 23],
          [1583103600000, 26],
          [1583107200000, 22],
          [1583110800000, 28],
          [1583114400000, 28],
          [1583118000000, 29],
          [1583121600000, 29],
        ],
      },
      {
        name: lang.roomTemp,
        type: 'line',
        yAxis: 1,
        color: '#50ab7e',
        data: [
          [1583100000000, 22],
          [1583103600000, 25],
          [1583107200000, 22],
          [1583110800000, 23],
          [1583114400000, 24],
          [1583118000000, 28],
          [1583121600000, 29],
        ],
      },
    ];
  }
  get weatherSeries() {
    const lang: any = this.$t(`${this.settingsViewLang}.chartLabels.weather`);
    return [
      {
        name: lang.air,
        type: 'line',
        yAxis: 0,
        color: '#ab5050',
        data: [
          [1583100000000, 20],
          [1583114400000, 25],
          [1583128800000, 22],
          [1583143200000, 22],
          [1583157600000, 22],
          [1583172000000, 27],
          [1583186400000, 30],
        ],
      },
      {
        name: lang.globalRadiation,
        type: 'line',
        yAxis: 1,
        color: '#ab8a50',
        data: [
          [1583100000000, 20],
          [1583114400000, 25],
          [1583128800000, 22],
          [1583143200000, 22],
          [1583157600000, 22],
          [1583172000000, 27],
          [1583186400000, 30],
        ],
      },
    ];
  }

  get settingsViewLang() {
    return 'mlModel.HeatingCircuitOptimization.settingsView';
  }

  get tabs() {
    return [
      {
        title: 'Temp/Valve',
        locale: `${this.settingsViewLang}.forecastTabs`,
        component: 'SimpleChart',
        chartType: 'line',
        source: 'endpoint 1',
        chartWidth: null,
        series: this.tempValveSeries,
        yAxis: this.tempValveYAxis,
      },
      {
        title: 'Weather',
        locale: `${this.settingsViewLang}.forecastTabs`,
        component: 'SimpleChart',
        chartType: 'line',
        source: 'endpoint 2',
        chartWidth: null,
        series: this.weatherSeries,
        yAxis: this.weatherYAxis,
      },
    ];
  }
}
