
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class InputFieldMock extends Vue {
  @Prop() min!: number;
  @Prop() max!: number;
  @Prop({ default: true }) isSubmitButton!: boolean;
  @Prop({ default: '#fff' }) background!: string;
  @Prop({ default: false }) small!: boolean;
  @Prop({ default: null }) initState!: any;
  @Prop({ default: () => {} }) descriptionStyles!: any;
  @Prop({ default: () => {} }) inputFieldStyles!: any;
  @Prop({ default: () => {} }) unitStyles!: any;
  @Prop({ default: () => {} }) iconStyles!: any;

  state = 20

  mounted() {
    if (this.initState) {
      this.state = this.initState;
    }
  }
}
