
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import CalculationAggregationWindow from '@/ui/components/modals/ManageChart/CalculationAggregationWindow.vue';
import { canBeParsed, getVariableCount, getVariableNames } from '@/ui/components/devices/charts/charts/ChartMath';
import { Mentionable } from 'vue-mention';
import { clone } from 'lodash';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';

/**
 * Expression Text Area component
 */
@Component({
  components: {
    CalculationAggregationWindow,
    Mentionable,
  },
})
export default class ExpressionTextArea extends mixins(Validation) {
  @Prop() expressionVariable !: any;
  @State('variables') variablesState!: any;
  @Getter('measurements/measurementsKeys') measurementsKeys!: any;

  listItems: any[] = [];
  doesHover = false;
  localExpressionVariable: string = clone(this.expressionVariable);

  rulesForExpressionField = {
    variableNameRule: () => this.checkVariableNames(),
    parenthesisRule: () => this.checkParenthesis(),
    allowedCharsRule: () => this.checkAllowedChars(),
    variableCountRule: () => this.checkVariableCount(),
    generalEpressionRule: () => this.checkGeneralExpression() || this.$t('modals.manageCharts.form.errorMessagesExpression.checkGeneralExpressionError'),
  }

  @Watch('expressionVariable')
  onExpressionVariableChange() {
    this.localExpressionVariable = clone(this.expressionVariable);
  }

  setFocusOnField() {
    setTimeout(() => {
      (this.$refs.textArea as any).focus();
    }, 100);
  }

  get allChecksOk() {
    return this.checkNotNull() === true
      && this.checkParenthesis() === true
      && this.checkAllowedChars() === true
      && this.checkVariableCount() === true
      && this.checkGeneralExpression() === true
      && this.checkVariableNames() === true;
  }

  /**
   * if there is an Error in the Expression Field the status gets emited to disable next button
   * @param allOk status of check
   */
  @Watch('allChecksOk')
  changeErrorHandling(allOk: boolean) {
    this.$emit('changeDisable', { val: !allOk });
  }

  async created() {
    const testCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    // make list of {value: <variablename>, label: <variablename>} out of measurementKeys list
    this.listItems = this.variablesState.variables
      // filter elements that start with number
      .filter((e: any) => !testCharacters.includes(e.variable.charAt(0)))
      .map((e: any) => {
        if (e.alias !== null) {
          return { value: e.variable, label: `${e.variable}  (${e.alias})` };
        } else {
          return { value: e.variable, label: e.variable };
        }
      });

    if (!this.checkNotNull) {
      this.$emit('changeDisable', { val: true });
    }

    await this.$nextTick();
    (this.$refs.textArea as any).validate(true);
  }

  /**
   * returns the new Math Expression to the childcomponent (ManageChartsCalculation)
   */
  emitChanges() {
    this.$emit('expressionChange', {
      varNames: getVariableNames(this.localExpressionVariable),
      fullExpression: this.localExpressionVariable,
    });
  }

  /**
   * Checks if variable names exist and are allowed to be used in expression.
   */
  checkVariableNames(): boolean | string {
    if (this.localExpressionVariable === '') return true;

    const variableNames = getVariableNames(this.localExpressionVariable);
    const availableVariables = this.listItems.map(e => e.value);

    for (let v of variableNames) {
      if (!availableVariables.includes(v)) {
        return `${v} ${this.$t('modals.manageCharts.form.errorMessagesExpression.variableNameError')}`;
      }
    }
    return true;
  }

  /**
   * Checks if the expression's parenthesis setting is correct.
   */
  checkParenthesis(): any {
    let count = 0;
    this.localExpressionVariable.split('').forEach(c => {
      if (c === '(') {
        count++;
      }
      if (c === ')') {
        count--;
      }
    });

    if (count > 0) {
      return this.$t('modals.manageCharts.form.errorMessagesExpression.parenthesisErrorNegativeValue');
    } else if (count < 0) {
      return this.$t('modals.manageCharts.form.errorMessagesExpression.parenthesisErrorPositiveValue');
    } else {
      return true;
    }
  }

  /**
   * Checks if expression only contains allowed chars.
   */
  checkAllowedChars(): boolean | string {
    const notAllowedChars = this.localExpressionVariable.match(/[^\w\.\,@\s\+\-\*\/\(\)]/g);
    return notAllowedChars === null || `${this.$t('modals.manageCharts.form.errorMessagesExpression.checkAllowedCharsError')} ${notAllowedChars}`;
  }

  /**
   * Counts the number of variables appearing in the expression.
   */
  checkVariableCount(): boolean | string {
    const variableCount = getVariableCount(this.localExpressionVariable);

    return variableCount <= 10 || `${variableCount} ${this.$t('modals.manageCharts.form.errorMessagesExpression.checkVariableCount')}`;
  }

  /**
   * Checks if the expression can be parsed.
   */
  checkGeneralExpression(): boolean {
    return canBeParsed(this.localExpressionVariable);
  }

  checkNotNull() {
    return this.localExpressionVariable !== '' && this.localExpressionVariable !== undefined;
  }
}
