
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import DaySelectionModal from '@/ui/components/partner/components/DayRangeSelectionModal.vue';
import { Validation } from '@/ui/mixins/validation';
import { ITasksObject } from '@/types/partnerWorkspace.types';
import { formatIsoDate } from '@/utils/utilsFunctions';

/**
 * Component that allows to create a new Task for the gantt chart
 */
@Component({
  methods: { formatIsoDate },
  components: {
    ModalWindow,
    DaySelectionModal,
  },
})
export default class ManageTask extends mixins(Validation) {
  @Prop({ default: false }) handleAsEdit!: boolean;
  @Prop({ default: [] }) tasks!: ITasksObject[];
  @Prop({ default: undefined }) task!: ITasksObject;

  selectedTask: ITasksObject = {
    id: '',
    name: '',
    start: '',
    end: '',
    progress: 0,
    dependencies: [],
  };
  valid = false;
  availableDependencies: {value: string; text: string}[] = [];
  selectedDependencies: {value: string; text: string}[] = [];
  dateSelected = false;

  get currentLang() {
    return this.$i18n.locale;
  }

  async onDialogStatusChange(status: boolean) {
    if (status) {
      if (this.tasks.length > 0) {
        this.availableDependencies = this.tasks.map((task) => {
          return { value: task.id, text: task.name };
        });
      }
      // if task is given, this means that the user wants to edit the task
      if (this.task) {
        this.selectedTask = this.task;
        // map selected dependencies
        // creates array (key : task_id , value: task_name) for the user to select from the given list of task id's listed in the selected task
        const currentList: {value: string; text: string}[] = [];
        this.task.dependencies.forEach((dependency) => {
          const found = this.availableDependencies.find((obj: {value: string; text: string}) => obj.value === dependency);
          if (found) {
            currentList.push({ value: found.value, text: found.text });
          }
        });
        this.selectedDependencies = currentList;

        // remove the task itself from the list of dependencies
        this.availableDependencies = this.availableDependencies.filter((dependency) => dependency.value !== this.task.id);
        this.$emit('passEditId', this.task.id); // pass the id of the task to the parent component
      }

      if (this.handleAsEdit) {
        this.dateSelected = true;
      }
      await this.$nextTick();
      this.validate();
    } else {
      this.resetVariables();
    }
  }

  @Watch('currentLang', { deep: true })
  handleLangChange() {
    this.validate();
  }

  validate() {
    (this.$refs.form as any)?.validate();
  }

  // contains current date string to set max date for calendar
  get maxDateCalendar() {
    return new Date().toDateString();
  }

  handleDateSelected(date: { start: string; end: string }) {
    this.selectedTask.start = date.start;
    this.selectedTask.end = date.end;
    this.dateSelected = true;
  }

  removeItem(item: {value: string; text: string}) {
    this.selectedDependencies.splice(this.selectedDependencies.indexOf(item), 1);
  }

  handleClick() {
    const objectToEmit = {
      ...this.selectedTask,
      id: this.selectedTask.name + this.selectedTask.start,
      dependencies: this.selectedDependencies.map((dependency) => dependency.value), // map dependencies to an array of strings containing the id's of the dependencies
    };
    this.$emit('taskCreated', objectToEmit);
    this.resetVariables();
    (this.$refs.ModalWindowInstance as ModalWindow).onConfirmationDialogClose();
  }

  resetVariables() {
    this.selectedTask = {
      id: '',
      name: '',
      start: '',
      end: '',
      progress: 0,
      dependencies: [],
    };
    this.selectedDependencies = [];
    this.dateSelected = false;
  }
}
