
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

/**
 * Component that represent custom input field number type.
 * Highlight error border if value not valid.
 * Example how to use:
 * <InputFieldExternal
 *   v-model="form_field"
 *   :step="0.1"
 * >
 */
@Component
export default class InputFieldExternal extends Vue {
  @Prop() value!: any;
  @Prop({ default: false }) isDecimal?: boolean;
  @Prop({ default: false }) disabled?: boolean;
  @Prop({ default: 0.01 }) step?: number;
  @Prop({ default: 0 }) min?: number;
  @Prop({ default: 100 }) max?: number;
  @Prop({ default: () => [] }) fieldRules?: any;
  @Prop({ default: '#fff' }) fieldBackground!: string;
  @Prop({ default: 36 }) height!: number | string;
  @Prop({ default: () => {} }) descriptionStyles!: any;
  @Prop({ default: () => {} }) inputFieldStyles!: any;
  @Prop({ default: () => {} }) unitStyles!: any;
  @Prop({ default: () => {} }) iconStyles!: any;
  @Prop({ default: true }) isDescriptionShown!: boolean;
  @Prop({ default: true }) isUnitShown!: boolean;
  @Prop({ default: true }) isIconShown!: boolean;

  state: any = 24
  valid = true
  @Watch('actualValue')
  onActualValueChange(val: any) {
    if (typeof val === 'number') this.state = val.toFixed(2);
  }

  get actualValue(): any {
    return 22;
  }

  get isOnlyInputFieldShown() {
    return !this.isIconShown && !this.isDescriptionShown && !this.isUnitShown;
  }

  validate() {
    (this.$refs.form as any).validate();
    this.handleState();
  }

  /**
   * Blocks invalid keys
   * @param {object} e event object
   */
  handleKeypress(e: any) {
    const isDecimalSymbol = /[0-9]|,/.test(e.key);
    const isInteger = /[0-9]/.test(e.key);
    if (this.isDecimal && !isDecimalSymbol) e.preventDefault();
    if (!this.isDecimal && !isInteger) e.preventDefault();
  }

  @Watch('currentLang', { deep: true })
  handleLangChange() {
    this.validate();
  }

  get currentLang() {
    return this.$i18n.locale;
  }

  handleState() {
    if (!this.valid) return null;
  }

  mounted() {
    (this.$refs.form as any).validate();
    this.state = this.actualValue;
  }
}
