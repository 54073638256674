
import { IWizardPage } from '@/types/wizards/wizard.general.types';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class InstallationSteps extends Vue {
  @Prop() name!: string;
  @Prop({ default: 0 }) activePage!: number;
  @Prop({ default: 1 }) activeStep!: number;
  @Prop() stepsCount!: number;
  @Prop() wizardPages!: IWizardPage[];

  steps = Array.from(Array(this.stepsCount).keys()).map((step: number) => step + 1);

  get subSteps() {
    return this.wizardPages.filter(
      (page: IWizardPage) => page.step === this.activeStep,
    );
  }

  get activeSubStepIndex() {
    const activePage = this.wizardPages[this.activePage];
    return this.subSteps.findIndex(subStep => subStep.page === activePage.page);
  }

  get currentPageIndex() {
    return this.wizardPages[this.activePage].page;
  }

  /**
   * Checks if any page is available for the current step
   * yes -> show the step
   * no -> hide the step
   */
  showStep(stepIndex: number) {
    const currentItems: any = this.wizardPages.filter(
      (page: IWizardPage) => page.step === stepIndex,
    ) ?? [];
    return currentItems.length > 0;
  }
}
