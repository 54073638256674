export const circlesDisplayDataMock = [
  {
    'id': 'pv',
    'bottomValue': (Math.random() * 99 + 1).toFixed(1),
    'centerContent': {
      'type': 'icon',
      'value': 'photovoltaic',
    },
    'position': 0,
    'systemCount': 2,
    'spinnerColor': '#f3db04',
  },
  {
    'id': 'chp',
    'bottomValue': (Math.random() * 99 + 1).toFixed(1),
    'centerContent': {
      'type': 'icon',
      'value': 'chp',
    },
    'position': 0,
    'systemCount': 1,
    'spinnerColor': '#f3db04',
  },
  {
    'id': 'generator',
    'bottomValue': (Math.random() * 99 + 1).toFixed(1),
    'centerContent': {
      'type': 'icon',
      'value': 'generator',
    },
    'position': 0,
    'systemCount': 2,
    'spinnerColor': '#f3db04',
  },
  {
    'id': 'grid',
    'bottomValue': (Math.random() * 99 + 1).toFixed(1),
    'centerContent': {
      'type': 'icon',
      'value': 'main-connection',
    },
    'position': 2,
    'systemCount': 1,
    'spinnerColor': '#fc0328',
  },
  {
    'id': 'battery',
    'bottomValue': (Math.random() * 99 + 1).toFixed(1),
    'centerContent': {
      'type': 'soc',
      'value': 1,
    },
    'position': 1,
    'systemCount': 2,
    'spinnerColor': '#fc0328',
    'additionalDisplayData': { forceCharge: 1, calibrationCharge: 0, maintenanceCharge: 0, chargeMaintenance: 0, feedInLimitationActive: 0, p14aActive: 0, activeEmsMode: 1 },
  },
  {
    'id': 'house',
    'bottomValue': (Math.random() * 99 + 1).toFixed(1),
    'centerContent': {
      'type': 'icon',
      'value': 'house-consumption',
    },
    'position': 3,
    'systemCount': 1,
    'spinnerColor': '#29810e',
  },
  {
    'id': 'charge_station',
    'bottomValue': (Math.random() * 99 + 1).toFixed(1),
    'centerContent': {
      'type': 'icon',
      'value': 'electric-charging-station',
    },
    'position': 3,
    'systemCount': 2,
    'spinnerColor': '#29810e',
  },
  {
    'id': 'electric_heating',
    'centerContent': {
      'type': 'icon',
      'value': 'electric-charging-boiler',
    },
    'position': 3,
    'systemCount': 2,
    'spinnerColor': '#29810e',
    'bottomValue': (Math.random() * 99 + 1).toFixed(1),
  },
  {
    'id': 'heating_pump',
    'centerContent': {
      'type': 'icon',
      'value': 'heating-capacity',
    },
    'position': 3,
    'systemCount': 2,
    'spinnerColor': '#29810e',
    'bottomValue': (Math.random() * 99 + 1).toFixed(1),
  },
  {
    'id': 'big_consumer',
    'bottomValue': (Math.random() * 99 + 1).toFixed(1),
    'centerContent': {
      'type': 'icon',
      'value': 'socket-switch',
    },
    'position': 3,
    'systemCount': 2,
    'spinnerColor': '#29810e',
  },
];

export const linesDisplayDataMock = [
  {
    'id': 'pv',
    'arrowDirection': 1,
    'colors': {
      'colorAtCenter': '#2BAAE2',
      'colorAtCircle': '#f3db04',
    },
  },
  {
    'id': 'chp',
    'arrowDirection': 1,
    'colors': {
      'colorAtCenter': '#2BAAE2',
      'colorAtCircle': '#f3db04',
    },
  },
  {
    'id': 'generator',
    'arrowDirection': 1,
    'colors': {
      'colorAtCenter': '#2BAAE2',
      'colorAtCircle': '#f3db04',
    },
  },
  {
    'id': 'grid',
    'arrowDirection': 1,
    'colors': {
      'colorAtCenter': '#2BAAE2',
      'colorAtCircle': '#fc0328',
    },
  },
  {
    'id': 'battery',
    'arrowDirection': 1,
    'colors': {
      'colorAtCenter': '#2BAAE2',
      'colorAtCircle': '#fc0328',
    },
  },
  {
    'id': 'house',
    'arrowDirection': 2,
    'colors': {
      'colorAtCenter': '#2BAAE2',
      'colorAtCircle': '#29810e',
    },
  },
  {
    'id': 'charge_station',
    'arrowDirection': 2,
    'colors': {
      'colorAtCenter': '#2BAAE2',
      'colorAtCircle': '#29810e',
    },
  },
  {
    'id': 'electric_heating',
    'arrowDirection': 2,
    'colors': {
      'colorAtCenter': '#2BAAE2',
      'colorAtCircle': '#29810e',
    },
  },
  {
    'id': 'heating_pump',
    'arrowDirection': 2,
    'colors': {
      'colorAtCenter': '#2BAAE2',
      'colorAtCircle': '#29810e',
    },
  },
  {
    'id': 'big_consumer',
    'arrowDirection': 2,
    'colors': {
      'colorAtCenter': '#2BAAE2',
      'colorAtCircle': '#29810e',
    },
  },
];
