
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { IAnomalyDetectionState } from '@/store/modules/anomalyDetection/types';
import { IVariablesState } from '@/store/modules/measurements/types';
import { envUserRolesAccessBlockList } from '@/utils/env';
import _ from 'lodash';
import { IChartsState } from '@/store/modules/charts/types';
import { IProject } from '@/types/project.types';
import { IMLModel, IMLModelType, IMLModelTypes } from '@/types/mpc/mpc.types';
import { IDevicesTypes, IDeviceType, IDevice } from '@/types/devices.types';
import { ActionsSchemasListItem } from '@/types/common.types';
import { CanAccessCheck, IMember } from '@/types/members.types';

enum DeviceAction {
  addToFavorites = 'addToFavorites',
  deleteFromFavorites = 'deleteFromFavorites',
  settings = 'settings',
  edit = 'edit',
  duplicate = 'duplicate',
  delete = 'delete',
  priority = 'priority',
}
const DEFAULT_ACTIONS_TYPES_LIST = [
  DeviceAction.addToFavorites,
  DeviceAction.deleteFromFavorites,
  DeviceAction.settings,
  DeviceAction.edit,
  DeviceAction.duplicate,
  DeviceAction.delete,
];

/**
 * Component that represent devices actions list
 */
@Component({
  components: {
    ManageMLModel: () => import('@/ui/components/modals/ManageMLModel/index.vue'),
    ManageDevice: () => import('@/ui/components/modals/ManageDevice/index.vue'),
    DeleteModalForm: () => import('@/ui/components/modals/DeleteModalForm.vue'),
    ManageAnomalyDetection: () => import('@/ui/components/modals/ManageAnomalyDetection/index.vue'),
    ManageCharts: () => import('@/ui/components/modals/ManageChart/index.vue'),
    DuplicateDeviceWindow: () => import('@/ui/components/devices/actions/DuplicateDeviceWindow.vue'),
    ManagePriorities: () => import('@/ui/components/modals/ManagePriorities/index.vue'),
  },
})
export default class DeviceActions extends Vue {
  @Prop() device!: any;
  @State('anomalyDetection') anomalyDetectionState!: IAnomalyDetectionState;
  @State('charts') chartsState!: IChartsState;
  @State('measurements') measurementsState!: IVariablesState;
  @Getter('devices/devicesTypes') devicesTypes!: IDevicesTypes;
  @Getter('devices/deviceTypeSchemaByKey') deviceTypeSchemaByKey!: (key: string) => IDeviceType;
  @Getter('mpc/mlModelTypes') mlModelTypes!: IMLModelTypes;
  @Getter('mpc/mlModelTypeSchemaByKey') mlModelTypeSchemaByKey!: (key: string) => IMLModelType;
  @Getter('projects/project') project!: IProject;
  @Getter('members/currentMember') currentMember!: IMember;
  @Getter('members/canMemberAccess') canMemberAccess!: CanAccessCheck;
  @Getter('members/currentUserRole') currentUserRole!: string;
  @Action('devices/deleteDevice') deleteDevice!: (device: IDevice) => void;
  @Action('devices/updateDevice') updateDevice!: (data: {device: IDevice; skipReport: boolean}) => void;
  @Action('devices/addDeviceToFavorites') addDeviceToFavorites!: (deviceId: string) => void;
  @Action('devices/removeDeviceFromFavorites') removeDeviceFromFavorites!: (deviceId: string) => void;
  @Action('mpc/addMPCToFavorites') addMPCToFavorites!: (mpcId: string) => void;
  @Action('mpc/removeMPCFromFavorites') removeMPCFromFavorites!: (mpcId: string) => void;
  @Action('mpc/deleteMPC') deleteMPC!: (mpcId: IMLModel) => void;
  @Action('mpc/updateMPC') updateMPC!: (mpc: IMLModel) => void;
  @Action('devices/createDevice') createDevice!: (control: IDevice) => void;
  @Action('mpc/createMCCInstance') createMCCInstance!: (control: IMLModel) => void;
  @Action('members/updateMemberMeta') updateMemberMeta!: (meta: { project_id: string; member: IMember }) => Promise<void>;

  isMenuOpen = false;
  isCloseOnClick = true;

  get measurements() {
    return this.measurementsState.measurements;
  }

  /**
   * Schemas for all possible device actions
   * @return {object} collection of actions
   */
  get actionsSchemasList(): Record<DeviceAction, ActionsSchemasListItem> {
    return {
      [DeviceAction.addToFavorites]: {
        id: 'favorites',
        title: 'Add to favorites',
        locale: 'uiComponents.deviceActions.addToFavorites',
        handle: this.addToFavorites,
        visible: true,
      },
      [DeviceAction.deleteFromFavorites]: {
        id: 'favorites',
        title: 'Delete from favorites',
        locale: 'uiComponents.deviceActions.deleteFromFavorites',
        handle: this.deleteFromFavorites,
        visible: true,
      },
      [DeviceAction.settings]: {
        id: 'settings',
        title: 'Settings',
        locale: 'uiComponents.deviceActions.settings',
        handle: () => this.$emit('switchSettingsView', true),
        visible: this.settingsListView,
        isValidation: true,
      },
      [DeviceAction.edit]: {
        id: 'edit',
        title: 'Edit',
        locale: 'uiComponents.deviceActions.edit',
        handle: this.editControl,
        modal: this.manageModalType,
        formTitle: this.editFormTitle,
        visible: true,
      },
      [DeviceAction.delete]: {
        id: 'delete',
        title: 'Delete',
        locale: 'uiComponents.deviceActions.delete',
        handle: this.removeDevice,
        modal: 'DeleteModalForm',
        deleteText: this.device.name,
        visible: true,
      },
      [DeviceAction.duplicate]: {
        id: 'duplicate',
        title: 'Duplicate',
        locale: 'uiComponents.deviceActions.duplicate',
        modal: 'DuplicateDeviceWindow',
        visible: true,
        handle: () => {},
      },
      [DeviceAction.priority]: {
        id: 'priority',
        title: 'Priority',
        locale: 'modals.managePriorities.title',
        modal: 'ManagePriorities',
        visible: true,
        handle: () => {},
      },
    };
  }

  get actionTypesListByDeviceType(): any {
    return {
      Robot: [
        DeviceAction.addToFavorites,
        DeviceAction.deleteFromFavorites,
        DeviceAction.settings,
        DeviceAction.edit,
      ],
      SurveyClient: [
        DeviceAction.addToFavorites,
        DeviceAction.deleteFromFavorites,
        DeviceAction.settings,
        DeviceAction.edit,
      ],
      chart: [
        DeviceAction.addToFavorites,
        DeviceAction.deleteFromFavorites,
        DeviceAction.settings,
        DeviceAction.edit,
        DeviceAction.duplicate,
        DeviceAction.delete,
      ],
      EMSV2: [
        DeviceAction.addToFavorites,
        DeviceAction.deleteFromFavorites,
        DeviceAction.settings,
        DeviceAction.edit,
        DeviceAction.priority,
        DeviceAction.duplicate,
        DeviceAction.delete,
      ],
      EnergyIO: [
        DeviceAction.addToFavorites,
        DeviceAction.deleteFromFavorites,
        DeviceAction.edit,
        DeviceAction.duplicate,
        DeviceAction.delete,
      ],
    };
  }
  get actionTypesList() {
    return this.actionTypesListByDeviceType[this.device.data.type] || DEFAULT_ACTIONS_TYPES_LIST;
  }

  /**
   * Return true if current device id in currentMember favorite_devices list.
   */
  get isDeviceFavorite() {
    return this.$route.name === 'Favorites' || this.currentMember?.meta?.favorite_devices?.includes(this.device.id);
  }

  /**
   * Return filtered list of filtered actions list according to if device in favorites
   */
  get currentActionListAccordingToDeviceFavorites() {
    const currentActions = this.actionTypesList.filter((action: DeviceAction) => {
      return this.isDeviceFavorite
        ? action !== DeviceAction.addToFavorites
        : action !== DeviceAction.deleteFromFavorites;
    });
    return currentActions.map((action: DeviceAction) => this.actionsSchemasList[action]);
  }

  /**
   * Filter action from list if member not have access to it
   */
  get accessibleActionList() {
    return this.currentActionListAccordingToDeviceFavorites.filter((item: any) => {
      return this.canMemberAccess('DeviceActions', item.id);
    });
  }

  get currentActionsListFiltered() {
    const newList: any = this.currentActionListAccordingToDeviceFavorites.filter((element: any) => !envUserRolesAccessBlockList[this.currentUserRole].DeviceActions.includes(element.id));
    if (newList?.[0]?.id === 'settings' && this.settingsListView === false) {
      return [];
    }
    return newList;
  }
  // types (need to improve)
  get isDevice() {
    return Object.keys(this.devicesTypes).some(item => item === this.device.data.type);
  }
  get isChart() {
    return Object.keys(this.chartsState.chartsTypes).some(item => item === this.device.data.type);
  }
  get mlModel() {
    return Object.keys(this.mlModelTypes).some(item => item === this.device.data.type);
  }
  get isAnomalyDetection() {
    return Object.keys(this.anomalyDetectionState.anomalyDetectionTypes).some(item => item === this.device.data.type);
  }

  get isMPC() {
    const mpcApiTypes: string[] = [...Object.keys(this.mlModelTypes), ...Object.keys(this.anomalyDetectionState.anomalyDetectionTypes)];
    return mpcApiTypes.some((type: string) => type === this.device.data.type);
  }

  /**
   * Define modal window by device type
   * @return {string} name of manage modal
   */
  get manageModalType() {
    if (this.isDevice) return 'ManageDevice';
    if (this.isChart) return 'ManageCharts';
    if (this.mlModel) return 'ManageMLModel';
    return 'ManageAnomalyDetection';
  }

  /**
   * Define translation path for title edit modal form
   * @return {string} path to i18n translation
   */
  get editFormTitle() {
    if (this.isDevice) return 'modals.manageDevice.editDeviceTitle';
    if (this.isChart) return 'modals.manageCharts.editChartTitle';
    if (this.mlModel) return 'modals.manageMLModel.editDeviceTitle';
    return 'modals.manageAnomalyDetection.editAnomalyDetectionTitle';
  }

  /**
   * This getter check if current device has settings view
   * @return {boolean} is device has settings view
   */
  get settingsListView() {
    if (this.isChart) return false;
    if (this.isAnomalyDetection) return false;
    if (this.mlModel) return this.mlModelTypeSchemaByKey(this.device.data.type).isSettingsView;
    return this.devicesTypes[this.device.data.type].isSettingsView;
  }

  get deviceValidationWithMpcLogic() {
    return this.mpcValidationLogic(this.deviceTypeSchemaByKey(this.device.data.type));
  }

  mpcValidationLogic(currentMPCTypeSchema: any) {
    const { settingsMappings } = currentMPCTypeSchema;
    if (!this.device.data?.meta?.controllerMappings || !settingsMappings) return null;

    const isValid: any = settingsMappings.map((field: string) => {
      const isFieldAreObject: boolean = typeof this.device.data.meta.controllerMappings[field] === 'object';
      const isDynamicField: boolean = /#id#/.test(field);
      if (isDynamicField) {
        const deviceIdUnderline: string = this.device.id.replace(/-/g, '_');
        const fieldVariable: string = field.replace(/#id#/, deviceIdUnderline);
        const value: any = this.measurements.get(fieldVariable);
        const { min, max } = currentMPCTypeSchema.dynamicMappingsMinMax[field];
        return min > value || max < value || typeof value !== 'number';
      } else if (isFieldAreObject) {
        const { minMaxBorders } = currentMPCTypeSchema.controllerMappings[field];
        const fields = this.device.data.meta.controllerMappings[field];
        const valuesArray = Object.values(fields).map((val: any) => this.measurements.get(val));
        return valuesArray.some((value: any) => minMaxBorders.min > value || minMaxBorders.max < value || typeof value !== 'number');
      } else {
        const { minMaxBorders } = currentMPCTypeSchema.controllerMappings[field];
        const value: any = this.measurements.get(this.device.data.meta.controllerMappings[field]);
        return minMaxBorders.min > value || minMaxBorders.max < value || typeof value !== 'number';
      }
    });

    return isValid.some((el: boolean) => el);
  }

  /**
   * Validation for devices action list.
   * If some form field in device settings view not valid,
   * red frame around the settings list item flashes
   * @return {boolean} validation status
   */
  get devicesValidation() {
    const additionalBasicDevices: any = this.device.data?.meta?.deviceSchema?.additionalBasicDevices;
    if (!additionalBasicDevices?.length) return null;
    const inputFieldsArray = () => {
      const filtered: any = additionalBasicDevices.filter((field: string) => {
        const fieldWithoutNumbers: any = field.replace(/[0-9]/g, '');
        return fieldWithoutNumbers === 'InputField';
      });
      return filtered.length ? filtered.map((field: string) => `${field}_targetValue`) : null;
    };
    const isValid: any = !inputFieldsArray() ? [] : inputFieldsArray().map((field: string) => {
      const { minMaxBorders } = this.deviceTypeSchemaByKey(this.device.data.type).mappings[field];
      const value: any = this.measurements.get(this.device.data.mappings[field]);
      if (!minMaxBorders) return [];
      return minMaxBorders.min > value || minMaxBorders.max < value || typeof value !== 'number';
    });

    return isValid.some((el: boolean) => el);
  }

  /**
   * Validation for MPC devices action list.
   * If some form field in MPC device settings view not valid,
   * red frame around the settings list item flashes
   * @return {boolean} validation status
   */
  get mpcValidation() {
    return this.mpcValidationLogic(this.mlModelTypeSchemaByKey(this.device.data.type));
  }

  /**
   * The flashing frame is only visible on devices that have settings view
   * @return validation getter
   */
  get settingsValidation() {
    // special case for valdation because EMSV2 is normal device but has data structure from MPC
    if (this.device.data.type === 'EMSV2') {
      return this.deviceValidationWithMpcLogic;
    }
    if (this.isDevice) return this.devicesValidation;
    if (this.mlModel) return this.mpcValidation;
    return null;
  }

  /**
   * Checks device type and use deleteFromFavorites method according to it
   */
  async deleteFromFavorites() {
    const member = JSON.parse(JSON.stringify(this.currentMember));
    if (member.meta.favorite_devices !== undefined) {
      member.meta.favorite_devices = member.meta.favorite_devices.filter((deviceId: string) => deviceId !== this.device.id);
    }
    if (member.meta.favorite_positions !== undefined) {
      member.meta.favorite_positions = member.meta.favorite_positions.filter((device: any) => device.i !== this.device.id);
    }
    if (this.project.id) {
      await this.updateMemberMeta({ project_id: this.project.id, member });
    }
  }

  /**
   * Checks device type and use addToFavorites method according to it
   */
  async addToFavorites() {
    const member = JSON.parse(JSON.stringify(this.currentMember));
    if (member.meta === undefined) {
      member.meta = {};
    }
    if (this.currentMember?.meta?.favorite_devices === undefined) {
      member.meta.favorite_devices = [this.device.id];
    } else {
      member.meta.favorite_devices.push(this.device.id);
    }
    if (this.project.id) {
      await this.updateMemberMeta({ project_id: this.project.id, member });
    }
  }

  /**
   * Checks device type and use delete method according to it
   */
  removeDevice() {
    if (this.isMPC) {
      this.deleteMPC(this.device as IMLModel);
    } else {
      this.deleteDevice(this.device as IDevice);
    }
  }

  /**
   * Checks device type and use update method according to it
   * @param payload device or mpc data
   */
  async editControl(payload: any) {
    this.$emit('toggleLoading', true);
    if (this.isMPC) await this.updateMPC(payload);
    else await this.updateDevice({ device: payload, skipReport: false });

    this.$emit('toggleLoading', false);
    this.$emit('rerenderDevice', payload); // TODO: remove rerender key when old EMS will be removed
  }
}
