
import { Vue, Component } from 'vue-property-decorator';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import SliderMock from '@/ui/components/devices/previews/components/SliderMock.vue';
import PushButtonMock from '@/ui/components/devices/previews/components/PushButtonMock.vue';
import ArrowButtonMock from '@/ui/components/devices/previews/components/ArrowButtonMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    InputFieldMock,
    SliderMock,
    PushButtonMock,
    ArrowButtonMock,
    DeviceLayout,
  },
})
export default class ControlBlindsPreview extends Vue {
  selected = 0

  get items() {
    return [
      { name: this.$t(`${this.mainViewLang}.dropDown.fullyOpen`), value: 0 },
      { name: this.$t(`${this.mainViewLang}.dropDown.threeQuarterOpen`), value: 1 },
      { name: this.$t(`${this.mainViewLang}.dropDown.halfOpen`), value: 2 },
      { name: this.$t(`${this.mainViewLang}.dropDown.quarterOpen`), value: 3 },
      { name: this.$t(`${this.mainViewLang}.dropDown.closed`), value: 4 },
    ];
  }

  get settingsViewLang() {
    return 'devices.ControlBlinds.settingsView';
  }
  get mainViewLang() {
    return 'devices.ControlBlinds.mainView';
  }
  get previewNameLang() {
    return this.$t('devices.ControlBlinds.previewName');
  }
}
