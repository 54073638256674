
import { Vue, Component } from 'vue-property-decorator';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import ArrowButtonMock from '@/ui/components/devices/previews/components/ArrowButtonMock.vue';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import SettingsCharts from '@/ui/components/devices/previews/mpc/HeatingCircuitOptimization/SettingsCharts.vue';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';

@Component({
  components: {
    SwitchMock,
    ShowEventDotMock,
    InputFieldMock,
    ArrowButtonMock,
    SettingsCharts,
    MPCLayout,
  },
})
export default class HeatingCircuitOptimizationPreview extends Vue {
  tab: any = null
  energySavingMode = 1

  get energySavingModesList() {
    const t: any = this.$t('mlModel.HeatingCircuitOptimization.settingsView.energySavingModesList');
    return [
      { title: t.comfort, value: 0 },
      { title: t.balanced, value: 1 },
      { title: t.energySaving, value: 2 },
    ];
  }
  get mainViewLang() {
    return 'mlModel.HeatingCircuitOptimization.mainView';
  }
  get settingsViewLang() {
    return 'mlModel.HeatingCircuitOptimization.settingsView';
  }
  get previewNameLang() {
    return this.$t('mlModel.HeatingCircuitOptimization.previewName');
  }
}
