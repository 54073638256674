
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { Validation } from '@/ui/mixins/validation';
import { Action, Getter, State } from 'vuex-class';
import { IPartnerWorkspace } from '@/types/partnerWorkspace.types';
import { IAppState } from '@/store/modules/app/types';

@Component({
  components: {
    ModalWindow,
  },
})
export default class ManageOffers extends mixins(Validation) {
  @State('app') appState!: IAppState;
  @Getter('partnerWorkspaces/workspace') workspace!: IPartnerWorkspace;
  @Action('partnerWorkspaces/updateWorkspace') updateWorkspace!: (data: {project_id: string; workspace: IPartnerWorkspace; showMessage: boolean}) => Promise<void>;
  @Action('app/postAsset') postAsset!: (asset: any) => Promise<any>;

  valid = false;
  fileName = '';
  assetId = '';
  fileSelected = false;

  get fullName() {
    return `${this.appState.user.first_name} ${this.appState.user.last_name}`;
  }

  get currentLang() {
    return this.$i18n.locale;
  }

  async onDialogStatusChange(status: boolean) {
    if (status) {
      await this.$nextTick();
      this.validate();
    } else {
      this.fileName = '';
      this.assetId = '';
      this.fileObject = { name: '' };
    }
  }

  @Watch('currentLang', { deep: true })
  handleLangChange() {
    this.validate();
  }

  validate() {
    (this.$refs.form as any)?.validate();
  }

  // file upload start
  fileObject = { name: '' };

  /**
   * Upload file and update current project
   */
  async handleFileUpload() {
    if (this.fileObject) {
      if (this.fileObject.name.lastIndexOf('.') <= 0) {
        return;
      }
      // upload file
      const result = await this.postAsset(this.fileObject);
      if (result) {
        // save asset id in project
        this.assetId = result;
        this.fileSelected = true;
      }
    }
  }
  // file upload end

  handleCreate() {
    this.$emit('addDocument', { name: this.fileName, file_id: this.assetId, upload_by: this.fullName, created_at: new Date().toISOString() });
    (this.$refs.ModalWindowInstance as any).closeDialog();
  }
}
