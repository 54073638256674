
import { Vue, Component, Prop } from 'vue-property-decorator';
import DeviceActions from '@/ui/components/devices/actions/DeviceActions.vue';
import SwitchBase from '@/ui/components/devices/devices/base/SwitchBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent LightSwitch device
 */
@Component({
  components: {
    SwitchBase,
    DeviceActions,
    DeviceLayout,
  },
})
export default class LightSwitch extends Vue {
  @Prop() deviceData!: IDevice;
}
