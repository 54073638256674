
import { Vue, Component, Provide, Prop } from 'vue-property-decorator';
import ChartTimetableConsumer from '@/ui/components/devices/previews/mpc/EMS/charts/ChartTimetableConsumer.vue';
import EnergyFlow from '@/ui/components/devices/previews/mpc/EMS/charts/EnergyFlow/index.vue';
import SingleSystemsCharts from '@/ui/components/devices/previews/devices/EMSV2/charts/SingleSystem/SingleSystemsCharts.vue';

@Component({
  components: {
    ChartTimetableConsumer,
    EnergyFlow,
    SingleSystemsCharts,
  },
})
export default class EMSCharts extends Vue {
  @Prop({ default: [] }) items!: any;
  tab = null

  @Provide('chartColors') provideColors = ['#6ca2d8', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'];
}
