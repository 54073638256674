
import { Vue, Component, Prop } from 'vue-property-decorator';
import FillLevelDisplayBase from '@/ui/components/devices/devices/base/FillLevelDisplayBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent SensorLevel device
 */
@Component({
  components: {
    FillLevelDisplayBase,
    DeviceLayout,
  },
})
export default class SensorLevel extends Vue {
  @Prop() deviceData!: IDevice;
}
