
import { Vue, Component } from 'vue-property-decorator';
import UploadFileModal from '@/ui/components/modals/Partner/UploadFileModal.vue';
import { IFile, IPartnerWorkspace } from '@/types/partnerWorkspace.types';
import { Getter, Action } from 'vuex-class';
import { formatDate } from '@/utils/utilsFunctions';

@Component({
  methods: { formatDate },
  components: {
    UploadFileModal,
  },
})
export default class Offer extends Vue {
  @Getter('partnerWorkspaces/workspace') workspace!: IPartnerWorkspace;
  @Action('partnerWorkspaces/updateWorkspace') updateWorkspace!: (data: {project_id: string; workspace: IPartnerWorkspace; showMessage: boolean}) => Promise<void>;

  get documents() {
    return this.workspace.meta?.offers || [];
  }

  get headers() {
    return [
      {
        text: this.$t('partners.partnerWorkspace.pages.offers.tableHeaders.name'),
        value: 'name',
        sortable: false,
        width: '25%',
      },
      {
        text: this.$t('partners.partnerWorkspace.pages.offers.tableHeaders.created_at'),
        value: 'created_at',
        sortable: false,
        width: '25%',
      },
      {
        text: this.$t('partners.partnerWorkspace.pages.offers.tableHeaders.upload_by'),
        value: 'upload_by',
        sortable: false,
        width: '25%',
      },
      {
        text: this.$t('partners.partnerWorkspace.pages.offers.tableHeaders.file'),
        value: 'file_id',
        sortable: false,
        width: '25%',
      },
    ];
  }

  deleteDocument(document: IFile) {
    if (!this.workspace.meta.offers) {
      return;
    }
    this.workspace.meta.offers = this.workspace.meta.offers.filter((item: IFile) => item.file_id !== document.file_id);
    this.updateWorkspace({ project_id: this.workspace.id, workspace: this.workspace, showMessage: true });
  }

  downloadDocument(url: string) {
    window.open(url, '_blank');
  }

  getFormatedDate(date: string) {
    let currentDate: Date | string = new Date(date);
    currentDate = formatDate(currentDate);
    return currentDate;
  }

  addDocument(offer: IFile) {
    const workspaceCopy = { ...this.workspace };
    if (!workspaceCopy.meta.offers) {
      workspaceCopy.meta.offers = [];
    }
    workspaceCopy.meta.offers.push(offer);
    this.updateWorkspace({ project_id: this.workspace.id, workspace: workspaceCopy, showMessage: true });
  }
}
