
import { fromDatePickerString, toSecondsSinceEpoch } from '@/utils/utilsFunctions';
import DefaultCalendar from '@/ui/components/devices/charts/components/DefaultCalendar.vue';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

/**
 * Component that represent hour picker in charts
 */
@Component({
  components: {
    DefaultCalendar,
  },
})
export default class HourPicker extends Vue {
  @Prop() calendarMin?: string;
  @Prop() calendarMax?: string;

  // date model, stored as array of numbers
  date: number[] = [];
  time = '';

  @Watch('fullDateTime')
  onDateTimeChange(date?: Date) {
    this.$emit('input', [toSecondsSinceEpoch(date)]);
  }

  /**
   * Define full date - time string
   * @return {array} time in format [YYYY-MM-DD-HH-MM]
   */
  get fullDateTime() {
    if (this.date && this.time) {
      const date = new Date(this.date[0] * 1000);
      const [hour, minute] = this.time.split(':');
      date.setHours(parseInt(hour, 10));
      date.setMinutes(parseInt(minute, 10));
      return date;
    } else {
      return undefined;
    }
  }

  get isToday(): boolean {
    if (!this.date) return false;

    const today = new Date();
    const d = new Date(this.date[0] * 1000);

    return d.getDate() === today.getDate() &&
      d.getMonth() === today.getMonth() &&
      d.getFullYear() === today.getFullYear();
  }

  /**
   * Define maximum value for hour picker
   * @return {string} time in format HH:MM
   */
  get timePickerMax(): string | undefined {
    // allow now - 1h as max time
    const d: any = moment().subtract(1, 'h').toDate();

    return this.isToday ? `${d.getHours()}:${d.getMinutes()}` : undefined;
  }

  reset() {
    // TODO: re-check! doesn't work yet completely
    this.date = [];
    this.time = '';
    // set current active picker to "hour". 1 corresponds to Hour option
    // for possible changes check:
    // https://github.com/vuetifyjs/vuetify/blob/8bb752b210d25fbebcea12cd073d2ce4986f5e12/packages/vuetify/src/components/VTimePicker/SelectingTimes.ts
    (this.$refs.timePicker as any).selecting = 1;
  }
}
