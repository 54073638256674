
import { Vue, Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

/**
 * Component that represent RonovatecConfirmationDialogButton type for PushButton base control
 */
@Component
export default class RonovatecConfirmationDialogButton extends BasicControl {
  @Prop({ default: null }) currentState!: number | null;
  @Prop({ default: 150 }) buttonWidth!: number;
  @Prop({ default: 36 }) buttonHeight!: number;
  @Prop({ default: 'theme' }) iconColor !: string;
  @Prop() iconSize?: number | string;

  dialogActive = false

  handleEvent() {
    this.$emit('sendConfirmed');
    this.dialogActive = false;
  }
}
