
import { Component, Prop, Watch } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

/**
 * Component that represent DateTimePicker Basic Control
 */
@Component
export default class DateTimePickerBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop({ default: false }) alignRight !: boolean;
  @Prop({ default: true }) convertToUnixTime!: boolean

  // value of DateTimePicker
  dateValue = '';
  disableSendButton = true;

  created() {
    const valueDatabase: any = this.measurements.get(this.mappingsClean.actualValue);
    const currentDateValue = Math.trunc(new Date().getTime() / 1000);
    if (valueDatabase - currentDateValue > 0) {
      const d = new Date(valueDatabase * 1000);
      this.dateValue = d.toISOString();
    }
  }

  @Watch('dateValue')
  enableSendButton() {
    if (this.dateValue !== null) {
      this.disableSendButton = false;
    }
  }

  emitDate() {
    const date = new Date(this.dateValue);
    const unixTimeStamp = Math.trunc(date.getTime() / 1000);

    this.send([{ n: this.mappingsClean.actualValue, v: unixTimeStamp, u: '' }]);
  }

  get isNotMapped(): boolean {
    return this.mappingsClean.actualValue === '';
  }
}
