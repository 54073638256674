
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { isNil, toNumber } from 'lodash';
import { defaultChartColors } from '@/ui/components/devices/charts/charts/LynusChart';
import { IVariable } from '@/types/variables.types';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';

/**
 * Component that contains manage charts form
 */
@Component
export default class ManageChartsView extends mixins(Validation) {
  @Prop() data!: any;
  @Prop() aggregationMethods!: any[];
  @Prop() chartTypes!: any[];
  @Prop() scalingMethods!: any[];
  @Getter('variables/variablesForComboBox') variablesForComboBox!: IVariable[];

  scaling = 'automatic';
  chartColors = defaultChartColors;
  valid = true;

  handleScaling(value: string) {
    this.scaling = value;
    if (value === 'automatic') {
      this.data.scaling.min = null;
      this.data.scaling.max = null;
    } else {
      this.data.scaling.min = 0;
      this.data.scaling.max = 1;
    }
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }
  onScalingInput() {
    this.setScaling();
  }
  setScaling() {
    const { min, max } = this.data.scaling;
    if (min !== '' && max !== '' || min !== '0' && max !== '0') {
      this.scaling = 'manual';
    }
    if (min === '' && max === '' || min === '0' && max === '0' || isNil(min) && isNil(max)) {
      this.scaling = 'automatic';
    }
  }

  async created() {
    this.setScaling();
  }

  async mounted() {
    await this.$nextTick();
    (this.$refs.form as any).validate();
  }
}
