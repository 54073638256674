
import { Component, Prop } from 'vue-property-decorator';
import SliderBase from '@/ui/components/devices/devices/base/SliderBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import ArrowDownBase from '@/ui/components/devices/devices/base/ArrowDownBase.vue';
import ArrowUpBase from '@/ui/components/devices/devices/base/ArrowUpBase.vue';
import DropDownBase from '@/ui/components/devices/devices/base/DropDown.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent ControlBlinds device
 */
@Component({
  components: {
    SliderBase,
    OutputFieldBase,
    InputFieldBase,
    PushButtonBase,
    ArrowDownBase,
    ArrowUpBase,
    DropDownBase,
    DeviceLayout,
    LabelUnitWrapper,
  },
})
export default class ControlBlinds extends mixins(Validation) {
  @Prop() deviceData!: IDevice;
  @State('measurements') measurementsState!: IVariablesState;

  get textMapping() {
    return {
      0: this.$t(`${this.langPath}.mainView.dropDown.fullyOpen`),
      1: this.$t(`${this.langPath}.mainView.dropDown.threeQuarterOpen`),
      2: this.$t(`${this.langPath}.mainView.dropDown.halfOpen`),
      3: this.$t(`${this.langPath}.mainView.dropDown.quarterOpen`),
      4: this.$t(`${this.langPath}.mainView.dropDown.closed`),
    };
  }

  get langPath() {
    return `devices.${this.deviceData.data.type}`;
  }

  get measurements() {
    return this.measurementsState.measurements;
  }
  get sliderActualValue() {
    return this.measurements.get(this.deviceData.data.mappings.OutputField_actualValue);
  }
}
