
import { Vue, Component, Prop } from 'vue-property-decorator';
import DeviceDescription from '@/ui/components/devices/actions/DeviceDescription.vue';
import OfflineChart from '@/ui/components/devices/previews/charts/components/OfflineChart.vue';
import { ChartData } from '@/types/chart.types';

@Component({
  components: {
    DeviceDescription,
    OfflineChart,
  },
})
export default class AreaChartPreview extends Vue {
  @Prop() chartWidth!: any;
  @Prop({
    default: 'Line area chart',
  }) chartTitle!: string;
  @Prop() chartDescription!: string;
  @Prop() chartData!: ChartData;

  get description() {
    return this.$t('chartsDescription.AreaChart');
  }
}
