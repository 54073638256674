
import { IWizardPage } from '@/types/wizards/wizard.general.types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import InstallationSteps from './InstallationSteps.vue';
import UserInformationField from './UserInformationField.vue';

@Component({
  components: {
    InstallationSteps,
    UserInformationField,
  },
})
export default class SideBar extends Vue {
  @Prop() name!: string;
  @Prop() stepsCount!: number;
  @Prop() wizardPages!: IWizardPage[];
  @Prop({ default: 0 }) activePage!: number;
  @Prop({ default: 1 }) activeStep!: number;
}
