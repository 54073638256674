
import { Vue, Component } from 'vue-property-decorator';
import OfflineChart from '@/ui/components/devices/previews/charts/components/OfflineChart.vue';

@Component({
  components: {
    OfflineChart,
  },
})
export default class Forecast extends Vue {
  // contains Chart Object
  get mockChartData() {
    let currentChartOptions: any = [];
    const lang: any = this.$t('mlModel.HppProductionService.settingsView.chartLabels.forecast');

    currentChartOptions = [
      {
        agg: 'first',
        name: lang.predictedEnergy,
        scaling: {
          max: '100',
          min: '0',
        },
        seriesType: 'View',
        type: 'line',
        unit: 'kWh',
        var: '',
      }, {
        agg: 'first',
        name: lang.predictedPower,
        scaling: {
          max: '100',
          min: '0',
        },
        seriesType: 'View',
        type: 'line',
        unit: 'kW',
        var: '',
      },
    ];

    const currentData = {
      data: {
        chartOptions: currentChartOptions,
        selectedStackingOptions: 'normal',
        selectedWidth: 'full',
        type: 'chart',
      },
      created_at: '2021-10-07T07:43:33.554983Z',
    };
    return currentData;
  }
}
