
import { Component, Prop } from 'vue-property-decorator';
import { Validation, ValidationRule } from '@/ui/mixins/validation';
import { TranslateResult } from 'vue-i18n';
import { mixins } from 'vue-class-component';

/**
 * Form field which used in manage modals forms
 */
@Component
export default class InputFieldNumber extends mixins(Validation) {
  @Prop() value!: number;
  @Prop() isDecimal?: boolean;
  @Prop({ default: false }) disabled?: boolean;
  @Prop({ default: 0.01 }) step?: number;
  @Prop() min?: number;
  @Prop() max?: number;
  @Prop({ default: () => [] }) fieldRules?: ValidationRule[];
  @Prop({ default: false }) small!: boolean;
  @Prop({ default: null }) label!: string | TranslateResult;

  state = 24

  get rulesList() {
    const min = typeof this.min === 'number' ? this.fieldMoreThan(this.min) : true;
    const max = typeof this.max === 'number' ? this.fieldLessThan(this.max) : true;
    return [...this.fieldRules as ValidationRule[], min, max];
  }

  handleKeypress(e: KeyboardEvent) {
    const isDecimalSymbol = /[0-9]|,/.test(e.key);
    const isInteger = /[0-9]/.test(e.key);
    if (this.isDecimal) {
      if (!isDecimalSymbol) e.preventDefault();
    }
    if (!this.isDecimal) {
      if (!isInteger) e.preventDefault();
    }
  }

  mounted() {
    (this.$refs.customField as any).validate(true);
  }
}
