
import { Vue, Component, Prop } from 'vue-property-decorator';
import { EnergyLineDisplayData } from '@/types/energyVisualisation/EnergyLineDisplayData';
import AnimatedLine from './AnimatedLine.vue';

@Component({
  components: {
    AnimatedLine,
  },
})
export default class EnergyLines extends Vue {
  @Prop({}) displayData!: EnergyLineDisplayData[];
  @Prop({ default: null }) canvasSize!: { width: number; height: number };
}
