import { envUserRolesAccessBlockList } from '@/utils/env';

export enum UserRoleCode {
  admin = 1000,
  user = 1001,
  readOnly = 1002,
}

export enum UserRoleName {
  admin = 'Admin',
  user = 'User',
  readOnly = 'ReadOnly',
}

export const userRolesKeys: Record<number, string> = {
  [UserRoleCode.admin]: UserRoleName.admin,
  [UserRoleCode.user]: UserRoleName.user,
  [UserRoleCode.readOnly]: UserRoleName.readOnly,
};

export const checkProtocolAccessList = ['support@lynus.io', 'technik@rehl-energy.de'];

/**
 * Checks whether the given role can access the given permissionName, found in the permissionCategory.
 * @param role the role as number (1000, 1001, 1002)
 * @param permissionCategory the permission category, e.g. CreateModals for all modals permissions
 * @param permissionName name of the actual permission within the category
 * @returns true if the permission can be accessed
 */
export function canAccess(role: number, permissionCategory: string, permissionName: string): boolean {
  return !envUserRolesAccessBlockList[userRolesKeys[role]][permissionCategory].includes(permissionName);
}
