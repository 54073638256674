
import { Vue, Component } from 'vue-property-decorator';
import { throttle } from 'lodash';
import { State } from 'vuex-class';
import CentralEndpoint from '@/ui/components/devices/components/EnergyParts/CentralEndpoint.vue';
import System from '@/ui/components/devices/previews/mpc/SetpointOptimizer/system/index.vue';

@Component({
  components: {
    CentralEndpoint,
    System,
  },
})
export default class MainView extends Vue {
  @State('mpc') mpcState!: any;

  heatingGroup = ['heating_air_systems', 'heating_water_systems']
  collingGroup = ['cooling_air_systems', 'cooling_water_systems']
  canvasSize: any = null
  canvasCenter: any = null
  canvasLines: any = {}
  systemsList = ['heating_air_systems', 'heating_water_systems', 'cooling_air_systems', 'cooling_water_systems',
    'hybrid_water_systems', 'hybrid_air_systems']

  systemsData = {
    heating_air_systems: {
      components: {
        heating_air_systems1: {},
      },
      count: 1,
    },
    heating_water_systems: {
      components: {
        heating_water_systems1: {},
      },
      count: 1,
    },
    cooling_air_systems: {
      components: {
        cooling_air_systems1: {},
      },
      count: 1,
    },
    cooling_water_systems: {
      components: {
        cooling_water_systems1: {},
      },
      count: 1,
    },
    hybrid_water_systems: {
      components: {
        hybrid_water_systems1: {},
      },
      count: 1,
    },
    hybrid_air_systems: {
      components: {
        hybrid_air_systems1: {},
      },
      count: 1,
    },
  }

  /**
   * Load line if all coords and sizes ready
   * @return {boolean} load line status
   */
  get loadLine() {
    return !!this.canvasSize && !!this.canvasCenter && !!Object.values(this.canvasLines);
  }

  get systemsSchemas() {
    return this.mpcState.mlModelTypes.SetpointOptimizer.systems;
  }

  /**
   * Defines coordinates of systems, Central Energy Point.
   * Use vm.$refs to retrieve references to elements.
   * Then it goes through the list of references and creates an options object for each one.
   */
  getCoords() {
    const central: any = this.$refs.centralEndpoint;
    const actualViewRef: any = this.$refs.canvas;
    this.canvasSize = { width: actualViewRef?.clientWidth, height: actualViewRef?.clientHeight };
    this.canvasCenter = {
      x: central.offsetLeft + central.offsetWidth / 2,
      y: central.offsetTop + central.offsetHeight / 2,
      w: central.offsetWidth,
      h: central.offsetHeight,
    };
    this.systemsList.forEach((system: string) => {
      const target: any = this.$refs[system];
      if (!target) return;

      if (Array.isArray(target)) {
        this.canvasLines[system] = {
          key: system,
          x: target[0].offsetLeft + target[0].offsetWidth / 2,
          y: target[0].offsetTop + target[0].offsetHeight / 2,
          w: target[0].offsetWidth,
          h: target[0].offsetHeight,
        };
      } else {
        this.canvasLines[system] = {
          key: system,
          x: target.offsetLeft + target.offsetWidth / 2,
          y: target.offsetTop + target.offsetHeight / 2,
          w: target.offsetWidth,
          h: target.offsetHeight,
        };
      }
    });
  }
  getCoordsThrottle = throttle(this.getCoords, 500)

  mounted() {
    window.addEventListener('resize', this.getCoordsThrottle);
    this.getCoords();
  }
  destroyed() {
    window.removeEventListener('resize', this.getCoordsThrottle);
  }
}
