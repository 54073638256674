
import { Vue, Component, Prop } from 'vue-property-decorator';
import DeviceDescription from '@/ui/components/devices/actions/DeviceDescription.vue';
import PreviewActions from '@/ui/components/devices/actions/PreviewActions.vue';

@Component({
  components: {
    DeviceDescription,
    PreviewActions,
  },
})
export default class RegentLightingPreview extends Vue {
  @Prop() isModal?: boolean;

  get description() {
    return this.$t('devicesDescriptions.RegentLighting');
  }
  get previewNameLang() {
    return this.$t('devices.RegentLighting.previewName');
  }

  get stockwerk() {
    return [
      { name: 'Temperatur', value: '25.5 °C' },
      { name: 'Luftfeuchtigkeit', value: '52 %rH' },
      { name: 'CO2-eq', value: '1431 ppm' },
      { name: 'TVOC', value: '998 ppb' },
      { name: 'Auslastung', value: '77 %' },
      { name: 'Warm Desks', value: '23 %' },
      { name: 'Lighting', value: '250 Lux' },
    ];
  }
  get beacons() {
    return [
      { name: 'Temperatur', value: '25 °C' },
      { name: 'Luftfeuchtigkeit', value: '0.1 % rH' },
      { name: 'Helligkeit', value: '21 Lux' },
      { name: 'CO2-eq', value: '1200 ppm' },
      { name: 'TVOC', value: '900 ppb' },
    ];
  }
  get platz() {
    return [
      { name: 'Anwesenheit', value: 'Ja' },
      { name: 'Bewegung', value: 'Nein' },
      { name: 'Warm Desk', value: 'Nein' },
    ];
  }
}
