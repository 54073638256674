
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Highstock from 'highcharts/highstock';
import HSExporting from 'highcharts/modules/exporting';
import HSExportingData from 'highcharts/modules/export-data';
import PeriodSelection from '@/ui/components/devices/charts/components/PeriodSelection.vue';
import HourPicker from '@/ui/components/devices/charts/components/HourPicker.vue';
import DataExportNew from '@/ui/components/devices/charts/charts/DataExportNew.vue';
import { envApi } from '@/utils/env';
import TotalValuesWindow from '@/ui/components/devices/charts/components/TotalValuesWindow.vue';
import LynusChart from '@/ui/components/devices/charts/charts/LynusChart';
import { Periods, getChartOptions, periodConfigurations } from '@/ui/components/devices/charts/charts/ChartUtils';
import _ from 'lodash';
import { getSeriesMockData } from '@/ui/components/devices/previews/charts/components/ChartVariableData';
import ChartColors from '@/ui/components/devices/charts/charts/ChartColors';
import { ChartData } from '@/types/chart.types';

HSExporting(Highstock);
HSExportingData(Highstock);

@Component({
  components: {
    PeriodSelection,
    DataExportNew,
    HourPicker,
    TotalValuesWindow,
  },
})
export default class OfflineChart extends Vue {
  @Prop() chartData!: ChartData;
  @Prop() chartWidth!: number;
  @Prop({ default: 0 }) parentComponentWidth!: number;
  @Prop({ default: 400 }) chartHeight!: number;
  @Prop({ default: null }) navigationItemsToExclude!: null | string[];
  @Prop({ default: false }) isThresholdLine!: boolean;
  @Prop({ default: 3 }) minNumberOfSeriesForWideChart!: number;
  @Prop({ default: false }) showTotalOfSeries!: number;
  @Prop({ default: 0 }) minValueMockData!: number;
  @Prop({ default: 50 }) maxValueMockData!: number;
  @Prop({ default: true }) showKalendarButton!: boolean;
  @Prop({ default: true }) showFullscreenIcon!: boolean;
  @Prop({ default: true }) showTotalValuesIcon!: boolean;
  @Prop({ default: true }) showDataExportButton!: boolean;
  @Prop({ default: true }) showNavigationButtons!: boolean;
  @Prop({ default: () => ({ day: 4, week: 1, month: 1, year: 1 }) }) customApproximationValue!: any;
  @Getter('projects/projectStartedAt') projectStartedAt!: string;
  @Getter('projects/projectStartedAtNumber') projectStartedAtNumber!: number;

  localChartData!: ChartData;
  datePicker = false;
  date: any = null;
  intervalTimer: any = undefined;
  periodName: string = Periods.DAY;
  calendarType = 'date';
  createdAt: any = undefined;
  chartContainerWidth = '100%';
  overflow: any = 'hidden';
  disableNavButton = false;
  disableCalendar = false;
  chart?: LynusChart;
  // disables the Button from the Dialog of the Calendar to avoid Bugs
  disableCalendarButton = true;
  tempSelectedStacking: any;
  localCountColumns = 0;
  localCountLines = 0;
  disableStackingButton = false;
  objectsDifferenceSeriesStats: any = [];
  objectsOtherSeriesStats: any = [];
  isTooltip = false;
  localDataToExport = [];

  buttonStyle: any = {
    width: 50,
  }

  buttons: any = {
    contextButton: {
      enabled: false,
    },
    yearButton: {
      text: this.$t('uiComponents.chartNavigation.YEAR'),
      onclick: () => {
        this.navigationChanged('year');
        const textPrev = this.$t('uiComponents.chartNavigation.pervYear');
        const textNext = this.$t('uiComponents.chartNavigation.nextYear');
        this.updateFullscreenButtonText(textPrev.toString(), textNext.toString());
      },
      theme: this.buttonStyle,
    },
    monthButton: {
      text: this.$t('uiComponents.chartNavigation.MONTH'),
      onclick: () => {
        this.navigationChanged('month');
        const textPrev = this.$t('uiComponents.chartNavigation.pervMonth');
        const textNext = this.$t('uiComponents.chartNavigation.nextMonth');
        this.updateFullscreenButtonText(textPrev.toString(), textNext.toString());
      },
      theme: this.buttonStyle,
    },
    weekButton: {
      text: this.$t('uiComponents.chartNavigation.WEEK'),
      onclick: () => {
        this.navigationChanged('week');
        const textPrev = this.$t('uiComponents.chartNavigation.pervWeek');
        const textNext = this.$t('uiComponents.chartNavigation.nextWeek');
        this.updateFullscreenButtonText(textPrev.toString(), textNext.toString());
      },
      theme: this.buttonStyle,
    },
    dayButton: {
      text: this.$t('uiComponents.chartNavigation.DAY'),
      onclick: () => {
        this.navigationChanged('day');
        const textPrev = this.$t('uiComponents.chartNavigation.pervDay');
        const textNext = this.$t('uiComponents.chartNavigation.nextDay');
        this.updateFullscreenButtonText(textPrev.toString(), textNext.toString());
      },
      theme: this.buttonStyle,
    },
  };

  @Watch('$i18n.locale')
  onLocaleChange(locale: any) {
    this.chart?.setLocale(locale);

    this.chart?.buildChart(this.$refs.chart as HTMLElement);
    this.navigationChanged(this.periodName);
  }

  @Watch('periodName')
  periodChange() {
    if (this.chart?.chart.fullscreen.isOpen === false) {
      this.chart?.chart.update({
        exporting: {
          buttons: {},
          enabled: false,
        },
      });
    } else {
      let checkChanges = false;
      if (this.periodName === 'live') {
        if (this.buttons.nextButton !== undefined) {
          checkChanges = true;
          this.buttons.nextButton.enabled = false;
        }
        if (this.buttons.backButton !== undefined) {
          checkChanges = true;
          this.buttons.backButton.enabled = false;
        }
      } else {
        if (this.buttons.nextButton !== undefined) {
          checkChanges = true;
          this.buttons.nextButton.enabled = true;
        }
        if (this.buttons.backButton !== undefined) {
          checkChanges = true;
          this.buttons.backButton.enabled = true;
        }
      }

      if (checkChanges === true) {
        this.chart?.chart.update({
          navigation: {
            buttonOptions: {
              enabled: document.fullscreenElement !== null,
              theme: {
                style: {
                  color: this.$vuetify.theme.dark ? 'white' : 'black',
                },
                fill: (this.$vuetify.theme as any).currentTheme.deviceBackground,
                states: {
                  hover: {
                    fill: this.$vuetify.theme.dark ? '#535c7a' : '#dceffc',
                  },
                },
              },
            },
          },
          exporting: {
            buttons: this.buttons,
            enabled: true,
          },
        } as any);
      }
    }
  }

  @Watch('$vuetify.theme.dark')
  onThemeChange(val: boolean) {
    this.chart?.setIsDarkTheme(val);
    this.chart?.setChartColors(this.chartColors);
    this.chart?.onThemeChange(
      this.$vuetify.theme.currentTheme.deviceBackground?.toString() ?? '#ffffff'
    );
  }
  get chartColors() {
    return ChartColors.colors(this.$vuetify.theme.dark);
  }

  get now() {
    return new Date().toISOString();
  }

  get periodConfigurations() {
    const periodConfigs: any = _.cloneDeep(periodConfigurations); // copy default periodConfigurations
    periodConfigs.day.interval = '15m';
    return periodConfigs;
  }

  get hourPickerSelectButtonValidation() {
    if (!this.date) return true;
    else {
      // avoid errors when switching period in fullscreen
      if (Array.isArray(this.date)) {
        const arr = this.date[0].split('-');
        // check if full date-time valid (year, month, date, hour, minute must be filled)
        return arr.some((el: string) => /\D/.test(el));
      } else {
        return true;
      }
    }
  }

  testIsFullscreen() {
    if (this.chart?.chart.fullscreen.isOpen === false) {
      (this.chart?.chart as any).update({
        navigation: {
          buttonOptions: {
            enabled: document.fullscreenElement !== null,
            theme: {
              style: {
                color: this.$vuetify.theme.dark ? 'white' : 'black',
              },
              fill: this.$vuetify.theme.currentTheme.deviceBackground,
              states: {
                hover: {
                  fill: this.$vuetify.theme.dark ? '#535c7a' : '#dceffc',
                },
              },
            },
          },
        },
        exporting: {
          buttons: {},
          enabled: false,
        },
      });
    }
  }

  onFavoriteUpdated(payload: ChartData) {
    this.localChartData = _.cloneDeep(payload);
  }

  // handle any Random clicks on the Chart that could cause the Darkmode to go away
  chartClickHandler() {
    this.chart?.updateTheme();
  }

  openFullscreen() {
    this.chart?.handleFullScreen();
  }

  updateFullscreenButtonText(backText: string, nextText: string) {
    this.buttons.nextButton.text = nextText;
    this.buttons.backButton.text = backText;

    (this.chart?.chart as any).update({
      navigation: {
        buttonOptions: {
          enabled: document.fullscreenElement !== null,
          theme: {
            style: {
              color: this.$vuetify.theme.dark ? 'white' : 'black',
            },
            fill: this.$vuetify.theme.currentTheme.secondary,
            states: {
              hover: {
                fill: this.$vuetify.theme.dark ? '#535c7a' : '#dceffc',
              },
              fill: this.$vuetify.theme.currentTheme.deviceBackground,
              states: {
                hover: {
                  fill: this.$vuetify.theme.dark ? '#535c7a' : '#dceffc',
                },
              },
            },
          },
        },
      },
      exporting: {
        buttons: this.buttons,
        enabled: true,
      },
    });
  }

  updateChartFullscreen() {
    if (this.localCountColumns === 0) {
      // line chart
      this.buttons.hourButton = {
        text: this.$t('uiComponents.chartNavigation.HOUR'),
        onclick: () => {
          this.navigationChanged('hour');
          const textPrev = this.$t('uiComponents.chartNavigation.prevHour');
          const textNext = this.$t('uiComponents.chartNavigation.nextHour');
          this.updateFullscreenButtonText(textPrev.toString(), textNext.toString());
        },
        theme: this.buttonStyle,
      };
      this.buttons.liveButton = {
        text: 'Live',
        onclick: () => {
          this.navigationChanged(Periods.LIVE);
        },
        theme: this.buttonStyle,
      };
    } else {
      this.buttons.liveButton = undefined;
      this.buttons.hourButton = undefined;
    }

    let initNameBack = '';
    let initNameNext = '';
    if (this.periodName === 'day') {
      initNameBack = this.$t('uiComponents.chartNavigation.pervDay').toString();
      initNameNext = this.$t('uiComponents.chartNavigation.nextDay').toString();
    } else if (this.periodName === 'week') {
      initNameBack = this.$t('uiComponents.chartNavigation.pervWeek').toString();
      initNameNext = this.$t('uiComponents.chartNavigation.nextWeek').toString();
    } else if (this.periodName === 'month') {
      initNameBack = this.$t('uiComponents.chartNavigation.pervMonth').toString();
      initNameNext = this.$t('uiComponents.chartNavigation.nextMonth').toString();
    } else if (this.periodName === 'year') {
      initNameBack = this.$t('uiComponents.chartNavigation.pervYear').toString();
      initNameNext = this.$t('uiComponents.chartNavigation.nextYear').toString();
    } else if (this.periodName === 'hour') {
      initNameBack = this.$t('uiComponents.chartNavigation.prevHour').toString();
      initNameNext = this.$t('uiComponents.chartNavigation.nextHour').toString();
    }
    this.buttons.nextButton = {
      text: initNameNext,
      onclick: () => {
        this.changeDateFullscreen('next');
      },
      theme: {
        width: 100,
      },
    };
    this.buttons.backButton = {
      text: initNameBack,
      onclick: () => {
        this.changeDateFullscreen('back');
      },
      theme: {
        width: 100,
      },
    };

    (this.chart?.chart as any).update({
      navigation: {
        buttonOptions: {
          enabled: document.fullscreenElement !== null,
          theme: {
            style: {
              color: this.$vuetify.theme.dark ? 'white' : 'black',
            },
            fill: this.$vuetify.theme.currentTheme.secondary,
            states: {
              hover: {
                fill: this.$vuetify.theme.dark ? '#535c7a' : '#dceffc',
              },
              fill: this.$vuetify.theme.currentTheme.deviceBackground,
              states: {
                hover: {
                  fill: this.$vuetify.theme.dark ? '#535c7a' : '#dceffc',
                },
              },
            },
          },
        },
      },
      exporting: {
        buttons: this.buttons,
        enabled: true,
      },
    });
    if (this.$vuetify.breakpoint.mobile === true) {
      this.chart?.chart.update(
        {
          exporting: {
            enabled: false,
            buttons: undefined,
          },
        }
      );
    }
    setTimeout(() => {
      this.testIsFullscreen();
    }, 100);
  }

  changeDateFullscreen(button: string) {
    const newDay = null;
    let newDateObj = null;
    let strs = null;
    let newDate = null;
    const currentTimeStampToControl = Math.floor(Date.now() / 1000);

    if (this.date === null) {
      // gets the current start of the chart to calculate new start and end
      if (this.chart?.start !== undefined) {
        const date = new Date(this.chart?.start * 1000);
        const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        const dateTimeString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${date.getHours()}-${date.getMinutes()}`;
        this.date = this.periodName === 'hour' ? dateTimeString : dateString;
      }
    }
    if (button === 'back') {
      if (typeof this.date === 'string') {
        strs = this.date.split('-');
      } else {
        strs = this.date[0].split('-');
      }
      newDate = new Date(+strs[0], +strs[1] - 1, +strs[2]);
      if (this.periodName === 'day') {
        // go back one day -> day value -1
        newDate.setDate(newDate.getDate() - 1);
        newDateObj = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
        this.date = [newDateObj];
        this.handleCalendar();
      } else if (this.periodName === 'hour') {
        this.date = [this.date];
        this.handleCalendar();
      } else if (this.periodName === 'week') {
        // go back seven days of current day -> day value -7
        newDate.setDate(newDate.getDate() - 7);
        newDateObj = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
        this.date = [newDateObj];
        this.handleCalendar();
      } else if (this.periodName === 'month') {
        // go back one month -> month value -1
        if (newDate.getMonth() !== 0) {
          newDateObj = `${newDate.getFullYear()}-${newDate.getMonth()}-${newDate.getDate()}`;
          this.date = [newDateObj];
          this.handleCalendar();
        } else {
          newDateObj = `${newDate.getFullYear() - 1}-12-${newDate.getDate()}`;
          this.date = [newDateObj];
          this.handleCalendar();
        }
      } else if (this.periodName === 'year') {
        // go back one year -> year value -1
        newDateObj = `${newDate.getFullYear() - 1}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
        this.date = [newDateObj];
        this.handleCalendar();
      }
    } else {
      if (typeof this.date === 'string') {
        strs = this.date.split('-');
      } else {
        strs = this.date[0].split('-');
      }
      newDate = new Date(+strs[0], +strs[1] - 1, +strs[2]);
      let testDate = new Date(newDate).getTime() / 1000;
      if (this.periodName === 'day') {
        // go next one day -> day value +1
        newDate.setDate(newDate.getDate() + 1);
        newDateObj = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
        testDate = new Date(newDate).getTime() / 1000;
        // checks if next day's timestamp is bigger than current Timestamp
        if (testDate - currentTimeStampToControl < 0) {
          this.date = [newDateObj];
          this.handleCalendar();
        }
      } else if (this.periodName === 'hour') {
        const currentTime = Math.trunc(new Date().getTime() / 1000);
        const endChartHour = (this.chart as any).end;
        const nextHourTimestamp = endChartHour + 3600;
        if (nextHourTimestamp < currentTime) {
          const nextHourTimestampInMilliseconds = nextHourTimestamp * 1000;
          const d = new Date(nextHourTimestampInMilliseconds);
          this.date = [`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}-${d.getHours()}-${d.getMinutes()}`];
          this.handleCalendar();
        }
      } else if (this.periodName === 'week') {
        // go next one day -> day value +7
        newDate.setDate(newDate.getDate() + 7);
        newDateObj = `${newDate.getFullYear()}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
        testDate = new Date(newDate).getTime() / 1000;
        // checks if next weeks's timestamp is bigger than current Timestamp
        if (testDate - currentTimeStampToControl < 0) {
          this.date = [newDateObj];
          this.handleCalendar();
        }
      } else if (this.periodName === 'month') {
        // go back one month -> month value +1
        if ((newDate.getMonth() + 2) !== 13) {
          newDateObj = `${newDate.getFullYear()}-${newDate.getMonth() + 2}-${newDate.getDate()}`;
          if (testDate - currentTimeStampToControl < 0) {
            this.date = [newDateObj];
            this.handleCalendar();
          }
        } else {
          // eslint-disable-next-line prefer-template
          newDateObj = `${newDate.getFullYear() + 1}-1-${newDate.getDate()}`;
          if (testDate - currentTimeStampToControl < 0) {
            this.date = [newDateObj];
            this.handleCalendar();
          }
        }
      } else if (this.periodName === 'year') {
        // go back one year -> year value +1
        newDateObj = `${newDate.getFullYear() + 1}-${newDate.getMonth() + 1}-${newDate.getDate()}`;
        // checks if next year's timestamp is bigger than current Timestamp
        if (testDate - currentTimeStampToControl < 0) {
          this.date = [newDateObj];
          this.handleCalendar();
        }
      }
    }
  }

  countColumnLineLocal() {
    this.localCountColumns = 0;
    this.localCountLines = 0;
    getChartOptions(this.localChartData).forEach((variable: any) => {
      if (variable.type === 'column' || variable.type === 'diff') {
        this.localCountColumns++;
      } else {
        this.localCountLines++;
      }
    });
  }

  isWideChart() {
    return getChartOptions(this.localChartData).length > this.minNumberOfSeriesForWideChart;
  }

  updateToWideChart() {
    // this is used to make charts scrollable when they have more
    // than 3 y-axis rows
    const numVars = getChartOptions(this.localChartData).length;
    if (this.isWideChart()) {
      const calculatedWidth = 892 + numVars * 100;
      this.chartContainerWidth = '100%';
      this.overflow = 'auto';
    }
  }

  // date expected to be in format YYYY-MM-DD
  handleWeek(date: any) {
    if (this.periodName === Periods.DAY) {
      this.date = [date];
    }

    if (this.periodName === Periods.WEEK) {
      const [year, month, day] = date.split('-');
      const selectedDate = new Date(+year, +month - 1, +day);
      const selectedDay = selectedDate.getDay() - 1; // day from sunday on, mon = 1
      let firstDay = selectedDate.getTime() / 1000;
      firstDay -= 86400 * selectedDay;
      let firstDayFormated = null;
      let str1 = null;
      let lastDay = null;
      let lastDayFormated = null;
      if (selectedDay !== -1) {
        firstDayFormated = new Date((firstDay + 86400) * 1000);
        str1 = firstDayFormated.toISOString().substring(0, 10);
        lastDay = firstDay + 86400 * 7;
        lastDayFormated = new Date(lastDay * 1000);
      } else {
        firstDayFormated = new Date((firstDay - 518400) * 1000);
        str1 = firstDayFormated.toISOString().substring(0, 10);
        lastDay = firstDay + 86400 * 7;
        lastDayFormated = new Date((lastDay - 604800) * 1000);
      }

      const str2 = lastDayFormated.toISOString().substring(0, 10);

      this.date = [str1, str2];
    }
    this.disableCalendarButton = false;
  }

  // date expected to be in format YYYY-MM
  handleMonth(date: any) {
    if (this.periodName === Periods.MONTH) {
      // eslint-disable-next-line prefer-template
      this.date = [date.toString() + '-01'];
      this.disableCalendarButton = false;
      this.handleCalendar();
    }
  }

  // year expected to be in format YYYY
  handleYear(year: any) {
    if (this.periodName === Periods.YEAR) {
      this.datePicker = false;
      // eslint-disable-next-line prefer-template
      this.date = [year.toString() + '-01-01'];
      this.disableCalendarButton = false;

      this.handleCalendar();
    }
  }

  updateDatePicker(): void {
    this.date = null;
    if (this.periodName === Periods.HOUR) return;

    const currentPicker = (this.$refs.calendar as any).activePicker;

    if (this.periodName === Periods.YEAR) {
      (this.$refs.calendar as any).activePicker = 'YEAR';
    } else if (this.periodName === Periods.MONTH) {
      (this.$refs.calendar as any).activePicker = 'MONTH';
      this.calendarType = this.chart?.period.calendarType;
    } else {
      // only replaces the Date if another Picker was selected
      if (currentPicker !== 'DATE') {
        this.date = new Date().toISOString().substring(0, 10);
      }
      (this.$refs.calendar as any).activePicker = 'DATE';
      this.calendarType = this.chart?.period.calendarType;
    }
  }

  updateStackingOptions() {
    this.chart?.toggleStacking();
    this.disableStackingButton = true;
    setTimeout(() => {
      this.disableStackingButton = false;
    }, 2000);
  }

  initThresholdAxis() {
    if (!this.isThresholdLine) return;
    const thresholdAxis = [{
      var: '',
      unit: '',
      name: '',
      scaling: { min: 0, max: 100 },
      agg: 'avg',
      type: 'line',
      seriesType: 'Threshold',
    }];
    (this.localChartData.data as any).chartOptions = [...thresholdAxis, ...this.localChartData.data.chartOptions];
  }

  async handleCalendar() {
    // eslint-disable-next-line prefer-const
    let [year, month, day, hour, minute] = this.date[0].split('-').map((e: string) => +e); // split and convert to numbers
    month -= 1; // convert month to "date" standard, ranging from 0-11 instead of 1-12
    this.datePicker = false;
    this.disableCalendarButton = true;
    this.disableNavButton = true;
    // load new calendar range starting with passed date, according to the currently set period on the chart
    // TODO: add mockdata to load
    const returnedSeries = await getSeriesMockData(
      this.periodName,
      this.chart?.start,
      this.chart?.endChart,
      { minValue: this.minValueMockData, maxValue: this.maxValueMockData, seriesCount: this.chartData.data.chartOptions.length },
    );
    this.chart?.load(returnedSeries);
    this.localDataToExport = returnedSeries;

    // generateObject for Total Sum (currently only for Difference Series)
    if (this.periodName !== Periods.LIVE) {
      this.generateObjectsStatsArrays();
    }

    this.disableNavButton = false;
    this.$emit('chartRangeChange', { start: this.chart?.start, end: this.chart?.end });
    this.updateDatePicker();

    const { start, end, endChart } = this.chart as any;
    this.$emit('handleAutarkiegrad', { start, end, endChart });
  }

  async navigationChanged(period: any) {
    clearInterval(this.intervalTimer);

    const periodForAutarkiegrad = this.periodConfigurations[period].period();
    this.$emit('handleAutarkiegrad', periodForAutarkiegrad);

    // set periodName
    this.periodName = period;
    if (this.periodName === Periods.LIVE) {
      this.disableCalendar = true;

      // activate live chart updates
      this.intervalTimer = setInterval(() => {
        const variableValues = getChartOptions(this.localChartData).map((optionsItem: any, index: number) => {
          if (this.localChartData.data.chartOptions[index].seriesType === 'Threshold') {
            return null;
          } else if (this.localChartData.data.mappings || this.localChartData.data.chartOptions[index].seriesType === 'View') {
            // gets the last value of the current series before filling up width live values
            const seriesDataLenght: any = this.chart?.chart.series[0].data.length;
            const prevValue: any = this.chart?.chart.series[index].data[seriesDataLenght - 1]?.options.y;
            return this.getSingleMockValue(prevValue);
          } else { // TODO: what if will be seriesType not VIEW
            return null;
          }
        });
        this.chart?.liveUpdateAllVariables(variableValues);
      }, 1000);
    } else {
      this.disableCalendar = false;
    }

    this.disableNavButton = true;
    // switch period and load new data for it
    this.chart?.switchPeriod(this.periodName);
    const returnedSeries = await getSeriesMockData(
      this.periodName,
      (this.chart as any)?.chartBounds.start,
      (this.chart as any)?.chartBounds.end,
      { minValue: this.minValueMockData, maxValue: this.maxValueMockData, seriesCount: this.chartData.data.chartOptions.length },
    );
    this.chart?.load(returnedSeries);
    this.localDataToExport = returnedSeries;

    // generateObject for Total Sum (currently only for Difference Series)
    if (this.periodName !== Periods.LIVE) {
      this.generateObjectsStatsArrays();
    }

    this.disableNavButton = false;
    if (this.$refs.calendar) {
      this.updateDatePicker();
    }
  }

  getSingleMockValue(previousValue: number) {
    let newValue: any = 2;
    const rangeFactor = (this.maxValueMockData - this.minValueMockData) / 70;
    let difference = Math.random() * (rangeFactor - (rangeFactor * -1)) + (rangeFactor * -1);
    difference = parseFloat(difference.toFixed(2));
    newValue = previousValue + difference;
    if (newValue > this.maxValueMockData) {
      // new Value cant get out of max bound
      newValue = this.maxValueMockData - rangeFactor;
    }
    if (newValue < this.minValueMockData) {
      // new Value cant get out of min bound
      newValue = this.minValueMockData + rangeFactor;
    }
    return newValue;
  }

  generateObjectsStatsArrays() {
    this.objectsDifferenceSeriesStats = [];
    this.objectsOtherSeriesStats = [];

    const chartOptions = getChartOptions(this.localChartData);

    chartOptions.forEach((optionsItem: any, index: number) => {
      let valuesArray;
      let seriesName = optionsItem.name;
      if (optionsItem.agg === 'diff') {
        valuesArray = this.getValuesOfSeries(index, optionsItem.type);

        const totalValue = _.sum(valuesArray) ?? 0;
        if (seriesName === '') {
          seriesName = `${optionsItem.seriesType}_${index}`;
        }
        this.objectsDifferenceSeriesStats.push({ name: seriesName, sum: totalValue.toFixed(2), unit: optionsItem.unit });
      } else {
        valuesArray = this.getValuesOfSeries(index, optionsItem.type);

        const max: number = _.max(valuesArray) ?? 0;
        const min: number = _.min(valuesArray) ?? 0;
        const avg: number = _.mean(valuesArray) ?? 0; // return avg of all numbers in array: https://www.geeksforgeeks.org/lodash-_-mean-method/

        if (seriesName === '') {
          seriesName = `${optionsItem.seriesType}_${index}`;
        }
        this.objectsOtherSeriesStats.push({ name: seriesName, minValue: min.toFixed(2), maxValue: max.toFixed(2), avgValue: avg.toFixed(2), unit: optionsItem.unit });
      }
    });
  }

  getValuesOfSeries(index: number, type: string) {
    let values;
    if (type !== 'column') {
      values = this.chart?.chart.series[index].data.map((dataElement: any) => {
        return dataElement.options.y;
      });
    } else {
      values = (this.chart?.chart.series[index] as any).yData;
    }
    return values;
  }

  created() {
    this.localChartData = _.cloneDeep(this.chartData);
    this.initThresholdAxis();
    this.tempSelectedStacking = this.localChartData.data.selectedStackingOptions;
    // init chart
    this.chart = new LynusChart(
      this.localChartData,
      this.$route.params.id,
      envApi,
      this.$vuetify.theme.dark,
      this.projectStartedAtNumber,
      this.$vuetify.theme.currentTheme.deviceBackground?.toString() ?? '#ffffff',
      this.tempSelectedStacking,
      periodConfigurations,
      this.chartColors,
      this.customApproximationValue,
    );
    if (this.isWideChart()) this.updateToWideChart();
    this.chart.updateColumnLineCounts();
    this.countColumnLineLocal();

    // Add Title if Fullscreen is active && buttons
    document.addEventListener('fullscreenchange', event => this.updateChartFullscreen());
  }

  mounted() {
    // Code that will run only after the entire view has been rendered
    // eslint-disable-next-line func-names
    this.$nextTick(function () {
      this.chart?.setLocale(this.$i18n.locale);
      this.chart?.buildChart(this.$refs.chart as HTMLElement);
      this.chart?.drawThreshold((this.localChartData.data as any)?.threshold);
    });
  }

  updated() {
    // eslint-disable-next-line func-names
    this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been re-rendered
      this.chart?.chart.reflow(); // reflow when chartWidth updates
    });
  }

  beforeDestroy() {
    const projectId = this.$route.params.id;
    if (this.tempSelectedStacking !== this.chart?.stackingOptions) {
      this.localChartData.data.selectedStackingOptions = this.chart?.stackingOptions;
      this.tempSelectedStacking = this.chart?.stackingOptions;
      // this.$store.dispatch('devices/updateDevice', { device: this.localChartData, skipReport: false });
    }
    // clear Interval when Area is Changed
    clearInterval(this.intervalTimer);
  }
}
