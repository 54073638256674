
import { Component, Prop, Watch } from 'vue-property-decorator';
import BasicControl, { SendingState } from '@/ui/components/devices/devices/BasicControl';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';
import { throttle } from 'lodash';
import { defaultFeedbackTimeoutMilliSeconds } from '@/utils/constants';

/**
 * Component that represent Slider Basic Control
 */
@Component({
  components: { FeedbackProvider },
})
export default class SliderBase extends BasicControl {
  @Prop() isActive?: boolean;
  @Prop() state!: number;
  @Prop() variableData!: string;
  @Prop() instance!: string;
  @Prop({ default: null }) appendIcon!: string;
  @Prop({ default: null }) prependIcon!: string;
  @Prop({ default: 250 }) sendingTimeout?: number;
  @Prop({ default: 0 }) minSliderValue!: number;
  @Prop({ default: 100 }) maxSliderValue!: number;
  @Prop({ default: 1 }) customStepSize!: any;
  @Prop({ default: '%' }) unit!: any;
  @Prop({ default: true }) showOutput!: any;
  @Prop({ default: defaultFeedbackTimeoutMilliSeconds }) feedBackTimeout!: number;

  timer?: NodeJS.Timeout;
  valueChangingTimer: any;

  sliderValue?: number;
  previousState = this.state;
  isSliderValueChanging = false;

  @Watch('state')
  onStateChange(val: number) {
    if (!this.isSliderValueChanging) {
      // slider not being dragged -> update slider value
      this.sliderValue = val;
    }

    // check if we got correct state update after sending
    if (this.sendingState === SendingState.Sending && val === this.sliderValue) {
      // successful send
      this.onSendSuccess();
      clearTimeout(this.timer);
    }
  }

  @Watch('isActive')
  onIsActiveChange(val: boolean) {
    if (val) {
      this.sliderValue = this.state;
    }
    if (!val) {
      clearInterval(this.valueChangingTimer);
      this.isSliderValueChanging = false;
      this.sliderValue = 0;
    }
  }

  sendValue(value: number) {
    if (typeof this.state === 'number' && this.state !== this.sliderValue) {
      this.send([{ v: value, n: this.mappingsClean.targetValue, u: '' }]);
    }
  }

  get throttledSendValue() {
    return throttle(this.sendValue, this.sendingTimeout, { leading: false });
  }

  handleStateDragging(value: number) {
    if (!this.isSliderValueChanging) {
      // slider drag start
      this.previousState = this.state;
      this.isSliderValueChanging = true;
    }

    this.sliderValue = value;
    this.throttledSendValue(value);
  }

  handleStateEnd(value: number) {
    this.sliderValue = value;

    this.onSendStart();
    this.sendValue(value);

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      // no state update after timeout, reset slider
      this.isSliderValueChanging = false;
      this.sliderValue = this.previousState;
      this.onSendError();
    }, this.feedBackTimeout);
  }

  /**
   * Used to disable the Base component if there is no variable set in the mappings of the device.
   */
  get isNotMapped() {
    return this.mappingsClean.targetValue === '' || this.state === undefined;
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }

  created() {
    this.sliderValue = this.state;
  }
}
