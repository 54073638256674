
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, State, Mutation } from 'vuex-class';
import GeneralInfoBanner from '@/ui/components/components/GeneralInfoBanner.vue';
import MembersWidget from '@/ui/components/components/MembersWidget.vue';
import DevicesListFlexGrid from '@/ui/components/lists/DevicesListFlexGrid/index.vue';
import DevicesListFavorites from '@/ui/components/lists/DevicesListFavorites/index.vue';
import DevicesList from '@/ui/components/lists/DevicesList/index.vue';
import { IDevicesState } from '@/store/modules/devices/types';
import { IProjectsState } from '@/store/modules/projects/types';
import { requestWithTimer } from '@/utils/utilsFunctions';
import EnergyVisualisation
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/index.vue';
import { IDevice } from '@/types/devices.types';
import { IMember } from '@/types/members.types';

/**
 * Favorites page, here we see all devices, MPC, charts which was added to favorites
 */
@Component({
  components: {
    EnergyVisualisation,
    GeneralInfoBanner,
    MembersWidget,
    DevicesListFlexGrid,
    DevicesListFavorites,
    DevicesList,
  },
})
export default class Favorites extends Vue {
  @State('devices') devicesState!: IDevicesState;
  @State('projects') projectsState!: IProjectsState;
  @Getter('devices/favoritesDevices') favoritesDevices!: Array<IDevice>;
  @Getter('members/currentMember') currentMember!: IMember;
  @Mutation('app/setReport') setReport!: (payload: any) => void;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;
  @Action('devices/fetchDevices') fetchDevices!: (projectId: string) => Promise<void>;
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;
  @Action('mpc/fetchMPCListByProject') fetchMPCListByProject!: () => Promise<void>;
  @Action('app/fetchWeather') fetchWeather!: (location: any) => any;
  @Action('measurements/fetchMeasurements') fetchMeasurements!: (projectId: string) => Promise<void>;
  @Action('rooms/fetchRooms') fetchRooms!: (projectId: string) => any;

  favoritesDataLoaded = false

  get project() {
    return this.projectsState.project;
  }

  get devicesFilter() {
    return this.devicesState.devicesFilter || '';
  }

  async created() {
    this.setProjectLoader(true);
    // Load measurements, rooms, devices, members, MPC, weather data
    await Promise.allSettled([
      this.fetchMeasurements(this.$route.params.id),
      this.fetchRooms(this.$route.params.id),
      this.fetchDevices(this.$route.params.id),
      this.fetchMembers(this.$route.params.id),
      requestWithTimer([
        this.fetchMPCListByProject(),
        this.fetchWeather(this.project.meta.location),
      ], this.setReport, {
        type: 'error',
        message: this.$t('uiComponents.reportMessages.loadMPCTimeoutError'),
        value: true,
      }),
    ]).finally(() => {
      this.setProjectLoader(false);
      this.favoritesDataLoaded = true;
    });
  }
}
