
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { mixins } from 'vue-class-component';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { Validation } from '@/ui/mixins/validation';
import { IInverter, SerialNumberType } from '@/types/serialNumber.types';
import { IReportBox } from '@/types/app.types';
import { TranslateResult } from 'vue-i18n';
import * as XLSX from 'xlsx';

/**
 * Component that allows to create a new project
 */
@Component({
  components: {
    ModalWindow,
  },
})
export default class CreateSerialNumber extends mixins(Validation) {
  @Action('serialNumber/createInverters') createInverters!: (inverter: {
    id: string;
    description: string;
  }[]) => Promise<void>;
  @Action('serialNumber/deleteInverter') deleteInverter!: (inverter: IInverter) => Promise<void>;
  @Action('serialNumber/fetchInverters') fetchInverters!: () => Promise<void>;
  @Getter('serialNumber/inverters') inverters!: IInverter[];
  @Mutation('app/setReport') setReport!: (report: IReportBox) => void;

  valid = false;
  isTooltip = false;
  loading = false;
  serialNumberFormatError = false;
  modelTypeFormatError = false;
  duplicates = false;

  showDuplicates = false;
  showOnlyFaulty = false;

  fileObject: any = [];
  inverterArray: { id: string; description: string; type: SerialNumberType }[] = [];

  search = '';

  serialNumberTypeItems = [
    {
      key: this.$t('modals.createSerialNumber.form.inverter'),
      value: 0,
    },
    {
      key: this.$t('modals.createSerialNumber.form.chargeStation'),
      value: SerialNumberType.ChargeStation,
    },
  ]
  serialNumberType: { key: string | TranslateResult; value: number } | null = null;

  get currentLang() {
    return this.$i18n.locale;
  }

  @Watch('currentLang', { deep: true })
  handleLangChange() {
    this.validate();
  }

  get filteredItems() {
    const items = [...this.inverterArray];
    let filteredBySearchItems: { id: string; description: string; type: SerialNumberType }[] = [];

    if (!this.search || this.search.length === 0) {
      filteredBySearchItems = items;
    } else {
      filteredBySearchItems = items.filter((inverter: {
        id: string;
        description: string;
        type: SerialNumberType;
      }) => {
        return (
          inverter.id.toLowerCase().includes(this.search.toLowerCase()) ||
          inverter.description?.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    }

    if (this.showOnlyFaulty || this.showDuplicates) {
      filteredBySearchItems = filteredBySearchItems.filter((inverter: {
        id: string;
        description: string;
        type: SerialNumberType;
      }) => {
        const styles = [...this.rowStyle(inverter, 'id'), ...this.rowStyle(inverter, 'description')];
        if (this.showOnlyFaulty && this.showDuplicates) {
          return styles.length > 0;
        } else if (this.showOnlyFaulty) {
          return styles.filter((style: string) => style !== 'duplicate').length > 0;
        } else if (this.showDuplicates) {
          return styles.includes('duplicate');
        }
        return true;
      });
    }

    return filteredBySearchItems;
  }

  serialNumbersCheck() {
    this.serialNumberFormatError = false;
    this.modelTypeFormatError = false;
    this.duplicates = false;
    this.inverterArray.forEach((inverter: {
      id: string;
      description: string;
      type: SerialNumberType;
    }) => {
      // checks for format on serial number
      if (inverter.id.length !== 10) this.serialNumberFormatError = true;
      if (this.inverters.some((inverter2: IInverter) => inverter2.id === inverter.id && inverter2.type === inverter.type)) this.duplicates = true;
    });
  }

  async onDialogStatusChange(status: boolean) {
    // clean local project + projectFilterTags when close dialog
    if (!status) {
      this.resetForm();
    } else {
      await this.$nextTick();
      this.validate();
    }
  }

  rowStyle(item: { id: string; description: string; type: SerialNumberType }, property: string) {
    const styles = [];

    if (property === 'id') {
      if (this.inverters.some((inverter: IInverter) => inverter.id === item.id && inverter.type === item.type)) {
        styles.push('duplicate');
      }
      if (item.id.length !== 10) {
        styles.push('serial-number-format-error');
      }
    } else {
      if (item.description === '') {
        styles.push('empty-model');
      }
    }

    return styles;
  }

  validate() {
    (this.$refs.form as any)?.validate();
  }

  isHybrid(modelType: string) {
    return modelType.toLowerCase().includes('hybrid inverter');
  }

  async createNewSerialNumber() {
    if (this.inverterArray.length === 0) return;
    await this.createInverters(this.inverterArray);

    this.resetForm();
    await (this.$refs.ModalWindowInstance as any).closeDialog();
  }

  resetForm() {
    this.inverterArray = [];
    this.fileObject = [];
    this.serialNumberType = null;
  }

  async handleFileSelect() {
    const files = this.fileObject as File[];
    this.loading = true;
    const readFilesPromises = files.map(file => {
      if (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.name.endsWith('.csv') || file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
        // Process csv and excel files
        return this.readFile(file);
      }
      return Promise.resolve(); // Return a resolved promise for other files
    });

    // Wait for all readFile promises to complete
    const result: any = await Promise.all(readFilesPromises);
    this.inverterArray = [];
    result.forEach((res: any) => {
      this.inverterArray = [...this.inverterArray, ...res.inverterArray];
    });
    this.serialNumbersCheck();
    this.loading = false;
  }

  readFile(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const text = e.target.result;
        let lines: string[] = [];
        let headers: string[] = [];
        let isFirstSheet = true;

        if (file.type === 'text/csv' || file.name.endsWith('.csv')) {
          lines = text.split('\n').map((line: string) => line.replace(/\r/g, ''));
          headers = lines[0].split(';');
          lines = lines.slice(1); // Remove the header line
        } else if (file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
          const workbook = XLSX.read(text, { type: 'binary' });
          workbook.SheetNames.forEach((sheetName: string) => {
            const worksheet = workbook.Sheets[sheetName];
            const sheetLines = XLSX.utils.sheet_to_csv(worksheet).split('\n').map((line: string) => line.replace(/\r/g, ''));
            if (isFirstSheet) {
              headers = sheetLines[0].split(',');
              lines = [...lines, ...sheetLines.slice(1)]; // Remove the header line from the first sheet
              isFirstSheet = false;
            } else {
              lines = [...lines, ...sheetLines.slice(1)]; // Remove the header line from subsequent sheets
            }
          });
        } else {
          reject(new Error('Unsupported file format'));
          return;
        }

        const separator = file.type === 'text/csv' || file.name.endsWith('.csv') ? ';' : ',';
        const mTypeIndex = headers.indexOf('M_Type');
        const mSnCodeIndex = headers.indexOf('M_SnCode');
        const systemTypeIndex = headers.indexOf('SystemType');

        if (mTypeIndex === -1) {
          this.setReport({
            type: 'error',
            message: 'File does not contain "M_SnCode" columns',
            value: true,
            timeout: 20000,
          });
          return;
        }

        if (systemTypeIndex === -1) {
          this.setReport({
            type: 'error',
            message: 'File does not contain "SystemType" column',
            value: true,
            timeout: 20000,
          });
          return;
        }

        const inverterArray: { id: string; description: string; type: SerialNumberType }[] = [];
        let type = SerialNumberType.HybridInverter;
        lines.forEach((line: any, index: number) => {
          if (line.trim() && line.split(separator).filter(Boolean).length > 1) {
            const values = line.split(separator);
            if (index === 0) {
              const systemtype = values[systemTypeIndex];
              if (systemtype === 'Hybrid Inverter') type = SerialNumberType.HybridInverter;
              else if (systemtype === 'String Inverter') type = SerialNumberType.StringInverter;
              else if (systemtype === 'Ladestation') type = SerialNumberType.ChargeStation;
              else type = -1;
            }
            if (values.length !== headers.length) return;
            const description = values[mTypeIndex];
            const id = values[mSnCodeIndex];
            inverterArray.push({
              id,
              description,
              type,
            });
          }
        });

        resolve({ inverterArray, filename: file.name });
      };
      reader.onerror = reject; // Reject the promise on error
      reader.readAsBinaryString(file);
    });
  }

  get headers() {
    return [
      { text: 'M_SnCode', align: 'start', value: 'number', width: '45%' },
      { text: 'M_Type', align: 'start', value: 'number', width: '45%' },
    ];
  }

  created() {
    this.validate();
  }
}
