
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, Mutation, State } from 'vuex-class';
import { INavigationState } from '@/store/modules/navigation/types';
import Search from '@/ui/components/components/Search.vue';
import UserProfile from '@/ui/components/components/UserProfile.vue';
import Localisation from '@/ui/components/components/Localisation.vue';
import StatusIndicator from '@/ui/components/components/StatusIndicator.vue';
import CreateProject from '@/ui/components/modals/CreateProject.vue';
import ManageRoom from '@/ui/components/modals/ManageRoom.vue';
import ManageDevice from '@/ui/components/modals/ManageDevice/index.vue';
import ManageAnomalyDetection from '@/ui/components/modals/ManageAnomalyDetection/index.vue';
import ManageCharts from '@/ui/components/modals/ManageChart/index.vue';
import ManageMLModel from '@/ui/components/modals/ManageMLModel/index.vue';
import OfflineTimer from '@/ui/components/components/OfflineTimer.vue';
import NewReport from '@/ui/components/modals/ManageReport/NewReport.vue';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import ManageRule from '@/ui/components/modals/ManageRule/index.vue';
import CreatePartnerProject from '@/ui/components/modals/Partner/CreatePartnerProject.vue';
import { displayProjectOfflineStatus, plcVersionDate } from '@/utils/versionManagementUtils';
import { envWorkbenchButtons } from '@/utils/env';
import { mapMeasurementsList } from '@/utils/utilsFunctions';
import { IProjectsState } from '@/store/modules/projects/types';
import { IAppState } from '@/store/modules/app/types';
import { IAnomalyDetectionState } from '@/store/modules/anomalyDetection/types';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IMPCState } from '@/store/modules/mpc/types';
import { IMQTTState } from '@/store/modules/mqtt/types';
import ProjectFilterPopup from '@/ui/components/components/ProjectFilterPopup.vue';
import PartnerProjectFilter from '@/ui/components/components/PartnerProjectFilter.vue';
import { IProject } from '@/types/project.types';
import { UserRoleCode } from '@/utils/userRoles';
import { ITenantWizardState } from '@/store/modules/tenantWizard/types';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { IDevice } from '@/types/devices.types';
import ToggleThemesButton from '@/ui/components/components/ToggleThemesButton.vue';
import { IMeasurements } from '@/types/measurements.types';
import { CanAccessCheck, IMember } from '@/types/members.types';
import InternetStatusIndicator from '@/ui/components/components/InternetStatusIndicator.vue';
import CreateSerialNumber from '@/ui/components/modals/CreateSerialNumber.vue';

/**
 * Component that represent control panel on the top of dashboard
 */
@Component({
  computed: {
    UserRoleCode: () => UserRoleCode,
  },
  components: {
    CreateSerialNumber,
    InternetStatusIndicator,
    ToggleThemesButton,
    ManageMLModel,
    ManageDevice,
    ManageCharts,
    ManageAnomalyDetection,
    Search,
    UserProfile,
    Localisation,
    StatusIndicator,
    CreateProject,
    ManageRoom,
    OfflineTimer,
    NewReport,
    ModalWindow,
    ProjectFilterPopup,
    ManageRule,
    InfoTooltip,
    CreatePartnerProject,
    PartnerProjectFilter,
  },
})
export default class AppBar extends Vue {
  @State('navigation') navigation!: INavigationState;
  @State('projects') projectsState!: IProjectsState;
  @State('anomalyDetection') anomalyDetectionState!: IAnomalyDetectionState;
  @State('app') appState!: IAppState;
  @State('mpc') mpcState!: IMPCState;
  @State('measurements') measurementsState!: IVariablesState;
  @State('mqttClient') mqttState!: IMQTTState;
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Getter('projects/project') project!: IProject;
  @Getter('rules/rulesLength') rulesLength!: number;
  @Getter('measurements/measurements') measurements!: IMeasurements;
  @Getter('members/currentMember') currentMember!: IMember;
  @Getter('members/canMemberAccess') canMemberAccess!: CanAccessCheck;
  @Getter('measurements/measurementsFilterCount') measurementsFilterCount!: number;
  @Mutation('navigation/handleDrawer') handleDrawer?: (payload: boolean) => void;
  @Mutation('tenantWizard/setWasTenantRestarted') setWasTenantRestarted!: (data: boolean) => void;
  @Action('rooms/createRoom') createRoomState!: (roomName: string) => void;
  @Action('devices/createDevice') createDevice!: (control: IDevice) => void;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;
  @Action('mpc/createMCCInstance') createMCCInstance!: (mpc: any) => void;
  @Action('projects/loadProject') loadCurrentProject!: (projectId: string) => void;

  showProjectStatus = false;
  showInternetStatus = false;

  handleOfflineTimerMessage(value: boolean) {
    this.showProjectStatus = value;

    let isLoggerProject = false;
    // check if the project was created from the date of 14.03.2024
    if (this.project.meta?.controller?.batterySystemType) {
      isLoggerProject = this.project.meta?.controller?.batterySystemType.toString() === 'Solarman Logger (Deye)';
    }
    this.showInternetStatus = !isLoggerProject && this.showProjectStatus && (plcVersionDate(this.project).getTime() > displayProjectOfflineStatus.getTime());
  }

  get disableActionButtons() {
    return this.currentMember?.role !== UserRoleCode.admin;
  }

  get drawer() {
    return this.navigation.drawer;
  }

  get projectStatus() {
    return this.mqttState.online;
  }

  get projectName() {
    return this.projectsState.project.name;
  }

  get dndStatus() {
    if (this.project) {
      return this.project.meta?.isDNDActive;
    }
    return false;
  }

  get isAreasMiniView() {
    if (this.project) {
      return this.project.meta?.isAreasMiniView;
    }
    return false;
  }

  get workbenchCurrentTab() {
    return this.appState.workbenchCurrentTab;
  }

  get isNavigation() {
    return (this.$route.meta as any).navigation;
  }

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  get iconsSize() {
    return this.$vuetify.breakpoint.mobile ? 13 : 20;
  }

  /**
   * Collection of Workbench buttons
   */
  get workbenchButtons() {
    return [
      {
        id: 'device',
        form: 'ManageDevice',
        formTitle: this.$t('modals.manageDevice.createDeviceTitle'),
        btnTitle: this.$t('uiComponents.buttons.addDevice'),
        visible: true,
      },
      {
        id: 'aiml',
        form: 'ManageAnomalyDetection',
        formTitle: this.$t('modals.manageAnomalyDetection.createAnomalyDetectionTitle'),
        btnTitle: this.$t('uiComponents.buttons.addAnomalyDetection'),
        visible: true,
      },
      {
        id: 'charts',
        form: 'ManageCharts',
        formTitle: this.$t('modals.manageCharts.createChartTitle'),
        btnTitle: this.$t('uiComponents.buttons.addChart'),
        visible: true,
      },
      {
        id: 'mpc',
        form: 'ManageMLModel',
        formTitle: this.$t('modals.manageMLModel.createDeviceTitle'),
        btnTitle: this.$t('uiComponents.buttons.addMlModel'),
        visible: this.isWeatherServiceActive,
      },
    ].filter(e => envWorkbenchButtons.includes(e.form));
  }

  get isWeatherServiceActive() {
    return this.mpcState.isWeatherServiceActive;
  }

  get mlModelTypes() {
    return Object.keys(this.mpcState.mlModelTypes);
  }

  get anomalyDetectionTypes() {
    return Object.keys(this.anomalyDetectionState.anomalyDetectionTypes);
  }

  get measurementsList() {
    return mapMeasurementsList(this.measurements);
  }

  get measurementsFilter() {
    return this.measurementsState.measurementsFilter;
  }

  get isDNDButtonVisible() {
    return (this.$route.meta as any).dndSwitch
      && !this.$vuetify.breakpoint.mobile
      && this.canMemberAccess('ProjectActions', 'DragAndDrop');
  }

  get appBarTitle() {
    switch (this.$route?.meta?.advancedTitle) {
      case 'numberOfMeasurements':
        return `${this.$t(this.$route?.meta?.locale)} (${this.measurementsFilterCount})`;
      case 'numberOfRules':
        return `${this.$t(this.$route?.meta?.locale)} (${this.rulesLength})`;
      default:
        return this.$t(this.$route?.meta?.locale);
    }
  }

  /**
   * Toggle area view between mini view and default view
   */
  async handleAreasView() {
    await this.updateProject({
      ...this.project,
      meta: {
        ...this.project.meta,
        isAreasMiniView: !this.isAreasMiniView,
      },
    });
  }

  /**
   * Toggle drag and drop
   */
  async handleDnd() {
    await this.updateProject({
      ...this.project,
      meta: {
        ...this.project.meta,
        isDNDActive: !this.dndStatus,
      },
    });
  }

  closeRuleDialog() {
    (this.$refs.RuleModalWindowInstance as any).closeDialog();
  }

  closeReportDialog() {
    (this.$refs.ModalWindowInstance as any).closeDialog();
  }

  createRoom(payload: any) {
    this.createRoomState(payload);
  }

  /**
   * Launch creation method according to device type.
   * @param payload device, MPC data
   */
  handleWorkbenchButton(payload: IDevice | any) {
    const mpcApiTypes: string[] = [...this.mlModelTypes, ...this.anomalyDetectionTypes];
    const isMpc: boolean = mpcApiTypes.some((type: string) => type === payload.data.type);
    if (isMpc) {
      this.createMCCInstance(payload);
    } else {
      this.createDevice(payload);
    }
  }

  handleRestartWizard() {
    this.setWasTenantRestarted(true);
  }
}
