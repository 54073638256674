import { cloneDeep } from 'lodash';
import tinycolor from 'tinycolor2';

export default class ChartColors {
  static readonly baseColors = [
    '#0f72ba',
    '#00c853',
    '#f57c00',
    '#8085e9',
    '#f34848',
    '#2baae2',
    '#009688',
    '#ee3562',
    '#7cb342',
  ];

  static colors(isDarkTheme: boolean): string[] {
    const colors = cloneDeep(this.baseColors);
    return this.colorsByTheme(colors, isDarkTheme);
  }

  static colorsByTheme(colors: any, isDarkTheme: any): string[] {
    return isDarkTheme
      ? colors.map((e: any) => tinycolor(e).lighten(7).toHexString())
      : colors.map((e: any) => tinycolor(e).darken(2).toHexString());
  }
}
