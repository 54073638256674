import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IMeasurements } from '@/types/measurements.types';
import {
  EnergyCircleType,
  IOEnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';
import {
  ArrowDirection,
  EnergyLineColors,
} from '@/types/energyVisualisation/EnergyLineDisplayData';
import {
  energyConsumerColors,
  energyGreen,
  energyGrey,
  energyGreyColors,
  energyRed, energyYellow,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/constants';
import { IDevice } from '@/types/devices.types';

/**
 * Helper for extracting data from EnergyView, EMS.
 */
@Component
export default class EnergyIOComponent extends Vue {
  @Prop({ default: null }) deviceData!: IDevice;
  @Getter('measurements/measurements') measurements!: IMeasurements;

  getSystemCount(type: IOEnergyCircleType): number {
    return this.deviceData.data.meta.controllerMappings?.[type]?.count ?? 0;
  }

  filterPresentIoSystems(systems: IOEnergyCircleType[]) {
    return systems.filter((systemType: IOEnergyCircleType) => this.isIoSystemPresent(systemType));
  }

  isIoSystemPresent(systemType: IOEnergyCircleType) {
    return this.deviceData.data.meta.controllerMappings?.[systemType]?.count ?? 0;
  }

  getIoSystemComponents(type: IOEnergyCircleType): Record<string, any> {
    return this.deviceData.data.meta.controllerMappings[type]?.components ?? {};
  }

  getMappedIoSystemComponents(type: IOEnergyCircleType): Record<string, any> {
    const components = this.getIoSystemComponents(type);
    return Object.keys(components).map((key: string) => {
      return {
        id: key,
        ...components[key],
      };
    });
  }
  getVariableValue(device: any, type: IOEnergyCircleType, systemInstance: string, variableToLookFor: string, variableInstance: string) {
    return device.data.meta.controllerMappings[type].components[systemInstance][variableToLookFor];
  }

  getAlternativeArrowDirection(value?: number): ArrowDirection | undefined {
    return value !== null && value !== undefined && value > 0
      ? ArrowDirection.awayFromCenter
      : undefined;
  }

  getAlternativeLineColors(value?: number): EnergyLineColors {
    return value != null && value > 0
      ? energyConsumerColors
      : energyGreyColors;
  }

  defaultColorByType(type: IOEnergyCircleType, value: number, hybridColor: null | string = null): string {
    if (type === IOEnergyCircleType.inputs) {
      return value !== 0 ? energyYellow : energyGrey;
    }
    if (type === IOEnergyCircleType.outputs) {
      return value !== 0 ? energyGreen : energyGrey;
    }
    if (type === IOEnergyCircleType.hybrids && hybridColor) {
      return value !== 0 ? hybridColor : energyGrey;
    }
    if (value > 0) {
      return energyRed;
    }
    if (value < 0) {
      return energyGreen;
    }
    return energyGrey;
  }
}
