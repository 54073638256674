
import { Component, Watch } from 'vue-property-decorator';
import LightDimmer from '@/ui/components/devices/devices/LightDimmer.vue';
import LightSwitch from '@/ui/components/devices/devices/LightSwitch.vue';
import Temperature from '@/ui/components/devices/devices/Temperature.vue';
import VariableInputField from '@/ui/components/devices/devices/VariableInputField.vue';
import VariableOutputField from '@/ui/components/devices/devices/VariableOutputField.vue';
import VariableTextInputField from '@/ui/components/devices/devices/VariableTextInputField.vue';
import VariableTextOutputField from '@/ui/components/devices/devices/VariableTextOutputField.vue';
import LightRGB from '@/ui/components/devices/devices/LightRGB.vue';
import SocketSwitch from '@/ui/components/devices/devices/SocketSwitch.vue';
import LightPushButton from '@/ui/components/devices/devices/LightPushButton.vue';
import ControlBlinds from '@/ui/components/devices/devices/ControlBlinds.vue';
import ControlShutter from '@/ui/components/devices/devices/ControlShutter.vue';
import ControlAwning from '@/ui/components/devices/devices/ControlAwning.vue';
import ThermostatDigital from '@/ui/components/devices/devices/ThermostatDigital.vue';
import ThermostatAnalog from '@/ui/components/devices/devices/ThermostatAnalog.vue';
import BrightnessSensor from '@/ui/components/devices/devices/BrightnessSensor.vue';
import AirHumiditySensor from '@/ui/components/devices/devices/AirHumiditySensor.vue';
import SensorCO2 from '@/ui/components/devices/devices/SensorCO2.vue';
import SensorLevel from '@/ui/components/devices/devices/SensorLevel.vue';
import WeatherStation from '@/ui/components/devices/devices/WeatherStation.vue';
import HotWaterMeter from '@/ui/components/devices/devices/HotWaterMeter.vue';
import ColdWaterMeter from '@/ui/components/devices/devices/ColdWaterMeter.vue';
import ElectricityMeter from '@/ui/components/devices/devices/ElectricityMeter.vue';
import HeatingMeter from '@/ui/components/devices/devices/HeatingMeter.vue';
import MotionSensor from '@/ui/components/devices/devices/MotionSensor.vue';
import Battery from '@/ui/components/devices/devices/Battery.vue';
import PVSystem from '@/ui/components/devices/devices/PVSystem.vue';
import HouseConsumption from '@/ui/components/devices/devices/HouseConsumption.vue';
import Generator from '@/ui/components/devices/devices/Generator.vue';
import MainsConnection from '@/ui/components/devices/devices/MainsConnection.vue';
import ElectricChargingStation from '@/ui/components/devices/devices/ElectricChargingStation.vue';
import ElectricChargingStationV2 from '@/ui/components/devices/devices/ElectricChargingStationV2.vue';
import ElectronicBoiler from '@/ui/components/devices/devices/ElectronicBoiler.vue';
import VentilatorSwitch from '@/ui/components/devices/devices/VentilatorSwitch.vue';
import MusicSystem from '@/ui/components/devices/devices/MusicSystem.vue';
import TV from '@/ui/components/devices/devices/TV.vue';
import MotorWithoutVFD from '@/ui/components/devices/devices/MotorWithoutVFD.vue';
import PumpWithoutVFD from '@/ui/components/devices/devices/PumpWithoutVFD.vue';
import PumpWithVFD from '@/ui/components/devices/devices/PumpWithVFD.vue';
import MotorWithVFD from '@/ui/components/devices/devices/MotorWithVFD.vue';
import MixingValve from '@/ui/components/devices/devices/MixingValve.vue';
import Ventilation from '@/ui/components/devices/devices/Ventilation.vue';
import Gauge from '@/ui/components/devices/devices/Gauge.vue';
import RegentLighting from '@/ui/components/devices/devices/RegentLighting.vue';
import HeatingCircuitOptimization from '@/ui/components/devices/mpc/HeatingCircuitOptimization/index.vue';
import VueGridLayout from 'vue-grid-layout';
import { Action, Getter, State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { DND } from '@/ui/mixins/dnd';
import Chart from '@/ui/components/devices/charts/charts/ChartDevice.vue';
import DropDownOutputFieldWithVariable from '@/ui/components/devices/devices/DropDownOutputFieldWithVariable.vue';
import DropDownInputFieldWithVariable from '@/ui/components/devices/devices/DropDownInputFieldWithVariable.vue';
import TSGFrischwasser from '@/ui/components/devices/devices/TSGFrischwasser.vue';
import TSGBrauchwasser from '@/ui/components/devices/devices/TSGBrauchwasser.vue';
import Robot from '@/ui/components/devices/devices/Robot.vue';
import SurveyClient from '@/ui/components/devices/devices/SurveyClient.vue';
import PVProductionService from '@/ui/components/devices/mpc/PVProductionService/index.vue';
import MpcEnergyView from '@/ui/components/devices/mpc/EnergyView/index.vue';
import EnergyViewV2 from '@/ui/components/devices/devices/EnergyViewV2/index.vue';
import ConsumptionService from '@/ui/components/devices/mpc/ConsumptionService/index.vue';
import HppProductionService from '@/ui/components/devices/mpc/HeatingProduction/index.vue';
import TSGLadestationNotAus from '@/ui/components/devices/devices/TSGLadestationNotAus.vue';
import EMS from '@/ui/components/devices/mpc/EMS/index.vue';
import EMSV2 from '@/ui/components/devices/devices/EMS/index.vue';
import GeneralSwitchV2 from '@/ui/components/devices/devices/GeneralSwitchV2.vue';
import PVMonitoringService from '@/ui/components/devices/mpc/PVMonitoringService/index.vue';
import HistoryAnomalyDetection
  from '@/ui/components/devices/anomalyDetection/HistoryAnomalyDetection.vue';
import StreamAnomalyDetection
  from '@/ui/components/devices/anomalyDetection/StreamAnomalyDetection.vue';
import { IProjectsState } from '@/store/modules/projects/types';
import SetpointOptimizer from '@/ui/components/devices/mpc/SetpointOptimizer/index.vue';
import TSGModulLadestation from '@/ui/components/devices/devices/TSGModulLadestation.vue';
import { IDevice } from '@/types/devices.types';
import EnergyIO from '@/ui/components/devices/devices/EnergyIO/index.vue';

/**
 * Component that represent all devices list in Drag and Drop grid.
 * Used in Favorites page.
 */
@Component({
  components: {
    TSGModulLadestation,
    HppProductionService,
    SetpointOptimizer,
    StreamAnomalyDetection,
    HistoryAnomalyDetection,
    PVMonitoringService,
    GeneralSwitchV2,
    EMSV2,
    EMS,
    EnergyIO,
    TSGLadestationNotAus,
    ConsumptionService,
    PVProductionService,
    EnergyViewV2,
    MpcEnergyView,
    SurveyClient,
    Robot,
    TSGBrauchwasser,
    TSGFrischwasser,
    DropDownInputFieldWithVariable,
    DropDownOutputFieldWithVariable,
    HeatingCircuitOptimization,
    Gauge,
    Ventilation,
    MixingValve,
    MotorWithVFD,
    PumpWithVFD,
    PumpWithoutVFD,
    MotorWithoutVFD,
    TV,
    MusicSystem,
    VentilatorSwitch,
    ElectronicBoiler,
    ElectricChargingStation,
    ElectricChargingStationV2,
    MainsConnection,
    Generator,
    HouseConsumption,
    PVSystem,
    Battery,
    MotionSensor,
    ElectricityMeter,
    HeatingMeter,
    ColdWaterMeter,
    HotWaterMeter,
    WeatherStation,
    SensorLevel,
    SensorCO2,
    BrightnessSensor,
    AirHumiditySensor,
    ThermostatAnalog,
    ThermostatDigital,
    ControlShutter,
    ControlAwning,
    ControlBlinds,
    LightRGB,
    SocketSwitch,
    LightPushButton,
    LightSwitch,
    LightDimmer,
    Temperature,
    VariableOutputField,
    VariableInputField,
    VariableTextInputField,
    VariableTextOutputField,
    RegentLighting,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    chart: Chart,
  },
})
export default class DevicesListFavorites extends mixins(DND) {
  @State('app') appState!: any;
  @State('mqttClient') mqttState!: any;
  @State('devices') devicesState!: any;
  @State('projects') projectsState!: IProjectsState;
  @Getter('projects/project') project!: any;
  @Getter('devices/favoritesDevices') favoritesDevices!: Array<IDevice>;
  @Getter('members/currentMember') currentMember!: any;
  @Getter('members/currentMemberFavoriteDevicesPositions') currentMemberFavoriteDevicesPositions!: any;
  @Getter('members/currentMemberFavoriteDevices') currentMemberFavoriteDevices!: any;
  @Action('members/updateMemberMeta') updateMemberMeta!: (meta: any) => Promise<void>;

  dndLayout = []
  chartInstanceWidth: any = null
  gridLayoutContainerWidth: any = null
  filteredDevicesWithPositions: any = []
  finalProjectWithNewPositions: any = null

  @Watch('favoritesDevices')
  onDevicesChange(devices: any) {
    this.initDndDevices(this.favoritesDevices);
  }
  @Watch('dndStatus')
  async onDNDStatusChange(val: boolean) {
    if (!val) {
      await this.saveDevicesPositions();
    }
  }

  get isProjectOnline() {
    if (this.projectsState.project?.meta?.disableDevicesWhenOffline) return this.mqttState.online;
    else return true;
  }

  get dndStatus() {
    if (this.project) return (this.project.meta as any).isDNDActive;
    return false;
  }
  get devicesTypes() {
    return Object.keys(this.devicesState.devicesTypes);
  }
  get favoritesDevicesPositions() {
    return this.currentMemberFavoriteDevicesPositions;
  }

  get user() {
    return this.appState.user;
  }

  async saveDevicesPositions() {
    const member = JSON.parse(JSON.stringify(this.currentMember));
    this.createMemberMeta(member);
    this.addFavoritesDevicesPositions(member);
    this.actualizationOfDevicesFavoritesList(member);

    await this.updateMemberMeta({ project_id: this.project.id, member });
  }

  createMemberMeta(member: any) {
    if (!member?.meta) member.meta = { favorite_positions: [] };
    if (!member?.meta?.favorite_positions) member.meta.favorite_positions = [];
  }
  actualizationOfDevicesFavoritesList(member: any) {
    const local = this.filteredDevicesWithPositions.length
      ? this.filteredDevicesWithPositions.map((el: any) => el.i)
      : [];
    if (member?.meta?.favorite_devices?.length) {
      member.meta.favorite_devices = member.meta.favorite_devices.filter((id: string) => local.includes(id));
    }
  }
  addFavoritesDevicesPositions(member: any) {
    member.meta.favorite_positions = this.filteredDevicesWithPositions;
  }

  currentDevicePositions(device: any) {
    return this.favoritesDevicesPositions.find((item: any) => item.i === device.id);
  }

  /**
   * Prepares list of devices for Drag and Drop grid
   * @param {array} devices list of devices
   */
  initDndDevices(devices: any) {
    const lastDeviceOnFirstCol = () => {
      if (!this.favoritesDevicesPositions.length) return;
      // filter all devices which on position x = 0, return their y-positions
      const arr = this.favoritesDevicesPositions.filter((device: any) => device.x === 0).map((d: any) => d.y);
      // define last y-position
      const lastPos = Math.max.apply(null, arr);
      return this.favoritesDevicesPositions.filter((device: any) => device.x === 0).find((el: any) => el.y === lastPos);
    };
    const lastDeviceOnFirstColSize = lastDeviceOnFirstCol() ? this.sizesForDnD(devices.find((dev: any) => dev.id === lastDeviceOnFirstCol().i)?.data?.type) : null;
    // define start position for new device what was added
    const startPositionForNewDevice = lastDeviceOnFirstColSize && lastDeviceOnFirstCol() ? lastDeviceOnFirstColSize.h + lastDeviceOnFirstCol().y : 0;

    this.dndLayout = devices.map((device: any) => {
      const isFullWidthChart = device.data.selectedWidth === 'full';

      const positions = () => {
        if (this.favoritesDevicesPositions.length) {
          // need when chart instance switch between full width and half width
          let snapshot = null;
          if (this.currentDevicePositions(device)) {
            snapshot = { ...this.currentDevicePositions(device) };
            if (isFullWidthChart) snapshot.x = 0;
            else snapshot.x = this.currentDevicePositions(device).x;
          }

          return snapshot || {
            x: 0,
            y: startPositionForNewDevice,
            i: device.id,
          };
        }
        return {
          x: 0,
          y: 0,
          i: device.id,
        };
      };
      return {
        device,
        ...positions(),
        ...this.sizesForDnD(device.data.type),
      };
    });
    this.$nextTick(() => {
      const gridLayoutContainerPadding = 40;
      this.chartInstanceWidth = Math.round((this.$refs.layout as any).$el.clientWidth / 2) - 20;
      this.gridLayoutContainerWidth = (this.$refs.layout as any).$el.clientWidth - gridLayoutContainerPadding;
    });

    // define sizes for EnergyView, charts
    this.dndLayout.forEach((item: any, index: number) => {
      const isChartOrAnomalyDevices: boolean = ['chart', 'HistoryAnomalyDetection', 'StreamAnomalyDetection']
        .some((type: string) => type === item.device.data.type);
      if (isChartOrAnomalyDevices) {
        if (item.device.data.selectedWidth === 'half') {
          item.w = 2;
        } else {
          item.w = 4;
        }
      }
    });
  }

  /**
   * Saves actual devices positions when Drag and Drop layout was updated
   * @param {array} layout Drag and Drop grid items list
   */
  layoutUpdatedEvent(layout: any) {
    this.filteredDevicesWithPositions = layout.map((item: any) => ({
      x: item.x,
      y: item.y,
      i: item.i,
    }));
    if (this.project) {
      // const copy = JSON.parse(JSON.stringify(this.project));
      const copy2 = JSON.parse(JSON.stringify(this.project));
      // copy.meta.favoritesDevicesPositions = this.filteredDevicesWithPositions;
      copy2.meta.favoritesDevicesPositions = copy2?.meta?.favoritesDevicesPositions?.length ? copy2.meta.favoritesDevicesPositions : {};
      copy2.meta.favoritesDevicesPositions[this.user.id] = this.filteredDevicesWithPositions;
      this.finalProjectWithNewPositions = copy2;
      // console.log(this.finalProjectWithNewPositions);
    }
  }

  mounted() {
    this.initDndDevices(this.favoritesDevices);
  }
}
