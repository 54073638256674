
import { Vue, Component, Inject } from 'vue-property-decorator';
import OfflineChart from '@/ui/components/devices/previews/charts/components/OfflineChart.vue';

@Component({
  components: {
    OfflineChart,
  },
})
export default class TimeTable extends Vue {
  @Inject({ from: 'chartColors' }) injectedChartColors!: string[];

  reRenderKey = 0
  systems: any = ['battery', 'charge_station', 'electric_heating', 'heating_pump', 'big_consumer']
  systemsScaling: any = {
    battery: { min: -100, max: 100 },
    charge_station: { min: 0, max: 100 },
    electric_heating: { min: 0, max: 100 },
    heating_pump: { min: 0, max: 100 },
    big_consumer: { min: 0, max: 100 },
  }
  currentChart: any = {
    system: null,
    vars: [],
  }

  get mockChartData() {
    let currentChartOptions: any = [];
    currentChartOptions = [
      {
        agg: 'first',
        name: 'var-1',
        scaling: {
          max: '100',
          min: '-100',
        },
        seriesType: 'View',
        type: 'line',
        unit: 'kWh',
        var: '',
      }, {
        agg: 'first',
        name: 'var-2',
        scaling: {
          max: '100',
          min: '-100',
        },
        seriesType: 'View',
        type: 'line',
        unit: 'kW',
        var: '',
      },
    ];

    const currentData = {
      data: {
        chartOptions: currentChartOptions,
        selectedStackingOptions: 'normal',
        selectedWidth: 'full',
        type: 'chart',
      },
      created_at: '2021-10-07T07:43:33.554983Z',
    };
    return currentData;
  }

  get systemSchema() {
    return [
      ['battery', [['battery1', 'battery2']]],
      ['charge_station', [['charge_station1', 'charge_station2']]],
      ['electric_heating', [['electric_heating1', 'electric_heating2']]],
      ['heating_pump', [['heating_pump1', 'heating_pump2']]],
      ['big_consumer', [['big_consumer1', 'big_consumer2']]],
    ];
  }

  /**
   * Creates a name for the menu item from an array of instances.
   * @param {array} arr list on system instances names
   * @return {string} menu item name. Example: 1-9
   */
  defineMenuItemName(arr: any) {
    const filterFromNotNumber = (str: string) => +str.replace(/\D+/g, '');
    if (!arr.length) return null;
    if (arr.length === 1) return filterFromNotNumber(arr[0]);
    else {
      const last: any = arr[arr.length - 1];
      return `1-${filterFromNotNumber(last)}`;
    }
  }

  handleCurrentChart(system: any, arr: any) {
    this.currentChart = { system, vars: arr };
    this.reRenderKey += 1;
  }

  mounted() {
    this.currentChart = { system: 'battery', vars: ['var-1', 'var-2'] };
  }
}
