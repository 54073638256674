import moment from 'moment-timezone';
import { clamp, isNil } from 'lodash';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/ui/plugins/i18n';
import { IRule, IRuleActions, IRuleActionsEmailParams } from '@/types/rules.types';
import { IMember } from '@/types/members.types';
import { IDragNDropElementPosition } from '@/types/app.types';
import { HasId } from '@/types/common.types';

/**
 * This function converts a time parameter to the time in minutes. Starting from 00:00 = 0 to 24:00 = 1.440.
 * Times will be in UTC Time !
 * @param time is a string in the format of HH:MM
 * @returns time in minutes as number
 */
export function timeToMinutes(time: string) {
  const splitElement: string[] = time.split(':');

  if (splitElement.length !== 2) {
    return 0;
  } else {
    const hours = Number.parseInt(splitElement[0], 10);
    const minutes = Number.parseInt(splitElement[1], 10);

    return (hours * 60) + minutes;
  }
}

/**
 * This function converts a time in minutes to the time in format HH:MM. Starting from 00:00 = 0 to 24:00 = 1.440.
 * Times will be in UTC Time !
 * @param minutes as number
 * @returns time is a string in the format of HH:MM
 */
export function minutesToTime(minutes: number) {
  const hours: any = Math.trunc(minutes / 60);
  const min = minutes - hours * 60;
  return `${hours < 10 ? `0${hours}` : hours}:${min < 10 ? `0${min}` : min}`;
}

/**
 * Calculates the number of pages from the given itemCount and itemsPerPage.
 * @param itemCount number of items in the complete list
 * @param itemsPerPage  number of items that should be shown per page
 * @returns number of pages
 */
export function calculatePageCount(itemCount: number, itemsPerPage: number) {
  if (itemCount === 0) {
    return 0;
  }

  if (itemCount % itemsPerPage === 0) {
    return Math.trunc(itemCount / itemsPerPage);
  } else {
    return Math.trunc(itemCount / itemsPerPage + 1);
  }
}

/**
 * Formats the given date according to the passed format and returns it as a string.
 * @param date Date object
 * @param format Date format string, e.g. "dd.MM.yyyy"
 */
export function formatDate(date?: any, format = 'DD.MM.yyyy'): string {
  if (date instanceof Date) {
    return moment(date as Date).format(format);
  }

  return '';
}

/**
 * Converts the given date object to seconds since epoch.
 * @param date Date object
 */
export function toSecondsSinceEpoch(date?: Date): number | undefined {
  if (date instanceof Date) {
    return Math.floor(date.getTime() / 1000);
  }

  return undefined;
}

/**
 * Converts the given timestamp in milliseconds to seconds.
 * @param timestamp timestamp in milliseconds
 */
export function toSeconds(timestamp: number): number {
  return Math.floor(timestamp / 1000);
}

/**
 * Converts a date string form the v-datepicker with format 'yyyy-MM-DD'
 * into a JavaScript Date object.
   * @param dateString The date string from the date picker with format 'yyyy-MM-DD'
   */
export function fromDatePickerString(dateString: string): Date {
  return moment(dateString).toDate();
}

/**
 * Formats the given Array of date strings into a proper string, respecting
 * the given period. Normally, `dateArray` contains only one element, which is the date.
 * Only in the case of week, `dateArray` contains two elements, which are used to
 * display "<start> - <end>" of the week.
 * @param periodName name of the period. ["hour", "day", "week", "month", "year"]
 * @param dateArray array of string dates, e.g. ["2022-01-01", "2020-04-21"]
 * @returns a string containing the formatted date output.
 */
export function formatDateForPeriod(periodName: string, dateArray: string[]): string {
  let format: string;
  switch (periodName) {
    case 'hour':
      format = 'HH:mm - DD. MMM yyyy';
      break;
    case 'week':
      format = 'DD. MMM';
      break;
    case 'month':
      format = 'MMM yyyy';
      break;
    case 'year':
      format = 'yyyy';
      break;
    default:
      format = 'DD. MMM yyyy';
      break;
  }

  return dateArray.map((d) => formatDate(fromDatePickerString(d), format)).join(' - ');
}

/**
 * Retrieve the array key corresponding to the largest element in the array.
 *
 * @param {Array.<number>} array Input array
 * @return {number} Index of array element with largest value
 */
export function argMax(array: any[]) {
  return array.map((x, i) => [x, i]).reduce((r, a) => (a[0] > r[0] ? a : r))[1];
}

/**
 * Call time_is_out message if promise not return something in 15 seconds
 * @param promisesList array of promises
 * @param reportFn  setReport function
 * @param reportArgs report message options
 */
type Report = {
  type: string;
  message: TranslateResult;
  value: boolean;
}

export async function requestWithTimer(
  promisesList: Promise<number | string | boolean>[],
  reportFn: Function,
  reportArgs: Report,
) {
  const delay = new Promise((resolve) => {
    setTimeout(() => resolve('time_is_out'), 15000);
  });
  await Promise.race([delay, Promise.allSettled(promisesList)])
    .then((res) => {
      if (res === 'time_is_out') reportFn(reportArgs);
    });
}

/**
 * Round to n decimal places
 * @param value numeric value to round
 * @param decimal_places number of decimal places
 */
export function roundNumber(value: number, decimal_places = 2) {
  return Math.round(value * (10 ** decimal_places)) / (10 ** decimal_places);
}

/**
 * map measurements list,
 * remove all measurements which name length = 0,
 * set value rounded if it has to many numbers after comma,
 * if the value is a string and is longer than 30 characters, truncates it
 */
export function mapMeasurementsList(measurements: any) {
  return Object.entries(measurements).map((meas: any) => {
    const value: any = meas[1];
    const roundedValue = (value: any) => {
      return Math.round(value * 1e2) / 1e2;
    };
    const actualValue = (value: any) => {
      if (typeof value === 'number') return roundedValue(value);
      if (typeof value === 'string') return value.slice(0, 30);
      return value;
    };
    return { name: meas[0], value: actualValue(value) };
  }).filter((meas: {name: string; value: string}) => meas.name.length);
}

/**
 * Change date by direction and period
 * @param timestamp Original timestamp
 * @param direction Either 'next' or 'prev'
 * @param period To change timestamp by this period
 */
export function changeDate(timestamp: number, direction: string, period: string): number {
  let newTimestamp = 0;
  const operator = direction === 'next' ? 1 : -1;
  const date = new Date(timestamp * 1000);
  switch (period) {
    case 'hour':
      newTimestamp = date.setHours(date.getHours() + 1 * operator);
      break;
    case 'day':
      newTimestamp = date.setDate(date.getDate() + 1 * operator);
      break;
    case 'week':
      newTimestamp = date.setDate(date.getDate() + 7 * operator);
      break;
    case 'month':
      newTimestamp = date.setMonth(date.getMonth() + 1 * operator);
      break;
    case 'year':
      newTimestamp = date.setFullYear(date.getFullYear() + 1 * operator);
      break;
  }
  return toSeconds(newTimestamp);
}

/**
 * @param deviceName name of the Rules as string
 * @param variableName name of the Variable for the condition of the rule as string
 * @param actionsBody Text that should be displayed inside the Action of the Rule as string
 * @returns returns array with one warning rule and one error rule
 */
export function getDefaultRulesObject(
  deviceName: string, variableName: string, actionsBody: string,
): IRule[] {
  return [
    {
      id: '',
      name: `${deviceName} Warning Rule`,
      active: true,
      timeout: 240,
      conditions: [
        {
          variable: variableName,
          and_or: false,
          condition: 'equals',
          target: 1,
        },
      ],
      actions: [{
        type: 'alert',
        params: { type: 1, body: `{{rule}} - ${actionsBody} has a Warning` },
      }],
      created_at: '',
    },
    {
      id: '',
      name: `${deviceName} Error Rule`,
      active: true,
      timeout: 240,
      conditions: [
        {
          variable: variableName,
          and_or: false,
          condition: 'equals',
          target: 2,
        },
      ],
      actions: [{
        type: 'alert',
        params: { type: 2, body: `{{rule}} - ${actionsBody} has an Error` },
      }],
      created_at: '',
    },
  ];
}

/**
 * @param deviceName name of the Rules as string
 * @param variableName name of the Variable for the condition of the rule as string
 * @param actionsBodyText Text that should be displayed inside the Action of the Rule as string
 * @param conditionTarget target of condition as number. If this function is used for Error Warning. (1 = Warning, 2 = Error)
 * @returns Returns one basic Rule Object
 */
export function getSingleRuleObject(deviceName: string, variableName: string, actionsBodyText: string, conditionTarget: number) {
  return {
    id: '',
    name: `${deviceName}`,
    active: true,
    timeout: 240,
    conditions: [
      {
        variable: variableName,
        and_or: false,
        condition: 'equals',
        target: conditionTarget,
      },
    ],
    actions: [{ type: 'alert', params: { type: 1, body: `{{rule}} - ${actionsBodyText}` } }],
    created_at: '',
  };
}

/**
 * @param systemName access key for allSystems
 * @param allSystems all available systems containing a "count"
 * @return array of Projects that have all selected Tags
 */
export function hasSystem(systemName: string, allSystems: any) {
  return allSystems?.[systemName]?.count > 0;
}

/**
 * Formats the given numerical value as a string, with max 2 decimal places.
 * @param value the numerical value to format
 * @param maximumFractionDigits the maximum number of decimal places to display
 * @return the formatted value
 */
export function formatValue(value: number, maximumFractionDigits = 2): string {
  return Intl.NumberFormat('de-GE', { maximumFractionDigits }).format(value);
}

/**
 * Validates a given value within a range from min to max
 * @param value the numerical value to validate
 * @param min the minimum valid number
 * @param max the maximum valid number
 * @return true or the error message
 */
export function minMaxValidation(value: number | string, min: number | null, max: number | null) {
  let errorMessage = '';
  if (isNil(min) && isNil(max) && value) return true;
  if (!isNil(min) && !isNil(max)) errorMessage = `Value must be in the range of ${min} to ${max}`;
  else if (!isNil(min) && isNil(max)) errorMessage = `Value must be above or equal to ${min}`;
  else if (isNil(min) && !isNil(max)) errorMessage = `Value must be below or equal to ${max}`;
  return [
    (value: number | null) => (value !== null && (!isNil(min) ? value >= min : true) && (!isNil(max) ? value <= max : true)) ||
      errorMessage,
  ];
}

export function itemsContainValue(items: any[], value: any) {
  return [items.includes(value) || 'Value must be one of the available options'];
}

/**
 * This function sorts an array of devices based on their positions.
 * The position of each device is looked up in the provided positions map.
 * If a device is not in the positions map, it's considered to have maximum x and y values, hence will be sorted last.
 *
 * @param devicesList - The list of devices to be sorted. Each device must at least have an 'id' property.
 * @param positions - A map where the key is the id of a device and the value is its position.
 * @return An array of devices sorted by their positions.
 */
export function sortElementsByPosition<T extends HasId>(
  { elementsList, positions }: { elementsList: T[]; positions: Record<string, IDragNDropElementPosition> },
): T[] {
  return elementsList.sort((a: T, b: T) => {
    const positionA = positions[a.id] || { x: Number.MAX_VALUE, y: Number.MAX_VALUE };
    const positionB = positions[b.id] || { x: Number.MAX_VALUE, y: Number.MAX_VALUE };
    // Sort by y first, then by x if y is equal
    if (positionA.y !== positionB.y) return positionA.y - positionB.y;
    else return positionA.x - positionB.x;
  });
}

/**
 * Takes an array of elements and validated if the sum is 100.
 * Each element in the array is has to have a value property.,
 *
 * @param objArray - An array of objects containing a value property.
 * @param property - name of the value property to add up.
 * @return boolean - true if all values combine to 100, false otherwise.
 */
export function valuesAddTo100(objArray: any[], property: string) {
  let sum = 0;
  for (let i = 0; i < objArray.length; i++) {
    if (objArray[i][property] !== undefined) {
      sum += objArray[i][property];
    } else {
      throw Error(`Object does not have a value property called: '${property}'`);
    }
  }
  return sum === 100;
}

/**
 * Takes an array of elements and converts it to an object.
 * Each element in the array is transformed into a key-value pair in the object,
 * where the key is the 'i' property of the element, and the value is the element itself.
 *
 * @param list - An array of elements.
 * @return A Record where each key is the 'i' property of an element from the array, and each value is the corresponding IDragNDropElementPosition element.
 */
export function convertElementsPositionsToObject(list: IDragNDropElementPosition[]) {
  return list.reduce((acc: Record<string, IDragNDropElementPosition>, el: IDragNDropElementPosition) => {
    return { ...acc, [el.i]: el };
  }, {});
}

/**
 * Generates a comma-separated string based on the given list of string segments and a minimum length requirement.
 *
 * - Starts with the first string in the list (if available).
 * - Appends each subsequent string from the list, separated by a comma, until the generated string meets or exceeds the specified minimum length.
 *
 * @example
 * buildCommaSeparatedString(['apple', 'banana', 'cherry'], 10) // returns 'apple,banana'
 * buildCommaSeparatedString(['a', 'b', 'c'], 5) // returns 'a,b,c'
 * buildCommaSeparatedString([], 5) // returns ''
 *
 * @param {string[]} list - The list of string segments to generate the string from.
 * @param {number} minLength - The minimum length that the generated string should have.
 *
 * @returns {string} The generated comma-separated string based on the conditions specified.
 */
export function buildCommaSeparatedString(list: string[], minLength: number): string {
  let str = list[0] || '';
  let index = 1;

  while (str.length < minLength && index < list.length) {
    const nextStr = list[index];
    str = `${str},${nextStr}`;
    index++;
  }

  return str;
}

/**
 * Calculates the minimum power percentage based on the given ampere and maxPower values.
 * The calculated percentage is clamped between 0 and 100.
 * @param {number} ampere - The initial ampere value to be used for the calculation.
 * @param {number} maxPower - The maximum power.
 * @returns {number} The minimum power percentage, clamped between 0 and 100.
 */
export function calculateMinPowerPercent(ampere: number, maxPower: number) {
  if (maxPower === 0) return 0;

  const kw = ampere * 692 / 1000;
  const rounded = Math.round(kw / maxPower * 100);
  return clamp(rounded, 0, 100);
}

/**
 * Get the amount of decimal places of a number
 * @param number any number
 * @returns amount of decimal places
 */
export function countDecimalPlaces(number: number) {
  if ((number % 1) !== 0) {
    return number.toString().split('.')[1].length;
  }
  return 0;
}

export function convertNegativeZero(value: any): any {
  if (typeof value === 'number') {
    return Object.is(value, -0) ? 0 : value;
  } else return value;
}

/**
 * @param date date as string in format yyyy-MM-dd
 * @returns date as string in format dd.MM.yyyy
 */
export function formatIsoDate(date: string) {
  const datePart: any = date.match(/\d+/g);
  const year = datePart[0];
  const month = datePart[1];
  const day = datePart[2];

  return `${day}.${month}.${year}`;
}

export function convertTimestampToDate(timestamp: number) {
  const date = new Date(timestamp * 1000);

  // Get day, month, and year components
  const day = date.getDate().toString();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // Format the date string
  return `${day}.${month}.${year}`;
}

/**
 * @param arr1 any array
 * @param arr2 any array
 * @returns boolean if the arrays are equal, regardless of the order of the elements
 */
export function checkUnsortedArrayEqual(arr1: any[], arr2: any[]) {
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  return sortedArr1.every((element, index) => element === sortedArr2[index]);
}

/**
 * Converts a binary number to a decimal number
 * @param binaryNumber binary number as number
 * @returns decimal number as number
 */
export function convertBinaryNumberToDecimal(binaryNumber: number) {
  const binaryString = binaryNumber.toString();
  return parseInt(binaryString, 2);
}

/**
 * Converts a decimal number to a binary number
 * @param decimalNumber decimal number as number
 * @returns binary number as number
 */
export function convertDecimalNumberToBinary(decimalNumber: number) {
  return parseInt(decimalNumber.toString(2), 10);
}

/**
 * Converts a decimal number to a bit example: input [1, 3, 8] -> output '10000101'
 * @param array array of numbers
 * @returns bit string as string
 */
export function convertArrayToBit(array: number[]) {
  // Find the maximum number in the array to determine the size of the bit string
  const maxNum = Math.max(...array);
  // Create a bit string filled with 0s of length maxNum
  const bitString = Array(maxNum).fill('0');

  // Iterate through the array and set the corresponding bit position to 1
  array.forEach(num => {
    bitString[maxNum - num] = '1';
  });

  // Join the bit string array back into a single string
  return bitString.join('');
}

/**
 * Converts a bit string to an array of numbers example: input '10000101' -> output [1, 3, 8]
 * @param bitString bit string as string
 * @returns array of numbers
 */
export function convertBitToArray(bitString: string) {
  // Reverse the bit string to iterate from right to left
  const reversedBitString = bitString.split('').reverse();
  const array: number[] = [];

  // Iterate through the bit string and add the position of each 1 to the array
  reversedBitString.forEach((bit, index) => {
    if (bit === '1') {
      array.push(index + 1);
    }
  });

  return array;
}
