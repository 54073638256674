
import { Vue, Component, Prop } from 'vue-property-decorator';
import DeviceActions from '@/ui/components/devices/actions/DeviceActions.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import DropDownBase from '@/ui/components/devices/devices/base/DropDown.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent TSGLadestationNotAus device
 */
@Component({
  components: {
    DeviceActions,
    DropDownBase,
    PushButtonBase,
    ShowEventDotBase,
    DeviceLayout,
    LabelUnitWrapper,
  },
})
export default class TSGLadestationNotAus extends Vue {
  @Prop() deviceData!: IDevice;

  get textMappingStatus() {
    return {
      0: this.$t(`${this.langPath}.mainView.dropDown1.value0`),
      1: this.$t(`${this.langPath}.mainView.dropDown1.value1`),
    };
  }
  get textMappingEmergency() {
    return {
      0: this.$t(`${this.langPath}.mainView.dropDown2.value0`),
      1: this.$t(`${this.langPath}.mainView.dropDown2.value1`),
    };
  }

  get langPath() {
    return `devices.${this.deviceData.data.type}`;
  }
}
