
import { Vue, Component } from 'vue-property-decorator';
import ForecastMock from '@/ui/components/devices/previews/mpc/HeatingCircuitOptimization/Forecast.vue';
import ChartMock from '@/ui/components/devices/previews/mpc/HeatingCircuitOptimization/Chart.vue';

@Component({
  components: {
    ForecastMock,
    ChartMock,
  },
})
export default class SettingsCharts extends Vue {
  tab: any = null

  get settingsViewLang() {
    return 'mlModel.HeatingCircuitOptimization.settingsView.chartTabs';
  }
  get tabs() {
    return [
      {
        title: 'Chart',
        locale: this.settingsViewLang,
        component: 'ChartMock',
      },
      {
        title: 'Forecast',
        locale: this.settingsViewLang,
        component: 'ForecastMock',
      },
    ];
  }
}
