
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { cutNumberFromString } from '@/ui/components/modals/ManageMLModel/utils';

/**
 * @name ManageSystemsDecreaseModal
 * @description
 * This component is used to manage systems amount in ML model.
 * It is used in ManageMLModel component.
 */

@Component({
  methods: { cutNumberFromString },
})
export default class ManageSystemsDecreaseModal extends Vue {
  @Prop() data!: any;
  @Prop({ default: false }) disabled!: boolean;
  @Prop() system!: string;

  dialog = false;
  model: string[] = [];

  amountOfRemovedSystems = 0;

  @Watch('dialog')
  onDialogChange(value: boolean) {
    if (value) {
      this.model = this.systemInstancesKeys;
    } else {
      this.amountOfRemovedSystems = 0;
    }
  }

  get systemInstancesKeys() {
    return Object.keys(this.data.components);
  }

  get isFormValid() {
    return this.model.length === this.systemInstancesKeys.length - this.amountOfRemovedSystems;
  }

  get removingInformation() {
    const amount = this.model.length - (this.systemInstancesKeys.length - this.amountOfRemovedSystems);
    if (amount > 0) return `${this.amountOfRemovedSystems} ${this.$t('modals.ManageSystemsDecreaseModal.needToBeRemoved')}`;
    if (amount < 0) return `${this.systemInstancesKeys.length - this.amountOfRemovedSystems} ${this.$t('modals.ManageSystemsDecreaseModal.needToBeKept')}`;
    return null;
  }

  sortSystems(systems: string[]) {
    return [...systems].sort((a: string, b: string) => {
      const aNumber = Number(cutNumberFromString(a));
      const bNumber = Number(cutNumberFromString(b));
      return aNumber - bNumber;
    });
  }

  onSave() {
    this.$emit('onManageSystemsSave', { system: this.system, instances: this.sortSystems(this.model) });
    this.dialog = false;
  }

  onCancel() {
    this.$emit('onManageSystemsCancel');
    this.dialog = false;
  }

  handleDialogOnDecrease(amountOfRemovedSystems: number) {
    this.dialog = true;
    this.amountOfRemovedSystems = amountOfRemovedSystems;
  }
}
