
import { Component, Prop } from 'vue-property-decorator';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import PushButtonBase from '@/ui/components/devices/devices/base/PushButtonBase/index.vue';
import Switch2VBase from '@/ui/components/devices/devices/base/Switch2VBase.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import PreviewMQTTControls
  from '@/ui/components/devices/components/EnergyParts/PreviewMQTTControls.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Getter } from 'vuex-class';
import { IDevice } from '@/types/devices.types';

/**
 * Battery single system settings.
 */
@Component({
  components: {
    InputFieldBase,
    PushButtonBase,
    OutputFieldBase,
    Switch2VBase,
    PreviewMQTTControls,
    LabelUnitWrapper,
  },
})
export default class BatterySystemSettings extends mixins(Validation) {
  @Prop() deviceData!: IDevice;
  @Prop({ default: null }) instanceData!: any;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;

  get outputFieldActualPower() {
    return {
      'OutputField_actualValue': this.instanceData.power,
    };
  }
  get outputFieldActualBatterySOC() {
    return {
      'OutputField_actualValue': this.instanceData.soc,
    };
  }
  get outputFieldActualBattery() {
    return {
      'OutputField_actualValue': this.instanceData.capacity,
    };
  }
  get targetPowerCapacityBattery() {
    return {
      'OutputField_actualValue': this.instanceData.target_power,
    };
  }
  get pushButtonReset() {
    return {
      'PushButton_onOff': this.instanceData.switch_reset,
      'PushButton_state': this.instanceData.state_reset,
    };
  }
  get inputFieldPriorityEMS() {
    return {
      'InputField_targetValue': this.instanceData.priority,
    };
  }
  get inputFieldMaxSizeBatteryCapacity() {
    return {
      'InputField1_targetValue': this.instanceData.size_capacity,
    };
  }
  get switchP14A() {
    return {
      'Switch2V_onOff': this.instanceData.switch_p14a,
      'Switch2V_state': this.instanceData.state_p14a,
    };
  }
}
