
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent settings view for device, MPC
 */
@Component
export default class SettingsView extends Vue {
  @Prop() isSettingsView!: boolean;
  @Prop() deviceData!: any;
  @Prop({ default: '600' }) modalWidth!: number | string;
}
