import FinalLoggerPage from '@/ui/components/wizards/loggerWizard/steps/finalPage/FinalLoggerPage.vue';
import GeneralInformationsLogger from '@/ui/components/wizards/loggerWizard/steps/generalSettings/GeneralInformations.vue';
import InviteUsersToProjectLogger
  from '@/ui/components/wizards/loggerWizard/steps/inviteUsers/InviteUserToProject.vue';
import LoggerManualsPage from '@/ui/components/wizards/loggerWizard/steps/defineComponents/LoggerManualsPage.vue';
import DefineLoggerIds from '@/ui/components/wizards/loggerWizard/steps/defineComponents/DefineLoggerIds.vue';
import LoggerInformation from '@/ui/components/wizards/loggerWizard/steps/defineComponents/LoggerInformation.vue';
import LoggerComponentsPage from '@/ui/components/wizards/loggerWizard/steps/defineComponents/LoggerComponentsPage.vue';
import CommonEnergyPriceSettings from '@/ui/components/wizards/baseComponents/commonPages/CommonEnergyPriceSettings.vue';

const loggerWizardImports = {
  DefineLoggerIds,
  LoggerComponentsPage,
  LoggerInformation,
  FinalLoggerPage,
  GeneralInformationsLogger,
  InviteUsersToProjectLogger,
  LoggerManualsPage,
  CommonEnergyPriceSettings,
};

export default loggerWizardImports;
