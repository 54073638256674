
import { Vue, Component, Prop } from 'vue-property-decorator';
import PreviewActions from '@/ui/components/devices/actions/PreviewActions.vue';
import DeviceDescription from '@/ui/components/devices/actions/DeviceDescription.vue';
import SettingsView from '@/ui/components/devices/components/SettingsView.vue';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';
import TimeSlider from '@/ui/components/devices/components/EnergyParts/TimeSlider.vue';
import ActualView from '@/ui/components/devices/previews/devices/EMSV2/ActualView.vue';
import ForecastView from '@/ui/components/devices/previews/mpc/EMS/ForecastView.vue';
import EnergyFlowView from '@/ui/components/devices/previews/mpc/EMS/EnergyFlowView.vue';
import Settings from '@/ui/components/devices/previews/mpc/EMS/Settings.vue';
import EMSCharts from '@/ui/components/devices/previews/mpc/EMS/charts/index.vue';
import MPCLayout from '@/ui/components/devices/components/MPCLayout.vue';
import emsMappingMock from '@/ui/components/devices/previews/mockObjects/emsObject.json';
import forecastMockData from './forecastMockData';

@Component({
  components: {
    PreviewActions,
    DeviceDescription,
    SettingsView,
    ShowEventDotMock,
    TimeSlider,
    ActualView,
    ForecastView,
    EnergyFlowView,
    Settings,
    EMSCharts,
    MPCLayout,
  },
})
export default class EMSPreview extends Vue {
  @Prop({ default: false }) isPreviewManageModalOpen!: boolean;

  isSettingsView = false
  groupSlider = 0
  mpc: any = null
  tabsTimer: any = null
  tab = null
  localEmsMappingMock = emsMappingMock;

  energyFlowContentMinHeight = '680px';

  forecastData = forecastMockData;

  get description() {
    return this.$t('mlModelsDescriptions.EMS');
  }

  get predictedPower() {
    if (this.mpc?.data?.meta?.charts?.predictedPower) return this.mpc.data.meta.charts.predictedPower;
    else return null;
  }

  get energyFlowData() {
    const data: any = {};
    Object.entries(this.forecastData).forEach((system: any) => {
      if (system[0].includes('grid') || system[0].includes('battery')) {
        data[system[0]] = {
          producer: this.sum(system[1].filter((entry: any) => entry[1] < 0)),
          consumer: this.sum(system[1].filter((entry: any) => entry[1] > 0)),
        };
      } else {
        data[system[0]] = this.sum(system[1]);
      }
    });
    return data;
  }

  sum(arr: any) {
    let sum = 0;
    arr.forEach((entry: any) => {
      sum += entry[1];
    });
    return sum;
  }

  switchSettingsView(setting: boolean) {
    this.isSettingsView = setting;
  }

  async handleTab(el: string) {
    clearTimeout(this.tabsTimer);
    this.tabsTimer = setTimeout(() => {
      if (el === 'forecast_view') {
        this.$nextTick(() => {
          (this.$refs[el] as any)?.handleGetCoords();
        });
      }
    }, 500);
  }

  async rerenderDevice() {
    this.mpc = null;
  }

  async created() {
  }
}
