import colors from '@/ui/components/devices/mpc/colors';

/**
 * A collection of device schemas with these options:
 * - **mappingsByColumns** - mappings grouped by columns, which will be drawn in the manage modal form
 * - **controllerMappings** - collection of mappings used in device, are also the form fields in the manage modal form
 * - **settingsMappings** - list of mappings which validates to red frame around the settings list item flashes
 * - **manageSchema** - manage modal form used to create, update device
 * - **isSettingsView** - status whether the device has a settings window
 * - **dynamicMappingsMinMax** - min, max borders to validate value of specific named mappings
 * for settings menu item flashing border
 *
 * Used in EMS and Setpoint Optimizer
 * - **groups** - collection of system groups
 * - **systems** - collection of systems with options settings for them
 * - **additionalFields** - additional fields for manage modal form stage 3
 */
const mlModelTypes = {
  HeatingCircuitOptimization: {
    mappingsByColumns: {
      inputMappings: [
        'valveReturnType',
        'flowTemperature',
        'returnTemperature',
        'areaTemperature',
        'valvePosition',
      ],
      outputMappings: [
        'newValvePosition',
        'newAreaTemperature',
        'newSetTemperature',
      ],
      middleMappings: ['heartbeat', 'errorWarning', 'mpcReady'],
    },
    controllerMappings: {
      valveReturnType: {
        vuetifyComponent: 'SelectField',
        items: ['0-1 Digital'],
        optional: false,
        type: 'input',
      },
      flowTemperature: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        type: 'input',
      },
      newSetTemperature: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        type: 'input',
      },
      returnTemperature: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        type: 'input',
      },
      areaTemperature: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        type: 'input',
      },
      valvePosition: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        type: 'input',
      },
      newValvePosition: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        type: 'output',
      },
      newAreaTemperature: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'output',
      },
      heartbeat: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        type: 'none',
      },
      errorWarning: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        type: 'none',
      },
      mpcReady: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        type: 'none',
      },
    },
    settingsMappings: null,
    manageSchema: 'HCOSchema',
    isSettingsView: true,
  },
  PVProductionService: {
    mappingsByColumns: {
      inputMappings: ['startDate', 'power'],
      outputMappings: ['predictedEnergy', 'predictedPower'],
      middleMappings: ['heartbeat', 'errorWarning', 'mpcReady'],
    },
    controllerMappings: {
      startDate: {
        vuetifyComponent: 'DatePickerCustom',
        optional: false,
      },
      heartbeat: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'none',
      },
      errorWarning: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'none',
      },
      power: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        type: 'input',
      },
      predictedPower: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'input',
      },
      predictedEnergy: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'input',
      },
      mpcReady: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'none',
      },
    },
    settingsMappings: null,
    manageSchema: 'ServicesSchema',
    isSettingsView: false,
  },
  ConsumptionService: {
    mappingsByColumns: {
      inputMappings: ['startDate', 'power'],
      outputMappings: ['predictedEnergy', 'predictedPower'],
      middleMappings: ['heartbeat', 'errorWarning', 'mpcReady'],
    },
    controllerMappings: {
      startDate: {
        vuetifyComponent: 'DatePickerCustom',
        optional: false,
      },
      heartbeat: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'none',
      },
      errorWarning: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'none',
      },
      power: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        type: 'input',
      },
      predictedPower: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'input',
      },
      predictedEnergy: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'input',
      },
      mpcReady: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'none',
      },
    },
    settingsMappings: null,
    manageSchema: 'ServicesSchema',
    isSettingsView: false,
  },
  HppProductionService: {
    mappingsByColumns: {
      inputMappings: ['startDate', 'power'],
      outputMappings: ['calculatedProduction', 'calculatedEnergy'],
      middleMappings: ['heartbeat', 'errorWarning', 'mpcReady'],
    },
    controllerMappings: {
      startDate: {
        vuetifyComponent: 'DatePickerCustom',
        optional: false,
      },
      power: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        type: 'input',
      },
      heartbeat: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'none',
      },
      errorWarning: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'none',
      },
      mpcReady: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'none',
      },
      calculatedProduction: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'input',
      },
      calculatedEnergy: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        type: 'input',
      },
    },
    settingsMappings: null,
    manageSchema: 'ServicesSchema',
    isSettingsView: false,
  },
  EMS: {
    mappingsByColumns: {
      inputMappings: ['startDate', 'state_enable_ems', 'allow_charging_state', 'message', 'state_main_fuse', 'inverter_power', 'force_charge', 'forceCharge2', 'calibration_charge', 'maintenance_charge', 'charge_maintenance'],
      outputMappings: [
        'heartbeat', 'errorWarning', 'mpcReady', 'operation_mode',
        'max_depth', 'reserve_battery', 'min_charge_battery', 'reserve_charge', 'update_time',
        'enable_ems', 'calculated_battery_power', 'activate_heating_pump', 'allow_charging_button',
        'enable_controlling', 'activate_main_fuse', 'size_main_fuse', 'size_main_fuse',
      ],
    },
    controllerMappings: {
      startDate: {
        vuetifyComponent: 'DatePickerCustom',
        optional: false,
      },
      activate_heating_pump: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      calculated_battery_power: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      state_enable_ems: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      enable_ems: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      heartbeat: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      errorWarning: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      mpcReady: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      size_main_fuse: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
        isDynamic: true,
        maxRange: 30,
        minMaxBorders: { min: 0, max: 500 },
      },
      operation_mode: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      max_depth: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
        minMaxBorders: { min: 10, max: 100 },
      },
      reserve_battery: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
        minMaxBorders: { min: 11, max: 100 },
      },
      min_charge_battery: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
        minMaxBorders: { min: 25, max: 75 },
      },
      reserve_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
        minMaxBorders: { min: 10, max: 100 },
      },
      update_time: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
        minMaxBorders: { min: 10, max: 600 },
      },
      message: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      allow_charging_button: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      allow_charging_state: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      enable_controlling: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      state_main_fuse: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      inverter_power: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      activate_main_fuse: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
        isSystem: false,
      },
      force_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      forceCharge2: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      calibration_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      maintenance_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      charge_maintenance: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
    },
    groups: {
      producer: {
        id: 'producer',
        title: 'Producer',
        systems: ['pv', 'generator'],
      },
      producerConsumer: {
        id: 'producerConsumer',
        title: 'Producer Consumer',
        systems: ['battery', 'grid'],
      },
      consumer: {
        id: 'consumer',
        title: 'Consumer',
        systems: ['house', 'charge_station', 'electric_heating', 'heating_pump', 'big_consumer'],
      },
    },
    systems: {
      pv: {
        id: 'pv',
        title: 'PV System',
        required: false,
        group: 'producer',
        quantity: 40,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      generator: {
        id: 'generator',
        title: 'Generator',
        required: false,
        group: 'producer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'state_reset', 'error', 'state_generator', 'state_enable', 'energy_counter', 'state_timelock'],
          output: ['enable_soc', 'disable_soc', 'switch_enable', 'switch_reset', 'hour_on', 'minute_on', 'hour_off', 'minute_off', 'enable_timelock'],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          state_reset: '',
          state_generator: '',
          enable_soc: '',
          disable_soc: '',
          switch_enable: '',
          switch_reset: '',
          state_enable: '',
          energy_counter: '',
          hour_on: '',
          minute_on: '',
          hour_off: '',
          minute_off: '',
          enable_timelock: '',
          state_timelock: '',
        },
        mappings: {
          power: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
            items: 'measurements',
          },
          state_reset: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_generator: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          enable_soc: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          disable_soc: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_reset: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          hour_on: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          minute_on: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          hour_off: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          minute_off: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          enable_timelock: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_timelock: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      battery: {
        id: 'battery',
        title: 'Battery',
        required: false,
        group: 'producerConsumer',
        quantity: 20,
        mappingsByColumns: {
          input: ['power', 'error', 'soc', 'target_power', 'state_enable', 'state_reset', 'capacity', 'energy_counter', 'reverse_energy_counter'],
          output: ['size_capacity', 'priority', 'switch_enable', 'switch_reset'],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          target_power: '',
          state_enable: '',
          state_reset: '',
          error: '',
          title: '',
          soc: '',
          size_capacity: '',
          capacity: '',
          priority: '',
          switch_enable: '',
          switch_reset: '',
          energy_counter: '',
          reverse_energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          target_power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_reset: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
            items: 'measurements',
          },
          soc: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          size_capacity: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          capacity: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          priority: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_reset: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          reverse_energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      grid: {
        id: 'grid',
        title: 'Grid',
        required: true,
        group: 'producerConsumer',
        quantity: 1,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter', 'reverse_energy_counter'],
          output: ['size'],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          size: '',
          energy_counter: '',
          reverse_energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          size: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          reverse_energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      house: {
        id: 'house',
        title: 'House Consumption',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'state_enable', 'energy_counter'],
          output: ['switch_enable'],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          state_enable: '',
          switch_enable: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          state_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          switch_enable: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      charge_station: {
        id: 'charge_station',
        title: 'EV Charging Station',
        required: false,
        group: 'consumer',
        quantity: 250,
        mappingsByColumns: {
          input: [
            'power', 'error', 'car_connected', 'charging_time', 'state_charging_station', 'state_emergency',
            'slider_target_power', 'state_manual', 'min_power', 'target_power', 'state_enable', 'energy_counter',
          ],
          output: [
            'switch_emergency', 'priority',
            'enable_soc', 'disable_soc', 'max_power', 'switch_manual', 'slider_manual',
            'slider_min_power', 'switch_enable',
          ],
          titles: ['title'],
        },
        initMappings: {
          'power': '',
          'error': '',
          'title': '',
          'car_connected': '',
          'charging_time': '',
          'state_charging_station': '',
          'state_emergency': '',
          'state_manual': '',
          'min_power': '',
          'switch_emergency': '',
          'priority': '',
          'enable_soc': '',
          'disable_soc': '',
          'max_power': '',
          'slider_manual': '',
          'slider_target_power': '',
          'slider_min_power': '',
          'target_power': '',
          'state_enable': '',
          'switch_enable': '',
          'switch_manual': '',
          'energy_counter': '',
        },
        mappings: {
          'power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'error': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'car_connected': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'charging_time': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_charging_station': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'min_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'priority': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'enable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'disable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'max_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_target_power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_min_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'target_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'title': {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          'energy_counter': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      electric_heating: {
        id: 'electric_heating',
        title: 'Electric Heating Element',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: [
            'power', 'error', 'temperature', 'state_electric_heating', 'state_emergency', 'state_manual',
            'state_time', 'state_disable_protection', 'slider_target_power', 'target_power', 'state_enable',
            'energy_counter',
          ],
          output: [
            'switch_emergency', 'priority', 'enable_soc', 'disable_soc',
            'switch_disable_protection', 'switch_manual', 'slider_manual', 'switch_time', 'hour_on', 'minute_on',
            'hour_off', 'minute_off', 'target_temp_on', 'target_temp_off', 'target_temp_max', 'switch_enable', 'max_power',
          ],
          titles: ['title'],
        },
        isDynamicFields: true,
        initMappings: {
          'power': '',
          'error': '',
          'title': '',
          'temperature': '',
          'state_electric_heating': '',
          'state_emergency': '',
          'state_manual': '',
          'state_time': '',
          'state_disable_protection': '',
          'switch_emergency': '',
          'priority': '',
          'enable_soc': '',
          'disable_soc': '',
          'switch_disable_protection': '',
          'switch_manual': '',
          'slider_target_power': '',
          'switch_time': '',
          'hour_on': '',
          'minute_on': '',
          'hour_off': '',
          'minute_off': '',
          'target_temp_on': '',
          'target_temp_off': '',
          'target_temp_max': '',
          'max_power': {},
          'target_power': '',
          'state_enable': '',
          'switch_enable': '',
          'slider_manual': '',
          'energy_counter': '',
        },
        mappings: {
          'power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'error': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'title': {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          'temperature': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_electric_heating': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_time': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_disable_protection': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'priority': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'enable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'disable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_disable_protection': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_target_power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_time': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'hour_on': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'minute_on': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'hour_off': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'minute_off': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'target_temp_on': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'target_temp_off': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'target_temp_max': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'max_power': {
            vuetifyComponent: 'DynamicField',
            isDynamic: true,
            optional: false,
            maxRange: 10,
          },
          'target_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'energy_counter': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      heating_pump: {
        id: 'heating_pump',
        title: 'Heating Pump',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: [
            'power', 'error', 'state_reset', 'state_heating_pump', 'state_emergency', 'state_manual', 'manual_power',
            'state_time', 'state_time_power', 'flow_temperature', 'return_temperature', 'inlet_temperature',
            'outlet_temperature', 'boiler_temperature', 'boiler_water_temperature', 'target_power', 'state_enable',
            'energy_counter', 'heating_power_produced', 'heating_power_consumed', 'heating_buffer', 'stop_heating_power',
          ],
          output: [
            'switch_emergency', 'priority', 'enable_soc', 'disable_soc',
            'switch_reset', 'switch_manual', 'slider_manual', 'switch_time', 'slider_power', 'hour_on', 'minute_on',
            'hour_off', 'minute_off', 'switch_enable', 'max_power',
          ],
          titles: ['title'],
        },
        isDynamicFields: true,
        initMappings: {
          'power': '',
          'error': '',
          'title': '',
          'state_reset': '',
          'state_heating_pump': '',
          'state_emergency': '',
          'state_manual': '',
          'manual_power': '',
          'state_time': '',
          'state_time_power': '',
          'flow_temperature': '',
          'return_temperature': '',
          'inlet_temperature': '',
          'outlet_temperature': '',
          'boiler_temperature': '',
          'boiler_water_temperature': '',
          'switch_emergency': '',
          'priority': '',
          'enable_soc': '',
          'disable_soc': '',
          'switch_reset': '',
          'switch_manual': '',
          'slider_power': '',
          'switch_time': '',
          'hour_on': '',
          'minute_on': '',
          'hour_off': '',
          'minute_off': '',
          'max_power': {},
          'switch_enable': '',
          'slider_manual': '',
          'target_power': '',
          'state_enable': '',
          'energy_counter': '',
          'heating_power_produced': '',
          'heating_power_consumed': '',
          'stop_heating_power': '',
          'heating_buffer': '',
        },
        mappings: {
          'power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'error': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'title': {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          'state_reset': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_heating_pump': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'manual_power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_time': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_time_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'flow_temperature': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'return_temperature': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'inlet_temperature': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'outlet_temperature': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'boiler_temperature': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'boiler_water_temperature': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'priority': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'enable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'disable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_reset': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_time': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'hour_on': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'minute_on': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'hour_off': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'minute_off': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'max_power': {
            vuetifyComponent: 'DynamicField',
            isDynamic: true,
            optional: true,
            maxRange: 2,
          },
          'switch_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'target_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'energy_counter': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'heating_power_produced': {
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
            optional: true,
          },
          'heating_power_consumed': {
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
            optional: true,
          },
          'heating_buffer': {
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
            optional: true,
          },
          'stop_heating_power': {
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
            optional: true,
            isSystem: false,
            minMaxBorders: { min: 0, max: 1 },
          },
        },
      },
      big_consumer: {
        id: 'big_consumer',
        title: 'Other Big Consumer',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: [
            'power', 'error', 'state_consumer', 'state_emergency', 'state_manual', 'state_enable', 'target_power',
            'slider_target_power', 'energy_counter',
          ],
          output: [
            'switch_emergency', 'priority', 'enable_soc', 'disable_soc',
            'switch_manual', 'switch_enable', 'slider_manual', 'max_power',
          ],
          titles: ['title'],
        },
        initMappings: {
          'power': '',
          'error': '',
          'title': '',
          'state_consumer': '',
          'state_emergency': '',
          'state_manual': '',
          'switch_emergency': '',
          'priority': '',
          'enable_soc': '',
          'disable_soc': '',
          'switch_manual': '',
          'state_enable': '',
          'switch_enable': '',
          'target_power': '',
          'slider_manual': '',
          'slider_target_power': '',
          'max_power': '',
          'energy_counter': '',
        },
        mappings: {
          'power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'error': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'title': {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          'state_consumer': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_emergency': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'priority': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'enable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'disable_soc': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'state_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'switch_enable': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'target_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_manual': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'slider_target_power': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'max_power': {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          'energy_counter': {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
    },
    settingsMappings: ['size_main_fuse', 'max_depth', 'reserve_battery', 'min_charge_battery', 'reserve_charge', 'update_time'],
    additionalFields: {
      component: 'EMSAdditionalFields',
      objects: {
        scaling: ['pv', 'consumption', 'batteryPower'],
        energyPrice: [],
      },
    },
    manageSchema: 'EMSSchema',
    isSettingsView: true,
  },
  PVMonitoringService: {
    mappingsByColumns: {
      inputMappings: ['startDate', 'endDate', 'power'],
      outputMappings: ['efficiency'],
      middleMappings: ['heartbeat', 'errorWarning', 'mpcReady'],
    },
    controllerMappings: {
      startDate: {
        vuetifyComponent: 'DatePickerCustom',
        optional: false,
      },
      endDate: {
        vuetifyComponent: 'DatePickerCustom',
        optional: false,
      },
      efficiency: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
      },
      heartbeat: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
      },
      errorWarning: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
      },
      power: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: false,
      },
      mpcReady: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
      },
    },
    settingsMappings: ['PV.#id#.decay'], // here used specific mapping naming syntax, it consists from 'PV.mpc_device_id.decay'
    dynamicMappingsMinMax: {
      'PV.#id#.decay': { min: 0, max: 100 },
    },
    manageSchema: 'DefaultSchema',
    isSettingsView: true,
  },
  // TODO: replace with EnergyView once the old EnergyView is not used anymore
  MpcEnergyView: {
    mappingsByColumns: {
      inputMappings: ['force_charge', 'forceCharge2', 'calibration_charge', 'maintenance_charge', 'charge_maintenance'],
      outputMappings: [],
    },
    controllerMappings: {
      force_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      forceCharge2: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      calibration_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      maintenance_charge: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
      charge_maintenance: {
        vuetifyComponent: 'ComboboxField',
        items: 'measurements',
        optional: true,
        isSystem: false,
      },
    },
    additionalFields: {
      component: 'EnergyViewAdditionalFields',
      objects: {
        scaling: ['pvScaling', 'conumptionScaling', 'maxGridSize'],
      },
    },
    groups: {
      producer: {
        id: 'producer',
        title: 'Producer',
        systems: ['pv', 'generator'],
      },
      producerConsumer: {
        id: 'producerConsumer',
        title: 'Producer Consumer',
        systems: ['grid', 'battery'],
      },
      consumer: {
        id: 'consumer',
        title: 'Consumer',
        systems: ['house', 'charge_station', 'electric_heating', 'heating_pump', 'big_consumer'],
      },
    },
    systems: {
      pv: {
        id: 'pv',
        title: 'PV System',
        required: true,
        group: 'producer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      generator: {
        id: 'generator',
        title: 'Generator',
        required: false,
        group: 'producer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
            items: 'measurements',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      battery: {
        id: 'battery',
        title: 'Battery',
        required: false,
        group: 'producerConsumer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'soc', 'energy_counter', 'reverse_energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          soc: '',
          energy_counter: '',
          reverse_energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
            items: 'measurements',
          },
          soc: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          reverse_energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      grid: {
        id: 'grid',
        title: 'Grid',
        required: true,
        group: 'producerConsumer',
        quantity: 1,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter', 'reverse_energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
          reverse_energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          reverse_energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      house: {
        id: 'house',
        title: 'House Consumption',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      charge_station: {
        id: 'charge_station',
        title: 'EV Charging Station',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      electric_heating: {
        id: 'electric_heating',
        title: 'Electric Heating Element',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      heating_pump: {
        id: 'heating_pump',
        title: 'Heating Pump',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
      big_consumer: {
        id: 'big_consumer',
        title: 'Other Big Consumer',
        required: false,
        group: 'consumer',
        quantity: 10,
        mappingsByColumns: {
          input: ['power', 'error', 'energy_counter'],
          output: [],
          titles: ['title'],
        },
        initMappings: {
          power: '',
          error: '',
          title: '',
          energy_counter: '',
        },
        mappings: {
          power: {
            optional: false,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          error: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
          title: {
            optional: false,
            vuetifyComponent: 'TextField',
          },
          energy_counter: {
            optional: true,
            vuetifyComponent: 'ComboboxField',
            items: 'measurements',
          },
        },
      },
    },
    manageSchema: 'EMSSchema',
    isSettingsView: false,
  },
};

export default mlModelTypes;
