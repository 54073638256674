
import { Vue, Component } from 'vue-property-decorator';
import {
  Action, Mutation, Getter, State,
} from 'vuex-class';
import DevicesTable from '@/ui/components/tables/DevicesTable.vue';
import AnomalyDetectionTable from '@/ui/components/tables/AnomalyDetectionTable.vue';
import ChartsTable from '@/ui/components/tables/ChartsTable.vue';
import MPCTable from '@/ui/components/tables/MPCTable.vue';
import { IAppState } from '@/store/modules/app/types';
import { envWorkbenchCategories } from '@/utils/env';
import { IAnomalyDetection } from '@/types/anomalyDetection.types';
import { IMLModel } from '@/types/mpc/mpc.types';
import { IDevice } from '@/types/devices.types';

/**
 * Page for creating devices, charts, MPC devices
 */
@Component({
  components: {
    DevicesTable,
    AnomalyDetectionTable,
    ChartsTable,
    MPCTable,
  },
})
export default class Workbench extends Vue {
  @State('app') appState!: IAppState;
  @Getter('charts/charts') charts!: any;
  @Getter('mpc/mpcControllers') mpcControllers!: any;
  @Getter('anomalyDetection/anomalyDetectionDevices') anomalyDetectionDevices!: IAnomalyDetection[];
  @Getter('devices/devices') devices!: IDevice[];
  @Getter('mpc/mlModelDevices') mlModelDevices!: IMLModel[];
  @Mutation('aiml/addMLControl') addMLControl!: (payload: any) => void;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;
  @Mutation('app/setWorkbenchTab') setWorkbenchTab!: (payload: any) => void;
  @Action('devices/fetchDevices') fetchDevices!: (projectId: string) => Promise<void>;
  @Action('mpc/fetchMPCWeatherStatus') fetchMPCWeatherStatus!: () => Promise<void>;
  @Action('mpc/fetchMPCListByProject') fetchMPCListByProject!: () => Promise<void>;
  @Action('rooms/fetchRooms') fetchRooms!: (projectId: string) => any;

  readonly itemCountPerPage: number = 100;
  actualDevicesLoaded = false;

  get workbenchCurrentTab() {
    return this.appState.workbenchCurrentTab;
  }
  set workbenchCurrentTab(value: any) {
    this.setWorkbenchTab(value);
  }

  get workbench() {
    return [
      {
        tab: 'Devices',
        tabName: `${(this.$t(this.localisationPath) as any)[0]} (${this.devices.length || 0})`,
        table: 'DevicesTable',
      },
      {
        tab: 'Anomaly Detection',
        tabName: `${(this.$t(this.localisationPath) as any)[1]} (${this.anomalyDetectionDevices.length || 0})`,
        table: 'AnomalyDetectionTable',
      },
      {
        tab: 'Charts',
        tabName: `${(this.$t(this.localisationPath) as any)[2]} (${this.charts.length || 0})`,
        table: 'ChartsTable',
      },
      {
        tab: 'MPC',
        tabName: `${(this.$t(this.localisationPath) as any)[3]} (${this.mlModelDevices.length || 0})`,
        table: 'MPCTable',
      },
    ];
  }
  get workbenchFiltered() {
    return this.workbench.filter((e) => envWorkbenchCategories.includes(e.tab));
  }

  get localisationPath() {
    return 'uiComponents.workbench.workbenchTabs';
  }

  async mounted() {
    this.setProjectLoader(true);
    // Load Rooms, Devices, MPC
    await Promise.allSettled([
      this.fetchRooms(this.$route.params.id),
      this.fetchDevices(this.$route.params.id),
    ]);
    // turn of loader
    this.actualDevicesLoaded = true;
    await this.fetchMPCWeatherStatus();
    await this.fetchMPCListByProject();
    this.setProjectLoader(false);
  }
}
