
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Chart } from 'highcharts';
import BaseChart from '@/ui/components/devices/charts/charts/BaseChart.vue';
import { ApproximationValue, ChartData } from '@/types/chart.types';

/**
 * Component that represent simple wrapper for chart with one slot for additional content.
 * Used in MPC devices.
 */
@Component({
  components: {
    BaseChart,
  },
})
export default class BaseChartWrapper extends Vue {
  @Prop() chartData!: ChartData;
  @Prop() chartWidth!: number;
  @Prop() settingsWrapperHeight!: number; // is used to get the height of the modal window in order to size the height of the chart right
  @Prop({ default: undefined }) chartWrapperHeight!: number; // important for ems
  @Prop({ default: null }) navigationItemsToExclude!: null | string[];
  @Prop({ default: 0 }) parentComponentWidth!: number;
  @Prop({ default: () => ({ day: 4, week: 1, month: 1, year: 1 }) }) customApproximationValue!: ApproximationValue;
  @Prop({ default: null }) chartScores!: Record<string, {value: number; unit: string}>;
  @Prop({ default: undefined }) modularDefaultPeriod!: string

  onDataLoaded(data: [number, number][], chart: Chart, period: string) {
    this.$emit('onDataLoaded', data, chart, period);
  }
  chartRangeChange(params: { start: number; end: number; period: string }) {
    this.$emit('chartRangeChange', params);
  }
}
