
import { Vue, Component, Prop } from 'vue-property-decorator';
import SingleSystemsView
  from '@/ui/components/devices/components/EnergyParts/EnergyView/components/charts/SingleSystemsView.vue';
import SelfConsumption
  from '@/ui/components/devices/mpc/components/chartsWindow/SelfConsumption/index.vue';
import { IAppState } from '@/store/modules/app/types';
import { Getter, State } from 'vuex-class';
import { IProject } from '@/types/project.types';

@Component({
  components: {
    SingleSystemsView,
    SelfConsumption,
  },
})
export default class EnergyViewCharts extends Vue {
  @Prop({ default: null }) allSystemsData!: any;
  @Prop({ default: null }) scaling!: any;
  @Prop({ default: null }) mpcData!: any;
  @Prop({ default: '' }) mpcId!: string;
  @Prop({ default: false }) isHybridSystem!: boolean;

  @State('app') appState!: IAppState;
  @Getter('projects/project') project!: IProject;

  tab = null;

  get shownSystemsSingleSystemsChart() {
    const items = [
      'charge_station', 'electric_heating', 'heating_pump', 'big_consumer',
      'pv', 'generator', 'grid', 'battery', 'house', 'chp',
    ];
    if (this.project.meta?.controller?.type === 'Solarman Logger') {
      // remove house as loggers dont send the information needed for this chart
      const itemsToRemove = ['house'];
      return items.filter((item) => !itemsToRemove.includes(item));
    }
    return items;
  }

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  get items() {
    return [
      {
        title: this.$t('devices.EnergyView.ChartsWindow.selfConsumption'),
        view: 'SelfConsumption',
      },
      {
        title: this.$t('devices.EnergyView.ChartsWindow.singleSystems'),
        view: 'SingleSystemsView',
        showColumnChart: true,
        systemsVars: {
          charge_station: ['energy_counter'],
          electric_heating: ['energy_counter'],
          heating_pump: ['energy_counter'],
          big_consumer: ['energy_counter'],
          pv: ['energy_counter'],
          chp: ['energy_counter'],
          generator: ['energy_counter'],
          grid: ['energy_counter', 'reverse_energy_counter'],
          battery: ['energy_counter', 'reverse_energy_counter'],
          house: ['energy_counter'],
        },
        defaultAggregation: 'diff',
        navItemsToExclude: ['live', 'hour', 'day'],
      },
    ];
  }
}
