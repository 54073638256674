import { render, staticRenderFns } from "./BatteryLevel.vue?vue&type=template&id=2f6f7662&scoped=true&"
import script from "./BatteryLevel.vue?vue&type=script&lang=ts&"
export * from "./BatteryLevel.vue?vue&type=script&lang=ts&"
import style0 from "./BatteryLevel.vue?vue&type=style&index=0&id=2f6f7662&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f6f7662",
  null
  
)

export default component.exports