
import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import InstallationWizard from '@/ui/components/wizards/installationWizard/InstallationWizard.vue';
import LoggerWizard from '@/ui/components/wizards/loggerWizard/LoggerWizard.vue';
import LeaveConfirmationModal from '@/ui/components/modals/LeaveConfirmationModal.vue';
import { LoggerType } from '@/ui/components/wizards/loggerWizard/wizardSettings/systemTypes';
import { BatteryType } from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { AllBatteryTypes } from '@/utils/batteryTypes';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    InstallationWizard,
    LoggerWizard,
    LeaveConfirmationModal,
  },
})
export default class SetupAssistent extends Vue {
  @Getter('projects/batterySystemType') batterySystemType!: BatteryType | LoggerType | undefined | AllBatteryTypes;
  @Getter('loggerWizard/currentStep') activeLoggerWizardStep!: number;
  @Getter('loggerWizard/currentPage') currentLoggerWizardPage!: number;
  @Getter('projects/isMobileDeye') isMobileDeye!: boolean;
  @Mutation('loggerWizard/setCurrentStep') setLoggerWizardStep!: (payload: number) => void;
  @Mutation('loggerWizard/setCurrentPage') setLoggerWizardPage!: (payload: number) => void;
  @Mutation('loggerWizard/reset') resetLoggerWizardWizard!: () => Promise<void>;
  @Getter('installationWizard/currentStep') activeDefaultWizardStep!: number;
  @Getter('installationWizard/currentPage') currentDefaultWizardPage!: number;
  @Mutation('installationWizard/setCurrentStep') setDefaultWizardStep!: (payload: number) => void;
  @Mutation('installationWizard/setCurrentPage') setDefaultWizardPage!: (payload: number) => void;
  @Mutation('installationWizard/reset') resetDefaultWizard!: () => Promise<void>;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;
  @Action('members/fetchMembers') fetchMembers!: (projectId: string) => Promise<void>;
  @Action('devices/fetchDevices') fetchDevices!: (projectId: string) => Promise<void>;

  showInstallationWizard = false;
  showLoggerWizard = false;
  sendWizardVariables = false;

  async created() {
    this.setProjectLoader(true);
    await Promise.all([
      this.fetchDevices(this.$route.params.id),
      this.fetchMembers(this.$route.params.id),
    ]);

    // Switch between installation wizard and logger wizard
    if (this.batterySystemType === 'Solarman Logger (Deye)') {
      this.showLoggerWizard = true;
      this.showInstallationWizard = false;
      this.resetLoggerWizardWizard();
    } else {
      this.showInstallationWizard = true;
      this.showLoggerWizard = false;
      this.resetDefaultWizard();

      // On Route change we need to send variables
      this.sendWizardVariables = true;
    }
    this.setProjectLoader(false);

  }

  /**
   * Set Page and Step to the first page and step
   */
  beforeDestroy() {
    if (this.showInstallationWizard) {
      this.setDefaultWizardStep(1);
      this.setDefaultWizardPage(0);
    } else {
      this.setLoggerWizardStep(1);
      this.setLoggerWizardPage(0);
    }
  }

  /**
   * Will reset the wizard store
   */
  handleRouteChange() {
    if (this.showInstallationWizard) {
      this.resetDefaultWizard();
    } else {
      this.resetLoggerWizardWizard();
    }
  }

  /**
   * Check if the user is allowed to leave the page without the leave confirmation modal
   */
  async beforeRouteLeave(to: any, from: any, next: any) {
    if (this.isMobileDeye) {
      // for mobile devices we don't need the leave confirmation modal as the wizard never sends any variables to the plc
      next();
    }
    // installation wizard conditions
    if (this.activeDefaultWizardStep === 6 && this.showInstallationWizard) {
      await this.resetDefaultWizard();
      next();
    }
    if (this.activeDefaultWizardStep === 1) {
      if (this.currentDefaultWizardPage < 2 && this.showInstallationWizard) next();
    }
    // logger wizard conditions
    if (this.activeLoggerWizardStep === 4 && this.showLoggerWizard) {
      await this.resetLoggerWizardWizard();
      next();
    }
    if (this.currentLoggerWizardPage < 2 && this.showLoggerWizard) next();

    // show leave confirmation modal
    await (this.$refs.confirmationModal as LeaveConfirmationModal).confirmAction(to, next);
  }
}
