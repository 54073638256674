
import { Component, Inject, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import PaginationTabs from '@/ui/components/components/PaginationTabs.vue';
import { CirclePosition, EnergyCircleDisplayData, IOEnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import { EnergyLineDisplayData } from '@/types/energyVisualisation/EnergyLineDisplayData';
import { IOEnergyCircleType } from '@/types/energyVisualisation/EnergyCircleType';
import { IDevice } from '@/types/devices.types';
import EnergyVisualisationIO from './index.vue';

/**
 * Actual view for EnergyView/EMS
 */
@Component({
  components: {
    PaginationTabs,
    EnergyVisualisationIO,
  },
})
export default class IOSystemDialogView extends mixins(EnergyComponent) {
  @Prop() circlesDisplayData!: IOEnergyCircleDisplayData[];
  @Prop() linesDisplayData!: Partial<EnergyLineDisplayData>[];
  @Prop() openedSystem!: IOEnergyCircleType;
  @Prop({ default: null }) deviceData!: IDevice;

  @Provide('onIOCircleClick') onIOCircleClick = this.onSingleSystemClickCallback;
  @Inject({ from: 'onIOSingleCircleClick', default: () => {} }) onIOSingleCircleClick?: (
    circleData: IOEnergyCircleDisplayData,
  ) => void;

  @Provide('onLineAnimationEnd') onLineAnimationEnd = this.onLineAnimationEndCallback;
  @Inject({ from: 'onDialogLineAnimationEnd', default: () => {} }) onDialogLineAnimationEnd!: (id: string) => void;

  readonly elementsPerPage = 10;
  startIndex = 0;

  onLineAnimationEndCallback(id: string) {
    this.onDialogLineAnimationEnd?.(id);
  }

  onSingleSystemClickCallback(circleData: IOEnergyCircleDisplayData) {
    this.onIOSingleCircleClick?.(circleData);
  }
  get circleKeys() {
    return this.circlesDisplayData.map((el: IOEnergyCircleDisplayData) => el.id);
  }
  get circlesDisplayDataMap() {
    return new Map(this.circlesDisplayData.map((el: IOEnergyCircleDisplayData) => [el.id, el]));
  }
  get linesDisplayDataMap() {
    return new Map(this.linesDisplayData.map((el: Partial<EnergyLineDisplayData>) => [el.id, el]));
  }
  get circleKeysSorted() {
    const sortedArray = this.circleKeys
      .sort((a, b) => {
        // extract numbers from system keys
        const aNum = Number(a.match(/\d+/)?.[0]);
        const bNum = Number(b.match(/\d+/)?.[0]);
        return aNum - bNum;
      });
    return sortedArray.slice(this.startIndex, this.startIndex + this.elementsPerPage);
  }
  get sortedCirclesDisplayData() {
    return this.circleKeysSorted.map((key: string, index: number) => {
      const circleData = this.circlesDisplayDataMap.get(key);
      return {
        ...circleData,
        position: index < 5 ? CirclePosition.top : CirclePosition.bottom,
      } as EnergyCircleDisplayData;
    });
  }
  get sortedLinesDisplayData() {
    return this.circleKeysSorted.map((key: string) => {
      const lineData = this.linesDisplayDataMap.get(key);
      return { ...lineData } as EnergyLineDisplayData;
    });
  }

  updatePage(index: number) {
    this.startIndex = index;
  }

  async mounted() {
    await this.$nextTick();
    (this.$refs.EnergyVisualisationIO as any).updateCircles();
  }
}
