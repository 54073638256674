
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent MinusBase component
 */
@Component
export default class MinusBase extends Vue {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop({ default: 'red' }) color!: string;
  @Prop({ default: false }) fab!: boolean;
  @Prop({ default: 'minus' }) icon!: string;

  handleMinus() {
    this.$emit('handleMinus');
  }
}
