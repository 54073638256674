
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IMeasurements } from '@/types/measurements.types';
import { Getter, Mutation } from 'vuex-class';
import api from '@/services/api';
import { SendingState } from '@/ui/components/devices/devices/BasicControl';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';

/**
 * Custom button component for charging station V2 Device
 */
@Component({
  components: {
    FeedbackProvider,
  },
})
export default class ChargingStationButton extends Vue {
  @Prop() deviceId!: string;
  @Prop({ default: 0 }) buttonActive!: number;
  @Prop({ default: 0 }) buttonDisabled!: number;
  @Prop({ default: '' }) variableName!: string;

  @Getter('measurements/measurements') measurements!: IMeasurements;
  @Mutation('app/setReport') setReport!: (payload: any) => void;

  disableButtonForRequest = false;
  sendingState = SendingState.None;

  async handleSend() {
    const projectId = this.$route.params.id;
    this.sendingState = SendingState.Sending;
    try {
      await api.fetch(`/projects/${projectId}/devices/${this.deviceId}/charge`, 'POST');
      this.disableButtonForRequest = true;
      // wait for 2s and check value
      await new Promise(resolve => setTimeout(resolve, 2000));
      // if value is still 0, enable button else disable button as normal
      if (this.measurements[this.variableName] === 0) {
        this.disableButtonForRequest = false;
      } else {
        this.sendingState = SendingState.Success;
      }
    } catch (e) {
      const errorText = (e as any).response.data.message;
      let reportText = (e as any).response.data.message;
      if (errorText.includes('not an electric charging station')) {
        reportText = this.$t('errorMessages.chargingErrors.notAnElecticChargingStation') as string;
      } else if (errorText.includes('already charging')) {
        reportText = this.$t('errorMessages.chargingErrors.alreadyCharging') as string;
      } else if (errorText.includes('user not allowed')) {
        reportText = this.$t('errorMessages.chargingErrors.userNotAllowed') as string;
      }
      // show error message
      this.setReport({ type: 'error', message: reportText, value: true });
      this.disableButtonForRequest = false;
      this.sendingState = SendingState.Error;
    }

    // wait for 2s and reset sending state
    await new Promise(resolve => setTimeout(resolve, 2000));
    this.sendingState = SendingState.None;
    this.disableButtonForRequest = false;
  }
}
