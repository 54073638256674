
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import RotatingBacklight
  from '@/ui/components/devices/components/EnergyParts/RotatingBacklight.vue';
import ShowEventBaseGroup from '@/ui/components/devices/mpc/base/ShowEventBase.vue';
import BatteryLevel from '@/ui/components/devices/components/EnergyParts/BatteryLevel.vue';
import {
  IOEnergyCircleDisplayData,
  PlaceholderIOEnergyCircleDisplayData,
} from '@/types/energyVisualisation/EnergyCircleDisplayData';
import { kiloWattUnit } from '@/utils/unit/unitConstants';
import { convertNegativeZero, countDecimalPlaces } from '@/utils/utilsFunctions';
import EnergyCircleValueIO
  from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/EnergyCircleIO/EnergyCircleValueIO.vue';
import {
  energyGrey,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/constants';
import { IOEnergyCircleType } from '@/types/energyVisualisation/EnergyCircleType';

@Component({
  components: {
    EnergyCircleValueIO,
    BatteryLevel,
    RotatingBacklight,
    ShowEventBaseGroup,
  },
  methods: {
    energyGrey() {
      return energyGrey;
    },
    convertNegativeZero,
  },
})
export default class EnergyCircleIO extends Vue {
  @Prop({}) displayData!: IOEnergyCircleDisplayData;
  @Inject({ from: 'onIOCircleClick', default: () => {} }) onIOCircleClick?: (
    circleData: IOEnergyCircleDisplayData,
  ) => void;

  /**
   * Returns the bottom value formatted with one decimal place if the value has more than one decimal place.
   */
  get formattedBottomValue() {
    if (typeof this.displayData.bottomValue === 'number' && countDecimalPlaces(this.displayData.bottomValue) > 1) {
      return parseFloat(this.displayData.bottomValue.toFixed(1));
    }
    return this.displayData.bottomValue;
  }

  get systemCountPosition() {
    return this.displayData.systemType === IOEnergyCircleType.outputs ? 'system-count-bottom' : 'system-count-top';
  }

  get isPlaceholder() {
    return this.displayData instanceof PlaceholderIOEnergyCircleDisplayData;
  }

  get showLoadingAnimationHouseCircle() {
    return false;
  }

  get unit() {
    return this.displayData.unit ?? kiloWattUnit;
  }

  get rotatingBacklightNoData() {
    return !(this.displayData.bottomValue ?? this.displayData.alternativeValue);
  }

  get disableSpinner() {
    return this.displayData.disableSpinner !== true;
  }

  get circleContentStyle() {
    if (this.displayData.bottomValue === undefined) {
      return {};
    } else {
      return {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        background: 'var(--v-secondaryDeviceBackground-base)',
        padding: '2px 4px',
        zIndex: 5,
        borderRadius: '6px',
      };
    }
  }

  energyCircleOnClick() {
    if (this.displayData.id !== 'calculated') this.onIOCircleClick?.(this.displayData);
  }
}
