
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ArrowButtonMock extends Vue {
  @Prop({ default: 'arrow-direction-left' }) icon!: string;
  @Prop({ default: 24 }) iconSize!: null | number | string;
  @Prop({ default: true }) small!: boolean;
  @Prop({ default: '#6CC1FF' }) activeColor!: string;
  @Prop({ default: '#C4C4C4' }) inactiveColor!: string;

  state = false
}
