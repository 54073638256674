
import { Component, Vue } from 'vue-property-decorator';
import ManageRule from '@/ui/components/modals/ManageRule/index.vue';
import { State, Action, Mutation } from 'vuex-class';
import { IRulesState } from '@/store/modules/rules/types';
import { IRule } from '@/types/rules.types';

/**
 * Rules page that shows rules
 */
@Component({
  components: { ManageRule },
})
export default class Rules extends Vue {
  @State('rules') rulesState!: IRulesState;
  @Mutation('projects/setProjectLoader') setProjectLoader!: (status: boolean) => void;
  @Action('rules/loadRules') loadRules!: (project_id: string) => Promise<IRule[]>;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;

  openedPanel = null;
  rulesDataLoaded = false;

  get rulesList() {
    return this.rulesState.rulesList;
  }
  get rulesFilter() {
    return this.rulesState.rulesFilter;
  }
  get rulesListSortedByDate() {
    const copy = JSON.parse(JSON.stringify(this.rulesList));
    return copy.sort((a: IRule, b: IRule) => {
      const first = new Date(a.created_at).getTime();
      const second = new Date(b.created_at).getTime();
      if (first > second) return 1;
      if (first < second) return -1;
      return 0;
    });
  }
  get filteredRulesList() {
    return this.rulesFilter
      ? this.rulesListSortedByDate.filter((rule: IRule) => rule.name.toLowerCase().includes(this.rulesFilter.toLowerCase()))
      : this.rulesListSortedByDate;
  }

  closePanel() {
    this.openedPanel = null;
  }

  async created() {
    this.setProjectLoader(true);
    await Promise.allSettled([
      this.loadRules(this.$route.params.id),
      this.fetchVariables(this.$route.params.id),
    ]).finally(() => {
      this.setProjectLoader(false);
      this.rulesDataLoaded = true;
    });
  }
}

