
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import FeedbackProvider from '@/ui/components/devices/components/FeedbackProvider.vue';

/**
 * Component that represent ArrowIn Basic Control
 */
@Component({
  components: { FeedbackProvider },
})
export default class ArrowInBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop({ default: false }) small!: boolean;

  timer: any

  get state() {
    if (this.mappingsClean) {
      return !!this.measurements.get(this.mappingsClean.state);
    }
  }

  /**
   * Used to disable the Base component if there is no variable set in the mappings of the device.
   */
  get isNotMapped() {
    return this.mappingsClean.state === '' || this.mappingsClean.in === '';
  }
  handleIn() {
    this.onSendStart();
    this.send([{ v: 1, n: this.mappingsClean.in, u: '' }]);
    this.send([{ v: 0, n: this.mappingsClean.in, u: '' }]);
    this.onSendSuccess();
  }

  beforeDestroy() {
    clearTimeout(this.timer);
  }
}
