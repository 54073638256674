import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/store/types';
import { Map } from 'immutable';
import { IRoomsState } from '@/store/modules/rooms/types';
import i18n from '@/ui/plugins/i18n';
import { sortElementsByPosition, convertElementsPositionsToObject } from '@/utils/utilsFunctions';
import { IRoom } from '@/types/rooms.types';
import CollectionService from '@/services/CollectionService';

const state: IRoomsState = {
  rooms: Map(),
};

const getters: GetterTree<IRoomsState, RootState> = {
  rooms(
    state: IRoomsState,
    rootGetters: Record<string, any>,
  ) {
    const areaPositions = rootGetters['members/currentMember']?.meta?.area_positions;
    const areaPositionsMap = areaPositions
      ? convertElementsPositionsToObject(rootGetters['members/currentMember'].meta.area_positions)
      : {};

    return sortElementsByPosition({
      elementsList: state.rooms.valueSeq().toJS(),
      positions: areaPositionsMap,
    });
  },

  /**
   * Sort the list of rooms by number in name and alphabetically.
   * @param state
   * @param getters
   * @return list of Rooms
   */
  sortedRoomsByName(state: IRoomsState, getters: any) {
    const numbersRegExp = /^[0-9]+/g;

    const roomsWithNumberInName = getters.rooms.filter((room: IRoom) => {
      return numbersRegExp.test(room.name);
    });
    const roomsWithoutNumbersInName = getters.rooms.filter((room: IRoom) => {
      return !numbersRegExp.test(room.name);
    });

    const roomsWithNumberInNameSorted = roomsWithNumberInName.sort((a: IRoom, b: IRoom) => {
      const aNameArr = a.name.match(numbersRegExp) || ['Infinity'];
      const bNameArr = b.name.match(numbersRegExp) || ['Infinity'];
      const aNameNum = Number(aNameArr[0]);
      const bNameNum = Number(bNameArr[0]);
      return aNameNum - bNameNum;
    });
    const roomsWithoutNumbersInNameSorted = roomsWithoutNumbersInName.sort((a: IRoom, b: IRoom) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

    return [...roomsWithNumberInNameSorted, ...roomsWithoutNumbersInNameSorted];
  },
};

const mutations: MutationTree<IRoomsState> = {
  setRooms(state: IRoomsState, rooms: Map<string, IRoom>) {
    state.rooms = rooms;
  },
};

const actions: ActionTree<IRoomsState, any> = {
  /**
   * Load rooms
   * @param commit
   * @param rootState
   * @param payload
   */
  async fetchRooms({ commit, rootState }, payload) {
    try {
      const res = await CollectionService.fetchCollections(payload);
      const rooms = (res as IRoom[]).reduce((acc, cur) => acc.set(cur.id, cur), Map<string, IRoom>());
      commit('setRooms', rooms);
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Creates room
   * @param dispatch
   * @param commit
   * @param state
   * @param rootState
   * @param payload
   */
  async createRoom({ dispatch, commit, state, rootState }, payload: any) {
    let assetResponse;
    let { cover } = payload.room;

    if (payload.cover) {
      if (payload.coverType === 'picture') {
        assetResponse = await dispatch('app/postAsset', payload.cover, { root: true });
        cover = `/assets/${assetResponse}`;
      } else if (payload.coverType === 'icon') {
        cover = `/icons/${payload.cover}.svg`;
      }
    }

    const roomResponse: IRoom = await CollectionService.createCollection(
      rootState.projects.projectId,
      {
        name: payload.room.name,
        meta: { cover, devicesPositions: undefined },
      },
    );
    commit('setRooms', state.rooms.set(roomResponse.id, roomResponse));
    commit('app/setReport', {
      type: 'success',
      message: i18n.t('uiComponents.reportMessages.createArea'),
      value: true,
    }, { root: true });
  },

  /**
   * Creates copy of room
   * @param dispatch
   * @param commit
   * @param state
   * @param rootState
   * @param payload
   */
  async copyRoom({ dispatch, commit, state, rootState }, payload: any) {
    try {
      const roomResponse: IRoom = await CollectionService.createCollection(
        rootState.projects.projectId,
        payload.room,
      );
      commit('setRooms', state.rooms.set(roomResponse.id, roomResponse));
      return roomResponse;
    } catch (e) {
      console.log(e);
      return { error: e };
    }
  },

  /**
   * Delete selected room
   * @param commit
   * @param state
   * @param dispatch
   * @param rootState
   * @param roomId
   */
  async deleteRoom({ commit, state, dispatch, rootState }, roomId: string) {
    try {
      await CollectionService.deleteCollection(rootState.projects.projectId, roomId);
      commit('setRooms', state.rooms.remove(roomId));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.deleteArea'),
        value: true,
      }, { root: true });
      rootState.mpc.mpcControllers.forEach((mpc: any) => {
        if (mpc.collection_id === roomId) {
          dispatch('mpc/deleteMPC', mpc, { root: true });
        }
      });
      rootState.devices.allDevices.forEach((device: any) => {
        if (device.room !== roomId) {
          dispatch('devices/deleteDevice', device, { root: true });
        }
      });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Updates selected room
   * @param commit
   * @param state
   * @param rootState
   * @param dispatch
   * @param payload
   */
  async updateRoom({ commit, state, rootState, dispatch }, payload) {
    try {
      let assetResponse;
      let { cover } = payload.room.meta;

      if (payload.cover) {
        if (payload.coverType === 'picture') {
          assetResponse = await dispatch('app/postAsset', payload.cover, { root: true });
          cover = `/assets/${assetResponse}`;
        } else if (payload.coverType === 'icon') {
          cover = `/icons/${payload.cover}.svg`;
        }
      }

      const roomResponse: IRoom = await CollectionService.updateCollection(
        rootState.projects.projectId,
        payload.room.id,
        {
          name: payload.room.name,
          meta: {
            cover,
            devicesPositions: payload.room.meta.devicesPositions || undefined,
          },
        },
      );
      commit('setRooms', state.rooms.set(roomResponse.id, roomResponse));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.editArea'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },
};

export const rooms: Module<IRoomsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
