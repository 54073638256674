
import { Vue, Component, Prop } from 'vue-property-decorator';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { EnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import LynusIcon from '@/ui/components/components/LynusIcon.vue';
import StatusIndicator from '@/ui/components/components/StatusIndicator.vue';

/**
 * Component that represents the force charge indicator for battery systems
 */
@Component({
  components: {
    StatusIndicator,
    LynusIcon,
    ModalWindow,
    InfoTooltip,
  },
})
export default class ChargeIndicator extends Vue {
  @Prop({}) displayData!: EnergyCircleDisplayData;

  index = 0;
  allSymbols = ['ronova_battery_charge2', 'ronova_battery_calibrate', 'ronova_battery_protect', 'ronova_battery_plus'];

  get forceCharge() {
    return this.displayData.additionalDisplayData?.forceCharge;
  }

  get items() {
    return [
      {
        title: this.$t('mlModel.EMSV2.mainView.forceCharge'),
        info: this.$t('mlModel.EMSV2.mainView.forceChargeInfo'),
        link: 'https://lynus.zammad.com/help/de-de/68-faq/124-erklarung-force-charge',
      },
      {
        title: this.$t('mlModel.EMSV2.mainView.calibrationCharge'),
        info: this.$t('mlModel.EMSV2.mainView.calibrationChargeInfo'),
        link: 'https://lynus.zammad.com/help/de-de/68-faq/121-erklarung-kalibrierungsladung',
      },
      {
        title: this.$t('mlModel.EMSV2.mainView.maintenanceCharge'),
        info: this.$t('mlModel.EMSV2.mainView.maintenanceChargeInfo'),
        link: 'https://lynus.zammad.com/help/de-de/68-faq/122-erklarung-erhaltungsladung',
      },
      {
        title: this.$t('mlModel.EMSV2.mainView.chargeMaintenance'),
        info: this.$t('mlModel.EMSV2.mainView.chargeMaintenanceInfo'),
        link: 'https://lynus.zammad.com/help/de-de/68-faq/123-erklarung-ladungserhaltung',
      },
    ];
  }

  get calibrationCharge() {
    return this.displayData.additionalDisplayData?.calibrationCharge;
  }

  get maintenanceCharge() {
    return this.displayData.additionalDisplayData?.maintenanceCharge;
  }

  get chargeMaintenance() {
    return this.displayData.additionalDisplayData?.chargeMaintenance;
  }

  get activeSymbols() {
    return [
      this.forceCharge ? 'ronova_battery_charge2' : '',
      this.calibrationCharge ? 'ronova_battery_calibrate' : '',
      this.maintenanceCharge ? 'ronova_battery_protect' : '',
      this.chargeMaintenance ? 'ronova_battery_plus' : '',
    ].filter((symbol) => symbol !== '');
  }

  get currentIcon() {
    return this.activeSymbols[this.index];
  }

  mounted() {
    // Interval to switch between all active icons
    setInterval(() => {
      this.index = (this.index + 1) % this.activeSymbols.length;
    }, 5000);
  }
}
