
import { Vue, Component } from 'vue-property-decorator';

/**
 * Component where you can download descriptions, documentations
 */
@Component
export default class DeviceConnectivity extends Vue {
  downloadsChips = [
    {
      topic: 'softwarePackageForBeckhoff',
      chips: [
        {
          isChip: true,
          title: 'controllersV2',
          source: './files/Dokumentation_zur_Benutzung_des_Lynus_Softwarepaketes_für_die_Beckhoff.pdf',
          linkAttr: 'href',
          isLoaded: false,
        },
        {
          isChip: false,
          title: 'tcpEdgeDevice',
          source: '/documentation/deviceConnectivity/edgeDevice',
          linkAttr: 'to',
          isLoaded: false,
        },
      ],
    },
    {
      topic: 'interfaceLynus',
      chips: [
        {
          isChip: true,
          title: 'interfaceDescriptionLynus',
          source: './files/Schnittstellenbeschreibung Lynus.pdf',
          linkAttr: 'href',
          isLoaded: false,
        },
      ],
    },
  ]

  get isCurrentRoute() {
    return this.$route.name === 'DeviceConnectivity';
  }
}
