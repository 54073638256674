
import { Vue, Component } from 'vue-property-decorator';
import OfflineChart from '@/ui/components/devices/previews/charts/components/OfflineChart.vue';
import SystemsNavigation from '@/ui/components/devices/mpc/SetpointOptimizer/components/SystemsNavigation.vue';

@Component({
  components: {
    OfflineChart,
    SystemsNavigation,
  },
})
export default class SingleSystemsChart extends Vue {
  selectedSystem = 'pv';
  reRenderKey = 0;

  mockItems = [
    ['pv', [['pv1', 'pv2']]],
    ['generator', [['generator1', 'generator2']]],
    ['grid', [['grid1']]],
    ['battery', [['battery1']]],
    ['house', [['house1', 'house2']]],
    ['charge_station', [['charge_station1', 'charge_station2']]],
    ['electric_heating', [['electric_heating1', 'electric_heating2']]],
    ['heating_pump', [['heating_pump1', 'heating_pump2']]],
    ['big_consumer', [['big_consumer1', 'big_consumer2']]],
  ]

  handleNavigationChanged(currentSystem: any) {
    this.selectedSystem = currentSystem.system;
    this.reRenderKey += 1;
  }

  // contains Chart Object
  get mockChartData() {
    let currentChartOptions: any = [];
    if (this.selectedSystem === 'battery' || this.selectedSystem === 'grid') {
      currentChartOptions = [
        {
          agg: 'first',
          name: `${this.$t(`mlModel.EMSV2.systems.${this.selectedSystem}.reverse_energy_counter`)}`,
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'column',
          unit: 'kW',
          var: '',
        }, {
          agg: 'first',
          name: `${this.$t(`mlModel.EMSV2.systems.${this.selectedSystem}.energy_counter`)}`,
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'column',
          unit: 'kW',
          var: '',
        },
      ];
    } else {
      currentChartOptions = [
        {
          agg: 'first',
          name: `${this.$t(`mlModel.EMSV2.systems.${this.selectedSystem}.energy_counter`)} 1`,
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'column',
          unit: 'kW',
          var: '',
        },
        {
          agg: 'first',
          name: `${this.$t(`mlModel.EMSV2.systems.${this.selectedSystem}.energy_counter`)} 2`,
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'column',
          unit: 'kW',
          var: '',
        },
      ];
    }

    const currentData = {
      data: {
        chartOptions: currentChartOptions,
        selectedStackingOptions: 'normal',
        selectedWidth: 'full',
        type: 'chart',
      },
      created_at: '2021-10-07T07:43:33.554983Z',
    };
    return currentData;
  }
  get settingsViewLang() {
    return 'devices.EMSV2.settingsView';
  }
}
