
import { Component, Prop } from 'vue-property-decorator';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import SwitchBase from '@/ui/components/devices/devices/base/SwitchBase.vue';
import SliderBase from '@/ui/components/devices/devices/base/SliderBase.vue';
import ShowEventBase from '@/ui/components/devices/devices/base/ShowEventBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent ElectronicBoiler device
 */
@Component({
  components: {
    OutputFieldBase,
    InputFieldBase,
    SwitchBase,
    SliderBase,
    ShowEventBase,
    DeviceLayout,
    LabelUnitWrapper,
  },
})
export default class ElectronicBoiler extends mixins(Validation) {
  @Prop() deviceData!: IDevice;
  @State('measurements') measurementsState!: IVariablesState;

  get measurements() {
    return this.measurementsState.measurements;
  }
  get sliderActualValue() {
    return this.measurements.get(this.deviceData.data.mappings.OutputField_actualValue);
  }

  get langPath() {
    return `devices.${this.deviceData.data.type}`;
  }

  get isActualValueVariableFilled() {
    return !!this.deviceData.data.mappings.OutputField1_actualValue.length;
  }
}
