var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('WizardContentView',{attrs:{"valid":_vm.valid,"handle-back":_vm.handleDecrement,"handle-next":_vm.handleNext},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',[_vm._v(_vm._s(_vm.$t(`installationWizard.defineEmsSettings.chp.title`)))])]},proxy:true},{key:"content",fn:function(){return [_c('v-form',{ref:"form",staticClass:"general-form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},_vm._l((_vm.visibleVariables),function(chpElement,index){return _c('v-row',{key:index},[_c('h3',{staticClass:"py-3 px-3"},[_vm._v(_vm._s(_vm.$t(`installationWizard.defineEmsSettings.chp.device`))+" "+_vm._s(index + 1)+" ")]),_vm._l((Object.entries(chpElement)),function(variable,variableIndex){return _c('v-col',{key:'var'+index+variableIndex,attrs:{"cols":"12","lg":"12","md":"12","sm":"12"}},[(variable[1].fieldInfo.type === 'textField')?_c('div',{staticStyle:{"width":"50%"}},[_c('v-text-field',{attrs:{"value":variable[1].mqttVariableInfo.value,"label":_vm.$t(`installationWizard.defineEmsSettings.chp.fieldLabel.${variable[0]}`),"color":"accent","type":"number","outlined":"","suffix":variable[1].fieldInfo.unit,"rules":[
                ...variable[1].fieldInfo.rules,
                ..._vm.minMaxValidation(
                  variable[1].mqttVariableInfo.value,
                  variable[1].fieldInfo.min,
                  variable[1].fieldInfo.max,
                )
                ]},on:{"change":function($event){return _vm.updateVariables($event, index, variable[0])}}})],1):_vm._e(),(variable[1].fieldInfo.type === 'checkbox')?_c('div',[_c('v-checkbox',{attrs:{"label":_vm.$t(`installationWizard.defineEmsSettings.chp.fieldLabel.${variable[0]}`),"true-value":1,"false-value":0,"color":"accent"},model:{value:(variable[1].mqttVariableInfo.value),callback:function ($$v) {_vm.$set(variable[1].mqttVariableInfo, "value", $$v)},expression:"variable[1].mqttVariableInfo.value"}})],1):_vm._e()])})],2)}),1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }