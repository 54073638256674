
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class HourPickerMultiSelect extends Vue {
  @Prop() value!: any;
  @Prop() enableMultiSelect!: boolean;

  date: any = null;
  time: any = '';
  dateList: any = [];
  disableMoreDates = false;
  formattedDateList: string[] = [];
  devBaseBGColor = this.$vuetify.theme.dark ? '#424242' : '#ffffff';
  reRenderKey = 1;

  @Watch('dateList')
  onDateTimeChange(val: any) {
    const returnValue = this.convertTimeToMs(val);
    this.$emit('input', returnValue);
  }

  handleRemoval(index: any) {
    this.formattedDateList.splice(index, 1);
  }

  convertTimeToMs(currentValue: any) {
    if (this.dateList.length >= 5) {
      this.disableMoreDates = true;
    }
    const val = currentValue;
    const returnArray: any = [];
    let d: any = '';
    let formatedDateSting = '';
    val.forEach((element: any, index: number) => {
      if (Number.isInteger(element) === false) {
        // date need to have the following format ['yyyy-mm-dd'] or ['yyyy-mm-dd', 'yyyy-mm-dd']
        d = new Date(element.year, element.month - 1, element.day, element.hour, element.minute);
        formatedDateSting = `${element.hour}:${element.minute}  ${element.day}.${element.month}.${element.year}`;
        if (!this.formattedDateList.includes(formatedDateSting)) {
          this.formattedDateList.push(formatedDateSting);
        }
        const newVal = Math.trunc(d.getTime() / 1000);
        returnArray.push(newVal);
      }
    });
    return returnArray;
  }

  addCurrentDate() {
    this.dateList.push(this.fullDateTime);
    this.date = null;
    this.time = '';
    this.reRenderKey += 1;
  }

  get fullDateTime() {
    if (this.date && this.time) {
      this.disableMoreDates = false;
      const [year, month, day] = this.date.split('-');
      const [hour, minute] = this.time.split(':');
      return { year, month, day, hour, minute };
    } else {
      this.disableMoreDates = true;
      return null;
    }
  }

  get datePickerMax() {
    return new Date().toISOString();
  }

  timePickerMax() {
    const d: any = new Date();
    const local = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
    const isToday: boolean = local === this.date;
    return isToday ? `${d.getHours()}:${d.getMinutes()}` : null;
  }
}
