
import { Vue, Component, Inject } from 'vue-property-decorator';
import TimeTable from '@/ui/components/devices/previews/mpc/EMS/charts/Forecast/TimeTable.vue';
import OfflineChart from '@/ui/components/devices/previews/charts/components/OfflineChart.vue';

@Component({
  components: {
    TimeTable,
    OfflineChart,
  },
})
export default class Forecast extends Vue {
  @Inject({ from: 'chartColors' }) injectedChartColors!: string[];

  tab: any = null

  // contains Chart Object
  get mockChartData() {
    let currentChartOptions: any = [];
    if (this.tab === 0) {
      currentChartOptions = [
        {
          agg: 'first',
          name: this.$t('mlModel.PVProductionService.settingsView.chartLabels.forecast.predictedEnergy'),
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kWh',
          var: '',
        }, {
          agg: 'first',
          name: this.$t('mlModel.PVProductionService.settingsView.chartLabels.forecast.predictedPower'),
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kWh',
          var: '',
        },
      ];
    } else if (this.tab === 1) {
      currentChartOptions = [
        {
          agg: 'first',
          name: this.$t('mlModel.ConsumptionService.settingsView.chartLabels.forecast.predictedEnergy'),
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kWh',
          var: '',
        }, {
          agg: 'first',
          name: this.$t('mlModel.ConsumptionService.settingsView.chartLabels.forecast.predictedPower'),
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kWh',
          var: '',
        },
      ];
    } else if (this.tab === 2) {
      currentChartOptions = [
        {
          agg: 'first',
          name: this.$t('mlModel.EMS.charts.forecast.difference.predictedPVPower'),
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kWh',
          var: '',
        }, {
          agg: 'first',
          name: this.$t('mlModel.EMS.charts.forecast.difference.predictedConsumption'),
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kWh',
          var: '',
        }, {
          agg: 'first',
          name: this.$t('mlModel.EMS.charts.forecast.difference.predictedGrid'),
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kWh',
          var: '',
        },
      ];
    } else {
      currentChartOptions = [
        {
          agg: 'first',
          name: 'var-1',
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kWh',
          var: '',
        }, {
          agg: 'first',
          name: 'var-2',
          scaling: {
            max: '100',
            min: '0',
          },
          seriesType: 'View',
          type: 'line',
          unit: 'kWh',
          var: '',
        }
      ];
    }

    const currentData = {
      data: {
        chartOptions: currentChartOptions,
        selectedStackingOptions: 'normal',
        selectedWidth: 'full',
        type: 'chart',
      },
      created_at: '2021-10-07T07:43:33.554983Z',
    };
    return currentData;
  }

  get mockMonth() {
    const series = [];
    let day = 0;
    let allDays = 28;
    while (allDays > 0) {
      day += 1;
      const d: any = new Date(2020, 1, day);
      series.push([Date.parse(d), Math.round(Math.random() * 100)]);
      allDays -= 1;
    }
    return series;
  }

  get items() {
    return [
      {
        title: this.$t('mlModel.EMS.charts.forecast.pv.title'),
      },
      {
        title: this.$t('mlModel.EMS.charts.forecast.consumption.title'),
      },
      {
        title: this.$t('mlModel.EMS.charts.forecast.difference.title'),
      },
      {
        title: this.$t('mlModel.EMS.charts.forecast.timeTableConsumer.title'),
      },
    ];
  }
}
