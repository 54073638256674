
import { Vue, Component, Prop } from 'vue-property-decorator';
import { IVariablesState } from '@/store/modules/measurements/types';
import { State } from 'vuex-class';
import _ from 'lodash';

@Component({
  components: {},
})
export default class AverageRoomTemps extends Vue {
  @Prop({ default: null }) controls!: any;
  @Prop({ default: undefined }) roomTemperatures!: any;
  @State('measurements') measurementsState!: IVariablesState;

  get measurements() {
    return this.measurementsState.measurements;
  }

  get averageOfRoomTemps(): any {
    const valuesArray = this.roomTemperatures.map((element: any) => {
      const controlsObject = this.controls.room_temperatures[element];
      return this.measurements.get(controlsObject.variable);
    });
    return _.mean(valuesArray).toFixed(2);
  }
}
