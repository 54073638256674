
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SliderMock extends Vue {
  @Prop({ default: 15 }) fontSize!: number;
  @Prop({ default: null }) appendIcon!: string;
  @Prop({ default: null }) prependIcon!: string;
  @Prop({ default: false }) showValAbove!: boolean;
  sliderState = 20
}
