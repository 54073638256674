
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ProgressBar extends Vue {
  @Prop({ default: 100 }) max!: number;
  @Prop({ default: 0 }) current!: number;
  @Prop({ default: 40 }) size!: number;
  @Prop({ default: 'default' }) color!: string; // possible values: default, accent, secondary (colors are taken from config.ts/VUE_APP_THEMES)

  get percentage() {
    if (this.max === 0) return 0;
    return (this.current / this.max) * 100;
  }

  get percentageText() {
    return `${this.percentage.toFixed(0)}%`; // Rounds to nearest whole number
  }
}
