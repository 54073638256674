import api from '@/services/api';
import { IPartnerWorkspace } from '@/types/partnerWorkspace.types';

export default {
  fetchWorkspaces() {
    return api.fetch('/admin/projects', 'GET');
  },
  createdWorkspace(id: string, workspace: IPartnerWorkspace) {
    return api.fetch('/admin/projects', 'POST', { partner_id: id, ...workspace });
  },
  updateWorkspace(project_id: string, workspace: IPartnerWorkspace) {
    return api.fetch(`/admin/projects/${project_id}`, 'PUT', workspace);
  },
  deleteWorkspace(project_id: string) {
    return api.fetch(`/admin/projects/${project_id}`, 'DELETE');
  },
  fetchWorkspaceNonAdmin() {
    return api.fetch('/partner/projects', 'GET');
  },
  createWorkspaceNonAdmin(workspace: IPartnerWorkspace) {
    return api.fetch('/partner/projects', 'POST', workspace);
  },
  updateWorkspaceNonAdmin(project_id: string, workspace: IPartnerWorkspace) {
    return api.fetch(`/partner/projects/${project_id}`, 'PUT', workspace);
  },
  deleteWorkspaceNonAdmin(project_id: string) {
    return api.fetch(`/partner/projects/${project_id}`, 'DELETE');
  },
};
