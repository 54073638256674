
import { IReportBox } from '@/types/app.types';
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import { Icon } from '@/types/common.types';

// IconList is a general component that can be used with
// any icon library. It implements a typing timeout to
// improve search performance.
// When clicking on an icon, the event @selected will be
// emitted and will return the icon
//
// Example:
// <IconList :search-timeout="350" @selected="onSelected" :icons="icons" :search="search" style="margin: 24px;"/>
// icons: Icon[] = []
//
// search(q: string) {
//   // clear icons if the query is smaller than 3 chars
//   if (q.length < 3) {
//     return new Promise((res) => {
//       this.icons = [];
//       res();
//     });
//   }
//
//   return IconService.fetchIcons(q)
//     .then(icons => this.icons = icons.map((icon: any) => ({ term: icon.term, url: icon.icon_url })));
// }
//
// onSelected(icon: Icon) {
//   console.log(icon);
// }
@Component
export default class IconList extends Vue {
  // https://stackoverflow.com/a/4220182
  // searchTimeout is the time a user has stopped typing
  // which is needed to trigger the @search event. This
  // prevents sending too much api requests
  @Prop({ default: 350 }) searchTimeout!: number
  // search is a prop func that returns the icons as a Promise
  @Prop({ required: true }) search!: (q: string) => Promise<void | Icon[]>
  // icons list. This is exposed as prop so that we can take out
  // the icon handling logic from IconList
  @Prop({ required: true }) icons!: Icon[]
  @Prop() outlined?: boolean;
  @Mutation('app/setReport') setReport!: (payload: IReportBox) => void;

  typingTimer?: number;
  loading = false;
  input = '';
  selected: Icon = { term: '', id: '' };

  @Watch('input')
  async onSearch(q: string) {
    clearTimeout(this.typingTimer);

    this.loading = true;
    this.typingTimer = window.setTimeout(() => {
      this.search(q)
        .catch((e) => this.setReport({ type: 'error', message: e.message, value: true }))
        .finally(() => this.loading = false);
    }, this.searchTimeout);
  }

  change(icon: Icon) {
    this.selected = icon;
    this.$emit('selected', icon);
  }
}
