
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent range time slider.
 * Used in forecast views in EMS, Setpoint Optimizer
 */
@Component
export default class TimeSlider extends Vue {
  @Prop({ default: null }) value!: any;
  @Prop({ default: [] }) items!: any;

  /**
   * Define langs locales for dates
   * @return {string} locale
   */
  get langSettingSting() {
    let langString = 'en-GB';
    if (this.$i18n.locale === 'de') {
      langString = 'de-GE';
    } else if (this.$i18n.locale === 'it') {
      langString = 'it-IT';
    }
    return langString;
  }

  /**
   * First time from this.items prop
   * @return {string} local time string
   */
  get groupSliderStartLabel() {
    if (!this.items?.length) return 'no data';

    // options object which show time string in specific format
    const options: any = {
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
    const d: any = new Date(this.items[0][0] * 1000);
    return d.toLocaleTimeString(this.langSettingSting, options);
  }

  /**
   * Last time from this.items prop
   * @return {string} local time string
   */
  get groupSliderEndLabel() {
    if (!this.items?.length) return 'no data';

    // options object which show time string in specific format
    const options: any = {
      month: 'long',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
    const d: any = new Date(this.items[this.items.length - 1][0] * 1000);
    return d.toLocaleTimeString(this.langSettingSting, options);
  }

  /**
   * Current Thumb label value
   * @return {string} local time string
   */
  get groupSliderThumbLabelValue() {
    if (!this.items?.length) return 'no data';

    const options: any = {
      hour: '2-digit',
      minute: '2-digit',
    };
    const startValue = this.value;
    const endValue = this.value + 1;
    const defineTime = (value: number) => {
      if (this.items[value]) {
        return new Date(this.items[value][0] * 1000);
      } else {
        return null;
      }
    };

    const start: any = defineTime(startValue);
    const end: any = defineTime(endValue);
    return {
      start: start.toLocaleTimeString('de-GE', options),
      end: end ? end.toLocaleTimeString('de-GE', options) : end,
    };
  }

  get sliderMaxLength() {
    return this.items?.length ? this.items.length - 1 : 0;
  }
}
