
import { Vue, Component } from 'vue-property-decorator';
import DeviceDescription from '@/ui/components/devices/actions/DeviceDescription.vue';
import PreviewActions from '@/ui/components/devices/actions/PreviewActions.vue';
import PushButtonMock from '@/ui/components/devices/previews/components/PushButtonMock.vue';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    DeviceDescription,
    PreviewActions,
    PushButtonMock,
    ShowEventDotMock,
    DeviceLayout,
  },
})
export default class TSGModulLadestationPreview extends Vue {
  get mainViewLang() {
    return 'devices.TSGLadestationNotAus.mainView';
  }
  get description() {
    return this.$t('devicesDescriptions.TSGModulLadestation');
  }
  get previewNameLang() {
    return this.$t('devices.TSGModulLadestation.previewName');
  }
}
