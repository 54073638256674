
import { Component, Prop } from 'vue-property-decorator';
import SystemModalWindowView from '@/ui/components/devices/components/EnergyParts/SystemModalWindowView.vue';
import AnimatedLine from '@/ui/components/devices/components/EnergyParts/AnimatedLine/index.vue';
import Preview from '@/ui/components/devices/components/EnergyParts/Preview.vue';
import HybridSettings from '@/ui/components/devices/previews/mpc/SetpointOptimizer/system/HybridSettings.vue';
import DefaultSettings from '@/ui/components/devices/previews/mpc/SetpointOptimizer/system/DefaultSettings.vue';
import { UnitMixin } from '@/ui/mixins/unitMixin';
import { mixins } from 'vue-class-component';

@Component({
  components: {
    SystemModalWindowView,
    AnimatedLine,
    Preview,
    HybridSettings,
    DefaultSettings,
  },
})
export default class SystemInstance extends mixins(UnitMixin) {
  @Prop({ default: false }) isForecastView!: boolean;
  @Prop({ default: null }) forecastActualValue!: number;
  @Prop({ default: null }) systemInstanceType!: string;
  @Prop({ default: 'top' }) previewTitlePosition!: string;
  @Prop({ default: null }) systemInstanceData!: any;
  @Prop({ default: null }) instanceCanvasSize!: any;
  @Prop({ default: null }) instanceCanvasCenter!: any;
  @Prop({ default: null }) instanceLineData!: any;
  @Prop({ default: false }) instanceLineReady!: boolean;
  @Prop({ default: null }) systemColors!: any;
  @Prop({ default: true }) isLineMovementReversed!: boolean;
  @Prop({ default: null }) currentPreviewIcon!: any;

  get settingsType() {
    return ({
      heating_air_systems: 'DefaultSettings',
      heating_water_systems: 'DefaultSettings',
      cooling_air_systems: 'DefaultSettings',
      cooling_water_systems: 'DefaultSettings',
      hybrid_water_systems: 'HybridSettings',
      hybrid_air_systems: 'HybridSettings',
    } as any)[this.systemInstanceType];
  }

  /**
   * Defines default line movement direction for current system
   * @return {boolean} if true arrows moves in default direction else in opposite direction
   */
  get defaultLineMovement() {
    return ({
      heating_air_systems: false,
      heating_water_systems: false,
      cooling_air_systems: false,
      cooling_water_systems: false,
      hybrid_water_systems: true, // switch between hating and cooling
      hybrid_air_systems: true, // switch between hating and cooling
    } as any)[this.systemInstanceType];
  }

  /**
   * Collection of systems line movement directions
   */
  get lineMovementSystems(): any {
    return {
      heating_air_systems: true,
      heating_water_systems: true,
      cooling_air_systems: true,
      cooling_water_systems: true,
      hybrid_water_systems: false, // switch between hating and cooling
      hybrid_air_systems: false, // switch between hating and cooling
    };
  }

  /**
   * Defines line movement direction for current system
   * @return {boolean} if true arrows moves in default direction else in opposite direction
   */
  get lineMovement() {
    return this.lineMovementSystems[this.systemInstanceType];
  }

  get modalWindowWidth() {
    const hybrid: string[] = ['hybrid_water_systems', 'hybrid_air_systems'];
    return hybrid.includes(this.systemInstanceType);
  }
}
