
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import IconList from '@/ui/components/components/IconList.vue';
import DefaultSchema from '@/ui/components/modals/ManageDevice/schemas/DefaultSchema.vue';
import DefaultSchemaWithUserSelection from '@/ui/components/modals/ManageDevice/schemas/DefaultSchemaWithUserSelection.vue';
import GaugeSchema from '@/ui/components/modals/ManageDevice/schemas/GaugeSchema.vue';
import DropDownSchema from '@/ui/components/modals/ManageDevice/schemas/DropDownSchema.vue';
import EMSSchema from '@/ui/components/modals/ManageMLModel/schemas/EMSSchema.vue';
import PreviewImage from '@/ui/components/components/PreviewImage.vue';
import i18n from '@/ui/plugins/i18n';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import VueI18n from 'vue-i18n';
import { IDevice, IDevicesTypes } from '@/types/devices.types';

/**
 * A component that allows to create or modify device
 */
@Component({
  components: {
    PreviewImage,
    DropDownSchema,
    GaugeSchema,
    DefaultSchema,
    EMSSchema,
    IconList,
    ModalWindow,
    DefaultSchemaWithUserSelection,
  },
})
export default class ManageDevice extends Vue {
  @Prop({
    default: () => ({
      name: '',
      data: {
        type: '',
        mappings: {},
        meta: {},
      },
      collection_id: '',
    }),
  }) deviceData?: IDevice;
  @Prop({ default: '' }) formTitle?: string | VueI18n.TranslateResult;
  @Prop({ default: '' }) activeRoomId!: string;
  @Getter('devices/lastCreatedDevice') lastCreatedDevice!: string;
  @Getter('devices/devicesTypes') devicesTypes!: IDevicesTypes;
  @Getter('devices/devicesTypesWithLocaleNamesList') devicesTypesWithLocaleNamesList!: { value: string; title: VueI18n.TranslateResult }[];

  deviceType = { title: '', value: '' }
  deviceFormView = false
  currentSelectedTheme = '';
  imageMaxHeight = window.innerHeight * 0.7;
  devicesTypesArrayList = [];
  isConfirmationDialog = false;

  get isEditModal() {
    return !!this.deviceData?.data.type;
  }

  get lastCreatedDeviceType() {
    return this.lastCreatedDevice;
  }

  // steps validation
  get typeValidation() {
    return !!this.deviceType?.value?.length;
  }

  get currentDeviceType() {
    if (this.deviceType.value) {
      return this.devicesTypes[this.deviceType.value];
    }
    return { manageSchema: '' };
  }

  get deviceDataWithType() {
    const clone = JSON.parse(JSON.stringify(this.deviceData));
    clone.data.type = this.deviceType.value;
    if (this.deviceData?.data.type === 'Robot' && this.deviceData?.data.meta !== undefined) {
      clone.data.meta.deviceSchema = this.devicesTypes.Robot.devicesSchemas;
    }
    return clone;
  }

  /**
   * Define device preview image according to type
   * @return path to preview image or null if device don`t have it
   */
  get currentPreviewImage() {
    const currentImgPreview = this.deviceType.value;
    if (currentImgPreview) {
      try {
        return require(`../../../assets/images/previews/${this.currentSelectedTheme}/${currentImgPreview}.png`);
      } catch (e) {
        // maybe load fallback image?
        console.log(e);
        return null;
      }
    } else {
      return null;
    }
  }

  onFormUnchanged(value: boolean) {
    this.isConfirmationDialog = !value;
  }

  closeDialog() {
    (this.$refs.ModalWindowInstance as any).closeDialog();
  }

  handleControl(device: IDevice) {
    this.$emit('handleControl', device);
  }

  /**
   * Controls deviceFormView status, deviceType, currentSelectedTheme according to dialog status
   * @param status dialog status
   */
  handleModalWindowStatus(status: boolean) {
    if (status) {
      this.deviceFormView = !!this.deviceData?.data.type;
      if (this.deviceData?.created_at) {
        this.deviceType.value = this.deviceData.data.type;
      }
      const clone = JSON.parse(JSON.stringify(this.devicesTypesWithLocaleNamesList));
      this.devicesTypesArrayList = clone.filter((deviceObject: any) => {
        return deviceObject.value !== 'Robot' && deviceObject.value !== 'SurveyClient';
      });
    } else {
      this.deviceFormView = false;
      this.$store.commit('devices/setLastCreatedDevice', this.deviceType.value);
      this.devicesTypesArrayList = [];
    }
    this.currentSelectedTheme = this.$vuetify.theme.dark ? 'themeDark' : 'themeLight';
  }

  created() {
    this.deviceType.value = this.lastCreatedDeviceType;
    this.deviceType.title = this.deviceType.value ? i18n.t(`devices.${this.deviceType.value}.previewName`).toString() : '';
  }
}
