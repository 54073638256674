import { GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import { INavigationState } from '@/store/modules/navigation/types';
import { envMainMenuEntries, envProjectMenuEntries, envDocumentationTabs } from '@/utils/env';

const state: INavigationState = {
  drawer: true,
  isMini: false,
  appNavigation: [
    {
      name: 'Home',
      locale: 'navigation.appNavigation.home',
      path: '/',
      icon: 'home',
    },
    {
      name: 'Documentation',
      locale: 'navigation.appNavigation.documentation',
      path: '/documentation',
      icon: 'documentation',
    },
    {
      name: 'Ticket',
      locale: 'navigation.appNavigation.ticket',
      path: '/ticket',
      icon: 'ticket',
    },
    {
      name: 'Changelog',
      locale: 'navigation.appNavigation.changelog',
      path: '/changelog',
      icon: 'fa-list-ol',
      superAdminOnly: true,
    },
    {
      name: 'PartnerManagement',
      locale: 'navigation.appNavigation.PartnerManagement',
      path: '/partner-management',
      icon: 'fa-chalkboard-teacher',
      partnerOnly: true,
    },
    {
      name: 'PartnerWorkspace',
      locale: 'navigation.appNavigation.PartnerWorkspace',
      path: '/partner-workspace',
      icon: 'fa-handshake',
      partnerOnly: true,
    },
    {
      name: 'SerialNumberManagement',
      locale: 'navigation.appNavigation.serialNumberManagement',
      path: '/serial-number-management',
      icon: 'fa-key',
      superAdminOnly: true,
      lynusSupportOnly: true,
    },
  ],
  documentationNavigation: [
    {
      title: 'User Manual',
      locale: 'navigation.documentationNavigation.userManual',
      name: 'UserManual',
      description: '',
      icon: 'library_add',
      path: '/documentation/userManual',
    },
    {
      title: 'API Documentation',
      locale: 'navigation.documentationNavigation.apiDocumentation',
      name: 'APIDocs',
      description: '',
      icon: 'description',
      path: '/documentation/api',
    },
    {
      title: 'Device Connectivity',
      locale: 'navigation.documentationNavigation.deviceConnectivity',
      name: 'DeviceConnectivity',
      description: '',
      icon: 'description',
      path: '/documentation/deviceConnectivity',
    },
  ],
};

const getters: GetterTree<INavigationState, RootState> = {
  appNavigationFiltered(state) {
    return state.appNavigation.filter((e: any) => envMainMenuEntries.includes(e.name));
  },

  /**
   * Project navigation
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   */
  projectNavigation(state, getters, rootState, rootGetters) {
    const route = `/projects/${rootState.projects.projectId}`;
    return [
      {
        name: 'Favorites',
        locale: 'navigation.appNavigation.favorites',
        path: `${route}/favorites`,
        icon: 'favourites',
        memberAccess: true,
      },
      {
        name: 'Areas',
        locale: 'navigation.appNavigation.areas',
        path: `${route}/areas`,
        icon: 'areas',
        memberAccess: true,
      },
      {
        name: 'Devices Library',
        locale: 'navigation.appNavigation.devicesLibrary',
        path: `${route}/devices`,
        icon: 'devices-library',
        memberAccess: false,
      },
      {
        name: 'AI/ML Library',
        locale: 'navigation.appNavigation.aimlLibrary',
        path: `${route}/aiml`,
        icon: 'AI-ML',
        memberAccess: false,
      },
      {
        name: 'Variables',
        locale: 'navigation.appNavigation.variables',
        path: `${route}/variables`,
        icon: 'variables',
        memberAccess: true,
      },
      {
        name: 'Workbench',
        locale: 'navigation.appNavigation.workbench',
        path: `${route}/workbench`,
        icon: 'workbench',
        memberAccess: false,
      },
      {
        name: 'Settings',
        locale: 'navigation.appNavigation.settings',
        path: `${route}/settings`,
        icon: 'settings',
        memberAccess: true,
      },
      {
        name: 'Rules',
        locale: 'navigation.appNavigation.rules',
        path: `${route}/rules`,
        icon: 'fa-check',
        memberAccess: false,
      },
      {
        name: 'Report',
        locale: 'navigation.appNavigation.report',
        path: `${route}/Report`,
        icon: 'fa-file-alt',
        memberAccess: false,
      },
      {
        name: 'Event List',
        locale: 'navigation.appNavigation.eventList',
        path: `${route}/eventlist`,
        icon: 'eventlist',
        memberAccess: true,
        accepted: rootGetters['events/getIsAllAccepted'],
        animation: rootGetters['events/getIsAllAccepted'],
      },
      {
        name: 'InstallationWizard',
        locale: 'navigation.appNavigation.installationWizard',
        path: `${route}/installation-wizard`,
        icon: 'mdi-auto-fix',
        memberAccess: false,
      },
      {
        name: 'TenantWizard',
        locale: 'navigation.appNavigation.tenantWizard',
        path: `${route}/tenant-wizard`,
        icon: 'mdi-home-analytics',
        memberAccess: false,
        filterForMobileBatteryDeye: true, // filter for mobile battery deye batterytype
      },
    ].filter((e) => envProjectMenuEntries.includes(e.name));
  },
  documentationNavigationFiltered(state) {
    return state.documentationNavigation.filter((tab: any) => envDocumentationTabs.includes(tab.name));
  },
  partnerNavigation(state, getters, rootState, rootGetters) {
    const id = rootState.partnerWorkspaces.workspaceId;
    return [
      {
        name: 'PartnerProjectView',
        locale: 'navigation.partnerNavigation.partnerProjectView',
        path: `/partner-workspace/${id}`,
        icon: 'settings',
        superAdminOnly: false,
      },
    ];
  },
};

const mutations: MutationTree<INavigationState> = {
  handleDrawer(state: INavigationState, payload: boolean) {
    state.drawer = payload;
  },
  handleMiniVariant(state: INavigationState, payload: boolean) {
    state.isMini = payload;
  },
};

const actions = {};

export const navigation: Module<INavigationState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
