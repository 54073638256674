
import { Component, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import { kiloWattHourUnit } from '@/utils/unit/unitConstants';
import CircleSpinner from '@/ui/components/components/CircleSpinner.vue';
import {
  allIOEnergyCircleTypes,
  getCirclePositionForType,
  IOEnergyCircleType,
} from '@/types/energyVisualisation/EnergyCircleType';
import EnergyVisualisationIO
  from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/index.vue';
import {
  getDateBoundsByPeriod,
  periodConfigurations,
  Periods,
} from '@/ui/components/devices/charts/charts/ChartUtils';
import { changeDate, formatDateForPeriod, roundNumber } from '@/utils/utilsFunctions';
import {
  CirclePosition,
  IOEnergyCircleDisplayData,
} from '@/types/energyVisualisation/EnergyCircleDisplayData';
import {
  energyBlue,
  energyConsumerColors,
  energyGreyColors,
  energyHybridColors,
  energyOrange,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/constants';
import {
  ArrowDirection,
  EnergyLineDisplayData,
} from '@/types/energyVisualisation/EnergyLineDisplayData';
import CalendarNavigation from '@/ui/components/devices/charts/charts/CalendarNavigation.vue';
import IconValueFieldWithTooltip from '@/ui/components/components/IconValueFieldWithTooltip.vue';
import { IDevice } from '@/types/devices.types';
import { IProject } from '@/types/project.types';
import {
  IOEnergyDataByPeriodResponse
} from '@/types/energyVisualisation/energyVisualisation.types';
import EnergyIOComponent
  from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/utils/EnergyIOComponent';
import {
  getDefaultArrowDirectionIO,
  getDefaultLineColorsIO,
} from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/utils/EnergyIOVisualisationUtils';
import EnergyFlowViewSystemDialogIO from './EnergyFlowViewSystemDialogIO.vue';

@Component({
  components: {
    CalendarNavigation,
    CircleSpinner,
    EnergyVisualisationIO,
    EnergyFlowViewSystemDialogIO,
    IconValueFieldWithTooltip,
  },
  methods: { roundNumber },
})
export default class EnergyFlowViewIO extends mixins(EnergyIOComponent) {
  @Prop({ default: null }) deviceData!: IDevice;
  @Getter('projects/projectStartedAtNumber') startedAt!: number;
  @Getter('projects/project') currentProject!: IProject;
  @Action('devices/fetchDataByPeriod') fetchDataByPeriod!: ({
    id,
    start,
    end,
    period,
  }: {
    id: string;
    start: number;
    end: number;
    period: string;
  }) => Promise<IOEnergyDataByPeriodResponse>;

  loader = true;
  energyDataByPeriodResponse: IOEnergyDataByPeriodResponse | null = null;

  // ========================== systems dialogs ==========================
  showDialog = false;
  openedSystemId?: string;
  openedSystem?: IOEnergyCircleType;
  @Provide('onIOCircleClick') onCircleClick = this.onCircleClickCallback;

  @Provide('onLineAnimationEnd') onLineAnimationEnd = this.onLineAnimationEndCallback;
  hybridHighlightBottomValue = false;
  hybridArrowDirection = ArrowDirection.toCenter;
  hybridLineColors = energyHybridColors;

  onLineAnimationEndCallback() {
    this.hybridHighlightBottomValue = !this.hybridHighlightBottomValue;
    this.hybridArrowDirection =
      this.hybridArrowDirection === ArrowDirection.toCenter
        ? ArrowDirection.awayFromCenter
        : ArrowDirection.toCenter;
    this.hybridLineColors =
      this.hybridLineColors === energyHybridColors ? energyConsumerColors : energyHybridColors;
  }

  onCircleClickCallback(circleData: IOEnergyCircleDisplayData) {
    this.openedSystemId = circleData.id;
    this.openedSystem = circleData.systemType as IOEnergyCircleType;
    this.showDialog = true;
  }

  autoReloadInterval: any = null;

  // ========================== calendar ==========================
  currentPeriod: string = Periods.DAY;
  currentCalendarStartDate: number | null = null;
  disableNavButton = false;
  navigationItemsToExclude = ['live', 'hour']

  // ========================== bottom, top info ==========================
  displayDate: string | null = null;

  get shownSystems() {
    return this.filterPresentIoSystems(allIOEnergyCircleTypes);
  }

  sumAllEnergies(component: any, systemType: IOEnergyCircleType) {
    let sum = 0;
    const powers = Object.keys(component.energy_counter) as string[];
    powers.forEach((counter_variable: string) => {
      sum += (this.energyDataByPeriodResponse?.energies[systemType][component.id] as any)[counter_variable] ?? 0;
    });
    return sum;
  }

  get totalDifferenceForAllInputsAndOutputs() {
    return this.energyDataByPeriodResponse?.calculations.difference;
  }

  get circlesDisplayData() {
    const mappedSystems: IOEnergyCircleDisplayData[] = [];
    this.shownSystems.forEach((systemType: IOEnergyCircleType) => {
      const position = getCirclePositionForType(systemType);

      const components = this.getIoSystemComponents(systemType);
      // convert the components Record to an array of objects
      const mappedComponents = Object.keys(components).map((key: string) => {
        return {
          id: key,
          ...components[key],
        };
      });
      if (systemType === IOEnergyCircleType.outputs) {
        mappedSystems.push({
          id: 'calculated',
          title: `${this.$t(`mlModel.${this.deviceData.data.type}.difference`)}`,
          systemType,
          spinnerColor: energyOrange,
          position: CirclePosition.bottom,
          disableSpinner: false,
          unit: kiloWattHourUnit,
          bottomValue: this.totalDifferenceForAllInputsAndOutputs,
        });
      }
      mappedComponents.forEach((component: any) => {
        let value = systemType === IOEnergyCircleType.hybrids ? this.energyDataByPeriodResponse?.energies[systemType][`${component.id}_from_production`] ?? 0 : this.sumAllEnergies(component, systemType);
        if (this.hybridHighlightBottomValue && systemType === IOEnergyCircleType.hybrids) value = this.energyDataByPeriodResponse?.energies[systemType][`${component.id}_to_consumer`] ?? 0;
        const systemCount = systemType === IOEnergyCircleType.hybrids ? 1 : Object.values(this.getIoSystemComponents(systemType)[component.id].energy_counter).length;
        const spinnerColor = this.defaultColorByType(systemType, value, systemType === IOEnergyCircleType.hybrids ? this.hybridLineColors.colorAtCircle : null);
        mappedSystems.push({
          id: component.id,
          title: component.title,
          systemType,
          systemCount,
          spinnerColor,
          position,
          disableSpinner: false,
          unit: kiloWattHourUnit,
          bottomValue: value,
        });
      });
    });
    return mappedSystems;
  }

  get linesDisplayData() {
    return this.circlesDisplayData.map((circle: IOEnergyCircleDisplayData, index: number) => {
      if (circle.systemType === IOEnergyCircleType.hybrids) {
        return {
          id: circle.id,
          arrowDirection: circle.bottomValue === 0 ? ArrowDirection.none : this.hybridArrowDirection,
          oneArrowOnly: true,
          colors: circle.bottomValue === 0 ? energyGreyColors : this.hybridLineColors,
        } as Partial<EnergyLineDisplayData>;
      }

      const colors = circle.id === 'calculated' && circle.bottomValue !== 0 ? { colorAtCircle: energyOrange, colorAtCenter: energyBlue } : getDefaultLineColorsIO(circle.systemType as IOEnergyCircleType, circle);
      return {
        id: circle.id,
        arrowDirection: getDefaultArrowDirectionIO(circle.systemType as IOEnergyCircleType, circle),
        colors,
      } as Partial<EnergyLineDisplayData>;
    });
  }

  setDisplayDate({ start, end }: { start: number; end: number }) {
    this.currentCalendarStartDate = start;
    this.displayDate = formatDateForPeriod(
      this.currentPeriod,
      this.currentPeriod === 'week' ? ([start * 1000, end * 1000] as any) : ([start * 1000] as any),
    );
  }

  async fetchEnergyData({ start, end }: { start: number; end: number }) {
    this.disableNavButton = true;
    this.loader = true;
    try {
      this.energyDataByPeriodResponse = await this.fetchDataByPeriod({
        id: this.deviceData.id.replaceAll('_', '-'),
        start,
        end,
        period: this.currentPeriod,
      });
    } catch (e) {
      this.energyDataByPeriodResponse = null;
      console.error(e);
    } finally {
      this.disableNavButton = false;
      this.loader = false;
    }
    clearInterval(this.autoReloadInterval);
    this.autoReloadInterval = setInterval(() => {
      this.fetchEnergyData({ start, end });
    }, 3600000);
  }

  async handleNavigation(period: string) {
    this.currentPeriod = period;
    const { start, end } = periodConfigurations[this.currentPeriod].period();
    this.setDisplayDate({ start, end });
    await this.fetchEnergyData({ start, end });
  }

  async handleCalendar(data: any) {
    (this.$refs.calendarDialog as any).closeDialog();
    await this.loadCalendarRange(data);
  }

  startOfCreatedAtDate() {
    const createdAtDate = new Date(this.startedAt);
    switch (this.currentPeriod) {
      case 'day':
        return new Date(
          createdAtDate.getFullYear(),
          createdAtDate.getMonth(),
          createdAtDate.getDate(),
        );
      case 'week':
        return new Date(
          createdAtDate.getFullYear(),
          createdAtDate.getMonth(),
          createdAtDate.getDate() - createdAtDate.getDay(),
        );
      case 'month':
        return new Date(createdAtDate.getFullYear(), createdAtDate.getMonth(), 1);
      case 'year':
        return new Date(createdAtDate.getFullYear(), 0, 1);
      default:
        return new Date(
          createdAtDate.getFullYear(),
          createdAtDate.getMonth(),
          createdAtDate.getDate(),
        );
    }
  }

  async handlePrevNextButtonCalendar(direction: string) {
    const date = changeDate(this.currentCalendarStartDate as number, direction, this.currentPeriod);
    const start = this.startOfCreatedAtDate().getTime() / 1000;
    const end = new Date().getTime() / 1000;
    if (date < end && date >= start) {
      await this.loadCalendarRange(date);
    }
  }

  async loadCalendarRange(date: number) {
    const now = Math.trunc(new Date().getTime() / 1000);
    const chartBounds = getDateBoundsByPeriod(date, this.currentPeriod);
    const { start, end } = chartBounds as { start: number; end: number; endChart: number };
    const maxEnd = end > now ? now : end;

    this.setDisplayDate({ start, end: maxEnd });
    await this.fetchEnergyData({ start, end: maxEnd });
  }

  beforeDestroy() {
    clearInterval(this.autoReloadInterval);
  }
}
