
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent PlusBase component
 */
@Component
export default class PlusBase extends Vue {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop({ default: 'green' }) color!: string;
  @Prop({ default: false }) fab!: boolean;
  @Prop({ default: 'plus' }) icon!: string;

  handlePlus() {
    this.$emit('handlePlus');
  }
}
