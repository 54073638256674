var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DeviceLayout',{attrs:{"device-data":_vm.deviceData,"device-icon":"generator"},scopedSlots:_vm._u([{key:"basic-controls",fn:function(){return [_c('div',{staticClass:"d-flex generator"},[_c('LabelUnitWrapper',{attrs:{"disabled":!_vm.isActualValueVariableFilled},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('OutputFieldBase',{attrs:{"variable-data":_vm.deviceData.data.mappings,"instance":"OutputField","min":0,"max":1000,"is-decimal":true}}),_c('div',{staticClass:"pl-2"},[_vm._v("kW")])]},proxy:true}])})],1)]},proxy:true},{key:"settings-view",fn:function(){return [_c('div',{staticClass:"generator-settings"},[_c('LabelUnitWrapper',{attrs:{"variables-list-for-check":[
          _vm.deviceData.data.mappings.Switch_on,
          _vm.deviceData.data.mappings.Switch_off,
          _vm.deviceData.data.mappings.Switch_state,
        ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t(`${_vm.langPath}.settingsView.manual`)))]},proxy:true},{key:"value",fn:function(){return [_c('SwitchBase',{staticClass:"py-0 my-0",attrs:{"variable-data":_vm.deviceData.data.mappings,"instance":"Switch"}})]},proxy:true}])}),_c('LabelUnitWrapper',{staticClass:"pt-4",attrs:{"variables-list-for-check":[
          _vm.deviceData.data.mappings.PushButton_onOff,
          _vm.deviceData.data.mappings.PushButton_state,
        ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(_vm._s(_vm.$t(`${_vm.langPath}.settingsView.reset`)))]},proxy:true},{key:"value",fn:function(){return [_c('PushButtonBase',{staticClass:"mr-4",attrs:{"variable-data":_vm.deviceData.data.mappings,"instance":"PushButton","icon":"push_reset"}})]},proxy:true}])})],1)]},proxy:true},{key:"dnd",fn:function(){return [_vm._t("dnd")]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }