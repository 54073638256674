
import { Vue, Component } from 'vue-property-decorator';
import MPCPreviewsList from '@/ui/components/devices/previews/components/MPCPreviewsList.vue';
import AnomalyDetectionPreviewsList from '@/ui/components/devices/previews/components/AnomalyDetectionPreviewsList.vue';
import { envAimlLibraryCategories } from '@/utils/env';

/**
 * AIML page, here render Anomaly Detection, ML Model devices previews
 */
@Component({
  components: {
    AnomalyDetectionPreviewsList,
    MPCPreviewsList,
  },
})
export default class AIML extends Vue {
  tab = null
  previews = [
    {
      name: 'Anomaly Detection',
      locale: this.localisationPath,
      title: 'Anomaly Detection',
      view: 'AnomalyDetectionPreviewsList',
    },
    {
      name: 'MPC',
      locale: this.localisationPath,
      title: 'ML Model',
      view: 'MPCPreviewsList',
    },
  ]

  get previewsFiltered() {
    return this.previews.filter((e) => envAimlLibraryCategories.includes(e.name));
  }

  get localisationPath() {
    return 'uiComponents.aimlLibrary.mainTabs';
  }
}
