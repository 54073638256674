
import { Vue, Component, Prop } from 'vue-property-decorator';
import { calculatePageCount } from '@/utils/utilsFunctions';
import { Action, Getter } from 'vuex-class';
import { IInverter } from '@/types/serialNumber.types';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: { DeleteModalForm },
})
export default class SerialNumberManagement extends Vue {
  @Prop({ default: 10 }) itemsPerPage!: number;
  @Action('serialNumber/fetchInverters') fetchSerialNumbers!: () => Promise<void>;
  @Getter('serialNumber/inverters') inverters!: IInverter[];
  @Getter('serialNumber/inverterFilter') inverterFilter!: string;
  currentPage = 1;
  sortBy: keyof IInverter = 'id';
  sortDesc = false;

  serialNumberType: { [key: number]: string | TranslateResult } = {
    0: this.$t('modals.createSerialNumber.form.hybridInverter'),
    1: this.$t('modals.createSerialNumber.form.stringInverter'),
    2: this.$t('modals.createSerialNumber.form.chargeStation'),
  };

  get items() {
    return this.inverters;
  }

  customSort(items: IInverter[], sortBy: keyof IInverter, sortDesc: boolean) {
    return items.sort((a, b) => {
      let modifier = 1;
      if (sortDesc) modifier = -1;
      if (sortBy === 'created_at') {
        return modifier * (new Date(a[sortBy]).getTime() - new Date(b[sortBy]).getTime());
      } else {
        const aValue = a[sortBy] ?? -Infinity;
        const bValue = b[sortBy] ?? -Infinity;

        if (aValue < bValue) return -1 * modifier;
        if (aValue > bValue) return modifier;
        return 0;
      }
    });
  }

  get filteredItems() {
    let filteredBySearchItems: IInverter[] = [];
    if (!this.inverterFilter || this.inverterFilter.length === 0) {
      filteredBySearchItems = this.items;
    } else {
      filteredBySearchItems = this.items.filter((inverter: IInverter) => inverter.id.toLowerCase().includes(this.inverterFilter.toLowerCase()) ||
        inverter.project_id?.toLowerCase().includes(this.inverterFilter.toLowerCase()) ||
        inverter.description?.toLowerCase().includes(this.inverterFilter.toLowerCase()));
    }

    return filteredBySearchItems.map((inverter: IInverter) => {
      if (inverter.project_id === null) {
        inverter.project_id = '-';
      }
      return inverter;
    });
  }

  get sortedItems() {
    return this.customSort(this.filteredItems, this.sortBy, this.sortDesc);
  }

  get pageNumber() {
    return calculatePageCount(this.filteredItems.length, this.itemsPerPage);
  }

  get headers() {
    return [
      {
        text: this.$t('uiComponents.serialNumberTable.number'),
        align: 'start',
        value: 'id',
        width: '25%',
      },
      {
        text: this.$t('uiComponents.serialNumberTable.description'),
        align: 'start',
        value: 'description',
        width: '20%',
      },
      {
        text: this.$t('uiComponents.serialNumberTable.type'),
        align: 'start',
        value: 'type',
        width: '15%',
      },
      {
        text: this.$t('uiComponents.serialNumberTable.project'),
        align: 'start',
        value: 'project_id',
        width: '15%',
      },
      {
        text: this.$t('uiComponents.serialNumberTable.createdAt'),
        align: 'center',
        value: 'created_at',
        width: '15%',
      },
      {
        text: this.$t('uiComponents.serialNumberTable.actions'),
        align: 'center',
        value: 'actions',
        sortable: false,
        width: '10%',
      },
    ];
  }

  deleteSerialNumber(id: string) {
    this.$store.dispatch('serialNumber/deleteSerialNumber', id);
  }

  async created() {
    await this.fetchSerialNumbers();
  }
}
