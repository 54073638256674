import {
  envDocumentationTabs,
  envMainMenuEntries,
  envPartnerProjectMenuEntries,
  envProjectMenuEntries,
  envProjectSettingsEnteries,
} from '@/utils/env';
import store from '@/store';
import { checkProtocolAccessList } from '@/utils/userRoles';

/**
 * When adding a new Route to "MAIN_MENU_ROUTES", "DOCUMENTATION_ROUTES", "PROJECT_MENU_ROUTES", "PROJECT_SETTINGS_ROUTES".
 * Check that the name is the same inside the "router/index.ts" and the "default-config.ts".
 * Otherwise, the route will not open correctly.
 */
const MAIN_MENU_ROUTES = [
  'Home',
  'Billing',
  'Documentation',
  'Changelog',
  'Ticket',
  'PartnerManagement',
  'PartnerWorkspace',
  'SerialNumberManagement',
];
const DOCUMENTATION_ROUTES = [
  'UserManual',
  'APIDocs',
  'DeviceConnectivity',
];
const PROJECT_MENU_ROUTES = [
  'Favorites',
  'Areas',
  'Devices Library',
  'Variables',
  'AI/ML Library',
  'Workbench',
  'Settings',
  'Rules',
  'Event List',
  'Report',
  'InstallationWizard',
  'TenantWizard',
];
const PROJECT_SETTINGS_ROUTES = [
  'General',
  'EnergyPrice',
  'Notification',
  'MQTT',
  'Permissions',
  'SettingsVariables',
  'CheckProtocol',
];
const PARTNER_PROJECT_MENU_ROUTES = [
  'PartnerProjectView',
];

export const canAccessRoute = (routeName: string) => {
  const user = store.getters['app/getUser'];
  if (MAIN_MENU_ROUTES.includes(routeName)) return true;
  if (DOCUMENTATION_ROUTES.includes(routeName)) return true;
  if (PROJECT_MENU_ROUTES.includes(routeName)) return store.getters['members/canMemberAccess']('ProjectMenuEntries', routeName);
  if (PARTNER_PROJECT_MENU_ROUTES.includes(routeName)) return true;
  if (PROJECT_SETTINGS_ROUTES.includes(routeName)) {
    if (!checkProtocolAccessList.includes(user.email) && routeName === 'CheckProtocol') {
      return false;
    }
    const isSettingsEnabled = store.getters['members/canMemberAccess']('ProjectMenuEntries', 'Settings');
    if (!isSettingsEnabled) return false;
    else return store.getters['members/canMemberAccess']('ProjectSettingsEntries', routeName);
  } else return null;
};

export const isRouteEnabled = (routeName: string) => {
  const user = store.getters['app/getUser'];
  if (MAIN_MENU_ROUTES.includes(routeName)) return envMainMenuEntries.includes(routeName);
  if (DOCUMENTATION_ROUTES.includes(routeName)) return envDocumentationTabs.includes(routeName);
  if (PROJECT_MENU_ROUTES.includes(routeName)) return envProjectMenuEntries.includes(routeName);
  if (PARTNER_PROJECT_MENU_ROUTES.includes(routeName)) return envPartnerProjectMenuEntries.includes(routeName);
  if (PROJECT_SETTINGS_ROUTES.includes(routeName)) {
    const isSettingsEnabled = envProjectMenuEntries.includes('Settings');
    if (!isSettingsEnabled) return false;
    else {
      // block route mqtt for not super admins
      if (!user.super_admin && routeName === 'MQTT') return false;
      else return envProjectSettingsEnteries.includes(routeName);
    }
  } else return null;
};
