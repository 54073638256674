/**
 * This file can/should be used to disable certain features in the installation wizard
 * I added the IS_PRODUCTION check to none can be accidentally enabled in production
 * just use FULL_DEV to disable every check
 */
const FULL_DEV = false;
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const CHECKS_DISABLED = !IS_PRODUCTION && (FULL_DEV || false);
export const SEND_MQTT_DISABLED = !IS_PRODUCTION && (FULL_DEV || false);
export const IGNORE_FEEDBACK = !IS_PRODUCTION && (FULL_DEV || false);
export const IGNORE_INVERTER_CHECK = !IS_PRODUCTION && (FULL_DEV || false);
