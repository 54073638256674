// Contains all battery types that can be selected inside the Project Settings
// (Combination from Types in Installation Wizard and Logger Wizard)
export enum AllBatteryTypes {
  AZZURRO = 'AIOS (Azzurro)',
  DEYE = 'AIOS (Deye)',
  STANDALONE = 'Standalone (Deye)',
  SolarmanLogger = 'Solarman Logger (Deye)',
  Solarmax = 'Solarmax',
  MobileBatteryDeye = 'Mobiler Speicher Deye'
}
