import { RootState } from '@/store/types';
import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import api from '@/services/api';
import i18n from '@/ui/plugins/i18n';
import { IRouterState } from '@/store/modules/router/types';
import { IScrollPosition } from '@/types/router.types';

const state: IRouterState = {
  scrollPosition: { x: 0, y: 0 },
  homeDataLoaded: false,
};

const getters: GetterTree<IRouterState, RootState> = {
  scrollPosition: (state: IRouterState) => state.scrollPosition,
  homeDataLoaded: (state: IRouterState) => state.homeDataLoaded,
};

const mutations: MutationTree<IRouterState> = {
  setScrollPosition(state: IRouterState, scrollPosition: IScrollPosition) {
    state.scrollPosition = scrollPosition;
  },
  setHomeDataLoaded(state: IRouterState, homeDataLoaded: boolean) {
    state.homeDataLoaded = homeDataLoaded;
  },
};

const actions: ActionTree<IRouterState, RootState> = {};

export const router: Module<IRouterState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
