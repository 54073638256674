import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import api from '@/services/api';
import { RootState } from '@/store/types';
import { IMPCState } from '@/store/modules/mpc/types';
import { Map } from 'immutable';
import mlModelTypes from '@/store/modules/mpc/mlModelTypes';
import i18n from '@/ui/plugins/i18n';
import { envLoadMPC, envMPCDeviceList } from '@/utils/env';
import { get, merge, set } from 'lodash';
import ControllerService from '@/services/ControllerService';
import MPCWeatherService from '@/services/MPCWeatherService';
import MPCEnergyService from '@/services/MPCEnergyService';

const state: IMPCState = {
  mlModelTypes,
  mpcControllers: Map(),
  isWeatherServiceActive: false,
  lastCreatedModel: '',
};

// TODO: remove this EnergyView check if old EnergyView is not needed anymore
function replacePossibleEnergyViewType(mpcController: any): void {
  const type = get(mpcController, 'data.type');
  // temporarily replace MPC EnergyView type with MpcEnergyView
  // for backwards compatibility with old EnergyView
  if (type === 'EnergyView') set(mpcController, 'data.type', 'MpcEnergyView');
}

const getters: GetterTree<IMPCState, RootState> = {
  mpcControllers(state: IMPCState) {
    return state.mpcControllers.valueSeq().toJS();
  },
  getMPCControllerById: (state: IMPCState) => (id: string) => state.mpcControllers.get(id),
  mpcControllersFavorites(state: IMPCState) {
    if (state.mpcControllers.size >= 1) {
      const mpcList = state.mpcControllers.valueSeq().toJS();
      return mpcList.filter((mpc: any) => mpc.favorite);
    }
    return [];
  },

  /**
   * Only ML Models, filtered from other MPC devices
   * @param state
   */
  mlModelDevices(state: IMPCState) {
    return state.mpcControllers.valueSeq().toJS()
      .filter((device: any) => Object.keys(state.mlModelTypes).some((el: string) => el === device.data.type));
  },
  mlModelTypes(state: IMPCState) {
    return state.mlModelTypes;
  },
  mlModelTypeSchemaByKey: (state: IMPCState) => (type: string) => state.mlModelTypes[type],

  /**
   * Goes through mlModelTypes and create options
   * (Example: { value: key, title: i18n translation }) for every item
   * @param state
   */
  mlModelsTypesWithLocaleNamesList(state: IMPCState) {
    let localModelTypes = Object.keys(state.mlModelTypes)
      .map((itemType: string) => ({ value: itemType, title: i18n.t(`mlModel.${itemType}.previewName`) }));
    localModelTypes = localModelTypes.filter((element: any) => envMPCDeviceList.includes(element.value));
    return localModelTypes;
  },

  /**
   * Returns MPC devices list with the visibility status of the settings window
   * @param state
   */
  mlModelIsSettings(state: IMPCState) {
    return Object.entries(state.mlModelTypes).map((mlModel: any) => [mlModel[0], mlModel[1].isSettingsView]);
  },
  lastCreatedModel(state: IMPCState) {
    return state.lastCreatedModel;
  },
};

const mutations: MutationTree<any> = {
  setMPCControllers(state, payload) {
    state.mpcControllers = payload;
  },
  updateMPCInstance(state, payload) {
    const oldMPCObject = state.mpcControllers.get(payload.id);
    const newMPCObject = merge(oldMPCObject, payload);
    state.mpcControllers = state.mpcControllers.set(newMPCObject.id, newMPCObject);
  },
  setWeatherServiceStatus(state, status) {
    state.isWeatherServiceActive = status;
  },
  setLastCreatedModel(state: IMPCState, device: any) {
    state.lastCreatedModel = device;
  },
};

const actions: ActionTree<any, RootState> = {
  /**
   * Creates new MPC device
   * @param commit
   * @param state
   * @param mpc
   */
  async createMCCInstance({ commit, state }, mpc) {
    try {
      // TODO: temporary solution for EmsEnergyView device
      const copy = JSON.parse(JSON.stringify(mpc));
      if (copy.data.type === 'MpcEnergyView') {
        copy.data.type = 'EnergyView';
        copy.data.meta.controllerMappings.errorWarning = '';
        copy.data.meta.controllerMappings.mpcReady = '';
        copy.data.meta.controllerMappings.heartbeat = '';
        copy.data.meta.controllerMappings.startDate = null;
      }

      const res = await ControllerService.createController(copy);
      if (res.data.type === 'EnergyView') res.data.type = 'MpcEnergyView';

      commit('setMPCControllers', state.mpcControllers.set(res.id, res));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.createMLModel', { name: res.name }),
        value: true,
      }, { root: true });
      return res;
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
      return null;
    }
  },

  /**
   * Load MPC devices, creates Map collection from them and set to MPC state
   * @param commit
   * @param state
   * @param rootState
   */
  async fetchMPCListByProject({ commit, state, rootState }) {
    if (!envLoadMPC) return;

    try {
      // TODO: refactor this
      // eslint-disable-next-line no-async-promise-executor
      const mpcPromise = new Promise(async (resolve) => {
        const res = await ControllerService.fetchControllersByProject(rootState.projects.projectId as string);
        resolve(res);
      });
      let timeout;
      const timeoutPromise = new Promise((resolve) => {
        timeout = setTimeout(resolve, 15000, 'MPC_Load_Timeout_Error');
      });
      const res: any = await Promise.race([mpcPromise, timeoutPromise]).then((value) => {
        if (value === 'MPC_Load_Timeout_Error') {
          commit('app/setReport', { type: 'error', message: i18n.t('uiComponents.reportMessages.loadMPCTimeoutError'), value: true }, { root: true });
          return [];
        } else {
          return value;
        }
      });
      if (timeout) {
        clearTimeout(timeout);
      }
      if (res.length) {
        const mpcControls = (res as any[]).reduce((acc, cur) => {
          // TODO: remove this EnergyView check if old EnergyView is not needed anymore
          replacePossibleEnergyViewType(cur);

          return acc.set(cur.id, cur);
        }, Map<string, any>());

        commit('setMPCControllers', mpcControls);
      } else {
        commit('setMPCControllers', Map());
      }
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: i18n.t('uiComponents.reportMessages.errorLoadingMPCData'),
        value: true,
      }, { root: true });
    }
  },

  /**
   * Load MPC full data, used in created component hook of MPC devices
   * @param commit
   * @param state
   * @param rootState
   * @param id
   */
  async fetchMPCData({ commit, state, rootState }, id) {
    try {
      const mpcController = await ControllerService.fetchController(id);

      // TODO: remove this EnergyView check if old EnergyView is not needed anymore
      replacePossibleEnergyViewType(mpcController);

      return mpcController;
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: i18n.t('uiComponents.reportMessages.errorLoadingMPCData'),
        value: true,
      }, { root: true });
    }
  },

  /**
   * get Energy Flow Data for Day
   * @param commit
   * @param state
   * @param rootState
   * @param params
   */
  async fetchEnergyDay({ commit, state, rootState }, params) {
    try {
      const res = await MPCEnergyService.fetchDay(params.id, {
        year: params.start.year,
        month: params.start.month,
        date: params.start.day,
      });
      return res;
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
      return null;
    }
  },
  /**
   * get Energy Flow Data between two dates
   * @param commit
   * @param state
   * @param rootState
   * @param params
   */
  async fetchEnergyWeek({ commit, state, rootState }, params) {
    try {
      const res = await MPCEnergyService.fetchWeek(
        params.id,
        { year: params.start.year, month: params.start.month, date: params.start.day },
        { year: params.end.year, month: params.end.month, date: params.end.day },
      );
      return res;
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
      return null;
    }
  },
  /**
   * get Energy Flow Data for Month
   * @param commit
   * @param state
   * @param rootState
   * @param params
   */
  async fetchEnergyMonth({ commit, state, rootState }, params) {
    try {
      const res = await MPCEnergyService.fetchMonth(params.id, {
        year: params.start.year,
        month: params.start.month,
      });
      return res;
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
      return null;
    }
  },
  /**
   * get Energy Flow Data for Year
   * @param commit
   * @param state
   * @param rootState
   * @param params
   */
  async fetchEnergyYear({ commit, state, rootState }, params) {
    try {
      const res = await MPCEnergyService.fetchYear(params.id, { year: params.start.year });
      return res;
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
      return null;
    }
  },

  /**
   * Change date by direction and period
   * @param dispatch
   * @param id Device id
   * @param start Start date for mpc data
   * @param end End date for mpc data
   * @param period Current period
   */
  async fetchDataByPeriodMpc(
    { dispatch },
    { id, start, end, period }: { id: string; start: number; end: number; period: string },
  ) {
    const startDate = new Date(start * 1000);
    const endDate = new Date(end * 1000);
    const params = {
      id,
      start: {
        day: startDate.getDate(),
        month: startDate.getMonth() + 1,
        year: startDate.getFullYear(),
      },
      end: {
        day: endDate.getDate(),
        month: endDate.getMonth() + 1,
        year: endDate.getFullYear(),
      },
    };
    const energyResponse = await dispatch(`mpc/fetchEnergy${period.charAt(0).toUpperCase() + period.slice(1)}`, params, { root: true });
    return energyResponse;
  },

  /**
   * Add MPC device to Favorites
   * @param commit
   * @param state
   * @param rootState
   * @param id
   */
  async addMPCToFavorites({ commit, state, rootState }, id) {
    await ControllerService.addToFavorites(id);

    const device: any = state.mpcControllers.get(id);
    if (device) {
      device.favorite = true;
      commit('setMPCControllers', state.mpcControllers.set(device.id, device));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.addToFavorites', { name: device.name }),
        value: true,
      }, { root: true });
    }
  },

  /**
   * Removes MPC device from Favorites
   * @param commit
   * @param state
   * @param rootState
   * @param id
   */
  async removeMPCFromFavorites({ commit, state, rootState }, id) {
    await ControllerService.deleteFromFavorites(id);

    const device: any = state.mpcControllers.get(id);
    if (device) {
      device.favorite = false;
      commit('setMPCControllers', state.mpcControllers.set(device.id, device));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.removeFromFavorites', { name: device.name }),
        value: true,
      }, { root: true });
    }
  },

  /**
   * Removes selected MPC device, rules attached to it
   * @param commit
   * @param dispatch
   * @param state
   * @param rootState
   * @param mpc
   */
  async deleteMPC({ commit, dispatch, state, rootState }, mpc) {
    // delete rules
    const warningRuleId = mpc.data.meta.warningRule;
    const errorRuleId = mpc.data.meta.errorRule;
    const systemsRules = mpc.data.meta.rules ? Object.values(mpc.data.meta.rules) : [];
    const rulesVariablesList: any = systemsRules.length
      ? systemsRules
        .map((system: any) => [system.errorRule, system.warningRule])
        .reduce((acc: any, item: any) => acc.concat(item), [])
      : [];
    const rulesList: any = [warningRuleId, errorRuleId, ...rulesVariablesList]
      .filter((el: any) => !!el)
      .map((variable: string) => dispatch('rules/deleteRule', { project_id: mpc.project_id, rule_id: variable }, { root: true }));
    await Promise.all(rulesList);
    // delete ems
    await ControllerService.deleteController(mpc.id);
    commit('setMPCControllers', state.mpcControllers.remove(mpc.id));
    commit('app/setReport', {
      type: 'success',
      message: i18n.t('uiComponents.reportMessages.deleteMLModel'),
      value: true,
    }, { root: true });
  },

  /**
   * Updates selected MPC device
   * @param commit
   * @param state
   * @param mpc
   */
  async updateMPC({ commit, state }, mpc) {
    try {
      // TODO: temporary solution for EmsEnergyView device
      const copy = JSON.parse(JSON.stringify(mpc));
      if (copy.data.type === 'MpcEnergyView') {
        copy.data.type = 'EnergyView';
        copy.data.meta.controllerMappings.errorWarning = '';
        copy.data.meta.controllerMappings.mpcReady = '';
        copy.data.meta.controllerMappings.heartbeat = '';
        copy.data.meta.controllerMappings.startDate = null;
      }

      const res = await ControllerService.updateController(mpc.id, copy);
      if (res.data.type === 'EnergyView') res.data.type = 'MpcEnergyView';

      commit('setMPCControllers', state.mpcControllers.set(res.id, res));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.editMLModel', { name: res.name }),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Updates MPC timing
   * @param commit
   * @param mpc
   */
  async updateMPCTiming({ commit }, mpc) {
    return ControllerService.updateControllerTiming(mpc.id, mpc)
      .then((res: any) => {
        commit('setMPCControllers', state.mpcControllers.set(res.id, res));
        commit('app/setReport', {
          type: 'success',
          message: i18n.t('uiComponents.reportMessages.updateMLModelTimings'),
          value: true,
        }, { root: true });
      });
  },

  /**
   * Load MPC weather status, affects on visibility MPC content
   * @param commit
   * @param state
   * @param rootState
   */
  async fetchMPCWeatherStatus({ commit, state, rootState }) {
    try {
      const res = await MPCWeatherService.fetchStatus(rootState.projects.projectId as string);
      commit('setWeatherServiceStatus', res.site_active);
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: i18n.t('uiComponents.reportMessages.errorFetchWeatherService'),
        value: true,
      }, { root: true });
    }
  },

  /**
   * Activate MPC weather service
   * @param commit
   * @param state
   * @param rootState
   * @param payload
   */
  async activateWeatherService({ commit, state, rootState }, payload) {
    try {
      await MPCWeatherService.activate(rootState.projects.projectId as string, payload);
      commit('setWeatherServiceStatus', true);
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.weatherServiceEnabled'),
        value: true,
      }, { root: true });
      return true;
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: i18n.t('uiComponents.reportMessages.errorFetchWeatherService'),
        value: true,
      }, { root: true });
      return false;
    }
  },

  /**
   * Deactivate MPC weather service
   * @param commit
   * @param state
   * @param rootState
   */
  async deactivateWeatherService({ commit, state, rootState }) {
    try {
      await MPCWeatherService.deactivate(rootState.projects.projectId as string);
      commit('setWeatherServiceStatus', false);
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.weatherServiceDisabled'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: i18n.t('uiComponents.reportMessages.errorFetchWeatherService'),
        value: true,
      }, { root: true });
    }
  },

  /**
   * Load Autarkiegrad according to selected period
   * @param commit
   * @param project_id
   * @param start start date
   * @param end end date
   * @param params combined from systems instances string
   */
  async fetchAutarkiegrad({ commit }, { project_id, start, end, params }) {
    try {
      return await api.fetchMPC(`/autarchy/${project_id}/${start}/${end}?${params}`, 'GET');
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: i18n.t('uiComponents.reportMessages.errorFetchAutarkiegrad'),
        value: true,
      }, { root: true });
    }
  },

  /**
   * Updates tariff settings
   * @param commit
   * @param state
   * @param mpc_id
   * @param settings
   */
  async updateTariffSettings({ commit, state }, { mpc_id, settings }) {
    try {
      const res = await ControllerService.updateControllerSettings(mpc_id, { settings });
      commit('setMPCControllers', state.mpcControllers.set(res.id, res));
      commit('app/setReport', {
        type: 'success',
        message: i18n.t('uiComponents.reportMessages.tariffUpdated'),
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Updates temperature settings of Setpoint Optimizer
   * @param commit
   * @param mpc_id
   * @param settings
   */
  async updateSetpointSystemInstanceTemperatureSettings({ commit }, { mpc_id, settings }) {
    try {
      const res = await ControllerService.updateControllerSettings(mpc_id, { settings });
      commit('updateMPCInstance', res);
      commit('app/setReport', {
        type: 'success',
        message: 'Temperature settings updated',
        value: true,
      }, { root: true });
    } catch (e) {
      commit('app/setReport', {
        type: 'error',
        message: e.message,
        value: true,
      }, { root: true });
    }
  },

  /**
   * Returns list of all basic mpc's by collection ID
   * @param collection_id
   */
  getMpcList({ getters }, collection_id: string) {
    return getters.mpcControllers.filter((device: any) => device.collection_id === collection_id);
  },
};

export const mpc: Module<IMPCState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
