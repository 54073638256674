
import { Component, Vue } from 'vue-property-decorator';
import NormalReport from '@/ui/components/modals/ManageReport/NormalReport.vue';
import ZevReport from '@/ui/components/modals/ManageReport/ZevReport.vue';
import { Action, Getter } from 'vuex-class';
import { IReport } from '@/types/report.types';
import { UserRoleCode } from '@/utils/userRoles';
import { IMember } from '@/types/members.types';

@Component({
  computed: {
    UserRoleCode: () => UserRoleCode,
  },
  components: {
    NormalReport,
    ZevReport,
  },
})
export default class NewReport extends Vue {
  @Action('report/addReport') addReport!: ({ id, reportModel }: { id: string; reportModel: IReport }) => Promise<void>;
  @Getter('members/currentMember') currentMember!: IMember;

  dialog = false;
  reportType = '';
  isFormValid = false;

  get reportTypesList() {
    const translations: any = this.$t('modals.manageReport.newReportListItems');
    return [
      { state: translations[0], abbr: 'zev' },
      { state: translations[1], abbr: 'heatingReport' },
      { state: translations[2], abbr: 'coldWaterReport' },
      { state: translations[3], abbr: 'hotWaterReport' },
      { state: translations[4], abbr: 'electricityReport' },
      { state: translations[5], abbr: 'otherReport' },
    ];
  }

  get reportsList(): { [key: string]: any } {
    return {
      zev: {
        component: 'ZevReport',
      },
      heatingReport: {
        component: 'NormalReport',
      },
      coldWaterReport: {
        component: 'NormalReport',
      },
      hotWaterReport: {
        component: 'NormalReport',
      },
      electricityReport: {
        component: 'NormalReport',
      },
      otherReport: {
        component: 'NormalReport',
      },
      '': {
        component: '',
      },
    };
  }

  get currentReport() {
    return this.reportsList[this.reportType];
  }

  async createReport() {
    const { reportDataToSend } = this.$refs.CurrentReport as any;
    if (reportDataToSend?.meta === undefined) {
      reportDataToSend.meta = {};
    }
    reportDataToSend.meta.showInList = this.reportType;
    await this.addReport({
      id: this.$route.params.id,
      reportModel: (this.$refs.CurrentReport as any).reportDataToSend,
    });
    this.$emit('handleClose');
  }
}
