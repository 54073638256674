
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class MultimediaButtonMock extends Vue {
  @Prop({ default: '#7c7c7c' }) color!: string;
  @Prop({ default: 'play' }) icon!: string;
  @Prop({ default: null }) iconSize!: string | number;
  @Prop({
    default: 'small',
    validator: (value: any): boolean => [null, 'x-small', 'small', 'large', 'x-large'].some((el: string | null) => el === value),
  }) buttonSizeClass!: string; // vuetify btn classes: x-small, small, large, x-large
}
