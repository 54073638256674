
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

/**
 * Component that represent ShowEventDot Basic Control
 */
@Component
export default class ShowEventDotBase extends BasicControl {
  @Prop() variableData!: any;
  @Prop() instance!: string;
  @Prop({ default: 20 }) width!: number | string;
  @Prop({ default: 20 }) height!: number | string;
  @Prop({ default() {
    return [[251, (this as any).$vuetify.theme.currentTheme.green]];
  } }) items!: any; // example: [[1, red], [2, blue]]
  @Prop({ default: '#bebebe' }) inactiveColor!: string;
  @Prop({ default: null }) activeMessage!: string;
  @Prop({ default: null }) inactiveMessage!: string;
  @Prop({ default: 'errorWarningState' }) mappingExtension!: string;

  get errorStatus() {
    return this.measurements.get(this.mappingsClean[this.mappingExtension]);
  }
  get warningColor() {
    const map: any = new Map(this.items);
    return map.get(this.errorStatus) || this.inactiveColor;
  }
  get isNotMapped(): boolean {
    return this.mappingsClean[this.mappingExtension] === '';
  }
}
