
import { Component, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { has } from 'lodash';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import { IOEnergyCircleType } from '@/types/energyVisualisation/EnergyCircleType';
import {
  CirclePosition,
  EnergyCircleDisplayData,
  IOEnergyCircleDisplayData,
} from '@/types/energyVisualisation/EnergyCircleDisplayData';
import SystemDialog
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/SystemDialog.vue';
import SystemDialogView
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/SystemDialogView.vue';
import {
  ArrowDirection,
  EnergyLineDisplayData,
} from '@/types/energyVisualisation/EnergyLineDisplayData';
import { IDevice } from '@/types/devices.types';
import {
  energyConsumerColors,
  energyGreyColors,
  energyHybridColors,
  energyProducerColors,
} from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/constants';
import IOSystemDialogView
  from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/IOSystemDialogView.vue';
import ActualViewIOSystemSettingsDialog
  from '@/ui/components/devices/components/EnergyParts/IOEnergyVisualisation/ActualViewIO/ActualViewIOSystemSettingsDialog.vue';
import { kiloWattUnit } from '@/utils/unit/unitConstants';

/**
 * Actual view for EnergyView/EMS
 */
@Component({
  components: {
    ActualViewIOSystemSettingsDialog,
    IOSystemDialogView,
    SystemDialog,
    SystemDialogView,
  },
})
export default class ActualViewIOSystemDialog extends mixins(EnergyComponent) {
  // whether the dialog is currently shown (v-model)
  @Prop() value!: boolean;
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop() id!: string;
  @Prop() openedSystem!: IOEnergyCircleType;
  @Prop({ default: () => [] }) devicesWithDynamicBottomValue!: string;
  @Prop({ default: false }) isHybridSystem!: boolean; // if the system is a hybrid system (normal components and pv components)
  @Prop() actualViewCiclesDisplayData!: IOEnergyCircleDisplayData[];

  @Provide('onDialogLineAnimationEnd') onDialogLineAnimationEnd =
    this.onDialogLineAnimationEndCallback;
  onDialogLineAnimationEndCallback(id: string) {
    if (has(this.electricHeatingInstancesAnimationStatus, id)) {
      this.electricHeatingInstancesAnimationStatus[id] = !this.electricHeatingInstancesAnimationStatus[id];
    } else {
      this.$set(this.electricHeatingInstancesAnimationStatus, id, false);
    }
  }
  electricHeatingInstancesAnimationStatus: Record<string, boolean> = {};

  get systemTitle() {
    return this.deviceData.data.meta.controllerMappings[this.openedSystem as any].components[this.id].title;
  }

  getIoSystemComponents(): Record<string, any> {
    return this.deviceData.data.meta.controllerMappings[this.openedSystem]?.components[this.id] ?? {};
  }

  get systemDialogCircles(): IOEnergyCircleDisplayData[] {
    if (!this.openedSystem) return [];

    const systems = this.getIoSystemComponents();
    const mappedSystems: IOEnergyCircleDisplayData[] = [];
    if (this.openedSystem === IOEnergyCircleType.hybrids) {
      const currentSystem = this.actualViewCiclesDisplayData.find(
        (circle) => circle.id === this.id,
      );
      mappedSystems.push({
        id: 'hybrid-system',
        title: this.$t(`mlModel.${this.deviceData.data.type}.groups.${this.openedSystem}.system`),
        systemType: this.openedSystem,
        position: CirclePosition.top,
        spinnerColor: currentSystem?.spinnerColor,
        disableSpinner: currentSystem?.disableSpinner,
        unit: kiloWattUnit,
        bottomValue: currentSystem?.bottomValue,
      } as IOEnergyCircleDisplayData);
      return mappedSystems;
    }
    Object.entries(systems.power).forEach(([key, variable], index) => {
      const currentSystem = this.actualViewCiclesDisplayData.find(
        (circle) => circle.id === this.id,
      );
      mappedSystems.push({
        id: key,
        title: `${this.$t(`mlModel.${this.deviceData.data.type}.groups.${this.openedSystem}.system`)} ${index + 1}`,
        systemType: this.openedSystem,
        position: CirclePosition.top,
        spinnerColor: currentSystem?.spinnerColor,
        disableSpinner: currentSystem?.disableSpinner,
        unit: kiloWattUnit,
        bottomDynamicValue: currentSystem?.bottomDynamicValue,
        bottomValue: this.measurements[variable as string] ?? 0,
      } as IOEnergyCircleDisplayData);
    });
    return mappedSystems;
  }

  get systemDialogLines(): Partial<EnergyLineDisplayData>[] {
    if (!this.openedSystem) return [];

    return this.systemDialogCircles.map(
      (displayData: IOEnergyCircleDisplayData) => {
        let arrowDirection;
        let colors;

        if (displayData.bottomValue === undefined || displayData.bottomValue === 0) {
          return {
            id: displayData.id,
            arrowDirection: ArrowDirection.none,
            colors: energyGreyColors,
          } as Partial<EnergyLineDisplayData>;
        }
        // if the temperature is visible and the power is negative, the arrow should not be visible else it should be visible (currently only for electric_heating systems)
        if (this.openedSystem === IOEnergyCircleType.hybrids) {
          arrowDirection = displayData.bottomValue < 0
            ? ArrowDirection.awayFromCenter
            : ArrowDirection.toCenter;
          colors = displayData.bottomValue > 0 ? energyHybridColors : energyConsumerColors;
        } else {
          arrowDirection = this.openedSystem === IOEnergyCircleType.inputs ? ArrowDirection.toCenter : ArrowDirection.awayFromCenter;
          colors = this.openedSystem === IOEnergyCircleType.inputs ? energyProducerColors : energyConsumerColors;
        }

        return {
          id: displayData.id,
          arrowDirection,
          colors,
        } as Partial<EnergyLineDisplayData>;
      },
    );
  }

  beforeDestroy() {
    this.electricHeatingInstancesAnimationStatus = {};
  }
}
