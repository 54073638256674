import { IRulesState } from '@/store/modules/rules/types';
import { RootState } from '@/store/types';
import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { IRule } from '@/types/rules.types';
import RuleService from '@/services/RuleService';

const state: IRulesState = {
  rulesList: [],
  rulesFilter: '',
};

const getters: GetterTree<IRulesState, RootState> = {
  rulesList: (state: IRulesState) => state.rulesList,
  findRuleById: (state: IRulesState) => (id: string) => state.rulesList.find((rule: IRule) => rule.id === id),
  rulesLength: (state: IRulesState) => state.rulesList.length,
};

const mutations: MutationTree<IRulesState> = {
  setRules(state: IRulesState, rules: IRule[]) {
    state.rulesList = rules;
  },
  setRule(state: IRulesState, rule: IRule) {
    state.rulesList.push(rule);
  },
  updateRule(state: IRulesState, rule: IRule) {
    state.rulesList = state.rulesList.map((r: IRule) => {
      if (r.id === rule.id) return rule;
      else return r;
    });
  },
  deleteRuleFromList(state: IRulesState, ruleId: string) {
    state.rulesList = state.rulesList.filter((el: IRule) => el.id !== ruleId);
  },
  setRulesFilter(state: IRulesState, name: string) {
    state.rulesFilter = name;
  },
};

const actions: ActionTree<IRulesState, RootState> = {
  /**
   * Load project rules
   * @param commit
   * @param project_id project id
   */
  async loadRules({ commit }, project_id) {
    const result = await RuleService.fetchRules(project_id);
    commit('setRules', result);
    return result;
  },

  /**
   * Create new rule
   * @param commit
   * @param payLoad rule data
   */
  async addRule({ commit }, payLoad: { id: string; ruleModel: IRule }) {
    const res = await RuleService.createRule(payLoad.id, payLoad.ruleModel);
    commit('setRule', res);
    return res;
  },

  /**
   * Create new rules list
   * @param commit
   * @param project_id
   * @param rulesList rules list
   */
  async addRules({ commit }, { project_id, rulesList }) {
    const res = RuleService.createRules(project_id, rulesList);
    return res;
  },

  /**
   * Update selected rule
   * @param commit
   * @param payLoad rule data
   */
  async modifyRule({ commit }, payLoad: { id: string; rule_id: string; ruleModel: IRule }) {
    const res = await RuleService.updateRule(payLoad.id, payLoad.rule_id, payLoad.ruleModel);
    commit('updateRule', res);
    commit('app/setReport', {
      type: 'success',
      message: 'rule updated',
      value: true,
    }, { root: true });
  },

  /**
   * Delete selected rule
   * @param commit
   * @param state
   * @param project_id project id
   * @param rule_id rule id
   */
  async deleteRule({ commit, state }, { project_id, rule_id }) {
    await RuleService.deleteRule(project_id, rule_id);
    commit('deleteRuleFromList', rule_id);
    commit('app/setReport', {
      type: 'success',
      message: 'rule deleted',
      value: true,
    }, { root: true });
  },
};

export const rules: Module<IRulesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
