
import LocationService from '@/services/LocationService';
import { IWeatherLocation, IGetLocationWithDelay } from '@/types/app.types';
import { IPartnerWorkspace, IWorker } from '@/types/partnerWorkspace.types';
import { Validation } from '@/ui/mixins/validation';
import { debounce } from 'lodash';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import ManageWorker from '@/ui/components/modals/Partner/ManageWorker.vue';
import { formatIsoDate } from '@/utils/utilsFunctions';
import { Action, Getter } from 'vuex-class';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';

@Component({
  methods: { formatIsoDate },
  components: {
    ManageWorker,
    DeleteModalForm,
  },
})
export default class PartnerInformation extends mixins(Validation) {
  @Getter('partnerWorkspaces/workspace') workspace!: IPartnerWorkspace;
  @Action('partnerWorkspaces/updateWorkspace') updateWorkspace!: (data: {project_id: string; workspace: IPartnerWorkspace; showMessage: boolean}) => Promise<void>;
  @Action('partnerWorkspaces/deleteWorkspace') deleteWorkspace!: (id: string) => Promise<void>;
  @Action('app/postAsset') postAsset!: (asset: any) => Promise<any>;

  valid = false;
  project: IPartnerWorkspace = {
    name: 'Partner 1',
    meta: {
      phone_number: '',
      description: '',
      logo: '',
      location: {
        display_name: '',
        lat: '0',
        lon: '0',
      },
      type: '',
      workers: [],
      tasks: [],
      offers: [],
      documentation: [],
      solar_calculation_results: [],
    },
    id: '',
    created_at: '',
  };

  headers = [
    {
      text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.headers.name'),
      value: 'name',
      sortable: false,
    },
    {
      text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.headers.email'),
      value: 'email',
      sortable: false,
    },
    {
      text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.headers.actions'),
      value: 'actions',
      sortable: false,
    },
  ];

  get projectTypes() {
    return [
      { text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.projectTypes.efh'), value: 'efh' },
      { text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.projectTypes.mfh'), value: 'mfh' },
      { text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.projectTypes.ac'), value: 'ac' },
      { text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.projectTypes.dc'), value: 'dc' },
      { text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.projectTypes.business'), value: 'business' },
      { text: this.$t('partners.partnerWorkspace.pages.projectPlan.manageTasks.projectTypes.industry'), value: 'industry' },
    ];
  }

  // search location start
  location: IWeatherLocation | null = null;
  searchPlaces: string | null = null;
  placesArr: IWeatherLocation[] = [];
  getLocationWithDelay: IGetLocationWithDelay | null = null;

  @Watch('searchPlaces')
  async onSearchPlaces(val: string | null) {
    const isEqualToSaved = this.project?.meta?.location?.display_name === val;
    if (val && val.length > 2 && !isEqualToSaved) {
      this.getLocationWithDelay?.(val);
    }
  }

  setLocation(location: IWeatherLocation) {
    this.location = { ...location };
    this.placesArr.push({ ...location });
  }
  // search location end

  // file upload start
  fileObject = { name: '' };

  /**
   * Upload file and update current project
   */
  async handleFileUpload() {
    if (this.fileObject) {
      if (this.fileObject.name.lastIndexOf('.') <= 0) {
        return;
      }
      // upload file
      const result = await this.postAsset(this.fileObject);
      if (result) {
        // save asset id in project
        this.project.meta.logo = result;
      }
    }
  }
  // file upload end

  handleDeleteWorker(worker: IWorker) {
    const index = this.project.meta.workers?.findIndex((w: IWorker) => w.id === worker.id);
    if (index !== undefined && index !== -1) {
      this.project.meta.workers?.splice(index, 1);
    }
  }

  handleAddWorker(worker: IWorker) {
    this.project.meta.workers?.push(worker);
  }

  handleEditWorker(worker: IWorker) {
    const index = this.project.meta.workers?.findIndex((w: IWorker) => w.id === worker.id);
    if (index !== undefined && index !== -1) {
      this.project.meta.workers?.splice(index, 1, worker);
    }
  }

  async handleDelete() {
    this.deleteWorkspace(this.project.id);
    this.$router.push({ path: '/partner-workspace' });
  }

  async handleUpdatePartnerWorkspace() {
    const project = { ...this.project };
    if (this.location) project.meta.location = this.location;
    await this.updateWorkspace({ project_id: this.project.id, workspace: this.project, showMessage: true });
  }

  created() {
    if (this.project) {
      this.project = this.workspace;
      // set location if already saved
      if (this.project.meta?.location) this.setLocation(this.project.meta?.location);
    }
    // fetch location with 1sec delay
    this.getLocationWithDelay = debounce(async (place) => {
      this.placesArr = await LocationService.fetchLocations(place);
    }, 1000);
  }

  mounted() {
    (this.$refs.form as any).validate();
  }
}
