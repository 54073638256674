
import { Vue, Component } from 'vue-property-decorator';
import BatteryBaseMock from '@/ui/components/devices/previews/components/BatteryBaseMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    BatteryBaseMock,
    DeviceLayout,
  },
})
export default class BatteryPreview extends Vue {
  isSettingsView = false

  get description() {
    return this.$t('devicesDescriptions.Battery');
  }
  get previewNameLang() {
    return this.$t('devices.Battery.previewName');
  }

  switchSettingsView(setting: boolean) {
    this.isSettingsView = setting;
  }
}
