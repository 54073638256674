
import { Vue, Component, Prop } from 'vue-property-decorator';
import imports from '@/ui/components/wizards/imports';
import { IWizardPage } from '@/types/wizards/wizard.general.types';

@Component({
  components: {
    ...imports,
  },
})
export default class WizardContent extends Vue {
  @Prop() name!: string;
  @Prop() pages!: IWizardPage[];
  @Prop({ default: 0 }) currentPage!: number;
  @Prop({ default: false }) hasToBeOnline!: boolean;
  @Prop({ default: false }) considerProjectStatus!: boolean;
  @Prop({ default: false }) isProjectOnline!: boolean;
}
