
import { Vue, Component, Prop } from 'vue-property-decorator';
import DeleteModalForm from '@/ui/components/modals/DeleteModalForm.vue';
import ManageRoom from '@/ui/components/modals/ManageRoom.vue';
import { Action, Getter, State } from 'vuex-class';
import PlaceHolderImage from '@/ui/components/components/PlaceHolderImage.vue';
import { IMemberState } from '@/store/modules/members/types';
import {
  duplicateSingleRoom,
} from '@/utils/duplicationUtils';
import _ from 'lodash';
import { IRoom } from '@/types/rooms.types';
import { IProject } from '@/types/project.types';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent room item
 */
@Component({
  components: {
    DeleteModalForm,
    ManageRoom,
    PlaceHolderImage,
  },
})
export default class RoomItem extends Vue {
  @Prop() roomData!: IRoom;
  @Prop() isActive!: boolean;
  @Prop() areasMiniView!: boolean;
  @State('members') membersState!: IMemberState;
  @Getter('projects/project') project!: IProject;
  @Getter('devices/devicesByRoom') devicesByRoom!: (roomId: string) => IDevice[];
  @Action('rooms/deleteRoom') deleteRoomState!: (id: string) => Promise<void>;
  @Action('rooms/updateRoom') updateRoom!: (room: any) => Promise<void>;
  @Getter('members/currentMember') currentMember!: any;

  isMenuOpen = false
  isCloseOnClick = true
  isAllowedToDelete = true

  /**
   * Defines list of actions by inserted actions names
   * @param {array} actions list of actions names
   * @return {array} list of actions
   */
  roomActionsList(actions: string[]) {
    return actions.map((action: string) => {
      return ({
        edit: { title: 'uiComponents.areasActions.edit', modal: 'ManageRoom' },
        duplicate: { title: 'uiComponents.areasActions.duplicate', handle: () => this.duplicateRoom() },
        delete: { title: 'uiComponents.areasActions.delete', modal: 'DeleteModalForm' },
      } as any)[action];
    });
  }

  /**
   * Checks current member access and define which actions to show
   * @return {array} list of actions
   */
  get roomActions() {
    if (this.currentMember?.admin) {
      if (this.isAllowedToDelete) return this.roomActionsList(['edit', 'duplicate', 'delete']);
      else return this.roomActionsList(['edit', 'duplicate']);
    }
    return [];
  }

  get isImageSource() {
    return !!(this.roomData.meta.imageId || this.roomData.meta.cover);
  }

  get devicesLength() {
    return this.devicesByRoom(this.roomData.id).length;
  }

  /**
   * Converts icon color according to current theme
   * @param {string} source icon id
   * @return {object} styles object
   */
  iconTheme(source: string) {
    const isIcon = /icons/g.test(source);
    return isIcon ? { filter: this.$vuetify.theme.dark ? 'brightness(0) invert(1)' : null } : {};
  }

  handleRoom() {
    this.$emit('handleRoomActiveStatus', this.roomData.id);
  }
  editRoom(room: IRoom) {
    this.updateRoom(room);
  }
  deleteRoom() {
    this.deleteRoomState(this.roomData.id);
  }
  async duplicateRoom() {
    await duplicateSingleRoom(this.roomData, this.project, this.currentMember);
  }

  created() {
    Object.values(this.devicesByRoom(this.roomData.id)).forEach((element: any) => {
      const devicesToExclude: string[] = ['Robot', 'SurveyClient'];
      const isValid: boolean = devicesToExclude.some((el: string) => el === element.data.type);
      if (isValid) this.isAllowedToDelete = false;
    });
  }
}
