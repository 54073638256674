
import Gantt from 'frappe-gantt';
import ManageTask from '@/ui/components/modals/Partner/ManageTask.vue';
import TasksTable from '@/ui/components/modals/Partner/TasksTable/index.vue';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { IPartnerWorkspace, ITasksObject } from '@/types/partnerWorkspace.types';
import { Getter, Action } from 'vuex-class';

@Component({
  components: {
    ManageTask,
    TasksTable,
  },
})
export default class ProjectPlan extends Vue {
  @Getter('partnerWorkspaces/workspace') workspace!: IPartnerWorkspace;
  @Action('partnerWorkspaces/updateWorkspace') updateWorkspace!: (data: {project_id: string; workspace: IPartnerWorkspace; showMessage: boolean}) => Promise<void>;

  get periodOptionButtons() {
    return [
      {
        text: this.$t('partners.partnerWorkspace.pages.projectPlan.periodOptions.day'),
        value: 'Day',
      },
      {
        text: this.$t('partners.partnerWorkspace.pages.projectPlan.periodOptions.week'),
        value: 'Week',
      },
      {
        text: this.$t('partners.partnerWorkspace.pages.projectPlan.periodOptions.month'),
        value: 'Month',
      },
    ];
  }

  selectedPeriod = 'Day';
  // gantt object
  gantt: any = null;
  // tasks visible on the gantt chart
  tasksForGantt: any = [];
  tasks: ITasksObject[] = [];
  rerenderSVG = false;

  // options to pass to the gantt constructor
  options = {
    header_height: 50,
    bar_height: 40,
    padding: 20,
    bar_corner_radius: 6,
  };

  /**
   * Get the tasks in the format required by the gantt chart
   * dependencies are converted from an array of objects to an array of strings
   */
  formatTasksForGantt(tasks: ITasksObject[]) {
    return tasks.map((task: ITasksObject) => {
      return {
        id: task.id,
        name: task.name,
        start: task.start,
        end: task.end,
        progress: task.progress,
        dependencies: task.dependencies.join(', '), // convert dependencies to string
      };
    });
  }

  @Watch('tasksForGantt', { deep: true })
  onTasksChange() {
    if (this.gantt !== null) {
      this.gantt.refresh(this.tasksForGantt); // refresh the gantt in order to display the updated tasks
    }
  }

  handleManageTasksClosed(currentTaskList: ITasksObject[]) {
    this.tasks = currentTaskList;
    this.tasksForGantt = this.formatTasksForGantt(currentTaskList);

    if (currentTaskList.length === 0) {
      // in case that the user deletes all tasks or it is a new workspace
      this.rerenderSVG = !this.rerenderSVG;
      this.gantt = null;
      return;
    }

    if (this.gantt === null) {
      // if the gantt chart has not been initialized yet, initialize it
      this.initGantt();
      this.$nextTick(() => {
        this.gantt.refresh(this.tasksForGantt);
        this.refreshHeight(true);
      });
    } else {
      // if the gantt chart has already been initialized, refresh it
      this.$nextTick(() => {
        this.refreshHeight();
      });
    }
  }

  changPeriod(period: string) {
    this.selectedPeriod = period;
    this.gantt.change_view_mode(period);
    this.translateHeader(); // translate the header to the current language
  }

  refreshHeight(initialLoad = false) {
    // set height of the gantt container
    this.$nextTick(() => {
      const container: any = this.$refs.ganttContainer;
      const svg: any = document.querySelectorAll('svg.gantt')[0];
      if (container) {
        if (document.querySelectorAll('svg.gantt')[0] !== undefined) {
          const newHeight = (svg.getAttribute('height') - 130).toString();
          document.querySelectorAll('svg.gantt')[0].setAttribute('height', newHeight); // 130 is the size of the empty space under the gantt chart
        }
        if (initialLoad) {
          this.translateHeader();
        }
      }
    });
  }

  translateHeader() {
    const container: any = this.$refs.ganttContainer;
    const currentYear = new Date().getFullYear();

    container.querySelectorAll('.upper-text, .lower-text').forEach((node: any) => {
      const isUpperText = node.classList.contains('upper-text');
      const textContent = node.innerHTML.toLowerCase();

      if (isUpperText && this.selectedPeriod !== 'Month') {
        // for months it is necessary to translate anything because the gantt chart only displays the current year
        node.innerHTML = `${this.$t(`uiComponents.months.${textContent}`)} ${currentYear}`;
      } else if (!isUpperText) {
        // for weeks it is necessary to translate the lower text
        const cleanText = textContent.replace(/[^a-z]/g, '');
        const number = textContent.replace(/\D/g, '');

        if (cleanText) {
          node.innerHTML = `${number} ${this.$t(`uiComponents.months.${cleanText}`)}`;
        }
      }
    });
  }

  initGantt() {
    const ganttElement: any = document.getElementById('gantt-container');
    this.gantt = new Gantt(ganttElement, this.tasksForGantt, {
      ...this.options,
      language: 'en',
      // custom popup (currently not visible)
      custom_popup_html(task: any) {
        // custom popup when th user clicks on any task.
        // the task object will contain the updated dates and progress value
        return `
          <div class="details-container">
            ${task.name}
          </div>
          `;
      },
    });
    this.refreshHeight(true);
  }

  created() {
    // tasks are by default null in the backend
    this.tasks = this.workspace.meta.tasks !== null ? this.workspace.meta.tasks : [];
  }

  mounted() {
    if (this.tasks.length !== 0) {
      this.tasksForGantt = this.formatTasksForGantt(this.tasks);
      this.initGantt();
    }
  }
}
