
import { Vue, Component } from 'vue-property-decorator';
import SwitchMock from '@/ui/components/devices/previews/components/SwitchMock.vue';
import PreviewActions from '@/ui/components/devices/actions/PreviewActions.vue';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';
import PushButtonMock from '@/ui/components/devices/previews/components/PushButtonMock.vue';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';

@Component({
  components: {
    InputFieldMock,
    ShowEventDotMock,
    PushButtonMock,
    PreviewActions,
    SwitchMock,
    DeviceLayout,
  },
})
export default class TSGBrauchwasserPreview extends Vue {
  isSettingsView = false
  selected = 0;
  itemsError = [
    { name: 'error', value: 0 },
    { name: 'no error', value: 1 },
  ];
  itemsAlarm = [
    { name: 'alarm', value: 0 },
    { name: 'no alarm', value: 1 },
  ];

  get previewNameLang() {
    return this.$t('devices.TSGBrauchwasser.previewName');
  }

  switchSettingsView(setting: boolean) {
    this.isSettingsView = setting;
  }
}
