
import { Vue, Component, Prop } from 'vue-property-decorator';
import SelfConsumption
  from '@/ui/components/devices/mpc/components/chartsWindow/SelfConsumption/index.vue';
import SingleSystemsView
  from '@/ui/components/devices/components/EnergyParts/EnergyView/components/charts/SingleSystemsView.vue';
import { IAppState } from '@/store/modules/app/types';
import { State } from 'vuex-class';

/**
 * Component that represent wrapper for charts tabs navigation.
 * Used in EMS.
 */
@Component({
  components: {
    SelfConsumption,
    SingleSystemsView,
  },
})
export default class EMSCharts extends Vue {
  @Prop({ default: null }) mpcData!: any;
  @Prop({ default: null }) prediction!: any;
  @Prop({ default: null }) predictedPower!: any;
  @Prop({ default: null }) predictedTargetPower!: any;
  @Prop({ default: null }) predictedEnergy!: any;
  @Prop({ default: null }) predictedSOC!: any;
  @Prop() mpcId!: any;
  @Prop() scaling!: any;
  @Prop({ default: false }) isHybridSystem!: boolean;

  @State('app') appState!: IAppState;

  tab = null
  settingsWrapperHeight = 0;
  shownSystemsSingleSystemsChart: string[] = [
    'charge_station', 'electric_heating', 'heating_pump', 'big_consumer', 'pv',
    'generator', 'grid', 'battery', 'house', 'chp',
  ];

  get superAdmin() {
    return this.appState.user.super_admin;
  }

  mounted() {
    this.$nextTick(() => {
      const settingsWrapperElement = (this.$refs.settingsWrapper as any);
      // change height of settingsWrapper for hybrid systems because we need to calculate in space for a info tooltip
      this.settingsWrapperHeight = !this.isHybridSystem ? settingsWrapperElement.clientHeight * 0.7 : (settingsWrapperElement.clientHeight * 0.7) - 40;
    });
  }

  get items() {
    return [
      {
        title: this.$t('mlModel.EMS.charts.chartDifference.title'),
        view: 'SelfConsumption',
        showHouseConsumption: true,
        showScores: true,
      },
      {
        title: this.$t('devices.EnergyView.ChartsWindow.singleSystems'),
        view: 'SingleSystemsView',
        showHouseConsumption: false,
        showScores: false,
        showColumnChart: true,
        systemsVars: {
          charge_station: ['energy_counter'],
          electric_heating: ['energy_counter'],
          heating_pump: ['energy_counter'],
          big_consumer: ['energy_counter'],
          pv: ['energy_counter'],
          chp: ['energy_counter'],
          generator: ['energy_counter'],
          grid: ['energy_counter', 'reverse_energy_counter'],
          battery: ['energy_counter', 'reverse_energy_counter'],
          house: ['energy_counter'],
        },
        defaultAggregation: 'diff',
        navItemsToExclude: ['live', 'hour', 'day'],
      },
    ];
  }

  getNumberOfSeriesPerChart(item: Record<string, any>) {
    return item?.numberOfSeriesPerChart;
  }
}
