
import { Component } from 'vue-property-decorator';
import WizardContentView
  from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Getter, Mutation, State } from 'vuex-class';
import {
  ITenantWizardState,
} from '@/store/modules/tenantWizard/types';
import { IResidualElectricityPart, ITenantModel } from '@/types/wizards/tenant.types';
import {
  minMaxValidation,
  valuesAddTo100,
  timeToMinutes,
} from '@/utils/utilsFunctions';
import { cloneDeep } from 'lodash';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import moment from 'moment';
import { IProject } from '@/types/project.types';

@Component({
  methods: { valuesAddTo100, minMaxValidation },
  components: {
    WizardContentView,
    InfoTooltip,
  },
})
export default class TariffSettings extends mixins(Validation) {
  @Getter('projects/project') project!: IProject;
  @State('tenantWizard') tenantState!: ITenantWizardState;
  @Mutation('tenantWizard/updateTenantObject') updateTenantObject!: (data: ITenantModel) => void;
  @Action('tenantWizard/handleIncrement') handleIncrement!: () => void;
  @Action('tenantWizard/handleDecrement') handleDecrement!: () => void;
  valid = false;

  tariffSettings: ITenantModel['general']['tariffSettings'] = {
    electricity: {
      lowTariffProduction: 0,
      highTariffProduction: 0,
      lowTariffGrid: 0,
      highTariffGrid: 0,
      allDayTariffGrid: 0,
      allDayTariffProduction: 0,
      residualElectricityComposition: [
        {
          name: this.$t('tenantWizard.tariffSettings.residualCompositionDefaultTexts.biomass') as string,
          percentage: 20,
        },
        {
          name: this.$t('tenantWizard.tariffSettings.residualCompositionDefaultTexts.wind') as string,
          percentage: 20,
        },
        {
          name: this.$t('tenantWizard.tariffSettings.residualCompositionDefaultTexts.solar') as string,
          percentage: 20,
        },
        {
          name: this.$t('tenantWizard.tariffSettings.residualCompositionDefaultTexts.cole') as string,
          percentage: 20,
        },
        {
          name: this.$t('tenantWizard.tariffSettings.residualCompositionDefaultTexts.nuclearPower') as string,
          percentage: 20,
        },
      ] as IResidualElectricityPart[],
      lowTariffTimesProduction: {
        start: '00:00',
        end: '00:00',
      },
      lowTariffTimesGrid: {
        start: '00:00',
        end: '00:00',
      },
      residualElectricityText: '',
    },
    water: {
      priceServiceWater: 0, // should have same currency like selected inside "general.paymentDetails.currency"
      priceColdWater: 0, // should have same currency like selected inside "general.paymentDetails.currency"
      priceHeating: 0, // should have same currency like selected inside "general.paymentDetails.currency"
      priceCooling: 0, // should have same currency like selected inside "general.paymentDetails.currency"
    },
  };
  timeZone = '';
  showAllDayTariffFields = false;

  selectedCurrency = '';
  tariffCost = {
    priceColdWater: 0,
    priceServiceWater: 0,
    priceHeating: 0,
    priceCooling: 0,
  };

  /* check if time for production tariffs is valid */
  get productionTariffsValid() {
    let valid = true;
    const values: any = this.tariffSettings.electricity.lowTariffTimesProduction;
    valid = timeToMinutes(values.start) < timeToMinutes(values.end);
    if (!valid) return this.$t('modals.manageReport.errorMessages.timeError');
    return valid;
  }

  /* check if time for grid tariffs is valid */
  get gridTariffsValid() {
    let valid = true;
    const values: any = this.tariffSettings.electricity.lowTariffTimesGrid;
    valid = timeToMinutes(values.start) < timeToMinutes(values.end);
    if (!valid) return this.$t('modals.manageReport.errorMessages.timeError');
    return valid;
  }

  // returns all european timezones
  get timeZones() {
    const timezones = moment.tz.names().filter((timezone: string) => {
      return timezone.includes('Europe');
    });
    return timezones;
  }

  created() {
    const currentTariffSettings = this.tenantState.tenant.general.tariffSettings;
    // if tenant was done before or if we went back from page after, show the same settings as before
    if (this.project.meta.wasTenantWizardCompleted || currentTariffSettings.electricity.allDayTariffGrid !== 0 || currentTariffSettings.electricity.highTariffGrid !== 0) {
      this.tariffSettings = cloneDeep(this.tenantState.tenant.general.tariffSettings);
      this.tariffCost = this.tenantState.tenant.general.tariffSettings.water;
      if (this.tenantState.tenant.general.timeZone !== undefined) {
        this.timeZone = this.tenantState.tenant.general.timeZone;
      }
      // if one of the all day tariff fields is not 0, show the all day tariff fields
      if (this.tariffSettings.electricity.allDayTariffGrid !== 0 || this.tariffSettings.electricity.allDayTariffProduction !== 0) {
        this.showAllDayTariffFields = true;
      }
    } else {
      // if tenant wasn't done before, show all day tariff fields as default
      this.showAllDayTariffFields = true;
    }
  }

  mounted() {
    this.selectedCurrency = this.tenantState.tenant.general.paymentDetails.currency;
    this.validate();
  }

  handleNext() {
    this.updateTenant();
    this.handleIncrement();
  }

  handleTariffSelectionChange() {
    if (!this.showAllDayTariffFields) {
      // clear all day tariff fields
      this.tariffSettings.electricity.allDayTariffGrid = 0;
      this.tariffSettings.electricity.allDayTariffProduction = 0;
    } else {
      // clear high/low tariff fields
      this.tariffSettings.electricity.lowTariffTimesProduction.start = '00:00';
      this.tariffSettings.electricity.lowTariffTimesProduction.end = '00:00';
      this.tariffSettings.electricity.lowTariffTimesGrid.start = '00:00';
      this.tariffSettings.electricity.lowTariffTimesGrid.end = '00:00';
      this.tariffSettings.electricity.lowTariffGrid = 0;
      this.tariffSettings.electricity.highTariffGrid = 0;
      this.tariffSettings.electricity.lowTariffProduction = 0;
      this.tariffSettings.electricity.highTariffProduction = 0;
    }
    this.$nextTick(() => {
      this.validate();
    });
  }

  updateTenant() {
    const tenantCopy = cloneDeep(this.tenantState.tenant);
    tenantCopy.general.tariffSettings.water = this.tariffCost;
    tenantCopy.general.tariffSettings.electricity = this.tariffSettings.electricity;
    tenantCopy.general.timeZone = this.timeZone;
    this.updateTenantObject(tenantCopy);
  }

  // check if sum of all percentages from the residualElectricityComposition is 100
  get isOneHundredPercent() {
    return valuesAddTo100(this.tariffSettings.electricity.residualElectricityComposition, 'percentage') || this.$t('tenantWizard.notHundredPercentError');
  }

  // adds new entrie to the residualElectricityComposition array and triggers validation
  addResidualElectricityComposition() {
    this.tariffSettings.electricity.residualElectricityComposition.push({
      name: '',
      percentage: 0,
    });
    this.$nextTick(() => {
      this.validate();
    });
  }

  // removes entrie from the residualElectricityComposition array at index
  clearFromResidualElectricityCompositionAt(index: number) {
    this.tariffSettings.electricity.residualElectricityComposition.splice(index, 1);
  }

  validate() {
    (this.$refs.form as any).validate();
  }
}
