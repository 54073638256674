
import { Component, Prop, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import SystemForecastView from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/SystemForecastView.vue';
import EnergyComponent
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/utils/EnergyComponent';
import EnergyVisualisation
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/index.vue';
import SystemDialog
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/SystemDialog.vue';
import { IDevice } from '@/types/devices.types';
import { IVariablesState } from '@/store/modules/measurements/types';
import { State } from 'vuex-class';
import SystemDialogView
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/SystemDialogView.vue';
import { INavigationState } from '@/store/modules/navigation/types';
import ActualViewSystemSettingsDialog
  from '@/ui/components/devices/components/EnergyParts/EnergyVisualisation/ActualView/ActualViewSystemSettingsDialog.vue';
import { EnergyCircleDisplayData, IconContent, SocContent } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import { EnergyCircleType, energyCircleIconForType } from '@/types/energyVisualisation/EnergyCircleType';
import { EnergyLineDisplayData } from '@/types/energyVisualisation/EnergyLineDisplayData';
import { getDefaultArrowDirectionSetpointOptimizer, getDefaultLineColorsSetpointOptimizer, getDefaultSpinnerColorSetpointOptimizer } from '../../../../components/EnergyParts/EnergyVisualisation/utils/EnergyVisualisationUtils';
import { SYSTEMS_WHERE_SETTINGS_WINDOW_ACTIVE } from '../../../../components/EnergyParts/EnergyVisualisation/utils/systemDialog';
import { celsiusUnit } from '@/utils/unit/unitConstants';
/**
 * Actual view for SetPointOptimizer
 */
@Component({
  components: {
    EnergyVisualisation,
    SystemDialog,
    SystemDialogView,
    ActualViewSystemSettingsDialog,
    SystemForecastView,
  },
})
export default class ActualView extends mixins(EnergyComponent) {
  @Prop({ default: null }) deviceData!: IDevice;
  @Prop({ default: false }) enableMinWidth!: boolean;
  @Prop({ default: false }) showDialog!: boolean;
  @Prop({ default: false }) openedSystem!: EnergyCircleType;
  @Prop({ default: null }) lineData!: any;
  @Prop({ default: false }) lineReady!: boolean;
  @Prop({ default: false }) isLineMovementReversed!: boolean;
  @Prop({ default: null }) forecastData!: any;
  @State('measurements') measurementsState!: IVariablesState;
  @State('navigation') navigationState!: INavigationState;

  showSystemSettingsDialog = false;
  openedSettingsSystemData?: EnergyCircleDisplayData;
  controls: any = undefined;

  @Provide('onSingleCircleClick') onSingleCircleClick = this.onSingleCircleClickCallback;
  onSingleCircleClickCallback(circleData: EnergyCircleDisplayData) {
    const systems = this.getSystemComponents(this.deviceData, this.openedSystem);
    this.controls = systems[circleData.id];
    const isSettingsActive = SYSTEMS_WHERE_SETTINGS_WINDOW_ACTIVE.includes(this.deviceData.data.type);
    if (!isSettingsActive) return;

    this.showSystemSettingsDialog = true;
    this.openedSettingsSystemData = circleData;
  }

  handleClose() {
    this.showSystemSettingsDialog = false;
  }

  get systemDialogCircles(): EnergyCircleDisplayData[] {
    if (!this.openedSystem) return [];

    const systems = this.getSystemComponents(this.deviceData, this.openedSystem);
    return Object.entries(systems).map(([key, systemComponent]) => {
      let unit;
      let bottomDynamicValue;
      let disableSpinner;

      const bottomValue: any = this.measurements[systemComponent.flow_temperature];
      const alternativeValue = 0;
      const centerContent: IconContent | SocContent = {
        type: 'icon',
        value: energyCircleIconForType(this.openedSystem),
      };
      return {
        id: key,
        centerContent,
        bottomValue,
        errorWarningMode: this.measurements[systemComponent.error],
        title: systemComponent.title,
        spinnerColor: getDefaultSpinnerColorSetpointOptimizer(this.openedSystem, bottomValue ?? alternativeValue),
        alternativeValue: undefined,
        unit: celsiusUnit,
        bottomDynamicValue,
        disableSpinner,
      } as EnergyCircleDisplayData;
    });
  }

  get systemDialogLines(): Partial<EnergyLineDisplayData>[] {
    if (!this.openedSystem) return [];

    return this.systemDialogCircles.map(
      (displayData: EnergyCircleDisplayData) => {
        const arrowDirection = getDefaultArrowDirectionSetpointOptimizer(this.openedSystem, displayData);
        const colors = getDefaultLineColorsSetpointOptimizer(this.openedSystem, displayData);

        return {
          id: displayData.id,
          arrowDirection,
          colors,
        } as Partial<EnergyLineDisplayData>;
      },
    );
  }
}
