
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Chart } from 'highcharts-vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import DeviceDescription from '@/ui/components/devices/actions/DeviceDescription.vue';
import PreviewActions from '@/ui/components/devices/actions/PreviewActions.vue';
import Highcharts from 'highcharts';
import solidGauge from 'highcharts/modules/solid-gauge';
import highchartsMore from 'highcharts/highcharts-more';

highchartsMore(Highcharts);
solidGauge(Highcharts);

@Component({
  components: {
    highcharts: Chart,
    DeviceDescription,
    PreviewActions,
    DeviceLayout,
  },
})
export default class GaugePreview extends Vue {
  @Prop() chartWidth!: any;
  @Prop() isModal!: boolean;

  timer: any
  val = 40;
  gaugeWidth = 490;
  chartKey = 1;

  get description() {
    return this.$t('devicesDescriptions.Gauge');
  }
  get previewNameLang() {
    return this.$t('devices.Gauge.previewName');
  }

  mounted() {
    this.$nextTick(() => this.resizeChart());
    window.addEventListener('resize', this.resizeChart);
  }

  resizeChart() {
    const element: any = this.$refs.chartDiv;
    if (element !== undefined) {
      if (element.clientWidth > 480) {
        this.gaugeWidth = 480;
      } else {
        this.gaugeWidth = element.clientWidth;
      }
      this.chartKey += 1;
    }
  }

  destroyed() {
    window.removeEventListener('resize', this.resizeChart);
  }
}
