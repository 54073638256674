
import { Component, Prop } from 'vue-property-decorator';
import InputFieldBase from '@/ui/components/devices/devices/base/InputFieldBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent VariableInputField device
 */
@Component({
  components: {
    InputFieldBase,
    DeviceLayout,
  },
})
export default class VariableInputField extends mixins(Validation) {
  @Prop() deviceData!: IDevice;
  @State('measurements') measurementsState!: IVariablesState;

  get measurements() {
    return this.measurementsState.measurements;
  }
  get unit() {
    return this.deviceData.data.meta?.unit ? this.deviceData.data.meta.unit : '';
  }

  iconTheme() {
    return { filter: this.$vuetify.theme.dark ? 'brightness(0) invert(1)' : null };
  }
}
