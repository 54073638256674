
import WizardContentView from '@/ui/components/wizards/baseComponents/WizardContentView.vue';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import _ from 'lodash';
import { IProject, IProjectExpert } from '@/types/project.types';
import WizardComponent from '@/ui/components/wizards/baseComponents/WizardComponent';
import LocationService from '@/services/LocationService';
import { IWizardLoadingState } from '@/types/wizards/wizard.general.types';

@Component({
  components: {
    InfoTooltip,
    WizardContentView,
  },
})
export default class GeneralInformations extends WizardComponent {
  @Getter('projects/project') project!: IProject;
  @Action('app/postAsset') postAsset!: (asset: any) => Promise<any>;
  @Action('projects/updateProject') updateProject!: (project: IProject) => Promise<void>;
  @Mutation('installationWizard/incrementLoadingSentCount') incrementLoadingSentCount!: () => void;
  @Mutation('installationWizard/setLoadingState') setLoadingState!: (payload: IWizardLoadingState) => void;

  generalInformations = {
    name: '',
    projectDescription: '',
  }
  expert: IProjectExpert = {
    name: '',
    street: '',
    city: '',
    zip: '',
    country: '',
    email: '',
    phone: '',
  }
  fileObject = { name: '' };
  placesArr: any = [];
  getLocationWithDelay: any = null;
  searchPlaces: any = null;
  model: any = null;
  valid = false;
  initialProjectName = '';
  uploadButtonClicked = true;

  get forceProjectNameChange() {
    // if installation wizard was completed, it is not required to change the project name anymore
    if (this.wasInstallationWizardCompleted) return true;

    // if the installation wizard is done for the first time, the project name has to be changed
    if (this.generalInformations.name === this.initialProjectName) {
      return this.$t('installationWizard.generalSettings.generalInformationsPage.forceProjectNameChange');
    }
    return true;
  }

  @Watch('searchPlaces')
  async onSearchPlaces(val: any) {
    const isEqualToSaved = this.project.meta?.location?.display_name === val;
    if (val && val.length > 2 && !isEqualToSaved) {
      this.getLocationWithDelay(val);
    }
  }

  created() {
    // fetch location with 1sec delay
    this.getLocationWithDelay = _.debounce(async (place) => {
      this.placesArr = await LocationService.fetchLocations(place);
    }, 1000);

    this.fillProjectObject();
    if (this.project?.meta?.expert) this.fillExpertProperties();
  }

  mounted() {
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }

  fillExpertProperties() {
    this.expert = { ...(this.project?.meta as any)?.expert };
  }

  fillProjectObject() {
    this.generalInformations.name = this.project.name;
    this.generalInformations.projectDescription = this.project.meta.description;
    if (this.project.meta?.location) this.setLocation(this.project.meta?.location);
    this.initialProjectName = this.generalInformations.name;
  }

  setLocation(location: any) {
    this.model = { ...location };
    this.placesArr.push({ ...location });
  }

  resetButtonClickedVariable() {
    this.uploadButtonClicked = false;
  }

  async handleFileUpload() {
    this.uploadButtonClicked = true;
    if (this.fileObject) {
      if (this.fileObject.name.lastIndexOf('.') <= 0) {
        return;
      }
      const result = await this.postAsset(this.fileObject);
      if (!result) return;
      await this.updateProject({
        ...this.project,
        ...(result && {
          meta: { ...this.project.meta,
            imageId: result,
          },
        }),
      });
    }
  }


  async handleNext() {
    // start loading
    this.setLoadingState({
      isLoading: true,
      loadingCount: 0,
      loadingTotal: 1,
    });
    await this.updateProject({
      ...this.project,
      ...{ name: this.generalInformations.name },
      meta: {
        ...this.project.meta,
        location: this.model,
        description: this.generalInformations.projectDescription,
        expert: this.expert,
      },
    });

    this.incrementLoadingSentCount();
    this.resetLoadingState();
    this.handleIncrement();
  }
}
