
import { ITriggerReportActionsPayload } from '@/types/report.types';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component
export default class TriggerActions extends Vue {
  @Prop() report!: any;
  @Action('report/triggerReportActions') triggerReportActions!: (payload: ITriggerReportActionsPayload) => Promise<void>;

  startDateActionTrigger = '';
  endDateActionTrigger = '';
  maxDateCalendar = '';
  loading = false;

  get isTriggerActionValidToRun() {
    return !!this.startDateActionTrigger && !!this.endDateActionTrigger && this.isTriggerActionTimesRight;
  }

  get isTriggerActionTimesRight() {
    if (this.startDateActionTrigger && this.endDateActionTrigger) {
      const dateStart = new Date(this.startDateActionTrigger);
      const dateEnd = new Date(this.endDateActionTrigger);
      const unixStart = Math.trunc(dateStart.getTime() / 1000);
      const unixEnd = Math.trunc(dateEnd.getTime() / 1000);
      return !(unixStart >= unixEnd || this.startDateActionTrigger === '' || this.endDateActionTrigger === null);
    } else {
      return false;
    }
  }

  async manualTriggerAction() {
    this.loading = true;
    const dateStart = new Date(this.startDateActionTrigger);
    const dateEnd = new Date(this.endDateActionTrigger);
    const unixStart = Math.trunc(dateStart.getTime() / 1000);
    const unixEnd = Math.trunc(dateEnd.getTime() / 1000);
    await this.triggerReportActions({
      id: this.$route.params.id,
      report_id: this.report.id,
      reportModel: this.report,
      start: unixStart,
      end: unixEnd,
      format: 'pdf',
    });
    this.loading = false;
  }

  updateMaxDateCalendar() {
    const yourDate = new Date();
    let currentTimeStamp: any = Date.now();
    const timeZoneOffset = (yourDate.getTimezoneOffset() * -1) / 60;
    currentTimeStamp += (timeZoneOffset * 3600000);

    this.maxDateCalendar = new Date(currentTimeStamp).toISOString().split('.')[0] as string;
  }

  created() {
    this.updateMaxDateCalendar();
  }
}
