
import { Vue, Component, Prop } from 'vue-property-decorator';
import CurrentValueGaugeChartBase from '@/ui/components/devices/devices/base/CurrentValueGaugeChartBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent Gauge device
 */
@Component({
  components: {
    CurrentValueGaugeChartBase,
    DeviceLayout,
  },
})
export default class Gauge extends Vue {
  @Prop() deviceData!: IDevice;
  @Prop() chartWidth!: number;
  @Prop() dndGridLayoutContainerWidth!: number;

  rerenderKey = 0

  get unit() {
    return this.deviceData.data.meta.unit;
  }
  get scaling() {
    return this.deviceData.data.meta.scaling;
  }

  handleRerenderKey() {
    this.rerenderKey += 1;
  }

  rerenderDevice(data: any) {
    this.rerenderKey += 1;
  }
}
