
import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { IFlatsConfig } from '@/types/wizards/tenant.types';
import { IDevice } from '@/types/devices.types';
import { Getter } from 'vuex-class';
import {
  chargeStationTypes,
} from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';

@Component({
  components: {
    ModalWindow,
  },
})
export default class AddUserToChargeStationWindow extends Vue {
  @Prop({ default: {} }) flat!: IFlatsConfig;
  @Prop({ default: '' }) memberId!: string;
  @Prop({ default: 0 }) flatIndex!: number;

  @Getter('devices/allDevices') allDevices!: IDevice[];
  @Getter('measurements/measurements') measurements!: any;

  chargeStationItems: any[] = [];
  selectedChargeStation: string | null = null;

  /**
   * Returns array of electric charging station devices from all devices
   */
  get electricChargingStationV2Devices() {
    return this.allDevices.filter((device: IDevice) => device.data.type === 'ElectricChargingStationV2');
  }

  getChargeStationItemByType(type: string) {
    return chargeStationTypes.find((item: any) => item.value === type);
  }

  /**
   * Get charge station by index if it exists inside the project
   */
  chargeStationByIndex(index: number) {
    const chargingStations = this.electricChargingStationV2Devices;
    return chargingStations.find((device: IDevice) => device.data.mappings.OutputField_actualValue.includes(`prgCS.lrPowerCS_${index + 1}`) || device.data.mappings.OutputField_actualValue.includes(`prgPilot.lrPower_${index + 1}`));
  }

  getStatusFreeChargeByIndex(device: IDevice) {
    return this.measurements[device.data.mappings.Switch2V_onOff];
  }

  onAssignUser() {
    // update device with new user
    const chargeStation = this.allDevices.find((device: IDevice) => device.id === this.selectedChargeStation);
    if (chargeStation === undefined) return;
    if (chargeStation.data.meta.selectedUsers === undefined) chargeStation.data.meta.selectedUsers = [];
    chargeStation.data.meta.selectedUsers.push(this.memberId);
    // emit to electricity page to update device
    this.$emit('assignUser', { chargeStation, index: this.flatIndex });
    (this.$refs.ModalWindowInstance as any).closeDialog();
  }

  async onDialogStatusChange(status: boolean) {
    if (status) {
      // dialog open
      this.chargeStationItems = this.electricChargingStationV2Devices.map((device: IDevice, index: number) => {
        if (this.getStatusFreeChargeByIndex(device) === 0) return null;
        const name = this.getChargeStationItemByType(device.data.meta.systemType)?.name;
        return {
          text: name !== undefined ? `${device.name}: ${name}` : device.name,
          value: device.id,
        };
      }).filter((item: any) => item !== null);
    } else {
      // dialog close
      this.selectedChargeStation = null;
    }
  }
}
