
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import LynusChart from '@/ui/components/devices/charts/charts/LynusChart';
import ChartLoader from '@/ui/components/devices/charts/components/ChartLoader.vue';
import _ from 'lodash';
import { changeDate, toSeconds } from '@/utils/utilsFunctions';
import { envApi } from '@/utils/env';
import { calculate } from '@/ui/components/devices/charts/charts/ChartMath';
import { ChartClickCallbackFunction } from 'highcharts/highstock';
import { IMeasurements } from '@/types/measurements.types';
import { ChartData, ApproximationValue, ChartOption } from '@/types/chart.types';
import { Periods, getChartOptions, periodConfigurations, getDateBoundsByPeriod } from './ChartUtils';
import ChartColors from './ChartColors';

@Component({
  components: {
    ChartLoader,
  },
})
export default class LynusChartBasicView extends Vue {
  @Prop() chartData!: ChartData;
  @Prop() chartWidth!: number;
  @Prop({ default: undefined }) chartHeight!: number;
  @Prop({ default: false }) isThresholdLine!: boolean;
  @Prop({ default: 3 }) minNumberOfSeriesForWideChart!: number;
  @Prop({ default: false }) showTotalOfSeries!: number;
  @Prop({ default: Periods.DAY }) currentPeriod!: string;
  @Prop({ default: 0 }) startedAt!: number;
  @Prop({ default: () => ({ day: 4, week: 1, month: 1, year: 1 }) }) customApproximationValue!: ApproximationValue;
  @Prop() onMouseMove?: ChartClickCallbackFunction;
  @Getter('measurements/measurements') measurements!: IMeasurements;
  @Action('devices/updateDevice') updateDevice!: (data: {device: ChartData; skipReport: boolean}) => void;

  chart: any = null;
  localChartData: any = null;
  intervalTimer: any = null;
  chartContainerWidth = '100%';
  overflow = 'hidden';
  isChartInitDone = false;
  lineOption: 'default' | 'area' | 'line' = 'default';
  hasStackingOptionsChanged = false;
  hasLineOptionChanged = false;

  @Watch('chart.loadingError', { deep: true })
  handleError(val: boolean) {
    if (val) {
      this.$emit('onDataLoaded');
    }
  }

  @Watch('$vuetify.theme.dark')
  handleThemeChange(val: boolean) {
    this.chart?.setIsDarkTheme(val);
    const fistSeries: any = this.chartData.data.chartOptions[0];
    // if custom color is selected this handles the Theme change in order for the correct colors to be displayed
    if (fistSeries.seriesColor !== undefined) {
      let colorArray: any = [];
      this.chartData.data.chartOptions.forEach((element: any) => {
        colorArray.push(element.seriesColor);
      });
      colorArray = ChartColors.colorsByTheme(colorArray, val);
      this.chart?.setChartColors(colorArray);
    } else {
      this.chart?.setChartColors(this.chartColors);
    }
    this.chart?.onThemeChange(
      this.$vuetify.theme.currentTheme.deviceBackground?.toString() ?? '#ffffff'
    );
  }
  get chartColors() {
    return ChartColors.colors(this.$vuetify.theme.dark);
  }

  get chartStyles() {
    return {
      width: this.chartContainerWidth,
      height: `${this.chartHeight}px`,
      margin: '0 auto',
    };
  }

  // fullscreen logic
  get fullScreenNavigationButtons(): any {
    return {
      contextButton: {
        enabled: false,
      },
      yearButton: {
        text: this.$t('uiComponents.chartNavigation.YEAR'),
        onclick: async () => {
          await this.handleFullScreenNavigationButton(Periods.YEAR);
        },
        theme: { width: 50 },
      },
      monthButton: {
        text: this.$t('uiComponents.chartNavigation.MONTH'),
        onclick: async () => {
          await this.handleFullScreenNavigationButton(Periods.MONTH);
        },
        theme: { width: 50 },
      },
      weekButton: {
        text: this.$t('uiComponents.chartNavigation.WEEK'),
        onclick: async () => {
          await this.handleFullScreenNavigationButton(Periods.WEEK);
        },
        theme: { width: 50 },
      },
      dayButton: {
        text: this.$t('uiComponents.chartNavigation.DAY'),
        onclick: async () => {
          await this.handleFullScreenNavigationButton(Periods.DAY);
        },
        theme: { width: 50 },
      },
      hourButton: {
        text: this.$t('uiComponents.chartNavigation.HOUR'),
        onclick: async () => {
          await this.handleFullScreenNavigationButton(Periods.HOUR);
        },
        theme: { width: 50 },
      },
      liveButton: {
        text: this.$t('uiComponents.chartNavigation.LIVE'),
        onclick: async () => {
          clearInterval(this.intervalTimer);
          await this.chart?.switchPeriodAndLoad(Periods.LIVE, Vue.prototype.$keycloak.token);
          await this.$emit('handlePeriodInFullScreenView', Periods.LIVE);
          this.livePeriod();
          this.updateFullScreenNavigation(this.currentFullScreenNavigationButtonsList, true);
        },
        theme: { width: 50 },
      },
      prevHourButton: {
        text: 'prev hour',
        onclick: async () => {
          this.handlePrevNextButtonCalendar('prev', 'hour');
          this.$emit('handlePeriodInFullScreenView', Periods.DAY);
        },
        theme: { width: 100 },
      },
      nextHourButton: {
        text: 'next hour',
        onclick: async () => {
          this.handlePrevNextButtonCalendar('next', 'hour');
          this.$emit('handlePeriodInFullScreenView', Periods.DAY);
        },
        theme: { width: 100 },
      },
      prevDayButton: {
        text: 'prev day',
        onclick: async () => {
          this.handlePrevNextButtonCalendar('prev', 'day');
          this.$emit('handlePeriodInFullScreenView', Periods.DAY);
        },
        theme: { width: 100 },
      },
      nextDayButton: {
        text: 'next day',
        onclick: async () => {
          this.handlePrevNextButtonCalendar('next', 'day');
          this.$emit('handlePeriodInFullScreenView', Periods.DAY);
        },
        theme: { width: 100 },
      },
      prevWeekButton: {
        text: 'prev week',
        onclick: async () => {
          this.handlePrevNextButtonCalendar('prev', 'week');
          this.$emit('handlePeriodInFullScreenView', Periods.DAY);
        },
        theme: { width: 100 },
      },
      nextWeekButton: {
        text: 'next week',
        onclick: async () => {
          this.handlePrevNextButtonCalendar('next', 'week');
          this.$emit('handlePeriodInFullScreenView', Periods.DAY);
        },
        theme: { width: 100 },
      },
      prevMonthButton: {
        text: 'prev month',
        onclick: async () => {
          this.handlePrevNextButtonCalendar('prev', 'month');
          this.$emit('handlePeriodInFullScreenView', Periods.DAY);
        },
        theme: { width: 100 },
      },
      nextMonthButton: {
        text: 'next month',
        onclick: async () => {
          this.handlePrevNextButtonCalendar('next', 'month');
          this.$emit('handlePeriodInFullScreenView', Periods.DAY);
        },
        theme: { width: 100 },
      },
      prevYearButton: {
        text: 'prev year',
        onclick: async () => {
          this.handlePrevNextButtonCalendar('prev', 'year');
          this.$emit('handlePeriodInFullScreenView', Periods.DAY);
        },
        theme: { width: 100 },
      },
      nextYearButton: {
        text: 'next year',
        onclick: async () => {
          this.handlePrevNextButtonCalendar('next', 'year');
          this.$emit('handlePeriodInFullScreenView', Periods.DAY);
        },
        theme: { width: 100 },
      },
    };
  }
  get fullScreenNavigationButtonsSchemas(): any {
    return {
      hour: ['prevHourButton', 'nextHourButton', 'liveButton', 'hourButton', 'dayButton', 'weekButton', 'monthButton', 'yearButton', 'contextButton'],
      day: ['prevDayButton', 'nextDayButton', 'liveButton', 'hourButton', 'dayButton', 'weekButton', 'monthButton', 'yearButton', 'contextButton'],
      week: ['prevWeekButton', 'nextWeekButton', 'liveButton', 'hourButton', 'dayButton', 'weekButton', 'monthButton', 'yearButton', 'contextButton'],
      month: ['prevMonthButton', 'nextMonthButton', 'liveButton', 'hourButton', 'dayButton', 'weekButton', 'monthButton', 'yearButton', 'contextButton'],
      year: ['prevYearButton', 'nextYearButton', 'liveButton', 'hourButton', 'dayButton', 'weekButton', 'monthButton', 'yearButton', 'contextButton'],
      live: ['liveButton', 'hourButton', 'dayButton', 'weekButton', 'monthButton', 'yearButton', 'contextButton'],
    };
  }
  get currentFullScreenNavigationButtonsList() {
    const list: any = [...this.fullScreenNavigationButtonsSchemas[this.currentPeriod]].reverse();
    let res = {};
    list.forEach((period: string) => {
      res = { ...res, [period]: this.fullScreenNavigationButtons[period] };
    });
    return res;
  }
  async handlePrevNextButtonCalendar(direction: string, currentPeriod: string) {
    clearInterval(this.intervalTimer);
    const currentPeriodStart = this.chart.chartBounds.start;
    const newDate = changeDate(currentPeriodStart, direction, currentPeriod);
    const now = toSeconds(new Date().getTime());
    const { start } = getDateBoundsByPeriod(toSeconds(this.startedAt), currentPeriod);

    if (start <= newDate && newDate < now) {
      await this.chart?.loadCalendarRange(newDate, Vue.prototype.$keycloak.token);
    }
  }
  async handleFullScreenNavigationButton(period: string) {
    clearInterval(this.intervalTimer);
    await this.chart?.switchPeriodAndLoad(period, Vue.prototype.$keycloak.token);
    await this.$emit('handlePeriodInFullScreenView', period);
    this.updateFullScreenNavigation(this.currentFullScreenNavigationButtonsList, true);
    this.$emit('handleDataToExport', this.chart?.dataToExport);
  }
  handleFullScreen() {
    this.chart?.handleFullScreen();
  }
  updateFullScreenNavigation(navigation: any, exportingEnabled: boolean) {
    // before add new buttons list clean old buttons, found better way to clean buttons
    (this.chart?.chart as any).update({
      navigation: {
        buttonOptions: {
          enabled: true,
          theme: {
            style: {
              color: this.$vuetify.theme.dark ? 'white' : 'black',
            },
            fill: this.$vuetify.theme.currentTheme.deviceBackground,
            states: {
              hover: {
                fill: this.$vuetify.theme.dark ? '#535c7a' : '#dceffc',
              },
              fill: this.$vuetify.theme.currentTheme.deviceBackground,
              states: {
                hover: {
                  fill: this.$vuetify.theme.dark ? '#535c7a' : '#dceffc',
                },
              },
            },
          },
        },
      },
      exporting: {
        buttons: null,
        enabled: false,
      },
    });
    // new buttons list
    (this.chart?.chart as any).update({
      navigation: {
        buttonOptions: {
          enabled: true,
          theme: {
            style: {
              color: this.$vuetify.theme.dark ? 'white' : 'black',
            },
            fill: this.$vuetify.theme.currentTheme.deviceBackground,
            states: {
              hover: {
                fill: this.$vuetify.theme.dark ? '#535c7a' : '#dceffc',
              },
              fill: this.$vuetify.theme.currentTheme.deviceBackground,
              states: {
                hover: {
                  fill: this.$vuetify.theme.dark ? '#535c7a' : '#dceffc',
                },
              },
            },
          },
        },
      },
      exporting: {
        buttons: navigation,
        enabled: exportingEnabled,
      },
    });
  }
  onFullScreenChange() {
    if ((this.chart as any).isFullScreenOpen) this.updateFullScreenNavigation(null, false);
    else this.updateFullScreenNavigation(this.currentFullScreenNavigationButtonsList, true);
  }

  toggleStacking() {
    this.chart?.toggleStacking();
    this.hasStackingOptionsChanged = true;
  }

  /**
   * @description - This function is used to toggle between line and area chart
   */
  async toggleLineOptionChange() {
    switch (this.lineOption) {
      case 'default':
        this.lineOption = 'area';
        break;
      case 'area':
        this.lineOption = 'line';
        break;
      default:
        this.lineOption = 'default';
    }
    this.hasLineOptionChanged = true;
    this.updateLineData();
  }

  updateLineData() {
    let chartDataUpdated: any;
    if (this.lineOption === 'default') {
      // set default chart options mapped by the user
      chartDataUpdated = [...this.chartData.data.chartOptions];
    } else {
      const targetType = this.lineOption === 'area' ? 'area' : 'line';
      chartDataUpdated = this.localChartData.data.chartOptions.map((chartOption: ChartOption) => {
        const chartOptionCopy = _.cloneDeep(chartOption);
        if (chartOptionCopy.seriesType !== 'Threshold') {
          chartOptionCopy.type = targetType;
        }
        return chartOptionCopy;
      });
    }

    // update chart series with updated types
    this.chart.chart.series.forEach((series: any, index: number) => {
      series.update({
        type: chartDataUpdated[index].type,
      });
    });
  }

  chartClickHandler() {
    this.chart?.updateTheme();
  }

  livePeriod() {
    this.intervalTimer = setInterval(() => {
      const variableValues = getChartOptions(this.localChartData).map((optionsItem: any, index: number) => {
        if (this.localChartData.data.chartOptions[index].seriesType === 'Threshold') {
          return null;
        } else if (this.localChartData.data.mappings || this.localChartData.data.chartOptions[index].seriesType === 'View') {
          // view
          // either old chart, or new chart of type view
          const variable = this.localChartData.data.mappings
            ? Object.values(this.localChartData.data.mappings)[index]
            : optionsItem.var;
          // view
          return this.measurements[variable];
        } else {
          // calculation
          return calculate(optionsItem.calculation.expression, this.measurements);
        }
      });
      this.chart?.liveUpdateAllVariables(variableValues);
    }, 1000);
  }

  initThresholdAxis() {
    if (!this.isThresholdLine) return;
    const thresholdAxis = [{
      var: '',
      unit: '',
      name: '',
      scaling: { min: 0, max: 100 },
      agg: 'avg',
      type: 'line',
      seriesType: 'Threshold',
    }];
    (this.localChartData.data as any).chartOptions = [...this.localChartData.data.chartOptions, ...thresholdAxis];
  }

  getChartObject() {
    return this.chart;
  }

  async handlePeriod(date: number) {
    await this.chart?.loadCalendarRange(date, Vue.prototype.$keycloak.token);
  }

  async renderChart() {
    await this.chart?.drawThreshold((this.localChartData.data as any)?.threshold);
    await this.chart?.switchPeriodAndLoad(this.currentPeriod, Vue.prototype.$keycloak.token);
    this.$emit('chartLoaded');
    this.$emit('handleDataToExport', this.chart?.dataToExport);
  }

  async initChart() {
    await this.chart?.setLocale(this.$i18n.locale);
    await this.chart?.buildChart(this.$refs.chart as HTMLElement);
    await this.renderChart();

    if (this.currentPeriod === Periods.LIVE) this.livePeriod();
    this.$emit('chartInitDone', true);

    if (this.chartData.data.selectedLineOptions) {
      this.lineOption = this.chartData.data.selectedLineOptions;
      this.updateLineData();
    }
  }

  created() {
    this.localChartData = _.cloneDeep(this.chartData);
    this.initThresholdAxis();
    this.chart = new LynusChart(
      this.localChartData,
      this.$route.params.id,
      envApi,
      this.$vuetify.theme.dark,
      this.startedAt,
      this.$vuetify.theme.currentTheme.deviceBackground?.toString() ?? '#ffffff',
      this.localChartData.data.selectedStackingOptions,
      periodConfigurations,
      this.chartColors,
      this.customApproximationValue,
      this.onMouseMove,
      this.onDataLoaded,
    );

    this.initChart();
    this.isChartInitDone = true;
  }
  async beforeDestroy() {
    const chartDataCopy = _.cloneDeep(this.chartData);
    if (this.hasStackingOptionsChanged) {
      // if the stacking options for charts have changed, update the device
      chartDataCopy.data.selectedStackingOptions = this.chart.stackingOptions;
      this.updateDevice({ device: chartDataCopy, skipReport: true });
    }
    if (this.hasLineOptionChanged) {
      // if the line options for charts have changed, update the device
      chartDataCopy.data.selectedLineOptions = this.lineOption;
      this.updateDevice({ device: chartDataCopy, skipReport: true });
    }

    (this.$refs.chart as any).removeEventListener('fullscreenchange', () => this.onFullScreenChange());
    clearInterval(this.intervalTimer);
  }
  onDataLoaded(data: any, chart: any, period: any) {
    this.$emit('onDataLoaded', data, chart, period);
  }
}
