import api from '@/services/api';
import { IReport } from '@/types/report.types';

export default {
  async fetchReports(projectId: string) {
    return api.fetch(`/projects/${projectId}/reports`, 'GET');
  },

  async createReport(projectId: string, report: Partial<IReport>) {
    return api.fetch(`/projects/${projectId}/reports`, 'POST', report);
  },

  async updateReport(projectId: string, reportId: string, report: Partial<IReport>) {
    return api.fetch(`/projects/${projectId}/reports/${reportId}`, 'PUT', report);
  },

  async deleteReport(projectId: string, reportId: string) {
    return api.fetch(`/projects/${projectId}/reports/${reportId}`, 'DELETE');
  },

  async printReport(
    projectId: string,
    reportId: string,
    format: string,
    start: number,
    end: number,
    responseType: 'arraybuffer' | undefined,
  ) {
    return api.fetch(
      `/projects/${projectId}/reports/${reportId}?format=${format}&start=${start}&end=${end}`,
      'GET',
      undefined,
      undefined,
      responseType,
    );
  },

  async triggerReportActions(
    projectId: string,
    reportId: string,
    format: 'json' | 'csv' | 'pdf' | 'xml',
    start: number,
    end: number,
    report: IReport,
  ) {
    return api.fetch(
      `/projects/${projectId}/reports/${reportId}?action=true&start=${start}&end=${end}&format=${format}`,
      'GET',
      report,
    );
  },
};
