
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SystemModalWindowView from '@/ui/components/devices/components/EnergyParts/SystemModalWindowView.vue';
import RotatingBacklight from '@/ui/components/devices/components/EnergyParts/RotatingBacklight.vue';
import InstanceView from '@/ui/components/devices/components/EnergyParts/EMS/components/systems/ChargeStationSystem/Forecast/InstanceView.vue';
import TimeSlider from '@/ui/components/devices/components/EnergyParts/TimeSlider.vue';
import AnimatedLine from '@/ui/components/devices/components/EnergyParts/AnimatedLine/index.vue';
import Preview from '@/ui/components/devices/components/EnergyParts/Preview.vue';
import CentralEndpoint from '@/ui/components/devices/components/EnergyParts/CentralEndpoint.vue';
import NavigationTab from '@/ui/components/modals/components/NavigationTab.vue';
import { State } from 'vuex-class';
import { IVariablesState } from '@/store/modules/measurements/types';
import { throttle } from 'lodash';
import { generateForecastSystems } from '@/ui/components/devices/components/EnergyParts/EMS/utils';

@Component({
  components: {
    SystemModalWindowView,
    AnimatedLine,
    RotatingBacklight,
    InstanceView,
    TimeSlider,
    Preview,
    CentralEndpoint,
    NavigationTab,
  },
})
export default class Forecast extends Vue {
  @Prop({ default: null }) systemData!: any;
  @Prop({ default: null }) allSystemsForecastData!: any;
  @Prop({ default: null }) systemCount!: number;
  @Prop({ default: 0 }) groupSliderState!: number;
  @Prop({ default: null }) canvasSize!: any;
  @Prop({ default: null }) canvasCenter!: any;
  @Prop({ default: null }) lineData!: any;
  @Prop({ default: false }) lineReady!: boolean;
  @Prop({ default: null }) systemForecastTargetPower !: any;
  @State('measurements') measurementsState!: IVariablesState;

  instancesCanvasSize: any = null
  instancesCanvasCenter: any = null
  instancesCanvasLines: any = {}

  systemSlider = 0
  showOutputValue = false;

  tab = 0
  isTabContentVisible = false

  @Watch('tab')
  async onTabChange(val: number) {
    this.isTabContentVisible = false;
    await this.$nextTick();
    this.getInstancesCoords();
    this.isTabContentVisible = true;
  }

  get measurements() {
    return this.measurementsState.measurements;
  }

  get instancesTopLine() {
    return Object.keys(this.systemData).slice(0, 5);
  }
  get instancesBottomLine() {
    return Object.keys(this.systemData).slice(5, 10);
  }

  get systemsForecastData() {
    const data = this.showOutputValue ? this.allSystemsForecastData : this.systemForecastTargetPower;
    return generateForecastSystems(Object.keys(this.systemData), data);
  }
  get sumSystemsForecastDataFiltered() {
    return Object.values(this.systemsForecastData).map((system: any) => system[this.groupSliderState] || [null, null]);
  }
  get sumForecast() {
    const values = Object.values(this.sumSystemsForecastDataFiltered).map((el: any) => el[1]);
    const checkEmpty = values.filter((el: any) => typeof el === 'number');
    if (checkEmpty.length) {
      const num: any = values.reduce((a: number, b: number) => a + b);
      return +num.toFixed(2);
    } else {
      return 0;
    }
  }

  get allSystemsArray() {
    return Object.keys(this.systemData);
  }
  get numberOfPages() {
    if (this.allSystemsArray.length > 10) {
      const clone: any = [...this.allSystemsArray];
      const arr: any = [];
      while (clone.length) {
        arr.push(clone.splice(0, 10));
      }
      return arr;
    } else {
      return [this.allSystemsArray];
    }
  }
  get defineTopLine() {
    return this.numberOfPages[this.tab].slice(0, 5);
  }
  get defineBottomLine() {
    return this.numberOfPages[this.tab].slice(5, 10);
  }

  get animatedLineMovement() {
    return this.sumForecast > 0;
  }
  get animatedLineActive() {
    return !!this.sumForecast && this.showOutputValue && this.sumForecast > 0;
  }

  get timeSliderInitArr() {
    if (this.systemsForecastData) {
      return Object.values(this.systemsForecastData)[0];
    }
    return [];
  }

  /**
   * Return system instance values relative to the selected value on the slider
   * @return {object}
   */
  get systemsForecastDataFiltered() {
    let obj = {};
    Object.entries(this.systemsForecastData).forEach((system: any) => {
      const res = system[1][this.systemSlider] || [null, null];
      obj = { ...obj, ...{ [system[0]]: res } };
    });
    return obj;
  }

  /**
   * Load line if all coords and sizes ready
   * @return {boolean} load line status
   */
  get isDataForInstancesAnimatedLineReady() {
    return !!this.instancesCanvasSize && !!this.instancesCanvasCenter && !!Object.values(this.instancesCanvasLines);
  }

  /**
   * Creates range for navigation tab name
   * @param {number} page index of current page
   * @param {number} lastPage index of last page in list
   */
  defineTabTitle(page: number, lastPage: number) {
    const start: any = page * 10 - 9;
    const end: any = page * 10;
    const isCurrentPageLast: boolean = page === lastPage;
    const allSystemsCount: any = this.allSystemsArray?.length;
    return `${start} - ${isCurrentPageLast ? allSystemsCount : end}`;
  }

  /**
   * Defines coordinates of systems, Central Energy Point.
   * Use vm.$refs to retrieve references to elements.
   * Then it goes through the list of references and creates an options object for each one.
   */
  getInstancesCoords() {
    const central: any = this.$refs.instancesCentralEndpoint;
    const actualViewRef: any = this.$refs.instancesCanvas;
    this.instancesCanvasSize = { width: actualViewRef?.clientWidth, height: actualViewRef?.clientHeight };
    this.instancesCanvasCenter = {
      x: central.offsetLeft + central.offsetWidth / 2,
      y: central.offsetTop + central.offsetHeight / 2,
      w: central.offsetWidth,
      h: central.offsetHeight,
    };

    [...this.defineTopLine, ...this.defineBottomLine].forEach((system: any) => {
      const target: any = this.$refs[system];
      if (!target.length) return;

      this.instancesCanvasLines[system] = {
        key: system,
        x: target[0].offsetLeft + target[0].offsetWidth / 2,
        y: target[0].offsetTop + target[0].offsetHeight / 2,
        w: target[0].offsetWidth,
        h: target[0].offsetHeight,
      };
    });
  }
  getInstancesCoordsThrottle = throttle(this.getInstancesCoords, 500)

  /**
   * When you open the system grid window,
   * get the current coordinates of the systems, Central Energy Point.
   * Define sizes.
   * @param {boolean} isContentVisible system grid visible status
   */
  async handleSystemModalWindowContent(isContentVisible: boolean) {
    if (isContentVisible) {
      this.tab = 0;
      this.isTabContentVisible = true;
      await this.$nextTick();
      window.addEventListener('resize', this.getInstancesCoordsThrottle);
      this.getInstancesCoords();
    } else {
      window.removeEventListener('resize', this.getInstancesCoordsThrottle);
    }
    this.systemSlider = this.groupSliderState;
  }

  created() {
    let res = Object.values(this.systemData).map((item: any) => {
      // check if item.power variable has value
      return item.power ? this.measurements.get(item.power) : null;
    });
    res = res.filter((element: any) => typeof element === 'number'); // if element not a number we dont show it
    this.showOutputValue = !!res.length;
  }
}
