
import { Component, Prop } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';
import BasicControl from '@/ui/components/devices/devices/BasicControl';
import { IProject } from '@/types/project.types';
import { minMaxValidation } from '@/utils/utilsFunctions';
import { getSelectedSystem } from '@/utils/installationWizardUtilsFunctions';
import { IMeasurements } from '@/types/measurements.types';
import { IIncludedSystemsTypes } from '@/types/wizards/installationWizard.types';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { hybridVersionDate, plcVersionDate } from '@/utils/versionManagementUtils';

@Component({
  methods: { minMaxValidation },
  components: {
    ModalWindow,
    InfoTooltip,
  },
})
export default class DeyeProducerComponentSettings extends mixins(Validation) {
  @State('installationWizard') wizardState!: IInstallationWizardState;
  @Prop({ default: {} }) includedSystemsTypes!: IIncludedSystemsTypes;
  @Getter('projects/project') project!: IProject;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('installationWizard/emsLimits') emsLimits!: Record<string, number>;
  @Getter('projects/projectCreationTimestamp') projectCreationTimestamp!: number;
  @Getter('measurements/measurements') measurements!: IMeasurements;
  @Getter('installationWizard/producerOptionsEnabled') producerOptionsEnabled!: boolean;
  @Getter('installationWizard/wasInstallationWizardCompleted') wasInstallationWizardCompleted!: boolean;
  @Mutation('installationWizard/setEMSSystemCount') setEMSSystemCount!: ({
    system,
    count,
  }: any) => void;
  @Mutation('installationWizard/setEnergyViewSystemCount') setEnergyViewSystemCount!: ({
    system,
    count,
  }: any) => void;
  @Mutation('installationWizard/setACPVSystemCount') setACPVSystemCount!: (data: number) => void;
  @Mutation('installationWizard/setACPVSystemSelection') setACPVSystemSelection!: (data: boolean) => void;
  @Mutation('installationWizard/setProducerOptionsEnabled') setProducerOptionsEnabled!: (data: boolean) => void;
  @Getter('installationWizard/generatorsOnGeneratorConnections') generatorsOnGeneratorConnection!: {
    isSelected: boolean;
    count: number;
    definition: [];
  };
  @Getter('installationWizard/ACPV') ACPV!: {
    isSelected: boolean;
    count: number;
  };
  @Mutation('installationWizard/handleIncludedSystemsTypesSystemDefinitionProps') handleIncludedSystemsTypesSystemDefinitionProps!: (
    payload: { systemName: string; systemIndex: number; prop: string; value: any },
  ) => void;
  @Mutation('installationWizard/handleIncludedSystemsTypesSystem') handleIncludedSystemsTypesSystem!: (
    { system, key, data }: { system: keyof IIncludedSystemsTypes; key: string; data: any }
  ) => void;

  rerenderKey = 0;
  valid = false;
  doesHover = false;
  generatorPower = 500;
  optionsEnabled = false;

  localGeneratorsOnGeneratorConnection: any = null;
  localACPV: any = null;
  localCHP: any = null;
  selectedSystem = 'generator'
  previousSystem = 'generator';

  DEFINITIONS: any = {
    generator: {
      power: 0,
      connectWithLine: '',
    },
    chp: {},
  };

  get isMultipleChpVersion() {
    return plcVersionDate(this.project).getTime() > hybridVersionDate.getTime();
  }

  get availableItems() {
    return [
      {
        text: this.$t('installationWizard.defineComponents.componentsPage.systems.generator.title'),
        value: 'generator',
      },
      {
        text: this.$t('installationWizard.defineComponents.componentsPage.systems.ac_pv.title'),
        value: 'ac_pv',
      },
      {
        text: this.$t('installationWizard.defineComponents.componentsPage.systems.chp.title'),
        value: 'chp',
        disabled: this.wizardState.stringInverterCount > 0,
      },
    ];
  }

  async handleSelect(system: string) {
    if (system === '') return;
    this.selectedSystem = system;
    this.selectProducer();
    this.previousSystem = this.selectedSystem;

    await this.$nextTick();
    this.validate();
  }

  handleChpCountChange(count: number) {
    // add the definition for chp to included systems the amount of times the chp count is set
    const definition = this.DEFINITIONS.chp;
    this.handleIncludedSystemsTypesSystem({
      system: 'chp',
      key: 'definition',
      data: Array(count).fill(definition),
    });
    this.handleIncludedSystemsTypesSystem({
      system: 'chp',
      key: 'count',
      data: count,
    });

    // update count in energy visualization
    this.setEMSSystemCount({ system: 'chp', count });
    this.setEnergyViewSystemCount({ system: 'chp', count });
    this.$nextTick();
    this.$emit('reRender');
  }

  handleGeneratorOptionsEnabled(checked: boolean, init = false) {
    if (!checked) this.clearAll();
    else {
      if (!init) this.handleSelect(this.isSolarmax ? 'ac_pv' : 'generator');
    }
    this.setProducerOptionsEnabled(checked);
    this.$nextTick();
    this.$emit('reRender');
  }

  clearAll() {
    const allProducerSystems = ['generator', 'ac_pv', 'chp'];
    allProducerSystems.forEach((system) => {
      const systemHasDefinitions = system !== 'ac_pv';
      this.selectSystem(system, false, systemHasDefinitions);
    });
  }

  validate() {
    (this.$refs.form as any).validate();
    this.$emit('deyeProducerValidationStatus', { system: 'deyeProducer', status: this.valid });
  }

  isSystemSelected(system: string) {
    return this.selectedSystem === system;
  }

  async handleGeneratorPowerChange(power: string) {
    await this.handleIncludedSystemsTypesSystemDefinitionProps({
      systemName: 'generator',
      systemIndex: 0,
      prop: 'power',
      value: Number(power),
    });
    await this.$nextTick();
    this.validate();
  }

  get generatorsLimit() {
    return {
      min: 0,
      max: 1,
      minPower: 0,
      maxPower: 100000,
    };
  }

  get chpLimit() {
    return {
      min: 0,
      max: this.emsLimits.chp,
    };
  }

  created() {
    this.localGeneratorsOnGeneratorConnection = this.generatorsOnGeneratorConnection;
    this.localACPV = this.ACPV;
    this.localCHP = this.includedSystemsTypes.chp;

    const controllerMappingsCopy = this.wizardState.emsDevice.data.meta.controllerMappings;
    if (this.isSolarmax) {
      this.selectedSystem = 'ac_pv';
    } else {
      this.selectedSystem = getSelectedSystem(controllerMappingsCopy, ['generator', 'ac_pv', 'chp'], 'ac_pv');
      this.selectedSystem = this.producerOptionsEnabled ? getSelectedSystem(controllerMappingsCopy, ['generator', 'ac_pv', 'chp'], 'ac_pv') : '';
    }
    this.previousSystem = this.selectedSystem;

    this.optionsEnabled = (this.producerOptionsEnabled || (!this.isSolarmax && this.selectedSystem !== 'generator')) && this.selectedSystem !== '';
    this.handleGeneratorOptionsEnabled(this.optionsEnabled, true);
  }

  async mounted() {
    switch (this.selectedSystem) {
      case ('generator'):
        this.defineGenerators();
        if (this.wasInstallationWizardCompleted) {
          const value = this.measurements['prgEnergy.fbI.dwPowerOfGen'];
          this.generatorPower = typeof value === 'string' ? parseInt(value, 10) : value;
        }
        break;
      case ('chp'):
        this.defineCHP();
        break;
      case ('ac_pv'):
        this.setACPVSystemCount(1);
        this.setACPVSystemSelection(true);
    }

    await this.$nextTick();
    this.validate();
  }

  defineCHP() {
    this.localCHP.isSelected = true;
    this.setEMSSystemCount({
      system: 'chp',
      count: this.includedSystemsTypes.chp.definition.length ?? 1,
    });
    this.setEnergyViewSystemCount({
      system: 'chp',
      count: this.includedSystemsTypes.chp.definition.length ?? 1,
    });
  }

  defineGenerators() {
    if (this.localGeneratorsOnGeneratorConnection.definition.length > 0) {
      this.localGeneratorsOnGeneratorConnection.isSelected = true;
      this.generatorPower = this.localGeneratorsOnGeneratorConnection.definition[0].power;
      this.setEMSSystemCount({ system: 'generator', count: 1 });
      this.setEnergyViewSystemCount({ system: 'generator', count: 1 });
    } else {
      this.localGeneratorsOnGeneratorConnection = this.includedSystemsTypes.generator;
      this.setEMSSystemCount({ system: 'generator', count: 1 });
      this.setEnergyViewSystemCount({ system: 'generator', count: 1 });
    }
  }

  selectSystem(system: string, value: any, hasDefinitions = true) {
    if (system === '') return;
    let count = value ? 1 : 0;
    if (system === 'chp' && value && this.includedSystemsTypes.chp.definition.length !== 0) count = this.includedSystemsTypes.chp.definition.length;
    if (system !== 'ac_pv') {
      if (hasDefinitions) {
        this.handleIncludedSystemsTypesSystem({
          system: system as keyof IIncludedSystemsTypes,
          key: 'definition',
          data: value ? [
            { ...this.DEFINITIONS[system] },
          ] : [],
        });
      }
      this.handleIncludedSystemsTypesSystem({
        system: system as keyof IIncludedSystemsTypes,
        key: 'isSelected',
        data: value,
      });
      this.handleIncludedSystemsTypesSystem({
        system: system as keyof IIncludedSystemsTypes,
        key: 'count',
        data: count,
      });
      this.setEMSSystemCount({ system, count });
      this.setEnergyViewSystemCount({ system, count });
    } else {
      this.setACPVSystemSelection(value);
      this.setACPVSystemCount(count);
    }
  }

  selectProducer() {
    const selectedSystemHasDefinitions = this.selectedSystem !== 'ac_pv';
    const previousSystemHasDefinitions = this.previousSystem !== 'ac_pv';
    this.selectSystem(this.selectedSystem, true, selectedSystemHasDefinitions);
    if (this.selectedSystem !== this.previousSystem) this.selectSystem(this.previousSystem, false, previousSystemHasDefinitions);
  }
}
