import { ValueUnit } from '@/utils/unit/valueUnit';

export default class Unit {
  constructor(unit: string, conversions?: Map<number, string>) {
    this.unit = unit;
    this.conversions = conversions;
  }

  /// Initial unit value, e.g. "kW"
  unit: string;

  /// Possible conversions of the unit, as a mapping from factor to unit,
  /// e.g. { 1000: "MW" }.
  conversions?: Map<number, string>;

  unitForValue(value?: number): ValueUnit {
    let result = new ValueUnit(value, this.unit);
    if (value) {
      this.conversions?.forEach((thresholdUnit, threshold) => {
        if (value > threshold || value < -threshold) {
          result = new ValueUnit(value * (1 / threshold), thresholdUnit);
        }
      });
    }

    return result;
  }
}
