
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Mutation } from 'vuex-class';
import { cloneDeep } from 'lodash';
import { IPrintReportPayload } from '@/types/report.types';

@Component
export default class PrintReport extends Vue {
  @Prop() report!: any;
  @Mutation('app/setReport') setReport!: (payload: any) => void;
  @Action('report/printReport') printReport!: (payload: IPrintReportPayload) => Promise<any>;

  printReportLoader = false;
  printFormat = '';
  printStartDate = '';
  printEndDate = '';
  maxDateCalendar = '';

  get formatOptions() {
    return this.report.type === 'zev'
      ? [
        { state: 'pdf', abbr: 'pdf' },
        { state: 'xml', abbr: 'xml' },
      ] : [
        { state: 'json', abbr: 'json' },
        { state: 'pdf', abbr: 'pdf' },
        { state: 'csv', abbr: 'csv' },
      ];
  }

  get isPrintDatesValid() {
    return !!this.printStartDate && !!this.printEndDate && !!this.printFormat;
  }

  updateMaxDateCalendar() {
    const yourDate = new Date();
    let currentTimeStamp: any = Date.now();
    const timeZoneOffset = (yourDate.getTimezoneOffset() * -1) / 60;
    currentTimeStamp += (timeZoneOffset * 3600000);

    this.maxDateCalendar = new Date(currentTimeStamp).toISOString().split('.')[0] as string;
  }

  prepareDataStringFileType(format: string, data: any) {
    switch (format) {
      case 'json':
        return {
          dataStr: `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data))}`,
          fileType: format,
        };
      case 'csv':
        return {
          dataStr: `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`,
          fileType: format,
        };
      case 'pdf': {
        const file = new Blob([data]);
        return {
          dataStr: URL.createObjectURL(file),
          fileType: format,
        };
      }
      case 'xml':
        return {
          dataStr: `data:text/xml;charset=utf-8,${encodeURIComponent(data)}`,
          fileType: format,
        };
      default: return { dataStr: '', fileType: '' };
    }
  }

  async downloadReport() {
    this.printReportLoader = true;
    const dateStart = new Date(this.printStartDate);
    const dateEnd = new Date(this.printEndDate);
    const unixStart = Math.trunc(dateStart.getTime() / 1000);
    const unixEnd = Math.trunc(dateEnd.getTime() / 1000);

    const response = await this.printReport({
      project_id: this.$route.params.id,
      report_id: this.report.id,
      format: this.printFormat,
      start: unixStart,
      end: unixEnd,
    });

    if (this.printFormat === 'pdf') {
      const buffer = cloneDeep(response);
      let bufferContent = new TextDecoder().decode(buffer);
      bufferContent = bufferContent.replace(/\s/g, '');
      const isNoData = bufferContent === 'nodatafortimeperiod';
      if (isNoData) {
        this.setReport({
          type: 'info',
          message: this.$t('modals.manageReport.noDataInFile') as string,
          value: true,
        });
        this.printReportLoader = false;
        return;
      }
    }

    // creates link to download report
    const downloadAnchorNode = document.createElement('a');
    const { dataStr, fileType } = this.prepareDataStringFileType(this.printFormat, response);

    // add attributes for download link
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', `${this.report.name}.${fileType}`);
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();

    this.printReportLoader = false;
  }

  created() {
    this.updateMaxDateCalendar();
  }
}
