
import { Component, Prop } from 'vue-property-decorator';
import BasicControl from '@/ui/components/devices/devices/BasicControl';

/**
 * Component that represent RonovatecUpDownButton type for PushButton base control
 */
@Component
export default class RonovatecUpDownButton extends BasicControl { // RonovatecUpDownButton
  @Prop({ default: null }) currentState!: number | null;
  @Prop({ default: 'push_button' }) icon!: string;
  @Prop({ default: 40 }) buttonSize!: string | number;
  @Prop() iconSize?: number | string;

  handleLeave() {
    const buttonComponent = this.$refs['ronovatec-up-down-button'] as Vue;
    const buttonElement = buttonComponent.$el as HTMLElement;
    buttonElement.blur();
    this.$emit('leave');
  }
}
