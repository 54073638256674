
import { Component, Prop } from 'vue-property-decorator';
import InputFieldTextBase from '@/ui/components/devices/devices/base/InputFieldTextBase.vue';
import DeviceLayout from '@/ui/components/devices/components/DeviceLayout.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { IDevice } from '@/types/devices.types';

/**
 * Component that represent VariableTextInputField device
 */
@Component({
  components: {
    InputFieldTextBase,
    DeviceLayout,
  },
})
export default class VariableTextInputField extends mixins(Validation) {
  @Prop() deviceData!: IDevice;

  iconTheme() {
    return { filter: this.$vuetify.theme.dark ? 'brightness(0) invert(1)' : null };
  }
}
