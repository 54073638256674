
import { Vue, Component, Prop } from 'vue-property-decorator';

/**
 * Component that represent Downloads.
 * Used to create different download sources.
 */
@Component
export default class Downloads extends Vue {
  @Prop({ type: Array }) chips!: any[];
  @Prop() fullWidth!: boolean;
  selectedChips = [];
}
