export interface IPilotMappings {
  ctRelationship: number;
  lineConfig: {
    device: { text: string; value: string };
    name: string;
    disabled: boolean;
  }[];
}

export interface ILineConfig {
  device: { text: string; value: string };
  name: string;
  disabled: boolean;
}

export interface Page {
  [key: string]: IMQTTVariable | Function;
}

export interface InstallationWizardVariables {
  [pageName: string]: Page;
}

export enum NavigationDirection {
  backward = 0,
  forward = 1,
}

export enum WizardPath {
  INSTALLATION,
  INSTALLATION_TENANT,
  TENANT_INSTALLATION_NO_BATTERY,
  TENANT,
  STRING_INVERTER,
  TENANT_STRING_INVERTER
}

export interface IWizardPage {
  title: string;
  view: string;
  step: number;
  page: number;
  availableFrom?: number;
  dependencies: string[];
}

export interface IMQTTVariable {
  value: number | string;
  variable: string;
  /**
   * For variables that require feedback from the controller
   * E.g. a slider variable that requires a certain state update from the controller
   * to be interpreted as transferred successfully.
   */
  feedbackVariable?: string;
  // For feedback variables of type boolean, so that the workaround with sending the opposite value can be used
  isBoolean?: boolean;
  // send with minimal delay 200ms
  quickSend?: boolean;
  // set custom timeout in seconds. Enables timer for user to see
  customTimeout?: number;
  // Set custom expected value for feedback variables. Default is the same as the value of the variable
  customExpectedValue?: number;
}

export interface IBatterySystem {
  size: { value: number };
  priority: { value: number };
  reserve: { value: number };
  maximumCharge: { value: number };
  updateTime: { value: number };
  chargeFromGrid: { value: number };
  prioritizedCharging: { value: number };
  minimumChargeState: { value: number };
  switchBackTimeFromEmergencyPower?: { value: number };
  smartMeterType?: { value: number };
  gridMeasurementViaCHINT?: { value: number };
}

export interface IChargeStationSystem {
  min_power: IMQTTVariable;
  priority: IMQTTVariable;
  enable_soc: IMQTTVariable;
  disable_soc: IMQTTVariable;
}
export interface IElectricHeatingElementSystem {
  priority: IMQTTVariable;
  temperature_on: IMQTTVariable;
  temperature_off: IMQTTVariable;
  temperature_max: IMQTTVariable;
  legionella_protection: IMQTTVariable;
  timeEnabled: IMQTTVariable;
  enableSOC: IMQTTVariable;
  disableSOC: IMQTTVariable;
}
export interface IHeatingPumpSystem {
  priority: IMQTTVariable;
  power: IMQTTVariable;
}
export interface IIncludedSystemsTypes {
  battery: {
    isSelected: boolean;
    count: number;
    definition: IIncludedSystemsBatteryDefinition[];
    systems: IBatterySystem[];
  };
  electric_heating: {
    isSelected: boolean;
    count: number;
    definition: IIncludedSystemsElectricHeatingDefinition[];
    systems: IElectricHeatingElementSystem[];
  };
  charge_station: {
    isSelected: boolean;
    count: number;
    definition: IIncludedSystemsChargeStationDefinition[];
    systems: IChargeStationSystem[];
  };
  pv: {
    isSelected: boolean;
    count: number;
    definition: IIncludedSystemsPvDefinition[];
  };
  generator: {
    isSelected: boolean;
    count: number;
    definition: IIncludedSystemsGeneratorDefinition[];
  };
  big_consumer: {
    isSelected: boolean;
    count: number;
    definition: IIncludedSystemsBigConsumerDefinition[];
  };
  heating_pump_consumer: {
    isSelected: boolean;
    count: number;
    definition: IIncludedSystemsHeatingPumpDefinition[];
  };
  heating_pump: {
    isSelected: boolean;
    count: number;
    definition: IIncludedSystemsHeatingPumpDefinition[];
    systems: IHeatingPumpSystem[];
  };
  load_shedding: {
    isSelected: boolean;
    count: number;
    definition: IIncludedSystemsLoadSheddingDefinition[];
  };
  chp: {
    isSelected: boolean;
    count: number;
    definition: any[];
  };
}

export interface IIncludedSystemsBatteryDefinition {
  systemType: string;
  batteryCapacity: string;
  inverterPower: string;
  batteryType: string;
}

export interface IIncludedSystemsElectricHeatingDefinition {
  systemType: string;
  modelType: number;
  title: string;
}

export interface IIncludedSystemsChargeStationDefinition {
  systemType: string;
  connectWithLine: string | number;
  noFreeLoading: boolean;
  webastoType: number;
  occupiedByTwinPoint: boolean;
  title: string;
}

export interface IIncludedSystemsPvDefinition {
  inverter: number;
}

export interface IIncludedSystemsHeatingPumpDefinition {
  connectWithLine: string | number;
  systemType: string;
  power?: string | number;
  priority?: string | number;
  title: string;
}

export interface IIncludedSystemsHeatingPumpConsumerDefinition {
  connectWithLine: string | number;
  powerSGR1?: string | number;
  powerSGR2?: string | number;
  title: string;
}

export interface IIncludedSystemsLoadSheddingDefinition {
  maxBatteryPower: string | number;
  powerConsumer1: string | number;
  powerConsumer2: string | number;
}

export interface IIncludedSystemsBigConsumerDefinition {
  connectWithLine: string | number;
  power: string | number;
  title: string;
}

export interface IIncludedSystemsGeneratorDefinition {
  connectWithLine?: string | number;
  power: string | number;
}

export type ISystemsDefinitionsProps = IMQTTVariable & {
  systemsIndex: number;
  key: string;
}

export type WaitForMQTTFeedbackAction =
  (variableToCheck?: { name: string; sentVariable: string; expectedValue: string | number; isBoolean?: boolean }) => Promise<void>;

export interface IHandleStatusChangeParams {
  system: 'battery' | 'charge_station' | 'electric_heating' | 'deyeConsumer' | 'deyeProducer';
  status: boolean;
}

export interface Definitions {
  pv: any;
  battery: IIncludedSystemsBatteryDefinition;
  charge_station: IIncludedSystemsChargeStationDefinition;
  electric_heating: IIncludedSystemsElectricHeatingDefinition;
  heating_pump: IIncludedSystemsHeatingPumpDefinition;
  heating_pump_consumer: IIncludedSystemsHeatingPumpConsumerDefinition;
  big_consumer: IIncludedSystemsBigConsumerDefinition;
  load_shedding: IIncludedSystemsLoadSheddingDefinition;
  generator: IIncludedSystemsGeneratorDefinition;
  chp: any;
}
export const DEFINITIONS: Definitions = {
  pv: { inverter: 0 },
  battery: { systemType: '', batteryCapacity: '', inverterPower: '', batteryType: '' },
  charge_station: { systemType: '', connectWithLine: '', noFreeLoading: false, occupiedByTwinPoint: false, webastoType: 0, title: '' },
  electric_heating: { systemType: '', modelType: 0, title: '' },
  heating_pump_consumer: { connectWithLine: '', powerSGR1: '', powerSGR2: '', title: '' },
  heating_pump: { connectWithLine: '', systemType: '', power: 0, priority: 2, title: '' },
  big_consumer: { connectWithLine: '', power: '', title: '' },
  load_shedding: { maxBatteryPower: '', powerConsumer1: '', powerConsumer2: '' },
  generator: { power: 0, connectWithLine: '' },
  chp: {},
};
