
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { cloneDeep } from 'lodash';
import ComboboxField from '@/ui/components/modals/components/form/ComboboxField.vue';
import { FormUpdateTracker } from '@/ui/mixins/formUpdateTracker';
import { getDefaultRulesObject } from '@/utils/utilsFunctions';
import { IVariablesState } from '@/store/modules/measurements/types';
import { IRoom } from '@/types/rooms.types';
import { IProject } from '@/types/project.types';
import { IRule } from '@/types/rules.types';
import { IDevice, IDevicesTypes } from '@/types/devices.types';

/**
 * Scheme to create, modify a specific device.
 * Specified in the device type definition in store/devices/devicesTypes.ts
 */
@Component({
  components: {
    ComboboxField,
  },
})
export default class GaugeSchema extends mixins(FormUpdateTracker) {
  @Prop({
    default: () => ({
      name: '',
      data: {
        type: '',
        mappings: {},
        meta: {},
      },
      collection_id: '',
    }),
  }) deviceData!: IDevice;
  @Prop() isEditModal!: boolean;
  @Prop({ default: '' }) activeRoomId!: string;

  @State('measurements') measurementsState!: IVariablesState;
  @Getter('rooms/sortedRoomsByName') sortedRoomsByName!: IRoom[];
  @Getter('devices/devicesTypes') devicesTypes!: IDevicesTypes;
  @Getter('projects/project') project!: IProject;
  @Action('variables/fetchVariables') fetchVariables!: (projectId: string) => Promise<void>;
  @Action('rules/addRules') addRules!: (payload: {
    project_id: string;
    rulesList: IRule[];
  }) => Promise<IRule[]>;
  @Action('rules/deleteRule') deleteRule!: (payload: { project_id: string; rule_id: string }) => Promise<void>;
  @Getter('variables/variablesForComboBox') variablesForComboBox!: [];

  stage = 1
  localDeviceData: IDevice = JSON.parse(JSON.stringify(this.deviceData));
  showOptionalFields = false;

  @Watch('stage')
  onStageChange(val: number) {
    if (val === 2) {
      this.fetchVariables(this.$route.params.id);
    }
  }

  // steps validation
  get nameTypeValidation() {
    return !!this.localDeviceData?.name?.length;
  }

  /**
   * Checks mappings if they not valid
   * @return true if mapping filled, else false
   */
  mappingValidation() {
    if (this.stage === 2) {
      const optionalFields = Object.entries(this.deviceMappings)
        .filter((field: any) => !field[1].optional)
        .map((field: any) => field[0]);
      return optionalFields.map((el: any) => this.localDeviceData.data.mappings[el]).every((el: any) => el);
    } else {
      return false;
    }
  }
  get settingsValidation() {
    return !!this.localDeviceData.collection_id.length;
  }
  get scalingValidation() {
    return +this.localDeviceData.data.meta.scaling.min < +this.localDeviceData.data.meta.scaling.max;
  }

  get deviceSchema() {
    return this.devicesTypes[this.deviceData.data.type];
  }
  get deviceMappings() {
    return this.deviceSchema.mappings;
  }
  get deviceMappingsList() {
    if (this.localDeviceData?.data?.type) {
      return Object.keys(this.deviceSchema.mappings);
    }
    return [];
  }

  /**
   * Goes through device mappings list and create options for every item
   * @return form of mappings options
   */
  initMappingsForDevice() {
    let mappingsSchema: any = {};
    Object.keys(this.devicesTypes[this.deviceData.data.type].mappings).forEach((item: string) => {
      mappingsSchema = { ...mappingsSchema, [item]: '' };
    });
    return mappingsSchema;
  }

  /**
   * Creates rules for device according to ShowEvent_errorWarningState field,
   * save ids of created rules in current device object
   * @param deviceData current device data object
   */
  async addRulesWhenCreateDevice(deviceData: IDevice) {
    if (!deviceData.data.mappings.ShowEvent_errorWarningState.length) return;
    const rulesList: any = getDefaultRulesObject(deviceData.name, deviceData.data.mappings.ShowEvent_errorWarningState, 'Device');
    if (this.project.id) {
      const res = await this.addRules({
        project_id: this.project.id,
        rulesList,
      });
      deviceData.data.meta.warningRule = res[0].id;
      deviceData.data.meta.errorRule = res[1].id;
    }
  }

  /**
   * Replacing existing rules with new ones when ShowEvent_errorWarningState field is changed
   * @param deviceData current device data object
   */
  async addRulesWhenEditDevice(deviceData: IDevice) {
    if (!this.project.id) return;
    const oldErrorWarningVar = this.deviceData.data.mappings.ShowEvent_errorWarningState;
    const newErrorWarningVar = deviceData.data.mappings.ShowEvent_errorWarningState;
    // if errorWarning was changed
    if (oldErrorWarningVar && !newErrorWarningVar) {
      await Promise.all([
        this.deleteRule({
          project_id: this.project.id,
          rule_id: deviceData.data.meta.warningRule,
        }),
        this.deleteRule({
          project_id: this.project.id,
          rule_id: deviceData.data.meta.errorRule,
        }),
      ]);
    }
    if (oldErrorWarningVar !== newErrorWarningVar && newErrorWarningVar) {
      // delete old rules
      if (oldErrorWarningVar.length) {
        await Promise.all([
          this.deleteRule({
            project_id: this.project.id,
            rule_id: deviceData.data.meta.warningRule,
          }),
          this.deleteRule({
            project_id: this.project.id,
            rule_id: deviceData.data.meta.errorRule,
          }),
        ]);
      }
      const rulesList = getDefaultRulesObject(deviceData.name, deviceData.data.mappings.ShowEvent_errorWarningState, 'Device');
      // create new rules
      const res = await this.addRules({ project_id: this.project.id, rulesList });
      const getWarningRule = () => {
        const warningRuleObj: IRule | undefined = res.find((rule: any) => rule.name === `${deviceData.name} Warning Rule`);
        return warningRuleObj?.id;
      };
      const getErrorRule = () => {
        const errorRuleObj: IRule | undefined = res.find((rule: any) => rule.name === `${deviceData.name} Error Rule`);
        return errorRuleObj?.id;
      };
      deviceData.data.meta.warningRule = getWarningRule();
      deviceData.data.meta.errorRule = getErrorRule();
    }
  }

  /**
   * Save device data in data base
   */
  async sendForm() {
    this.$emit('onFormUnchanged', true);
    const copy = cloneDeep(this.localDeviceData);

    // add rules
    if (!this.isEditModal) {
      await this.addRulesWhenCreateDevice(copy);
    } else {
      await this.addRulesWhenEditDevice(copy);
    }

    this.$emit('handleControl', copy);
    this.$emit('closeDialog');
  }

  async created() {
    const copy = cloneDeep(this.deviceData);

    if (this.activeRoomId.length && !this.isEditModal) copy.collection_id = this.activeRoomId;

    if (!copy.data.meta?.scaling) {
      copy.data.meta = {
        scaling: {
          min: null,
          max: null,
        },
        unit: '',
      };
    }

    this.localDeviceData = copy;
    this.localDeviceData.data.mappings = this.initMappingsForDevice();
    this.localDeviceData.data.mappings = { ...this.localDeviceData.data.mappings, ...this.deviceData.data.mappings };

    if (!this.isEditModal) this.dataSnapshot = cloneDeep(this.localDeviceData);
  }
}
