import EventService from '@/services/EventService';
import {
  ActionTree, GetterTree, Module, MutationTree,
} from 'vuex';
import { RootState } from '@/store/types';
import { IEvent } from '@/types/events.types';
import { IEventState } from './types';

const state: IEventState = {
  eventList: [],
  numberOfPages: null,
  eventListFilter: '',
};

const getters: GetterTree<IEventState, RootState> = {
  /**
   * Returns events filtered by eventListFilter
   * @param state
   */
  getEventList(state) {
    return state.eventListFilter
      ? state.eventList.filter((item: IEvent) => Object.values(item).join().toLowerCase().includes(state.eventListFilter))
      : state.eventList;
  },

  /**
   * Returns accepted events
   * @param state
   */
  getIsAllAccepted(state) {
    let counterNotAccepted = 0;
    Object.values(state.eventList).forEach((element: IEvent) => {
      if (element.accepted_at === '' || element.accepted_at === null) {
        counterNotAccepted++;
      }
    });
    return counterNotAccepted !== 0;
  },
};

const mutations: MutationTree<IEventState> = {
  setEventsList(state, events) {
    state.eventList = events;
  },
  setNumberOfPages(state, payload) {
    state.numberOfPages = payload;
  },
  acceptAllEvents(state, payLoad: { formattedDate: string; fullName: string }) {
    state.eventList.forEach((item: IEvent) => {
      if (item.accepted_by === 'ACCEPT') {
        item.accepted_at = payLoad.formattedDate;
        item.accepted_by = payLoad.fullName;
      }
    });
  },
  setEventListFilter(state, value: string) {
    state.eventListFilter = value;
  },
  removeEventFromList(state, payload) {
    state.eventList = state.eventList.filter((event: IEvent) => event.id !== payload.id);
  },
  clean(state) {
    state.eventList = [];
    state.numberOfPages = null;
    state.eventListFilter = '';
  },
};

const actions: ActionTree<IEventState, RootState> = {
  /**
   * Accept selected event
   * @param commit
   * @param state
   * @param rootState
   * @param payLoad
   */
  async acceptOneEvent({ commit, state, rootState }, payLoad: { id: string }) {
    try {
      const acceptedEvent = await EventService.acceptEvent(rootState.projects.projectId as string, payLoad.id);
      commit('removeEventFromList', acceptedEvent);
    } catch (e) {
      commit('setReport', {
        type: 'error',
        message: e.message,
        value: true,
      });
    }
  },

  /**
   * Accepts all events
   * @param commit
   * @param state
   * @param rootState
   * @param payLoad
   */
  async acceptEveryEvent({ commit, state, rootState }) {
    try {
      await EventService.acceptEvents(rootState.projects.projectId as string);
      commit('setEventsList', []);
    } catch (e) {
      commit('setReport', {
        type: 'error',
        message: e.message,
        value: true,
      });
    }
  },

  /**
   * Load events list according to selected page
   * @param commit
   * @param state
   * @param rootState
   * @param payload
   */
  async loadEvents({ commit, state, rootState }, payload: { page: number; bol: boolean }) {
    try {
      const res = await EventService.fetchEvents(rootState.projects.projectId as string, payload.page, payload.bol);
      commit('setEventsList', res.data);
      commit('setNumberOfPages', res.pages);
    } catch (e) {
      commit('setReport', {
        type: 'error',
        message: e.message,
        value: true,
      });
    }
  },
};

export const events: Module<IEventState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
