import { IDevice } from '@/types/devices.types';
import { allEnergyDevicesCircleTypes } from '@/types/energyVisualisation/EnergyCircleType';
import i18n from '@/ui/plugins/i18n';
import installationWizardVariables
  from '@/ui/components/wizards/installationWizard/installationWizardVariables';
import { IMQTTVariable, IBatterySystem } from '@/types/wizards/installationWizard.types';
import store from '@/store';

export function getSelectedSystem(controllerMappingsCopy: Record<string, any>, allSystems: string[], fallback: string) {
  let selectedSystem = '';
  allSystems.forEach((system: string) => {
    if (system === fallback) return;
    if (!controllerMappingsCopy[system]) return controllerMappingsCopy[system] = { count: 0, components: {} };
    const dummyCount = Object.values(controllerMappingsCopy[system].components).filter((component: any) => component.external_energy_measurement).length;
    let amountOfNonConsumerHeatingPumps = 0;
    Object.values((controllerMappingsCopy[system].components)).forEach((component: any) => {
      if (component.power.includes('prgHP.lrPowerHP_')) {
        amountOfNonConsumerHeatingPumps++;
      }
    });
    const subtractValue = system === 'heating_pump' ? amountOfNonConsumerHeatingPumps : 0;
    if ((controllerMappingsCopy[system].count - dummyCount - subtractValue) > 0) {
      selectedSystem = system;
    }
  });
  if (selectedSystem === '') selectedSystem = fallback;
  return selectedSystem;
}

export function resetEnergyDeviceMappings(energyDevice: IDevice, emsSystemsLimits: Record<string, number>, wasInstallationWizardCompleted: boolean, projectSystemType: string, consumerOptionsEnabled: boolean, producerOptionsEnabled: boolean) {
  // filter out informations that we dont need inside the new ems and energyview object
  // to avoid ems and energyview having system that they shouldn't have
  if (projectSystemType === 'AIOS (Azzurro)') {
    const resetToZero = ['heating_pump', 'big_consumer', 'chp', 'generator'];
    allEnergyDevicesCircleTypes.forEach((systemType: string) => {
      if (!energyDevice.data.meta.controllerMappings[systemType]) return energyDevice.data.meta.controllerMappings[systemType] = { count: 0, components: {} };
      if (systemType === 'house') {
        energyDevice.data.meta.controllerMappings[systemType].count = 1;
      } else if (resetToZero.includes(systemType)) {
        energyDevice.data.meta.controllerMappings[systemType].count = 0;
      } else {
        if (systemType !== 'grid') {
          const key = (systemType as 'pv' | 'battery' | 'charge_station' | 'electric_heating');
          if (energyDevice.data.meta.controllerMappings[key]?.count >= emsSystemsLimits[key]) {
            energyDevice.data.meta.controllerMappings[key].count = emsSystemsLimits[key];
          }
        }
      }
    });
  } else {
    const controllerMappingsCopy = { ...energyDevice.data.meta.controllerMappings };

    const allConsumers = ['heating_pump', 'big_consumer', 'load_shedding'];
    const allProducers = ['ac_pv', 'chp', 'generator'];
    const selectedConsumer = consumerOptionsEnabled ? getSelectedSystem(controllerMappingsCopy, allConsumers, 'load_shedding') : '';
    const selectedProducer = producerOptionsEnabled ? getSelectedSystem(controllerMappingsCopy, allProducers, 'ac_pv') : '';

    allEnergyDevicesCircleTypes.forEach((systemType: string) => {
      if (!controllerMappingsCopy[systemType]) controllerMappingsCopy[systemType] = { count: 0, components: {} };
      if (allConsumers.includes(systemType)) {
        if (selectedConsumer !== systemType) {
          if (systemType === 'heating_pump') {
            let indexOfConsumerHeatingPump = 0;
            let hasConsumerHeatingPump = false;
            Object.values(controllerMappingsCopy[systemType].components).forEach((component: any, index: number) => {
              if (!component.power.includes('prgHP.lrPowerHP_') && !(component.external_energy_measurement && component.error === undefined)) {
                indexOfConsumerHeatingPump = index;
                hasConsumerHeatingPump = true;
              }
            });
            if (hasConsumerHeatingPump) {
              controllerMappingsCopy[systemType].count--;
              const newComponents: any = {};
              Object.entries(controllerMappingsCopy[systemType].components).forEach(([key, value]) => {
                if (key !== `heating_pump${indexOfConsumerHeatingPump}`) {
                  newComponents[key] = value;
                }
              });
              controllerMappingsCopy[systemType].components = newComponents;
            }
          } else {
            controllerMappingsCopy[systemType].count = 0;
            controllerMappingsCopy[systemType].components = {};
          }
        }
      }
      if (allProducers.includes(systemType)) {
        if (selectedProducer !== systemType) {
          controllerMappingsCopy[systemType].count = 0;
          controllerMappingsCopy[systemType].components = {};
        }
      }
      /**
       * Add check for PV's that are connected via loggers.
       * These can be removed from the mappings and get added later again after the user has selected the correct logger.
       * This can be done because he logger info we need is saved in the project meta.
       */
      if (systemType === 'pv') {
        if (store.getters['projects/project'].meta.loggerInformation) {
          const countOfLoggers = store.getters['projects/project'].meta.loggerInformation.length ?? 0;

          for (let index = 0; index < countOfLoggers; index++) {
            delete controllerMappingsCopy[systemType].components[`pv${controllerMappingsCopy[systemType].count}`];
            controllerMappingsCopy[systemType].count--;
          }
        }
      }
    });
    energyDevice.data.meta.controllerMappings = controllerMappingsCopy;
  }
  return energyDevice;
}

export function connectWithLineItems() {
  const noLineText = i18n.t('installationWizard.defineComponents.componentsPage.systems.charge_station.texts.noLine') as string;
  const lineText = i18n.t('installationWizard.defineComponents.componentsPage.systems.charge_station.texts.line') as string;
  return [
    { name: noLineText, value: 'noLine', disabled: false },
    { name: `${lineText} 1`, value: 1, disabled: false },
    { name: `${lineText} 2`, value: 2, disabled: false },
    { name: `${lineText} 3`, value: 3, disabled: false },
    { name: `${lineText} 4`, value: 4, disabled: false },
  ];
}

export function overWriteVariableValuesWithMeasurements(defaultMap: Record<string, IMQTTVariable> | IBatterySystem, measurements: Record<string, number>, page = 'batteryGridPage') {
  const defaultMapCopy: any = { ...defaultMap };
  Object.keys(defaultMapCopy).forEach((key: string) => {
    const variableKey = defaultMapCopy[key].variable ?? installationWizardVariables(key, page).variable;
    const measurement = measurements[variableKey];
    if ((measurement !== undefined) && !(key === 'priority' && measurement === 0)) {
      defaultMapCopy[key].value = measurement;
    }
  });
  return defaultMapCopy;
}

export function setVariableValuesWithMeasurements(defaultMap: Map<string, IMQTTVariable>, measurements: Record<string, number>) {
  const defaultMapCopy: Map<string, IMQTTVariable> = new Map(defaultMap);
  defaultMapCopy.forEach((variable: IMQTTVariable, key: string) => {
    const currentMeasurentValue = measurements[variable.variable];
    if (currentMeasurentValue !== undefined) {
      defaultMapCopy.set(key, {
        ...variable,
        value: (key.includes('priority') && currentMeasurentValue === 0) ? variable.value : currentMeasurentValue,
      });
    }
  });
  return defaultMapCopy;
}
