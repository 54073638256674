
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import BatteryComponentsSettings
  from '@/ui/components/wizards/installationWizard/steps/defineComponents/singleSystems/BatteryComponentSettings.vue';
import ElectricHeatingSettings
  from '@/ui/components/wizards/installationWizard/steps/defineComponents/singleSystems/ElectricHeatingSettings.vue';
import HeatingPumpComponentsSettings
  from '@/ui/components/wizards/installationWizard/steps/defineComponents/singleSystems/HeatingPumpComponentsSettings.vue';
import ChargeStationComponentsSettings
  from '@/ui/components/wizards/installationWizard/steps/defineComponents/singleSystems/ChargeStationComponentSettings.vue';
import { Getter, Mutation, State } from 'vuex-class';
import {
  DEFINITIONS,
  Definitions,
  IHandleStatusChangeParams,
  IIncludedSystemsTypes,
} from '@/types/wizards/installationWizard.types';
import { newChargeStationLimitDate, plcVersionDate } from '@/utils/versionManagementUtils';
import { IProject } from '@/types/project.types';
import { itemsContainValue } from '@/utils/utilsFunctions';
import { IInstallationWizardState } from '@/store/modules/installationWizard/types';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { BatteryType } from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';

@Component({
  methods: {itemsContainValue},
  components: {
    ModalWindow,
    InfoTooltip,
    BatteryComponentsSettings,
    ElectricHeatingSettings,
    HeatingPumpComponentsSettings,
    ChargeStationComponentsSettings,
  },
})
export default class ComponentsPage extends Vue {
  @State('installationWizard') wizardState!: IInstallationWizardState;
  @Prop() system!: string;
  @Prop() includedSystemsTypes!: IIncludedSystemsTypes;
  @Getter('projects/isDeye') isDeye!: boolean;
  @Getter('projects/isSolarmax') isSolarmax!: boolean;
  @Getter('projects/project') project!: IProject;
  @Getter('installationWizard/emsLimits') emsLimits!: Record<string, number>;
  @Mutation('installationWizard/setEMSSystemCount') setEMSSystemCount!: ({
    system,
    count,
  }: any) => void;
  @Mutation('installationWizard/setEnergyViewSystemCount') setEnergyViewSystemCount!: ({
    system,
    count,
  }: any) => void;
  @Mutation('installationWizard/handleIncludedSystemsTypesSystem') handleIncludedSystemsTypesSystem!: (
    { system, key, data }: { system: keyof IIncludedSystemsTypes; key: string; data: any }
  ) => void;

  systemLimits: any = {};
  doesHover = false;

  componentsMapping: any = {
    battery: 'BatteryComponentsSettings',
    electric_heating: 'ElectricHeatingSettings',
    charge_station: 'ChargeStationComponentsSettings',
  }

  get includedSystemsCount() {
    const includedSystemsCount: any = {};
    includedSystemsCount[this.system] = (this.includedSystemsTypes[this.system as keyof IIncludedSystemsTypes].definition.length < this.systemLimits[this.system].min) ? this.systemLimits[this.system].min : this.includedSystemsTypes[this.system as keyof IIncludedSystemsTypes].definition.length;

    return includedSystemsCount;
  }

  get image() {
    if (this.includedSystemsTypes.battery.definition.length > 1) return '2_batteries_on_2_inputs';
    if (this.wizardState.hybridSettings.useBothBatteryInputs) return 'battery_on_2_inputs';
    return 'battery_on_1_input';
  }

  get imageSrc() {
    return require(`../../../../../../assets/images/installation wizard/${this.image}.png`);
  }

  get isStandalone() {
    return this.projectBatteryType === BatteryType.STANDALONE;
  }

  get projectBatteryType() {
    // contains type of system that's selected inside the project settings
    const { batterySystemType } = this.project?.meta?.controller;
    return batterySystemType === undefined ? '' : batterySystemType;
  }

  handleStatusChange(value: IHandleStatusChangeParams) {
    this.$emit('statusUpdate', value);
  }

  /**
   * @description Handles checkbox change
   * @param event
   * @param system_name
   */
  async handleCheckbox(event: boolean, system_name: string) {
    if (!event) this.handleStatusChange({ system: system_name, status: true } as IHandleStatusChangeParams);
    // Set includedSystemsTypes system isSelected in store
    this.handleIncludedSystemsTypesSystem({
      system: system_name as keyof IIncludedSystemsTypes, key: 'isSelected', data: event,
    });
    // If checkbox is checked set system count to 1 and add system definition from DEFINITIONS list
    if (event) {
      const clone = JSON.parse(JSON.stringify(DEFINITIONS));
      this.handleIncludedSystemsTypesSystem({
        system: system_name as keyof IIncludedSystemsTypes, key: 'count', data: 1,
      });
      this.selectEmsSystem(system_name, 1, 'definition', [clone[system_name]]);
      // if battery is selected there must also be a pv System
      if (!this.isDeye && system_name === 'battery') {
        this.handleIncludedSystemsTypesSystem({ system: 'pv', key: 'count', data: 1 });
        this.selectEmsSystem('pv', 1, 'isSelected', true);
      }
    } else {
      this.handleIncludedSystemsTypesSystem({
        system: system_name as keyof IIncludedSystemsTypes, key: 'count', data: 0,
      });
      this.selectEmsSystem(system_name, 0, 'definition', []);
      // if battery is not selected there is no pv System
      if (!this.isDeye && system_name === 'battery') {
        this.handleIncludedSystemsTypesSystem({ system: 'pv', key: 'count', data: 0 });
        this.selectEmsSystem('pv', 0, 'isSelected', false);
      }
    }

    // Rerender EnergyVisualisation component
    this.updateEnergyVisualization();

    // Validate form to see rules errors
    await this.$nextTick();
    this.validate();
  }

  countPvByInverter(inverter: number) {
    return this.includedSystemsTypes.pv.definition.filter((item: any) => item.inverter === inverter).length;
  }

  /**
   * @description Handles system count change
   * @param newCount
   * @param system_name
   * @param inverter
   */
  async handleQuantitySelect(newCount: number, system_name: keyof IIncludedSystemsTypes, inverter = 0) {
    const oldCount = system_name === 'pv' ? this.countPvByInverter(inverter) : this.includedSystemsTypes[system_name].definition.length;
    const currentDefinitions = this.includedSystemsTypes[system_name].definition;

    if (newCount > oldCount) {
      const amount = newCount - oldCount;
      let newObjects = JSON.parse(JSON.stringify(
        Array(amount).fill({ ...DEFINITIONS[system_name as keyof Definitions] }),
      ));
      if (system_name === 'pv') {
        newObjects = newObjects.map((item: any) => ({ ...item, inverter }));
      }
      this.handleIncludedSystemsTypesSystem({
        system: system_name as keyof IIncludedSystemsTypes,
        key: 'definition',
        data: [...currentDefinitions, ...newObjects],
      });
    }
    if (newCount < oldCount) {
      if (system_name !== 'pv') {
        const amount = oldCount - newCount;
        this.handleIncludedSystemsTypesSystem({
          system: system_name as keyof IIncludedSystemsTypes,
          key: 'definition',
          data: currentDefinitions.slice(0, -amount),
        });
      } else {
        const filteredCurrentDefinitions = currentDefinitions.filter((item: any) => item.inverter !== inverter);
        const newObjects = JSON.parse(JSON.stringify(
          Array(newCount).fill({ ...DEFINITIONS[system_name as keyof Definitions] }),
        )).map((item: any) => ({ ...item, inverter }));
        this.handleIncludedSystemsTypesSystem({
          system: system_name as keyof IIncludedSystemsTypes,
          key: 'definition',
          data: [...filteredCurrentDefinitions, ...newObjects],
        });
      }
    }

    const pvCount = this.includedSystemsTypes.pv.definition.length;
    const count = system_name === 'pv' ? pvCount : newCount;
    this.handleIncludedSystemsTypesSystem({
      system: system_name as keyof IIncludedSystemsTypes, key: 'count', data: count,
    });
    this.setEMSSystemCount({ system: system_name, count });
    this.setEnergyViewSystemCount({ system: system_name, count });

    if (count === 0) {
      this.handleIncludedSystemsTypesSystem({
        system: system_name as keyof IIncludedSystemsTypes, key: 'isSelected', data: false,
      });
    }

    this.updateEnergyVisualization();
    await this.$nextTick();
    this.validate();
  }

  selectEmsSystem(system: string, count: number, prop: string, data: any) {
    this.setEMSSystemCount({ system, count });
    this.setEnergyViewSystemCount({ system, count });
    this.handleIncludedSystemsTypesSystem({
      system: system as keyof IIncludedSystemsTypes,
      key: prop,
      data,
    });
  }

  updateEnergyVisualization() {
    this.$emit('updateEnergyVisualization');
  }

  validate() {
    this.$emit('validate');
  }

  mounted() {
    this.validate();
  }

  created() {
    if ((this.isDeye || this.isSolarmax) && plcVersionDate(this.project).getTime() > newChargeStationLimitDate.getTime()) {
      this.componentsMapping = {
        ...this.componentsMapping,
        heating_pump: 'HeatingPumpComponentsSettings',
      };
    }
    this.systemLimits = {
      battery: {
        min: this.isDeye ? 0 : 1,
        max: this.emsLimits.battery,
      },
      electric_heating: {
        min: 0,
        max: this.emsLimits.electric_heating,
      },
      charge_station: {
        min: 0,
        max: this.emsLimits.charge_station,
      },
      charge_station_consumer: {
        min: 0,
        max: this.emsLimits.charge_station_consumer,
      },
      pv: {
        min: 0,
        max: this.emsLimits.pv - 1,
      },
      heating_pump: {
        min: 0,
        max: this.emsLimits.heating_pump,
      },
    };
  }
}
