
import { Vue, Component, Prop } from 'vue-property-decorator';
import SystemsInstanceControlsTemplate
  from '@/ui/components/devices/mpc/SetpointOptimizer/system/SystemInstanceControls/SystemsInstanceControlsTemplate.vue';
import InputFieldMock from '@/ui/components/devices/previews/components/InputFieldMock.vue';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import ShowEventDotMock from '@/ui/components/devices/previews/components/ShowEventDotMock.vue';
import colors from '@/ui/components/devices/mpc/colors';

@Component({
  components: {
    SystemsInstanceControlsTemplate,
    InputFieldMock,
    LabelUnitWrapper,
    ShowEventDotMock,
  },
})
export default class HybridSettings extends Vue {
  @Prop({ default: null }) systemInstanceType!: string;

  get colors() {
    return colors;
  }
}
