
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

/**
 * Component that represent total chart series stats
 */
@Component({
  components: {},
})
export default class TotalValuesWindow extends Vue {
  @Prop() objectsDifferenceSeriesStats!: any[];
  @Prop() objectsOtherSeriesStats!: any[];
  @Prop() currentSelectedPeriod!: any;

  isTooltip = false

  showModal = false;

  get translatedTitle() {
    const translatedText: any = this.$t(`uiComponents.chartsTotalView.titleMappings.${this.currentSelectedPeriod}`);
    return translatedText;
  }

  get localDifferenceObject() {
    return this.objectsDifferenceSeriesStats;
  }

  get localOtherStats() {
    return this.objectsOtherSeriesStats;
  }

  get other() {
    return [
      { type: 'name', prop: 'name', cols: 4 },
      { type: 'min', prop: 'minValue', cols: 2 },
      { type: 'max', prop: 'maxValue', cols: 2 },
      { type: 'avg', prop: 'avgValue', cols: 2 },
      { type: 'unit', prop: 'unit', cols: 2 },
    ];
  }

  get diff() {
    return [
      { type: 'name', prop: 'name', cols: 8 },
      { type: 'sum', prop: 'sum', cols: 2 },
      { type: 'unit', prop: 'unit', cols: 2 },
    ];
  }
}
