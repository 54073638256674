
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { Validation } from '@/ui/mixins/validation';

@Component({
  components: {
    ModalWindow,
  },
})
export default class DayRangeSelectionModal extends mixins(Validation) {
  @Prop({ default: undefined }) date!: { start: string; end: string};
  @Prop({ default: 'Select Date' }) buttonText!: string;
  @Prop({ default: 'dateSelected' }) emitName!: string;

  selectedDate: string[] = [];
  showStartDatePicker = true;

  switchToEndSelection() {
    this.showStartDatePicker = false;
  }

  emitDate() {
    const dates = this.selectedDate;
    // Sorting in ascending order
    dates.sort((a: string, b: string) => +new Date(a) - +new Date(b));
    this.$emit(this.emitName, {
      start: dates[0],
      end: dates[1],
    });

    this.resetVariables();
    (this.$refs.ModalWindowInstance as ModalWindow).onConfirmationDialogClose();
  }

  async onDialogStatusChange(status: boolean) {
    if (!status) {
      this.resetVariables();
    } else {
      if (this.date) {
        this.selectedDate.push(this.date.start);
        this.selectedDate.push(this.date.end);
      }
    }
  }

  resetVariables() {
    // reset values
    this.selectedDate = [];
    this.showStartDatePicker = true;
  }
}
