
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import CalculationAggregationWindow from '@/ui/components/modals/ManageChart/CalculationAggregationWindow.vue';
import ExpressionTextArea from '@/ui/components/modals/ManageChart/ExpressionTextArea.vue';
import { defaultChartColors } from '@/ui/components/devices/charts/charts/LynusChart';
import { IVariable } from '@/types/variables.types';
import { getVariableNames } from '@/ui/components/devices/charts/charts/ChartMath';
import { Validation } from '@/ui/mixins/validation';
import { mixins } from 'vue-class-component';
import { is } from 'immutable';
import { isNil } from 'lodash';

/**
 * Manage charts calculation form
 */
@Component({
  components: {
    CalculationAggregationWindow,
    ExpressionTextArea,
  },
})
export default class ManageChartsCalculation extends mixins(Validation) {
  @Prop() data!: any;
  @Prop() aggregationMethods!: any[];
  @Prop() chartTypes!: any[];
  @Prop() scalingMethods!: any[];
  @Getter('variables/variablesForComboBox') variablesForComboBox!: IVariable[];

  scaling = 'automatic';
  chartColors = defaultChartColors;
  valid = true;

  handleScaling(value: string) {
    this.scaling = value;
    if (value === 'automatic') {
      this.data.scaling.min = null;
      this.data.scaling.max = null;
    } else {
      this.data.scaling.min = 0;
      this.data.scaling.max = 1;
    }
    this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }
  onScalingInput() {
    this.setScaling();
  }
  setScaling() {
    const { min, max } = this.data.scaling;
    if (min !== '' && max !== '' || min !== '0' && max !== '0') {
      this.scaling = 'manual';
    }
    if (min === '' && max === '' || min === '0' && max === '0' || isNil(min) && isNil(max)) {
      this.scaling = 'automatic';
    }
  }

  onExpressionChange(expression: { varNames: string[]; fullExpression: string }) {
    this.data.calculation.expression = expression.fullExpression;
    this.defineAggregations(expression.fullExpression);
  }
  defineAggregations(expression: string) {
    // TODO: Currently getVariableNames when remove completely expression return array with empty string, need to find best place to fix this
    const variablesList = getVariableNames(expression).filter((el: string) => el.length);
    const diff = variablesList.length - this.data.calculation.aggregations.length;
    if (diff > 0) {
      this.data.calculation.aggregations.splice(this.data.calculation.aggregations.length, diff, 'last');
    }
    if (diff < 0) {
      const removeCount = Math.abs(diff);
      const start = this.data.calculation.aggregations.length - removeCount;
      this.data.calculation.aggregations.splice(start, removeCount);
    }
  }

  created() {
    this.setScaling();
  }

  async mounted() {
    await this.$nextTick(() => {
      (this.$refs.form as any).validate();
    });
  }
}
