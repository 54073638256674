
import { Vue, Component } from 'vue-property-decorator';
import OfflineChart from '@/ui/components/devices/previews/charts/components/OfflineChart.vue';

@Component({
  components: {
    OfflineChart,
  },
})
export default class Chart extends Vue {
  tab: any = null

  // contains Chart Object
  get mockChartData() {
    let currentChartOptions: any = [];
    currentChartOptions = [
      {
        agg: 'first',
        name: this.$t(`${this.settingsViewLang}.chartLabels.power.actualPower`),
        scaling: {
          max: '100',
          min: '0',
        },
        seriesType: 'View',
        type: 'line',
        unit: 'kW',
        var: '',
      }, {
        agg: 'first',
        name: this.$t(`${this.settingsViewLang}.chartLabels.power.calculatedPower`),
        scaling: {
          max: '100',
          min: '0',
        },
        seriesType: 'View',
        type: 'line',
        unit: 'kW',
        var: '',
      },
    ];

    const currentData = {
      data: {
        chartOptions: currentChartOptions,
        selectedStackingOptions: 'normal',
        selectedWidth: 'full',
        type: 'chart',
      },
      created_at: '2021-10-07T07:43:33.554983Z',
    };
    return currentData;
  }
  get settingsViewLang() {
    return 'mlModel.PVProductionService.settingsView';
  }
}
