
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseChartWrapper from '@/ui/components/devices/charts/charts/BaseChartWrapper.vue';

@Component({
  components: {
    BaseChartWrapper,
  },
})
export default class Charts extends Vue {
  @Prop() httpCharts!: any;
  @Prop({ default: null }) chartsScaling!: any;
  @Prop({
    default: {
      actualPower: '',
      calculatedPower: null,
      calculatedEnergy: null,
    },
  }) mqttChartsVariables!: any;

  tab: any = null

  get langPath() {
    return 'mlModel.HppProductionService.settingsView';
  }

  get power() {
    return {
      name: '',
      data: {
        chartOptions: [
          {
            agg: 'last',
            name: this.$t(
              'mlModel.HppProductionService.settingsView.chartLabels.energy.actualEnergy',
            ),
            scaling: {
              max: this.chartsScaling * 8,
              min: 0,
            },
            type: 'column',
            unit: 'kWh',
            var: this.mqttChartsVariables.actualEnergyChart,
          },
          {
            agg: 'last',
            name: this.$t(
              'mlModel.HppProductionService.settingsView.chartLabels.energy.calculatedEnergy',
            ),
            scaling: {
              max: this.chartsScaling * 8,
              min: 0,
            },
            type: 'column',
            unit: 'kWh',
            var: this.mqttChartsVariables.calculatedEnergy,
          },
        ],
      },
      selectedWidth: 'full',
      type: 'chart',
    };
  }

  get chartData() {
    return {
      chartWidth: null,
      chartHeight: 600,
      chartData: this.power,
      navigationItemsToExclude: ['live', 'hour', 'day'],
    };
  }
}
