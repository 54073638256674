
import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import { Getter } from 'vuex-class';
import { Periods } from '@/ui/components/devices/charts/charts/ChartUtils';
import DefaultCalendar from '@/ui/components/devices/charts/components/DefaultCalendar.vue';
import MultiSelectionCalendar
  from '@/ui/components/devices/charts/components/multiselection-calendar/MultiSelectionCalendar.vue';
import HourPicker from '@/ui/components/devices/charts/components/HourPicker.vue';
import { ApproximationValue } from '@/types/chart.types';

@Component({
  components: {
    ModalWindow,
    DefaultCalendar,
    MultiSelectionCalendar,
    HourPicker,
  },
})
export default class CalendarButtons extends Vue {
  @Prop({ default: false }) disableNavButton!: boolean;
  @Prop({ default: false }) enableMultiSelect!: boolean;
  @Prop({ default: false }) hideCalendarNavigation!: boolean;
  @Prop({ default: Periods.DAY }) currentPeriod!: string;
  @Prop({ default: () => ({ day: 4, week: 1, month: 1, year: 1 }) }) customApproximationValue!: ApproximationValue;
  @Getter('projects/projectStartedAt') startedAt!: string;

  date: number[] = [new Date().setUTCHours(0, 0, 0, 0) / 1000];
  readonly multiSelectLimit: number = 5;

  get hourPickerSelectButtonValidation() {
    return !this.date;
  }
  get now() {
    return new Date().toISOString();
  }
  get calendarModalOptions(): any {
    if (!this.enableMultiSelect) {
      return {
        live: { active: false, component: null, windowWidth: null, validation: null },
        hour: { active: true, component: 'HourPicker', validation: this.hourPickerSelectButtonValidation },
        day: { active: true, component: 'DefaultCalendar', validation: null },
        week: { active: true, component: 'DefaultCalendar', validation: null },
        month: { active: true, component: 'DefaultCalendar', validation: null },
        year: { active: true, component: 'DefaultCalendar', validation: null },
      };
    } else {
      return {
        live: { active: false, component: null, windowWidth: null, validation: null },
        hour: { active: true, component: 'MultiSelectionCalendar', validation: this.multiSelectValidation },
        day: { active: true, component: 'MultiSelectionCalendar', validation: this.multiSelectValidation },
        week: { active: true, component: 'MultiSelectionCalendar', validation: this.multiSelectValidation },
        month: { active: true, component: 'MultiSelectionCalendar', validation: this.multiSelectValidation },
        year: { active: true, component: 'MultiSelectionCalendar', validation: this.multiSelectValidation },
      };
    }
  }
  get calendarTitle(): string {
    if (!this.enableMultiSelect) {
      switch (this.currentPeriod) {
        case 'hour': return this.$t('uiComponents.chartsCalendar.selectHour').toString();
        case 'day': return this.$t('uiComponents.chartsCalendar.selectDay').toString();
        case 'week': return this.$t('uiComponents.chartsCalendar.selectWeek').toString();
        case 'month': return this.$t('uiComponents.chartsCalendar.selectMonth').toString();
        case 'year': return this.$t('uiComponents.chartsCalendar.selectYear').toString();
        default: return '';
      }
    } else {
      switch (this.currentPeriod) {
        case 'hour': return this.$t('uiComponents.chartsCalendar.selectHourMulti', { limit: this.multiSelectLimit }).toString();
        case 'day': return this.$t('uiComponents.chartsCalendar.selectDayMulti', { limit: this.multiSelectLimit }).toString();
        case 'week': return this.$t('uiComponents.chartsCalendar.selectWeekMulti', { limit: this.multiSelectLimit }).toString();
        case 'month': return this.$t('uiComponents.chartsCalendar.selectMonthMulti', { limit: this.multiSelectLimit }).toString();
        case 'year': return this.$t('uiComponents.chartsCalendar.selectYearMulti', { limit: this.multiSelectLimit }).toString();
        default: return '';
      }
    }
  }
  get currentCalendar() {
    return this.calendarModalOptions[this.currentPeriod];
  }
  get multiSelectValidation() {
    return this.enableMultiSelect && !(this.date?.length >= 2);
  }

  closeDialog() {
    (this.$refs.modalWindowInstance as any).closeDialog();
  }
}
