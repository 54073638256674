// Contains System Types List for Selectfields
// availableFrom contains UNIX Timestamp in milliseconds. If Project was created after this timestamp the user can select this product
import {
  mennekesEnerchargeDate,
  newChargeStationLimitDate,
  versionLimitedChargeStations
} from '@/utils/versionManagementUtils';
import { ChargeStationType } from '@/types/project.types';

export enum batteryOptions {
  BatteryTower = 'Battery Tower',
  BatteryRackSystem = 'Battery Rack System',
  WecoBatteryStack = 'Weco Battery Stack',
  WecoSlimStack = 'Weco Slim Stack',
}
export enum LoggerType {
  SolarmanLogger = 'Solarman Logger (Deye)',
}

export const loggerTypes = [LoggerType.SolarmanLogger];
export const inverterPowers = {
  [LoggerType.SolarmanLogger]: [
    // no inverter info needed
  ],
};

export const batteryCapacity = {
  [LoggerType.SolarmanLogger]: {
    [batteryOptions.BatteryTower]: [
      { name: '8.2 kWh', value: '8.2 kWh', availableFrom: new Date('1970-01-01') },
      { name: '12.3 kWh', value: '12.3Wh', availableFrom: new Date('1970-01-01') },
      { name: '16.4 kWh', value: '16.4 kWh', availableFrom: new Date('1970-01-01') },
      { name: '20.5 kWh', value: '20.5 kWh', availableFrom: new Date('1970-01-01') },
      { name: '24.6 kWh', value: '24.6 kWh', availableFrom: new Date('1970-01-01') },
    ],
    [batteryOptions.BatteryRackSystem]: [
      { name: '20,5 kWh', value: '20,5 kWh', availableFrom: new Date('1970-01-01') },
      { name: '25.6 kWh', value: '25.6 kWh', availableFrom: new Date('1970-01-01') },
      { name: '30.7 kWh', value: '30.7 kWh', availableFrom: new Date('1970-01-01') },
      { name: '35.8 kWh', value: '35.8 kWh', availableFrom: new Date('1970-01-01') },
      { name: '41 kWh', value: '41 kWh', availableFrom: new Date('1970-01-01') },
      { name: '46.1 kWh', value: '46.1 kWh', availableFrom: new Date('1970-01-01') },
      { name: '51.2 kWh', value: '51.2 kWh', availableFrom: new Date('1970-01-01') },
      { name: '56.2 kWh', value: '56.2 kWh', availableFrom: new Date('1970-01-01') },
    ],
    [batteryOptions.WecoBatteryStack]: [
      { name: '21.2 kWh', value: '21.2 kWh', availableFrom: new Date('1970-01-01') },
      { name: '26.5 kWh', value: '26.5 kWh', availableFrom: new Date('1970-01-01') },
      { name: '31.8 kWh', value: '31.8 kWh', availableFrom: new Date('1970-01-01') },
      { name: '42.4 kWh', value: '42.4 kWh', availableFrom: new Date('1970-01-01') },
      { name: '47.7 kWh', value: '47.7 kWh', availableFrom: new Date('1970-01-01') },
      { name: '53 kWh', value: '53 kWh', availableFrom: new Date('1970-01-01') },
      { name: '58.3 kWh', value: '58.3 kWh', availableFrom: new Date('1970-01-01') },
      { name: '68.9 kWh', value: '68.9 kWh', availableFrom: new Date('1970-01-01') },
      { name: '74.2 kWh', value: '74.2 kWh', availableFrom: new Date('1970-01-01') },
    ],
    [batteryOptions.WecoSlimStack]: [
      { name: '5.1 kWh', value: '5.1 kWh', availableFrom: new Date('1970-01-01') },
      { name: '10.2 kWh', value: '10.2 kWh', availableFrom: new Date('1970-01-01') },
      { name: '15.3 kWh', value: '15.3 kWh', availableFrom: new Date('1970-01-01') },
      { name: '20.4 kWh', value: '20.4 kWh', availableFrom: new Date('1970-01-01') },
      { name: '25.5 kWh', value: '25.5 kWh', availableFrom: new Date('1970-01-01') },
      { name: '30.6 kWh', value: '30.6 kWh', availableFrom: new Date('1970-01-01') },
    ],
  },
};

export const chargeStationTypes = [
  { name: 'Webasto 11 kW', value: 'Webasto11', availableFrom: new Date('1970-01-01'), disabled: false },
  { name: 'Webasto 22 kW', value: 'Webasto22', availableFrom: new Date('1970-01-01'), disabled: false },
  { name: 'Weidmüller AC Smart Value 11 kW', value: ChargeStationType.internalWeidmüller11, availableFrom: versionLimitedChargeStations.weidmueller11kWInternal, disabled: false },
  { name: 'Weidmüller AC Smart Value 22 kW', value: ChargeStationType.internalWeidmüller22, availableFrom: versionLimitedChargeStations.weidmueller22kWInternal, disabled: false },
  { name: 'Weidmüller AC Smart Eco 11 kW (externe Messung)', value: ChargeStationType.externalWeidmüller11, availableFrom: new Date('1970-01-01'), disabled: false },
  { name: 'Weidmüller AC Smart Eco 22 kW (externe Messung)', value: ChargeStationType.externalWeidmüller22, availableFrom: new Date('1970-01-01'), disabled: false },
  { name: 'Weidmüller AC Smart Advanced 11 kW (für Mieterstrom)', value: ChargeStationType.internalWeidmüllerMS11, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'Weidmüller AC Smart Advanced 22 kW (für Mieterstrom)', value: ChargeStationType.internalWeidmüllerMS22, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'Schneider EVlink Pro AC 11 kW', value: ChargeStationType.Schneider11, availableFrom: newChargeStationLimitDate, disabled: false },
  { name: 'Schneider EVlink Pro AC 22 kW', value: ChargeStationType.Schneider22, availableFrom: newChargeStationLimitDate, disabled: false },
  { name: 'AMTRON Charge Control 11 kW', value: ChargeStationType.AmtronCC11, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'AMTRON Professional 11 kW', value: ChargeStationType.AmtronPro11, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'AMTRON Professional 22 kW', value: ChargeStationType.AmtronPro22, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'AMTRON TwinCharge 22 kW', value: ChargeStationType.AmtronTC22, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'AMEDIO Professional 22 kW', value: ChargeStationType.AmedioPro22, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'Enercharge 50 kW', value: ChargeStationType.Enercharge50, availableFrom: mennekesEnerchargeDate, disabled: false },
  { name: 'Enercharge 100 kW', value: ChargeStationType.Enercharge100, availableFrom: mennekesEnerchargeDate, disabled: false },
];

export type IWizardComponentMap = Record<string, Array<number | number[]>>;

export const chargeStationMapTypes = ['WebastoUnite', 'WebastoNext', 'Weidmüller', 'Schneider', 'Amtron', 'AmtronTC', 'Amedio', 'Enercharge'];

export const heatingPumpMapTypes = ['Lambda Eureka', 'Soltop M-Tech'];
export const defineSystemMap = (systemTypes: string[], maximumSystemCount: number) => {
  const systemMap: IWizardComponentMap = {};
  systemTypes.forEach((type) => {
    systemMap[type] = Array.from({ length: maximumSystemCount }, (_, i) => 0);
  });
  return systemMap;
};

export const electricHeatingTypes = [
  { name: 'Askoma 3.5 kW', value: 'Askoma 3.5 kW', availableFrom: new Date('1970-01-01'), rawValue: 3.5 },
  { name: 'Askoma 5.8 kW', value: 'Askoma 5.8 kW', availableFrom: new Date('1970-01-01'), rawValue: 5.8 },
];
export function getRawValueBySystemTypeElectricHeating(system: string) {
  return electricHeatingTypes.find((item: any) => item.name === system)?.rawValue ?? system;
}

export const avaliableExternalMeasurementTypes = ['big_consumer', 'electric_heating', 'charge_station', 'heating_pump'];

export function getChargeStationNameByValue(value: string) {
  const foundItem = chargeStationTypes.find(item => item.value === value);
  return foundItem ? foundItem.name : null;
}

export function getChargeStationPower(key: string) {
  const chargeStationPower: Record<string, number> = {
    'Webasto11': 11,
    'Webasto22': 22,
    [ChargeStationType.WebastoNext11]: 11,
    [ChargeStationType.WebastoNext22]: 22,
    [ChargeStationType.WebastoUnite11]: 11,
    [ChargeStationType.WebastoUnite22]: 22,
    [ChargeStationType.internalWeidmüller11]: 11,
    [ChargeStationType.internalWeidmüller22]: 22,
    [ChargeStationType.externalWeidmüller11]: 11,
    [ChargeStationType.externalWeidmüller22]: 22,
    [ChargeStationType.internalWeidmüllerMS11]: 11,
    [ChargeStationType.internalWeidmüllerMS22]: 22,
    [ChargeStationType.Schneider11]: 11,
    [ChargeStationType.Schneider22]: 22,
    [ChargeStationType.AmtronCC11]: 11,
    [ChargeStationType.AmtronPro11]: 11,
    [ChargeStationType.AmtronPro22]: 22,
    [ChargeStationType.AmtronTC22]: 22,
    [ChargeStationType.AmedioPro22]: 22,
    [ChargeStationType.Enercharge50]: 50,
    [ChargeStationType.Enercharge100]: 100,
  };

  return chargeStationPower[key];
}

export function getChargeStationTypeInformationBySystemType(name: string) {
  const chargeStationPower: Record<string, { type: string; isExternal: boolean}> = {
    'Webasto11': { type: 'Webasto', isExternal: false },
    'Webasto22': { type: 'Webasto', isExternal: false },
    [ChargeStationType.WebastoNext11]: { type: 'Webasto', isExternal: false },
    [ChargeStationType.WebastoNext22]: { type: 'Webasto', isExternal: false },
    [ChargeStationType.WebastoUnite22]: { type: 'Webasto', isExternal: false },
    [ChargeStationType.WebastoUnite22]: { type: 'Webasto', isExternal: false },
    [ChargeStationType.internalWeidmüller11]: { type: 'Weidmüller', isExternal: false },
    [ChargeStationType.internalWeidmüller22]: { type: 'Weidmüller', isExternal: false },
    [ChargeStationType.externalWeidmüller11]: { type: 'Weidmüller', isExternal: true },
    [ChargeStationType.externalWeidmüller22]: { type: 'Weidmüller', isExternal: true },
    [ChargeStationType.internalWeidmüllerMS11]: { type: 'Weidmüller', isExternal: false },
    [ChargeStationType.internalWeidmüllerMS22]: { type: 'Weidmüller', isExternal: false },
    [ChargeStationType.Schneider11]: { type: 'Schneider', isExternal: false },
    [ChargeStationType.Schneider22]: { type: 'Schneider', isExternal: false },
    [ChargeStationType.AmtronCC11]: { type: 'Amtron', isExternal: false },
    [ChargeStationType.AmtronPro11]: { type: 'Amtron', isExternal: false },
    [ChargeStationType.AmtronPro22]: { type: 'Amtron', isExternal: false },
    [ChargeStationType.AmtronTC22]: { type: 'AmtronTC', isExternal: false },
    [ChargeStationType.AmedioPro22]: { type: 'Amedio', isExternal: false },
    [ChargeStationType.Enercharge50]: { type: 'Enercharge', isExternal: false },
    [ChargeStationType.Enercharge100]: { type: 'Enercharge', isExternal: false },
  };
  return chargeStationPower[name];
}
