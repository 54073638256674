
import { Vue, Component, Prop } from 'vue-property-decorator';
import InfoTooltip from '@/ui/components/components/InfoTooltip.vue';
import { EnergyCircleDisplayData } from '@/types/energyVisualisation/EnergyCircleDisplayData';
import ModalWindow from '@/ui/components/components/ModalWindow.vue';
import LynusIcon from '@/ui/components/components/LynusIcon.vue';
import StatusIndicator from '@/ui/components/components/StatusIndicator.vue';

/**
 * Component that represents the force charge indicator for battery systems
 */
@Component({
  components: {
    StatusIndicator,
    LynusIcon,
    ModalWindow,
    InfoTooltip,
  },
})
export default class EmdModeIndicator extends Vue {
  @Prop({}) displayData!: EnergyCircleDisplayData;

  get activeEmsMode() {
    return this.displayData.additionalDisplayData?.activeEmsMode;
  }

  get currentMode() {
    switch (this.activeEmsMode) {
      case 1:
        return {
          iconLeft: 'mdi-solar-power',
          iconRight: 'mdi-transmission-tower',
          documentation: {
            title: this.$t('mlModel.EMSV2.mainView.sellEnergy'),
            info: this.$t('mlModel.EMSV2.mainView.sellEnergyInfo'),
            link: 'https://lynus.zammad.com/help/de-de/166-direktvermarktung-dynamischer-stromtarif/241-modus-beschreibungen',
          },
        };
      case 2:
        return {
          iconLeft: 'mdi-transmission-tower',
          iconRight: 'mdi-home-variant',
          documentation: {
            title: this.$t('mlModel.EMSV2.mainView.buyEnergy'),
            info: this.$t('mlModel.EMSV2.mainView.buyEnergyInfo'),
            link: 'https://lynus.zammad.com/help/de-de/166-direktvermarktung-dynamischer-stromtarif/241-modus-beschreibungen',
          },
        };
      case 3:
        return {
          iconLeft: 'mdi-transmission-tower',
          iconRight: 'mdi-scale-balance',
          documentation: {
            title: this.$t('mlModel.EMSV2.mainView.regelenergiePositive'),
            info: this.$t('mlModel.EMSV2.mainView.regelenergiePositiveInfo'),
            link: 'https://lynus.zammad.com/help/de-de/166-direktvermarktung-dynamischer-stromtarif/241-modus-beschreibungen',
          },
        };
      case 4:
        return {
          iconLeft: 'mdi-scale-balance',
          iconRight: 'mdi-transmission-tower',
          documentation: {
            title: this.$t('mlModel.EMSV2.mainView.regelenergieNegative'),
            info: this.$t('mlModel.EMSV2.mainView.regelenergieNegativeInfo'),
            link: 'https://lynus.zammad.com/help/de-de/166-direktvermarktung-dynamischer-stromtarif/241-modus-beschreibungen',
          },
        };
      default:
        return {
          iconLeft: 'mdi-solar-power',
          iconRight: 'mdi-transmission-tower',
          documentation: {
            title: this.$t('mlModel.EMSV2.mainView.buyEnergy'),
            info: this.$t('mlModel.EMSV2.mainView.buyEnergyInfo'),
            link: 'https://lynus.zammad.com/help/de-de/166-direktvermarktung-dynamischer-stromtarif/241-modus-beschreibungen',
          },
        };
    }
  }
}
