// Will contain all limits that the user when going through the installation wizard

import { LoggerType } from './systemTypes';

export const solarmanLoggerLimits: Record<string, number> = {
  pv: 0,
  generator: 0,
  battery: 0,
  grid: 1,
  house: 1,
  charge_station: 0,
  electric_heating: 0,
  heating_pump: 0,
  big_consumer: 0,
  emergencyPowerOperation: 0,
  avPv: 20,
  chp: 0,
};

export function emsLimitsByType(type: LoggerType) {
  return {
    [LoggerType.SolarmanLogger]: solarmanLoggerLimits,
  }[type];
}

// limits for logger by type
export const loggerLimitByType: Record<string, number> = {
  string: 20,
  hybrid: 10,
};
