
import { Vue, Component, Prop } from 'vue-property-decorator';
import WizardContent from '@/ui/components/wizards/baseComponents/WizardContent.vue';
import SideBar from '@/ui/components/wizards/baseComponents/SideBar.vue';
import LeaveConfirmationModal from '@/ui/components/modals/LeaveConfirmationModal.vue';
import { Getter, Mutation } from 'vuex-class';
import { IReportBox } from '@/types/app.types';
import { IWizardPage } from '@/types/wizards/wizard.general.types';

@Component({
  components: { LeaveConfirmationModal, SideBar, WizardContent },
})
export default class Wizard extends Vue {
  @Prop() name!: string;
  @Prop() stepsCount!: number;
  @Prop() pages!: IWizardPage[];
  @Prop({ default: 0 }) currentPage!: number;
  @Prop({ default: 1 }) currentStep!: number;
  @Prop({ default: false }) hasToBeOnline!: boolean;
  @Prop({ default: false }) considerProjectStatus!: boolean;
  @Prop({ default: false }) isProjectOnline!: boolean;
  @Getter('installationWizard/currentStep') activeStep!: number;
  @Mutation('app/setReport') setReport!: (report: IReportBox) => void;

  async handleRouteChange() {
    this.$emit('handleRouteChange');
  }

  // accessed by ref
  async confirmAction(to: any, next: any) {
    await (this.$refs.confirmationModal as any).confirmAction(to, next);
  }
}
