import api from '@/services/api';

export default {
  async fetchEvents(projectId: string, page: number, accepted: boolean) {
    return api.fetch(`/projects/${projectId}/alerts?page=${page}&accepted=${accepted}`, 'GET');
  },

  async acceptEvent(projectId: string, eventId: string) {
    return api.fetch(`/projects/${projectId}/alerts/${eventId}`, 'PUT');
  },

  async acceptEvents(projectId: string) {
    return api.fetch(`/projects/${projectId}/alerts`, 'PUT');
  },
};
