
import { Vue, Component, Watch } from 'vue-property-decorator';
import ProjectTags from '@/ui/components/components/ProjectTagsField.vue';
import { AllBatteryTypes } from '@/utils/batteryTypes';
import { deyeTypes, deyeBatteryTypes } from '@/ui/components/wizards/installationWizard/wizardSettings/systemTypes';
import { Getter, Mutation, State } from 'vuex-class';
import { IProject, IProjectFilters } from '@/types/project.types';
import { IProjectAmountByStatus } from '@/types/common.types';

@Component({
  components: {
    ProjectTags,
  },
})
export default class TagsFilterPopup extends Vue {
  @State('projects') projectsState!: any;
  @Getter('projects/projects') projects!: IProject[];
  @Getter('projects/projectFilters') projectFilters!: IProjectFilters;
  @Mutation('projects/setProjectFilter') setProjectFilter!: (data: { type: keyof IProjectFilters; value: any }) => any;

  selectByProjectStatus = '';
  selectedSystemType = '';
  selectedBatterySystemType = '';

  // will contain the amount of projects by status
  projectAmountByStatus: IProjectAmountByStatus = {
    ok: 0,
    warning: 0,
    error: 0,
  };

  get filterProjectByStatus() {
    return this.projectsState.projectFilters.projectStatus;
  }

  get projectFilterTags() {
    return this.projectFilters.projectTags;
  }

  get badgeValue() {
    let additional = 0;
    if (this.selectByProjectStatus !== '' && this.selectByProjectStatus !== undefined) {
      additional = 1;
    }
    return this.projectFilterTags.length + additional;
  }

  get projectStatusList() {
    return [
      { name: `Ok (${this.projectAmountByStatus.ok})`, value: 'ok' },
      { name: `Warning (${this.projectAmountByStatus.warning})`, value: 'suspend' },
      { name: `Error (${this.projectAmountByStatus.error})`, value: 'error' },
    ];
  }

  get allSystemTypes() {
    // convert enum to array with all system types
    return Object.values(AllBatteryTypes);
  }

  get allDeyeTypes() {
    // convert enum to array with all battery system types
    return Object.values(deyeTypes);
  }

  get deyeBatteryTypes() {
    // convert enum to array with all battery system types
    return Object.values(deyeBatteryTypes);
  }

  handleClearSystemTypeFilter() {
    // reset system type and battery type filter because battery type is dependent on system type
    this.setProjectFilter({ type: 'systemType', value: '' });
    this.setProjectFilter({ type: 'batterySystemType', value: '' });
    this.selectedBatterySystemType = '';
  }

  @Watch('projects')
  handleProjectsChange() {
    // update project amount by status
    Object.keys(this.projectAmountByStatus).forEach((status: string) => {
      this.projectAmountByStatus[status as keyof IProjectAmountByStatus] = this.projects.filter((project: IProject) => {
        if (!project.stats) return false;
        if (status === 'ok') {
          return project.stats.errors === 0 && project.stats.warnings === 0;
        }
        if (status === 'warning') {
          return project.stats.errors === 0 && project.stats.warnings > 0;
        }
        if (status === 'error') {
          return project.stats.errors > 0;
        }
        return false;
      }).length;
    });
  }

  mounted() {
    // set filter if some is already selected
    if (this.filterProjectByStatus !== '' && this.filterProjectByStatus !== undefined) {
      this.selectByProjectStatus = this.filterProjectByStatus;
    }
  }

  menu = false;
}
