var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-6"},[_c('div',{staticStyle:{"width":"100%","display":"table","align-content":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticStyle:{"z-index":"999999999!important","width":"30px","margin-left":"auto","margin-right":"10px"},on:{"mouseenter":function($event){_vm.doesHover = true},"mouseleave":function($event){_vm.doesHover = false}}},on),[_c('lynus-icon',_vm._g({staticStyle:{"z-index":"9!important"},attrs:{"name":"info-in-circle","color":_vm.doesHover ? 'accent' : 'theme',"size":"24"}},on))],1)]}}])},[_c('div',{staticStyle:{"max-width":"200px"}},[_c('div',{staticStyle:{"font-weight":"bold","padding-bottom":"4px"}},[_vm._v(" "+_vm._s(_vm.$t('modals.manageCharts.form.expressionDescriptionTitle'))+" ")]),_c('ul',_vm._l((_vm.$t('modals.manageCharts.form.expressionDescriptionItems')),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0)])])],1),_c('Mentionable',{attrs:{"keys":['@'],"items":_vm.listItems,"offset":"5","omitKey":"","insertSpace":"","limit":100},on:{"close":function($event){return _vm.setFocusOnField()}}},[_c('v-textarea',{ref:"textArea",staticStyle:{"font-family":"monospace"},attrs:{"label":_vm.$t('modals.manageCharts.form.mathExpression'),"height":400,"no-resize":"","color":"accent","outlined":"","rules":[
        _vm.rules.required,
        _vm.rulesForExpressionField.variableNameRule,
        _vm.rulesForExpressionField.parenthesisRule,
        _vm.rulesForExpressionField.allowedCharsRule,
        _vm.rulesForExpressionField.variableCountRule,
        _vm.rulesForExpressionField.generalEpressionRule
      ]},on:{"input":function($event){return _vm.emitChanges()}},model:{value:(_vm.localExpressionVariable),callback:function ($$v) {_vm.localExpressionVariable=$$v},expression:"localExpressionVariable"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }