import { render, staticRenderFns } from "./ArrowUpBase.vue?vue&type=template&id=70dd1f2b&scoped=true&"
import script from "./ArrowUpBase.vue?vue&type=script&lang=ts&"
export * from "./ArrowUpBase.vue?vue&type=script&lang=ts&"
import style0 from "./ArrowUpBase.vue?vue&type=style&index=0&id=70dd1f2b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70dd1f2b",
  null
  
)

export default component.exports