
import { Vue, Component, Prop } from 'vue-property-decorator';
import Forecast from '@/ui/components/devices/mpc/PVProductionService/SettingsCharts/Forecast.vue';
import Charts from '@/ui/components/devices/mpc/PVProductionService/SettingsCharts/Charts.vue';

/**
 * Component that represent wrapper for charts tabs navigation.
 * Used in PVProductionService.
 */
@Component({
  components: {
    Forecast,
    Charts,
  },
})
export default class SettingsCharts extends Vue {
  @Prop() mpcId!: any;
  @Prop({ default: null }) chartsScaling!: any;
  @Prop({
    default: {
      actualPower: '',
      calculatedPower: null,
      calculatedEnergy: null,
      producedEnergy: null,
    },
  }) mqttChartsVariables!: any;

  tab: any = null

  get langPath() {
    return 'mlModel.PVProductionService.settingsView.chartTabs';
  }

  get tabs() {
    return [
      {
        title: 'chart',
        locale: this.langPath,
        component: 'Charts',
        chartsScaling: this.chartsScaling,
        mqttChartsVariables: this.mqttChartsVariables,
      },
      {
        title: 'forecast',
        locale: this.langPath,
        component: 'Forecast',
        chartsScaling: this.chartsScaling,
        mpcId: this.mpcId,
      },
    ];
  }
}
