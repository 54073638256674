
import { Component, Prop } from 'vue-property-decorator';
import LabelUnitWrapper from '@/ui/components/devices/components/LabelUnitWrapper.vue';
import InputFieldExternal from '@/ui/components/devices/components/InputFields/InputFieldExternal.vue';
import OutputFieldBase from '@/ui/components/devices/devices/base/OutputFieldBase.vue';
import ShowEventDotBase from '@/ui/components/devices/devices/base/ShowEventDotBase.vue';
import SystemsInstanceControlsTemplate
  from '@/ui/components/devices/mpc/SetpointOptimizer/system/SystemInstanceControls/SystemsInstanceControlsTemplate.vue';
import { mixins } from 'vue-class-component';
import { Validation } from '@/ui/mixins/validation';
import { Action, Getter } from 'vuex-class';
import colors from '@/ui/components/devices/mpc/colors';
import AverageRoomTemps from '@/ui/components/devices/mpc/SetpointOptimizer/system/components/AverageRoomTemps.vue';
import { IMLModel } from '@/types/mpc/mpc.types';

/**
 * Component that represents HybridWater form controls
 */
@Component({
  components: {
    LabelUnitWrapper,
    InputFieldExternal,
    OutputFieldBase,
    ShowEventDotBase,
    SystemsInstanceControlsTemplate,
    AverageRoomTemps,
  },
})
export default class HybridWaterControls extends mixins(Validation) {
  @Prop({ default: null }) controls!: any;
  @Prop({ default: null }) mpcId!: any;
  @Prop({ default: null }) systemInstanceId!: string;
  @Getter('mpc/getMPCControllerById') getMPCControllerById!: (id: string) => IMLModel;
  @Action('mpc/updateSetpointSystemInstanceTemperatureSettings') updateSetpointSystemInstanceTemperatureSettings: any;

  settings: any = {
    identifier: '',
    max_flow_temperature: {
      cooling: 0,
      heating: 0,
    },
    min_flow_temperature: {
      cooling: 0,
      heating: 0,
    },
    set_point_temperature: {
      cooling: 0,
      heating: 0,
    },
  }

  get roomTemperatures() {
    return Object.keys(this.controls.room_temperatures);
  }

  get outputFieldFlowTemperature() {
    return {
      'OutputField_actualValue': this.controls.flow_temperature,
    };
  }
  get outputFieldReturnTemperature() {
    return {
      'OutputField_actualValue': this.controls.return_temperature,
    };
  }
  get showEventDotStatus() {
    return {
      'ShowEventDot_errorWarningState': this.controls.status,
    };
  }

  get colors() {
    return colors;
  }

  initTemperatureSettings() {
    const copy: any = JSON.parse(JSON.stringify(this.controls));
    this.settings.identifier = this.systemInstanceId;
    this.settings.max_flow_temperature = copy?.max_flow_temperature;
    this.settings.min_flow_temperature = copy?.min_flow_temperature;
    this.settings.set_point_temperature = copy?.set_point_temperature;
  }

  async handleTemperatureSettings() {
    await this.updateSetpointSystemInstanceTemperatureSettings({ mpc_id: this.mpcId, settings: this.settings });
    this.$emit('updateLocalMPCData', this.getMPCControllerById(this.mpcId));
  }

  created() {
    this.initTemperatureSettings();
  }
}
