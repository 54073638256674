import api from '@/services/api';
import { IMember } from '@/types/members.types';

export default {
  async fetchMembers(projectId: string) {
    return api.fetch(`/projects/${projectId}/members`, 'GET');
  },

  async fetchMember(projectId: string, memberId: string) {
    return api.fetch(`/projects/${projectId}/members/${memberId}`, 'GET');
  },

  async createMember(projectId: string, member: Partial<IMember>) {
    return api.fetch(`/projects/${projectId}/members`, 'POST', member);
  },

  async updateMember(projectId: string, memberId: string, member: Partial<IMember>) {
    return api.fetch(`/projects/${projectId}/members/${memberId}`, 'PUT', member);
  },

  async deleteMember(projectId: string, memberId: string) {
    return api.fetch(`/projects/${projectId}/members/${memberId}`, 'DELETE');
  },

  async updateMemberMeta(projectId: string, memberId: string, member: Partial<IMember>) {
    return api.fetch(`/projects/${projectId}/members/${memberId}/meta`, 'PUT', member);
  },

  async updateMemberCollections(projectId: string, memberId: string, collections: string[]) {
    return api.fetch(`/projects/${projectId}/members/${memberId}/collections`, 'PUT', collections);
  },
};
